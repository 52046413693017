import React, { FunctionComponent } from 'react';
import { TextField, ShowProps } from 'react-admin';

import AppTitle from '../../Components/ui/layout/AppTitle';
import { AppShow } from '../../Components/ui/detail/AppShow';
import AppShowLayout from '../../Components/ui/layout/AppShowLayout';

const CategoryShow: FunctionComponent<ShowProps> = (props) => {
  return (
    <AppShow
      {...props}
      title={<AppTitle title={`Dettagli Lotto Numero ${props.id}`} />}
      //actions={<ToolbarTop {...props} />}
    >
      <AppShowLayout>
        <TextField source='description' label='Nome' />
      </AppShowLayout>
    </AppShow>
  );
};

export default CategoryShow;
