import PublishIcon from '@material-ui/icons/Publish';
import { ResourceExport } from '../../types/resource-export.type';
import MassivePublicationList from './MassivePublicationList';

const MassivePublication: ResourceExport = {
  list: MassivePublicationList,
  icon: PublishIcon,
};

export default MassivePublication;
