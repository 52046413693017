import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import {
  DeleteWithConfirmButton,
  FunctionField,
  TextField,
  useCheckMinimumRequiredProps,
} from 'react-admin';
import PersonRoleManager from '../../Resources/Person/PersonRoleManager';
import AppRelatedResource, {
  AppRelatedResourceEditButton,
} from '../related-resource/app-related-resource';
import AppCard from '../ui/card/AppCard';
import AppDatagrid from '../ui/datagrid/AppDatagrid';

const useStyles = makeStyles(() => ({
  roleDesc: {
    minWidth: '20rem',
  },
  courtDesc: {
    minWidth: '18rem',
  },
  fill: {
    width: '100%',
  },
}));

const AppRolesData: FC<AppRolesDataProps> = (props) => {
  const classes = useStyles();
  useCheckMinimumRequiredProps('AppRolesData', ['record', 'mode', 'personType'], props);
  const { personType, disabled, ...others } = props;

  return (
    <AppCard {...others} title="Ruoli" hideContent={others.mode === 'create'}>
      <AppRelatedResource
        relatedResource="/roles"
        errorMessage="Errore durante il caricamento dei ruoli"
        manager={<PersonRoleManager anchor="right" personType={personType} />}
        actionsHidden={disabled}
      >
        <AppDatagrid>
          <TextField label="Ruolo" source="role.description" headerClassName={classes.roleDesc} />
          <FunctionField
            label="Tribunale"
            render={(r) => r?.court?.name || '-'}
            headerClassName={classes.courtDesc}
          />
          <FunctionField
            label="Annotazioni"
            render={(r) => r?.notes || '-'}
            headerClassName={classes.fill}
          />
          <AppRelatedResourceEditButton disabled={disabled} />
          <DeleteWithConfirmButton
            redirect={false}
            confirmTitle="Eliminazione ruolo"
            confirmContent="Sei sicuro di voler procedere con la cancellazione?"
            disabled={disabled}
          />
        </AppDatagrid>
      </AppRelatedResource>
    </AppCard>
  );
};

export default AppRolesData;

export interface AppRolesDataProps {
  personType: 'naturalPerson' | 'juridicalPerson';

  [k: string]: any;
}
