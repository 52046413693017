import { Box, Chip, Grid, InputAdornment, Typography } from '@material-ui/core';
import { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateTimeInput,
  AppNumberInput,
} from '../../../Components/ui/input';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import { SaleMode } from '../../../utils/constants';
import { amountFormatter } from '../../../utils/data-formatters';
import { maxDate } from '../../../utils/validators';

export const OffersCollection: FC<any> = ({ formData, mode, selectedItem, lastSaleExperiment }) => {
  const { fkSaleMode } = formData;

  return fkSaleMode === SaleMode.OffersCollection ? (
    <AppCard title="Informazioni sulla raccolta offerte" disabled={mode === 'show'}>
      <AppDateTimeInput
        label="Inizio"
        source="offersCollectionStartAt"
        md={3}
        required
        validate={[maxDate('offersCollectionEndAt', 'Termine', false)]}
      />
      <AppDateTimeInput label="Termine" source="offersCollectionEndAt" md={3} required />
      <AppNumberInput
        label="Prezzo base"
        source="basePrice"
        inputMode="decimal"
        xs={6}
        md={3}
        isAmount
        required={!lastSaleExperiment}
        defaultValue={lastSaleExperiment?.basePrice}
      />
      <AppAutocompleteInput
        label="Tipo di vendita"
        source="fkSaleType"
        reference="sale-types"
        optionText="description"
        md={3}
      />
      <AppDivider />
      <AppNumberInput
        label="Diritti d'asta"
        source="auctionRights"
        md={3}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <AppNumberInput
        label="IVA su diritti d'asta"
        source="auctionRightsVat"
        md={3}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <AppNumberInput
        label="IVA sull'aggiudicazione"
        source="adjudicationVat"
        md={3}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <AppBooleanInput source="publishVatInfo" label="Pubblica online" md={3} />
      <AppDivider />
      <AppBooleanInput
        source="showCommercialPrice"
        label="Pubblica il valore commerciale del lotto"
        disabled={!selectedItem.value}
      />
      <Grid item md={6}>
        <Typography align="center">
          Valore commerciale{' '}
          <Box display="block">
            <Chip
              label={amountFormatter(selectedItem.value)}
              color={formData.showCommercialPrice ? 'primary' : 'secondary'}
            />
          </Box>
        </Typography>
      </Grid>
      <AppBooleanInput
        source="showEvaluationPrice"
        label="Pubblica il prezzo di perizia del lotto"
        disabled={!selectedItem.evaluation}
      />
      <Grid item md={6}>
        <Typography align="center">
          Prezzo di perizia{' '}
          <Box display="block">
            <Chip
              label={amountFormatter(selectedItem.evaluation)}
              color={formData.showEvaluationPrice ? 'primary' : 'secondary'}
            />
          </Box>
        </Typography>
      </Grid>
    </AppCard>
  ) : (
    <></>
  );
};
