import { useState } from 'react';

/**
 * Simple hook that speeds up the creation of the open/close state of a dialog.
 * @return The dialog "open" state and the toggle-state function
 */
export const useToggleState = (): [boolean, () => void] => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prevState) => !prevState);
  return [open, toggleOpen];
};
