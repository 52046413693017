import { Grid } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import { FeeConditionText } from '../../Components/fee-profile/fee-conditions-recap';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { useCmsConfiguration } from '../../hooks/use-cms-configuration';
import { PaymentMethod, PAYMENT_METHODS } from '../Payment/constants';

const IVG_NAME = process.env.REACT_APP_IVG_NAME;

export const FeeProfileExpand: FC<any> = ({ record }) => {
  const cmsConfig = useCmsConfiguration();
  const paymentsBeneficiaries = useMemo(
    () => [
      { id: 'cda', name: IVG_NAME ?? 'Casa delle Aste' },
      {
        id: 'procedure',
        name: cmsConfig?.websiteCustomization?.Generic?.WireTransfersBeneficiary ?? 'La procedura',
      },
    ],
    [cmsConfig],
  );

  return (
    <Grid container>
      <DetailPanelTitle>Impostazioni offerta</DetailPanelTitle>
      <DetailPanelField
        label="Applica cauzione"
        value={record.applyFee !== undefined ? (record.applyFee ? 'SI' : 'NO') : 'NON SPECIFICATO'}
        md={2}
      />
      {record.feePaymentMethods?.length && (
        <DetailPanelField
          label="Metodi di pagamento"
          value={PAYMENT_METHODS.filter((pm) =>
            record.feePaymentMethods.some((feePm) => feePm === pm.id),
          )
            .map((pm) => pm.name)
            .join(' - ')}
          md={10}
        />
      )}
      {record.feePaymentMethods?.includes(PaymentMethod.WireTransfer) && (
        <>
          <DetailPanelTitle>Pagamento con bonifico</DetailPanelTitle>
          <DetailPanelField label="IBAN beneficiario" value={record.ibanCode} md={4} />
          <DetailPanelField
            label="Beneficiario pagamenti"
            value={paymentsBeneficiaries.find((pb) => pb.id === record.paymentsBeneficiary)?.name}
            md={4}
          />
          <DetailPanelField label="Istituto bancario" value={record.bank} md={4} />
        </>
      )}
      {record.feeConditions && !!Object.values(record.feeConditions).length && (
        <DetailPanelField
          label="Condizioni cauzione"
          value={record.feeConditions.map((feeCondition) => (
            <FeeConditionText
              feePercentage={feeCondition.feePercentage}
              offerAmount={feeCondition.offerAmount}
              type={feeCondition.type}
            />
          ))}
          md={12}
        />
      )}
      <AppDivider />
      <DetailPanelTitle>Impostazioni offerta 107</DetailPanelTitle>
      <DetailPanelField
        label="Applica cauzione"
        value={
          record.applyFeeToA107 !== undefined
            ? record.applyFeeToA107
              ? 'SI'
              : 'NO'
            : 'NON SPECIFICATO'
        }
        md={2}
      />
      {record.a107FeePaymentMethods?.length && (
        <DetailPanelField
          label="Metodi di pagamento"
          value={PAYMENT_METHODS.filter((pm) =>
            record.a107FeePaymentMethods.some((feePm) => feePm === pm.id),
          )
            .map((pm) => pm.name)
            .join(' - ')}
        />
      )}
      {record.a107FeeConditions && !!Object.values(record.a107FeeConditions).length && (
        <DetailPanelField
          label="Condizioni cauzione"
          value={record.a107FeeConditions.map((feeCondition) => (
            <FeeConditionText
              feePercentage={feeCondition.feePercentage}
              offerAmount={feeCondition.offerAmount}
              type={feeCondition.type}
            />
          ))}
          md={12}
        />
      )}
    </Grid>
  );
};
