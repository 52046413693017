import { useEffect } from 'react';
import { useRefresh } from 'react-admin';

/**
 * Refreshes the page every *ms* (default = 1 min)
 * @param ms
 */
export const useAutomaticRefresh = (ms = 60000): void => {
  const refresh = useRefresh();

  useEffect(() => {
    const interval = setInterval(refresh, ms);
    return () => {
      clearInterval(interval);
    };
  }, []);
};
