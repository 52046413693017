import { makeStyles } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DetailPanelTitle } from '../../detail-panel';
import RequestPublicationsList from '../utilities/request-publications-list';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    nameCol: {
      minWidth: theme.spacing(40),
    },
    urlCol: {
      width: '100%',
    },
  }),
  { name: 'RequestDetailsPublicationsList' },
);

interface RequestDetailsPublicationsListProps {
  publicationsList: Record<string, any>[];
}

const RequestDetailsPublicationsList: FC<RequestDetailsPublicationsListProps> = ({
  publicationsList,
}) => {
  const classes = useStyles();

  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  useEffect(() => {
    const publicationsWithIds = publicationsList.map((publication, index) => ({
      id: index + 1,
      ...publication,
    }));
    setListCtx(
      _assign({}, listBaseCtx, {
        data: _keyBy(publicationsWithIds, 'id'),
        ids: _map(publicationsWithIds, 'id'),
        total: publicationsWithIds.length,
        loaded: true,
        loading: false,
      }),
    );
  }, [publicationsList]);

  return (
    <div className={classes.container}>
      <DetailPanelTitle children="LISTA DELLE PUBBLICAZIONI" />
      <RequestPublicationsList listCtx={listCtx} />
    </div>
  );
};

export default RequestDetailsPublicationsList;
