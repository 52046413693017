import { ReactElement, useState } from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { LINK_SECTIONS_AGENCIES_CHOICES, LINK_TYPES_CHOICES } from '../../Booking/constants';
import PageAgencySelection from '../Pages/PageSelection';

const LinkDetails = (props: Record<any, any>): ReactElement => {
  const [urlType, setUrlType] = useState<string>(props.record.urlType ?? '');

  return (
    <AppBaseForm {...props}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppTextInput
          source="title"
          label="Titolo"
          md={12}
          required
          disabled={props.mode === 'show'}
        />
        <AppSelectInput
          source="section"
          label="Sezione"
          md={6}
          required
          choices={LINK_SECTIONS_AGENCIES_CHOICES}
          disabled={props.mode === 'show'}
        />
        <AppBooleanInput
          source="openInNewWindow"
          label="Apri in nuova finestra"
          md={3}
          required
          disabled={props.mode === 'show'}
        />
        <AppNumberInput
          source="order"
          label="Ordine"
          md={3}
          required
          disabled={props.mode === 'show'}
        />
        <AppSelectInput
          source="urlType"
          label="Tipo"
          md={6}
          required
          choices={LINK_TYPES_CHOICES}
          onChange={(e) => setUrlType(e.target.value)}
          defaultValue={props.record.urlType}
          disabled={props.mode === 'show'}
        />
        {urlType === LINK_TYPES_CHOICES[0].id && (
          <PageAgencySelection mode={props.mode} source="url" label="Pagina" />
        )}
        {urlType === LINK_TYPES_CHOICES[1].id && (
          <AppTextInput source="url" label="URL" md={6} disabled={props.mode === 'show'} />
        )}
        {props.mode === 'show' ? (
          <ReferenceArrayField
            reference="person-sellers"
            source="personSellerIds"
            label="Proprietari"
          >
            <SingleFieldList>
              <ChipField source="description" style={{ marginBottom: '15px' }} />
            </SingleFieldList>
          </ReferenceArrayField>
        ) : (
          <AppAutocompleteInput
            source="personSellerIds"
            reference="person-sellers"
            label="Proprietari"
            optionText={(option) => {
              if (!option) return '';
              return option.description;
            }}
            resettable
            variant="outlined"
            isArray
            md={12}
            filter={{
              schema: null,
            }}
          />
        )}
      </AppCard>
    </AppBaseForm>
  );
};

export default LinkDetails;
