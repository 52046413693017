import { makeStyles } from '@material-ui/core';
import React, { cloneElement, FC } from 'react';
import { BooleanField, FunctionField, Identifier, Record as RaRecord } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';

const useStyles = makeStyles(
  (theme) => ({
    saleExperimentInsertionId: {
      // minWidth: theme.spacing(30),
      whiteSpace: 'nowrap',
    },
    title: {
      minWidth: theme.spacing(50),
    },
    publishDate: {
      minWidth: theme.spacing(20),
    },
    saleDate: {
      minWidth: theme.spacing(25),
    },
    procedure: {
      width: theme.spacing(20),
    },
    code: {
      // width: theme.spacing(20),
      whiteSpace: 'nowrap',
    },
    court: {
      minWidth: theme.spacing(30),
    },
    sale: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      width: '100%',
    },
  }),
  {
    name: 'PvpPublicationRequestDatagrid',
  },
);

interface PvpPublicationRequestDatagridProps {
  rowStyle?: (record: RaRecord, index: number) => any;
  rowClick?: string | ((id: Identifier, basePath: string, record: RaRecord) => string);
  expand?: JSX.Element;
  additionalInfo?: JSX.Element;
  showPvpRequestTypes?: boolean;
  buttons?: JSX.Element[];
}

const PvpPublicationRequestDatagrid: FC<PvpPublicationRequestDatagridProps> = ({
  rowStyle,
  rowClick,
  expand,
  additionalInfo,
  showPvpRequestTypes = true,
  buttons,
}) => {
  const classes = useStyles();
  return (
    <AppDatagrid
      rowClick={rowClick}
      expand={expand}
      rowStyle={rowStyle}
      additionalInfo={additionalInfo}
    >
      <AppTextField
        source="saleExperimentInsertionId"
        label="Numero inserzione sul PVP"
        headerClassName={classes.saleExperimentInsertionId}
        sortBy={'"pvpPubReq"."requestData"->\'attributes\'->\'idInserzioneEspVendita\''}
      />
      <AppTextField
        source="title"
        label="Titolo"
        truncate
        maxTextLength={40}
        headerClassName={classes.title}
        sortBy={'"pvpPubReq"."requestData"->\'lotto\'->\'descrizioneIT\''}
      />
      <AppDateField
        source="publishedOnPvpAt"
        label="Data richiesta"
        headerClassName={classes.publishDate}
        sortBy={'"pvpPubReq"."requestData"->\'dataPubblicazione\''}
      />
      <AppDateField
        source="auctionStartAt"
        label="Data di vendita"
        headerClassName={classes.saleDate}
        sortBy={'"pvpPubReq"."requestData"->\'datiVendita\'->\'dataOraVendita\''}
      />
      <AppTextField
        source="genre"
        label="Genere"
        sortBy={'"pvpPubReq"."requestData"->\'lotto\'->\'genere\''}
      />
      <FunctionField
        label="Procedura"
        render={(record) => `${record.procedureNumber}/${record.procedureYear}`}
        headerClassName={classes.procedure}
        sortBy={'"procedureCodeAndYear"'}
      />
      <AppTextField
        source="code"
        label="Numero lotto"
        headerClassName={classes.code}
        sortBy={'"pvpPubReq"."requestData"->\'lotto\'->\'codice\''}
      />
      <AppTextField
        source="court"
        label="Tribunale"
        truncate
        headerClassName={showPvpRequestTypes ? classes.court : classes.lastCol}
        sortBy={
          "\"pvpPubReq\".\"requestData\"->'datiProcedura'->'proceduraGiudiziaria'->'tribunale'"
        }
      />
      {showPvpRequestTypes && [
        <BooleanField source="sale" label="Vendita" headerClassName={classes.sale} />,
        <BooleanField source="adv" label="Pubblicità" headerClassName={classes.lastCol} />,
      ]}
      {buttons && !!buttons.length && buttons.map((button) => cloneElement(button))}
    </AppDatagrid>
  );
};

export default PvpPublicationRequestDatagrid;
