/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container, ContainerProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Record, useTranslate } from 'ra-core';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(
  (theme: Theme) => ({
    globalContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    gridContainer: {},
  }),
  { name: 'AppFormTab' },
);

const hiddenStyle = { display: 'none' };
const activeStyle = { display: 'block' };

const AppFormTab: FC<PropsWithChildren<AppFormTabProps>> = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  icon,
  intent,
  label,
  margin,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  path,
  record,
  resource,
  variant,
  value,
  disabled = false,
  disabledCards = false,
  maxWidth = 'md',
  ...rest
}) => {
  const translate = useTranslate();
  const location = useLocation();

  // Changed only the primary class name
  const renderHeader = () => {
    if (!disabled) {
      return (
        <MuiTab
          key={label}
          label={translate(label, { _: label })}
          value={value}
          icon={icon}
          className={classnames('app-form-tab', className)}
          component={Link}
          to={{ ...location, pathname: value }}
          disabled={disabled}
          {...rest}
        />
      );
    }
    return <span />;
  };

  const renderContent = () => {
    const classes = useStyles(rest);
    return (
      <Container maxWidth={maxWidth}>
        <div
          style={disabled || hidden ? hiddenStyle : activeStyle}
          className={classnames(classes.globalContainer, contentClassName)}
        >
          {!disabled &&
            React.Children.map(children as any, (child: ReactElement) =>
              React.cloneElement(child, {
                basePath,
                record,
                resource,
                variant,
                margin,
                disabled: disabledCards,
              }),
            )}
        </div>
      </Container>
    );
  };

  return intent === 'header' ? renderHeader() : renderContent();
};

export interface AppFormTabProps extends Pick<ContainerProps, 'maxWidth'> {
  children?: React.ReactNode;
  basePath?: string;
  className?: string;
  contentClassName?: string;
  hidden?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  intent?: 'header' | 'content';
  label: string;
  margin?: 'none' | 'normal' | 'dense';
  path?: string;
  record?: Record;
  resource?: string;
  value?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  mode?: 'create' | 'edit';
  disabledCards?: boolean;
}

AppFormTab.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  intent: PropTypes.oneOf(['header', 'content']),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  path: PropTypes.string,
  // @ts-ignore
  record: PropTypes.object,
  resource: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  mode: PropTypes.oneOf(['create', 'edit']),
};

AppFormTab.displayName = 'AppFormTab';

export default AppFormTab;
