import { makeStyles } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { FunctionField, ListContextProvider, ReferenceField } from 'react-admin';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import { SaleMode } from '../../utils/constants';
import { getResourceType } from '../../utils/reference-selector';
import { CalendarEvent } from './types';

const useDgStyles = makeStyles(
  (theme) => ({
    others: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
    buttonCol: {
      minWidth: theme.spacing(30),
    },
  }),
  { name: 'SaleExperimentCalendarEventsTable' },
);

interface SaleExperimentCalendarTableProps {
  listCtx: Record<string, any>;
  resource: string;
  selectedEvent?: CalendarEvent;
}

const SaleExperimentCalendarTable: FC<SaleExperimentCalendarTableProps> = ({
  listCtx,
  selectedEvent,
  resource,
}) => {
  const dgClasses = useDgStyles();
  const resourceType = useMemo(() => getResourceType(resource), [resource]);
  return (
    <ListContextProvider value={listCtx}>
      <AppDatagrid>
        <AppTextField
          source={
            resourceType === 'private'
              ? 'relatedItem.relatedFolder.title'
              : 'relatedItem.relatedFolder.procedureCode'
          }
          label={resourceType === 'private' ? 'Pratica' : 'Numero procedura'}
          headerClassName={dgClasses.others}
        />
        {resourceType !== 'private' && (
          <ReferenceField
            basePath="sale-experiments"
            reference="courts"
            source="relatedItem.relatedFolder.fkCourt"
            label="Tribunale"
            link={false}
            headerClassName={dgClasses.others}
          >
            <AppTextField source="name" />
          </ReferenceField>
        )}
        {!!selectedEvent && selectedEvent.saleMode === SaleMode.AsyncOnline && (
          <AppDateField
            source="auctionStartAt"
            showTime
            label="Data inizio asta"
            headerClassName={dgClasses.others}
          />
        )}
        {!!selectedEvent && selectedEvent.saleMode === SaleMode.AsyncOnline && (
          <AppDateField
            source="auctionEndAt"
            showTime
            label="Data termine asta"
            headerClassName={dgClasses.others}
          />
        )}
        <AppTextField
          source="relatedItem.title"
          label="Titolo del lotto"
          truncate
          headerClassName={dgClasses.others}
        />
        <ReferenceField
          basePath="sale-experiments"
          reference="cities"
          source="relatedItem.fkCity"
          label="Città"
          link={false}
          emptyText="-"
          headerClassName={dgClasses.lastCol}
        >
          <AppTextField source="name" />
        </ReferenceField>
        <FunctionField
          headerClassName={dgClasses.buttonCol}
          render={(record) => (
            <AppItemLinksButton
              resource={resource}
              saleExpIdSource="id"
              itemIdSource="fkItem"
              folderIdSource="relatedItem.fkFolder"
              currentResourceLabel="Calendario vendite"
              record={record}
              size="small"
            />
          )}
        />
      </AppDatagrid>
    </ListContextProvider>
  );
};

export default SaleExperimentCalendarTable;
