import React, { FC } from 'react';
import PvpOfferBid from './pvp-offer-bid';
import PvpOfferBidders from './pvp-offer-bidders';
import PvpOfferPresenter from './pvp-offer-presenter';

interface PvpOfferTabProps {
  pvpOfferData: Record<string, any>;
}

const PvpOffer: FC<PvpOfferTabProps> = ({ pvpOfferData }) => {
  const { offerta, presentatore } = pvpOfferData;
  const offerenti =
    pvpOfferData.offerenti.offerente instanceof Array
      ? pvpOfferData.offerenti.offerente
      : [pvpOfferData.offerenti.offerente];

  return (
    <React.Fragment>
      <PvpOfferPresenter presentatore={presentatore} />
      <PvpOfferBidders offerenti={offerenti} />
      <PvpOfferBid offerta={offerta} />
    </React.Fragment>
  );
};

export default PvpOffer;
