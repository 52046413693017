import PublishIcon from '@material-ui/icons/Publish';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import ExternalPropertiesUpdatesList from './ExternalPropertiesUpdatesList';

export const EXTERNAL_PROPERTIES_UPDATE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const ExternalPropertiesUpdate: ResourceExport = {
  list: ExternalPropertiesUpdatesList,
  icon: PublishIcon,
};
export default ExternalPropertiesUpdate;
