import React from 'react';
import { useCallback, useMemo } from 'react';
import {
  TablePagination,
  Toolbar,
  useMediaQuery,
  Theme,
  CardContent,
  Typography,
} from '@material-ui/core';
import { useTranslate, useListPaginationContext, sanitizeListRestProps } from 'ra-core';

import AppPaginationToolbarActions from './AppPaginationToolbarActions';

const PaginationLimit = (
  <CardContent>
    <Typography variant='body2'>Nessun risultato</Typography>
  </CardContent>
);

const emptyArray = [];

const AppPaginationToolbar: any = (props) => {
  const { rowsPerPageOptions = [20, 50, 100], limit = PaginationLimit, ...rest } = props;
  const { loading, page, perPage, total, setPage, setPerPage } = useListPaginationContext(props);
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1;
  }, [perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > totalPages - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          }),
        );
      }
      setPage(page + 1);
    },
    [totalPages, setPage, translate],
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage],
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate],
  );

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant='dense' /> : limit;
  }

  if (isSmall) {
    return (
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component='span'
        labelDisplayedRows={labelDisplayedRows}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={AppPaginationToolbarActions}
      component='span'
      labelRowsPerPage='Righe per pagina:'
      labelDisplayedRows={({ from, to, count }) => (
        <div>
          Elementi da {from} a {to} di {count}
        </div>
      )}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
};

/*
AppPagination.propTypes = {
  actions: ComponentPropType,
  limit: PropTypes.element,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export interface AppPaginationProps extends TablePaginationBaseProps {
  rowsPerPageOptions?: number[];
  actions?: FC;
  limit?: ReactElement;
}
 */

export default AppPaginationToolbar;
