import { makeStyles } from '@material-ui/core';
import { keyBy as _keyBy, map as _map } from 'lodash';
import { FC, useMemo } from 'react';
import { FunctionField, ListContextProvider, Record as RaRecord } from 'react-admin';
import AppDatagrid from '../ui/datagrid/AppDatagrid';
import AppTextField from '../ui/field/AppTextField';
import AppUrlField from '../ui/field/AppUrlField';
import { AppText } from '../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    itemTitle: {
      minWidth: theme.spacing(50),
    },
    name: {
      minWidth: theme.spacing(40),
    },
    url: {
      width: '100%',
    },
    cellError: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'RelatedPublicationsList' },
);

interface RelatedPublicationsListProps {
  publicationsList: RaRecord[];
}

export const RelatedPublicationsList: FC<RelatedPublicationsListProps> = ({ publicationsList }) => {
  const classes = useStyles();

  const listCtx = useMemo(
    () => ({
      resource: 'publication-proofs',
      page: 1,
      perPage: 1000,
      currentSort: { field: 'id', sort: 'ASC' },
      selectedIds: [],
      data: _keyBy(publicationsList, 'id'),
      ids: _map(publicationsList, 'id'),
      total: publicationsList.length,
      loaded: true,
      loading: false,
    }),
    [publicationsList],
  );

  return (
    <ListContextProvider value={listCtx}>
      <AppDatagrid>
        <AppTextField
          source="itemTitle"
          label="Lotto"
          truncate
          maxTextLength={40}
          headerClassName={classes.itemTitle}
        />
        <AppTextField source="name" label="Sito" headerClassName={classes.name} />
        <FunctionField
          label="Pubblicazione"
          headerClassName={classes.url}
          render={(publication) =>
            publication?.isPublished ? (
              <AppUrlField
                url={`${publication.host}/annunci/${publication.slug}`}
                text="Vai al dettaglio"
              />
            ) : (
              <AppText children="Non attiva" className={classes.cellError} />
            )
          }
        />
      </AppDatagrid>
    </ListContextProvider>
  );
};
