import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
  ArrayField,
  ChipField,
  FunctionField,
  ListProps,
  ReferenceField,
  SelectField,
  SingleFieldList,
} from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppLinkToEntityField } from '../../Components/ui/field';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppNumberInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { PERSON_TYPES } from '../Booking/constants';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    name: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    amount: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    errorMessage: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
  }),
  { name: 'ParticipationAttemptList' },
);

const PARTICIPANT_BASE_SOURCE = 'frontEndParticipant';
const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const ERROR_CHOICES = [
  { id: '', name: 'Tutti' },
  { id: 'Chiusura finestra browser', name: 'Chiusura finestra browser' },
  { id: 'Chiusura del form di pagamento', name: 'Chiusura del form di pagamento' },
  { id: 'Errore nella compilazione del form', name: 'Errore nella compilazione del form' },
  { id: 'Altro', name: 'Altro' },
];

const ParticipantAttemptListFilters = (
  <AppListFilter helperText="Anagrafica richiedente">
    <AppSelectInput source="errorMessage" choices={ERROR_CHOICES} label="Errore" md={3} />
    <AppNumberInput source="amount" label="Offerta maggiore di" md={3} />
  </AppListFilter>
);

const ParticipationAttemptList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Tentativi di partecipazione" />}
      hideCreateButton
      filters={ParticipantAttemptListFilters}
    >
      <AppDatagrid>
        <AppDateField
          source="createdAt"
          label="Data richiesta"
          showTime
          headerClassName={classes.createdAt}
        />
        <FunctionField
          label="Anagrafica richiedente"
          render={(r) => {
            const name =
              r?.frontEndParticipant.type === 'person'
                ? `${r?.frontEndParticipant.firstName} ${r?.frontEndParticipant.lastName}`
                : r?.frontEndParticipant.businessName;
            return (
              <AppLinkToEntityField
                targetResource={'participants'}
                originName="tentativi di partecipazione"
                value={name}
                targetResourceId={() => r?.frontEndParticipant.id}
              />
            );
          }}
          headerClassName={classes.name}
        />
        <AppTextField
          source="amount"
          label="Offerta"
          type="amount"
          headerClassName={classes.amount}
          cellClassName={classes.amount}
        />
        <AppTextField
          source="errorMessage"
          label="Errore"
          headerClassName={classes.errorMessage}
          cellClassName={classes.errorMessage}
        />
        <ArrayField source={`${PARTICIPANT_BASE_SOURCE}.mobiles`} label="Cellulare">
          <SingleFieldList>
            <ChipField source="number" />
          </SingleFieldList>
        </ArrayField>
        <AppTextField source={`${PARTICIPANT_BASE_SOURCE}.email`} label="Email" />
        <SelectField
          choices={PERSON_TYPES}
          source={`${PARTICIPANT_BASE_SOURCE}.type`}
          label="Tipo persona"
          headerClassName={classes.type}
        />
        <ReferenceField
          reference={IS_IVG ? 'sale-experiments-judiciary' : 'sale-experiments'}
          source="saleExperimentId"
          label="Esperimento di vendita"
          link={false}
          headerClassName={classes.lastCol}
        >
          <FunctionField
            render={(saleExp) => (
              <AppLinkToEntityField
                mode="show"
                targetResource="sale-experiments"
                targetResourceId={saleExp?.id as number}
                originName="pagamenti"
                value={saleExp?.id as string}
              />
            )}
          />
        </ReferenceField>
      </AppDatagrid>
    </AppList>
  );
};

export default ParticipationAttemptList;
