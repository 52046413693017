export enum ImportState {
  Pending = 'pending',
  Successful = 'successful',
  Error = 'error',
}

export const ImportStates = [
  { id: null, name: '\xa0' },
  { id: ImportState.Pending, name: 'In attesa' },
  { id: ImportState.Successful, name: 'Ok' },
  { id: ImportState.Error, name: 'Errore' },
];

/*
  Pending = 'pending',
  Successful = 'successful',
  Error = 'error',
  Processing = 'processing',
 */
