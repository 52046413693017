import { makeStyles } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { ListContextProvider } from 'react-admin';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import PvpPublicationRequestDatagrid from '../PvpPublicationRequest/PvpPublicationRequestDatagrid';

const useStyles = makeStyles(
  () => ({
    goToItemButton: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PvpAssignmentExpanded' },
);

const GoToItemButton: FC<any> = ({ record, resource }) => {
  const classes = useStyles();

  if (!record || (!record.relatedItemId && !record.relatedFolderId && !record.relatedSaleId))
    return <Fragment />;

  return (
    <AppItemLinksButton
      resource={resource}
      record={record}
      itemIdSource="relatedItemId"
      folderIdSource="relatedFolderId"
      saleExpIdSource="relatedSaleId"
      currentResourceLabel="Registri incarichi PVP"
      size="small"
      forceJudiciaryTargetResource
      className={classes.goToItemButton}
    />
  );
};

const PvpAssignmentExpanded: FC<any> = ({ record: pvpAssignment }) => {
  const { pvpPublicationRequestsList } = pvpAssignment;
  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  useEffect(() => {
    setListCtx(
      _assign({}, listBaseCtx, {
        data: _keyBy(pvpPublicationRequestsList, 'id'),
        ids: _map(pvpPublicationRequestsList, 'id'),
        total: pvpPublicationRequestsList.length,
        loaded: true,
        loading: false,
      }),
    );
  }, [pvpPublicationRequestsList]);

  return (
    <ListContextProvider value={listCtx}>
      <PvpPublicationRequestDatagrid showPvpRequestTypes={false} buttons={[<GoToItemButton />]} />
    </ListContextProvider>
  );
};

export default PvpAssignmentExpanded;
