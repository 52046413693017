import { Button, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  assign as _assign,
  isEqual as _isEqual,
  isFunction as _isFn,
  keyBy as _keyBy,
  map as _map,
} from 'lodash';
import { Record as RaRecord } from 'ra-core/esm/types';
import {
  FC,
  Fragment,
  ReactElement,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ClassesOverride,
  ListContextProvider,
  ResourceContextProvider,
  useCheckMinimumRequiredProps,
  useCreate,
  useMutation,
  useNotify,
} from 'react-admin';
import { AppEmptyNoItems } from '../empty/app-empty';
import AppDivider from '../ui/layout/AppDivider';

export const useAppRelatedResourceController = (
  props: AppRelatedResource2Props,
): AppRelatedResourceContextInterface => {
  const {
    resource: mainResource,
    record,
    relatedResource,
    filter: filterProp,
    sort: sortProp,
    errorMessage: defaultErrorMsg = 'Impossibile scaricare dati secondari',
    defaultManagerMode = null,
    onChange = null,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [managerTitle, setManagerTitle] = useState<string>('');
  const [managerMode, setManagerMode] = useState<'insert' | 'edit' | null>(defaultManagerMode);
  const [item, setItem] = useState({});

  const [additionalParams, setAdditionalParams] = useState<Record<string, any>>({});

  // Lazy fix, managerMode doesn't update inside submit function call
  const modeRef = useRef(managerMode);
  useEffect(() => {
    modeRef.current = managerMode;
  }, [managerMode]);

  const resource = _getResourcePath(mainResource, relatedResource, record);
  const filter = _isFn(filterProp) ? filterProp(record) : filterProp ?? {};
  const sort = _isFn(sortProp) ? sortProp(record) : sortProp ?? { field: 'id', sort: 'ASC' };

  const notify = useNotify();

  const baseCtx = {
    resource,
    page: 1,
    perPage: 1000,
    currentSort: sortProp ?? { field: 'id', sort: 'ASC' },
    setSort: (sort, order) => {
      setListCtx((prev) => ({ ...prev, currentSort: { field: sort, order: order } }));
    },
    selectedIds: [],
    ids: [],
    data: {},
    total: 0,
    loaded: false,
    loading: false,
  };
  const [listCtx, setListCtx] = useState(baseCtx);
  const [getList /*, { data, total, error, loading, loaded }*/] = useMutation(
    {
      type: 'getList',
      resource,
      payload: { filter, sort: listCtx.currentSort ?? sort },
    },
    {
      onSuccess: (res) => {
        setListCtx((prev) => ({
          ...prev,
          data: _keyBy(res.data, 'id'),
          ids: _map(res.data, 'id') as never[],
          total: res.total,
          loaded: true,
          loading: false,
        }));
        onChange && onChange(res.data);
      },
      onFailure: (error) => {
        setListCtx(
          _assign({}, baseCtx, {
            data: {},
            ids: [],
            total: -1,
            loaded: true,
            loading: false,
          }) as any,
        );
        notify(defaultErrorMsg, 'error');
        process.env.NODE_ENV !== 'production' && console.error(error);
      },
    },
  );

  useEffect(() => {
    getList();
  }, [listCtx.currentSort]);

  const [create /*{loading: creating}*/] = useCreate(resource);
  const addRelatedResource = (values, close) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: (res) => {
          notify('Operazione conclusa con successo.', 'info');
          getList();
          setOpen(!close);
          if (close) return;
          setManagerMode('edit');
          setItem(res.data);
        },
        onFailure: (err) => {
          notify(err.message ?? err, 'error');
        },
      },
    );
  };

  const [update /*{loading: updating}*/] = useMutation();
  const updateRelatedResource = (values, close) => {
    update(
      {
        type: 'update',
        resource,
        payload: { id: values.id, data: values },
      },
      {
        onSuccess: (res) => {
          notify('Operazione conclusa con successo.', 'info');
          getList();
          setOpen(!close);
          setItem(res.data);
        },
        onFailure: (err) => {
          notify(err.message ?? err, 'error');
        },
      },
    );
  };

  const [remove] = useMutation();
  const removeRelatedResource = (values, close) => {
    remove(
      {
        type: 'delete',
        resource,
        payload: { id: values.id, previousData: values },
      },
      {
        onSuccess: () => {
          notify('Operazione conclusa con successo.', 'info');
          getList();
          setOpen(!close);
        },
        onFailure: (err) => notify(err.message ?? err, 'error'),
      },
    );
  };

  const removeRelatedResources = (values: number[], close?: boolean, callback?: () => void) => {
    remove(
      {
        type: 'deleteMany',
        resource: `${resource}/delete-many`,
        payload: { ids: values },
      },
      {
        onSuccess: () => {
          notify('Operazione conclusa con successo.', 'info');
          getList();
          if (close !== undefined) {
            setOpen(!close);
          }

          // If a callback is passed, it will be executed after the operation is completed (e.g. update a state)
          if (callback) {
            console.log('callback passed!');
            callback();
          }
        },
        onFailure: (err) => notify(err.message ?? err, 'error'),
      },
    );
  };

  const [getOne /*{loading: fetching}*/] = useMutation();
  const getRelatedResource = (id: number | string) =>
    getOne(
      {
        type: 'getOne',
        resource,
        payload: { id },
      },
      {
        onSuccess: ({ data }) => {
          setManagerMode('edit');
          setItem(data);
          setOpen(true);
        },
        onFailure: (err) => {
          notify("Impossibile recuperare l'elemento richiesto", 'error');
          process.env.NODE_ENV !== 'production' && console.error(err);
        },
      },
    );

  return {
    resource,
    listContext: listCtx,
    getRelatedResources: () => {
      //TODO: update state... loading:true & loaded:false ... use function for setListCtx
      getList();
    },
    getRelatedResource,
    mainResourceRecord: props.record ?? {},
    item,
    manager: {
      isOpen: isOpen,
      title: managerTitle,
      setTitle: setManagerTitle,
      mode: managerMode,
      setMode: (mode) => {
        if (mode === 'insert') setItem({});
        setManagerMode(mode);
      },
      submit: (data, close) => {
        modeRef.current === 'insert'
          ? addRelatedResource(data, close)
          : updateRelatedResource(data, close);
      },
      remove: removeRelatedResource,
      removeMany: removeRelatedResources,
      open: _toggleManager.bind(null, true),
      close: _toggleManager.bind(null, false),
      setAdditionalParams: setAdditionalParams,
      additionalParams: additionalParams,
    },
  };

  // Utility functions
  function _getResourcePath(mainRes, specificRes, record) {
    if (_isFn(specificRes)) {
      if (record) {
        return specificRes(record, mainRes);
      }
      console.error(
        `AppRelatedResource. "record" not valid; mainResource (${mainRes}) will be used`,
      );
      return mainRes;
    }

    if (specificRes.startsWith('/')) {
      if (record && record.id) {
        return `${mainRes}/${record.id}${specificRes}`;
      }
      console.warn(
        `AppRelatedResource. No instance id found; "${mainRes}${specificRes}" will be used`,
      );
      return `${mainRes}${specificRes}`;
    }

    return specificRes;
  }

  function _toggleManager(status: boolean, event?: any) {
    event && event.stopPropagation && event.stopPropagation();
    setOpen(status);
  }
};

export const AppRelatedResourceContext = createContext<AppRelatedResourceContextInterface>({
  resource: '',
  listContext: {},
  getRelatedResources: () => null,
  getRelatedResource: () => null,
  mainResourceRecord: {},
  item: {},
  manager: {
    isOpen: false,
    title: '',
    setTitle: () => null,
    mode: null,
    setMode: () => null,
    submit: () => null,
    open: () => null,
    close: () => null,
    remove: () => null,
    removeMany: () => null,
    setAdditionalParams: () => null,
    additionalParams: {},
  },
});
AppRelatedResourceContext.displayName = 'AppRelatedResourceContext';

export interface AppRelatedResourceContextInterface {
  resource: string;
  listContext: Record<string, any>;
  getRelatedResources: () => void;
  getRelatedResource: (id: number | string) => void;
  mainResourceRecord: Record<string, any>;
  item: Record<string, any>;
  manager: AppRelatedResourceManager;
}

interface AppRelatedResourceManager {
  isOpen: boolean;
  title: string;
  setTitle: (title: string) => void;
  mode: 'insert' | 'edit' | null;
  setMode: (mode: 'insert' | 'edit') => void;
  open: (event?: any) => void;
  close: (event?: any) => void;
  submit: (data: any, close?: boolean, changeMode?: boolean) => void;
  remove: (data: any, close?: boolean) => void;
  removeMany: (data: number[], close?: boolean, callback?: () => void) => void;
  setAdditionalParams: (params: Record<string, any>) => void;
  additionalParams: Record<string, any>;
}

const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        width: '100%',
        marginBottom: theme.spacing(1),
      },
      toolbar: {
        //backgroundColor: 'lightgray',
      },
      listContainer: {
        //backgroundColor: 'pink',
      },
      rowButton: {
        width: '6rem',
      },
    };
  },
  { name: 'AppRelatedResource2' },
);

const AppRelatedResource: FC<AppRelatedResource2Props> = (props) => {
  useCheckMinimumRequiredProps(
    'AppRelatedResource',
    ['record', 'resource', 'relatedResource'],
    props,
  );

  const {
    manager: Manager,
    actions,
    className,
    classes: classesOverride,
    showDivider = true,
    children,
    emptyPlaceholder = <AppEmptyNoItems />,
    actionsHidden = false,
    filter,
    ...rest
  } = props;
  const ctrl = useAppRelatedResourceController({ ...rest, filter });
  const classes = useStyles({ classes: classesOverride });

  const [filterStatus, setFilterStatus] = useState<Record<string, any>>({});
  useEffect(() => {
    // Need to check for filter change in order to avoid page reload every time tab is changed
    //  (case of multiple AppRelatedResource instances in one component... don't know why it behaves like this, I suppose is some kind of React Context "thing")
    if (!filter || _isEqual(filterStatus, filter)) return;
    setFilterStatus(_isFn(filter) ? filter(rest.record) : filter);
  }, [filter]);

  useEffect(() => {
    ctrl.getRelatedResources();
  }, [filterStatus]);

  //TODO: rivedere corretta posizione/visualizzazione del placeholder/lista(children)

  return (
    <AppRelatedResourceContext.Provider value={ctrl}>
      <div className={classNames(classes.root, className)}>
        {!actionsHidden && (
          <Fragment>
            <Toolbar className={classes.toolbar}>
              {actions && actions.map((action, idx) => cloneElement(action, { key: idx, rest }))}
              {!actions && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    ctrl.manager.setTitle('Nuovo');
                    ctrl.manager.setMode('insert');
                    ctrl.manager.open();
                  }}
                  children="Aggiungi"
                />
              )}
            </Toolbar>
            {showDivider && <AppDivider />}
          </Fragment>
        )}

        <div className={classes.listContainer}>
          <ResourceContextProvider value={ctrl.resource}>
            <ListContextProvider value={ctrl.listContext}>
              {ctrl.listContext.ids.length ? children : emptyPlaceholder}
            </ListContextProvider>
          </ResourceContextProvider>
        </div>
      </div>
      {Manager}
    </AppRelatedResourceContext.Provider>
  );
};

export default AppRelatedResource;

export interface AppRelatedResource2Props {
  relatedResource: string | ((record?: RaRecord, resource?: string) => string);
  filter?: Record<string, any> | ((record?: RaRecord) => Record<string, any>);
  sort?: Record<string, any> | ((record?: RaRecord) => Record<string, any>);
  actions?: ReactElement[];
  manager?: ReactElement;
  children?: any;
  classes?: ClassesOverride<typeof useStyles>;
  className?: string;
  showDivider?: boolean;
  emptyPlaceholder?: ReactElement;
  errorMessage?: string;
  //passed by parent
  record?: RaRecord;
  resource?: string;
  defaultManagerMode?: 'insert' | 'edit';
  actionsHidden?: boolean;
  onChange?: (resources: any) => void;
}

export const AppRelatedResourceEditButton: FC<{
  record?: RaRecord;
  text?: string;
  disabled?: boolean;
  onClick?: () => void | undefined;
}> = (props) => {
  const { disabled = false, record, onClick } = props;
  const ctx = useContext(AppRelatedResourceContext);
  const classes = useStyles();
  return (
    <Button
      className={classes.rowButton}
      variant="outlined"
      color="primary"
      disabled={disabled}
      onClick={() => (onClick && onClick()) || ctx.getRelatedResource((record as any).id)}
    >
      {props.text === undefined ? 'MODIFICA' : props.text ?? ''}
    </Button>
  );
};
