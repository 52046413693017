import { FC, useContext, useEffect, useState } from 'react';
import { RadioButtonGroupInput, useMutation, useNotify } from 'react-admin';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppCard from '../ui/card/AppCard';
import { AppNumberInput, AppSelectInput } from '../ui/input';
import AppDrawerForm from '../drawer-form/drawer-form';

const AppBuyNowItemStocksManager: FC<any> = (props) => {
  const { anchor, title, ...others } = props;
  const notify = useNotify();

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;
  const [variantChoices, setVariantChoices] = useState<Record<string, any>[]>([]);
  const [mutateVariantChoices] = useMutation();
  const [medias, setMedias] = useState<Record<string, any>[]>([]);
  const [mutateImagesChoices] = useMutation();
  const [choices, setChoices] = useState<Record<string, any>[]>([]);
  const [zoomedImage, setZoomedImage] = useState<string | null>();

  useEffect(() => {
    handleVariantChoices();
    handleImagesChoices();
  }, [ctx.manager.isOpen]);

  const handleVariantChoices = () =>
    ctx.manager.isOpen &&
    mutateVariantChoices(
      {
        type: 'getList',
        resource: `items/${ctx.mainResourceRecord.id}/buy-now-variants`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setVariantChoices(res.data);
        },
        onFailure: (err) => {
          notify('Non è stato possibile recuperare la lista delle varianti.', 'error');
          console.error(err);
        },
      },
    );

  const handleImagesChoices = () =>
    ctx.manager.isOpen &&
    mutateImagesChoices(
      {
        type: 'getList',
        resource: 'media',
        payload: {
          filter: {
            refId: ctx.mainResourceRecord.id,
            refType: 'item',
          },
        },
      },
      {
        onSuccess: (res) => {
          const results = res.data
            .filter((r) => r.fkMediaRole === 11)
            .sort((m1, m2) => m1.order - m2.order);
          setMedias(results);
        },
        onFailure: (err) => {
          console.error(err);
          notify('Non è stato possibile caricare i media.', 'error');
          setMedias([]);
        },
      },
    );

  useEffect(() => {
    if (medias && medias.length > 0) {
      const choices = [{ id: -1, url: '-' }];
      medias.forEach((m) => {
        choices.push({ id: m.id, url: m.url });
      });
      setChoices(choices);
    } else {
      setChoices([]);
    }
  }, [medias]);

  const ImageField = (props) => {
    return (
      <div style={{ width: '500px' }}>
        {props.record.id === -1 ? (
          <label>Nessuna selezione</label>
        ) : (
          <img
            src={props.record.url}
            height={'100px'}
            style={{ marginTop: '10px', marginLeft: '5px' }}
            alt="Preview"
            onMouseLeave={() => {
              setZoomedImage(null);
            }}
            onMouseOut={() => {
              setZoomedImage(null);
            }}
            onMouseOver={() => {
              setZoomedImage(props.record.url);
            }}
            onMouseDown={() => {
              setZoomedImage(null);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <AppDrawerForm
      anchor={anchor}
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      saveBtn={true}
      deleteBtn={true}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        delete data.values;
        delete data.fkMedia;
        delete data.allStocksHandled;
        delete data.handledStockQuantity;
        if (data.chosenImageId === -1) {
          delete data.chosenImageId;
        }
        for (let i = 0; i < variantChoices.length; i++) {
          if (data[variantChoices[i].name] === '-1' || data[variantChoices[i].name] === null) {
            delete data[variantChoices[i].name];
          }
        }

        ctx.manager.submit(data, close);
      }}
      onRemove={(data, close) => ctx.manager.remove(data, close)}
      record={ctx.item}
      mode={ctx.manager.mode}
      {...others}
    >
      <AppCard title="Giacenza" expanded>
        <AppNumberInput source="quantity" label="Quantità" md={3} required />
        {variantChoices.map((v) => {
          const choices = v.values.map((val) => {
            return { id: val.value, name: val.value };
          });
          return (
            <AppSelectInput
              id={v.id}
              label={v.name}
              source={v.name}
              choices={choices}
              md={12}
              defaultValue={
                ctx.item?.values?.find((values) => values.name === v.name)?.value ?? null
              }
              required
              TransitionProps={{ enter: false, exit: false }}
            />
          );
        })}
        {variantChoices.length > 0 && (
          <RadioButtonGroupInput
            source="chosenImageId"
            choices={choices}
            label="Immagine associata"
            optionText={<ImageField />}
            required={false}
            defaultChecked={false}
            defaultValue={ctx.item?.fkMedia ?? -1}
          />
        )}

        {zoomedImage && (
          <div
            style={{
              position: 'fixed',
              right: '2%',
              top: '20%',
              height: '70%',
              zIndex: 1000,
              backgroundColor: 'black',
            }}
          >
            <img
              src={zoomedImage}
              height={'400px'}
              width={'550px'}
              style={{
                objectFit: 'contain',
              }}
              alt="Preview"
              onMouseOut={() => {
                setZoomedImage(null);
              }}
            />
          </div>
        )}
      </AppCard>
    </AppDrawerForm>
  );
};

export default AppBuyNowItemStocksManager;
