import { makeStyles } from '@material-ui/core/styles';
import { Record as RaRecord } from 'ra-core/esm/types';
import React, { FC, useCallback } from 'react';
import { useMutation, useNotify } from 'react-admin';
import AuctionManagementButton from '../auction-management-button';

const useStyles = makeStyles(
  (theme) => ({
    mailToOwnerButton: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  }),
  { name: 'MailToOwnerButton' },
);

interface MailToOwnerButtonProps {
  record: RaRecord;
}

const MailToOwnerButton: FC<MailToOwnerButtonProps> = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  // mail to owner handler
  // TODO: disable button if owner has registered
  const [mailToOwner] = useMutation();
  const handleMailToOwner = useCallback(() => {
    mailToOwner(
      {
        type: 'getOne',
        resource: `sale-experiments-private/${props.record.id}/owner-registration-email`,
        payload: {},
      },
      {
        onSuccess: () => notify('Mail inviata correttamente al proprietario del lotto.', 'info'),
        onFailure: (err) => {
          console.error(err);
          notify(err.message ? err.message : "Errore nell'invio della mail", 'error');
        },
      },
    );
  }, []);

  return (
    <AuctionManagementButton
      caption="Invia mail a proprietario"
      clickBtnHandler={handleMailToOwner}
      color="primary"
      variant="contained"
      className={classes.mailToOwnerButton}
    />
  );
};

export default MailToOwnerButton;
