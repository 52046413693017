import { FC } from 'react';
import { ListProps } from 'react-admin';
import ExportToCsvButton from '../../Components/booking/export-to-csv-button';
import AppList from '../../Components/ui/detail/AppList';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppSelectInput,
} from '../../Components/ui/input';
import AppFiller from '../../Components/ui/layout/AppFiller';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import BookingDatagrid from './BookingDatagrid';
import BookingExpanded from './BookingExpanded';
import { ITEM_GENRE_CHOICES } from './constants';

const BookingListFilters = (
  <AppListFilter helperText="Nominativo">
    <AppDateInput source="bookingsFrom" label="Prenotazioni dal" md={2} />
    <AppDateInput source="bookingsTo" label="al" md={2} />
    <AppSelectInput source="genre" choices={ITEM_GENRE_CHOICES} label="Genere" md={1} />
    <AppFiller />
    <AppAutocompleteInput
      label="Richiedente"
      source="registryId"
      resettable
      reference="bookings/get-people-and-companies"
      optionText={(record) => {
        if (!record) return '';
        return `${record.businessName} (${record.taxCode ?? record.vat ?? '-'})`;
      }}
      md={3}
    />
    <AppFiller />
    <AppBooleanInput source="excludeAlreadyExported" label="Escludi già esportate" md={2} />
  </AppListFilter>
);

const BookingListFiltersForAgencies = (
  <AppListFilter helperText="Nominativo">
    <AppDateInput source="bookingsFrom" label="Prenotazioni dal" md={2} />
    <AppDateInput source="bookingsTo" label="al" md={2} />
    <AppFiller />
    <AppAutocompleteInput
      label="Richiedente"
      source="registryId"
      resettable
      reference="bookings/get-people-and-companies"
      optionText={(record) => {
        if (!record) return '';
        return `${record.businessName} (${record.taxCode ?? record.vat ?? '-'})`;
      }}
      md={3}
    />
    <AppFiller />
    <AppBooleanInput source="excludeAlreadyExported" label="Escludi già esportate" md={2} />
  </AppListFilter>
);

const BookingList: FC<ListProps> = (props) => {
  return (
    <AppList
      {...props}
      title={<AppTitle title="Prenotazioni" />}
      hideCreateButton
      ToolbarActions={<ExportToCsvButton />}
      filters={props.permissions?.agency ? BookingListFiltersForAgencies : BookingListFilters}
    >
      <BookingDatagrid expand={<BookingExpanded />} />
    </AppList>
  );
};

export default BookingList;
