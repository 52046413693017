import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import styles from '../styles';

type PdfSectionProps = {
  subtitle: string;
  children: JSX.Element | JSX.Element[];
};

export const PdfSection: FC<PdfSectionProps> = (props) => (
  <View style={styles.dataBlock} wrap={false}>
    <View style={styles.subtitle}>
      <Text>{props.subtitle}</Text>
    </View>
    {props.children}
  </View>
);
