import { makeStyles } from '@material-ui/core/styles';
import { assign as _assign } from 'lodash';
import { createElement, FC, Fragment, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import AppAlert from '../ui/alert/AppAlert';
import AppCard from '../ui/card/AppCard';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../ui/input';

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'AppPublicationMetadata' },
);

const AppPublicationMetadata: FC<any> = (props) => {
  const { item, property, record, mode, typologyType, itemTitle } = props;
  const classes = useStyles();
  const { change } = useForm();
  const { values } = useFormState();

  // Parse property's metadata
  const [metadata, setMetadata] = useState<Record<string, any>>({});
  useEffect(() => {
    if (property.metadata) {
      setMetadata(JSON.parse(property.metadata));
    } else {
      setMetadata({});
    }
  }, [property]);

  // set extra field input component and related props
  const handleExtraFieldComponent = (field: Record<string, any>): [Record<string, any>, any] => {
    const props = {
      source: `extraFields.${field.name}`,
      label: field.label,
      defaultValue: field.name === 'titolo' ? itemTitle : field.default,
      required: field.name !== 'data_pubblicazione' ? field.mandatory : false,
      variant: 'outlined',
      key: field.name,
    };

    let InputComponent;

    switch (field.type) {
      case 'text':
        InputComponent = AppTextInput;
        break;
      case 'textarea':
        InputComponent = AppTextInput;
        _assign(props, {
          multiline: true,
          rows: 3,
          md: 12,
        });
        break;
      case 'checkbox':
        InputComponent = AppBooleanInput;
        _assign(props, {
          initialValue: field.default,
          md: 3,
        });
        break;
      case 'date':
        InputComponent = AppDateInput;
        _assign(props, {
          md: 4,
        });
        break;
      case 'amount':
        InputComponent = AppNumberInput;
        _assign(props, {
          isAmount: true,
          md: 4,
        });
        break;
      case 'select':
        InputComponent = AppSelectInput;
        _assign(props, {
          choices: field.options,
          optionText: 'label',
          optionValue: 'value',
        });
        break;
    }

    return [props, InputComponent];
  };

  const [selectedCategory, setSelectedCategory] = useState<Record<string, any>>({});

  useEffect(() => {
    if (mode === 'insert') {
      // reset metadata fields
      change('categoriesMapping', {});
      setSelectedCategory({});
      change('extraFields', {});
    }
  }, [values.fkProperty]);

  useEffect(() => {
    if (mode === 'insert') {
      autocompleteCategory();
    } else {
      // in edit mode restore selected category to render subcategory selection
      if (
        metadata.categories &&
        metadata.categories.length &&
        record.categoriesMapping?.subcategory
      ) {
        metadata.categories.forEach((category) => {
          if (category.sub) {
            for (let i = 0; i < category.sub.length; i++) {
              if (category.sub[i].key === record.categoriesMapping?.subcategory?.toString()) {
                handleSelectedCategory(category);
                change('categoriesMapping.category', category.key);
                change('categoriesMapping.subcategory', category.sub[i].key);
                break;
              }
            }
          }
        });
      } else {
        autocompleteCategory();
      }
    }
  }, [metadata]);

  const autocompleteCategory = () => {
    //if typology matches mainCat inside a category choice (if exists) --> set the categories automatically
    if (metadata.categories && metadata.categories.length) {
      let categ;
      const matchingTypology = metadata?.mapping?.find((category) => category.key === typologyType);
      if (matchingTypology) {
        metadata.categories.forEach((category) => {
          if (category.sub) {
            for (let i = 0; i < category.sub.length; i++) {
              if (category.sub[i].key === matchingTypology.value.toString()) {
                handleSelectedCategory(category);
                change('categoriesMapping.category', category.key);
                categ = category.label;
                change('categoriesMapping.subcategory', category.sub[i].key);
                change('extraFields.immoPenthouse', item.realEstate.hasPenthouse);
                break;
              }
            }
          }
        });
      }
      if (!categ && metadata.categories && metadata.categories.length > 0) {
        handleSelectedCategory(metadata.categories[0]);
        change('categoriesMapping.category', metadata.categories[0].key);
        change('categoriesMapping.subcategory', metadata.categories[0].sub[0].key);
      }
    }
  };

  const handleSelectedCategory = (category) => {
    if (!category.sub) {
      change('categoriesMapping.subcategory', undefined);
    }
    setSelectedCategory(category);
  };

  return (
    <Fragment>
      <AppCard title="Campi aggiuntivi" expanded>
        {metadata.extraFields && metadata.extraFields.length ? (
          metadata.extraFields.map((extraField) => {
            const [props, InputComponent] = handleExtraFieldComponent(extraField);
            return createElement(InputComponent as any, props);
          })
        ) : (
          <AppAlert variant="filled" severity="info" className={classes.alert}>
            Non sono presenti campi aggiuntivi
          </AppAlert>
        )}
      </AppCard>
      <AppCard title="Categorie" expanded>
        {metadata.categories && metadata.categories.length ? (
          <AppAutocompleteInput
            source="categoriesMapping.category"
            choices={metadata.categories}
            optionValue="key"
            optionText="label"
            label="Categoria"
            onSelect={handleSelectedCategory}
            required
          />
        ) : (
          <AppAlert variant="filled" severity="info" className={classes.alert}>
            Non sono presenti categorie
          </AppAlert>
        )}
        {Object.keys(selectedCategory).length && Object.keys(selectedCategory).includes('sub') && (
          <AppAutocompleteInput
            source="categoriesMapping.subcategory"
            choices={selectedCategory.sub}
            optionValue="key"
            optionText="label"
            label="Sottocategoria"
            required
          />
        )}
      </AppCard>
    </Fragment>
  );
};

export default AppPublicationMetadata;
