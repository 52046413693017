import { FC } from 'react';
import { ArrayInput, required, SimpleFormIterator, TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { PageSection } from '../../utils/constants';
import { AppRichTextInput } from '../ui/input';
import { AppImageInput } from '../ui/input/AppImageInput';

interface Props {
  formSource: string;
  formLabel: string;
}

const AppPageSectionMultipleWithImageForm: FC<Props> = (props: Props) => {
  const { formSource, formLabel } = props;
  const { values } = useFormState();

  return (
    <ArrayInput source={formSource} label={formLabel} validate={[required()]}>
      <SimpleFormIterator
        disableAdd={
          values.type === PageSection.InfoTriple &&
          values.infoTriples &&
          values.infoTriples.length === 3
        }
      >
        <TextInput source="title" label="Titolo" variant="outlined" />
        <AppRichTextInput source="text" label="Testo" />
        <AppImageInput
          source="imageFile"
          accept="image/png,image/jpeg"
          label="Immagine"
          previewSource="imageUrl"
          previewLabel="Immagine corrente"
          isRequired
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default AppPageSectionMultipleWithImageForm;
