import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import AppDrawerForm from '../../Components/drawer-form/drawer-form';
import { AppRelatedResourceContext } from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import { AppAutocompleteInput, AppTextInput } from '../../Components/ui/input';

const PersonRoleManager: FC<PersonRoleManagerProps> = (props) => {
  const ctx = useContext(AppRelatedResourceContext);
  const { anchor, personType, ...others } = props;

  const [roleChoices, setChoices] = useState([]);
  const [judiciary, setJudiciary] = useState({});

  const [mutateRoles] = useMutation();

  useEffect(() => {
    //console.log('USE EFFECT');
    mutateRoles(
      {
        type: 'getList',
        resource: 'roles',
        payload: {
          filter: {
            [personType]: true,
          },
          sort: { field: 'description', order: 'ASC' },
        },
      },
      {
        onSuccess: (res) => {
          setChoices(res.data);
          const role: any = roleChoices.find((r) => (r as any).id === ctx.item.fkRole);
          setJudiciary((role && role.judiciary) ?? false);
        },
        onFailure: () => setChoices([]),
      },
    );
    //return () => {
    //  console.log('CLEANUP');
    //};
  }, [ctx.item]);

  return (
    <AppDrawerForm
      anchor={anchor}
      width="45rem"
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      onSubmit={ctx.manager.submit}
      record={ctx.item}
      title={ctx.manager.mode === 'edit' ? 'Modifica ruolo' : 'Aggiungi ruolo'}
      {...others}
      mode={ctx.manager.mode}
    >
      <AppCard expanded>
        <AppAutocompleteInput
          label="Ruolo"
          source="fkRole"
          md={12}
          choices={roleChoices}
          optionText="description"
          onSelect={(data) => setJudiciary(data.judiciary)}
        />
        {judiciary && (
          <AppAutocompleteInput
            label="Tribunale"
            source="fkCourt"
            md={12}
            reference="courts"
            optionText="name"
          />
        )}
        <AppTextInput source="notes" label="Note" multiline md={12} />
      </AppCard>
    </AppDrawerForm>
  );
};

export default PersonRoleManager;

export interface PersonRoleManagerProps {
  anchor: 'top' | 'right' | 'bottom' | 'left';
  personType: 'naturalPerson' | 'juridicalPerson';
}
