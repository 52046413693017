import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, Fragment, useContext } from 'react';
import { SaleMode } from '../../utils/constants';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: -theme.spacing(5.5),
    },
  }),
  { name: 'ParticipationAddButton' },
);

const ParticipationAddButton: FC<any> = (props) => {
  const { observer, a107 } = props;
  const classes = useStyles();
  const ctx = useContext(AppRelatedResourceContext);
  const { mainResourceRecord: saleExperiment } = ctx;

  if (saleExperiment.fkSaleMode !== SaleMode.SyncMixed) return <Fragment />;

  if (a107) {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          ctx.manager.setMode('insert');
          ctx.manager.open();
        }}
        children="Inserimento offerta dopo aggiudicazione"
        className={classes.button}
        disabled={
          !(saleExperiment.auctionStatus === 'completed' && !saleExperiment.unsuccessfulAuction)
        }
      />
    );
  }

  if (['inactive', 'active', 'completed', 'settled'].includes(saleExperiment.auctionStatus))
    return <React.Fragment />;

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => {
        ctx.manager.setMode('insert');
        ctx.manager.open();
      }}
      children={observer ? 'Inserimento osservatore' : 'Inserimento offerta cartacea'}
      disabled={saleExperiment.auctionStatus !== 'authorization'}
      className={classes.button}
    />
  );
};

export default ParticipationAddButton;
