import { Divider, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles(
  (theme: Theme) => ({
    divider: (props: Record<string, any>) => ({
      flex: '1 1 100%',
      marginLeft: -theme.spacing(1),
      marginRight: -theme.spacing(1),
      marginBottom: theme.spacing(props.marginBottom),
    }),
  }),
  { name: 'AppDivider' },
);

interface AppDividerProps {
  flex?: boolean;
  marginBottom?: number;
  className?: string;
}

const AppDivider: FC<AppDividerProps> = (props) => {
  const { flex = true, marginBottom = 2, className } = props;
  const classes = useStyles({
    marginBottom,
  });
  return <Divider className={className ? className : flex ? classes.divider : ''} />;
};

export default AppDivider;
