import FooterSlidesIcon from '@material-ui/icons/BorderBottom';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import FooterSlideDetails from './FooterSlideDetails';
import FooterSlideList from './FooterSlideList';

export const FOOTER_SLIDE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const FooterSlide: ResourceExport = {
  list: FooterSlideList,
  create: resourceWrapper(
    'create',
    FooterSlideDetails,
    undefined,
    undefined,
    FOOTER_SLIDE_PERMISSIONS,
  ),
  edit: resourceWrapper('edit', FooterSlideDetails, undefined, undefined, FOOTER_SLIDE_PERMISSIONS),
  show: resourceWrapper('show', FooterSlideDetails, undefined, undefined, FOOTER_SLIDE_PERMISSIONS),
  icon: FooterSlidesIcon,
};
export default FooterSlide;
