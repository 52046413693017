import { assign as _assign } from 'lodash';
import React, { FC } from 'react';
import { DateField, DateFieldProps } from 'react-admin';

type DateFieldCustomProps = DateFieldProps & {
  showSeconds?: boolean;
  showMilliseconds?: boolean;
};

export const AppDateField: FC<DateFieldCustomProps> = (props) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  if (props.showTime) {
    _assign(options, timeOptions);
  }

  if (props.showSeconds) {
    _assign(options, { second: '2-digit' });
  }

  if (props.showMilliseconds) {
    _assign(options, { fractionalSecondDigits: 3 });
  }

  return <DateField {...props} options={options} emptyText="-" />;
};

export default AppDateField;
