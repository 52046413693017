import React, { FC } from 'react';
import { Button, useRedirect, useRefresh } from 'react-admin';
import { useHistory } from 'react-router';

const AppCancelButton: FC<any> = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { goBack, resourceLabel, previousResourcePath, mode, ...rest } = props;
  const history = useHistory();

  const buttonText = goBack
    ? `Torna a ${resourceLabel}`
    : mode === 'show'
    ? 'Torna alla lista'
    : 'Annulla e torna alla lista';

  const handleBackToList = () => {
    redirect('list', props.basePath);
    refresh();
  };

  return (
    <Button
      variant='outlined'
      color='default'
      onClick={() => (goBack ? history.push(previousResourcePath) : handleBackToList())}
      label={buttonText}
      size='medium'
      {...rest}
    />
  );
};

export default AppCancelButton;
