import { Button, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ImportParticipationIcon from '@material-ui/icons/SystemUpdateAlt';
import React, { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-admin';
import { useBasePath } from '../../../hooks/use-base-path';
import { useToggleState } from '../../../hooks/use-toggle-state';
import { PersonRoles, SaleMode } from '../../../utils/constants';
import { getReference } from '../../../utils/reference-selector';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import AppAlert from '../../ui/alert/AppAlert';
import { AppDialog } from '../../ui/dialog';
import { AppBold, AppText } from '../../ui/text';
import { ParticipationsToImportList } from './participations-to-import-list';

const useStyles = makeStyles(
  (theme) => ({
    dialogContainer: {
      maxWidth: 'none',
      width: theme.spacing(160),
      height: theme.spacing(70),
    },
    description: {
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: -theme.spacing(5.5),
      marginRight: theme.spacing(45),
    },
  }),
  { name: 'ParticipationAddButton' },
);

export const ImportParticipationButton: FC<any> = React.memo<any>(({ rest: props }) => {
  const classes = useStyles();

  const basePath = useBasePath();

  const [importDialogOpen, toggleImportDialog] = useToggleState();

  const ctx = useContext(AppRelatedResourceContext);
  const { mainResourceRecord: saleExperiment } = ctx;
  const [enabled, setEnabled] = useState<boolean>(false);

  const isAuctioneer = useMemo(
    () => props.permissions?.roleId === PersonRoles.Auctioneer,
    [props.permissions],
  );
  const [mutateParticipationImport] = useMutation();
  const getParticipationImport = () => {
    mutateParticipationImport(
      {
        type: 'getOne',
        resource: `${basePath}/${saleExperiment.id}/participation-import`,
        payload: {},
      },
      {
        onSuccess: ({ data }) => {
          setEnabled(
            data.previousSaleExperimentA107 &&
              !isAuctioneer &&
              saleExperiment.auctionStatus !== 'inactive' &&
              data.anySaleExpAwarded &&
              !data.isFirstSaleExperiment,
          );
        },
        onFailure: (err) => {
          setEnabled(false);
          console.error(err);
        },
      },
    );
  };

  useEffect(() => {
    if (
      [SaleMode.AsyncOnline, SaleMode.SyncMixed, SaleMode.SyncOnline].includes(
        saleExperiment.fkSaleMode,
      )
    )
      getParticipationImport();
  }, [saleExperiment]);

  if (!enabled) return <Fragment />;

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={toggleImportDialog}
        children="Importa partecipanti"
        startIcon={<ImportParticipationIcon />}
        className={classes.button}
      />
      <AppDialog
        title="Gestione importazione partecipanti art.107 e vincitore precedente gara"
        open={importDialogOpen}
        onClose={toggleImportDialog}
        content={
          <>
            <AppText className={classes.description}>
              Di seguito la lista dei <AppBold>partecipanti art.107</AppBold> iscritti al termine
              della precedente gara che possono essere importati.
              <br />
              Se presente e non ancora importato{' '}
              <AppBold>
                è incluso anche il vincitore, evidenziato in{' '}
                <span style={{ color: green[500] }}>verde</span>
              </AppBold>{' '}
              .
            </AppText>
            <AppAlert severity="warning" title="Attenzione" className={classes.alert}>
              Prima di eseguire l'importazione assicurarsi che la pubblicazione principale del lotto
              abbia <AppBold> il flag "Gestisce la vendita" abilitato</AppBold> nella{' '}
              <a
                href={`#/${getReference(props.resource, 'items')}/${
                  saleExperiment.fkItem
                }/publications`}
                target="_blank"
              >
                APPOSITA SCHEDA
              </a>
              .
            </AppAlert>
            <ParticipationsToImportList
              baseResource={props.resource}
              saleExpId={saleExperiment.id}
            />
          </>
        }
        actions={<Button onClick={toggleImportDialog}>Chiudi</Button>}
        classes={{ paper: classes.dialogContainer }}
      />
    </>
  );
});
