import { blue, green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import { FC } from 'react';
import AppAlert from '../ui/alert/AppAlert';
import { AppText } from '../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'MagazineListLegend' },
);

export const MagazineListLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: yellow[600] }} />
        In attesa di generazione
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: blue[300] }} />
        Generazione in corso
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: green[300] }} />
        Generato con successo
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: red[400] }} />
        Errore di generazione
      </AppText>
    </AppAlert>
  );
};
