import { Button, InputAdornment } from '@material-ui/core';
import { UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput';
import { FC, ReactElement } from 'react';
import { TextInput, Validator } from 'react-admin';
import { useToggleState } from '../../../hooks/use-toggle-state';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput } from '../utils';

export const AppTextInput: FC<AppTextInputProps> = (props: AppTextInputProps): ReactElement => {
  const {
    required,
    validate: customValidators,
    source,
    expandable,
    rows = 5,
    rowsMax = 12,
    ...rest
  } = props;

  const [expanded, toggleExpand] = useToggleState(); // In case of multiline

  return gridWrappedInput(
    { ...rest, rows: expanded ? rowsMax : rows, variant: 'outlined' },
    <TextInput
      source={source}
      validate={getValidators(customValidators as Validator[], required && !props.disabled)}
      InputProps={
        expandable && props.multiline
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    children={expanded ? <UnfoldLess /> : <UnfoldMore />}
                    onClick={toggleExpand}
                  />
                </InputAdornment>
              ),
            }
          : {}
      }
    />,
  );
};

export type AppTextInputProps = TextInputProps &
  AppGridProps & {
    expandable?: boolean; // Allow multiline text to be enlarged
  };
