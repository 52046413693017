import { Grid } from '@material-ui/core';
import React, { FC, Fragment, useMemo } from 'react';
import { usePermissions } from 'react-admin';
import AppGoToResourceButton from '../../../Components/ui/button/AppGoToResourceButton';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppAmountCalculator,
  AppAutocompleteInput,
  AppBooleanInput,
  AppSelectArrayInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { useCmsConfiguration } from '../../../hooks/use-cms-configuration';
import { User } from '../../../types/user.type';
import {
  ConfigurationAgencyGenre,
  SECURITY_DEPOSIT_TYPES,
  SaleMode,
} from '../../../utils/constants';
import { isDetailComponentVisible } from '../../../utils/sale-experiment';
import { FeeProfileType } from '../../FeeProfile/enums';
import { PAYMENT_METHODS } from '../../Payment/constants';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';
const IVG_NAME = process.env.REACT_APP_IVG_NAME;

const WHERE_IS_VISIBLE = [
  SaleMode.AtSeller,
  SaleMode.SyncOnline,
  SaleMode.SyncMixed,
  SaleMode.AsyncOnline,
  SaleMode.OffersCollection,
  SaleMode.ExpressionOfInterest,
  SaleMode.WithAuction,
];

export const AuctionFee: FC<any> = React.memo(({ formData, mode, record }) => {
  const { fkSaleMode } = formData;

  const { permissions: user } = usePermissions<User>();

  const cmsConfig = useCmsConfiguration();
  const paymentsBeneficiaryChoices = useMemo(
    () =>
      user?.agency
        ? [
            {
              id: 'agency',
              name:
                user?.agency?.genre === ConfigurationAgencyGenre.RealEstate
                  ? 'Agenzia immobiliare'
                  : user?.agency?.genre === ConfigurationAgencyGenre.Movable
                  ? 'Agenzia mobiliare'
                  : 'Agenzia',
            },
          ]
        : [
            { id: 'cda', name: IVG_NAME ?? 'Casa delle Aste' },
            {
              id: 'procedure',
              name:
                cmsConfig?.websiteCustomization?.Generic?.WireTransfersBeneficiary ??
                'La procedura',
            },
          ],
    [cmsConfig, user],
  );

  const isVisible = useMemo(
    () => isDetailComponentVisible(fkSaleMode, WHERE_IS_VISIBLE),
    [fkSaleMode],
  );

  const disabled = useMemo(() => mode === 'show', [mode]);

  return isVisible ? (
    <AppCard title="Cauzione" disabled={disabled}>
      <AppAutocompleteInput
        source="fkFeeProfile"
        reference="fee-profiles"
        label="Profilo cauzione"
        filter={{
          active: true,
          type: FeeProfileType.Manual,
        }}
        resettable
        md={12}
      />
      {formData.fkFeeProfile && (
        <Grid item>
          <AppGoToResourceButton
            destinationResourceName="fee-profiles"
            mode="show"
            destinationResourceId={formData.fkFeeProfile}
            buttonLabel="Dettagli profilo cauzione"
            size="medium"
            currentResourceLabel="Esperimento di vendita"
          />
        </Grid>
      )}
      {!formData.fkFeeProfile && (
        <>
          <AppBooleanInput label="Cauzione richiesta" source="hasFee" md={2} initialValue={true} />
          {formData.hasFee && (
            <Fragment>
              <AppSelectArrayInput
                source="paymentMethods"
                choices={PAYMENT_METHODS}
                label="Modalità di versamento cauzione"
                required
                md={10}
                variant="outlined"
                disabled={disabled}
              />
              <AppAmountCalculator
                source="depositPrice"
                label="Importo della cauzione"
                refToSource="depositPriceRefTo"
                refToChoices={[
                  { id: 'basePrice', name: 'Prezzo base' },
                  { id: 'minPrice', name: 'Prezzo minimo' },
                ]}
                refToExternalChoices={[
                  { id: 'depositPercentageOnFirstOffer', name: 'Importo offerto' },
                ]}
                refToDefaultValue="depositPercentageOnFirstOffer"
                required
                mode={mode}
                record={record}
                disabled={disabled}
              />
              {formData.paymentMethods?.includes('wire-transfer') && [
                <AppTextInput
                  source="ibanCode"
                  label="IBAN"
                  md={4}
                  required
                  variant="outlined"
                  disabled={disabled}
                />,
                <AppSelectInput
                  source="paymentsBeneficiary"
                  label="Beneficiario pagamenti"
                  choices={paymentsBeneficiaryChoices}
                  md={4}
                  required
                  variant="outlined"
                  disabled={disabled}
                  defaultValue={IS_IVG ? 'procedure' : undefined}
                />,
                <AppTextInput
                  source="bank"
                  label="Istituto bancario"
                  md={4}
                  required
                  variant="outlined"
                  disabled={disabled}
                />,
              ]}
              {formData.paymentMethods?.includes('other') && (
                <AppTextInput
                  source="otherPaymentMethod"
                  label="Altro metodo"
                  md={12}
                  required
                  variant="outlined"
                  disabled={disabled}
                />
              )}
              <AppSelectInput
                label="Informazioni sulla cauzione"
                source="securityDepositType"
                choices={SECURITY_DEPOSIT_TYPES}
                md={3}
                variant="outlined"
                disabled={disabled}
              />
            </Fragment>
          )}
        </>
      )}
    </AppCard>
  ) : (
    <Fragment />
  );
});
