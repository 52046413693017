import { makeStyles } from '@material-ui/core';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import { FC } from 'react';
import { SERVICE_PAYMENT_COLORS } from '../../utils/constants';
import AppAlert from '../ui/alert/AppAlert';
import { AppText } from '../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'InfoRequestPaymentLegend' },
);

export const InfoRequestPaymentLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: SERVICE_PAYMENT_COLORS.unpaid }} />
        Non pagato
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: SERVICE_PAYMENT_COLORS.paid }} />
        Pagato
      </AppText>
    </AppAlert>
  );
};
