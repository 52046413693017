import FrontEndUsersIcon from '@material-ui/icons/SupervisedUserCircle';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import FrontEndUsersImmoDetails from './FrontEndUsersImmoDetails';
import FrontEndUsersImmoList from './FrontEndUsersImmoList';

const { Auctioneer } = PersonRoles;

export const FRONT_END_USERS_IMMO_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const FrontEndUsersImmo: ResourceExport = {
  list: !IS_IVG ? FrontEndUsersImmoList : undefined,
  show: !IS_IVG
    ? resourceWrapper(
        'show',
        FrontEndUsersImmoDetails,
        undefined,
        undefined,
        FRONT_END_USERS_IMMO_PERMISSIONS,
      )
    : undefined,
  edit: !IS_IVG
    ? resourceWrapper(
        'edit',
        FrontEndUsersImmoDetails,
        undefined,
        undefined,
        FRONT_END_USERS_IMMO_PERMISSIONS,
      )
    : undefined,
  icon: FrontEndUsersIcon,
};

export default FrontEndUsersImmo;
