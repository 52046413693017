import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { BooleanField, ListContextProvider, SelectField } from 'react-admin';
import { useFormState } from 'react-final-form';
import { BIDDER_RECEIVING_METHODS, BIDDER_RIGHTS, BIDDER_TITLES } from '../../../utils/constants';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';
import HandleBidder from './HandleBidder';
import HandleBidderButton from './HandleBidderButton';
import RemoveBidderButton from './RemoveBidderButton';

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      margin: theme.spacing(2),
      width: '100%',
    },
    addBidderButton: {
      margin: theme.spacing(2),
    },
  }),
  { name: 'ParticipationBidders' },
);

export const useBidderDatagridStyles = makeStyles(
  (theme) => ({
    lastCol: {
      width: '100%',
    },
    others: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(15),
    },
  }),
  { name: 'ParticipationBiddersDatagrid' },
);

interface ParticipationBiddersProps {
  record?: Record<string, any>;
  disabled?: boolean;
}

const ParticipationBidders: FC<ParticipationBiddersProps> = (props) => {
  const classes = useStyles();
  const datagridStyles = useBidderDatagridStyles();
  const { disabled: hideButton } = props;

  const { values } = useFormState();

  const [open, setOpen] = useState<boolean>(false);
  const [disabledHandleBidderButton, setDisabledHandleBidderButton] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [currentBidder, setCurrentBidder] = useState<Record<string, any>>({});

  // Presenter flags handling
  useEffect(() => {
    if (values.presenterIsBidder) {
      if (!values.hasMultipleBidders) {
        setDisabledHandleBidderButton(true);
        return setAlertMessage(
          'Il presentatore è stato selezionato come unico offerente, gli eventuali offerenti precedentemente inseriti verranno rimossi al salvataggio.',
        );
      }
      setDisabledHandleBidderButton(false);
      return setAlertMessage(
        'Il presentatore ricopre anche il ruolo di offerente, nella tabella sottostante è possibile visualizzare gli eventuali altri offerenti.',
      );
    }

    if (!values.hasMultipleBidders && values.bidders.length >= 1) {
      setDisabledHandleBidderButton(true);
      return setAlertMessage(
        'La partecipazione ha un solo offerente: sarà salvato il primo offerente in lista e gli eventuali altri offerenti aggiunti saranno rimossi.',
      );
    }

    setDisabledHandleBidderButton(false);
    setAlertMessage('');
  }, [values.presenterIsBidder, values.hasMultipleBidders, values.bidders]);

  /// region BIDDERS LIST

  // list context
  const baseCtx = {
    resource: 'sale-experiments',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };

  const [listCtx, setListCtx] = useState<Record<string, any>>(baseCtx);
  useEffect(() => {
    setListCtx(
      _assign({}, baseCtx, {
        data: _keyBy(values.bidders, 'id'),
        ids: _map(values.bidders, 'id'),
        total: values.bidders.length,
        loaded: true,
        loading: false,
      }) as any,
    );
  }, [values.bidders]);

  /// endregion

  return (
    <React.Fragment>
      {alertMessage.length ? (
        <Alert severity="info" className={classes.alert} variant="filled">
          <Typography>{alertMessage}</Typography>
        </Alert>
      ) : (
        <span />
      )}
      <Grid container justify="flex-end">
        <Grid item hidden={hideButton}>
          <HandleBidderButton
            type="insert"
            setOpen={setOpen}
            setCurrentBidder={setCurrentBidder}
            disabledHandleBidderButton={disabledHandleBidderButton}
          />
        </Grid>
      </Grid>
      <ListContextProvider value={listCtx}>
        <AppDatagrid>
          <AppTextField source="firstName" label="Nome" />
          <AppTextField source="lastName" label="Cognome" />
          <AppTextField source="taxCode" label="Codice fiscale" />
          <AppTextField source="email" label="Email" />
          <AppTextField source="share" label="Quota" />
          <SelectField choices={BIDDER_RIGHTS} source="right" label="Diritto" />
          <SelectField choices={BIDDER_TITLES} source="title" label="Titolo" />
          <SelectField
            choices={BIDDER_RECEIVING_METHODS}
            source="receivingMethod"
            label="Metodo di ricezione"
            headerClassName={datagridStyles.others}
          />
          <BooleanField
            source="isPresenter"
            label="Presentatore"
            headerClassName={datagridStyles.lastCol}
          />
          {!hideButton && [
            <HandleBidderButton
              type="edit"
              setOpen={setOpen}
              setCurrentBidder={setCurrentBidder}
            />,
            <RemoveBidderButton />,
          ]}
        </AppDatagrid>
      </ListContextProvider>
      {!values.bidders.length ? (
        <Alert className={classes.alert} severity="warning">
          Non sono presenti offerenti
        </Alert>
      ) : (
        <span />
      )}
      <HandleBidder open={open} setOpen={setOpen} listCtx={listCtx} bidder={currentBidder} />
    </React.Fragment>
  );
};

export default ParticipationBidders;
