import { Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { FC } from 'react';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { AppBold, AppText } from '../ui/text';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}));

interface DetailPanelFieldProps extends GridProps {
  label: string;
  value: string | number | undefined;
  url?: string;
  onClick?: (e) => void;
  className?: string;
  emptyText?: string;
  isBoolean?: boolean;
  isAmount?: boolean;
  isDate?: boolean;
}

export const DetailPanelField: FC<DetailPanelFieldProps> = ({
  md = 6,
  label,
  value,
  url,
  onClick,
  className,
  emptyText = '-',
  isBoolean = false,
  isAmount = false,
  isDate = false,
}) => (
  <Grid item md={md} className={useStyles().container}>
    <AppText style={{ whiteSpace: 'pre-line' }} onClick={onClick} className={className}>
      <AppBold>{label.toUpperCase()}</AppBold>
      <AppText>
        {isBoolean ? (
          value === 'true' ? (
            <DoneIcon data-testid="true" fontSize="small" />
          ) : (
            <ClearIcon data-testid="false" fontSize="small" />
          )
        ) : isAmount && typeof value === 'number' ? (
          amountFormatter(value)
        ) : isDate && value ? (
          dateFormatter(value)
        ) : value ? (
          url ? (
            <a href={url} target="_blank">
              {value}
            </a>
          ) : (
            value
          )
        ) : (
          emptyText
        )}
      </AppText>
    </AppText>
  </Grid>
);
