import { makeStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { FC } from 'react';
import { FunctionField, ListProps } from 'react-admin';
import ChatbotExecuteImportButton, {
  ImportType,
} from '../../Components/chatbot-import/buttons/chatbot-execute-import-button';
import ChatbotImportListExpanded from '../../Components/chatbot-import/list/chatbot-import-list-expanded';
import ChatbotImportListLegend from '../../Components/chatbot-import/list/chatbot-import-list-legend';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { useAutomaticRefresh } from '../../hooks/use-automatic-refresh';
import { ImportState, ImportStates } from './constants';
import { CHATBOT_IMPORT_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    uuid: {
      whiteSpace: 'nowrap',
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    executedAt: {
      whiteSpace: 'nowrap',
    },
    mediaToImportCount: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      width: '100%',
    },
    showItemBtn: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ChatbotImportList' },
);

const ChatbotImportListFilters = (
  <AppListFilter>
    <AppSelectInput source="importState" choices={ImportStates} label="Stato" md={1} />
  </AppListFilter>
);

const ChatbotImportList: FC<ListProps> = (props) => {
  const classes = useStyles();

  useAutomaticRefresh();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Stato importazioni da chatbot" />}
      filters={ChatbotImportListFilters}
      permissions={CHATBOT_IMPORT_PERMISSIONS}
      hideCreateButton
      ToolbarActions={
        <ChatbotExecuteImportButton importType={ImportType.All} refreshAfterExecution />
      }
    >
      <AppDatagrid
        rowStyle={(record) => ({
          backgroundColor:
            record.importState === ImportState.Successful
              ? green[300]
              : record.importState === ImportState.Error
              ? red[300]
              : yellow[600],
        })}
        additionalInfo={<ChatbotImportListLegend />}
        expand={<ChatbotImportListExpanded />}
      >
        <AppTextField
          source="uuid"
          label="Id sul chatbot"
          cellClassName={classes.uuid}
          clickToCopy
        />
        <AppTextField
          source="relatedItem.title"
          label="Titolo lotto"
          truncate
          headerClassName={classes.itemTitle}
        />
        <AppDateField
          source="executedAt"
          label="Data/ora esecuzione"
          showTime
          cellClassName={classes.executedAt}
          headerClassName={classes.executedAt}
        />
        <FunctionField
          label="Media da importare"
          headerClassName={classes.mediaToImportCount}
          render={(record) => {
            let text;
            if (record.importState !== ImportState.Successful) {
              text = 'In attesa di importazione/aggiornamento del lotto';
            } else if (record.pendingMedia) {
              let mediaToImportCount = 0;
              for (const mediaArray of Object.values<any[]>(record.pendingMedia)) {
                mediaToImportCount += mediaArray.length;
              }
              text = mediaToImportCount;
            } else {
              text = 'Media importati';
            }
            return <AppTextField customText={text} truncate />;
          }}
        />
        <AppTextField source="notes" label="Note" headerClassName={classes.lastCol} />
        <FunctionField
          render={(chatbotImport) =>
            chatbotImport.importState !== ImportState.Pending && (
              <ChatbotExecuteImportButton
                importType={ImportType.Single}
                chatbotId={chatbotImport.uuid}
                refreshAfterExecution
              />
            )
          }
          headerClassName={classes.showItemBtn}
          cellClassName={classes.showItemBtn}
        />
        <FunctionField
          render={(record) =>
            record.relatedItem && (
              <AppItemLinksButton
                resource={props.resource ?? ''}
                record={record}
                itemIdSource="relatedItem.id"
                folderIdSource="relatedItem.fkFolder"
                currentResourceLabel="Importazioni da chatbot"
                size="small"
                color="secondary"
                variant="contained"
                forceJudiciaryTargetResource
              />
            )
          }
          headerClassName={classes.showItemBtn}
          cellClassName={classes.showItemBtn}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default ChatbotImportList;
