import { MonetizationOnSharp } from '@material-ui/icons';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import BuyNowVariantList from './BuyNowVariantList';
import BuyNowVariantDetails from './BuyNowVariantDetails';
import { resourceWrapper } from '../../Components/ui/utils';

export const BUY_NOW_VARIANT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const BuyNowVariant: ResourceExport = {
  list: BuyNowVariantList,
  create: resourceWrapper(
    'create',
    BuyNowVariantDetails,
    undefined,
    false,
    BUY_NOW_VARIANT_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    BuyNowVariantDetails,
    undefined,
    false,
    BUY_NOW_VARIANT_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    BuyNowVariantDetails,
    undefined,
    false,
    BUY_NOW_VARIANT_PERMISSIONS,
  ),
  icon: MonetizationOnSharp,
};

export default BuyNowVariant;
