import { Grid, makeStyles } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import {
  ChipField,
  DeleteWithConfirmButton,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import AppPageSectionManager from '../../../Components/page-section/app-page-section-manager';
import OrderSectionButtons from '../../../Components/page-section/order-section-buttons';
import AppPageSeoManager from '../../../Components/page-seo/app-page-seo-manager';
import AppRelatedResource, {
  AppRelatedResourceEditButton,
} from '../../../Components/related-resource/app-related-resource';
import AppCard from '../../../Components/ui/card/AppCard';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import { AppTextField } from '../../../Components/ui/field';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import { AppAutocompleteInput, AppBooleanInput, AppTextInput } from '../../../Components/ui/input';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import AppDivider from '../../../Components/ui/layout/AppDivider';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      width: theme.spacing(50),
    },
    type: {
      width: theme.spacing(50),
    },

    metaDescription: {
      width: theme.spacing(30),
    },
    keywords: {
      width: theme.spacing(30),
    },
    metaTitle: {
      width: theme.spacing(40),
    },
    alt: {
      whiteSpace: 'nowrap',
    },
    lastColumn: {
      width: '100%',
    },
  }),
  { name: 'PageDetails' },
);

const PageDetails = (props: Record<any, any>): ReactElement => {
  const classes = useStyles();

  const [seoEmpty, setSeoEmpty] = useState<boolean>(true);

  return (
    <AppBaseForm {...props}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppTextInput
          source="header"
          label="Titolo"
          md={12}
          disabled={props.mode === 'show'}
          required
        />
        {props.mode !== 'create' && <AppTextInput source="slug" label="Slug" md={5} disabled />}
        <AppTextInput
          source="language"
          label="Lingua"
          md={3}
          disabled={props.mode === 'show'}
          required
          defaultValue="it"
        />
        <AppBooleanInput
          source="hasMenu"
          label="Menu"
          md={3}
          disabled={props.mode === 'show'}
          required
        />
        {props.mode === 'show' ? (
          <Grid item md={12}>
            <AppSectionTitle>PROPRIETARI</AppSectionTitle>
            <ReferenceArrayField
              reference="person-sellers"
              source="personSellerIds"
              label="Proprietari"
            >
              <SingleFieldList>
                <ChipField source="description" style={{ marginBottom: '15px' }} />
              </SingleFieldList>
            </ReferenceArrayField>
          </Grid>
        ) : (
          <AppAutocompleteInput
            source="personSellerIds"
            reference="person-sellers"
            label="Proprietari"
            optionText={(option) => {
              if (!option) return '';
              return option.description;
            }}
            resettable
            variant="outlined"
            isArray
            md={12}
            filter={{
              schema: null,
            }}
          />
        )}
        <AppDivider />
      </AppCard>

      <AppCard title="Sezioni" expanded hideContent={props.mode === 'create'}>
        <AppRelatedResource
          relatedResource="/sections"
          manager={
            <AppPageSectionManager
              title={(mode) => (mode === 'insert' ? 'Crea nuova sezione' : 'Modifica sezione')}
              resource="page"
            />
          }
          actionsHidden={props.mode === 'show'}
        >
          <AppDatagrid>
            <FunctionField
              render={(record) => (
                <OrderSectionButtons
                  record={record}
                  disabled={props.mode === 'show'}
                  agency={true}
                />
              )}
            />

            <AppTextField source="title" label="Titolo" headerClassName={classes.title} />
            <ChipField source="type" label="Tipo" headerClassName={classes.type} />
            <FunctionField
              render={(record) => (
                <AppRelatedResourceEditButton
                  disabled={
                    record.refType === 'sections' || record.savedAs || props.mode === 'show'
                  }
                  record={record}
                />
              )}
            />
            <FunctionField
              render={(record) => (
                <DeleteWithConfirmButton
                  record={record}
                  disabled={record.refType === 'section' || record.savedAs || props.mode === 'show'}
                  redirect={false}
                  confirmTitle="Eliminazione sezione"
                  confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                />
              )}
            />
          </AppDatagrid>
        </AppRelatedResource>
      </AppCard>

      <AppCard title="SEO" expanded collapsable={false} hideContent={props.mode === 'create'}>
        <AppRelatedResource
          relatedResource="/page-seo"
          manager={
            <AppPageSeoManager
              title={(mode) => (mode === 'insert' ? 'Crea nuovo SEO' : 'Modifica SEO')}
              resource="page"
            />
          }
          onChange={(res) => setSeoEmpty(res.length === 0)}
          actionsHidden={props.mode !== 'edit' || !seoEmpty}
        >
          <AppDatagrid>
            <AppTextField source="metaTitle" label="Titolo" headerClassName={classes.metaTitle} />
            <AppTextField
              source="metaDescription"
              label="Descrizione"
              headerClassName={classes.metaDescription}
            />
            <AppTextField
              source="altImage"
              label="Testo alternativo immagine"
              headerClassName={classes.alt}
            />
            <AppTextField
              source="keywords"
              label="Parole chiavi"
              headerClassName={classes.keywords}
            />
            <FunctionField
              render={(record) => (
                <AppRelatedResourceEditButton
                  disabled={
                    record.refType === 'page-seo' || record.savedAs || props.mode === 'show'
                  }
                  record={record}
                />
              )}
            />
            <FunctionField
              render={(record) => (
                <DeleteWithConfirmButton
                  record={record}
                  disabled={
                    record.refType === 'page-seo' || record.savedAs || props.mode === 'show'
                  }
                  redirect={false}
                  confirmTitle="Eliminazione SEO"
                  confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                />
              )}
            />
          </AppDatagrid>
        </AppRelatedResource>
      </AppCard>
    </AppBaseForm>
  );
};

export default PageDetails;
