import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Description, PhotoCamera } from '@material-ui/icons';
import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { ArrayInput, FormDataConsumer, useNotify } from 'react-admin';
import AppDrawerFormTab from '../../Components/drawer-form/drawer-form-tab';
import AppDrawerTabbedForm from '../../Components/drawer-form/drawer-tabbed-form';
import DeleteMediaButton from '../../Components/media/delete-media-button';
import UploadMediaButton from '../../Components/media/upload/upload-media-button';
import UploadMediaManager from '../../Components/media/upload/upload-media-manager';
import ParticipationPerson from '../../Components/participation/ParticipationPerson';
import AttachmentsDrawer from '../../Components/participation/attachments/attachments-drawer';
import { AuthorizationButtons } from '../../Components/participation/authorization/authorization-buttons';
import ParticipationBidders from '../../Components/participation/bidders/ParticipationBidders';
import PresenterIsBidder from '../../Components/participation/bidders/PresenterIsBidder';
import PresenterIsDepositor from '../../Components/participation/depositor/PresenterIsDepositor';
import ParticipationOffer from '../../Components/participation/offer/ParticipationOffer';
import PvpOffer from '../../Components/participation/pvp/pvp-offer';
import AppRelatedResource, {
  AppRelatedResourceContext,
} from '../../Components/related-resource/app-related-resource';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppButtonGroup from '../../Components/ui/button/AppButtonGroup';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDownloadField from '../../Components/ui/field/AppDownloadField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppUpdatedByField from '../../Components/ui/field/AppUpdatedByField';
import { AppBooleanInput } from '../../Components/ui/input';
import { OriginType, SaleMode } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const useStyles = makeStyles(
  (theme) => ({
    fullWidth: {
      width: '100%',
    },
    mediaName: {
      minWidth: theme.spacing(50),
    },
    mediaDescription: {
      minWidth: theme.spacing(30),
    },
    alert: {
      margin: theme.spacing(1),
      width: '100%',
    },
  }),
  { name: 'ParticipantManager' },
);

const AppParticipantManager: FC<any> = ({
  anchor,
  observer,
  a107,
  newFrontEndParticipant,
  geocoderRef,
  minOffer,
  auctionStatus,
  isAsync,
  ...others
}) => {
  const ctx = useContext(AppRelatedResourceContext);
  const notify = useNotify();
  ctx.item.bidders = ctx.item.bidders ?? [];

  const participationIsNotFromPaper = useMemo(
    () => ctx.manager.mode === 'edit' && ctx.item.origin !== OriginType.Paper,
    [ctx.manager.mode, ctx.item.origin],
  );

  const editDisabled = participationIsNotFromPaper || auctionStatus === 'active';

  const [minOffertAlert, setMinOffertAlert] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [close, setClose] = useState<any>();

  useEffect(() => {
    if (ctx.item.authorizationErrors && ctx.item.authorizationErrors.length) {
      notify(
        ctx.item.authorizationErrors,
        'error',
        { title: 'Errori di autorizzazione partecipante:' },
        false,
        8000,
      );
    }
  }, [ctx.item]);

  useEffect(() => {
    if (!newFrontEndParticipant || !newFrontEndParticipant.participationType) return;

    // update participations list (standard / observer / a107) when new FE's participant is subscribed
    if (a107 && newFrontEndParticipant.participationType === 'a107') {
      return ctx.getRelatedResources();
    }
    if (observer && newFrontEndParticipant.participationType === 'observer') {
      return ctx.getRelatedResources();
    }

    ctx.getRelatedResources();
  }, [newFrontEndParticipant]);

  const exprOfInterestOrOffCollection = useMemo(
    () =>
      [SaleMode.OffersCollection, SaleMode.ExpressionOfInterest].includes(
        ctx.mainResourceRecord.fkSaleMode,
      ),
    [ctx.mainResourceRecord.fkSaleMode],
  );

  const classes = useStyles();

  const actionsDisabled = useMemo(() => {
    if (a107) return false;
    return auctionStatus !== 'authorization';
  }, [auctionStatus, a107]);

  if (ctx.manager.additionalParams.attachmentsDrawer) return <AttachmentsDrawer />;

  return (
    <>
      <AppDrawerTabbedForm
        anchor={anchor}
        open={ctx.manager.isOpen}
        onClose={ctx.manager.close}
        title={getFormTitle(ctx.manager.mode, observer, a107, ctx.item.origin).toUpperCase()}
        saveBtn
        deleteBtn={!participationIsNotFromPaper}
        onSubmit={(data, close) => {
          data.observer = observer;
          data.a107 = a107;
          if (data.pending) {
            notify(
              `Autorizza o rifiuta ${
                observer ? "l'osservatore" : 'il partecipante'
              } per continuare.`,
              'error',
            );
          } else {
            if (minOffer && data.amount < minOffer) {
              setMinOffertAlert(true);
              setData(data);
              setClose(close);
            } else ctx.manager.submit(data, close);
          }
        }}
        onRemove={(data, close) => ctx.manager.remove(data, close)}
        mode={ctx.manager.mode}
        {...others}
        record={ctx.item}
      >
        <AppDrawerFormTab
          tabTitle="Dati PVP"
          isHidden={
            !IS_IVG || !ctx.item.pvpOfferData || !Object.values(ctx.item.pvpOfferData).length
          }
        >
          <PvpOffer pvpOfferData={ctx.item.pvpOfferData} />
        </AppDrawerFormTab>
        <AppDrawerFormTab
          tabTitle={observer ? 'Dati osservatore' : 'Presentatore'}
          disabled={editDisabled}
        >
          <AuthorizationButtons
            exprOfInterestOrOffCollection={exprOfInterestOrOffCollection}
            observer={observer}
            actionsDisabled={actionsDisabled}
            isAsync={isAsync}
          />
          <ParticipationPerson
            type="presenter"
            participantChoiceSource="fkPresenter"
            participantChoiceLabel={
              observer
                ? 'Seleziona osservatore (se esistente)'
                : 'Seleziona presentatore (se esistente)'
            }
            geocoderRef={geocoderRef}
            participationIsNotFromPaper={participationIsNotFromPaper}
            actionsDisabled={actionsDisabled}
          />
          <AppCard
            title="Informazioni aggiuntive sulla partecipazione"
            expanded
            hidden={observer || exprOfInterestOrOffCollection}
          >
            <PresenterIsBidder />
            <PresenterIsDepositor />
            <AppBooleanInput
              source="hasMultipleBidders"
              label="Ci sono più offerenti"
              initialValue={false}
              md={4}
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.hasMultipleBidders && (
                  <AppAlert severity="warning" className={classes.alert}>
                    Controllare nell’Avviso di Vendita se il presentatore può rappresentare più
                    offerenti
                  </AppAlert>
                )
              }
            </FormDataConsumer>
          </AppCard>
        </AppDrawerFormTab>
        <AppDrawerFormTab tabTitle="Depositante" isHidden={observer} disabled={editDisabled}>
          <ParticipationPerson
            type="depositor"
            participantChoiceSource="depositor.id"
            participantChoiceLabel="Seleziona depositante (se esistente)"
            geocoderRef={geocoderRef}
            actionsDisabled={actionsDisabled}
          />
        </AppDrawerFormTab>
        <AppDrawerFormTab
          tabTitle="Offerente"
          isHidden={observer}
          disabled={participationIsNotFromPaper}
        >
          <AppCard title="Offerenti" expanded>
            <ParticipationBidders />
            {/*NOTE: needed by react-final-form to subscribe to the 'bidders' field and apply the 'pristine' check (or any other form-related checks)*/}
            <ArrayInput
              source="bidders"
              defaultValue={ctx.item.bidders}
              style={{ display: 'none' }}
            >
              <Fragment />
            </ArrayInput>
          </AppCard>
        </AppDrawerFormTab>
        <AppDrawerFormTab tabTitle="Offerta" isHidden={observer} disabled={editDisabled}>
          <ParticipationOffer
            ctx={ctx}
            participationIsNotFromPaper={participationIsNotFromPaper}
            a107={a107}
            auctionStatus={auctionStatus}
            actionsDisabled={actionsDisabled}
          />
        </AppDrawerFormTab>
        <AppDrawerFormTab tabTitle="Documenti allegati">
          <AppCard
            title="Caricamento allegati del partecipante"
            hideContent={ctx.manager.mode === 'insert' || !ctx.item.id}
            expanded
          >
            <AppRelatedResource
              resource="participations"
              relatedResource="/media"
              sort={{ field: 'metadata.mimeType', order: 'ASC' }}
              manager={
                <UploadMediaManager showAdditionalFields={false} /*showMagazineRole={false}*/ />
              }
              actions={[
                <AppButtonGroup title="Documenti" icon={<Description />} disabled={editDisabled}>
                  <UploadMediaButton mediaType="document" label="Caricamento" />
                </AppButtonGroup>,
                <AppButtonGroup title="Immagini" icon={<PhotoCamera />} disabled={editDisabled}>
                  <UploadMediaButton mediaType="image" label="Caricamento singolo" />
                  <UploadMediaButton
                    mediaType="image"
                    label="Caricamento multiplo"
                    multiple
                    role="Foto"
                  />
                </AppButtonGroup>,
              ]}
            >
              <AppDatagrid>
                <AppDownloadField
                  source="name"
                  label="Nome file"
                  headerClassName={classes.mediaName}
                />
                <AppTextField
                  source="description"
                  label="Titolo"
                  headerClassName={classes.mediaDescription}
                />
                <AppTextField
                  source="role.name"
                  label="Tipologia"
                  headerClassName={classes.fullWidth}
                />
                <UploadMediaButton
                  label="Modifica"
                  mode="edit"
                  mainResource="publications"
                  disabled={editDisabled}
                />
                <DeleteMediaButton disabled={editDisabled} />
              </AppDatagrid>
            </AppRelatedResource>
          </AppCard>
        </AppDrawerFormTab>
        <AppDrawerFormTab tabTitle="Informazioni aggiuntive">
          <AppCard
            hideContent={ctx.manager.mode === 'insert'}
            collapsable={false}
            title="Informazioni sul salvataggio"
          >
            <Grid item md={6}>
              <Typography align="center">
                Data/ora ultimo salvataggio{' '}
                <Box display="block">
                  <Chip
                    label={
                      ctx.item.updatedAt ? dateFormatter(ctx.item.updatedAt) : 'Non disponibile'
                    }
                  />
                </Box>
              </Typography>
            </Grid>
            <Grid item md={6}>
              <AppUpdatedByField record={ctx.item} />
            </Grid>
          </AppCard>
        </AppDrawerFormTab>
      </AppDrawerTabbedForm>

      <Dialog open={minOffertAlert}>
        <DialogTitle>Attenzione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            L'offerta che stai per inserire è più bassa del prezzo minimo. Sei sicuro di voler
            procedere lo stesso?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button children="NO" onClick={() => setMinOffertAlert(false)} />
          <Button
            children="SI"
            variant="contained"
            color="primary"
            onClick={() => {
              ctx.manager.submit(data, close);
              setMinOffertAlert(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const getFormTitle = (
  mode: 'insert' | 'edit' | null,
  observer: boolean,
  a107: boolean,
  origin: OriginType,
): string => {
  if (!mode) return 'Gestione partecipante';
  if (mode === 'insert') {
    if (observer) {
      return 'Inserimento osservatore';
    } else if (a107) {
      return 'Inserimento offerta cartacea (Art.107 L.F.)';
    } else {
      return 'Inserimento offerta cartacea';
    }
  } else {
    if (observer) {
      return 'Gestisci osservatore';
    } else if (a107) {
      return 'Gestisci offerta Art.107 L.F.';
    } else {
      if (origin !== OriginType.Paper) {
        return `Verifica iscrizione ${origin === OriginType.Pvp ? 'pvp' : 'online'}`;
      } else {
        return 'Modifica offerta cartacea';
      }
    }
  }
};

export default AppParticipantManager;
