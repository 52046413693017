import React, { FC, Fragment } from 'react';
import { useEditContext } from 'react-admin';
import { useFormState } from 'react-final-form';

const CaptureFormData: FC = () => {
  const editCtx = useEditContext();
  editCtx['$formStatus'] = useFormState();
  return <Fragment />;
};

export default CaptureFormData;
