import { useDashboardContext } from '../../../hooks';
import { AppBold, AppText } from '../../ui/text';
import AppDashboardCard from '../components/app-dashboard-card';

const AppDashboardTitle = (): JSX.Element => {
  const { adminName, user } = useDashboardContext();
  return (
    <AppDashboardCard md={12}>
      <AppText variant="h3" align="center">
        Pannello di amministrazione di <AppBold>{adminName}</AppBold>
      </AppText>
      <AppText variant="h4" align="center">
        Benvenuto/a, <strong>{user?.businessName ?? 'utente'}</strong>.
      </AppText>
      <AppText variant="h6" align="center">
        Hai eseguito l'accesso come{' '}
        <AppBold>
          {user?.roleName.toUpperCase() ?? 'UTENTE STANDARD'} {user?.agency ? '(Agenzia)' : ''}
        </AppBold>
        .
      </AppText>
      {/* 
        // TODO: fix this
      */}
      {/* <AppText variant="button">
        {ROLES_INFO.find((role) => role.id === user?.loginRole?.fkRole)?.description ?? ''}
      </AppText> */}
    </AppDashboardCard>
  );
};

export default AppDashboardTitle;
