import { Button, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, Fragment } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import { INFO_REQUEST_STATE_CHOICES } from '../../Resources/InfoRequest/constants';
import { useToggleState } from '../../hooks/use-toggle-state';
import { useUserIsAllowed } from '../../hooks/use-user-is-allowed';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import { AppSelectInput } from '../ui/input';

const useStyles = makeStyles(
  () => ({
    button: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'UpdateInfoRequestStatusButton' },
);

export const UpdateInfoRequestStatusButton: FC<any> = React.memo(({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [updateInfoRequestStatusDialogOpen, updateInfoRequestStatusDialogToggle] = useToggleState();

  const allowedToUpdate = useUserIsAllowed(ResourceAction.Put, 'info-requests');

  const [mutateInfoRequestStatus] = useMutation();
  const onInfoRequestStatusUpdateSubmit = (data) =>
    mutateInfoRequestStatus(
      {
        type: 'update',
        resource: `info-requests/${record.id}`,
        payload: {
          data,
        },
      },
      {
        onSuccess: () => {
          notify('Operazione conclusa con successo.');
          refresh();
          updateInfoRequestStatusDialogToggle();
        },
        onFailure: (err) => {
          console.error('Error updating info request status:', err);
          notify(`Operazione non riuscita: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`, 'error');
        },
      },
    );

  return (
    <Fragment>
      <Button
        children="Gestisci stato"
        onClick={updateInfoRequestStatusDialogToggle}
        className={classes.button}
        startIcon={<EditIcon />}
        variant="outlined"
        disabled={!allowedToUpdate}
      />
      <AppFormDialog
        open={updateInfoRequestStatusDialogOpen}
        onClose={updateInfoRequestStatusDialogToggle}
        title="Modifica stato richiesta informazioni"
        onSubmit={onInfoRequestStatusUpdateSubmit}
        record={{
          requestStatus: record.requestStatus,
        }}
        width={64}
        height={36}
        canSubmitIfPristine={record.requestStatus}
      >
        <AppSelectInput
          source="requestStatus"
          label="Stato"
          required
          choices={INFO_REQUEST_STATE_CHOICES}
          md={12}
        />
      </AppFormDialog>
    </Fragment>
  );
});
