import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

interface HandleBidderButtonProps {
  type: 'insert' | 'edit';
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBidder: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  disabledHandleBidderButton?: boolean;
  record?: Record<string, any>;
}

const useStyles = makeStyles(
  (theme) => ({
    addBidderButton: {
      margin: theme.spacing(2),
    },
  }),
  { name: 'HandleBidderButton' },
);

const HandleBidderButton: FC<HandleBidderButtonProps> = (props) => {
  const {
    type,
    setOpen,
    disabledHandleBidderButton,
    record: bidder = {},
    setCurrentBidder,
  } = props;
  const classes = useStyles();

  const handleClickOpen = () => {
    if (type === 'edit') {
      setCurrentBidder(bidder);
    } else {
      setCurrentBidder({});
    }
    setOpen(true);
  };

  return (
    <Button
      onClick={handleClickOpen}
      variant={type === 'insert' ? 'contained' : 'outlined'}
      color='primary'
      children={type === 'insert' ? 'Inserisci offerente' : 'Modifica'}
      disabled={
        (type === 'insert' && disabledHandleBidderButton) || (type === 'edit' && bidder.isPresenter)
      }
      className={classes.addBidderButton}
    />
  );
};

export default HandleBidderButton;
