import { AuthProvider } from 'react-admin';
import { ApiProvider } from './ApiProvider';
import AuthManager from './AuthManager';

const apiProvider = new ApiProvider();

export default {
  // called when the user attempts to log in
  login: ({ email, password }) => {
    return apiProvider
      .post<any>('auth/login', null, {
        email,
        password,
      })
      .then(({ data }) => {
        AuthManager.setAuthInfo(data.accessToken, data.user);
      })
      .catch((err) => Promise.reject(err));
  },
  // called when the user clicks on the logout button or checkError/checkAuth rejects the Promise
  logout: () => {
    return apiProvider
      .get('auth/logout', null)
      .then(() => AuthManager.destroyAuthInfo())
      .catch((err) => Promise.reject(err));
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (!AuthManager.getAuthToken()) {
      if (!(window.location.hash || '').startsWith('#/auth/create-password')) {
        window.location.replace('#/login');
      }
      return Promise.reject({ message: false }); // LOGOUT
    }

    // Update permissions
    const user = AuthManager.getUserInfo();
    if (user) {
      apiProvider
        .getOne<{ permissions: any[]; admin: boolean }>(
          `auth/permissions/${JSON.parse(user).roleId}`,
          null,
        )
        .then(({ data }) => {
          AuthManager.updatePermissions(data.permissions, data.admin);
        })
        .catch((err) => console.error(err));
    }

    return Promise.resolve(); // CONTINUE
  },
  // called whenever the API SERVER returns an error
  checkError: (error) => {
    if (error.status === 401) {
      if (!AuthManager.getAuthToken()) return Promise.resolve();
      else return Promise.reject({ message: false }); // reject --> LOGOUT
    }

    return Promise.resolve(); // resolve --> CONTINUE
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const user = AuthManager.getUserInfo();
    return user ? Promise.resolve(JSON.parse(user)) : Promise.reject();
  },
} as AuthProvider;
