import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useBasePath } from '../../../hooks/use-base-path';
import {
  AuctionFee,
  AuctionGeneral,
  AuctionInfo,
  AuctionVisit,
  ExpressionOfInterest,
  OffersCollection,
} from './';
import BuyItNow from './BuyItNow';
import Retail from './Retail';

interface SaleExperimentAuctionDetailsProps {
  mode: string;
  record: Record<string, any>;
  resource: string;
  cannotModify: boolean;
}

export const SaleExperimentAuctionDetails: FC<SaleExperimentAuctionDetailsProps> = (props) => {
  const { values: formData } = useFormState();
  const notify = useNotify();
  const basePath = useBasePath();

  const [selectedItem, setSelectedItem] = useState<Record<string, any>>({});
  const [lastSaleExperiment, setLastSaleExperiment] = useState<Record<string, any>>();

  const [getSelectedItem] = useMutation();
  const [getLastSaleExperiment] = useMutation();
  useEffect(() => {
    if (formData.fkItem) {
      getSelectedItem(
        {
          type: 'getOne',
          resource: 'items',
          payload: {
            id: formData.fkItem,
          },
        },
        {
          onSuccess: (res) => setSelectedItem(res.data),
          onFailure: (err) => {
            notify('Errore nella ricezione dei dati del lotto selezionato.', 'error');
            setSelectedItem({});
            console.error(err);
          },
        },
      );
      getLastSaleExperiment(
        {
          type: 'getOne',
          resource: `${basePath}/import-latest-sale-experiment`,
          payload: {
            id: formData.fkItem,
          },
        },
        {
          onSuccess: (res) => setLastSaleExperiment(res.data),
          onFailure: (err) => {
            setLastSaleExperiment(undefined);
            console.error(err);
          },
        },
      );
    }
  }, [formData.fkItem, basePath]);

  return (
    <Fragment>
      <AuctionGeneral {...props} formData={formData} selectedItem={selectedItem} />
      <AuctionInfo
        {...props}
        formData={formData}
        selectedItem={selectedItem}
        cannotModify={props.cannotModify}
      />
      <ExpressionOfInterest
        {...props}
        selectedItem={selectedItem}
        lastSaleExperiment={lastSaleExperiment}
        formData={formData}
      />
      <OffersCollection
        {...props}
        selectedItem={selectedItem}
        lastSaleExperiment={lastSaleExperiment}
        formData={formData}
      />
      <AuctionFee {...props} formData={formData} />
      <AuctionVisit {...props} formData={formData} selectedItem={selectedItem} />
      <Retail {...props} formData={formData} selectedItem={selectedItem} />
      <BuyItNow {...props} formData={formData} selectedItem={selectedItem} />
    </Fragment>
  );
};
