import { blue, green, grey, orange, red } from '@material-ui/core/colors';

export const ECWID_EVENT_TYPES = [
  { id: 'unfinished-order.created', name: 'Unfinished Order Created' },
  { id: 'unfinished-order.updated', name: 'Unfinished Order Updated' },
  { id: 'unfinished-order.deleted', name: 'Unfinished Order Deleted' },
  { id: 'order.created', name: 'Order Created' },
  { id: 'order.updated', name: 'Order Updated' },
  { id: 'order.deleted', name: 'Order Deleted' },
  { id: 'product.created', name: 'Product Created' },
  { id: 'product.updated', name: 'Product Updated' },
  { id: 'product.deleted', name: 'Product Deleted' },
  { id: 'category.created', name: 'Category Created' },
  { id: 'category.updated', name: 'Category Updated' },
  { id: 'category.deleted', name: 'Category Deleted' },
  { id: 'application.installed', name: 'Application Installed' },
  { id: 'application.subscriptionStatusChanged', name: 'Application Subscription Status Changed' },
  { id: 'application.uninstalled', name: 'Application Uninstalled' },
  { id: 'profile.subscriptionStatusChanged', name: 'Profile Subscription Status Changed' },
  { id: 'profile.updated', name: 'Profile Updated' },
  { id: 'customer.created', name: 'Customer Created' },
  { id: 'customer.updated', name: 'Customer Updated' },
  { id: 'customer.deleted', name: 'Customer Deleted' },
  { id: 'customer.personalDataRemovalRequest', name: 'Customer Personal Data Removal Request' },
  { id: 'customer.personalDataExportRequest', name: 'Customer Personal Data Export Request' },
  { id: 'profile.personalDataRemovalRequest', name: 'Profile Personal Data Removal Request' },
  { id: 'profile.personalDataExportRequest', name: 'Profile Personal Data Export Request' },
  { id: 'discount_coupon.created', name: 'Discount Coupon Created' },
  { id: 'discount_coupon.updated', name: 'Discount Coupon Updated' },
  { id: 'discount_coupon.deleted', name: 'Discount Coupon Deleted' },
  { id: 'invoice.created', name: 'Invoice Created' },
  { id: 'invoice.deleted', name: 'Invoice Deleted' },
];

export const ECWID_EVENT_STATUSES = [
  { id: 'pending', name: 'In attesa' },
  { id: 'pre-processing', name: 'Pre processo in corso' },
  { id: 'pre-processed', name: 'Pre-processato' },
  { id: 'processing', name: 'In corso' },
  { id: 'processed', name: 'Processato' },
  { id: 'error', name: 'Errore' },
];

export enum EcwidEventType {
  UnfinishedOrderCreated = 'unfinished-order.created',
  UnfinishedOrderUpdated = 'unfinished-order.updated',
  UnfinishedOrderDeleted = 'unfinished-order.deleted',
  OrderCreated = 'order.created',
  OrderUpdated = 'order.updated',
  OrderDeleted = 'order.deleted',
  ProductCreated = 'product.created',
  ProductUpdated = 'product.updated',
  ProductDeleted = 'product.deleted',
  CategoryCreated = 'category.created',
  CategoryUpdated = 'category.updated',
  CategoryDeleted = 'category.deleted',
  ApplicationInstalled = 'application.installed',
  ApplicationSubscriptionStatusChanged = 'application.subscriptionStatusChanged',
  ApplicationUninstalled = 'application.uninstalled',
  ProfileSubscriptionStatusChanged = 'profile.subscriptionStatusChanged',
  ProfileUpdated = 'profile.updated',
  CustomerCreated = 'customer.created',
  CustomerUpdated = 'customer.updated',
  CustomerDeleted = 'customer.deleted',
  CustomerPersonalDataRemovalRequest = 'customer.personalDataRemovalRequest',
  CustomerPersonalDataExportRequest = 'customer.personalDataExportRequest',
  ProfilePersonalDataRemovalRequest = 'profile.personalDataRemovalRequest',
  ProfilePersonalDataExportRequest = 'profile.personalDataExportRequest',
  DiscountCouponCreated = 'discount_coupon.created',
  DiscountCouponUpdated = 'discount_coupon.updated',
  DiscountCouponDeleted = 'discount_coupon.deleted',
  InvoiceCreated = 'invoice.created',
  InvoiceDeleted = 'invoice.deleted',
}

/// region COLORS

type EcwidEventColors = {
  pending: [string, string];
  'pre-processing': [string, string];
  'pre-processed': [string, string];
  processing: [string, string];
  processed: [string, string];
  error: [string, string];
};

export const ECWID_EVENT_PENDING_BACKGROUND_COLOR: string = grey[700];
export const ECWID_EVENT_PENDING_TEXT_COLOR: string = grey[50];
export const ECWID_EVENT_PRE_PROCESSING_BACKGROUND_COLOR: string = orange[500];
export const ECWID_EVENT_PRE_PROCESSING_TEXT_COLOR: string = grey[900];
export const ECWID_EVENT_PRE_PROCESSED_BACKGROUND_COLOR: string = blue[500];
export const ECWID_EVENT_PRE_PROCESSED_TEXT_COLOR: string = grey[900];
export const ECWID_EVENT_PROCESSING_BACKGROUND_COLOR: string = orange[500];
export const ECWID_EVENT_PROCESSING_TEXT_COLOR: string = grey[900];
export const ECWID_EVENT_PROCESSED_BACKGROUND_COLOR: string = green[500];
export const ECWID_EVENT_PROCESSED_TEXT_COLOR: string = grey[900];
export const ECWID_EVENT_ERROR_BACKGROUND_COLOR: string = red[700];
export const ECWID_EVENT_ERROR_TEXT_COLOR: string = grey[50];

export const ECWID_EVENT_COLORS: EcwidEventColors = {
  pending: [ECWID_EVENT_PENDING_BACKGROUND_COLOR, ECWID_EVENT_PENDING_TEXT_COLOR],
  'pre-processing': [
    ECWID_EVENT_PRE_PROCESSING_BACKGROUND_COLOR,
    ECWID_EVENT_PRE_PROCESSING_TEXT_COLOR,
  ],
  'pre-processed': [
    ECWID_EVENT_PRE_PROCESSED_BACKGROUND_COLOR,
    ECWID_EVENT_PRE_PROCESSED_TEXT_COLOR,
  ],
  processing: [ECWID_EVENT_PROCESSING_BACKGROUND_COLOR, ECWID_EVENT_PROCESSING_TEXT_COLOR],
  processed: [ECWID_EVENT_PROCESSED_BACKGROUND_COLOR, ECWID_EVENT_PROCESSED_TEXT_COLOR],
  error: [ECWID_EVENT_ERROR_BACKGROUND_COLOR, ECWID_EVENT_ERROR_TEXT_COLOR],
};

/// endregion
