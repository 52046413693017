import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { ResourceExport } from '../../types/resource-export.type';
import ParticipationAttemptList from './ParticipationAttemptList';

const ParticipationAttempt: ResourceExport = {
  list: ParticipationAttemptList,
  icon: AssignmentLateIcon,
};

export default ParticipationAttempt;
