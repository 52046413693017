import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import RequestActions from '../../Components/pvp/request-actions/request-actions';
import RequestDetails from '../../Components/pvp/request-details/request-details';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'PvpPublicationRequestExpanded' },
);

const PvpPublicationRequestExpanded: FC<any> = ({ record: pvpRequest, resource }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <RequestDetails pvpRequest={pvpRequest} />
      <RequestActions pvpRequest={pvpRequest} resource={resource} />
    </div>
  );
};

export default PvpPublicationRequestExpanded;
