import { Button } from '@material-ui/core';
import ImportFromChatbotIcon from '@material-ui/icons/GetApp';
import { useNotify, useRefresh } from 'ra-core';
import { FC, Fragment } from 'react';
import { useMutation } from 'react-admin';
import { useToggleState } from '../../../hooks/use-toggle-state';
import AppAlert from '../../ui/alert/AppAlert';
import AppConfirmDialog from '../../ui/dialog/confirm-dialog/app-confirm-dialog';

export enum ImportType {
  All,
  Single,
}

interface ChatbotExecuteImportButtonProps {
  importType: ImportType;
  chatbotId?: string;
  refreshAfterExecution?: boolean;
  handleClose?: () => void;
  disabled?: boolean;
}

const ChatbotExecuteImportButton: FC<ChatbotExecuteImportButtonProps> = ({
  importType,
  chatbotId,
  handleClose,
  refreshAfterExecution = false,
  disabled,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [confirmDialogOpen, toggleDialog] = useToggleState();

  const [addChatbotImport] = useMutation();
  const handleAddChatbotImport = async () => {
    if (importType === ImportType.Single && !chatbotId) {
      return notify('Aggiornamento da chatbot non possibile: id chatbot non presente.', 'error');
    }

    await addChatbotImport(
      {
        type: 'getOne',
        resource: `chatbot-import/${
          importType === ImportType.All ? 'add-chatbot-imports' : `add-chatbot-import/${chatbotId}`
        }`,
        payload: {},
      },
      {
        onSuccess: () => {
          refreshAfterExecution && refresh();
          !!handleClose && handleClose();

          notify(
            importType === ImportType.All
              ? 'Lotti in importazione.'
              : 'Il lotto verrà aggiornato a breve.',
            'info',
          );
          toggleDialog();
        },
        onFailure: (err) => {
          console.error('[handleExecuteImport]:', err);
          notify(`Operazione fallita${err.message ? `: ${err.message}` : ''}.`, 'error');
        },
      },
    );
  };

  return (
    <Fragment>
      <Button
        children={
          importType === ImportType.All
            ? 'Avvia nuova importazione da chatbot'
            : 'Importa aggiornamenti'
        }
        onClick={toggleDialog}
        variant={importType == ImportType.All ? 'text' : 'contained'}
        color={importType === ImportType.All ? 'primary' : 'secondary'}
        startIcon={<ImportFromChatbotIcon />}
        size={importType === ImportType.All ? 'medium' : 'small'}
        disabled={disabled}
      />
      <AppConfirmDialog
        onConfirm={handleAddChatbotImport}
        onClose={toggleDialog}
        open={confirmDialogOpen}
        title="Confermi l'avvio dell'importazione?"
        details={
          <AppAlert
            title="Attenzione"
            children={
              importType === ImportType.All
                ? "Questa azione avvierà l'importazione di TUTTI i lotti aggiornati nelle ultime 24 ore."
                : "Questa azione avvierà l'aggiornamento del lotto"
            }
            severity="warning"
          />
        }
      />
    </Fragment>
  );
};

export default ChatbotExecuteImportButton;
