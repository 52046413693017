import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { concat as _concat, merge as _merge } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Form, useForm, useFormState } from 'react-final-form';
import { BIDDER_RECEIVING_METHODS, BIDDER_RIGHTS, BIDDER_TITLES } from '../../../utils/constants';
import { AppBooleanInput, AppNumberInput, AppSelectInput } from '../../ui/input';

const PresenterIsBidder: FC<any> = React.memo((props) => {
  const { values } = useFormState();
  const { change } = useForm();
  const notify = useNotify();

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = (value) => {
    if (value === true) {
      setOpen(true);
    } else {
      // remove presenter from bidders
      change(
        'bidders',
        values.bidders.filter((bidder) => !bidder.isPresenter),
      );
      notify('Il presentatore verrà rimosso dagli offerenti dopo il salvataggio.', 'info');
    }
  };

  const submit = (additionalInfo) => {
    const presenterAsBidder: Record<string, any> = _merge(values.presenter, additionalInfo, {
      isPresenter: true,
    });

    // add presenter to bidders
    change('bidders', _concat([], presenterAsBidder, values.bidders));
    setOpen(false);
    notify('Il presentatore è stato aggiunto agli offerenti con successo.', 'info');
  };

  const handleClose = () => {
    change('presenterIsBidder', false);
    setOpen(false);
  };

  // Presenter as bidder info update if presenter changes and presenter is set as bidder too
  useEffect(() => {
    if (!values.presenterIsBidder) return;

    const presenterAsBidderIndex = (values.bidders as Record<string, any>[]).findIndex(
      (bidder) => !!bidder.isPresenter,
    );
    if (presenterAsBidderIndex < 0) return;

    change(`bidders[${presenterAsBidderIndex}]`, {
      ...values.bidders[presenterAsBidderIndex],
      ...values.presenter,
    });
  }, [values.presenterId, values.presenter]);

  return (
    <React.Fragment>
      <AppBooleanInput
        source="presenterIsBidder"
        label="Il presentatore è anche offerente"
        initialValue={false}
        onChange={handleClickOpen}
        disabled={props.disabled}
      />
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          Aggiungi il presentatore come offerente
        </DialogTitle>
        <DialogContent>
          <Form
            onSubmit={submit}
            render={(formProps) => {
              const { handleSubmit } = formProps;
              return (
                <Grid container spacing={2}>
                  <AppSelectInput
                    source="right"
                    label="Diritto"
                    choices={BIDDER_RIGHTS}
                    variant="outlined"
                    required
                  />
                  <AppSelectInput
                    source="title"
                    label="Titolo"
                    choices={BIDDER_TITLES}
                    variant="outlined"
                    required
                  />
                  <AppSelectInput
                    source="receivingMethod"
                    label="Metodo di ricezione"
                    choices={BIDDER_RECEIVING_METHODS}
                    variant="outlined"
                    required
                  />
                  <AppNumberInput source="share" label="Quota" variant="outlined" required />
                  <Grid item md={2}>
                    <Button onClick={handleSubmit} color="primary">
                      Conferma
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Annulla
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});

export default PresenterIsBidder;
