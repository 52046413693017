import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
  ChipField,
  ListProps,
  ReferenceArrayField,
  SelectField,
  SingleFieldList,
} from 'react-admin';
import AppEditButton from '../../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../../Components/ui/detail/AppList';
import AppTextField from '../../../Components/ui/field/AppTextField';
import AppTitle from '../../../Components/ui/layout/AppTitle';
import { MESSAGES_TYPE_CHOICES } from '../../Booking/constants';

const useStyles = makeStyles(
  (theme) => ({
    type: {
      minWidth: theme.spacing(30),
    },
    pages: {
      minWidth: theme.spacing(30),
    },
    title: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'MessagesList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const MessageList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList {...props} title={<AppTitle title="Popup" />}>
      <AppDatagrid
        resource="messages"
        rowClick="show"
        permissions={props.permissions}
        expand={<span>{/* TODO: show images and description */}</span>}
      >
        <SelectField
          source="type"
          label="Tipo messaggio"
          choices={MESSAGES_TYPE_CHOICES}
          headerClassName={classes.type}
        />
        <AppTextField source="pages" label="Pagine" headerClassName={classes.pages} />
        <AppTextField source="title" label="Titolo" headerClassName={classes.title} />
        {!IS_IVG && (
          <ReferenceArrayField
            reference="properties"
            source="propertyIds"
            label="Siti"
            headerClassName={classes.lastCol}
          >
            <SingleFieldList>
              <ChipField source="name" style={{ marginBottom: '15px' }} />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default MessageList;
