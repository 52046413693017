import { Image, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import styles from '../styles';

type DocumentProps = {
  name: string;
  base64: string;
  mimeType: string;
};

type DataAttachmentProps = {
  doc: DocumentProps;
};

export const PdfDataAttachment: FC<DataAttachmentProps> = (props) => {
  const { name, base64, mimeType } = props.doc;
  const attachmentSrc = `data:${mimeType};base64,${base64}`;
  return (
    <View key={`${name}`} wrap={false} break>
      <Text style={styles.title}>{name}</Text>
      <Image
        src={attachmentSrc}
        style={{
          height: 700,
          objectFit: 'contain',
        }}
      />
    </View>
  );
};
