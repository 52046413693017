import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { DECREE_PERMISSIONS } from './index';

const useStyles = makeStyles(
  () => ({
    title: { whiteSpace: 'nowrap' },
    description: { whiteSpace: 'nowrap', width: '100%' },
  }),
  { name: 'DecreeList' },
);

const DecreeList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Decreti e provvedimenti" />}
      resourcePermissions={DECREE_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField
          source="title"
          label="Titolo"
          headerClassName={classes.title}
          cellClassName={classes.title}
        />
        <AppTextField
          source="description"
          label="Descrizione"
          headerClassName={classes.description}
          cellClassName={classes.description}
        />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default DecreeList;
