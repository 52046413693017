import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionField, ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppColorField, AppSelectField, AppTextField } from '../../Components/ui/field';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { ITEM_GENRES, MAGAZINE_KINDS } from '../../utils/constants';
import { MAGAZINE_INDEX_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    name: {},
    textColor: {
      minWidth: theme.spacing(25),
    },
    textX: {},
    textWidth: {},
    textFontSize: {},
    genre: {},
    kind: {},
    residentialY: {},
    industrialY: {},
    commercialY: {},
    otherRealEstateY: {},
    anpeY: {},
    participationRealEstate1Y: {},
    participationRealEstate2Y: {},
    participationRealEstate3Y: {},
    participationRealEstate1Delta: {},
    participationRealEstate2Delta: {},
    participationRealEstate3Delta: {},
    notesY: {},
    notesDelta: {},
    carsY: {},
    shipsY: {},
    machineriesY: {},
    informaticsY: {},
    furnitureY: {},
    artY: {},
    clothingY: {},
    otherMovableY: {},
    participationY: {},
    participationDelta: {},
  }),
  { name: 'MagazineIndexList' },
);

const MagazineIndexList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Indici" />}
      resourcePermissions={MAGAZINE_INDEX_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <FunctionField
          label="Colore testo"
          source="textColor"
          render={(record) => <AppColorField color={record.textColor} />}
          headerClassName={classes.textColor}
        />
        <AppSelectField
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          headerClassName={classes.genre}
        />
        <AppSelectField
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo"
          headerClassName={classes.kind}
        />
        <AppTextField source="textX" label="Testo: X" headerClassName={classes.textX} />
        <AppTextField
          source="textWidth"
          label="Testo: Larghezza"
          headerClassName={classes.textWidth}
        />
        <AppTextField
          source="textFontSize"
          label="Testo: Dimensione"
          headerClassName={classes.textFontSize}
        />
        <FunctionField
          label="Residenziale: Y"
          headerClassName={classes.residentialY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="residentialY"
                label="Residenziale: Y"
                headerClassName={classes.residentialY}
              />
            ) : (
              <AppTextField label="Residenziale: Y" headerClassName={classes.residentialY} />
            )
          }
        />
        <FunctionField
          label="Industriale: Y"
          headerClassName={classes.industrialY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="industrialY"
                label="Industriale: Y"
                headerClassName={classes.industrialY}
              />
            ) : (
              <AppTextField label="Industriale: Y" headerClassName={classes.industrialY} />
            )
          }
        />
        <FunctionField
          label="Commerciale: Y"
          headerClassName={classes.commercialY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="commercialY"
                label="Commerciale: Y"
                headerClassName={classes.commercialY}
              />
            ) : (
              <AppTextField label="Commerciale: Y" headerClassName={classes.commercialY} />
            )
          }
        />
        <FunctionField
          label="Altro: Y"
          headerClassName={classes.otherRealEstateY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="otherRealEstateY"
                label="Altro: Y"
                headerClassName={classes.otherRealEstateY}
              />
            ) : (
              <AppTextField label="Altro: Y" headerClassName={classes.otherRealEstateY} />
            )
          }
        />
        <FunctionField
          label="ANPE: Y"
          headerClassName={classes.anpeY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="anpeY"
                label="ANPE: Y"
                headerClassName={classes.anpeY}
              />
            ) : (
              <AppTextField label="ANPE: Y" headerClassName={classes.anpeY} />
            )
          }
        />
        <FunctionField
          label="Partecipazione 1: Y"
          headerClassName={classes.participationRealEstate1Y}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate1Y"
                label="Partecipazione 1: Y"
                headerClassName={classes.participationRealEstate1Y}
              />
            ) : (
              <AppTextField
                label="Partecipazione 1: Y"
                headerClassName={classes.participationRealEstate1Y}
              />
            )
          }
        />
        <FunctionField
          label="Partecipazione 1: Delta"
          headerClassName={classes.participationRealEstate1Delta}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate1Delta"
                label="Partecipazione 1: Delta"
                headerClassName={classes.participationRealEstate1Delta}
              />
            ) : (
              <AppTextField
                label="Partecipazione 1: Delta"
                headerClassName={classes.participationRealEstate1Delta}
              />
            )
          }
        />
        <FunctionField
          label="Partecipazione 2: Y"
          headerClassName={classes.participationRealEstate2Y}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate2Y"
                label="Partecipazione 2: Y"
                headerClassName={classes.participationRealEstate2Y}
              />
            ) : (
              <AppTextField
                label="Partecipazione 2: Y"
                headerClassName={classes.participationRealEstate2Y}
              />
            )
          }
        />
        <FunctionField
          label="Partecipazione 2: Delta"
          headerClassName={classes.participationRealEstate2Delta}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate2Delta"
                label="Partecipazione 2: Delta"
                headerClassName={classes.participationRealEstate2Delta}
              />
            ) : (
              <AppTextField
                label="Partecipazione 2: Delta"
                headerClassName={classes.participationRealEstate2Delta}
              />
            )
          }
        />
        <FunctionField
          label="Partecipazione 3: Y"
          headerClassName={classes.participationRealEstate3Y}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate3Y"
                label="Partecipazione 3: Y"
                headerClassName={classes.participationRealEstate3Y}
              />
            ) : (
              <AppTextField
                label="Partecipazione 3: Y"
                headerClassName={classes.participationRealEstate3Y}
              />
            )
          }
        />
        <FunctionField
          label="Partecipazione 3: Delta"
          headerClassName={classes.participationRealEstate3Delta}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="participationRealEstate3Delta"
                label="Partecipazione 3: Delta"
                headerClassName={classes.participationRealEstate3Delta}
              />
            ) : (
              <AppTextField
                label="Partecipazione 3: Delta"
                headerClassName={classes.participationRealEstate3Delta}
              />
            )
          }
        />
        <FunctionField
          label="Note: Y"
          headerClassName={classes.notesY}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="notesY"
                label="Note: Y"
                headerClassName={classes.notesY}
              />
            ) : (
              <AppTextField label="Note: Y" headerClassName={classes.notesY} />
            )
          }
        />
        <FunctionField
          label="Note: Delta"
          headerClassName={classes.notesDelta}
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppTextField
                record={record}
                source="notesDelta"
                label="Note: Delta"
                headerClassName={classes.notesDelta}
              />
            ) : (
              <AppTextField label="Note: Delta" headerClassName={classes.notesDelta} />
            )
          }
        />
        <FunctionField
          label="Automobili: Y"
          headerClassName={classes.carsY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="carsY"
                label="Automobili: Y"
                headerClassName={classes.carsY}
              />
            ) : (
              <AppTextField label="Automobili: Y" headerClassName={classes.carsY} />
            )
          }
        />
        <FunctionField
          label="Barche: Y"
          headerClassName={classes.shipsY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="shipsY"
                label="Barche: Y"
                headerClassName={classes.shipsY}
              />
            ) : (
              <AppTextField label="Barche: Y" headerClassName={classes.shipsY} />
            )
          }
        />
        <FunctionField
          label="Macchinari: Y"
          headerClassName={classes.machineriesY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="machineriesY"
                label="Macchinari: Y"
                headerClassName={classes.machineriesY}
              />
            ) : (
              <AppTextField label="Macchinari: Y" headerClassName={classes.machineriesY} />
            )
          }
        />
        <FunctionField
          label="Informatica: Y"
          headerClassName={classes.informaticsY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="informaticsY"
                label="Informatica: Y"
                headerClassName={classes.informaticsY}
              />
            ) : (
              <AppTextField label="Informatica: Y" headerClassName={classes.informaticsY} />
            )
          }
        />
        <FunctionField
          label="Mobili: Y"
          headerClassName={classes.furnitureY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="furnitureY"
                label="Mobili: Y"
                headerClassName={classes.furnitureY}
              />
            ) : (
              <AppTextField label="Mobili: Y" headerClassName={classes.furnitureY} />
            )
          }
        />
        <FunctionField
          label="Arte: Y"
          headerClassName={classes.artY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="artY"
                label="Arte: Y"
                headerClassName={classes.artY}
              />
            ) : (
              <AppTextField label="Arte: Y" headerClassName={classes.artY} />
            )
          }
        />
        <FunctionField
          label="Abbigliamento: Y"
          headerClassName={classes.clothingY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="clothingY"
                label="Abbigliamento: Y"
                headerClassName={classes.clothingY}
              />
            ) : (
              <AppTextField label="Abbigliamento: Y" headerClassName={classes.clothingY} />
            )
          }
        />
        <FunctionField
          label="Altro: Y"
          headerClassName={classes.otherMovableY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="otherMovableY"
                label="Altro: Y"
                headerClassName={classes.otherMovableY}
              />
            ) : (
              <AppTextField label="Altro: Y" headerClassName={classes.otherMovableY} />
            )
          }
        />
        <FunctionField
          label="Partecipazione: Y"
          headerClassName={classes.participationY}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="participationY"
                label="Partecipazione: Y"
                headerClassName={classes.participationY}
              />
            ) : (
              <AppTextField label="Partecipazione: Y" headerClassName={classes.participationY} />
            )
          }
        />
        <FunctionField
          label="Partecipazione: Delta"
          headerClassName={classes.participationDelta}
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="participationDelta"
                label="Partecipazione: Delta"
                headerClassName={classes.participationDelta}
              />
            ) : (
              <AppTextField
                label="Partecipazione: Delta"
                headerClassName={classes.participationDelta}
              />
            )
          }
        />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default MagazineIndexList;
