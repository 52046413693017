import { get as _get } from 'lodash';
import { Record as RaRecord, required, Validator } from 'react-admin';
import { amountFormatter, dateFormatter } from './data-formatters';
import { checkAndGetDate } from './dates';

export function getValidators(
  customValidators: Validator[] | undefined,
  inputIsRequired?: boolean,
): Validator[] {
  const validators: Validator[] = [];
  if (customValidators && customValidators.length)
    customValidators.forEach((validator) => validators.push(validator));

  if (inputIsRequired) validators.push(required());

  return validators;
}

/// region CUSTOM VALIDATORS

/**
 * NOTES
 *
 * RA always passes "value" (current value of the input) and "allValues" (current form values) to the validators
 *
 * Custom validators must return "undefined" if no error and a string (explaining the error) if an error occurs
 */

/// region DATES

/**
 * Maximum date validator
 *
 * @description Checks that the date is at most equal to another date
 *
 * @param {string} dateToCompareSource
 * @param {string} dateToCompareLabel
 * @param {boolean} showDateInErrorMessage
 *
 * @return {string|undefined}
 *
 */
export const maxDate =
  (dateToCompareSource: string, dateToCompareLabel: string, showDateInErrorMessage = true) =>
  (value: Date | string, allValues: RaRecord): string | undefined => {
    let dateToCompare = _get(allValues, dateToCompareSource);
    let originalDate = value;

    if (typeof dateToCompare === 'string') dateToCompare = new Date(dateToCompare);
    if (typeof originalDate === 'string') originalDate = new Date(originalDate);

    if (dateToCompare && originalDate && originalDate > dateToCompare)
      return `Non può essere maggiore di '${dateToCompareLabel}' ${
        showDateInErrorMessage ? `(${dateFormatter(dateToCompare.toISOString())})` : ''
      }`;

    return;
  };

/**
 * Minimum date validator
 *
 * @description Checks that the date is less than another date
 *
 * @param {string} dateToCompareSource
 * @param {string} dateToCompareLabel
 * @param {boolean} showDateInErrorMessage
 *
 * @return {string|undefined}
 *
 */
export const minDate =
  (dateToCompareSource: string, dateToCompareLabel: string, showDateInErrorMessage = true) =>
  (value: Date | string, allValues: RaRecord): string | undefined => {
    let dateToCompare = _get(allValues, dateToCompareSource);
    let originalDate = value;

    if (typeof dateToCompare === 'string') dateToCompare = new Date(dateToCompare);
    if (typeof originalDate === 'string') originalDate = new Date(originalDate);

    if (dateToCompare && originalDate && originalDate < dateToCompare)
      return `Non può essere minore di '${dateToCompareLabel}' ${
        showDateInErrorMessage ? `(${dateFormatter(dateToCompare.toISOString())})` : ''
      }`;

    return;
  };

/**
 * Minimum date validator
 *
 * @description Checks that the date greater than (or equal to) another date
 *
 * @param {string} dateToCompareSource
 * @param {string} dateToCompareLabel
 * @param {boolean} showDateInErrorMessage
 * @param strictComparison
 * @return {string|undefined}
 *
 */
export const minDate2 =
  (
    dateToCompareSource: string,
    dateToCompareLabel: string,
    strictComparison = true,
    showDateInErrorMessage = true,
  ) =>
  (value: Date | string, allValues: RaRecord): string | undefined => {
    let dateToCompare = _get(allValues, dateToCompareSource);
    let originalDate = value;

    dateToCompare = checkAndGetDate(dateToCompare);
    originalDate = checkAndGetDate(originalDate);

    if (
      !dateToCompare ||
      !originalDate ||
      (strictComparison ? dateToCompare < originalDate : dateToCompare <= originalDate)
    )
      return;

    return `Deve essere ${
      strictComparison ? 'maggiore' : 'maggiore o uguale'
    } a '${dateToCompareLabel}' ${
      showDateInErrorMessage ? `(${dateFormatter(dateToCompare.toISOString())})` : ''
    }`;
  };

/// endregion

/// region AMOUNTS

/**
 * Checks that the input value is at most equal to the given value (minValue).
 * You can compare the input to another number field (pass the source of the field as minValue) or to a fixed number
 * @param minValue
 * @param minValueName
 * @param isAmount
 * @param isSelect
 * @param choices
 */
export const minValue =
  (
    minValue: number | string,
    minValueName?: string,
    isAmount = false,
    isSelect = false,
    choices: Record<string, any>[] = [],
  ) =>
  (value: number, allValues: RaRecord): string | undefined => {
    const valueToCompareTo = typeof minValue === 'string' ? _get(allValues, minValue) : minValue;

    if (isNaN(valueToCompareTo)) return;

    if (value < valueToCompareTo)
      return `Il valore inserito non può essere minore di "${
        isAmount
          ? amountFormatter(valueToCompareTo)
          : isSelect && !!choices.length
          ? choices.find((choice) => choice.id === valueToCompareTo)?.name ?? valueToCompareTo
          : valueToCompareTo
      }" ${minValueName ? `(${minValueName})` : ''}`;
  };

/**
 * Checks that the input value is less than the given value (minValue).
 * You can compare the input to another number field (pass the source of the field as minValue) or to a fixed number
 * @param minValue
 * @param minValueName
 * @param isAmount
 */
export const maxValue =
  (minValue: number | string, minValueName?: string, isAmount = false) =>
  (value: number, allValues: RaRecord): string | undefined => {
    const valueToCompareTo = typeof minValue === 'string' ? _get(allValues, minValue) : minValue;

    if (isNaN(valueToCompareTo)) return;

    if (value > valueToCompareTo)
      return `Il valore inserito non può essere maggiore di ${
        isAmount ? amountFormatter(valueToCompareTo) : valueToCompareTo
      } ${minValueName ? `(${minValueName})` : ''}`;
  };

/// endregion

/// region PASSWORD

/**
 * Validate password against RegExp
 */
export const validatePassword =
  () =>
  (password: string): string | undefined => {
    /**
     * Password rules:
     * - at least a character
     * - at least a number
     * - at least a symbol: ! ? @ # $ % ^ & * _ \ - , . ; :
     * - min: 8 characters
     * - max: 64 characters, for good measure
     */
    if (!RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!?@#$%^&*_\\-,.;:]).{8,64}$').test(password))
      return 'Formato password non valido.';
  };

/**
 * Checks that the confirmed password matches with the new password
 * @param newPasswordSource
 */
export const validateConfirmPassword =
  (newPasswordSource: string) =>
  (confirmPassword: string, allValues: RaRecord): string | undefined => {
    const newPassword: string = _get(allValues, newPasswordSource);

    if (!newPassword || !newPassword.length) return;

    if (newPassword !== confirmPassword)
      return 'Il valore inserito non combacia con la nuova password.';
  };

/// endregion

/// endregion
