import { ResourceExport } from '../../types/resource-export.type';
import CategoryList from './CategoryList';
import CategoryShow from './CategoryShow';
import CategoryIcon from '@material-ui/icons/Category';

const Category: ResourceExport = {
  list: CategoryList,
  //TODO: resource needs refactor after API server changes
  //create: resourceWrapper('create', CategoryDetails),
  //edit: resourceWrapper('edit', CategoryDetails),
  show: CategoryShow,
  icon: CategoryIcon,
};

export default Category;
