import React, { FC, Fragment } from 'react';
import { PvpOfferField } from './pvp-offer-field';

interface PvpOfferContactsProps {
  contatti: Record<string, any>;
}

export const PvpOfferContacts: FC<PvpOfferContactsProps> = ({ contatti }) => {
  return (
    <Fragment>
      <PvpOfferField label="Cellulare" record={contatti} field="cellulare" md={6} />
      <PvpOfferField label="Telefono" record={contatti} field="telefono" md={6} />
      <PvpOfferField label="Email" record={contatti} field="email" md={6} />
      <PvpOfferField label="Pec" record={contatti} field="pec" md={6} />
    </Fragment>
  );
};
