import { FC, useState } from 'react';
import { BooleanInput } from 'react-admin';
import { ConfigurationSection } from '../../../Components/cms/configuration/configuration-section';
import { WebsiteCustomizationDetails } from '../../../Components/cms/configuration/website-customization/website-customization-details';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppArrayInput,
  AppAutocompleteInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { AppImageInput } from '../../../Components/ui/input/AppImageInput';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import { LINK_TYPES_CHOICES } from '../../Booking/constants';
import PageSelection from '../Pages/PageSelection';
import { ITEM_IDENTIFIERS } from './constants';

const ConfigurationDetails: FC<any> = (props) => {
  const [linkType, setLinkType] = useState<string>(props.record?.searchPayoff?.linkType ?? '');

  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <ConfigurationSection collapsable={false} title="Informazioni generali">
        <AppAutocompleteInput
          source="id"
          reference="properties"
          label="Sito"
          variant="outlined"
          md={12}
          disabled={props.mode !== 'create'}
          filter={
            {
              schema: null,
            } as any
          }
        />
      </ConfigurationSection>
      <ConfigurationSection baseSource="companyConfiguration" title="Dati società">
        <AppTextInput source="Name" label="Nome" required />
        <AppTextInput source="Address" label="Indirizzo" required />
        <AppTextInput source="Locality" label="Località" required />
        <AppTextInput source="PostalCode" label="CAP" required />
        <AppTextInput source="Province" label="Provincia" required />
        <AppTextInput source="VAT" label="Partita IVA" required />
        <AppTextInput source="Phone" label="Telefono" />
        <AppTextInput source="Fax" label="Fax" />
        <AppTextInput source="Email" label="Email" required />
        <AppTextInput source="Skype" label="Skype" />
        <AppTextInput
          source="TelematicSalesManagersRegistration"
          label="Iscrizione gestori vendita telematica"
        />
        <AppTextInput
          source="NoticesPublicationEnabling"
          label="Abilitazione pubblicazione avvisi"
        />
        <AppDivider />
        <AppSectionTitle>Social</AppSectionTitle>
        <AppTextInput source="Social.Facebook" label="Facebook" />
        <AppTextInput source="Social.Linkedin" label="LinkedIn" />
        <AppDivider />
        <AppArrayInput
          source="RealEstateVisits"
          label="Notifiche richieste di visita immobiliari"
          md={12}
        >
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
        <AppArrayInput
          source="MovableVisits"
          label="Notifiche richieste di visita mobiliari"
          md={12}
        >
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
        <AppArrayInput source="InfoRequests" label="Notifiche richieste di informazioni" md={12}>
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
        <AppArrayInput source="LivenUps" label="Notifiche richieste di vivacizzazione" md={12}>
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
        <AppArrayInput source="AdminNotifications" label="Notifiche Admin" md={12}>
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
        <AppDivider />
        <AppTextInput
          source="CustomFooter"
          label="Testo footer personalizzato"
          md={12}
          multiline
          expandable
        />
        <AppArrayInput
          source="AuctionRoomBookings"
          label="Notifiche prenotazioni sale d'asta"
          md={12}
        >
          <AppTextInput source="Email" label="Email" required />
        </AppArrayInput>
      </ConfigurationSection>
      <ConfigurationSection
        title="Link del payoff del pannello di ricerca"
        baseSource="searchPayoff"
      >
        <AppSelectInput
          source="linkType"
          label="Tipo"
          choices={LINK_TYPES_CHOICES}
          onChange={(e) => setLinkType(e.target.value)}
        />
        {linkType === LINK_TYPES_CHOICES[0].id && (
          <PageSelection source="link" label="Pagina" mode={props.mode} />
        )}
        {linkType === LINK_TYPES_CHOICES[1].id && (
          <AppTextInput source="link" label="Url" placeholder="https://google.com" />
        )}
      </ConfigurationSection>
      <ConfigurationSection title="Contenuto homepage">
        <AppArrayInput source="homepageContent" label="Contenuto homepage" md={12}>
          <AppTextInput source="Title" label="Titolo" />
          <AppTextInput source="Text" label="Testo" />
          <AppImageInput
            source="Image.file"
            previewSource="url"
            previewLabel="Corrente"
            label="Immagine"
          />
          <PageSelection mode={props.mode} source="Page" label="Pagina" />
        </AppArrayInput>
      </ConfigurationSection>
      <WebsiteCustomizationDetails />
      <ConfigurationSection baseSource="homepageConfiguration" title="Configurazione homepage">
        <AppTextInput source="HeaderPayoff" label="Motto intestazione" required />
        <AppTextInput source="SearchPayoff" label="Motto ricerca" required />
        <AppImageInput
          source="RegisterCtaImageFile"
          accept="image/png"
          label="RegisterCta"
          isRequired
          previewSource="RegisterCtaImage"
          previewLabel="RegisterCta corrente"
        />
        <AppArrayInput source="SearchBackgroundImages" label="Immagini sfondo homepage" md={12}>
          <AppImageInput
            source="file"
            previewSource="url"
            previewLabel="Corrente"
            label="Immagine"
          />
        </AppArrayInput>
      </ConfigurationSection>
      <ConfigurationSection
        baseSource="publicationConfiguration"
        title="Configurazione pubblicazioni"
      >
        <AppSelectInput
          source="ItemIdentifier"
          choices={ITEM_IDENTIFIERS}
          label="Riferimento del lotto"
          defaultValue={ITEM_IDENTIFIERS[0].id}
          helperText="Riferimento lotto da inviare ai siti esterni (immobiliare.it, idealista.it, etc.)"
        />
      </ConfigurationSection>
      <ConfigurationSection
        baseSource="immobiliareItConfiguration"
        title="Configurazioni Immobiliare.it"
      >
        <AppTextInput source="TextBefore" label="Testo prima" multiline expandable />
        <AppTextInput source="TextAfter" label="Testo dopo" multiline expandable />
      </ConfigurationSection>
      <ConfigurationSection baseSource="idealistaConfiguration" title="Configurazioni Idealista">
        <AppTextInput source="TextBefore" label="Testo prima" multiline expandable />
        <AppTextInput source="TextAfter" label="Testo dopo" multiline expandable />
      </ConfigurationSection>
      <ConfigurationSection
        baseSource="massivePublicationConfiguration"
        title="Configurazioni pubblicazione massiva"
      >
        <BooleanInput
          source="BuyItNowAutomaticPublication"
          label="Pubblicazione automatica dei lotti del compra subito"
        />
      </ConfigurationSection>
    </AppBaseForm>
  );
};

export default ConfigurationDetails;
