import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import React, { FC } from 'react';

interface AppAlertProps extends AlertProps {
  withGrid?: boolean;
  md?: boolean | 12 | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
}

const AppAlert: FC<AppAlertProps> = (props) => {
  const { children, title, withGrid, md = 12, ...rest } = props;

  const AlertComponent = (
    <Alert {...rest}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography>{children}</Typography>
    </Alert>
  );

  return withGrid ? (
    <Grid item md={md}>
      {AlertComponent}
    </Grid>
  ) : (
    <>{AlertComponent}</>
  );
};

export default AppAlert;
