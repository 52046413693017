import { NumberInputProps } from 'ra-ui-materialui/lib/input/NumberInput';
import React, { FC, ReactElement } from 'react';
import { NumberInput, Validator } from 'react-admin';
import NumberFormat from 'react-number-format';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

export const AppNumberInput: FC<AppNumberInputProps> = React.memo<AppNumberInputProps>(
  (props: AppNumberInputProps): ReactElement => {
    const {
      required,
      validate: customValidators,
      isAmount = false,
      type = 'number',
      ...rest
    } = props;
    return gridWrappedInput(
      { ...rest },
      <NumberInput
        type={isAmount ? 'text' : type}
        onWheel={(e) => e.target.blur()}
        InputProps={isAmount ? { inputComponent: AppNumberFormat as any } : {}}
        validate={getValidators(customValidators as Validator[], required)}
        {...inputProps({ ...rest })}
      />,
    );
  },
);

// NUMBER FORMAT COMPONENT (if is amount)
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const AppNumberFormat: FC<any> = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export type AppNumberInputProps = NumberInputProps & AppGridProps & { isAmount?: boolean };
