import { FC } from 'react';
import { PAYMENT_METHOD } from '../../../../utils/constants';
import { amountFormatter, dateFormatter } from '../../../../utils/data-formatters';
import { PdfDataLine, PdfSection } from '../utils';
import { PdfSingleSectionProps } from './sections';

export const InfoOffer: FC<PdfSingleSectionProps> = (props) => {
  const data = props.additionalData;
  return (
    <PdfSection subtitle="INFORMAZIONI OFFERTA">
      <PdfDataLine
        label="Identificativo offerta PVP"
        value={data.pvpOfferData ? data.pvpOfferData.attributes.idOfferta : '-'}
      />
      <PdfDataLine
        label="Data di ricezione offerta PVP"
        value={
          data.pvpOfferData ? `${dateFormatter(data.pvpOfferData.offerta.dataCro, true)}` : '-'
        }
      />
      <PdfDataLine label="Offerta" value={`€ ${amountFormatter(data.amount)}`} />
      <PdfDataLine
        label="Data deposito"
        value={data.depositAmount ? `${dateFormatter(data.offeredAt, true)}` : '-'}
      />
      <PdfDataLine
        label="Cauzione versata"
        value={data.depositAmount ? `€ ${amountFormatter(data.depositAmount)}` : '-'}
      />
      <PdfDataLine
        label="Termine pagamento"
        value={`${dateFormatter(data.saleExperiment.balanceDueDate)}`}
      />
      {data.payment ? (
        <>
          <PdfDataLine
            label="Metodo pagamento"
            value={
              data.payment.paymentMethod
                ? PAYMENT_METHOD.find((m) => m.id === data.payment.paymentMethod)?.name ?? '-'
                : '-'
            }
          />
          <PdfDataLine label="Identificativo CRO" value={data.payment.cro ?? '-'} />
          <PdfDataLine label="Numero assegno" value={data.payment.checkNumber ?? '-'} />
          <PdfDataLine
            label="Termine saldo"
            value={
              data.payment.balanceDueDate ? dateFormatter(data.payment.balanceDueDate, true) : '-'
            }
          />
          <PdfDataLine label="Data del bonifico" value={dateFormatter(data.payment.croDate)} />
          <PdfDataLine
            label="IBAN di accredito versamento"
            value={data.payment.beneficiaryIban ?? '-'}
          />
          <PdfDataLine
            label="Intestatario conto"
            value={data.payment.creditCardOwner?.name ?? '-'}
          />
          <PdfDataLine label="Data accredito" value={dateFormatter(data.payment.creditEntryAt)} />
          <PdfDataLine label="IBAN riaccredito" value={data.payment.returnIban ?? '-'} />
          <PdfDataLine
            label="Stato"
            value={data.payment.verified ? 'Verificato' : data.payment.pending ? 'In sospeso' : '-'}
          />
        </>
      ) : (
        <></>
      )}
    </PdfSection>
  );
};
