import { keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { BooleanField, ListContextProvider, SelectField } from 'react-admin';
import { BIDDER_RECEIVING_METHODS, BIDDER_RIGHTS, BIDDER_TITLES } from '../../../utils/constants';
import { useBidderDatagridStyles } from '../../participation/bidders/ParticipationBidders';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';
import { AppText } from '../../ui/text';

const ParticipationDetails: FC<any> = ({ record }) => {
  const classes = useBidderDatagridStyles();

  const listContext = useMemo(
    () => ({
      resource: 'sale-experiments',
      data: _keyBy(record.bidders, 'id'),
      ids: _map(record.bidders, 'id'),
      total: record.bidders.length,
      loaded: true,
      loading: false,
      page: 1,
      perPage: 1000,
      currentSort: { field: 'id', sort: 'ASC' },
      selectedIds: [],
    }),
    [record],
  );

  return (
    <>
      <AppText variant="button">
        {listContext.total > 0
          ? 'Lista degli offerenti della partecipazione'
          : 'Non sono presenti offerenti per questa partecipazione.'}
      </AppText>
      <ListContextProvider value={listContext}>
        <AppDatagrid>
          <AppTextField source="firstName" label="Nome" />
          <AppTextField source="lastName" label="Cognome" />
          <AppTextField source="taxCode" label="Codice fiscale" />
          <AppTextField source="email" label="Email" />
          <AppTextField source="share" label="Quota" />
          <SelectField choices={BIDDER_RIGHTS} source="right" label="Diritto" />
          <SelectField choices={BIDDER_TITLES} source="title" label="Titolo" />
          <SelectField
            choices={BIDDER_RECEIVING_METHODS}
            source="receivingMethod"
            label="Metodo di ricezione"
            headerClassName={classes.others}
          />
          <BooleanField
            source="isPresenter"
            label="Presentatore"
            headerClassName={classes.lastCol}
          />
        </AppDatagrid>
      </ListContextProvider>
    </>
  );
};

export default ParticipationDetails;
