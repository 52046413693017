import { makeStyles } from '@material-ui/core';
import { FC, memo, useState } from 'react';
import { useGetList, useMutation, useNotify, usePermissions } from 'react-admin';
import { useForm } from 'react-final-form';
import { ChipPanelField } from '../../../Components/chip/chip-panel-field';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppAutocompleteInput, AppInputFiller } from '../../../Components/ui/input';
import AppInputHidden from '../../../Components/ui/input/AppInputHidden';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import { User } from '../../../types/user.type';
import { FolderType } from '../../Folder/enums';

const useStyles = makeStyles(
  (theme) => ({
    ownerChip: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },
  }),
  { name: 'OwnerSelection' },
);

/**
 * Handles person selection for the "to-the-user" payment creation (in this case the person is the "owner" of the folder)
 */
export const OwnerSelection: FC<any> = memo((props) => {
  const classes = useStyles();
  const notify = useNotify();

  const { change } = useForm();

  const { permissions: user } = usePermissions<User>();

  const getFoldersResources = user?.permissions
    .filter((p) => p.method === ResourceAction.Get && p.path.startsWith('folders'))
    .map((p) => p.path);

  //#region FOLDER SELECTION

  const [foldersChoices, setFoldersChoices] = useState<Record<string, any>[]>([]);
  useGetList(
    getFoldersResources?.[0] ?? 'folders',
    undefined,
    undefined,
    {
      type: FolderType.Private,
    },
    {
      onSuccess: ({ data }) => {
        Object.values(data as Record<string, any>).forEach((folder) => {
          setFoldersChoices((foldersChoices) => [
            ...foldersChoices,
            { id: folder.id, name: folder.title },
          ]);
        });
      },
    },
  );

  //#endregion

  //#region ITEM SELECTION

  const [owner, setOwner] = useState<Record<string, any> | null>(null);
  const [itemsChoices, setItemsChoices] = useState<Record<string, any>[]>([]);
  const [mutateItems] = useMutation();
  const onFolderSelection = (folderId: number) => {
    if (!folderId) return;

    mutateItems(
      {
        type: 'getList',
        resource: `folders/${folderId}/items`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setItemsChoices(res.data);
        },
        onFailure: (err) => {
          setItemsChoices([]);
          console.error(err);
        },
      },
    );
  };

  //#endregion

  //#region SALE EXPERIMENT SELECTION

  const [mutateCurrentSaleExp] = useMutation();
  const onItemSelection = (itemId: number) => {
    if (!itemId) return;

    mutateCurrentSaleExp(
      {
        type: 'getOne',
        resource: `items/${itemId}/current-sale-experiment`,
        payload: {},
      },
      {
        onSuccess: ({ data }) => {
          change('fkSaleExperiment', data.id);
          onSaleExperimentSelection(data.id);
        },
        onFailure: (err) => {
          change('fkSaleExperiment', undefined);
          notify(err?.message ?? 'UNKNOWN_ERROR', { type: 'error' });

          console.log(err);
        },
      },
    );
  };

  //#endregion

  //#region PARTICIPANT (OWNER) SELECTION

  const [mutateOwner] = useMutation();
  const onSaleExperimentSelection = (saleExpId: number) => {
    mutateOwner(
      {
        type: 'getOne',
        resource: `sale-experiments-private/${saleExpId}/owner`,
        payload: {},
      },
      {
        onSuccess: ({ data }) => {
          setOwner(data.presenter);
          change('fkParticipation', data.id);
        },
        onFailure: (err) => {
          notify(err?.message ?? 'UNKNOWN_ERROR', { type: 'error' });

          setOwner(null);
          change('fkParticipation', undefined);
          change('fkSaleExperiment', undefined);
          change('itemId', undefined);

          console.log(err);
        },
      },
    );
  };

  //#endregion

  return (
    <AppCard title="Compilazione proprietario" expanded>
      <AppAutocompleteInput
        {...props}
        source="folderId"
        choices={foldersChoices}
        label="Fascicolo"
        md={3}
        onSelect={(s) => onFolderSelection(s.id)}
        required
      />
      <AppAutocompleteInput
        {...props}
        source="itemId"
        choices={itemsChoices}
        label="Lotto"
        md={3}
        optionText="title"
        required
        onSelect={(i) => onItemSelection(i.id)}
      />
      {/* <AppNumberInput
        {...props}
        source="fkSaleExperiment"
        label="Esperimento corrente"
        disabled
        md={3}
      /> */}
      {/* <AppNumberInput
        {...props}
        source="fkParticipation"
        label="Proprietario selezionato"
        disabled
        md={3}
      /> */}
      <AppInputHidden source="fkSaleExperiment" />
      <AppInputHidden source="fkParticipation" />
      {owner ? (
        <ChipPanelField
          label="Proprietario gara:"
          value={
            owner.type === 'person'
              ? `${owner.firstName} ${owner.lastName} (${owner.taxCode ?? '-'})`
              : `${owner.businessName} (${owner.vat ?? '-'})`
          }
          className={classes.ownerChip}
        />
      ) : (
        <AppInputFiller md={6} />
      )}
    </AppCard>
  );
});
