// TODO: Move to SaleExperiment resource directory

import { SaleMode } from './constants';

export const isDetailComponentVisible = (
  fkSaleMode: number,
  WHERE_IS_VISIBLE: SaleMode[],
): boolean => {
  if (!fkSaleMode) return false;

  return WHERE_IS_VISIBLE.includes(fkSaleMode);
};
