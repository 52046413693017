import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { PaymentMethod } from '../Payment/constants';

export const InfoRequestPaymentExpand: FC<any> = ({ record }) => {
  return (
    <Grid container>
      <DetailPanelTitle>Dettagli pagamento</DetailPanelTitle>
      <DetailPanelField
        label="Importo"
        value={amountFormatter(record.amount)}
        md={record.feesAmount ? 2 : 12}
      />
      {!!record.feesAmount && (
        <DetailPanelField
          label="Commissioni di servizio"
          value={amountFormatter(record.feesAmount)}
          md={10}
        />
      )}
      {record.paymentMethod === PaymentMethod.WireTransfer && (
        <>
          <DetailPanelField label="CRO" value={record.cro} md={2} />
          <DetailPanelField label="Data CRO" value={dateFormatter(record.croDate, true)} md={2} />
        </>
      )}
      {record.paymentMethod === PaymentMethod.CreditCard && (
        <>
          <DetailPanelField label="Tipo carta di credito" value={record.creditCardType} md={2} />
          <DetailPanelField
            label="Intestatario carta"
            value={record.creditCardOwner?.name}
            md={2}
          />
          <DetailPanelField
            label="Email intestatario"
            value={record.creditCardOwner?.email}
            md={2}
          />
          <DetailPanelField
            label="ID transazione"
            value={record.transactionId ?? record.axervePaymentId ?? '-'}
            md={2}
          />
        </>
      )}
      <DetailPanelField label="Note" value={record.notes} md={12} />
    </Grid>
  );
};
