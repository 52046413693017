import { FC } from 'react';
import { ListProps } from 'react-admin';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';
import { InfoRequestLegend } from '../../Components/info-request/info-request-legend';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput, AppDateInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { INFO_REQUEST_COLORS } from '../../utils/constants';
import InfoRequestDatagrid from './InfoRequestDatagrid';
import InfoRequestExpanded from './InfoRequestExpanded';
import { INFO_REQUEST_STATE_CHOICES, INFO_REQUEST_TYPE_CHOICES } from './constants';

const InfoRequestListFiltersForAgencies = (
  <AppListFilter helperText="Nominativo">
    <AppSelectInput
      variant="outlined"
      source="requestStatus"
      choices={INFO_REQUEST_STATE_CHOICES}
      label="Stato richiesta"
      md={2}
    />
    <AppDateInput source="infoRequestsFrom" label="Richieste dal" md={2} />
    <AppDateInput source="infoRequestsTo" label="al" md={2} />
    <AppAutocompleteInput
      label="Richiedente"
      source="registryId"
      resettable
      reference="info-requests/get-people"
      optionText={(record) => {
        if (!record) return '';
        return `${record.lastName} ${record.firstName}`;
      }}
      md={4}
    />
  </AppListFilter>
);

const InfoRequestListFilters = (
  <AppListFilter helperText="Nominativo">
    <AppSelectInput
      variant="outlined"
      source="requestType"
      choices={INFO_REQUEST_TYPE_CHOICES}
      label="Tipo richiesta"
      md={2}
    />
    <AppSelectInput
      variant="outlined"
      source="requestStatus"
      choices={INFO_REQUEST_STATE_CHOICES}
      label="Stato richiesta"
      md={2}
    />
    <AppDateInput source="infoRequestsFrom" label="Richieste dal" md={2} />
    <AppDateInput source="infoRequestsTo" label="al" md={2} />
    <AppAutocompleteInput
      label="Richiedente"
      source="registryId"
      resettable
      reference="info-requests/get-people"
      optionText={(record) => {
        if (!record) return '';
        return `${record.lastName} ${record.firstName}`;
      }}
      md={4}
    />
  </AppListFilter>
);

const INFO_REQUEST_ENABLED = process.env.REACT_APP_INFO_REQUEST_ENABLED === 'true';

const InfoRequestList: FC<ListProps> = (props) => {
  return INFO_REQUEST_ENABLED ? (
    <AppList
      {...props}
      title={<AppTitle title="Richieste di info" />}
      hideCreateButton
      filters={
        props.permissions?.agency ? InfoRequestListFiltersForAgencies : InfoRequestListFilters
      }
    >
      <InfoRequestDatagrid
        expand={<InfoRequestExpanded />}
        rowStyle={(record) => ({
          backgroundColor: INFO_REQUEST_COLORS[record.requestStatus],
        })}
        additionalInfo={<InfoRequestLegend />}
      />
    </AppList>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità "Richieste info" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default InfoRequestList;
