export enum PaymentMethod {
  WireTransfer = 'wire-transfer', // bonifico bancario
  CreditCard = 'credit-card',
  BankGuarantee = 'bank-guarantee', // fideiussione
  Check = 'check', // assegno
  Other = 'other',
}

export const PAYMENT_METHODS = [
  { id: PaymentMethod.WireTransfer, name: 'Bonifico bancario' },
  { id: PaymentMethod.CreditCard, name: 'Carta di credito' },
  { id: PaymentMethod.BankGuarantee, name: 'Fideiussione' },
  { id: PaymentMethod.Check, name: 'Assegno circolare' },
  { id: PaymentMethod.Other, name: 'Altro' },
];

export enum PaymentReason {
  Deposit = 'deposit',
  Balance = 'balance',
}

export const PAYMENT_REASONS = [
  { id: PaymentReason.Deposit, name: 'Cauzione' },
  { id: PaymentReason.Balance, name: 'Saldo' },
];

export enum PaymentType {
  Received = 'received',
  Sent = 'sent',
}

export const PAYMENT_TYPES = [
  { id: PaymentType.Received, name: 'Inviato a utente' },
  { id: PaymentType.Sent, name: 'Ricevuto da utente' },
];

export const CREDIT_CARD_TYPES = [
  { id: 'VISA', name: 'Visa' },
  { id: 'MASTERCARD', name: 'Mastercard' },
  { id: 'ALTRO', name: 'Altro' },
];
