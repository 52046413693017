import { Badge, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountCircle as AccountIcon,
  Notifications as NoNotificationsIcon,
  NotificationsActive as OneOrMoreNotificationsIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { ResourceAction } from '../../../../Providers/AppPermissionsProvider';
import { useUserIsAllowed } from '../../../../hooks/use-user-is-allowed';
import AppTooltip from '../../tooltip/AppTooltip';
import { ActionsMenu } from './actions/actions-menu';
import { NotificationsList } from './notifications/notifications-list';

const useStyles = makeStyles(
  () => ({
    userInfo: {
      minHeight: '63px',
    },
    gridContainer: {
      height: '100%',
    },
  }),
  { name: 'AppProfile' },
);

interface AppProfileProps extends MenuItemProps<'li', { button: true }> {
  className?: string;
  redirectTo?: string;
  icon?: ReactElement;
}

const AppProfile: FC<AppProfileProps> = React.memo(() => {
  const classes = useStyles();

  // Actions Menu handlers
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
  const openActionsMenu = (event) => setActionsMenuAnchor(event.currentTarget);
  const closeActionsMenu = () => setActionsMenuAnchor(null);

  // Notifications list handlers
  const [notificationsListAnchor, setNotificationsListAnchor] = useState(null);
  const openNotificationsList = (event) => {
    setNotificationsListAnchor(event.currentTarget);
  };
  const closeNotificationsList = () => setNotificationsListAnchor(null);

  const userIsAllowedToReadNotifications = useUserIsAllowed(ResourceAction.Get, 'notifications');

  const [notificationsList, setNotificationsList] = useState<Record<string, any>[]>([]);
  const [mutateNotifications] = useMutation();
  const getUnreadNotifications = () => {
    mutateNotifications(
      {
        type: 'getList',
        resource: 'notifications/unread',
        payload: {},
      },
      {
        onSuccess: (res) => setNotificationsList(res.data),
        onFailure: (err) => {
          console.error('[getUnreadNotifications] Error:', err);
          setNotificationsList([]);
        },
      },
    );
  };

  useEffect(() => {
    if (!userIsAllowedToReadNotifications) return;

    // Refresh every min in order to get notifications updates
    getUnreadNotifications();
    const interval = setInterval(getUnreadNotifications, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [userIsAllowedToReadNotifications]);

  return (
    <div className={classes.userInfo}>
      <Grid container className={classes.gridContainer} justifyContent="center">
        <Grid item>
          <AppTooltip title="Profilo">
            <Button
              color="primary"
              onClick={openActionsMenu}
              style={{ width: '100%', height: '100%' }}
            >
              <AccountIcon />
            </Button>
          </AppTooltip>
        </Grid>
        {userIsAllowedToReadNotifications && (
          <Grid item>
            <AppTooltip title="Notifiche">
              <Button onClick={openNotificationsList} style={{ width: '100%', height: '100%' }}>
                <Badge badgeContent={notificationsList.length} color="primary">
                  {notificationsList.length > 0 ? (
                    <OneOrMoreNotificationsIcon />
                  ) : (
                    <NoNotificationsIcon />
                  )}
                </Badge>
              </Button>
            </AppTooltip>
          </Grid>
        )}
      </Grid>
      <ActionsMenu closeActionsMenu={closeActionsMenu} actionsMenuAnchor={actionsMenuAnchor} />
      {userIsAllowedToReadNotifications && (
        <NotificationsList
          notificationsListAnchor={notificationsListAnchor}
          closeNotificationsList={closeNotificationsList}
          notificationsList={notificationsList}
          setNotificationsList={setNotificationsList}
        />
      )}
    </div>
  );
});

AppProfile.propTypes = {
  redirectTo: PropTypes.string,
  icon: PropTypes.element,
};

export default AppProfile;
