import { Button, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';
import React, { FC, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { InfoRequestStatus, InfoRequestType } from '../../Resources/InfoRequest/enums';
import { useToggleState } from '../../hooks/use-toggle-state';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import { AppNumberInput } from '../ui/input';

const useStyles = makeStyles(
  () => ({
    button: {
      whiteSpace: 'nowrap',
      marginBottom: '8px',
    },
  }),
  { name: 'PaymentButton' },
);

const DEFAULT_PAYMENT_AMOUNT = 100;

type LinkType = 'first' | 'second';
type Action = 'copy' | 'create';

type PaymentButtonProps = {
  paymentLink: string | null;
  linkType: LinkType;
  onClick: (e: React.MouseEvent<HTMLButtonElement>, type: LinkType, action: Action) => void;
  loading: boolean;
};

const PaymentButton: FC<PaymentButtonProps> = ({ paymentLink, linkType, onClick, loading }) => {
  const classes = useStyles();

  return (
    <Button
      children={
        paymentLink
          ? `Copia${linkType === 'second' ? ' secondo' : ''} link di pagamento`
          : `Crea${linkType === 'second' ? ' secondo' : ''} link di pagamento`
      }
      onClick={(e) => onClick(e, linkType, paymentLink ? 'copy' : 'create')}
      variant="contained"
      startIcon={paymentLink ? <FileCopyIcon /> : <AddCircleOutlineIcon />}
      endIcon={<LinkIcon />}
      className={classes.button}
      disabled={loading}
      size="small"
    />
  );
};

export const CreateInfoRequestPaymentButton: FC<any> = React.memo(({ record: infoRequest }) => {
  const notify = useNotify();

  const [createInfoRequestPaymentDialogOpen, createInfoRequestPaymentDialogToggle] =
    useToggleState();

  const [firstPaymentLink, setFirstPaymentLink] = useState<string | null>(
    infoRequest.infoRequestPayments[0]
      ? `${infoRequest.infoRequestPayments[0].paymentLink}/${infoRequest.infoRequestPayments[0].id}`
      : null,
  );
  const [secondPaymentLink, setSecondPaymentLink] = useState<string | null>(
    infoRequest.infoRequestPayments[1]
      ? `${infoRequest.infoRequestPayments[1].paymentLink}/${infoRequest.infoRequestPayments[1].id}`
      : null,
  );

  const [paymentToHandle, setPaymentToHandle] = useState<LinkType>('first');

  const [createPaymentLink, { loading }] = useMutation();

  const copyPaymentLink = async () => {
    const paymentLink = paymentToHandle === 'first' ? firstPaymentLink : secondPaymentLink;

    if (!paymentLink) return;

    try {
      await navigator.clipboard.writeText(paymentLink);
      notify('Link di pagamento copiato negli appunti.', 'info');
    } catch (err) {
      console.error('Error copying payment link:', err);
      notify('Errore durante la copia del link.', 'error');
    }
  };

  const onCreateInfoRequestPaymentSubmit = (formData) => {
    createPaymentLink(
      {
        type: 'create',
        resource: `info-requests/${infoRequest.id}/create-payment`,
        payload: {
          data: {
            amount:
              formData.amount && !isNaN(formData.amount) ? formData.amount : DEFAULT_PAYMENT_AMOUNT,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          console.log('Payment link created:', data);
          notify('Link di pagamento creato con successo.', 'info');
          if (paymentToHandle === 'first') {
            setFirstPaymentLink(`${data.paymentLink}/${data.id}`);
          } else {
            setSecondPaymentLink(`${data.paymentLink}/${data.id}`);
          }
          createInfoRequestPaymentDialogToggle();
        },
        onFailure: (err) => {
          console.error('Error creating payment link:', err);
          notify(
            `Errore durante la creazione del link di pagamento: ${
              err?.message ?? 'ERRORE_SCONOSCIUTO'
            }`,
            'error',
          );
        },
      },
    );
  };

  const onPaymentButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    type: LinkType,
    action: Action,
  ) => {
    e.stopPropagation();

    setPaymentToHandle(type);

    if (action === 'copy') {
      return copyPaymentLink();
    }

    createInfoRequestPaymentDialogToggle();
  };

  const showButtons =
    infoRequest.requestType === InfoRequestType.Service &&
    infoRequest.requestStatus === InfoRequestStatus.Processing;

  return showButtons ? (
    <>
      <PaymentButton
        paymentLink={firstPaymentLink}
        linkType="first"
        onClick={onPaymentButtonClick}
        loading={loading}
      />
      {firstPaymentLink && (
        <PaymentButton
          paymentLink={secondPaymentLink}
          linkType="second"
          onClick={onPaymentButtonClick}
          loading={loading}
        />
      )}
      {(!firstPaymentLink || !secondPaymentLink) && (
        <AppFormDialog
          open={createInfoRequestPaymentDialogOpen}
          onClose={createInfoRequestPaymentDialogToggle}
          title="Creazione link di pagamento"
          onSubmit={onCreateInfoRequestPaymentSubmit}
          record={{
            amount: DEFAULT_PAYMENT_AMOUNT,
          }}
          width={64}
          height={36}
          canSubmitIfPristine
        >
          <AppNumberInput source="amount" label="Importo" isAmount required />
        </AppFormDialog>
      )}
    </>
  ) : (
    <></>
  );
});
