import PublicationRequestIcon from '@material-ui/icons/Input';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import PvpPublicationRequestList from './PvpPublicationRequestList';

export const PUBLICATION_REQUEST_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const PublicationRequest: ResourceExport = {
  list: PvpPublicationRequestList,
  icon: PublicationRequestIcon,
};

export default PublicationRequest;
