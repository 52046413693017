/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component } from 'react';
import _ from 'lodash';
import RGL, { WidthProvider } from 'react-grid-layout';

const ReactGridLayout = WidthProvider(RGL);

type Props = {
  onLayoutChange: (orders: any[]) => void;
  items: any[];
};

export default class ItemMediaVerticalLayout extends Component<Props> {
  static defaultProps = {
    className: 'layout',
    rowHeight: 110,
    cols: 1,
  };

  constructor(props: Props) {
    super(props);
  }

  generateDOM() {
    return _.map(_.range(this.props.items.length), (i) => {
      const record = this.props.items[i];
      return (
        <div
          key={i}
          style={{
            border: '2px dashed black',
            backgroundColor: 'lightgray',
          }}
        >
          {record.url && (
            <img
              src={record.url}
              height={'100px'}
              width={'100px'}
              style={{ marginTop: '2px', marginLeft: '5px' }}
              alt="Preview"
            />
          )}
          <span style={{ marginLeft: '32px' }}>{record.name}</span>
        </div>
      );
    });
  }

  render() {
    return <ReactGridLayout {...this.props}>{this.generateDOM()}</ReactGridLayout>;
  }
}
