import FrontEndUsersIcon from '@material-ui/icons/SupervisedUserCircle';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import FrontEndUsersDetails from './FrontEndUsersDetails';
import FrontEndUsersList from './FrontEndUsersList';

const { Auctioneer } = PersonRoles;

export const FRONT_END_USERS_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const FrontEndUsers: ResourceExport = {
  list: FrontEndUsersList,
  show: resourceWrapper(
    'show',
    FrontEndUsersDetails,
    undefined,
    undefined,
    FRONT_END_USERS_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    FrontEndUsersDetails,
    undefined,
    undefined,
    FRONT_END_USERS_PERMISSIONS,
  ),
  icon: FrontEndUsersIcon,
};

export default FrontEndUsers;
