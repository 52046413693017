import LinkIcon from '@material-ui/icons/Link';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import { PersonRoles } from '../../../utils/constants';
import LinkDetails from './LinkDetails';
import LinksList from './LinkList';

const { Auctioneer } = PersonRoles;

export const LINK_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const Link: ResourceExport = {
  list: LinksList,
  create: resourceWrapper('create', LinkDetails, undefined, undefined, LINK_PERMISSIONS),
  edit: resourceWrapper('edit', LinkDetails, undefined, undefined, LINK_PERMISSIONS),
  show: resourceWrapper('show', LinkDetails, undefined, undefined, LINK_PERMISSIONS),
  icon: LinkIcon,
};
export default Link;
