import { Grid } from '@material-ui/core';
import { get as _get } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { AppAutocompleteInput, AppTextInput } from '.';
import { AppGeographiesAutocomplete } from './AppGeographiesAutocomplete';
import translatedCountries from '../../../utils/countries.json';

type Props = {
  addressSource: string;
  zipCodeSource: string;
  countrySource: string;
  regionSource: string;
  provinceSource: string;
  citySource: string;
  foreignCitySource: string;
  disabled?: boolean;
  geocoderRef?: any;
} & Record<string, any>;

const AppInternationalGeographiesInput: FC<Props> = React.memo<Props>((props) => {
  const {
    variant,
    addressSource,
    addressNumberSource,
    zipCodeSource,
    countrySource,
    regionSource,
    provinceSource,
    citySource,
    foreignCitySource,
    mode = 'edit',
    required = false,
    disabled = false,
  } = props;

  const countryChoices = useMemo(() => {
    const parsedCountries: { id: string; name: string }[] = [];
    for (const key of Object.keys(translatedCountries)) {
      parsedCountries.push({
        id: key,
        name: translatedCountries[key],
      });
    }
    parsedCountries.sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      return 0;
    });
    const allCountries = [{ id: 'IT', name: 'Italia' }];
    allCountries.push(...parsedCountries.filter((c) => c.id !== 'IT'));
    return allCountries;
  }, []);

  const [foreignCountry, setForeignCountry] = useState<boolean>(false);

  const [regionChoices, setRegionChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [cityChoices, setCityChoices] = useState([]);

  const [inputsEnabled, setInputsEnabled] = useState<boolean>(mode === 'edit' && !disabled);

  // Get form mutators and values
  const { mutators: formMutators, change } = useForm();
  const { values } = useFormState();

  // Update coordinates once every necessary field is filled
  const prevAddressState = useRef({
    region: values[regionSource],
    province: values[provinceSource],
    city: values[citySource],
    foreignCity: values[foreignCitySource],
    zipCode: values[zipCodeSource],
    address: values[addressSource],
    addressNumber: values[addressNumberSource],
  });

  // Zip code initial state
  const [zipCodeChoices, setZipChoices] = useState(() => {
    if (mode === 'create') {
      return;
    }

    const zipCode = _get(values, zipCodeSource);
    if (!zipCode) {
      return [];
    }

    return [
      {
        id: zipCode,
        name: zipCode,
      },
    ];
  });

  // get mutation function for choices changes
  const [mutateRegions] = useMutation();
  const [mutateProvinces] = useMutation();
  const [mutateCities] = useMutation();

  // Update inputs on mode change
  useEffect(() => {
    setInputsEnabled((mode === 'edit' || mode === 'create') && !disabled);
  }, [mode]);

  // get initial choices using existing values if 'edit' mode
  useEffect(() => {
    const country = _get(values, countrySource);
    if (country && country !== 'IT') {
      setForeignCountry(true);
      change(zipCodeSource, null);
    }

    if (
      (mode === 'edit' || mode === 'show') &&
      !foreignCountry &&
      (!!_get(values, countrySource) ||
        !!_get(values, regionSource) ||
        !!_get(values, provinceSource))
    ) {
      getRegions(false);
      getProvinces(_get(values, regionSource), false);
      getCities(_get(values, provinceSource), false);
    }
  }, [_get(values, countrySource), _get(values, regionSource), _get(values, provinceSource)]);

  useEffect(() => {
    const region = values[regionSource];
    const province = values[provinceSource];
    const city = values[citySource];
    const foreignCity = values[foreignCitySource];
    const zipCode = values[zipCodeSource];
    const address = values[addressSource];
    const addressNumber = values[addressNumberSource];

    // All fields must be set
    if (!region || !province || !city || !foreignCity || !zipCode || !address) return;

    // Fields must have changed
    const { current } = prevAddressState;

    if (
      current.region === region &&
      current.province === province &&
      current.city === city &&
      current.foreignCity === foreignCity &&
      current.zipCode === zipCode &&
      current.address === address &&
      current.addressNumber === addressNumber
    )
      return;

    // Set new prev value
    prevAddressState.current = {
      region,
      province,
      city,
      foreignCity,
      zipCode,
      address,
      addressNumber,
    };
  }, [
    values[citySource],
    values[foreignCitySource],
    values[zipCodeSource],
    values[addressSource],
    values[addressNumberSource],
  ]);

  // Update zip code choices
  useEffect(() => {
    if (!_get(values, citySource)) {
      return;
    }

    const city: any = cityChoices.find((city: any) => city.id === _get(values, citySource));
    if (!city) return;

    const zipCodes = city.zipCode || [];
    setZipChoices(
      zipCodes.map((zip) => ({
        id: zip,
        name: zip,
      })),
    );

    if (zipCodes.length === 1) {
      change(zipCodeSource, zipCodes[0]);
    }
  }, [_get(values, citySource), cityChoices]);

  useEffect(() => {
    if (!_get(values, foreignCitySource)) {
      return;
    }
    change(foreignCitySource, _get(values, foreignCitySource));
  }, [_get(values, foreignCitySource)]);

  const handleCountryChanged = (countryId) => {
    setForeignCountry(countryId !== 'IT');
    if (countryId === 'IT') getRegions(countryId);
  };

  const getRegions = (changed = true) =>
    mutateRegions(
      {
        type: 'getList',
        resource: 'regions',
        payload: {
          filter: {
            fkCountry: 1,
          },
        },
      },
      {
        onSuccess: (res) => {
          setRegionChoices(res.data);

          if (changed) {
            //done only if the previous autocomplete has changed
            formMutators.clearInputs([regionSource, provinceSource, citySource, foreignCitySource]);
            setProvinceChoices([]);
            setCityChoices([]);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs([regionSource, provinceSource, citySource, foreignCitySource]);
          setRegionChoices([]);
          setProvinceChoices([]);
          setCityChoices([]);
        },
      },
    );

  const getProvinces = (regionId, changed = true) =>
    regionId &&
    mutateProvinces(
      {
        type: 'getList',
        resource: 'provinces',
        payload: {
          filter: {
            fkRegion: regionId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setProvinceChoices(res.data);

          if (changed) {
            formMutators.clearInputs([provinceSource, citySource, foreignCitySource]);
            setCityChoices([]);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs([provinceSource, citySource, foreignCitySource]);
          setProvinceChoices([]);
          setCityChoices([]);
        },
      },
    );

  const getCities = (provinceId, changed = true) =>
    provinceId &&
    mutateCities(
      {
        type: 'getList',
        resource: 'cities',
        payload: {
          filter: {
            fkProvince: provinceId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setCityChoices(res.data);

          if (changed) {
            formMutators.clearInputs(citySource, foreignCitySource);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs(citySource, foreignCitySource);
          setCityChoices([]);
        },
      },
    );

  const getComponent = (components, componentName, fieldName = 'long_name') => {
    for (let i = 0; i < components.length; i++) {
      for (let j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] === componentName) {
          /*
           *
           * Workaround to avoid problems with the mismatch between "Reggio Emilia" and "Reggio nell'Emilia" -> https://astech-ai.atlassian.net/browse/AS-2149
           * After finding a better solution we can restore the original code:
           * return components[i][fieldName];
           *
           */
          return componentName === 'administrative_area_level_3' &&
            components[i][fieldName] === 'Reggio Emilia'
            ? "Reggio nell'Emilia"
            : components[i][fieldName];
        }
      }
    }
  };

  const getCountry = (components) => {
    return getComponent(components, 'country', 'short_name');
  };

  const getRegion = (components) => {
    return getComponent(components, 'administrative_area_level_1');
  };

  /* MAY BE UNNECESSARY */
  /*const getProvince = (components) => {
    return getComponent(components, 'administrative_area_level_2');
  };*/

  const getProvinceCode = (components) => {
    return getComponent(components, 'administrative_area_level_2', 'short_name');
  };

  const getCity = (components) => {
    return (
      getComponent(components, 'postal_town') ||
      getComponent(components, 'administrative_area_level_3') ||
      getComponent(components, 'locality')
    );
  };

  const getStreet = (components) => {
    return getComponent(components, 'route');
  };

  const getStreetNumber = (components) => {
    return getComponent(components, 'street_number');
  };

  const getPostalCode = (components) => {
    return getComponent(components, 'postal_code');
  };

  const onPlaceChanged = (autocomplete) => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    const country = getCountry(place.address_components);

    const isForeignCountry = country !== 'IT';
    setForeignCountry(isForeignCountry);
    if (isForeignCountry) {
      change(countrySource, country);
      change(foreignCitySource, getCity(place.address_components));
      change(addressSource, getStreet(place.address_components));
      change(addressNumberSource, getStreetNumber(place.address_components));
      return;
    }

    let regionId;
    let provinceId;
    let cityId;
    let zipCodes;
    change(countrySource, 'IT');
    mutateRegions(
      {
        type: 'getList',
        resource: 'regions',
        payload: {
          filter: {
            fkCountry: 1,
          },
        },
      },
      {
        onSuccess: ({ data: regions }) => {
          setRegionChoices(regions);
          regionId =
            regions.find((region) => region.name === getRegion(place.address_components))?.id ?? -1;
          change(regionSource, regionId);
          mutateProvinces(
            {
              type: 'getList',
              resource: 'provinces',
              payload: {
                filter: {
                  fkRegion: regionId,
                },
              },
            },
            {
              onSuccess: ({ data: provinces }) => {
                setProvinceChoices(provinces);
              },
              onFailure: console.error,
            },
          );
          mutateProvinces(
            {
              type: 'getList',
              resource: 'provinces',
              payload: {
                filter: {
                  fkRegion: regionId,
                  initials: getProvinceCode(place.address_components),
                },
              },
            },
            {
              onSuccess: ({ data: provinces }) => {
                provinceId = provinces[0].id ?? -1;
                change(provinceSource, provinceId);
                mutateCities(
                  {
                    type: 'getList',
                    resource: 'cities',
                    payload: {
                      filter: {
                        fkProvince: provinceId,
                      },
                    },
                  },
                  {
                    onSuccess: ({ data: cities }) => {
                      setCityChoices(cities);
                      const city =
                        cities.find((city) => city.name === getCity(place.address_components)) ??
                        null;
                      cityId = city?.id ?? -1;
                      zipCodes = city.zipCode || [];
                      change(citySource, cityId);
                      setZipChoices(
                        zipCodes.map((zip) => ({
                          id: zip,
                          name: zip,
                        })),
                      );
                      if (getPostalCode(place.address_components)) {
                        change(zipCodeSource, getPostalCode(place.address_components));
                      } else if (zipCodes.length === 1) {
                        change(zipCodeSource, zipCodes[0]);
                      } else {
                        formMutators.clearInputs(zipCodeSource);
                      }
                    },
                    onFailure: console.error,
                  },
                );
              },
              onFailure: console.error,
            },
          );
        },
        onFailure: console.error,
      },
    );

    // Set address
    if (getStreet(place.address_components)) {
      change(addressSource, getStreet(place.address_components));
      change(addressNumberSource, getStreetNumber(place.address_components));
    } else {
      formMutators.clearInputs([addressSource, addressNumberSource]);
    }
  };

  //TODO: AGGIUNGERE IL MODO PER TROVARE LE COORDINATE DAI CAMPI ANZICHÉ DA PLACES API?
  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <p>Usa l'autocompletamento per cercare l'indirizzo</p>
      </Grid>
      <Grid item xs={12} md={12}>
        <AppGeographiesAutocomplete onPlaceChanged={onPlaceChanged} disabled={!inputsEnabled} />
      </Grid>
      <Grid item xs={12} md={12}>
        <p style={{ textAlign: 'center' }}>~~~ o ~~~</p>
      </Grid>
      <Grid item xs={12} md={12}>
        <p>Oppure compila manualmente i campi</p>
      </Grid>
      <AppAutocompleteInput
        source={countrySource}
        label="Nazione"
        variant={variant}
        md={3}
        onChange={handleCountryChanged}
        choices={countryChoices}
        disabled={!inputsEnabled}
        required={required}
      />
      {!foreignCountry && (
        <AppAutocompleteInput
          source={regionSource}
          label="Regione"
          variant={variant}
          md={3}
          choices={regionChoices}
          onChange={getProvinces}
          disabled={!inputsEnabled || regionChoices.length == 0}
          required={required}
        />
      )}
      {!foreignCountry && (
        <AppAutocompleteInput
          source={provinceSource}
          label="Provincia"
          variant={variant}
          md={3}
          choices={provinceChoices}
          onChange={getCities}
          disabled={!inputsEnabled || provinceChoices.length == 0}
          required={required}
        />
      )}
      {!foreignCountry ? (
        <AppAutocompleteInput
          source={citySource}
          label="Città"
          variant={variant}
          md={3}
          choices={cityChoices}
          disabled={!inputsEnabled || cityChoices.length === 0}
          required={required}
        />
      ) : (
        <AppTextInput
          source={foreignCitySource}
          label="Città estera"
          variant={variant}
          md={3}
          disabled={!inputsEnabled}
          required={required}
        />
      )}
      <AppTextInput
        source={addressSource}
        required={required}
        label="Indirizzo"
        variant="outlined"
        md={6}
        disabled={!inputsEnabled}
      />
      <AppTextInput
        source={addressNumberSource}
        label="Civico"
        variant="outlined"
        md={2}
        disabled={!inputsEnabled}
      />
      {!foreignCountry && (
        <AppAutocompleteInput
          source={zipCodeSource}
          required={required}
          label="CAP"
          md={4}
          variant={variant}
          choices={zipCodeChoices}
          disabled={!inputsEnabled || foreignCountry}
        />
      )}
    </React.Fragment>
  );
});

export default AppInternationalGeographiesInput;
