import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { BooleanField, FunctionField, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import {
  AppDateField,
  AppLinkToEntityField,
  AppTextField,
  AppUrlField,
} from '../../Components/ui/field';
import { AppText } from '../../Components/ui/text';
import {
  BOOKER_BASE_SOURCE,
  ITEM_BASE_SOURCE,
  PERSON_BASE_SOURCE,
  PERSON_TYPES,
} from './constants';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    name: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    publications: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(50),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
  }),
  { name: 'BookingDatagrid' },
);

const BookingDatagrid: FC<any> = (props) => {
  const classes = useStyles();

  return (
    <AppDatagrid {...props} expand={props.expand}>
      <AppDateField source="createdAt" label="Data richiesta" headerClassName={classes.createdAt} />
      <FunctionField
        label="Nominativo"
        render={(record) => (
          <AppTextField
            customText={
              record
                ? record[BOOKER_BASE_SOURCE].type === 'person'
                  ? `${record[BOOKER_BASE_SOURCE].lastName} ${record[BOOKER_BASE_SOURCE].firstName}`
                  : record[BOOKER_BASE_SOURCE].businessName
                : '-'
            }
          />
        )}
        headerClassName={classes.name}
      />
      <AppTextField source={`${BOOKER_BASE_SOURCE}.mobile`} label="Cellulare" />
      <AppTextField source={`${BOOKER_BASE_SOURCE}.email`} label="Email" />
      <SelectField
        choices={PERSON_TYPES}
        source={`${BOOKER_BASE_SOURCE}.type`}
        label="Tipo persona"
        headerClassName={classes.type}
      />
      <FunctionField
        label="Anagrafica richiedente"
        render={(r) => (
          <AppLinkToEntityField
            targetResource={r?.[PERSON_BASE_SOURCE].type === 'person' ? 'people' : 'companies'}
            originName="visite"
            value={() => r?.[PERSON_BASE_SOURCE].businessName}
            targetResourceId={() => r?.[PERSON_BASE_SOURCE].id}
          />
        )}
        headerClassName={classes.name}
      />
      <AppLinkToEntityField
        label="Lotto da visitare"
        targetResource="items"
        originName="visite"
        value={(r) => r[ITEM_BASE_SOURCE].title}
        targetResourceId={(r) => r[ITEM_BASE_SOURCE].id}
        truncate
        headerClassName={classes.itemTitle}
      />
      <FunctionField
        label="Pubblicazioni del lotto"
        headerClassName={classes.publications}
        render={(booking) =>
          booking?.item.publications && booking.item.publications.some((p) => p.isPublished) ? (
            booking.item.publications
              .filter((p) => p.isPublished)
              .map((p) => (
                <AppUrlField
                  url={`${p.property.host}/annunci/${p.slug}`}
                  text={p.property.name}
                  component="p"
                />
              ))
          ) : (
            <AppText children="Nessuna pubblicazione attiva su questo lotto" />
          )
        }
      />
      <BooleanField
        source="alreadyExported"
        label="Già esportato"
        headerClassName={classes.lastCol}
      />
    </AppDatagrid>
  );
};

export default BookingDatagrid;
