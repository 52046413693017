import { Checkbox } from '@material-ui/core';
import { FC, useMemo } from 'react';

interface MediaSelectButtonProps {
  selectedMedia: number[];
  setSelectedMedia: React.Dispatch<React.SetStateAction<number[]>>;
  record?: Record<string, any>;
}

export const MediaSelectButton: FC<MediaSelectButtonProps> = ({
  record,
  selectedMedia,
  setSelectedMedia,
}) => {
  const checked = useMemo(
    () => selectedMedia.findIndex((mediaId) => mediaId === record?.id) > -1,
    [selectedMedia, record?.id],
  );

  const onClick = (e) => {
    if (!record) return;

    setSelectedMedia((prevSelectedMedia) => {
      if (e.target.checked) {
        return [...prevSelectedMedia, record.id];
      }
      return prevSelectedMedia.filter((id) => id !== record.id);
    });
  };

  return <Checkbox color="primary" onClick={onClick} checked={checked} />;
};
