import {
  Dialog,
  DialogActions,
  DialogClassKey,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { FC } from 'react';

interface AppDialogProps {
  open: boolean;
  onClose: () => void;
  title: any;
  content: any;
  actions: any;
  classes?: Partial<Record<DialogClassKey, string>>;
}

export const AppDialog: FC<AppDialogProps> = ({ title, content, actions, ...rest }) => (
  <Dialog {...rest}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>{content}</DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);
