import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import { FunctionComponent } from 'react';
import { Button } from 'react-admin';

const useStyles = makeStyles(
  () => ({
    downloadButton: {
      display: 'flex',
      margin: '0 auto',
    },
  }),
  { name: 'AppDownloadMediaButton' },
);

type AppDownloadMediaButtonProps = {
  fileName: string;
  base64: string;
};

const AppDownloadMediaButton: FunctionComponent<AppDownloadMediaButtonProps> = (props) => {
  const { fileName, base64 } = props;
  const classes = useStyles();

  const downloadMedia = () => {
    const a = document.createElement('a');
    a.href = base64;
    a.download = fileName;
    a.click();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => downloadMedia()}
      startIcon={<CloudDownload />}
      className={classes.downloadButton}
      label="Scarica"
    />
  );
};

export default AppDownloadMediaButton;
