import { ReactElement, useState } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppDateInput, AppTextInput } from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import AppInternationalGeographiesNoAddressInput from '../../Components/ui/input/AppInternationalGeographiesNoAddressInput';
import { Button, FunctionField, ReferenceField, useMutation, useNotify } from 'react-admin';
import { green, red } from '@material-ui/core/colors';

const FrontEndUsersProfileModificationDetails = (props: Record<any, any>): ReactElement => {
  const notify = useNotify();
  const [mutateAcceptRequest] = useMutation();
  const [mutateRejectRequest] = useMutation();

  const [status, setStatus] = useState<string>(props?.record?.status ?? 'pending');

  const handleAcceptRequest = () =>
    mutateAcceptRequest(
      {
        type: 'update',
        resource: `profile-modification-requests/${props.id}/accept`,
        payload: {},
      },
      {
        onSuccess: () => {
          setStatus('accepted');
          notify('Richiesta accettata con successo', 'info');
        },
        onFailure: (err) => {
          notify('Non è stato possibile completare la richiesta', 'error');
          console.error(err);
        },
      },
    );

  const handleRejectRequest = () =>
    mutateRejectRequest(
      {
        type: 'update',
        resource: `profile-modification-requests/${props.id}/reject`,
        payload: {},
      },
      {
        onSuccess: () => {
          setStatus('rejected');
          notify('Richiesta rifiutata con successo', 'info');
        },
        onFailure: (err) => {
          notify('Non è stato possibile completare la richiesta', 'error');
          console.error(err);
        },
      },
    );

  const path =
    props?.record?.fkProperty === 1
      ? 'front-end-users'
      : props?.record?.fkProperty === 2
      ? 'front-end-users-immo'
      : props?.record?.fkProperty === 7
      ? 'front-end-users-cda'
      : 'front-end-users';

  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale">
        <AppCard title="Verifica dati" expanded>
          <Button
            label={status === 'accepted' ? 'Autorizzato' : 'Autorizza'}
            disabled={status === 'accepted' || status === 'rejected'}
            onClick={handleAcceptRequest}
            style={{
              background: status === 'accepted' ? green[400] : 'white',
              color: status === 'accepted' ? 'white' : 'black',
            }}
          />
          <Button
            label={status === 'rejected' ? 'Rifiutato' : 'Rifiuta'}
            disabled={status === 'accepted' || status === 'rejected'}
            style={{
              margin: '10px',
              background: status === 'rejected' ? red[400] : 'white',
              color: status === 'rejected' ? 'white' : 'black',
            }}
            onClick={handleRejectRequest}
          />
          <ReferenceField
            label="Utente"
            reference={path}
            source="userId"
            basePath={`/${path}`}
            link={true}
          >
            <Button
              label={'Vai alla modifica manuale'}
              style={{
                margin: '10px',
                marginLeft: '30px',
              }}
            />
          </ReferenceField>

          <AppDivider />
        </AppCard>
        <AppCard title="Anagrafica" expanded>
          <AppDateInput source="createdAt" label="Data richiesta" showTime disabled md={3} />
          <ReferenceField
            label="Utente"
            reference={path}
            source="userId"
            basePath={`/${path}`}
            link={true}
          >
            <FunctionField
              render={(record) => `${record?.firstName} ${record?.lastName}`}
              style={{
                marginLeft: '15px',
                borderBottom: '1px solid #ccc',
                padding: '5px',
              }}
            />
          </ReferenceField>
          <AppDivider />
        </AppCard>
        <AppCard title="(NUOVI) Dati anagrafici e luogo di nascita" expanded>
          <AppTextInput source="data.firstName" label="Nome" disabled md={4} />
          <AppTextInput source="data.lastName" label="Cognome" disabled md={4} />
          <AppDateInput source="data.birthday" label="Data di nascita" disabled md={4} />
          <AppInternationalGeographiesNoAddressInput
            variant="outlined"
            countrySource={'data.birthCountry'}
            regionSource={'data.birthRegion'}
            provinceSource={'data.birthProvince'}
            citySource={'data.birthCity'}
            foreignCitySource={'data.birthForeignCity'}
            mode={props.mode}
            disabled
          />
        </AppCard>

        <AppCard title="(VECCHI) Dati anagrafici eluogo di nascita" expanded>
          <AppTextInput source="data.oldFirstName" label="Nome" disabled md={4} />
          <AppTextInput source="data.oldLastName" label="Cognome" disabled md={4} />
          <AppDateInput source="data.oldBirthday" label="Data di nascita" disabled md={4} />

          <AppInternationalGeographiesNoAddressInput
            variant="outlined"
            countrySource={'data.oldBirthCountry'}
            regionSource={'data.oldBirthRegion'}
            provinceSource={'data.oldBirthProvince'}
            citySource={'data.oldBirthCity'}
            foreignCitySource={'data.oldBirthForeignCity'}
            mode={props.mode}
            disabled
          />
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default FrontEndUsersProfileModificationDetails;
