import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import AppAlert from '../ui/alert/AppAlert';
import { AppText } from '../ui/text';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import { INFO_REQUEST_COLORS } from '../../utils/constants';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'InfoRequestLegend' },
);

export const InfoRequestLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: INFO_REQUEST_COLORS.pending }} />
        Richiesta in attesa
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: INFO_REQUEST_COLORS.processing }} />
        Richiesta in gestione
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: INFO_REQUEST_COLORS.completed }} />
        Richiesta completata
      </AppText>
    </AppAlert>
  );
};
