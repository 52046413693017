import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Lock } from '@material-ui/icons';
import { FC, Fragment, useCallback, useState } from 'react';
import { useEditContext, useMutation, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import AppMessageDialog from '../../Components/ui/dialog/message-dialog/app-message-dialog';
import { AppSelectInput } from '../../Components/ui/input';
import AppToolbarAction from '../../Components/ui/layout/AppToolbarAction';
import AppToolbarReEnableAccess from './AppToolbarReEnableAccess';
import { PlatformAccessState } from './enums';

const useStyles = makeStyles((theme: Theme) => {
  const contentWidth = 400;

  return {
    card: {
      marginBottom: theme.spacing(1),
      fontSize: '0.8rem',
      width: contentWidth,
      '& p': {
        textAlign: 'justify',
        margin: '0.3rem 0',
      },
      '& p:first-child': {
        marginTop: 0,
      },
      '& p:last-child': {
        marginBottom: 0,
      },
    },
    select: {
      width: contentWidth,
    },
  };
});

const AppToolbarEnableAccess: FC<any> = (props) => {
  const { handleClose: closeMenu, record } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [choices, setChoices] = useState([]);
  const [message, setMessage] = useState('');
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const { $formStatus } = useEditContext() as any;
  const classes = useStyles();

  const openDialog = (e) => {
    e.stopPropagation();

    closeMenu();

    setHasError($formStatus?.dirty || !record.email);

    if ($formStatus?.dirty) {
      setMessage('Sono presenti delle modifiche non salvate.');
      setDialogOpen(true);
      return;
    }

    if (!record.email) {
      setMessage("Per attivare l'accesso è necessario specificare una mail per l'utente.");
      setDialogOpen(true);
      return;
    }

    if (record.accessState !== PlatformAccessState.Inactive) return setDialogOpen(true);

    mutate(
      {
        type: 'getList',
        resource: 'roles',
        payload: { filter: { _source: 'auth' } },
      },
      {
        onSuccess: (res) => {
          setChoices(res.data);
          setDialogOpen(true);
        },
        onFailure: (err) => {
          console.error(err);
          notify('Impossibile scaricare la lista dei ruoli. Impossibile procedere.', 'error');
        },
      },
    );
  };
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  const changeStatus = (data) => {
    mutate(
      {
        type: 'create',
        resource: 'auth/set-access-state',
        payload: {
          data: {
            user: record.id,
            state: 'active',
            role: data.role,
          },
        },
      },
      {
        onSuccess: () => {
          setDialogOpen(false);
          refresh();
          notify(
            "L'utente è stato attivato e riceverà a breve un'email per la creazione della password.",
            'info',
          );
        },
        onFailure: (err) => {
          console.error(err);
          notify(`Attivazione fallita. ${err.message}.`);
        },
      },
    );
  };

  return (
    <Fragment>
      <AppToolbarAction onClick={openDialog} text="Attiva accesso" icon={Lock} />
      {hasError ? (
        <AppMessageDialog
          open={dialogOpen}
          title="Impossibile procedere"
          content={message}
          onClose={closeDialog}
        />
      ) : record?.accessState === PlatformAccessState.Inactive ? (
        <Dialog open={dialogOpen}>
          <DialogTitle>Attivazione accesso</DialogTitle>
          <Form
            onSubmit={changeStatus}
            initialValues={{ role: '' }}
            render={({ handleSubmit, submitting, hasValidationErrors }) => (
              <Fragment>
                <DialogContent>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <p>
                        Stai abilitando <b>{`${record.lastName} ${record.firstName}`}</b> ad
                        accedere alla piattaforma.
                      </p>
                      <p>
                        Per proseguire è necessario aver registrato la mail dell'utente e indicare
                        un ruolo tra quelli presenti nel campo sottostante.
                      </p>
                      <p>
                        Una volta confermata l'attivazione sarà inviata una mail all'utente, nella
                        quale troverà descrizione della procedura da seguire per creare la propria
                        password personale, necessaria per accedere al sistema.
                      </p>
                    </CardContent>
                  </Card>
                  <AppSelectInput
                    variant="outlined"
                    margin="dense"
                    source="role"
                    label="Ruolo"
                    className={classes.select}
                    choices={choices}
                    required
                    optionText={(o) => {
                      if (!o) return '';
                      return o.description;
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button children="Annulla" onClick={closeDialog} />
                  <Button
                    children="Conferma"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={submitting || hasValidationErrors}
                  />
                </DialogActions>
              </Fragment>
            )}
          />
        </Dialog>
      ) : (
        <AppToolbarReEnableAccess {...props} open={dialogOpen} handleClose={closeDialog} />
      )}
    </Fragment>
  );
};

export default AppToolbarEnableAccess;
