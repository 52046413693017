import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { AppTextInput } from '../ui/input';
import { AppAutocompleteInput } from '../ui/input/AppAutocompleteInput';
import AppDivider from '../ui/layout/AppDivider';

const AddPersonWithRole: FC<any> = (props) => {
  const {
    open,
    setOpen,
    courtId,
    type,
    currentChoices,
    formValueChanger,
    capabilityFormValues,
    getPeople,
    getPersonRoles,
    changeTarget,
    roleId,
  } = props;

  const notify = useNotify();

  let resource = '';
  let label = '';
  let autocompleteReference = '';
  switch (type) {
    case 'person':
      resource = 'people/addPersonAndOrRole';
      label = 'Persona';
      autocompleteReference = 'people';
      break;
    case 'company':
      resource = 'companies/addCompanyAndOrRole';
      label = 'Azienda';
      autocompleteReference = 'companies';
      break;
  }

  const [create] = useCreate(resource);
  const submit = (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: (res) => {
          getPeople ? getPeople(capabilityFormValues.fkRole) : getPersonRoles();
          formValueChanger(changeTarget ?? 'fkPerson', res.data.id);
          notify('Anagrafica collegata con successo.', 'info');
          setOpen(false);
        },
        onFailure: (err) => {
          console.error(err);
          notify(err.message ?? err, 'error');
        },
      },
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkCurrentPeopleChoices = (personId) => {
    const searchChoice = currentChoices.find((person) => person.id === personId);
    if (searchChoice) {
      formValueChanger(changeTarget ?? 'personId', personId);
      setOpen(false);
      notify("L'anagrafica selezionata è già collegata al ruolo selezionato.", 'info');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        Collega una {type === 'person' ? 'persona' : 'azienda'} al ruolo selezionato
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={(values) => {
            values.roleId = roleId ?? capabilityFormValues.fkRole;
            values.courtId = courtId;
            submit(values);
          }}
          render={(formProps) => {
            const { submitting, handleSubmit, pristine, values } = formProps;
            return (
              <Grid container spacing={2}>
                <AppAutocompleteInput
                  md={12}
                  xs={6}
                  variant="outlined"
                  label={label}
                  reference={autocompleteReference}
                  optionText={(option) => {
                    if (!option) return '';
                    return type === 'person'
                      ? `${option.lastName} ${option.firstName}`
                      : `${option.businessName} (${option.vat})`;
                  }}
                  source="registryId"
                  resettable
                  onChange={checkCurrentPeopleChoices}
                />
                <AppDivider />
                {type === 'person' && !values.registryId && (
                  <React.Fragment>
                    <AppTextInput source="firstName" label="Nome" required variant="outlined" />
                    <AppTextInput source="lastName" label="Cognome" required variant="outlined" />
                    <AppTextInput source="email" type="email" label="Email" variant="outlined" />
                    <AppTextInput source="taxCode" label="Codice fiscale" variant="outlined" />
                  </React.Fragment>
                )}
                {type === 'company' && !values.registryId && (
                  <React.Fragment>
                    <AppTextInput
                      source="businessName"
                      label="Ragione sociale"
                      required
                      variant="outlined"
                    />
                    <AppTextInput source="vat" required label="Partita IVA" variant="outlined" />
                    <AppTextInput
                      source="email"
                      type="email"
                      required
                      label="Email"
                      variant="outlined"
                    />
                    <Grid item md={6} />
                  </React.Fragment>
                )}
                <Grid item md={2}>
                  <Button onClick={handleSubmit} color="primary" disabled={submitting || pristine}>
                    Collega
                  </Button>
                </Grid>
                <Grid item md={2}>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Annulla
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddPersonWithRole;
