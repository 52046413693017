import { Folder as FolderIcon } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import FolderDetails from './FolderDetails';
import FolderList from './FolderList';

const { Auctioneer } = PersonRoles;

export const FOLDER_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const Folder: ResourceExport = {
  list: FolderList,
  create: resourceWrapper('create', FolderDetails, undefined, undefined, FOLDER_PERMISSIONS),
  edit: resourceWrapper('edit', FolderDetails, undefined, undefined, FOLDER_PERMISSIONS),
  show: resourceWrapper('show', FolderDetails, undefined, undefined, FOLDER_PERMISSIONS),
  icon: FolderIcon,
};

export default Folder;
