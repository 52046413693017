import { GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AssignmentTurnedIn as NoSalesIcon } from '@material-ui/icons';
import { FC, useMemo } from 'react';
import { SelectField } from 'react-admin';
import { useHistory } from 'react-router';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import { useDashboardContext } from '../../../hooks';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { AUCTION_STATUS, PersonRoles } from '../../../utils/constants';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useDatagridColumnsStyles = makeStyles(
  (theme) => ({
    pvpId: {
      minWidth: theme.spacing(10),
    },
    procedureCode: {
      minWidth: theme.spacing(25),
    },
    procedureTitle: {
      minWidth: theme.spacing(40),
    },
    itemTitle: {
      minWidth: theme.spacing(40),
    },
    saleMode: {
      minWidth: theme.spacing(25),
    },
    auctionStatus: {
      minWidth: theme.spacing(30),
    },
    auctionDates: {
      minWidth: theme.spacing(40),
    },
    auctionPrices: {
      minWidth: theme.spacing(25),
    },
  }),
  { name: 'datagridColumnStyles' },
);

const AppDashboardSaleExperimentsToBePaid: FC<GridProps> = (gridProps) => {
  const datagridColumnsStyles = useDatagridColumnsStyles();

  const { isIvg, user } = useDashboardContext();
  const resource = useMemo(
    () =>
      isIvg
        ? 'sale-experiments-judiciary'
        : user?.agency
        ? 'sale-experiments-private'
        : 'sale-experiments',
    [isIvg, user],
  );
  const allowView = useUserIsAllowed(ResourceAction.Get, resource);
  const history = useHistory();

  if (!allowView) return null;

  if (user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...gridProps} md={isIvg ? 12 : 6}>
      <AppDashboardTable
        resource={`${resource}/to-be-paid`}
        filter={{
          _source: 'list',
        }}
        tableTitle="Vendite ancora da saldare"
        dataError="Non è stato possibile recuperare le vendite ancora da saldare."
        noItemMessage="Nessuna vendita ancora da saldare trovata."
        noItemIcon={<NoSalesIcon />}
        rowClick={(_id, _basePath, record) =>
          history.push(
            `${resource}/${record.id}/participants?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
          )
        }
      >
        <AppTextField
          source="relatedItem.relatedFolder.pvpProcedureId"
          label="Id PVP"
          headerClassName={datagridColumnsStyles.pvpId}
        />
        <AppTextField
          source="relatedItem.relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={datagridColumnsStyles.procedureCode}
        />
        <AppTextField
          source="relatedItem.relatedFolder.title"
          label="Nome procedura"
          headerClassName={datagridColumnsStyles.procedureTitle}
          truncate
        />
        <AppTextField
          source="relatedItem.title"
          label="Titolo lotto"
          truncate
          headerClassName={datagridColumnsStyles.itemTitle}
        />
        <AppTextField
          source="relatedSaleMode.description"
          label="Modalità di vendita"
          headerClassName={datagridColumnsStyles.saleMode}
        />
        <SelectField
          source="auctionStatus"
          label="Stato asta"
          choices={AUCTION_STATUS}
          headerClassName={datagridColumnsStyles.auctionStatus}
        />
        <AppTextField
          source="basePrice"
          label="Prezzo base"
          type="amount"
          headerClassName={datagridColumnsStyles.auctionPrices}
        />
        <AppTextField
          source="minPrice"
          label="Prezzo minimo"
          type="amount"
          headerClassName={datagridColumnsStyles.auctionPrices}
        />
        <AppDateField
          source="auctionStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={datagridColumnsStyles.auctionDates}
        />
        <AppDateField
          source="auctionEndAt"
          label="Data/ora termine asta"
          showTime
          headerClassName={datagridColumnsStyles.auctionDates}
        />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardSaleExperimentsToBePaid;
