import { GridProps } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FC } from 'react';
import { useHistory } from 'react-router';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';
import InfoRequestDatagrid from '../../../Resources/InfoRequest/InfoRequestDatagrid';
import { useDashboardContext } from '../../../hooks';
import { PersonRoles } from '../../../utils/constants';

const AppDashboardInfoRequests: FC<GridProps> = (props) => {
  const history = useHistory();
  const { user } = useDashboardContext();

  if (user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...props} md={12}>
      <AppDashboardTable
        resource="info-requests"
        pagination={{
          page: 1,
          perPage: 12,
        }}
        tableTitle="Ultime richieste di informazioni"
        tableSubtitle="Di seguito sono elencate le ultime 10 richieste di informazioni"
        noItemMessage="Non sono presenti richieste di informazioni"
        noItemIcon={<InfoIcon />}
        CustomDatagrid={
          <InfoRequestDatagrid
            rowClick={() => {
              history.push('info-requests');
            }}
          />
        }
      />
    </AppDashboardCard>
  );
};

export default AppDashboardInfoRequests;
