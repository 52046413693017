import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ChipField, ListProps, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppEditButton from '../../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../../Components/ui/detail/AppList';
import { AppImageField } from '../../../Components/ui/field/AppImageField';
import AppTextField from '../../../Components/ui/field/AppTextField';
import { AppTextInput } from '../../../Components/ui/input';
import AppListFilter from '../../../Components/ui/layout/AppListFilter';

import AppTitle from '../../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    thumbnail: {
      minWidth: theme.spacing(30),
    },
    rgeNumber: {
      minWidth: theme.spacing(30),
    },
    title: {
      minWidth: theme.spacing(30),
    },
    slug: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'HighlightsList' },
);

const HighlightsFilters = (
  <AppListFilter disableFulltextSearch>
    <AppTextInput source="rgeNumber" label="Numero Rge" />
    <AppTextInput source="slug" label="Slug" />
  </AppListFilter>
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const HighlightList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Procedure in evidenza" />}
      filters={HighlightsFilters}
    >
      <AppDatagrid resource="highlights" rowClick="show">
        <AppImageField source="thumbnail" label="Thumbnail" headerClassName={classes.thumbnail} />
        <AppTextField source="rgeNumber" label="Numero Rge" headerClassName={classes.rgeNumber} />
        <AppTextField source="title" label="Titolo" headerClassName={classes.title} />
        <AppTextField source="slug" label="Slug" headerClassName={classes.slug} />
        {!IS_IVG && (
          <ReferenceArrayField
            reference="properties"
            source="propertyIds"
            label="Siti"
            headerClassName={classes.lastCol}
          >
            <SingleFieldList>
              <ChipField source="name" style={{ marginBottom: '15px' }} />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default HighlightList;
