import { Grid, makeStyles } from '@material-ui/core';
import { Record as RaRecord } from 'ra-core/esm/types';
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { useHistory } from 'react-router';
import { CompletedAdvertisementInfo } from '../../../../Resources/SaleExperiment/types';
import { amountFormatter, dateFormatter } from '../../../../utils/data-formatters';
import { DetailPanelField, DetailPanelTitle } from '../../../detail-panel';
import AppDivider from '../../../ui/layout/AppDivider';

const useStyles = makeStyles(
  () => ({
    winner: {
      cursor: 'pointer',
    },
  }),
  { name: 'AdvertisementOutcomeCompletedSold' },
);

interface AdvertisementOutcomeCompletedSoldProps {
  completedAuctionInfo: CompletedAdvertisementInfo;
  saleExperiment: RaRecord;
}

export const AdvertisementOutcomeCompletedSold: FC<AdvertisementOutcomeCompletedSoldProps> = ({
  completedAuctionInfo,
  saleExperiment,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [mutateWinner, { loading }] = useMutation();
  const [winner, setWinner] = useState<Record<string, any> | undefined>(undefined);
  const getWinner = (winnerId: number): void =>
    mutateWinner(
      {
        type: 'getOne',
        resource: 'participations',
        payload: {
          id: winnerId,
        },
      },
      {
        onSuccess: (res) => setWinner(res.data),
        onFailure: (err) => console.error(err),
      },
    );

  useEffect(() => {
    // Get winner info if exists
    const winnerId = completedAuctionInfo
      ? completedAuctionInfo.winner
      : saleExperiment
      ? saleExperiment.winner
      : undefined;
    if (winnerId) {
      getWinner(winnerId);
    }
  }, [completedAuctionInfo, saleExperiment]);

  return (
    <Grid container spacing={3}>
      <DetailPanelTitle variant="h5" children="Bene venduto" />
      <AppDivider />
      <DetailPanelField
        label="Vincitore"
        value={
          winner
            ? `${winner.presenter.lastName} ${winner.presenter.firstName} (${
                winner.presenter.taxCode ?? '-'
              })`
            : loading
            ? 'Caricamento in corso...'
            : 'Non indicato'
        }
        onClick={() =>
          winner &&
          history.push(
            `/participants/${winner.presenter.id}/show?goBack=true&resourceLabel=Esperimento di vendita&previousResourcePath=${history.location.pathname}`,
          )
        }
        className={classes.winner}
      />
      <DetailPanelTitle variant="h6" children="Dati di vendita" />
      <DetailPanelField
        label="Prezzo di vendita"
        value={amountFormatter(completedAuctionInfo.awardPrice)}
        md={3}
      />
      <DetailPanelField
        label="Data/ora vendita"
        value={dateFormatter(completedAuctionInfo.soldAt)}
        md={3}
      />
    </Grid>
  );
};
