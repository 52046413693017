import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ListProps, ReferenceField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    fileName: {
      minWidth: theme.spacing(30),
    },
    dates: {
      minWidth: theme.spacing(30),
    },
    genre: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    buttons: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'InvoiceList' },
);

const InvoiceList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList {...props} title={<AppTitle title="Fatturazioni CSV" />}>
      <AppDatagrid rowClick="show">
        <AppDateField source="createdAt" label="Data creazione" headerClassName={classes.dates} />
        <AppTextField source="title" label="Titolo" truncate headerClassName={classes.fileName} />
        <AppTextField
          source="fileName"
          label="Nome file"
          truncate
          headerClassName={classes.fileName}
        />
        <AppDateField
          source="soldAtFrom"
          label="Data aggiudicazione dal"
          headerClassName={classes.dates}
        />
        <AppDateField
          source="soldAtTo"
          label="Data aggiudicazione al"
          headerClassName={classes.dates}
        />
        <ReferenceField
          reference="genres"
          source="genreId"
          label="Genere"
          link={false}
          emptyText="-"
          headerClassName={classes.genre}
        >
          <AppTextField source="description" />
        </ReferenceField>
        <ReferenceField
          reference="sale-modes"
          source="saleModeId"
          label="Modalità di vendita"
          link={false}
          emptyText="-"
          headerClassName={classes.lastCol}
        >
          <AppTextField source="description" />
        </ReferenceField>
        <AppDownloadFromGCPBucketField
          label="CSV"
          protectedFile
          sortable={false}
          alwaysShow
          headerClassName={classes.buttons}
          cellClassName={classes.buttons}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default InvoiceList;
