import PublicationProofIcon from '@material-ui/icons/CameraEnhance';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import PublicationProofDetails from './PublicationProofDetails';
import PublicationProofList from './PublicationProofList';

const PublicationProof: ResourceExport = {
  list: PublicationProofList,
  create: resourceWrapper('create', PublicationProofDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', PublicationProofDetails, undefined, undefined, undefined),
  icon: PublicationProofIcon,
};

export default PublicationProof;
