import queryString from 'query-string';
import { FC, Fragment, useMemo } from 'react';
import { Button } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';

const AppGoToResourceButton: FC<AppGoToResourceButtonProps> = ({
  goBack = true,
  currentResourceLabel = 'risorsa precedente',
  currentResourceId,
  destinationResourceId,
  mode,
  destinationResourceName,
  buttonLabel,
  listQueryString,
  detailParameters,
  record,
  disabled,
  hidden,
  className,
  color = 'primary',
  size = 'small',
  variant: customVariant,
  action = ResourceAction.Get,
  targetBlank = false,
}) => {
  const { pathname } = useLocation();

  const userIsAllowed = useUserIsAllowed(action, destinationResourceName);

  // Get additional query string params
  const additionalParams = useMemo(
    () => (detailParameters ? queryString.stringify(detailParameters) : ''),
    [detailParameters],
  );

  const buttonOptions = useMemo(() => {
    let goTo: string, buttonText: string, variant: 'text' | 'outlined' | 'contained';
    switch (mode) {
      case 'list':
        goTo = `/${destinationResourceName}${
          listQueryString && listQueryString.length > 0 ? `?${listQueryString}` : ''
        }`;
        buttonText = buttonLabel ?? 'Vai alla lista ';
        variant = 'outlined';
        break;

      case 'create':
        if (!currentResourceId) {
          throw new Error("L'id dell'elemento della risorsa corrente non esiste.");
        }

        goTo = `/${destinationResourceName}/${mode}?currentResourceId=${currentResourceId}&goBack=${goBack}&resourceLabel=${currentResourceLabel}&previousResourcePath=${pathname}${
          additionalParams.length > 0 ? `&${additionalParams}` : ''
        }`;
        buttonText = buttonLabel ?? 'Inserisci';
        variant = 'contained';
        break;

      case 'edit':
        if (!destinationResourceId && !record) {
          throw new Error("L'id dell'elemento della risorsa di destinazione non esiste.");
        }

        goTo = `/${destinationResourceName}/${
          destinationResourceId ?? record?.id
        }?goBack=${goBack}&resourceLabel=${currentResourceLabel}&previousResourcePath=${pathname}${
          additionalParams.length > 0 ? `&${additionalParams}` : ''
        }`;
        buttonText = buttonLabel ?? 'Modifica';
        variant = 'outlined';
        break;

      case 'show':
        if (!destinationResourceId && !record) {
          throw new Error("L'id dell'elemento della risorsa di destinazione non esiste.");
        }

        goTo = `/${destinationResourceName}/${
          destinationResourceId ?? record?.id
        }/${mode}?goBack=${goBack}&resourceLabel=${currentResourceLabel}&previousResourcePath=${pathname}${
          additionalParams.length > 0 ? `&${additionalParams}` : ''
        }`;
        buttonText = buttonLabel ?? 'Mostra';
        variant = 'outlined';
        break;
    }

    return {
      goTo,
      buttonText,
      variant,
    };
  }, [mode, additionalParams, listQueryString]);

  if (hidden) return <Fragment />;

  return (
    <Button
      label={buttonOptions.buttonText}
      color={color}
      variant={customVariant ?? buttonOptions.variant}
      disabled={disabled || !userIsAllowed}
      className={className ?? ''}
      onClick={(e) => {
        e.stopPropagation();
        window.open(`#${buttonOptions.goTo}`, targetBlank ? '_blank' : '_self');
      }}
      size={size}
    />
  );
};

interface AppGoToResourceButtonProps {
  goBack?: boolean;
  mode: 'create' | 'edit' | 'show' | 'list';
  currentResourceId?: number;
  currentResourceLabel?: string;
  destinationResourceName: string;
  destinationResourceId?: number; // in case of edit
  buttonLabel?: string;
  listQueryString?: string; // List view
  detailParameters?: Record<string, any>; // Create / Edit / Show views
  record?: Record<string, any>;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  action?: ResourceAction;
  targetBlank?: boolean;
}

export default AppGoToResourceButton;
