import { FC } from 'react';
import { PdfDataLine, PdfSection } from '../utils';
import { PdfSingleSectionProps } from './sections';

export const InfoProcedure: FC<PdfSingleSectionProps> = (props) => (
  <PdfSection subtitle="INFORMAZIONI PROCEDURA">
    {props.additionalData.saleExperiment.item.folder.courtProcedureNumber &&
    props.additionalData.saleExperiment.item.folder.courtProcedureYear ? (
      <PdfDataLine
        label="Numero / anno"
        value={`${props.additionalData.saleExperiment.item.folder.courtProcedureNumber} / ${props.additionalData.saleExperiment.item.folder.courtProcedureYear}`}
      />
    ) : (
      <></>
    )}
    {props.additionalData.saleExperiment.item.folder.court?.name ? (
      <PdfDataLine
        label="Tribunale"
        value={`${props.additionalData.saleExperiment.item.folder.court.name}`}
      />
    ) : (
      <></>
    )}
    <PdfDataLine
      label="Tipo procedura"
      value={`${props.additionalData.saleExperiment.item.folder.pvpRegister.description} - ${props.additionalData.saleExperiment.item.folder.pvpRite.description}`}
    />
  </PdfSection>
);
