export enum InfoRequestType {
  Item = 'item',
  Service = 'service',
}

export enum InfoRequestStatus {
  Pending = 'pending',
  Processing = 'processing',
  Completed = 'completed',
}
