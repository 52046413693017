import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import AppAlert from '../../../../Components/ui/alert/AppAlert';
import { ACTION_CHOICES } from '../../constants';

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AuctionAlert' },
);

const AuctionAlert: FC<any> = (props) => {
  const classes = useStyles();
  const {
    severity,
    auctionStatus,
    auctionStatusReason,
    auctionStatusReasonDetails,
    title,
    detail,
  } = props;

  const _getTitle = () =>
    title ||
    `${
      auctionStatus === 'settled'
        ? 'Asta annullata'
        : auctionStatus === 'postponed'
        ? 'Asta sospesa'
        : 'Asta deserta'
    }: ${ACTION_CHOICES[auctionStatus].find((a) => a.id === auctionStatusReason).name}`;

  return (
    <AppAlert severity={severity} className={classes.alert} variant="standard" title={_getTitle()}>
      {detail || auctionStatusReasonDetails}
    </AppAlert>
  );
};

export default AuctionAlert;
