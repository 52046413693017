import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useBasePath } from '../../hooks/use-base-path';
import { SaleMode } from '../../utils/constants';
import { addDays } from '../../utils/dates';
import { maxDate } from '../../utils/validators';
import AppDrawerForm from '../drawer-form/drawer-form';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppAlert from '../ui/alert/AppAlert';
import AppCard from '../ui/card/AppCard';
import { AppAutocompleteInput, AppBooleanInput, AppDateInput } from '../ui/input';
import AppFiller from '../ui/layout/AppFiller';
import { AppPublicationIdealista } from './publication-idealista';
import AppPublicationMetadata from './publication-metadata';

const useStyles = makeStyles(
  (theme) => ({
    publicationButton: {
      marginBottom: theme.spacing(2.5),
    },
  }),
  { name: 'AppPublicationManager' },
);

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppPublicationManager: FC<any> = (props) => {
  const {
    anchor,
    title,
    itemTitle,
    canCreateEcwidPublication = false,
    handledStockQuantity = 0,
    ...others
  } = props;
  const notify = useNotify();
  const classes = useStyles();

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  const [propertyChoices, setPropertyChoices] = useState<Record<string, any>[]>([]);
  const [mutatePropertyChoices] = useMutation();

  const [typology, setTypology] = useState<string | null>();
  const [mutateTypology] = useMutation();

  const [selectedProperty, setSelectedProperty] = useState<Record<string, any> | null>(null); // used to generate metadata input

  const [defaultPublicationEndDate, setDefaultPublicationEndDate] = useState<Date | undefined>();

  /// region PROPERTY CHOICES

  useEffect(() => {
    if (ctx.manager.mode === 'insert') {
      handlePropertyChoices();
      setSelectedProperty(null);
    } else {
      setSelectedProperty(ctx.item.property ?? null);
    }
  }, [ctx.manager.isOpen]);

  useEffect(() => {
    let defaultPublicationEndDate =
      ctx.manager.mode === 'insert' &&
      ['immobiliare.it', 'idealista.it'].includes(selectedProperty?.schema)
        ? [SaleMode.SyncMixed, SaleMode.SyncOnline].includes(
            ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent)
              ?.fkSaleMode,
          )
          ? ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent)
              ?.auctionStartAt
          : ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent)
              ?.auctionEndAt ??
            ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent)
              ?.expressionOfInterestEndAt ??
            ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent)
              ?.offersCollectionEndAt ??
            undefined
        : undefined;
    if (defaultPublicationEndDate) {
      defaultPublicationEndDate = addDays(defaultPublicationEndDate, 1);
      defaultPublicationEndDate.setHours(12, 0, 0, 0);
    }
    setDefaultPublicationEndDate(defaultPublicationEndDate);
  }, [ctx.manager.mode, selectedProperty]);

  const handlePropertyChoices = () =>
    ctx.manager.isOpen &&
    mutatePropertyChoices(
      {
        type: 'getList',
        resource: `items/${ctx.mainResourceRecord.id}/publications/available-properties`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setPropertyChoices(res.data);

          ctx.mainResourceRecord.fkTypology &&
            mutateTypology(
              {
                type: 'getOne',
                resource: `typologies/${ctx.mainResourceRecord.fkTypology}`,
                payload: {},
              },
              {
                onSuccess: (res) => {
                  setTypology(res.data?.type ?? null);
                },
                onFailure: (err) => {
                  console.error(err);
                },
              },
            );
        },
        onFailure: (err) => {
          notify('Non è stato possibile recuperare la lista delle proprietà.', 'error');
          console.error(err);
        },
      },
    );

  // endregion

  /// region DEACTIVATE PUBLICATION

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const basePath = useBasePath();
  const [deactivatePublication] = useMutation();
  const handlePublicationDeactivation = () =>
    ctx.item.id &&
    deactivatePublication(
      {
        type: 'update',
        resource: `${basePath}/${ctx.mainResourceRecord.id}/publications/deactivate`,
        payload: {
          data: {
            isPublished: false,
          },
          id: ctx.item.id,
        },
      },
      {
        onSuccess: () => {
          handleDialogClose();
          ctx.getRelatedResources();
          ctx.manager.close();
          notify('Pubblicazione terminata con successo', 'info');
        },
        onFailure: (err) => {
          handleDialogClose();
          notify('Non è stato possibile terminare la pubblicazione.', 'error');
          console.error(err);
        },
      },
    );

  const [sendMedia] = useMutation();
  const handleSendMedia = () =>
    ctx.item.id &&
    sendMedia(
      {
        type: 'getOne',
        resource: `items/${ctx.mainResourceRecord.id}/publications/${ctx.item.id}/idealista/send-media`,
        payload: {},
      },
      {
        onSuccess: () => {
          notify('Media inviati con successo', 'info');
        },
        onFailure: (err) => {
          handleDialogClose();
          notify(
            `Non è stato possibile inviare i media: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`,
            'error',
          );
          console.error(err);
        },
      },
    );

  /// endregion

  const currentSaleExp = useMemo(
    () => ctx.mainResourceRecord?.relatedSaleExperiments?.find((saleExp) => !!saleExp.isCurrent),
    [ctx.mainResourceRecord?.relatedSaleExperiments],
  );
  const stockQuantity = useMemo(
    () => currentSaleExp?.totalQuantity - currentSaleExp?.soldQuantity ?? 0,
    [currentSaleExp],
  );

  return (
    <AppDrawerForm
      anchor={anchor}
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      saveBtn={true}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        ctx.manager.submit(data, close);
      }}
      deleteBtn
      onRemove={(data, close) => {
        ctx.manager.remove(data, close);
      }}
      record={ctx.item}
      mode={ctx.manager.mode}
      forceDisable={selectedProperty?.schema === 'ecwid.com' && !canCreateEcwidPublication}
      {...others}
    >
      {selectedProperty?.schema === 'ecwid.com' ? (
        <Grid item md={12} style={{ marginBottom: '12px' }}>
          <AppAlert severity={canCreateEcwidPublication ? 'success' : 'error'}>
            {canCreateEcwidPublication
              ? 'Le giacenze sono configurate correttamente, è possibile procedere con la pubblicazione del lotto.'
              : `Sono state configurate ${handledStockQuantity} giacenze su un totale di ${stockQuantity}. Per poter pubblicare il lotto è necessario configurare tutte le giacenze.`}
          </AppAlert>
        </Grid>
      ) : (
        <></>
      )}
      <AppCard title="Informazioni generali" expanded>
        {ctx.manager.mode === 'insert' && ( // cannot modify property once is saved in "insert" mode
          <AppAutocompleteInput
            source="fkProperty"
            label="Proprietà"
            required
            choices={propertyChoices}
            onSelect={(property) => setSelectedProperty(property)}
            md={8}
          />
        )}
        <AppBooleanInput
          source="isSale"
          label="Gestisce la vendita"
          md={3}
          disabled={ctx.item.isPublished}
        />
        <AppDateInput
          source="publicationDate"
          label="Data di pubblicazione"
          disabled={ctx.item.isPublished} // can modify dates only if item is currently unpublished on selected property, in order to publish it
          required
          validate={[maxDate('publicationEndDate', 'Termine pubblicazione', false)]}
          md={4}
        />
        <AppDateInput
          source="publicationEndDate"
          label="Termine pubblicazione"
          disabled={ctx.item.isPublished}
          defaultValue={defaultPublicationEndDate}
          md={4}
        />
        {ctx.manager.mode === 'edit' && selectedProperty?.schema === 'idealista.it' && (
          <Grid item>
            <Button
              children="Ricarica media su Idealista.it"
              disabled={!ctx.item.isPublished}
              onClick={handleSendMedia}
              className={classes.publicationButton}
              variant="contained"
            />
          </Grid>
        )}
        <AppFiller />
        <Grid item>
          <Button
            children="Termina pubblicazione"
            disabled={!ctx.item.isPublished}
            onClick={handleDialogOpen}
            className={classes.publicationButton}
            color="primary"
            variant="contained"
          />
        </Grid>
      </AppCard>
      {selectedProperty && selectedProperty.metadata ? (
        selectedProperty.schema === 'idealista.it' ? (
          <AppPublicationIdealista
            metadata={selectedProperty.metadata}
            typologyType={typology}
            item={ctx.mainResourceRecord}
          />
        ) : (
          <AppPublicationMetadata
            property={selectedProperty}
            record={ctx.item}
            typologyType={typology}
            itemTitle={itemTitle}
            item={ctx.mainResourceRecord}
            mode={ctx.manager.mode}
          />
        )
      ) : (
        <></>
      )}
      <Dialog
        open={dialogOpen}
        TransitionComponent={DialogTransition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Terminazione pubblicazione</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Vuoi terminare la pubblicazione del lotto{' '}
            <Box fontWeight="bold" display="inline">
              {ctx.mainResourceRecord.title}
            </Box>{' '}
            sulla proprietà{' '}
            <Box fontWeight="bold" display="block">
              {ctx.item.property ? ctx.item.property.name : ''} ?
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Annulla
          </Button>
          <Button onClick={handlePublicationDeactivation} color="primary">
            Confermo
          </Button>
        </DialogActions>
      </Dialog>
    </AppDrawerForm>
  );
};

export default AppPublicationManager;
