import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { paddingTop: '24px', paddingBottom: '24px' },
  pageNumber: {
    position: 'absolute',
    bottom: '15px',
    right: '24px',
    fontSize: '8px',
    lineHeight: 0.5,
    textAlign: 'right',
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Helvetica',
    fontSize: '18px',
    lineHeight: 1,
    fontWeight: 700,
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  subtitle: {
    position: 'relative',
    marginTop: 24,
    marginBottom: 12,
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    lineHeight: 1,
    fontWeight: 700,
    paddingLeft: '44px',
    paddingRight: '44px',
  },
  dataBlock: {},
  dataLine: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 1,
    width: '100%',
  },
  dataLabel: {
    width: '30%',
    textAlign: 'left',
    fontFamily: 'Helvetica',
    fontSize: '10px',
    lineHeight: 1,
    fontWeight: 700,
    paddingLeft: '44px',
    paddingRight: '10px',
  },
  dataValue: {
    width: '70%',
    textAlign: 'left',
    fontFamily: 'Helvetica',
    fontSize: '10px',
    lineHeight: 1,
    fontWeight: 'normal',
    paddingLeft: '10px',
    paddingRight: '44px',
  },
  attachment: {
    height: '90%',
    width: '90%',
  },
});

export default styles;
