import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => {
    return {
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'white',
        flexDirection: 'column',
      },
      backdropContent: {
        alignItems: 'center',
      },
      notFullscreen: {
        position: 'inherit',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    };
  },
  { name: 'AppCircularProgress' },
);

interface AppCircularLoaderProps extends BackdropProps {
  notFullscreen?: boolean;
}

type Props = {
  text?: string;
} & AppCircularLoaderProps;

const AppCircularLoader: FC<Props> = (props) => {
  const classes = useStyles();
  const { notFullscreen = false, text = '' } = props;
  return (
    <Backdrop
      className={classes.backdrop}
      {...props}
      classes={notFullscreen ? { root: classes.notFullscreen } : {}}
    >
      <div className={classes.backdropContent}>
        <CircularProgress color="inherit" />
      </div>
      {text !== '' && <p>{text}</p>}
    </Backdrop>
  );
};

export default AppCircularLoader;
