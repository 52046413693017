import React, { FC, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => {
    return {
      button: {
        marginBottom: theme.spacing(1),
      },
    };
  },
  { name: 'TodayFilterButton' },
);

interface TodayFilterButtonProps {
  fromSource: string;
  toSource: string;
  label: string;
}

const TodayFilterButton: FC<TodayFilterButtonProps> = (props) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const { fromSource, toSource, label } = props;
  const classes = useStyles();

  const today = new Date().toISOString().substr(0, 10);

  const [isActive, setIsActive] = useState(
    filterValues[fromSource] === filterValues[toSource] && filterValues[fromSource] === today,
  );
  useEffect(() => {
    setIsActive(
      filterValues[fromSource] === filterValues[toSource] && filterValues[fromSource] === today,
    );
  }, [filterValues[fromSource], filterValues[toSource]]);

  const handleFilterActivation = () => {
    if (isActive) {
      setFilters({ ...filterValues, [fromSource]: null, [toSource]: null }, displayedFilters);
    } else {
      setFilters({ ...filterValues, [fromSource]: today, [toSource]: today }, displayedFilters);
    }
  };

  return (
    <Button
      onClick={handleFilterActivation}
      variant='contained'
      color={isActive ? 'primary' : 'secondary'}
      children={label}
      className={classes.button}
    />
  );
};

export default TodayFilterButton;
