import { Grid } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { AppImageInput } from '../../../Components/ui/input/AppImageInput';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import {
  LINK_TYPES_CHOICES,
  MESSAGES_PAGES_CHOICES,
  MESSAGES_TYPE_CHOICES,
} from '../../Booking/constants';
import PageSelection from '../Pages/PageSelection';

const MessageDetails = (props: Record<any, any>): ReactElement => {
  const [urlType, setUrlType] = useState<string>(props.record.urlType ?? '');

  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppSelectInput
          source="type"
          label="Tipo messaggio"
          md={6}
          required
          choices={MESSAGES_TYPE_CHOICES}
        />
        <AppSelectInput
          source="pages"
          label="Pagine"
          md={6}
          required
          choices={MESSAGES_PAGES_CHOICES}
        />
        <AppTextInput source="title" label="Titolo" md={12} required />
        <AppTextInput
          source="description"
          label="Description"
          md={12}
          multiline
          expandable
          required
        />
        <AppDivider />
        <AppImageInput
          source="desktopImageFile"
          accept="image/png,image/jpeg"
          label="Immagine desktop"
          previewSource="desktopImage"
          previewLabel="Immagine desktop corrente"
          isRequired
        />
        <AppImageInput
          source="mobileImageFile"
          accept="image/png,image/jpeg"
          label="Immagine mobile"
          previewSource="mobileImage"
          previewLabel="Immagine mobile corrente"
          isRequired
        />
        <AppDivider />
        <AppDateInput
          source="dateFrom"
          label="Data inizio"
          md={3}
          required
          defaultValue={new Date().toDateString()}
        />
        <AppDateInput source="dateTo" label="Data fine" md={3} required />
        <AppBooleanInput source="enabled" label="Abilitato" md={3} required initialValue={true} />
        <AppNumberInput source="order" label="Ordine" md={1} defaultValue={0} />
        <AppSelectInput
          source="urlType"
          label="Tipo link"
          md={6}
          required
          choices={LINK_TYPES_CHOICES}
          defaultValue={props.record.urlType}
          onChange={(e) => setUrlType(e.target.value)}
        />
        {urlType === LINK_TYPES_CHOICES[0].id && (
          <PageSelection mode={props.mode} source="url" label="Pagina" />
        )}
        {urlType === LINK_TYPES_CHOICES[1].id && <AppTextInput source="url" label="URL" md={6} />}
        {props.mode === 'show' ? (
          <Grid item md={12}>
            <AppSectionTitle>SITI</AppSectionTitle>
            <ReferenceArrayField reference="properties" source="propertyIds" label="Siti">
              <SingleFieldList>
                <ChipField source="name" style={{ marginBottom: '15px' }} />
              </SingleFieldList>
            </ReferenceArrayField>
          </Grid>
        ) : (
          <AppAutocompleteInput
            source="propertyIds"
            reference="properties"
            label="Siti"
            resettable
            variant="outlined"
            isArray
            md={12}
            filter={{
              schema: null,
            }}
          />
        )}
      </AppCard>
    </AppBaseForm>
  );
};

export default MessageDetails;
