import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import React, { FC } from 'react';

interface AppConfirmDialogProps extends DialogProps {
  onConfirm: () => void;
  onClose: () => void;
  details?: string | JSX.Element;
  title?: string;
}

const AppConfirmDialog: FC<AppConfirmDialogProps> = (props) => {
  const { open, onConfirm, title = "Vuoi confermare l'operazione?", details, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {details && (
        <DialogContent>
          {typeof details === 'string' ? (
            <DialogContentText>{details}</DialogContentText>
          ) : (
            React.cloneElement(details)
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
        >
          Conferma
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppConfirmDialog;
