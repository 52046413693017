import { makeStyles } from '@material-ui/core';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import { FunctionComponent, useEffect, useState } from 'react';
import { FunctionField, ListProps, useMutation } from 'react-admin';
import { MagazineListLegend } from '../../Components/magazine/magazine-list-legend';
import { RetryMagazineGenerationButton } from '../../Components/magazine/retry-magazine-generation-button';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppSelectField } from '../../Components/ui/field';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { useAutomaticRefresh } from '../../hooks/use-automatic-refresh';
import { ITEM_GENRES, MAGAZINE_KINDS } from '../../utils/constants';
import { MagazineExpanded } from './MagazineExpanded';
import { GenerationStatus } from './constants';

const useStyles = makeStyles(
  () => ({
    kind: { whiteSpace: 'nowrap' },
    genre: { whiteSpace: 'nowrap' },
    date: { whiteSpace: 'nowrap' },
    title: { whiteSpace: 'nowrap' },
    publicUrl: { whiteSpace: 'nowrap' },
    lastCol: { width: '100%', whiteSpace: 'nowrap' },
  }),
  { name: 'MagazineList' },
);

const MagazineList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  useAutomaticRefresh();

  const [courtsCatalog, setCourtsCatalog] = useState([]);
  const [mutateCourts] = useMutation();
  useEffect(() => {
    // Get courts
    mutateCourts(
      {
        type: 'getList',
        resource: 'courts',
        payload: {},
      },
      {
        onSuccess: (res) => setCourtsCatalog(res.data),
        onFailure: (err) => {
          console.error('Cannot receive courts: ', err);
        },
      },
    );
  }, []);

  return (
    <AppList {...props} title={<AppTitle title="Gestione bollettini" />}>
      <AppDatagrid
        rowClick="show"
        expand={<MagazineExpanded courtsCatalog={courtsCatalog} />}
        rowStyle={(record) => ({
          backgroundColor:
            record.generationStatus === GenerationStatus.Pending
              ? yellow[600]
              : record.generationStatus === GenerationStatus.InProgress
              ? blue[300]
              : record.generationStatus === GenerationStatus.Error
              ? red[400]
              : green[400],
        })}
        additionalInfo={<MagazineListLegend />}
      >
        <AppTextField
          source="title"
          label="Titolo"
          headerClassName={classes.title}
          cellClassName={classes.title}
        />
        <AppSelectField
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          headerClassName={classes.genre}
          cellClassName={classes.genre}
        />
        <FunctionField
          render={(record) => (
            <AppTextField
              customText={
                record?.method === 'generate'
                  ? MAGAZINE_KINDS.find((item) => item.id === record.kind)?.name ?? '-'
                  : '-'
              }
            />
          )}
          label="Tipo"
          headerClassName={classes.kind}
          cellClassName={classes.kind}
        />
        <AppDateField source="date" label="Data inizio" headerClassName={classes.date} />
        <AppDateField source="endDate" label="Data fine" headerClassName={classes.date} />
        <AppTextField source="notes" label="Note" headerClassName={classes.lastCol} />
        <RetryMagazineGenerationButton />
        <AppDownloadFromGCPBucketField
          source="publicUrl"
          label="PDF"
          sortable={false}
          headerClassName={classes.publicUrl}
          cellClassName={classes.publicUrl}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default MagazineList;
