import { Button } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { FC, Fragment, useContext } from 'react';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import AppTooltip from '../../ui/tooltip/AppTooltip';

const AttachmentsButton: FC<any> = ({ record, mode }) => {
  const ctx = useContext(AppRelatedResourceContext);

  return (
    <Fragment>
      <AppTooltip title="Clicca per visualizzare gli allegati" arrow>
        <Button
          children={record.mediaNumber}
          startIcon={<InsertDriveFileIcon />}
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => {
            ctx.manager.setAdditionalParams({
              attachmentsDrawer: true,
              disableAuthorization: mode === 'show',
            });
            ctx.getRelatedResource(record.id);
          }}
          style={{ lineHeight: 'initial' }}
        />
      </AppTooltip>
    </Fragment>
  );
};

export default AttachmentsButton;
