import { Box } from '@material-ui/core';
import React, { FC } from 'react';

//const hiddenStyle = {display: 'none'};
//const activeStyle = {display: 'auto'};

const AppFiller: FC = () => {
  //const {hidden = false, xs, md} = props;
  //const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  return <Box flex={1} flexBasis='100%' />;
};

//export interface AppFillerProps {
//  hidden?: boolean;
//  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
//  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
//}

export default AppFiller;
