import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import { useBasePath } from '../../hooks/use-base-path';
import { OriginType } from '../../utils/constants';
import { amountFormatter } from '../../utils/data-formatters';
import { minValue } from '../../utils/validators';
import { AppAutocompleteInput, AppNumberInput } from '../ui/input';

const useStyles = makeStyles(
  (theme) => ({
    newOfferButton: (props: Record<string, any>) => ({
      minWidth: theme.spacing(40),
      marginTop: -theme.spacing(5.5),
      marginRight: theme.spacing(props.isPaused ? 30 : 40),
    }),
    formDialog: {
      width: theme.spacing(110),
      height: theme.spacing(50),
      maxWidth: 'none',
    },
    confirmationDialog: {
      width: theme.spacing(60),
      height: theme.spacing(30),
      maxWidth: 'none',
    },
  }),
  { name: 'ParticipationAddOfferButton' },
);

const ParticipationAddOfferButton: FC<any> = (props) => {
  const { saleExpRecord, offerSuggestion, completedAuction = false, bestBidderId } = props;
  const { auctionStatus, id: saleExpId, fkSaleMode, auctionIsPaused } = saleExpRecord;
  const classes = useStyles({
    isPaused: auctionIsPaused,
  });
  const notify = useNotify();
  const refresh = useRefresh();

  const basePath = useBasePath();

  const [participantChoices, setParticipantChoices] = useState<Record<string, any>[]>([]);

  const isVisible =
    !completedAuction &&
    fkSaleMode === 3 &&
    auctionStatus === 'active' &&
    participantChoices.length > 0;

  useEffect(() => {
    // set participant choices (filter out non-paper participants)
    if (saleExpRecord.relatedParticipations) {
      setParticipantChoices(
        saleExpRecord.relatedParticipations
          .filter(
            (genericPart) => genericPart.origin === OriginType.Paper && genericPart.authorized,
          )
          .map((paperPart) => ({
            id: paperPart.id,
            name: `${paperPart.auctionId} - ${paperPart.presenter.lastName} ${paperPart.presenter.firstName}`,
          })),
      );
    }
  }, [saleExpRecord]);

  // MUTATE AUCTION STATUS
  const [mutateAuctionIsPaused] = useMutation();
  const handleAuctionPauseToggle = () =>
    mutateAuctionIsPaused(
      {
        type: 'create',
        resource: `${basePath}/${saleExpId}/toggle-auction-is-paused`,
        payload: {
          data: {
            id: saleExpId,
            offering: true,
          },
        },
      },
      {
        onSuccess: (res) => {
          if (res.data.auctionIsPaused) {
            notify("L'asta è attualmente in pausa.", 'info');
            setAddOfferDialogOpen(true);
          } else {
            setConfirmOfferDialogOpen(false);
            setAddOfferDialogOpen(false);
            setResumeAuctionDialogOpen(false);
            notify("L'asta è attualmente in corso.", 'info');
            refresh();
          }
        },
        onFailure: (err) => {
          notify(err.message, 'error');
          console.log(err);
        },
      },
    );

  // RESUME DIALOG
  const [resumeAuctionDialogIsOpen, setResumeAuctionDialogOpen] = useState(false);
  const handleResumeAuctionDialogClose = () => {
    setResumeAuctionDialogOpen(false);
    refresh();
  };

  /// region ADD OFFER

  const [addOfferDialogIsOpen, setAddOfferDialogOpen] = useState(false);
  const [offerAmount, setOfferAmount] = useState<number>(offerSuggestion);
  const [participantChoice, setParticipantChoice] = useState<number>(0);

  useEffect(() => {
    // Always update offer amount after offer suggestion changes (min offer)
    setOfferAmount(offerSuggestion);
  }, [offerSuggestion]);

  const [addNewOffer] = useMutation();
  const handleNewOfferDialog = () => {
    auctionIsPaused ? setAddOfferDialogOpen(true) : handleAuctionPauseToggle();
  };

  const handleNewOffer = () =>
    addNewOffer(
      {
        type: 'create',
        resource: `${basePath}/${saleExpId}/offline-bid/${participantChoice}`,
        payload: {
          data: {
            id: participantChoice,
            amount: Number(offerAmount),
          },
        },
      },
      {
        onSuccess: () => {
          notify('Offerta inserita correttamente.', 'info');
          handleAuctionPauseToggle();
        },
        onFailure: (err) => {
          console.error(err);
          notify('Non è stato possibile inserire il nuovo rilancio.', 'error');
        },
      },
    );

  const [confirmOfferDialogIsOpen, setConfirmOfferDialogOpen] = useState(false);
  const [tooHighBidAlertVisible, setTooHighBidAlertVisible] = useState(false);
  const [sameBidderAlertVisible, setSameBidderAlertVisible] = useState(false);

  useEffect(() => {
    if (!tooHighBidAlertVisible) setOfferAmount(offerSuggestion);
  }, [tooHighBidAlertVisible]);

  /// endregion

  const calcPercentageOfPrice = (price: number | null, percentage: number | null): number =>
    percentage && !isNaN(percentage) && price && !isNaN(price) ? (percentage * price) / 100 : 0;

  const handleConfirmOfferDialog = (offer: number) => {
    if (bestBidderId === participantChoice) {
      setSameBidderAlertVisible(true);
    } else {
      const minOffer = offerSuggestion;
      if (
        offer >= minOffer * 2 &&
        offer - minOffer >=
          (saleExpRecord.minRaise
            ? saleExpRecord.minRaise
            : calcPercentageOfPrice(minOffer, saleExpRecord.minRaisePercentage)) *
            2
      ) {
        setTooHighBidAlertVisible(true);
      } else {
        setConfirmOfferDialogOpen(true);
      }
    }
    setAddOfferDialogOpen(false);
  };

  return isVisible ? (
    <Fragment>
      <Button
        children="Inserisci rilancio dalla sala"
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        className={classes.newOfferButton}
        onClick={handleNewOfferDialog}
      />
      <Dialog open={addOfferDialogIsOpen} classes={{ paper: classes.formDialog }}>
        <DialogTitle>Inserimento rilancio - Asta in pausa</DialogTitle>
        <Form
          onSubmit={handleNewOffer}
          mutators={arrayMutators as any}
          initialValues={{
            amount: offerSuggestion,
          }}
          render={(formProps) => {
            const { submitting, hasValidationErrors } = formProps;

            return (
              <Fragment>
                <DialogContent>
                  <Grid container spacing={2}>
                    <AppAutocompleteInput
                      source="participant"
                      label="Partecipante"
                      variant="outlined"
                      choices={participantChoices}
                      defaultValue={participantChoice}
                      onSelect={(participant) => setParticipantChoice(participant.id)}
                      required
                    />
                    <AppNumberInput
                      source="amount"
                      label="Ammontare nuovo rilancio"
                      isAmount
                      variant="outlined"
                      helperText="L'importo suggerito corrisponde alla migliore offerta pervenuta attualmente oltre il rilancio minimo"
                      required
                      onChange={(event) => setOfferAmount(event.target.value)}
                      validate={[minValue(offerSuggestion, 'offerta minima', true)]}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    children="Annulla"
                    onClick={() => {
                      setAddOfferDialogOpen(false);
                      setResumeAuctionDialogOpen(true);
                    }}
                  />
                  <Button
                    children="Conferma"
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirmOfferDialog(offerAmount)}
                    disabled={submitting || hasValidationErrors}
                  />
                </DialogActions>
              </Fragment>
            );
          }}
        />
      </Dialog>

      <Dialog open={confirmOfferDialogIsOpen}>
        <DialogTitle>Conferma inserimento rilancio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confermi il rilancio di <strong>{amountFormatter(offerAmount)}</strong> per il
            partecipante{' '}
            <strong>
              {participantChoices.find((part) => part.id === participantChoice)?.name ?? ''}
            </strong>{' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button children="NO" onClick={() => setConfirmOfferDialogOpen(false)} />
          <Button children="SI" variant="contained" color="primary" onClick={handleNewOffer} />
        </DialogActions>
      </Dialog>

      <Dialog open={sameBidderAlertVisible}>
        <DialogTitle>Attenzione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            L'offerta di{' '}
            <strong>
              {participantChoices.find((part) => part.id === participantChoice)?.name ?? ''}
            </strong>{' '}
            è già la più alta fatta finora!
            <br />
            Sei sicuro di voler rilanciare?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            children="NO"
            onClick={() => {
              setSameBidderAlertVisible(false);
              setAddOfferDialogOpen(true);
            }}
          />
          <Button children="SI" variant="contained" color="primary" onClick={handleNewOffer} />
        </DialogActions>
      </Dialog>

      <Dialog open={tooHighBidAlertVisible}>
        <DialogTitle>Attenzione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            L'offerta è il doppio dell'offerta più alta fatta finora!
            <br />
            Potresti offrire <strong>{amountFormatter(offerSuggestion)}</strong>, sei sicuro di
            voler offrire
            <strong> {amountFormatter(offerAmount ? offerAmount : 0)} </strong>per il partecipante{' '}
            <strong>
              {participantChoices.find((part) => part.id === participantChoice)?.name ?? ''}
            </strong>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            children="NO"
            onClick={() => {
              setTooHighBidAlertVisible(false);
              setAddOfferDialogOpen(true);
            }}
          />
          <Button children="SI" variant="contained" color="primary" onClick={handleNewOffer} />
        </DialogActions>
      </Dialog>
      <Dialog open={resumeAuctionDialogIsOpen} classes={{ paper: classes.confirmationDialog }}>
        <DialogTitle>Attenzione - Riavvio gara</DialogTitle>
        <DialogContent>
          <DialogContentText>Riavviare la gara?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button children="NO" onClick={handleResumeAuctionDialogClose} />
          <Button
            children="SI"
            color="primary"
            variant="contained"
            onClick={handleAuctionPauseToggle}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : (
    <span />
  );
};

export default ParticipationAddOfferButton;
