import { FC, ReactElement } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { PERMISSION_METHODS } from './constants';

const PermissionDetails: FC<any> = (props): ReactElement => {
  return (
    <AppBaseForm variant="outlined" {...props} disabledCards={props.mode === 'show'}>
      <AppCard title="Permesso" collapsable={false}>
        <AppTextInput source="code" required label="Codice univoco" />
        <AppTextInput source="description" required label="Descrizione" />
        <AppSelectInput source="method" label="Metodo" choices={PERMISSION_METHODS} required />
        <AppTextInput source="path" required label="Percorso" />
      </AppCard>
    </AppBaseForm>
  );
};

export default PermissionDetails;
