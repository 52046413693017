import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import {
  Children,
  FC,
  Fragment,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useRef,
} from 'react';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import AppDivider from '../../layout/AppDivider';
import { AppText } from '../../text';

const useStyles = makeStyles(
  (theme) => ({
    dialog: (props: Record<string, any>) => ({
      width: theme.spacing(props.width),
      height: theme.spacing(props.height),
      maxWidth: 'none',
    }),
    button: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'AppDialogForm' },
);

interface AppFormDialogProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: (event?: any) => void;
  title: string;
  onSubmit: (data, close) => void;
  record: Record<string, any>;
  mode?: 'edit' | 'insert' | null;
  saveBtnConfig?: Record<string, any>;
  width?: number; // theme spacing value
  height?: number; // theme spacing value
  variant?: string;
  canSubmitIfPristine?: boolean;
  subtitle?: string;
}

const AppFormDialog: FC<PropsWithChildren<AppFormDialogProps>> = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    subtitle,
    onSubmit,
    width = 70,
    height = 40,
    saveBtnConfig = {
      label: 'Salva',
      icon: <Save />,
    },
    canSubmitIfPristine = false,
    ...others
  } = props;

  const notify = useNotify();
  const closeMe = useRef(false);
  const classes = useStyles({
    width,
    height,
  });

  const clearInputs = ([name], state, { changeValue }) => {
    //accepts both a single field name and an array of names
    if (name instanceof Array) {
      name.forEach((name) => changeValue(state, name, () => undefined));
    } else {
      changeValue(state, name, () => undefined);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        <AppText variant="h6">{title}</AppText>
        {!!subtitle && <AppText variant="subtitle1">{subtitle}</AppText>}
      </DialogTitle>
      <AppDivider flex={false} />
      <Form
        initialValues={others.record}
        mutators={{ clearInputs, ...(arrayMutators as any) }}
        onSubmit={(data) => {
          onSubmit(data, closeMe.current);
        }}
        render={(formProps) => {
          const { handleSubmit, submitting, pristine, hasValidationErrors } = formProps;
          const disabled = submitting || (!canSubmitIfPristine && pristine);

          const checkValidationErrors = () =>
            hasValidationErrors && notify('ra.message.invalid_form', 'error');

          return (
            <Fragment>
              <DialogContent>
                <Grid container spacing={2}>
                  {Children.map(children as ReactElement[], (child: ReactElement) =>
                    cloneElement(child, { variant: 'outlined', ...others }),
                  )}
                </Grid>
              </DialogContent>
              <AppDivider flex={false} />
              <DialogActions>
                <Button onClick={onClose} children="Annulla" className={classes.button} />
                <Button
                  disabled={disabled}
                  onClick={() => {
                    checkValidationErrors();
                    closeMe.current = true;
                    handleSubmit();
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                  startIcon={saveBtnConfig.icon}
                  children={saveBtnConfig.label}
                  className={classes.button}
                />
              </DialogActions>
            </Fragment>
          );
        }}
      />
    </Dialog>
  );
};

export default AppFormDialog;
