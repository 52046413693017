import { Box, Button, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { FunctionField, ListContextProvider, useMutation, useNotify } from 'react-admin';
import { PaymentReason } from '../../../Resources/Payment/constants';
import { useToggleState } from '../../../hooks/use-toggle-state';
import AppAlert from '../../ui/alert/AppAlert';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppFormDialog from '../../ui/dialog/form-dialog/form-dialog';
import { AppDateField, AppTextField } from '../../ui/field';
import { AppNumberInput } from '../../ui/input';
import AppCircularLoader from '../../ui/loaders/AppCircularLoader';
import { AppText } from '../../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(50),
    },
    otherCol: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      width: '100%',
    },
    alertBox: {
      width: '100%',
      marginTop: theme.spacing(),
    },
  }),
  { name: 'ParticipationsToImportList' },
);

export const ParticipationsToImportList: FC<any> = React.memo(({ baseResource, saleExpId }) => {
  const classes = useStyles();
  const notify = useNotify();

  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
    loading: true,
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  const [selectedParticipation, setSelectedParticipation] = useState<Record<string, any> | null>(
    null,
  );

  const [confirmDialogOpen, toggleConfirmDialog] = useToggleState();

  const [loaderOpen, toggleLoader] = useToggleState();

  const [mutateParticipations] = useMutation();
  const getParticipationsToImport = () => {
    mutateParticipations(
      {
        type: 'getList',
        resource: `${baseResource}/${saleExpId}/participations/to-import`,
        payload: {},
      },
      {
        onSuccess: (res) =>
          setListCtx(
            _assign({}, listBaseCtx, {
              data: _keyBy(res.data, 'id'),
              ids: _map(res.data, 'id'),
              total: res.total,
              loaded: true,
              loading: false,
            }) as any,
          ),
        onFailure: (err) => {
          notify('Impossibile caricare la lista dei partecipanti.', 'error');
          console.error(err);
        },
      },
    );
  };

  useEffect(getParticipationsToImport, []);

  const handleOpenConfirmDialog = (participation: Record<string, any>) => {
    setSelectedParticipation(participation);

    toggleConfirmDialog();
  };

  const handleParticipationImport = ({ offerAmount }) => {
    if (!selectedParticipation) return;

    toggleLoader();

    mutateParticipations(
      {
        type: 'create',
        resource: `${baseResource}/${saleExpId}/participations/${selectedParticipation.id}/import-in-new-sale`,
        payload: {
          data: offerAmount ? { offerAmount } : { noData: '' }, // Workaround because RA doesn't accept an empty data object in POST/PUT
        },
      },
      {
        onSuccess: () => {
          setListCtx((prevListCtx) => {
            const newListCtx: Record<string, any> = {
              ...prevListCtx,
              ids: prevListCtx.ids.filter((id) => id !== selectedParticipation.id),
              total: prevListCtx.total - 1,
            };
            delete newListCtx.data[selectedParticipation.id];
            return newListCtx;
          });

          toggleLoader();
          notify('Importazione avvenuta con successo.');
          toggleConfirmDialog();
        },
        onFailure: (err) => {
          console.error(err);
          toggleLoader();
          notify("Errore durante l'importazione.", 'error');
          toggleConfirmDialog();
        },
      },
    );
  };

  return listCtx.loading ? (
    <AppText>Caricamento partecipanti...</AppText>
  ) : (
    <>
      {listCtx.total > 0 ? (
        <>
          <ListContextProvider value={listCtx}>
            <AppDatagrid
              rowStyle={(record) => (record.awarded ? { backgroundColor: green[500] } : {})}
            >
              <FunctionField
                render={(record) => (
                  <AppTextField
                    customText={`${record.presenter.lastName} ${record.presenter.firstName} (${
                      record.presenter.taxCode ?? record.presenter.vat ?? '-'
                    })`}
                  />
                )}
                label="Nominativo"
                headerClassName={classes.name}
              />
              <FunctionField
                label="Offerta"
                render={(record) => (
                  <AppTextField
                    record={record}
                    type="amount"
                    source={record.awarded ? 'highestOffer' : 'offers[0].amount'}
                  />
                )}
                headerClassName={classes.otherCol}
              />
              <FunctionField
                label="Data/ora offerta"
                render={(record) => (
                  <AppDateField
                    source={record.awarded ? 'highestOfferDate' : 'offers[0].offeredAt'}
                  />
                )}
                headerClassName={classes.otherCol}
              />
              <FunctionField
                label="Importo cauzione"
                render={(record) => (
                  <AppTextField
                    type="amount"
                    customText={
                      record.awarded
                        ? '-'
                        : record.payments?.find((p) => p.paymentReason === PaymentReason.Deposit)
                            ?.amount
                    }
                  />
                )}
                headerClassName={classes.lastCol}
              />
              <FunctionField
                render={(record) => (
                  <Button
                    children="Importa"
                    startIcon={<ImportIcon />}
                    onClick={() => handleOpenConfirmDialog(record)}
                    variant="outlined"
                  />
                )}
              />
            </AppDatagrid>
          </ListContextProvider>
          <AppFormDialog
            open={confirmDialogOpen}
            onClose={toggleConfirmDialog}
            record={{}}
            onSubmit={handleParticipationImport}
            title={`Confermi di voler importare il partecipante ${selectedParticipation?.presenter?.lastName} ${selectedParticipation?.presenter?.firstName}?`}
            width={100}
            height={50}
            saveBtnConfig={{
              label: 'Importa',
            }}
            canSubmitIfPristine
          >
            {selectedParticipation?.awarded ? (
              <>
                <Box className={classes.alertBox}>
                  <AppAlert severity="info" title="Vincitore precedente">
                    Inserire l'importo in modo da eguagliare o superare la migliore offerta art.107
                    ricevuta.
                  </AppAlert>
                </Box>
                <AppNumberInput
                  source="offerAmount"
                  label="Importo nuova offerta"
                  isAmount
                  required
                  variant="outlined"
                  md={12}
                />
              </>
            ) : (
              <Box className={classes.alertBox}>
                <AppAlert severity="warning">
                  Il partecipante 107 sarà aggiunto alla lista dei partecipanti a questa gara
                </AppAlert>
              </Box>
            )}
          </AppFormDialog>
        </>
      ) : (
        <AppAlert children="Nessun partecipante da importare." severity="info" />
      )}
      <AppCircularLoader open={loaderOpen} text="Importazione partecipazione in corso..." />
    </>
  );
});
