import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import CompanyList from './CompanyList';
import CompanyIcon from '@material-ui/icons/Business';
import { resourceWrapper } from '../../Components/ui/utils';
import CompanyDetails from './CompanyDetails';

const { Auctioneer } = PersonRoles;

export const COMPANY_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const Company: ResourceExport = {
  list: CompanyList,
  create: resourceWrapper('create', CompanyDetails, undefined, undefined, COMPANY_PERMISSIONS),
  edit: resourceWrapper('edit', CompanyDetails, undefined, undefined, COMPANY_PERMISSIONS),
  show: resourceWrapper('show', CompanyDetails, undefined, undefined, COMPANY_PERMISSIONS),
  icon: CompanyIcon,
};

export default Company;
