import React, { FC, Fragment } from 'react';
import { AppAutocompleteInput, AppTextInput } from '../../ui/input';
import { AppFileInput } from '../../ui/input/AppFileInput';

const AppShippingForm: FC<any> = ({ ctx }) => {
  return (
    <Fragment>
      <AppAutocompleteInput
        reference="participations"
        filter={{
          awarded: true,
          fkSaleExp: ctx.mainResourceRecord.id,
        }}
        optionText={(participation) => {
          if (!participation) return '';
          return `${participation.presenter.lastName} ${participation.presenter.firstName} (${(
            participation.presenter.taxCode || '-'
          ).toUpperCase()})`;
        }}
        source="participationId"
        suggestionLimit={5}
        md={6}
        resettable
        label="Anagrafica"
      />
      <AppTextInput source="trackingCode" label="Tracking code" variant="outlined" md={6} />
      <AppFileInput
        source="mediaFile"
        previewSource="media"
        label="Allegato"
        base64Preview={ctx.manager.mode !== 'insert' && ctx.item.mediaFile && !ctx.item.media}
        md={12}
        previewMaxWidth
        isRequired
      />
    </Fragment>
  );
};

export default AppShippingForm;
