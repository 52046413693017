import { Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { SaleMode } from '../../../utils/constants';

const useStyles = makeStyles(
  (theme) => ({
    timerTitle: {
      marginBottom: theme.spacing(1),
    },
    timerValue: {
      fontSize: 20,
      fontWeight: 'bold',
    },
  }),
  { name: 'AuctionCountdown' },
);

interface AuctionCountdownProps {
  isPaused: boolean;
  saleMode: number;
  raiseLimitTS: number;
  serverTimeDeviceTimeOffset: number;
}

const AuctionCountdown: FC<AuctionCountdownProps> = ({
  raiseLimitTS,
  saleMode,
  isPaused,
  serverTimeDeviceTimeOffset,
}) => {
  const classes = useStyles();
  const [timer, setTimer] = useState<number>(0);

  // Countdown handler
  const intervalRef = useRef<number>();
  useEffect(
    () => handleCountdown(intervalRef, setTimer, raiseLimitTS, serverTimeDeviceTimeOffset),
    [raiseLimitTS],
  );

  return (
    <Fragment>
      <Typography variant="h4" className={classes.timerTitle}>
        Timer gara
      </Typography>
      <Chip
        variant="outlined"
        label={getTimerValue(saleMode, timer, isPaused)}
        color="primary"
        icon={<TimerIcon />}
        classes={{
          label: classes.timerValue,
        }}
      />
    </Fragment>
  );
};

/// region UTILS

const getTimerValue = (saleMode: SaleMode, timer: number, isPaused: boolean): string => {
  if (saleMode === SaleMode.AsyncOnline) {
    const days = Math.floor(timer / 24 / 60 / 60);
    const hoursLeft = Math.floor(timer - days * 86400);
    const hours = Math.floor(hoursLeft / 3600);
    const minutesLeft = Math.floor(hoursLeft - hours * 3600);
    const minutes = Math.floor(minutesLeft / 60);
    const seconds = timer % 60;

    return `
      ${days} ${days === 1 ? 'giorno' : 'giorni'} 
      ${hours} ${hours === 1 ? 'ora' : 'ore'}
      ${minutes} min
      ${seconds} sec
    `;
  }

  return `${isPaused ? 'In pausa' : `${timer} ${timer === 1 ? 'secondo' : 'secondi'}`}`;
};

const handleCountdown = (
  intervalRef: React.MutableRefObject<number | undefined>,
  setTimer: React.Dispatch<React.SetStateAction<number>>,
  raiseLimitTS: number,
  serverTimeDeviceTimeOffset: number,
) => {
  if (intervalRef.current) {
    window.clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  }

  intervalRef.current = window.setInterval(() => {
    setTimer(() => {
      const actualNowTs = Date.now() + serverTimeDeviceTimeOffset; //VERY IMPORTANT, helps to sync user's time with server.
      const remaining =
        raiseLimitTS >= actualNowTs ? Math.floor((raiseLimitTS - actualNowTs) / 1000) : 0;

      if (remaining === 0) window.clearInterval(intervalRef.current);

      return remaining;
    });
  }, 1000);

  return () => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  };
};

/// endregion

export default AuctionCountdown;
