import { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput';
import React, { FC, ReactElement } from 'react';
import { DateInput, Validator } from 'react-admin';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

export const AppDateInput: FC<AppDateInputProps> = (props: AppDateInputProps): ReactElement => {
  //TODO: make date inputs resettable
  //const resetDate = (e) => console.log(e);
  const { required, validate: customValidators, ...rest } = props;

  return gridWrappedInput(
    { ...rest },
    <DateInput
      validate={getValidators(customValidators as Validator[], required)}
      {...inputProps({ ...rest })}
    />,
  );
};

export type AppDateInputProps = TextInputProps & AppGridProps;
