import {
  Bubble,
  ChatList,
  ChatListItem,
  Column,
  Fill,
  Fit,
  IconButton,
  Message,
  MessageGroup,
  MessageList,
  MessageMedia,
  MessageText,
  MessageTitle,
  Row,
  SendButton,
  Subtitle,
  TextComposer,
  TextInput,
  Title,
} from '@livechat/ui-kit';
import {
  Avatar,
  Badge,
  BadgeProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  AppBar as MuiAppBar,
  Typography,
} from '@material-ui/core';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Block as BannIcon, Close, DoneAll, Email, RecordVoiceOver } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FC, RefObject, createRef, useContext, useEffect, useState } from 'react';
import { AppChatMessage, AppChatParticipant, AppChatWidgetData } from './chat-widget-types';
import { ChatWidgetContext } from './chat-widget.provider';

const dateFormatter = new Intl.DateTimeFormat('it-IT', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});
const useStyles = makeStyles(
  (theme) => ({
    container: {
      //backgroundColor: 'lightgreen',
      width: '710px',
      display: 'flex',
      flex: '1 1 100%',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    title: {},
    chatList: {
      //backgroundColor: 'red',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 100 %',
      maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
      maxWidth: 280,
      minWidth: 280,
    },
    messageListContainer: {
      display: 'flex',
      flex: '1 1 100%',
      flexDirection: 'column',
      minWidth: 0,
      borderLeft: '1px solid lightgray',
    },
    participantsListContainer: {
      overflow: 'auto',
    },
    avatar: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main} !important`,
      marginRight: theme.spacing(2),
      fontWeight: 'bold',
    },
  }),
  { name: 'ChatWidgetMaximized' },
);

type GroupedMessages = Array<{ origin: string; messages: AppChatMessage[] }>;

interface AppMessageListProps {
  messages: AppChatMessage[];
  containerRef: RefObject<HTMLDivElement>;
  onSeen: (message: Record<string, any>) => void;
  direct?: boolean;
  participants?: AppChatParticipant[];
}

const AppMessageList: FC<AppMessageListProps> = ({
  messages,
  containerRef,
  onSeen,
  direct = false,
  participants,
}) => {
  const [data, setData] = useState<GroupedMessages>([]);

  useEffect(() => {
    if (!messages) return;

    if (messages.length === 0) return setData([]);
    const tmpData: GroupedMessages = [];
    let prevMsgOrigin = '';

    for (const msg of messages) {
      if (prevMsgOrigin !== msg.origin) {
        tmpData.push({ origin: msg.origin, messages: [msg] });
      } else {
        tmpData[tmpData.length - 1].messages.push(msg);
      }
      prevMsgOrigin = msg.origin;
    }
    setData(tmpData);
    //if (tmpData[tmpData.length - 1].messages[0].origin === 'M') {
    if (!containerRef) return;
    const scrollable = containerRef.current?.firstElementChild;
    setTimeout(() => scrollable?.scrollTo(0, scrollable.scrollHeight));
    //}
  }, [messages]);

  useEffect(() => {
    if (!containerRef) return;
    const scrollable = containerRef.current?.firstElementChild;
    setTimeout(() => scrollable?.scrollTo(0, scrollable.scrollHeight));
  }, []);

  return (
    <MessageList style={{ paddingBottom: 0 }} active>
      {data.map((messageGroup, grpId) => (
        <MessageGroup key={grpId} onlyFirstWithMeta>
          {(messageGroup.messages || []).map((message, index, array) => (
            <Message
              onSeen={() => onSeen(message)}
              date={message.ts && dateFormatter.format(message.ts)}
              isOwn={message.origin === 'M'}
              key={`${grpId}-${index}`}
              style={{ marginBottom: '4px' }}
            >
              {!direct &&
                message.origin !== 'M' &&
                (index === 0 ||
                  (messageGroup.messages || [])?.[index - 1]?.participationId !==
                    message.participationId) && (
                  <Bubble
                    isOwn={false}
                    radiusType={'single'}
                    style={{
                      color: message.participationId !== 'M' ? 'white' : 'inherit',
                      backgroundColor:
                        message.participationId !== 'M'
                          ? `hsl(${(Number(message.participationId) * 50) % 360}, 50%, 30%)`
                          : 'inherit',
                      padding: '4px 8px',
                    }}
                  >
                    {`${
                      participants?.filter(
                        (p) => Number(p.participationId) === Number(message.participationId),
                      )?.[0]?.firstName
                    } ${
                      participants?.filter(
                        (p) => Number(p.participationId) === Number(message.participationId),
                      )?.[0]?.lastName
                    }`}
                  </Bubble>
                )}
              <Bubble
                radiusType={
                  array.length === 1
                    ? 'single'
                    : index === 0
                    ? 'first'
                    : index === array.length - 1
                    ? 'last'
                    : null
                }
              >
                {message.title && <MessageTitle title={message.title} />}
                {message.text && (
                  <MessageText
                    style={{
                      fontWeight: direct
                        ? message.read || message.origin === 'M'
                          ? 'normal'
                          : 'bold'
                        : message['M'] === true || message.origin === 'M'
                        ? 'normal'
                        : 'bold',
                    }}
                  >
                    {message.text}
                  </MessageText>
                )}
                {message.mediaUrl && (
                  <MessageMedia>
                    <img src={message.mediaUrl} />
                  </MessageMedia>
                )}
              </Bubble>
            </Message>
          ))}
        </MessageGroup>
      ))}
    </MessageList>
  );
};

interface AppTextComposerProps {
  placeholder: string;
  onMessageSend: (text: string) => void;
}

const AppTextComposer: FC<AppTextComposerProps> = ({ placeholder, onMessageSend }) => {
  return (
    <TextComposer onSend={onMessageSend}>
      <Row align="center" style={{ marginLeft: '6px' }}>
        <Fill>
          <TextInput placeholder={placeholder} />
        </Fill>
        <Fit>
          <SendButton />
        </Fit>
      </Row>
    </TextComposer>
  );
};

const useStatusBadgeStyles = makeStyles((theme) => {
  const ON = theme.palette.success.main;
  const OFF = theme.palette.error.main;
  return {
    badge: {
      backgroundColor: (props) => ((props as any).online ? ON : OFF),
      color: (props) => ((props as any).online ? 'green' : 'red'),
      boxShadow: (props) =>
        typeof (props as any).online === 'boolean'
          ? `0 0 0 2px ${theme.palette.background.paper}`
          : 'none',
      right: '28%',
      display: (props) => (typeof (props as any).online === 'boolean' ? 'auto' : 'none'),
    },
  };
});
const StatusBadge: FC<BadgeProps & { online: boolean }> = (props) => {
  const { online, ...others } = props;
  const classes = useStatusBadgeStyles({ online });
  return <Badge {...others} classes={classes} />;
};

interface ChatWidgetMaximizedProps {
  minimize?: () => void; // passed from root component
}

const ChatWidgetMaximized: FC<ChatWidgetMaximizedProps> = (props) => {
  const { minimize } = props;
  const classes = useStyles(props);
  const [selected, setSelected] = useState('0');

  const {
    participants,
    directMessages,
    directMessagesUnread,
    globalMessages,
    globalMessagesUnread,
    onlineStatuses,
    setCurrentChat,
    sendMessage,
    setAsRead,
    setAsBanned,
  } = useContext<AppChatWidgetData>(ChatWidgetContext);

  const containerRef = createRef<HTMLDivElement>();

  const directMessagesUnreadCount = Object.values(directMessagesUnread).reduce((a, c) => a + c, 0);

  const [participantBanAlert, setParticipantBanAlert] = useState(false);
  const [participantToBeBanned, setParticipantToBeBanned] = useState<AppChatParticipant | null>();

  const onSeenHandler = (message: Record<string, any>, kind: 'global' | 'private') => {
    setAsRead(selected, message, kind);
  };

  const handleParticipantBan = (participant: AppChatParticipant) => {
    setParticipantToBeBanned(participant);
    setParticipantBanAlert(true);
  };

  const handleParticipantConfirmBan = () => {
    setAsBanned(Number(participantToBeBanned?.participationId ?? 0));
    setParticipantBanAlert(false);
    setParticipantToBeBanned(null);
  };

  return (
    <Drawer open anchor="right" onClose={minimize}>
      <MuiAppBar position="relative">
        <MuiToolbar>
          <IconButton onClick={minimize} className="close">
            <Close />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap style={{ width: '100%' }}>
            Chat per l'asta
          </Typography>
        </MuiToolbar>
      </MuiAppBar>
      <div className={classes.container}>
        <ChatList className={classes.chatList}>
          <ChatListItem
            active={selected === '0'}
            key={'0'}
            onClick={() => handleChatSelect('0')}
            style={{
              border: selected === '0' ? '3px solid #007bff' : 'none',
              borderRadius: selected === '0' ? '4px' : 'none',
            }}
          >
            <Avatar className={classes.avatar}>
              <RecordVoiceOver />
            </Avatar>
            <Column fill>
              <Title ellipsis>Messaggi broadcast</Title>
              <Subtitle ellipsis>Visibili a tutti i partecipanti.</Subtitle>
              {globalMessagesUnread > 0 && (
                <div style={{ marginLeft: '185px', marginTop: '-35px' }}>
                  <Badge badgeContent={globalMessagesUnread} max={99} color="error">
                    <Email />
                  </Badge>
                </div>
              )}
            </Column>
          </ChatListItem>
          <Alert
            square
            severity={directMessagesUnreadCount > 0 ? 'warning' : 'success'}
            elevation={2}
            iconMapping={{
              success: <DoneAll />,
              warning: <Email />,
            }}
          >
            <AlertTitle>Lista partecipanti</AlertTitle>
            <Typography>
              {directMessagesUnreadCount === 0
                ? 'Nessun messaggio da leggere.'
                : directMessagesUnreadCount === 1
                ? "C'è un messaggio da leggere."
                : `Ci sono ${directMessagesUnreadCount} messaggi da leggere.`}
            </Typography>
          </Alert>
          <div className={classes.participantsListContainer}>
            {participants
              .sort((a, b) => b?.owner - a?.owner)
              .map((participant: AppChatParticipant, index: number) => {
                const isSelected = selected === participant.participationId?.toString();
                return (
                  <ChatListItem
                    active={isSelected}
                    key={participant.participationId}
                    onClick={() => handleChatSelect(participant.participationId)}
                    style={{
                      border: isSelected ? '3px solid #007bff' : 'inherit',
                      borderRadius: isSelected ? '4px' : 'none',
                    }}
                  >
                    <StatusBadge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      variant="dot"
                      online={onlineStatuses[`p${participant.participationId}`]}
                    >
                      <Avatar className={classes.avatar}>
                        {participant?.owner ? '' : participant.auctionUserId ?? index}
                        {participant?.owner && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5m14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1Z"
                            />
                          </svg>
                        )}
                      </Avatar>
                    </StatusBadge>
                    <Column style={{ width: '100%' }}>
                      <Title ellipsis>
                        {participant.lastName}
                        {participant.banned ? ' (BANNATO)' : ''}
                      </Title>
                      <Subtitle ellipsis>{participant.firstName}</Subtitle>
                    </Column>
                    {directMessagesUnread[`p${participant.participationId}`] > 0 && (
                      <Badge
                        badgeContent={directMessagesUnread[`p${participant.participationId}`]}
                        max={99}
                        color="error"
                        style={{ marginRight: '12px', marginTop: '16px' }}
                      >
                        <Email />
                      </Badge>
                    )}
                    {!participant.banned && !participant.owner && (
                      <BannIcon
                        style={{ marginRight: '12px', marginTop: '10px' }}
                        onClick={() => handleParticipantBan(participant)}
                      />
                    )}
                  </ChatListItem>
                );
              })}
          </div>
        </ChatList>
        <div ref={containerRef} className={classes.messageListContainer}>
          {selected !== '0' && (
            <AppMessageList
              messages={directMessages}
              containerRef={containerRef}
              onSeen={(msg) => onSeenHandler(msg, 'private')}
              direct={true}
            />
          )}
          {selected === '0' && (
            <AppMessageList
              messages={globalMessages}
              containerRef={containerRef}
              onSeen={(msg) => onSeenHandler(msg, 'global')}
              participants={participants}
            />
          )}
          {selected === '0' && (
            <Alert severity="warning" variant="standard">
              <AlertTitle>
                I messaggi inviati in questa chat saranno visibili a tutti i partecipanti.
              </AlertTitle>
            </Alert>
          )}

          <Dialog open={participantBanAlert && participantToBeBanned !== null}>
            <DialogTitle>Attenzione</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sei sicuro di voler rimuovere il partecipante {participantToBeBanned?.firstName}{' '}
                {participantToBeBanned?.lastName} dalla chat pubblica?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                children="NO"
                onClick={() => {
                  setParticipantBanAlert(false);
                  setParticipantToBeBanned(null);
                }}
              />
              <Button
                children="SI"
                variant="contained"
                color="primary"
                onClick={handleParticipantConfirmBan}
              />
            </DialogActions>
          </Dialog>

          <AppTextComposer
            placeholder="Scrivi e premi INVIO per spedire."
            onMessageSend={(text) => sendMessage(selected, { text })}
          />
        </div>
      </div>
    </Drawer>
  );

  function handleChatSelect(chatId: string) {
    setSelected(chatId.toString());
    if (chatId) setCurrentChat(chatId);
  }
};

export default ChatWidgetMaximized;
