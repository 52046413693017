import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { BooleanField, FunctionField, ListProps, SelectField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import TodayFilterButton from '../../Components/ui/button/TodayFilterButton';
import TodayEndFilterButton from '../../Components/ui/button/TodayEndFilterButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import {
  AppDateInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { AUCTION_STATUS } from '../../utils/constants';

const useStyles = makeStyles(
  (theme) => ({
    pvpId: {
      minWidth: theme.spacing(8),
    },
    procedureCode: {
      minWidth: theme.spacing(15),
    },
    procedureTitle: {
      minWidth: theme.spacing(20),
    },
    itemTitle: {
      minWidth: theme.spacing(20),
    },
    itemCode: {
      minWidth: theme.spacing(20),
    },
    saleMode: {
      minWidth: theme.spacing(15),
    },
    auctionStatus: {
      minWidth: theme.spacing(20),
    },
    auctionDates: {
      minWidth: theme.spacing(20),
    },
    auctionPrices: {
      minWidth: theme.spacing(15),
    },
    isCurrent: {
      minWidth: theme.spacing(15),
    },
    lastColumn: {
      width: '100%',
    },
  }),
  { name: 'SaleExperimentList' },
);

/// region FILTERS

const SaleExperimentListFiltersForAgencies = (
  <AppListFilter
    filterButtons={[
      <TodayFilterButton
        fromSource="auctionStartFrom"
        toSource="auctionStartTo"
        label="Aste del giorno"
      />,
      <TodayEndFilterButton
        fromSource="auctionEndFrom"
        toSource="auctionEndTo"
        label="Aste in chiusura oggi"
      />,
    ]}
    initialFilters={{
      auctionStartFrom: new Date().toISOString().substr(0, 10),
      auctionStartTo: new Date().toISOString().substr(0, 10),
    }}
  >
    <AppDateInput source="auctionStartFrom" label="Inizio asta dal..." md={3} />
    <AppDateInput source="auctionStartTo" label="Inizio asta al..." md={3} />
    <AppDateInput source="auctionEndFrom" label="Termine asta dal..." md={3} />
    <AppDateInput source="auctionEndTo" label="Termine asta al..." md={3} />
    <AppSelectInput
      source="withOffers"
      label="Gare con offerte"
      choices={[
        { id: 1, name: 'SI' },
        { id: -1, name: 'NO' },
      ]}
      resettable
      md={2}
    />
    <AppSelectInput
      source="internalSale"
      label="Vendita interna"
      choices={[
        { id: 1, name: 'SI' },
        { id: -1, name: 'NO' },
      ]}
      resettable
      md={2}
    />
    <AppTextInput source="procedureCode" label="Numero procedura" md={2} />
    <AppSelectInput
      source="saleExperiment_auctionStatus"
      choices={AUCTION_STATUS}
      resettable
      label="Stato asta"
      md={2}
    />
    <AppNumberInput source="saleExperiment_id" label="Ricerca per ID interno" md={2} />
  </AppListFilter>
);

const SaleExperimentListFilters = (
  <AppListFilter
    filterButtons={[
      <TodayFilterButton
        fromSource="auctionStartFrom"
        toSource="auctionStartTo"
        label="Aste del giorno"
      />,
      <TodayEndFilterButton
        fromSource="auctionEndFrom"
        toSource="auctionEndTo"
        label="Aste in chiusura oggi"
      />,
    ]}
    initialFilters={{
      auctionStartFrom: new Date().toISOString().substr(0, 10),
      auctionStartTo: new Date().toISOString().substr(0, 10),
    }}
  >
    <AppDateInput source="auctionStartFrom" label="Inizio asta dal..." md={3} />
    <AppDateInput source="auctionStartTo" label="Inizio asta al..." md={3} />
    <AppDateInput source="auctionEndFrom" label="Termine asta dal..." md={3} />
    <AppDateInput source="auctionEndTo" label="Termine asta al..." md={3} />
    <AppSelectInput
      source="withOffers"
      label="Gare con offerte"
      choices={[
        { id: 1, name: 'SI' },
        { id: -1, name: 'NO' },
      ]}
      resettable
      md={2}
    />
    <AppSelectInput
      source="internalSale"
      label="Vendita interna"
      choices={[
        { id: 1, name: 'SI' },
        { id: -1, name: 'NO' },
      ]}
      resettable
      md={2}
    />
    <AppTextInput source="procedureCode" label="Numero procedura" md={2} />
    <AppSelectInput
      source="saleExperiment_auctionStatus"
      choices={AUCTION_STATUS}
      resettable
      label="Stato asta"
      md={2}
    />
    <AppNumberInput source="saleExperiment_id" label="Ricerca per ID interno" md={2} />
    <AppNumberInput source="saleExperiment_pvpId" label="Ricerca per ID PVP" md={2} />
  </AppListFilter>
);

/// endregion

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const SaleExperimentList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      bulkActionButtons={false}
      filters={
        props.permissions?.agency ? SaleExperimentListFiltersForAgencies : SaleExperimentListFilters
      }
      filter={{
        _source: 'list',
      }}
      sort={{
        field: 'isCurrent',
        order: 'DESC',
      }}
      filterDefaultValues={{
        auctionStartFrom: new Date().toISOString().substr(0, 10),
        auctionStartTo: new Date().toISOString().substr(0, 10),
      }}
      title={<AppTitle title={props.options.label} />}
    >
      <AppDatagrid rowClick="show">
        {IS_IVG && <AppTextField source="pvpId" label="Id PVP" headerClassName={classes.pvpId} />}
        <AppTextField
          source="relatedItem.relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={classes.procedureCode}
        />
        <AppTextField
          source="relatedItem.relatedFolder.title"
          label="Nome procedura"
          headerClassName={classes.procedureTitle}
          truncate
        />
        <AppTextField
          source="relatedItem.title"
          label="Titolo lotto"
          truncate
          headerClassName={classes.itemTitle}
        />
        <AppTextField
          source="relatedItem.code"
          label="Codice lotto"
          headerClassName={classes.itemCode}
        />
        <AppTextField
          source="relatedSaleMode.description"
          label="Modalità di vendita"
          headerClassName={classes.saleMode}
        />
        <SelectField
          source="auctionStatus"
          label="Stato asta"
          choices={AUCTION_STATUS}
          headerClassName={classes.auctionStatus}
        />
        <AppTextField
          source="basePrice"
          label="Prezzo base"
          type="amount"
          headerClassName={classes.auctionPrices}
        />
        <FunctionField
          label="Prezzo minimo"
          render={(r) =>
            r && r.relatedItem?.relatedFolder?.type === 'judiciary' ? (
              <AppTextField record={r} source="minPrice" type="amount" />
            ) : (
              '-'
            )
          }
          headerClassName={classes.auctionPrices}
        />
        <AppDateField
          source="auctionStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={classes.auctionDates}
        />
        <AppDateField
          source="auctionEndAt"
          label="Data/ora termine asta"
          showTime
          headerClassName={classes.auctionDates}
        />
        <BooleanField
          source="isCurrent"
          label="Vendita corrente"
          headerClassName={classes.isCurrent}
        />
        <FunctionField
          label="Offerte"
          render={(r) => (
            <AppTextField
              customText={
                r?.relatedParticipations?.length > 0 ? r?.relatedParticipations?.length : ''
              }
            />
          )}
          headerClassName={classes.lastColumn}
        />
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default SaleExperimentList;
