import PvpAssignmentIcon from '@material-ui/icons/Assignment';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import PvpAssignmentDetails from './PvpAssignmentDetails';
import PvpAssignmentList from './PvpAssignmentList';

export const PVP_ASSIGNMENT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const PublicationRequest: ResourceExport = {
  list: PvpAssignmentList,
  create: resourceWrapper(
    'create',
    PvpAssignmentDetails,
    undefined,
    undefined,
    PVP_ASSIGNMENT_PERMISSIONS,
  ),
  icon: PvpAssignmentIcon,
};

export default PublicationRequest;
