import React, { Component } from 'react';
import { Autocomplete, AutocompleteState } from '@react-google-maps/api';
import { AppTextInput } from './';

type Props = {
  onPlaceChanged: () => void,
  disabled: boolean,
};

export class AppGeographiesAutocomplete extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: '',
    };

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }

  updateInputValue(evt: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      inputValue: evt.target.value,
    });
  }

  onLoad(autocomplete: AutocompleteState): void {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged(): void {
    this.props.onPlaceChanged(this.autocomplete);
  }

  render(): JSX.Element {
    return (
      <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}>
        <AppTextInput
          source={'cerca'}
          required={false}
          label={this.state.inputValue === '' ? '' : 'Cerca'}
          variant="outlined"
          md={12}
          disabled={this.props.disabled}
          placeholder="Cerca"
          value={this.state.inputValue}
          onChange={(evt) => this.updateInputValue(evt)}
        />
      </Autocomplete>
    );
  }
}

<AppGeographiesAutocomplete />;
