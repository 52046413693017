import { GridProps } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  CloudTwoTone as FrontEndOriginIcon,
  HomeWorkTwoTone as PaperOriginIcon,
  AccountBalance as PvpOriginIcon,
} from '@material-ui/icons';
import AllAuthorizedIcon from '@material-ui/icons/Spellcheck';
import { FC } from 'react';
import { FunctionField } from 'react-admin';
import { useHistory } from 'react-router';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import { useDashboardContext } from '../../../hooks';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { PersonRoles, SaleMode } from '../../../utils/constants';
import { folderIsJudiciary } from '../../../utils/folder';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      minWidth: theme.spacing(30),
    },
    presenter: {
      minWidth: theme.spacing(30),
    },
    auctionStartAt: {
      minWidth: theme.spacing(30),
    },
    saleMode: {
      width: '100%',
      minWidth: theme.spacing(20),
    },
    cellCenter: {
      textAlign: 'center',
    },
  }),
  { name: 'AppDashboardPendingParticipations' },
);

interface AppDashboardPendingParticipationsProps extends GridProps {
  a107?: boolean;
  expressionOfInterests?: boolean;
  offersCollections?: boolean;
}

const AppDashboardPendingParticipations: FC<AppDashboardPendingParticipationsProps> = ({
  a107 = false,
  expressionOfInterests,
  offersCollections,
  ...gridProps
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useDashboardContext();

  const allowView = useUserIsAllowed(ResourceAction.Get, 'participations');

  if (!allowView || (a107 && user?.roleId === PersonRoles.Owner)) return <></>;

  if (
    user?.roleId === PersonRoles.Auctioneer &&
    (a107 || expressionOfInterests || offersCollections)
  )
    return null;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="participations/pending"
        filter={{
          a107,
          fkSaleMode: expressionOfInterests
            ? SaleMode.ExpressionOfInterest
            : offersCollections
            ? SaleMode.OffersCollection
            : undefined,
        }}
        tableTitle={`Partecipanti${
          a107
            ? ' art. 107 L.F.'
            : expressionOfInterests
            ? ' a Manifestazioni di Interesse'
            : offersCollections
            ? ' a Raccolta Offerte'
            : '/Osservatori'
        } in attesa di autorizzazione`}
        tableSubtitle={`Di seguito sono ${
          a107
            ? "elencate le offerte dopo l'aggiudicazione in attesa di verifica"
            : expressionOfInterests
            ? 'elencati gli iscritti a manifestazioni di interesse'
            : offersCollections
            ? 'elencati gli iscritti a raccolte offerte'
            : 'elencati i partecipanti alle aste in attesa di verifica'
        }`}
        dataError="Non è stato possibile recuperare i partecipanti in attesa di autorizzazione."
        noItemMessage="Nessun partecipante in attesa di autorizzazione."
        noItemIcon={<AllAuthorizedIcon />}
        rowClick={(id, basePath, record) =>
          history.push(
            `${
              record.relatedSaleExperiment?.relatedItem?.relatedFolder
                ? folderIsJudiciary(record.relatedSaleExperiment?.relatedItem?.relatedFolder)
                  ? 'sale-experiments-judiciary'
                  : 'sale-experiments-private'
                : 'sale-experiments'
            }/${
              record.fkSaleExp
            }/participants?goBack=true&resourceLabel=dashboard&previousResourcePath=${
              location.pathname
            }`,
          )
        }
        rowStyle={(record) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const auctionStartAt = new Date(record.relatedSaleExperiment.auctionStartAt);
          return auctionStartAt.getTime() < today.getTime() ||
            !record.relatedSaleExperiment.auctionStartAt
            ? { backgroundColor: red[500] }
            : {};
        }}
      >
        <FunctionField
          label="Presentatore"
          headerClassName={classes.presenter}
          render={(record) => `${record?.presenter.firstName} ${record?.presenter.lastName}`}
        />
        <FunctionField
          label="Tipo"
          render={(record) => (record?.observer ? 'Osservatore' : 'Partecipante')}
        />
        <FunctionField
          label="Origine"
          cellClassName={classes.cellCenter}
          render={(record) =>
            record?.origin === 'paper' ? (
              <PaperOriginIcon />
            ) : record?.origin === 'front-end' ? (
              <FrontEndOriginIcon />
            ) : (
              <PvpOriginIcon />
            )
          }
        />
        <AppTextField
          source="relatedSaleExperiment.relatedItem.title"
          label="Lotto"
          headerClassName={classes.title}
          truncate
        />
        <AppDateField
          source="relatedSaleExperiment.auctionStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={classes.auctionStartAt}
        />
        <AppTextField
          source="relatedSaleExperiment.relatedSaleMode.description"
          label="Modalità di vendita"
          headerClassName={classes.saleMode}
        />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardPendingParticipations;
