import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { filter as _filter, sortBy as _sortBy } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';
import AppTooltip from '../ui/tooltip/AppTooltip';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'OrderSectionButton' },
);

const OrderSectionButtons: FC<any> = (props) => {
  const { record: section, disabled, agency } = props;
  const notify = useNotify();
  const classes = useStyles();

  // ctx info
  const ctx = useContext(AppRelatedResourceContext);
  const { mainResourceRecord: page, listContext } = ctx;
  const { data: allSection } = listContext;

  // circular loader state
  const [openLoader, setOpenLoader] = useState(false);

  /// region ORDERING HANDLER

  const [mutateSectionOrder] = useMutation();
  const updateSectionOrder = (direction: 'up' | 'down') => {
    setOpenLoader(true);
    mutateSectionOrder(
      {
        type: 'update',
        resource: `pages${agency ? '-agencies' : ''}/${page.id}/section/order`,
        payload: {
          id: section.id,
          data: {
            direction,
          },
        },
      },
      {
        onSuccess: () => {
          ctx.getRelatedResources(); // update list
          setOpenLoader(false);
        },
        onFailure: (err) => {
          console.error(err);
          setOpenLoader(false);
          notify(err.message, 'error');
        },
      },
    );
  };

  /// endregion

  // Disable "UP" button if first section
  const [firstSection, setFirstSection] = useState(false);
  // Disable "DOWN" button if last section
  const [lastSection, setLastSection] = useState(false);
  useEffect(() => {
    // search last section
    const sortedSection = _sortBy(_filter(allSection), (filteredSection) => filteredSection.order);
    setFirstSection(sortedSection[0].order === section.order);
    setLastSection(sortedSection[sortedSection.length - 1].order === section.order);
  }, [ctx.listContext]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <AppTooltip title="Ordina su" placement="top">
        <Button
          children={<ExpandLess />}
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => updateSectionOrder('up')}
          className={classes.button}
          disabled={disabled || firstSection}
        />
      </AppTooltip>
      <AppTooltip title="Ordina giù" placement="bottom">
        <Button
          children={<ExpandMore />}
          variant="contained"
          size="small"
          color="secondary"
          className={classes.button}
          onClick={() => updateSectionOrder('down')}
          disabled={disabled || lastSection}
        />
      </AppTooltip>
      <AppCircularLoader open={openLoader} />
    </div>
  );
};

export default OrderSectionButtons;
