import { Delete } from '@material-ui/icons';
import inflection from 'inflection';
import { useTranslate } from 'ra-core';
import { FC, Fragment } from 'react';
import {
  Confirm,
  useCheckMinimumRequiredProps,
  useDeleteWithConfirmController,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import AppToolbarAction, { AppToolbarActionProps } from '../layout/AppToolbarAction';

interface AppToolbarDeleteProps extends Omit<AppToolbarActionProps, 'onClick' | 'text'> {
  onClick?: (event: any) => void;
  text?: string;
}

const AppToolbarDelete: FC<AppToolbarDeleteProps> = (props) => {
  useCheckMinimumRequiredProps('AppToolbarDelete', ['basePath', 'record', 'resource'], props);
  const { record, basePath, redirect = 'list' } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();

  const { open, loading, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      record,
      redirect,
      basePath,
      //onClick,
      onSuccess: () => {
        redirectTo(redirect, basePath);
        notify('Eliminazione effettuata con successo', 'info', { smart_count: 1 });
      },
      //onFailure,
    });
  const resource = useResourceContext(props);
  const translate = useTranslate();

  const allowDelete = useUserIsAllowed(ResourceAction.Delete, props.resource);

  return (
    <Fragment>
      <AppToolbarAction
        {...props}
        onClick={handleDialogOpen}
        text="Elimina"
        icon={Delete}
        disabled={!allowDelete}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Cancellazione dati"
        content="Vuoi procedere con la cancellazione?"
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true,
            ),
          }),
          id: (record || {}).id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default AppToolbarDelete;
