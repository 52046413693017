import ReceiptIcon from '@material-ui/icons/Receipt';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import InvoiceDetails from './InvoiceDetails';
import InvoiceList from './InvoiceList';

export const INVOICE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Invoice: ResourceExport = {
  list: InvoiceList,
  show: resourceWrapper('show', InvoiceDetails, undefined, undefined, INVOICE_PERMISSIONS),
  create: resourceWrapper('create', InvoiceDetails, undefined, undefined, INVOICE_PERMISSIONS),
  icon: ReceiptIcon,
};

export default Invoice;
