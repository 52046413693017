import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { get as _get } from 'lodash';
import { useNotify } from 'ra-core';
import React, { FC, ReactElement } from 'react';
import { TextFieldProps } from 'react-admin';
import { amountFormatter } from '../../../utils/data-formatters';
import AppTooltip from '../tooltip/AppTooltip';

export interface AppTextFieldProps extends TextFieldProps {
  source?: string;
  label?: string;
  headerClassName?: string;
  type?: 'text' | 'amount';
  truncate?: boolean;
  maxTextLength?: number;
  customText?: string;
  array?: boolean;
  choices?: Record<string, any>[]; // used with "array" to translate values
  clickToCopy?: boolean; // if true user can copy text to clipboard by clicking on the text
  isUrl?: boolean;
  component?: React.ElementType;
  variant?: Variant;
}

export const AppTextField: FC<AppTextFieldProps> = (props): ReactElement => {
  const {
    source = '',
    type = 'text',
    truncate = false,
    maxTextLength = 20,
    record = {},
    emptyText = '-',
    customText,
    array = false,
    choices,
    clickToCopy = false,
    isUrl = false,
    component = 'span',
    variant = 'body2',
    style,
    ...rest
  } = props;
  const notify = useNotify();
  let value = customText ?? _get(record, source, emptyText);

  if (array && value instanceof Array) {
    if (choices && choices.length)
      value = value.map((v) => choices.find((choice) => choice.id === v)?.name ?? emptyText);
    value = value.join(', ');
  }

  const canCopyToClipboard = clickToCopy && value !== emptyText;
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => notify('Testo copiato negli appunti.'))
      .catch();
  };

  const TextField = (
    <Typography
      {...rest}
      component={component}
      variant={variant}
      onClick={canCopyToClipboard ? copyToClipboard : undefined}
      style={{ ...style, cursor: canCopyToClipboard || isUrl ? 'pointer' : 'text' }}
    >
      {_renderText(value, type === 'amount', truncate, maxTextLength)}
    </Typography>
  );

  return _truncateText(truncate, value, maxTextLength) ? (
    <AppTooltip title={value}>{TextField}</AppTooltip>
  ) : (
    TextField
  );
};

export default AppTextField;

function _renderText(value, isAmount: boolean, truncate: boolean, maxTextLength: number): string {
  if (isAmount) return amountFormatter(value);

  return _truncateText(truncate, value, maxTextLength)
    ? `${value.substr(0, maxTextLength)}...`
    : value;
}

function _truncateText(truncate: boolean, value: string, maxTextLength: number): boolean {
  return truncate && !!value && value.length > maxTextLength;
}
