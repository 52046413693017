import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { FC, Fragment, ReactElement } from 'react';
import { BooleanField, FunctionField, ReferenceInput, SelectField } from 'react-admin';
import CaptureFormData from '../../Components/capture-form-data/capture-form-data';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';
import { InfoRequestLegend } from '../../Components/info-request/info-request-legend';
import AppAddressData from '../../Components/person-company/address-data.component';
import AppContactsData from '../../Components/person-company/contacts-data.component';
import AppRolesData from '../../Components/person-company/roles-data.component';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppLinkToEntityField from '../../Components/ui/field/AppLinkToEntityField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppDateInput,
  AppRichTextInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import { AppBold, AppText } from '../../Components/ui/text';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import { GENDER_CHOICES, INFO_REQUEST_COLORS } from '../../utils/constants';
import { BOOKER_BASE_SOURCE, ITEM_BASE_SOURCE, PERSON_TYPES } from '../Booking/constants';
import FolderDatagrid from '../Folder/FolderDatagrid';
import InfoRequestDatagrid from '../InfoRequest/InfoRequestDatagrid';
import InfoRequestExpanded from '../InfoRequest/InfoRequestExpanded';
import OpLogDatagrid from '../OpLog/OpLogDatagrid';
import { PlatformAccessState } from './enums';

const useStyles = makeStyles((theme) => ({
  accessDetails: {
    marginBottom: theme.spacing(2),
  },
}));

const useBookingsDgStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(80),
    },
    name: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'BookingDatagrid' },
);

const UserAccessDetails: FC<any> = (props) => {
  const { record } = props;
  const classes = useStyles();
  return (
    <Alert
      severity={
        record.accessState === PlatformAccessState.Active && record.passwordCreated
          ? 'info'
          : 'warning'
      }
      variant="outlined"
      className={classes.accessDetails}
    >
      <AppText>
        L'utente ha accesso alla piattaforma con ruolo{' '}
        <AppBold>{record.loginRole?.description}</AppBold>{' '}
        {record.accessState === PlatformAccessState.Disabled && (
          <span>
            ma attualmente risulta essere <AppBold>Disattivo</AppBold>. Riattivandolo è possibile
            anche eseguire il reset della password.
          </span>
        )}{' '}
        {record.accessState === PlatformAccessState.Active && (
          <span>
            {record.passwordCreated
              ? 'e può accedere alla piattaforma. In caso di problemi di accesso disattivarlo e riattivarlo nuovamente.'
              : 'ma non ha ancora impostato la password.'}
          </span>
        )}
      </AppText>
    </Alert>
  );
};

const INFO_REQUEST_ENABLED = process.env.REACT_APP_INFO_REQUEST_ENABLED === 'true';

const PersonDetails = (props: Record<any, any>): ReactElement => {
  const bookingsDgClasses = useBookingsDgStyles();
  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        {props.record?.id ? <CaptureFormData /> : <Fragment />}
        {props.record?.id && props.record?.accessState !== PlatformAccessState.Inactive ? (
          <UserAccessDetails />
        ) : (
          <Fragment />
        )}
        <AppCard title="Anagrafica" expanded>
          <AppTextInput source="lastName" label="Cognome" required />
          <AppTextInput source="firstName" label="Nome" required />
          <AppSelectInput source="gender" label="Sesso" choices={GENDER_CHOICES} />
          <AppDateInput source="birthday" label="Data di nascita" />
          <AppTextInput source="taxCode" label="Codice fiscale" />
          <ReferenceInput label="Azienda" source="fkCompany" reference="companies">
            <AppSelectInput optionText="businessName" />
          </ReferenceInput>
        </AppCard>
        <AppAddressData
          primaryAddress="Residenza"
          secondaryAddress="Domicilio"
          geocoderRef={props.options.geocoderRef}
          {...props}
        />
        <AppContactsData {...props} emailRequired />
        <AppRolesData {...props} personType="naturalPerson" />
        <AppCard title="Annotazioni">
          <AppRichTextInput source="notes" label="" />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Fascicoli" path="folders" maxWidth={false}>
        <AppCard hideContent={props.mode === 'create'} collapsable={false} header={false}>
          <AppRelatedResource relatedResource="/folders" actionsHidden>
            <FolderDatagrid
              rowClick={(id) =>
                `/folders/${id}/show?goBack=true&resourceLabel=Persona&previousResourcePath=${props.location.pathname}`
              }
              editButton={
                <AppGoToResourceButton
                  mode="edit"
                  destinationResourceName="folders"
                  currentResourceLabel="Persona"
                  action={ResourceAction.Put}
                />
              }
              hideEditButton={props.mode === 'show'}
              showButton={
                <AppGoToResourceButton
                  mode="show"
                  destinationResourceName="folders"
                  currentResourceLabel="Persona"
                  action={ResourceAction.Get}
                />
              }
              permissions={props.permissions}
              currentResourceLabel="Persona"
              resource={props.resource}
            />
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Prenotazioni" path="bookings" maxWidth={false}>
        <AppCard hideContent={props.mode === 'create'} collapsable={false} header={false}>
          <AppRelatedResource relatedResource="/bookings" actionsHidden>
            <AppDatagrid>
              <AppDateField
                source="createdAt"
                label="Data richiesta"
                headerClassName={bookingsDgClasses.createdAt}
              />
              <AppLinkToEntityField
                label="Lotto da visitare"
                targetResource="items"
                originName="persona"
                value={(r) => r[ITEM_BASE_SOURCE].title}
                targetResourceId={(r) => r[ITEM_BASE_SOURCE].id}
                truncate
                maxTextLength={60}
                headerClassName={bookingsDgClasses.itemTitle}
              />
              <FunctionField
                label="Nominativo"
                render={(record) =>
                  !!record && (
                    <AppTextField
                      customText={
                        record[BOOKER_BASE_SOURCE].type === 'person'
                          ? `${record[BOOKER_BASE_SOURCE].lastName} ${record[BOOKER_BASE_SOURCE].firstName}`
                          : record[BOOKER_BASE_SOURCE].businessName
                      }
                    />
                  )
                }
                headerClassName={bookingsDgClasses.name}
              />
              <SelectField
                choices={PERSON_TYPES}
                source={`${BOOKER_BASE_SOURCE}.type`}
                label="Tipo persona"
                headerClassName={bookingsDgClasses.type}
              />
              <BooleanField
                source="alreadyExported"
                label="Già esportato"
                headerClassName={bookingsDgClasses.lastCol}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Richieste info" path="info-requests" maxWidth={false}>
        {INFO_REQUEST_ENABLED ? (
          <AppCard title="Richieste di informazioni" expanded>
            <AppRelatedResource relatedResource="/info-requests" actionsHidden>
              <InfoRequestDatagrid
                expand={<InfoRequestExpanded />}
                rowStyle={(record) => ({
                  backgroundColor: INFO_REQUEST_COLORS[record.requestStatus],
                })}
                additionalInfo={<InfoRequestLegend />}
                resource={props.resource}
              />
            </AppRelatedResource>
          </AppCard>
        ) : (
          <DeactivatedFeature
            title={'La funzionalità "Richieste info" attualmente non è attiva'}
            subtitle={"Contatta l'assistenza per attivarla"}
          />
        )}
      </AppFormTab>
      <AppFormTab label="Logs" path="op-log" maxWidth={false}>
        <AppCard hideContent={props.mode === 'create'} collapsable={false} header={false}>
          <AppRelatedResource relatedResource="/op-log" actionsHidden>
            <OpLogDatagrid />
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default PersonDetails;
