import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ListProps, useMutation, usePermissions } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput, AppTextInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { ITEM_PERMISSIONS } from './index';
import ItemDatagrid from './ItemDatagrid';
import { User } from '../../types/user.type';

/// region FILTERS
const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const ItemListFiltersForAgencies = (typologyChoices, typologies) => (
  <AppListFilter>
    <AppTextInput source="procedureCode" label="Numero procedura" md={2} />
    <AppTextInput source="item_id" label="Progressivo interno" md={2} />
    {IS_IVG ? (
      <AppTextInput source="ivgCode" label="Numero IVG" fulltext minLength={2} md={2} />
    ) : (
      <Fragment />
    )}
    <AppAutocompleteInput
      // reference="typologies"
      source="typology_id"
      label="Tipologia"
      optionText="description"
      optionValue="type"
      choices={typologyChoices.filter((typology) => typologies.includes(typology.type))}
      md={2}
      resettable
      suggestionLimit={10}
    />
    <AppTextInput
      source="addressWithCity"
      label="Indirizzo e comune"
      md={5}
      fulltext
      minLength={3}
    />
  </AppListFilter>
);

const ItemListFilters = (
  <AppListFilter>
    <AppTextInput source="procedureCode" label="Numero procedura" md={2} />
    <AppTextInput source="item_id" label="Progressivo interno" md={2} />
    {IS_IVG ? (
      <AppTextInput source="ivgCode" label="Numero IVG" fulltext minLength={2} md={2} />
    ) : (
      <Fragment />
    )}
    <AppAutocompleteInput
      reference="genres"
      source="genre_id"
      label="Genere"
      optionText="description"
      md={2}
      resettable
    />
    <AppAutocompleteInput
      reference="typologies"
      source="typology_id"
      label="Tipologia"
      optionText="description"
      md={2}
      resettable
      suggestionLimit={5}
    />
    <AppTextInput
      source="addressWithCity"
      label="Indirizzo e comune"
      md={5}
      fulltext
      minLength={3}
    />
  </AppListFilter>
);

/// endregion

const ItemList: FunctionComponent<ListProps> = (props) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { permissions: user } = usePermissions<User>();
  // console.log('user', user);

  const [typologyChoices, setTypologyChoices] = useState<any[]>([]);
  const [mutateTypologies] = useMutation();
  const getTypologies = (categoryName) => {
    fetch(`${apiUrl}/categories/by-name/${categoryName}`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            mutateTypologies(
              {
                type: 'getList',
                resource: 'typologies',
                payload: {
                  filter: {
                    fkCategory: data.id,
                  },
                },
              },
              {
                onSuccess: (res) => {
                  setTypologyChoices(res.data);
                },
                onFailure: (err) => {
                  console.error(err);
                  setTypologyChoices([]);
                },
              },
            );
          })
          .catch((error) => {
            console.error('Error fetching ', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching ', error);
      });
  };

  useEffect(() => {
    getTypologies(user?.agency?.category);
  }, [user?.agency?.category]);

  return (
    <AppList
      {...props}
      title={<AppTitle title={props.options.label} />}
      bulkActionButtons={false}
      filters={
        props.permissions?.agency
          ? ItemListFiltersForAgencies(typologyChoices, user?.agency?.typologies)
          : ItemListFilters
      }
      filter={{
        _source: 'list',
      }}
      resourcePermissions={ITEM_PERMISSIONS}
    >
      <ItemDatagrid rowClick="show" permissions={props.permissions} />
    </AppList>
  );
};

export default ItemList;
