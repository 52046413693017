import { Grid } from '@material-ui/core';
import React, { FC, Fragment, useEffect, useState } from 'react';
import AppItemLinksButton from '../../ui/button/AppItemLinksButton';
import PublicationNotificationDialog from '../publication-notification/publication-notification-dialog';
import RequestLinkDialog from '../request-link/request-link-dialog';
import RequestButton from '../utilities/request-button';

const RequestActions: FC<any> = ({ pvpRequest, resource }) => {
  const [linkDialogIsOpen, setLinkDialogIsOpen] = useState<boolean>(false);
  const toggleLinkDialog = () => setLinkDialogIsOpen((prevState) => !prevState);
  const [linkButtonEnabled, setLinkButtonEnabled] = useState<boolean>(false);

  const [notifyDialogIsOpen, setNotifyDialogIsOpen] = useState<boolean>(false);
  const toggleNotifyDialog = () => setNotifyDialogIsOpen((prevState) => !prevState);
  const [notifyButtonEnabled, setNotifyButtonEnabled] = useState<boolean>(false);

  useEffect(() => {
    setLinkButtonEnabled(!pvpRequest.processed);
    setNotifyButtonEnabled(pvpRequest.processed && !pvpRequest.publicationNotified);
  }, [pvpRequest.processed, pvpRequest.publicationNotified, pvpRequest.saleReportSent]);

  return (
    <Fragment>
      <Grid container>
        <Grid item>
          <RequestButton
            disabled={!linkButtonEnabled}
            onClick={toggleLinkDialog}
            children="Gestisci associazione"
          />
        </Grid>
        <Grid item>
          <RequestButton
            disabled={!notifyButtonEnabled}
            onClick={toggleNotifyDialog}
            children="Notifica pubblicazione al PVP"
          />
        </Grid>
        {pvpRequest.relatedItemId && (
          <Grid item>
            <RequestButton
              CustomButton={
                <AppItemLinksButton
                  resource={resource}
                  record={pvpRequest}
                  itemIdSource="relatedItemId"
                  folderIdSource="relatedFolderId"
                  saleExpIdSource="relatedSaleId"
                  forceJudiciaryTargetResource
                  currentResourceLabel="Richieste di pubblicazione"
                />
              }
            />
          </Grid>
        )}
      </Grid>

      {linkButtonEnabled && (
        <RequestLinkDialog
          open={linkDialogIsOpen}
          closeDialog={toggleLinkDialog}
          pvpRequest={pvpRequest}
        />
      )}
      {notifyButtonEnabled && (
        <PublicationNotificationDialog
          open={notifyDialogIsOpen}
          closeDialog={toggleNotifyDialog}
          pvpRequestId={pvpRequest.id}
          publications={pvpRequest.publicationsList}
        />
      )}
    </Fragment>
  );
};

export default RequestActions;
