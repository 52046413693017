import MediaRolesIcon from '@material-ui/icons/FileCopy';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import MediaRolesDetails from './MediaRolesDetails';
import MediaRolesList from './MediaRolesList';

const { Auctioneer } = PersonRoles;

export const MEDIA_ROLES_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const MediaRoles: ResourceExport = {
  list: MediaRolesList,
  create: resourceWrapper(
    'create',
    MediaRolesDetails,
    undefined,
    undefined,
    MEDIA_ROLES_PERMISSIONS,
  ),
  edit: resourceWrapper('edit', MediaRolesDetails, undefined, undefined, MEDIA_ROLES_PERMISSIONS),
  show: resourceWrapper('show', MediaRolesDetails, undefined, undefined, MEDIA_ROLES_PERMISSIONS),
  icon: MediaRolesIcon,
};

export default MediaRoles;
