import { makeStyles, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ReactElement, useEffect, useState } from 'react';
import { BooleanField, Button, FunctionField, ReferenceField, TextField } from 'react-admin';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';
import { InfoRequestLegend } from '../../Components/info-request/info-request-legend';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppDateField, AppLinkToEntityField, AppUrlField } from '../../Components/ui/field';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppDateInput, AppTextInput } from '../../Components/ui/input';
import AppInternationalGeographiesInput from '../../Components/ui/input/AppInternationalGeographiesInput';
import AppInternationalGeographiesNoAddressInput from '../../Components/ui/input/AppInternationalGeographiesNoAddressInput';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { AppText } from '../../Components/ui/text';
import AppTooltip from '../../Components/ui/tooltip/AppTooltip';
import { INFO_REQUEST_COLORS } from '../../utils/constants';
import { ITEM_BASE_SOURCE } from '../Booking/constants';
import InfoRequestDatagrid from '../InfoRequest/InfoRequestDatagrid';
import InfoRequestExpanded from '../InfoRequest/InfoRequestExpanded';
import Attachment from './Attachment';
import AttachmentsWindow from './attachments/attachments-window';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    publications: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(50),
    },
    saleExp: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    booleanField: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    noDocument: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'FrontEndUsersDetails' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';
const INFO_REQUEST_ENABLED = process.env.REACT_APP_INFO_REQUEST_ENABLED === 'true';

const FrontEndUsersDetails = (props: Record<any, any>): ReactElement => {
  const classes = useStyles();

  const [documentBack, setDocumentBack] = useState<any>();
  const [documentFront, setDocumentFront] = useState<any>();
  const [attachmentsWindowVisible, setAttachmentsWindowVisible] = useState<boolean>(false);
  const [attachmentIds, setAttachmentIds] = useState<number[]>([]);

  useEffect(() => {
    if (props.record?.documentBack) setDocumentBack(props.record?.documentBack);
    if (props.record?.documentFront) setDocumentFront(props.record?.documentFront);
  }, [props.record?.documentBack, props.record?.documentFront]);

  const handleAttachmentsOpened = (ids: number[]) => {
    setAttachmentsWindowVisible(true);
    setAttachmentIds(ids);
  };

  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale">
        <AppCard title="Anagrafica" expanded>
          <AppDateInput source="createdAt" label="Data iscrizione" disabled md={3} />
          <AppTextInput source="firstName" label="Nome" disabled={props.mode === 'show'} md={3} />
          <AppTextInput source="lastName" label="Cognome" disabled={props.mode === 'show'} md={3} />
          <AppDateInput
            source="birthday"
            label="Data di nascita"
            disabled={props.mode === 'show'}
            md={3}
          />
          <AppTextInput source="taxCode" label="Codice fiscale" disabled md={3} />
          <AppTextInput source="email" label="Email" disabled md={3} />
          <AppTextInput source="pec" label="PEC" disabled={props.mode === 'show'} md={3} />
          <AppTextInput source="mobile" label="Cellulare" disabled={props.mode === 'show'} md={3} />
          <AppTextInput source="phone" label="Telefono" disabled={props.mode === 'show'} md={3} />
          <AppDivider />
        </AppCard>
        <AppCard title="Luogo di nascita" expanded>
          <AppInternationalGeographiesNoAddressInput
            variant="outlined"
            countrySource={'birthCountry'}
            regionSource={'birthRegion'}
            provinceSource={'birthProvince'}
            citySource={'birthCity'}
            foreignCitySource={'birthForeignCity'}
            mode={props.mode}
          />
        </AppCard>
        <AppCard title="Residenza" expanded>
          <AppInternationalGeographiesInput
            variant="outlined"
            countrySource={'residenceCountry'}
            regionSource={'residenceRegion'}
            provinceSource={'residenceProvince'}
            citySource={'residenceCity'}
            foreignCitySource={'residenceForeignCity'}
            addressSource={'residenceAddress'}
            addressNumberSource={'residenceAddressNumber'}
            zipCodeSource={'residenceZipCode'}
            mode={props.mode}
          />
        </AppCard>
        <AppCard title="Documenti profilo" hideContent={props.mode === 'create'} expanded>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {documentBack && <Attachment attachment={documentBack} />}
            {documentFront && <Attachment attachment={documentFront} />}
            {!documentBack && !documentFront && (
              <Typography variant="h6" className={classes.noDocument}>
                Nessun documento presente
              </Typography>
            )}
          </div>
        </AppCard>
      </AppFormTab>

      <AppFormTab label="Prenotazioni" path="visits" maxWidth={false}>
        <AppCard title="Prenotazioni" expanded>
          <AppRelatedResource relatedResource="/visits" actionsHidden>
            <AppDatagrid>
              <AppDateField
                source="createdAt"
                label="Data richiesta"
                showTime
                headerClassName={classes.createdAt}
              />
              <AppLinkToEntityField
                label="Lotto da visitare"
                targetResource="items"
                originName="visite"
                value={(r) => r[ITEM_BASE_SOURCE].title}
                targetResourceId={(r) => r[ITEM_BASE_SOURCE].id}
                truncate
                headerClassName={classes.itemTitle}
              />
              <FunctionField
                label="Pubblicazioni del lotto"
                headerClassName={classes.publications}
                render={(booking) =>
                  booking &&
                  booking.item.publications &&
                  booking.item.publications.some((p) => p.isPublished) ? (
                    booking.item.publications
                      .filter((p) => p.isPublished)
                      .map((p) => (
                        <AppUrlField
                          url={`${p.property.host}/annunci/${p.slug}`}
                          text={p.property.name}
                        />
                      ))
                  ) : (
                    <AppText children="Nessuna pubblicazione attiva su questo lotto" />
                  )
                }
              />
              <BooleanField
                source="alreadyExported"
                label="Già esportato"
                headerClassName={classes.lastCol}
              />
              <FunctionField
                label="Documenti"
                headerClassName={classes.booleanField}
                render={(booking) =>
                  booking &&
                  booking.documentsAttachedIds &&
                  booking.documentsAttachedIds.length > 0 && (
                    <>
                      <AppTooltip title="Clicca per visualizzare gli allegati" arrow>
                        <Button
                          startIcon={<InsertDriveFileIcon />}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => handleAttachmentsOpened(booking.documentsAttachedIds)}
                          style={{ lineHeight: 'initial', margin: '10px' }}
                        />
                      </AppTooltip>
                    </>
                  )
                }
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>

      <AppFormTab label="Richieste info" path="info-requests" maxWidth={false}>
        {INFO_REQUEST_ENABLED ? (
          <AppCard title="Richieste di informazioni" expanded>
            <AppRelatedResource relatedResource="/info-requests" actionsHidden>
              <InfoRequestDatagrid
                expand={<InfoRequestExpanded />}
                rowStyle={(record) => ({
                  backgroundColor: INFO_REQUEST_COLORS[record.requestStatus],
                })}
                additionalInfo={<InfoRequestLegend />}
                resource={props.resource}
              />
            </AppRelatedResource>
          </AppCard>
        ) : (
          <DeactivatedFeature
            title={'La funzionalità "Richieste info" attualmente non è attiva'}
            subtitle={"Contatta l'assistenza per attivarla"}
          />
        )}
      </AppFormTab>

      <AppFormTab label="Partecipazioni" path="participations" maxWidth={false}>
        <AppCard title="Partecipazioni" expanded>
          <AppRelatedResource relatedResource="/participations" actionsHidden>
            <AppDatagrid>
              <AppDateField
                source="createdAt"
                label="Data richiesta"
                headerClassName={classes.createdAt}
                showTime
              />
              <ReferenceField
                reference={IS_IVG ? 'sale-experiments-judiciary' : 'sale-experiments'}
                source="fkSaleExp"
                label="Esperimento di vendita"
                link={false}
                headerClassName={classes.saleExp}
              >
                <FunctionField
                  render={(saleExp) => (
                    <AppLinkToEntityField
                      mode="show"
                      targetResource="sale-experiments"
                      targetResourceId={saleExp?.id as number}
                      originName="pagamenti"
                      value={saleExp?.id as string}
                    />
                  )}
                />
              </ReferenceField>
              <FunctionField
                label="Giacenza"
                headerClassName={classes.booleanField}
                render={(participation) =>
                  participation &&
                  participation.metadata && <TextField label="Giacenza" source="metadata.variant" />
                }
              />
              <BooleanField
                source="depositPaid"
                label="Deposito pagato"
                headerClassName={classes.booleanField}
              />
              <BooleanField
                source="authorized"
                label="Autorizzato"
                headerClassName={classes.booleanField}
              />
              <BooleanField source="awarded" label="Premiato" headerClassName={classes.lastCol} />

              <FunctionField
                label="Documenti"
                headerClassName={classes.booleanField}
                render={(participation) =>
                  participation &&
                  participation.documentsAttachedIds &&
                  participation.documentsAttachedIds.length > 0 && (
                    <>
                      <AppTooltip title="Clicca per visualizzare gli allegati" arrow>
                        <Button
                          startIcon={<InsertDriveFileIcon />}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleAttachmentsOpened(participation.documentsAttachedIds)
                          }
                          style={{ lineHeight: 'initial', margin: '10px' }}
                        />
                      </AppTooltip>
                    </>
                  )
                }
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AttachmentsWindow
        attachmentIds={attachmentIds}
        isOpen={attachmentsWindowVisible}
        onClose={() => setAttachmentsWindowVisible(false)}
      />
    </AppTabbedForm>
  );
};

export default FrontEndUsersDetails;
