import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => {
  return {
    collapsed: {
      display: 'flex',
      width: theme.spacing(8),
      height: theme.spacing(8),
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(180deg)',
    },
  };
});

const AppCardHeaderToggleCollapse: FC<AppCardHeaderToggleCollapseProps> = (props) => {
  const classes = useStyles();
  const { expanded, onClick } = props;

  return (
    <IconButton
      className={clsx(classes.collapsed, {
        [classes.expanded]: expanded,
      })}
      onClick={onClick}
      aria-expanded={expanded}
      aria-label={expanded ? 'Nascondi dettagli' : 'Mostra dettagli'}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

export default AppCardHeaderToggleCollapse;

interface AppCardHeaderToggleCollapseProps {
  expanded: boolean;
  onClick: () => void;
}
