import React, { FC, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { AppBooleanInput } from '../../ui/input';

const PresenterIsDepositor: FC<any> = React.memo((props) => {
  const { values } = useFormState();
  const { change } = useForm();
  const notify = useNotify();

  const handleChange = (value) => {
    if (value === true) {
      // check if presenter minimum fields are inserted
      if (
        values.fkPresenter ||
        (values.presenter &&
          values.presenter.firstName &&
          values.presenter.lastName &&
          values.presenter.email &&
          values.presenter.taxCode)
      ) {
        change('depositor', values.presenter);
        notify('Il presentatore sarà salvato come depositante.', 'info');
      } else {
        notify('Non hai ancora compilato i dati necessari del presentatore.', 'info');
        change('presenterIsDepositor', false);
      }
    } else {
      // presenter is not depositor anymore
      change('depositor', {});
      notify('Il presentatore sarà rimosso dal ruolo di depositante dopo il salvataggio.', 'info');
    }
  };

  // Depositor info update if presenter changes and presenter is set as depositor too
  useEffect(() => {
    if (!values.presenterIsDepositor) return;

    change('depositor', values.presenter);
  }, [values.presenterId, values.presenter]);

  return (
    <AppBooleanInput
      source="presenterIsDepositor"
      label="Il presentatore è anche depositante"
      initialValue={false}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
});

export default PresenterIsDepositor;
