import { Button, Grid, makeStyles, Zoom } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import queryString from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useLocation } from 'react-router';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import { AppAutocompleteInput } from '../../Components/ui/input/AppAutocompleteInput';
import AppTooltip from '../../Components/ui/tooltip/AppTooltip';
import { useBasePath } from '../../hooks/use-base-path';
import { SaleMode, SaleStatus } from '../../utils/constants';
import { getReference } from '../../utils/reference-selector';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: -theme.spacing(1.5),
      marginLeft: theme.spacing(4),
    },
  }),
  { name: 'ItemSelection' },
);

// Handles item choice and (optionally) import of latest added sale exp ('create' mode)
const ItemSelection: FC<any> = React.memo(({ record, mode, resource }) => {
  const classes = useStyles();
  const notify = useNotify();

  const basePath = useBasePath();

  const form = useForm();
  const { values } = useFormState();

  // Get item id and folder id from query string (if exists)
  const location = useLocation();
  const { currentResourceId: itemId, folderId: folderIdFromItem } = queryString.parse(
    location.search,
  );

  const folderId = useMemo(
    () => (mode === 'create' ? folderIdFromItem : record?.relatedItem?.fkFolder),
    [record],
  );

  const [itemsChoices, setItemsChoices] = useState([]);
  const [mutateItems] = useMutation();
  const [mutateSaleExperiment] = useMutation();

  useEffect(() => {
    getItems(folderId, false);
  }, [folderId]);

  const getItems = (folderId, changed = true) => {
    if (!folderId) return;

    form.change('relatedItem.relatedFolder.id', +folderId);

    mutateItems(
      {
        type: 'getList',
        resource: `folders/${folderId}/items`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          if (mode === 'create') {
            setItemsChoices(
              res.data.filter((item) => {
                // take in opened or close and awarded with a107 items
                const currentSaleExp = item.relatedSaleExperiments.find(
                  (saleExp) => saleExp.isCurrent,
                );
                return (
                  // item.fkSaleStatus === SaleStatus.Opened ||
                  // !!(
                  //   currentSaleExp &&
                  //   currentSaleExp.a107 &&
                  //   item.fkSaleStatus === SaleStatus.ClosedAndAwarded
                  // )
                  item.fkSaleStatus === SaleStatus.Opened ||
                  (currentSaleExp && currentSaleExp.a107 && !currentSaleExp.a107Expired)
                );
              }),
            );
          } else {
            setItemsChoices(res.data);
          }
          if (changed) {
            form.mutators.clearInputs('fkItem');
          }
        },
        onFailure: (err) => {
          setItemsChoices([]);
          form.mutators.clearInputs('fkItem');
          notify(err.message, 'error');
        },
      },
    );
  };

  const importSaleExperiment = () =>
    mutateSaleExperiment(
      {
        type: 'getOne',
        resource: `${basePath}/import-latest-sale-experiment`,
        payload: {
          id: values.fkItem,
        },
      },
      {
        onSuccess: (res) => {
          form.initialize(res.data);
          notify("Dati dell'esperimento corrente caricati.", 'info');
        },
        onFailure: (err) => notify(`Errore: ${err.message}`, 'error'),
      },
    );

  return (
    <React.Fragment>
      <AppAutocompleteInput
        label={resource === 'sale-experiments-private' ? 'Pratica' : 'Fascicolo'}
        source="relatedItem.relatedFolder.id"
        reference={getReference(resource, 'folders')}
        optionText={(folder) => {
          if (!folder) return '';
          return `${folder.internalCode} (${folder.procedureCode ?? '-'})`;
        }}
        // filter={
        //   {
        //     _fulltextFields: ['procedureCode'],
        //   } as any
        // }
        resettable
        md={3}
        onChange={getItems}
        variant="outlined"
        disabled={mode !== 'create'}
      />
      <AppAutocompleteInput
        label="Lotto"
        source="fkItem"
        choices={itemsChoices}
        optionText="title"
        resettable
        md={5}
        required
        variant="outlined"
        defaultValue={itemId ? +itemId : undefined}
        disabled={mode !== 'create'}
      />
      {mode !== 'create' && (
        <Grid item md={2}>
          <AppItemLinksButton
            className={classes.button}
            // itemId={values.fkItem}
            // folderId={folderId}
            itemIdSource="fkItem"
            folderIdSource="relatedItem.relatedFolder.id"
            currentResourceLabel="Esperimento di vendita"
            resource={resource}
            record={values}
            historySaleId={record.isCurrent ? undefined : record.id}
          />
        </Grid>
      )}
      <Grid item md={2}>
        <AppTooltip
          title="Importa i dati dell'esperimento di vendita corrente"
          TransitionComponent={Zoom}
          arrow
        >
          <Button
            onClick={importSaleExperiment}
            variant="outlined"
            className={classes.button}
            disabled={
              mode !== 'create' ||
              !values.fkItem ||
              [SaleMode.OffersCollection, SaleMode.ExpressionOfInterest].includes(values.fkSaleMode)
            }
            startIcon={<SystemUpdateAltIcon />}
          >
            Importa
          </Button>
        </AppTooltip>
      </Grid>
    </React.Fragment>
  );
});

export default ItemSelection;
