/**
 * This component is required when we need to show custom data or calculate
 * something based on record
 */

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Record } from 'ra-core';
import React, { createElement, FC, ReactElement } from 'react';
import AppCardHeaderToggleCollapse from './app-card-header-toggle-collapse';

const useStyles = makeStyles(
  (theme) => {
    return {
      headerContainer: {
        backgroundColor: theme.palette.action.hover,
        display: 'flex',
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
          3,
        )}px`,
        '& h6': {
          display: 'flex',
          flexGrow: 1,
          alignSelf: 'center',
          marginTop: '0.35em',
        },
      },
      collapsableCard: {
        cursor: 'pointer',
      },
      cardExpanded: {
        borderBottom: `1px solid ${theme.palette.action.disabled}`,
      },
    };
  },
  { name: 'AppCardHeader' },
);

const AppCardHeader: FC<AppCardHeaderProps> = (props: AppCardHeaderProps) => {
  const {
    title,
    record,
    contentExpanded,
    contentCollapsable,
    component = <Typography variant='h6' color='textPrimary' gutterBottom />,
    onToggle: toggleHandler,
  } = props;

  const classes = useStyles();

  const titleValue = typeof title === 'function' ? title(record) : title;
  return (
    <div
      onClick={toggleHandler}
      className={clsx(classes.headerContainer, {
        [classes.cardExpanded]: contentExpanded,
        [classes.collapsableCard]: contentCollapsable,
      })}
    >
      {React.cloneElement(component, { children: titleValue })}
      {contentCollapsable &&
        createElement(AppCardHeaderToggleCollapse, {
          expanded: contentExpanded,
          onClick: toggleHandler,
        })}
    </div>
  );
};

export interface AppCardHeaderProps {
  title?: string | ((record?: Record) => string);
  component?: ReactElement;
  index?: number;
  record?: Record;
  contentExpanded: boolean;
  contentCollapsable: boolean;
  onToggle: () => void;
}

export default AppCardHeader;
