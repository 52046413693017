import { makeStyles } from '@material-ui/core';
import { BooleanField, FunctionField } from 'ra-ui-materialui';
import { FC } from 'react';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppDateField, AppLinkToEntityField, AppTextField } from '../../Components/ui/field';

const useStyles = makeStyles(
  () => ({
    id: { whiteSpace: 'nowrap' },
    trackingCode: { whiteSpace: 'nowrap' },
    date: { whiteSpace: 'nowrap' },
    flag: { whiteSpace: 'nowrap' },
    name: { whiteSpace: 'nowrap' },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ComplaintDatagrid' },
);

const PARTICIPATION_BASE_SOURCE = 'participation';
const SALE_EXPERIMENT_BASE_SOURCE = 'saleExperiment';

const ShippingDatagrid: FC<any> = (props) => {
  const { rowClick, ...rest } = props;
  const classes = useStyles(); // TODO

  return (
    <AppDatagrid {...rest} rowClick={rowClick}>
      <AppTextField source="id" label="Identificativo univoco" headerClassName={classes.id} />
      <AppDateField
        source="shippingDate"
        label="Data/ora spedizione"
        showTime
        headerClassName={classes.date}
      />
      <AppTextField
        source="trackingCode"
        label="Codice tracking"
        headerClassName={classes.trackingCode}
      />
      <BooleanField source="received" label="Consegnato" headerClassName={classes.flag} />
      <AppDateField
        source="receivedDate"
        label="Data/ora consegna"
        showTime
        headerClassName={classes.date}
      />
      <BooleanField source="notReceived" label="Non consegnato" headerClassName={classes.flag} />
      <AppDateField
        source="receivedDate"
        label="Data/ora mancata consegna"
        showTime
        headerClassName={classes.date}
      />
      <FunctionField
        label="Ricevente"
        render={(r) => {
          if (!r?.[PARTICIPATION_BASE_SOURCE]) return '-';

          return (
            <AppLinkToEntityField
              targetResource="participants"
              originName="Spedizioni"
              value={() =>
                `${r[PARTICIPATION_BASE_SOURCE].presenter.lastName} ${
                  r[PARTICIPATION_BASE_SOURCE].presenter.firstName
                } (${r[PARTICIPATION_BASE_SOURCE].presenter.taxCode ?? '-'})`
              }
              targetResourceId={() => r[PARTICIPATION_BASE_SOURCE].fkPresenter}
              className={classes.name}
            />
          );
        }}
      />
      <FunctionField
        label="Esperimento di vendita"
        render={(r) => {
          if (!r?.[SALE_EXPERIMENT_BASE_SOURCE]) return '-';

          return (
            <AppLinkToEntityField
              targetResource="sale-experiments-private"
              originName="Spedizioni"
              value={() => `${r[SALE_EXPERIMENT_BASE_SOURCE].id}`}
              targetResourceId={() => r[SALE_EXPERIMENT_BASE_SOURCE].id}
              className={classes.name}
            />
          );
        }}
        className={classes.name}
      />
      <AppEditButton />
    </AppDatagrid>
  );
};

export default ShippingDatagrid;
