import { PropertySchema } from '../Resources/Property/enums';
import { Publication } from '../Resources/Property/types';

export const getPublicationUrl = (publication: Publication, historySaleId?: number): string => {
  if (!publication || !publication.property) return '#';

  const baseUrl = `${publication.property.host}${publication.property.itemsPath}`;

  if (!publication.property.schema)
    return `${baseUrl}/${publication.slug}${historySaleId ? `?saleId=${historySaleId}` : ''}`;

  switch (publication.property.schema) {
    case PropertySchema.Immobiliare_it:
      return `${baseUrl}/${
        publication.externalData?.auctionId ?? publication.externalData?.propertyId
      }?anteprima=s`;
    case PropertySchema.Idealista_it:
      return `${baseUrl}/${publication.externalData?.propertyId}`;
    case PropertySchema.External:
      return `${baseUrl}/${publication.slug}`;
    default:
      return '#';
  }
};
