import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { DetailPanelField } from '../detail-panel';
import AppCard from '../ui/card/AppCard';

type JugaadExternalPropertyInfoProps = {
  itemId: number;
};
export const JugaadExternalPropertyInfo: FC<JugaadExternalPropertyInfoProps> = ({ itemId }) => {
  const [jugaadExternalPropertyInfo, setJugaadExternalPropertyInfo] = useState<Record<
    string,
    any
  > | null>(null);
  const [mutation] = useMutation();
  const getJugaadExtermalPropertyInfo = async () => {
    if (!itemId) return;

    mutation(
      {
        type: 'getOne',
        resource: `items/external/${itemId}/property-info`,
        payload: {},
      },
      {
        onSuccess: ({ data }) => setJugaadExternalPropertyInfo(data),
        onFailure: (err) => console.error('[getJugaadExtermalPropertyInfo]:', err),
      },
    );
  };
  useEffect(() => {
    getJugaadExtermalPropertyInfo();
  }, [itemId]);

  return jugaadExternalPropertyInfo ? (
    <AppCard title="Lotto esterno - Informazioni sul mittente">
      <DetailPanelField label="Nome" value={jugaadExternalPropertyInfo.name} md={3} />
      <DetailPanelField
        label="Sito"
        value={jugaadExternalPropertyInfo.url}
        url={jugaadExternalPropertyInfo.url}
        md={3}
      />
    </AppCard>
  ) : (
    <></>
  );
};
