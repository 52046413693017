import { makeStyles } from '@material-ui/core';
import React, { Children, cloneElement, FC, isValidElement } from 'react';

const useStyles = makeStyles(
  (theme) => {
    return {
      active: {
        display: 'block',
        padding: theme.spacing(1.5),
        overflow: 'auto',
        maxHeight: `calc(100vh - ${theme.spacing(1)}rem)`,
      },
      hidden: {
        display: 'none',
      },
    };
  },
  { name: 'AppDrawerFormTab' },
); //TODO: set style name where is missing!

const AppDrawerFormTab: FC<any> = (props) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={value === index ? classes.active : classes.hidden}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {Children.map(children, (child) => isValidElement(child) && cloneElement(child, other))}
    </div>
  );
};

export default AppDrawerFormTab;
