import { makeStyles } from '@material-ui/core/styles';
import { Dashboard } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DefaultIcon from '@material-ui/icons/ViewList';
import { TreeView } from '@material-ui/lab';
import classnames from 'classnames';
import inflection from 'inflection';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { getResources, ReduxState, Translate, useTranslate } from 'ra-core';
import React, { FC, useEffect, useRef, useState } from 'react';
import { usePermissions } from 'react-admin';
import { shallowEqual, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ResourceAction, userIsAllowed } from '../../../Providers/AppPermissionsProvider';

import { User } from '../../../types/user.type';
import AppMenuItem from './menu-item';

export const MENU_WIDTH = 240;
// export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    main: {
      //display: 'flex',
      //flexDirection: 'column',
      //justifyContent: 'flex-start',
      paddingTop: theme.spacing(1),
      height: '100%',
      minHeight: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      //[theme.breakpoints.only('xs')]: {
      //  marginTop: 0,
      //},
      //[theme.breakpoints.up('md')]: {
      //  marginTop: '1.5em',
      //},
    },
    open: {
      width: _get(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      //width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
  }),
  { name: 'AppMenu' },
);

const translatedResourceName = (resource: any, translate: Translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const AppMenu: FC<AppMenuProps> = React.memo<AppMenuProps>((props) => {
  const { className, hasDashboard, ...rest } = _omit(props, ['classes']);

  const translate = useTranslate();
  const classes = useStyles(props);
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;

  const location = useLocation();
  const activeResource = resources.find((r) =>
    matchPath(location.pathname, { path: `/${r.name}` }),
  );
  const activeResourceGroupRef = useRef<string | undefined>();
  const [activeResourceGroup, setActiveResourceGroup] = useState<string | undefined>(undefined);

  const { permissions: user } = usePermissions<User>();
  const [menuItemsState, setMenuItemsState] = useState<any[]>([]);
  useEffect(() => {
    const menuItems: any[] = [];
    resources
      .filter(
        (r) =>
          r.hasList &&
          userIsAllowed(ResourceAction.Get, r.name, user) &&
          ((!r.options.group?.adminOnly && !r.options?.adminOnly) || user?.admin),
      )
      .forEach((r) => {
        const [key, hasGroup, visibleByIvg] =
          r.options && r.options.group && r.options.group.id
            ? [r.options.group.id, true, r.options.group.visibleByIvg]
            : [r.name, false, true];
        const itmObj = menuItems.find((x) => x.key === key);
        if (!itmObj) {
          if (IS_IVG && !visibleByIvg) return; //* Workaround to hide menus items to IVG admin

          menuItems.push({ key, child: hasGroup ? [r] : r });
        } else {
          itmObj.child.push(r);
        }
      });

    setMenuItemsState(menuItems);
  }, [user]);

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  activeResourceGroupRef.current = activeResource?.options?.group?.id;
  useEffect(() => {
    setActiveResourceGroup(activeResourceGroupRef.current);
  }, [activeResourceGroupRef.current]);

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className,
      )}
      {...rest}
    >
      <TreeView
        //className={classes.root}
        expanded={[activeResourceGroup as string]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        selected={
          !activeResource
            ? 'root'
            : activeResourceGroup
            ? `${activeResourceGroup}.${activeResource.name}`
            : activeResource.name
        }
        onNodeToggle={(_, keys) => setActiveResourceGroup(keys[0])}
      >
        {hasDashboard && (
          <AppMenuItem
            nodeId="root"
            labelIcon={Dashboard}
            labelText="Dashboard"
            to="/"
            sidebarIsOpen={open}
          />
        )}

        {menuItemsState.map((itm) => {
          if (!Array.isArray(itm.child) && !itm.child.options.hidden) {
            return (
              <AppMenuItem
                nodeId={itm.key}
                labelIcon={itm.child?.icon || DefaultIcon}
                labelText={translatedResourceName(itm.child, translate)}
                sidebarIsOpen={open}
                to={`/${itm.key}`}
              />
            );
          }

          return (
            <AppMenuItem
              nodeId={itm.key}
              labelIcon={itm.child[0]?.options?.group?.icon || DefaultIcon}
              labelText={itm.child[0].options.group.label}
              sidebarIsOpen={open}
            >
              {itm.child
                .filter((c) => !c.options.hidden)
                .map((c) => {
                  return (
                    <AppMenuItem
                      nodeId={`${itm.key}.${c.name}`}
                      labelIcon={c.icon || DefaultIcon}
                      labelText={translatedResourceName(c, translate)}
                      sidebarIsOpen={open}
                      to={`/${c.name}`}
                    />
                  );
                })}
            </AppMenuItem>
          );
        })}
      </TreeView>
    </div>
  );
});

export interface AppMenuProps {
  classes?: any;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  //logout?: ReactNode;
  onMenuClick?: () => void;
}

AppMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  //logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

AppMenu.defaultProps = {
  onMenuClick: () => null,
};

export default AppMenu;
