import React, { FC, Fragment } from 'react';
import { ACTION_CHOICES } from '../../../../Resources/SaleExperiment/constants';
import { AdvertisementOutcomeType } from '../../../../Resources/SaleExperiment/enums';
import { AppSelectInput, AppTextInput } from '../../../ui/input';
import AppDivider from '../../../ui/layout/AppDivider';

export const AdvertisementOutcomeFormNotSold: FC<any> = ({ formProps, variant }) => {
  if (!formProps) return <Fragment />;

  const { values } = formProps;

  if (
    !values['outcomeType'] ||
    [AdvertisementOutcomeType.Sold, AdvertisementOutcomeType.PartiallySold].includes(
      values['outcomeType'],
    )
  )
    return <Fragment />;

  return (
    <>
      <AppDivider />
      <AppSelectInput
        source="auctionStatusReason"
        choices={ACTION_CHOICES[values['outcomeType']]}
        label="Motivazione"
        required
        variant={variant}
      />
      <AppTextInput
        source="auctionStatusReasonDetails"
        label="Dettagli"
        variant={variant}
        multiline
        rows={4}
      />
    </>
  );
};
