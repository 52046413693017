import { isArray, isNil } from 'lodash';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ApiProvider } from './ApiProvider';

export default class AppDataProvider implements DataProvider {
  provider: ApiProvider;

  constructor() {
    this.provider = new ApiProvider();
  }

  create<RecordType>(
    resource: string,
    params: CreateParams & { $file?: Record<string, any> | Record<string, any>[] },
  ): Promise<CreateResult<RecordType>> {
    const { data, $file } = params;

    if ($file) {
      return this.provider.upload(resource, $file);
    }

    if (data) {
      return this.provider
        .post(resource, null, data)
        .then((res) => ({ data: isArray(res.data) ? res.data[0] : res.data }));
    }

    return Promise.reject('No "data" or "files" provided.');
  }

  delete<RecordType>(resource: string, params: DeleteParams): Promise<DeleteResult<RecordType>> {
    const { id, previousData } = params;
    return this.provider.deleteOne(resource, id).then(() => ({ data: previousData as any }));
  }

  deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
    return this.provider
      .post(resource, undefined, { ids: params.ids })
      .then(() => ({ data: params.ids }));
  }

  async getList<RecordType>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> {
    const { pagination, sort, filter = {} } = params;

    //TODO a volte nel filtro compaiono i dati di ordinamento dalle autocomplete
    // con questo check lo intercetto e scateno un errore così da capire quando
    // accade e come sistemare la cosa (ovvero qual'è quello giusto)
    if (!isNil(filter.sortBy)) {
      if (!isNil(sort.field) && filter.sortBy !== sort.field) {
        throw new Error('Il filtro non coincide!'); //TODO cercare di capire quando accade
      }
      sort.field = filter.sortBy;
      delete filter.sortBy;
    }
    if (!isNil(filter.sortDirection)) {
      if (!isNil(sort.order) && filter.sortDirection !== sort.order) {
        throw new Error('Il filtro non coincide!'); //TODO cercare di capire quando accade
      }
      sort.order = filter.sortDirection;
      delete filter.sortDirection;
    }

    return this.provider.list<RecordType>(resource, {
      pagination,
      sort,
      filter,
    });
  }

  getMany<RecordType>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
    return this.provider.get(resource, params);
  }

  getManyReference<RecordType>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> {
    // TODO
    console.log(resource, params);
    throw new Error('NIY');
  }

  getOne<RecordType>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
    return this.provider.getOne(resource, params.id);
  }

  update<RecordType>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
    const { id, data /*, previousData*/ } = params;
    return this.provider.putOne<RecordType>(resource, id, data);
  }

  updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResult> {
    const { data, ids } = params;
    return this.provider.put(resource, { ids }, data);
  }
}
