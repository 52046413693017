import { blue, green, grey, yellow } from '@material-ui/core/colors';
import { FC, useEffect, useState } from 'react';
import { ListProps } from 'react-admin';
import RequestsListLegend from '../../Components/pvp/utilities/requests-list-legend';
import AppList from '../../Components/ui/detail/AppList';
import { AppDateInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { REQUEST_STATE_CHOICES } from './constants';
import { PUBLICATION_REQUEST_PERMISSIONS } from './index';
import PvpPublicationRequestDatagrid from './PvpPublicationRequestDatagrid';
import PvpPublicationRequestExpanded from './PvpPublicationRequestExpanded';

const PvpPublicationRequestFilters = (
  <AppListFilter
    helperText="numero inserzione pvp, titolo, numero procedura, numero/anno procedura, data di vendita"
    initialFilters={{
      createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24 * 60), // Default a 60 giorni fa
    }}
  >
    <AppSelectInput
      variant="outlined"
      source="requestState"
      choices={REQUEST_STATE_CHOICES}
      label="Stato richiesta"
      md={3}
    />
    <AppDateInput source="createdAt" label="Pubblicazioni ricevute dal" md={2} />
  </AppListFilter>
);

const PvpPublicationRequestList: FC<ListProps> = (props: ListProps) => {
  const [pvpOk, setPvpOk] = useState<boolean>(true);

  useEffect(() => {
    const apiSoapUrl = process.env.REACT_APP_API_SOAP_URL;
    fetch(`${apiSoapUrl}\\pvp`).then((res) => setPvpOk(res.status === 200));
  }, []);

  return (
    <>
      {!pvpOk && (
        <p
          style={{
            padding: '5px',
            backgroundColor: yellow[600],
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Si comunica che attualmente i sistemi ministeriali stanno riscontrando problemi tecnici e
          pertanto non si assicura la ricezione delle offerte telematiche e la lavorazione di nuove
          inserzioni
        </p>
      )}
      <AppList
        {...props}
        title={<AppTitle title="Richieste di pubblicazione dal PVP" />}
        filters={PvpPublicationRequestFilters}
        filterDefaultValues={{
          createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24 * 60), // Default a 60 giorni fa
        }}
        resourcePermissions={PUBLICATION_REQUEST_PERMISSIONS}
        hideCreateButton
        sort={{
          field: '"pvpPubReq"."requestData"->\'dataPubblicazione\'',
          order: 'DESC',
        }}
      >
        <PvpPublicationRequestDatagrid
          rowClick="expand"
          expand={<PvpPublicationRequestExpanded />}
          rowStyle={(record) => ({
            backgroundColor: record.saleReportSent
              ? grey[400]
              : record.publicationNotified
              ? green[300]
              : record.processed
              ? blue[300]
              : yellow[600],
          })}
          additionalInfo={<RequestsListLegend />}
        />
      </AppList>
    </>
  );
};

export default PvpPublicationRequestList;
