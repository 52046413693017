import { Badge, BadgeProps, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Email, People } from '@material-ui/icons';
import { FC, useContext } from 'react';
import { AppChatWidgetData } from './chat-widget-types';
import { ChatWidgetContext } from './chat-widget.provider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useChatBadgeStyles = makeStyles((theme) => {
  const getBgColor = ({ bgColor = 'primary' }) =>
    theme.palette[bgColor] ? theme.palette[bgColor].main : bgColor;
  const getColor = ({ color, bgColor = 'primary' }) => {
    return color && theme.palette.text[color]
      ? theme.palette.text[color]
      : theme.palette[bgColor].contrastText;
  };
  return {
    badge: {
      backgroundColor: getBgColor,
      color: getColor,
    },
  };
});
const ChatBadge: FC<Omit<BadgeProps, 'color'> & { color?: string; bgColor?: string }> = (props) => {
  const { color, bgColor, ...others } = props;
  const classes = useChatBadgeStyles({ color, bgColor });
  return <Badge {...others} classes={classes} />;
};

const ChatWidgetMinimized: FC<any> = ({ maximize }) => {
  const onOpenHandler = (event) => {
    event.stopPropagation();
    maximize();
  };

  const { directMessagesUnread, globalMessagesUnread, onlineStatuses } =
    useContext<AppChatWidgetData>(ChatWidgetContext);

  const directMessagesUnreadCount = Object.values(directMessagesUnread).reduce((a, c) => a + c, 0);
  // const globalUnreadMessagesCount = globalUnreadMessages['all'] ?? 0;
  const onlineUsersCount = Object.values(onlineStatuses).filter((v) => v).length;

  return (
    <Grid container spacing={2} style={{ marginTop: 'auto', justifyContent: 'space-evenly' }}>
      <Grid item>
        <ChatBadge
          badgeContent={'' + onlineUsersCount}
          max={99}
          bgColor={onlineUsersCount === 0 ? 'warning' : 'primary'}
          title="Utenti online."
        >
          <People />
        </ChatBadge>
      </Grid>
      <Grid item>
        <ChatBadge
          badgeContent={'' + (directMessagesUnreadCount + globalMessagesUnread)}
          max={99}
          bgColor={directMessagesUnreadCount + globalMessagesUnread === 0 ? 'success' : 'error'}
          title="Messaggi da leggere."
        >
          <Email />
        </ChatBadge>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onOpenHandler} variant="contained" color="primary" fullWidth>
          APRI
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChatWidgetMinimized;
