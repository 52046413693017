import { Avatar, Card, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TitleComponent, useCheckAuth } from 'ra-core';
import React, {
  FC,
  ComponentType,
  createElement,
  HtmlHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';

import AppTheme from '../../../Providers/ThemeProvider';
import AppNotification from '../../../Components/ui/layout/AppNotification';
import AppLoginForm from './AppLoginForm';

export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: Record<string, any>;
  className?: string;
  notification?: ComponentType;
  staticContext?: StaticContext;
  theme?: Record<string, any>;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { name: 'AppLogin' },
);

const AppLogin: FC<LoginProps> = (props) => {
  const {
    // theme,
    // title,
    // classes: classesOverride,
    className,
    children,
    notification,
    // staticContext,
    backgroundImage,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles(props);

  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();

  useEffect(() => {
    if (!(navigator.userAgent.indexOf('Chrome') != -1)) {
      alert('Browser non supportato! Si consiglia di usare Chrome.');
    }
  }, []);

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={AppTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          {children}
        </Card>
        {notification ? createElement(notification) : null}
      </div>
    </ThemeProvider>
  );
};

AppLogin.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

AppLogin.defaultProps = {
  theme: AppTheme,
  children: <AppLoginForm />,
  notification: AppNotification,
};

export default AppLogin;
