import { useEffect, useState } from 'react';
import { useMutation } from 'react-admin';

// TODO: improve by passing specific columns to get from api (e.g. websiteCustomization only, companyConfig only, ...)
export const useCmsConfiguration = (): Record<string, any> | null => {
  const [config, setConfig] = useState<Record<string, any> | null>(null);

  const [mutateConfig] = useMutation();
  useEffect(() => {
    mutateConfig(
      {
        type: 'getOne',
        resource: 'configurations/generic-for-admin',
        payload: {},
      },
      {
        onSuccess: (res) => setConfig(res.data),
        onFailure: (err) => {
          setConfig(null);
          console.error('[useCmsConfiguration] Failed loading cms configuration:', err);
        },
      },
    );
  }, []);

  return config;
};
