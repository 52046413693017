import { MenuBookTwoTone } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import DecreeDetails from './DecreeDetails';
import DecreeList from './DecreeList';

export const DECREE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Decree: ResourceExport = {
  list: DecreeList,
  create: resourceWrapper('create', DecreeDetails, undefined, undefined, DECREE_PERMISSIONS),
  edit: resourceWrapper('edit', DecreeDetails, undefined, undefined, DECREE_PERMISSIONS),
  show: resourceWrapper('show', DecreeDetails, undefined, undefined, DECREE_PERMISSIONS),
  icon: MenuBookTwoTone,
};

export default Decree;
