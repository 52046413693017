import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FormDataConsumer } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppColorInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import { AppFileInput } from '../../Components/ui/input/AppFileInput';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { ITEM_GENRES, MAGAZINE_KINDS } from '../../utils/constants';
import { blobToBase64 } from '../../utils/files';
import { MAGAZINE_INDEX_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    fileInput: {
      marginLeft: theme.spacing(5),
    },
    fileInputPreview: {
      maxWidth: theme.spacing(30),
    },
  }),
  { name: 'MagazineIndexDetails' },
);

const MagazineIndexDetails: FC<any> = (props) => {
  const classes = useStyles();

  const handleFileAndSave = async (record: any, redirect: any, options: any) => {
    if (record.indexFile)
      await blobToBase64(record.indexFile.indexFile).then((result) => {
        record.indexFile = {
          base64: result,
          filename: record.indexFile.filename,
          type: record.indexFile.rawFile.type,
        };
      });

    // Send to API server
    props.save(record, redirect, options);
  };

  return (
    <AppBaseForm
      {...props}
      save={(record, redirect, options) => handleFileAndSave(record, redirect, options)}
      disabledCards={
        !hasViewPermissions(props.permissions?.loginRole, 'create', MAGAZINE_INDEX_PERMISSIONS) ||
        props.mode === 'show'
      }
    >
      <AppCard collapsable={false} title="Impostazioni indice">
        <AppTextInput source="name" label="Nome indice" required md={4} />
        <AppSelectInput
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppNumberInput
          required
          source="textX"
          label="Posizione testo: X"
          md={3}
          defaultValue={props.mode === 'create' ? 182 : undefined}
        />
        <AppNumberInput
          required
          source="textWidth"
          label="Larghezza testo"
          md={3}
          defaultValue={props.mode === 'create' ? 12 : undefined}
        />
        <AppNumberInput
          required
          source="textFontSize"
          label="Dimensione testo"
          md={3}
          defaultValue={props.mode === 'create' ? 20 : undefined}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'real-estate' && (
              <>
                <AppNumberInput
                  {...rest}
                  required
                  source="residentialY"
                  label="Residenziale: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 74 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="industrialY"
                  label="Industriale: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 86 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="commercialY"
                  label="Commerciale: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 98 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="otherRealEstateY"
                  label="Altro: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 110 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="anpeY"
                  label="ANPE: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 156.5 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate1Y"
                  label="Partecipazione 1: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 186 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate1Delta"
                  label="Partecipazione 1: Delta"
                  md={3}
                  defaultValue={props.mode === 'create' ? 1 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate2Y"
                  label="Partecipazione 2: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 205 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate2Delta"
                  label="Partecipazione 2: Delta"
                  md={3}
                  defaultValue={props.mode === 'create' ? 1 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate3Y"
                  label="Partecipazione 3: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 216.5 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationRealEstate3Delta"
                  label="Partecipazione 3: Delta"
                  md={3}
                  defaultValue={props.mode === 'create' ? 3 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="notesY"
                  label="Note: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 246.5 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="notesDelta"
                  label="Note: Delta"
                  md={3}
                  defaultValue={props.mode === 'create' ? 4 : undefined}
                />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'movable' && (
              <>
                <AppNumberInput
                  {...rest}
                  required
                  source="carsY"
                  label="Automobili: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 121 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="shipsY"
                  label="Barche: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 133.4 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="machineriesY"
                  label="Macchinari: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 145.8 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="informaticsY"
                  label="Informatica: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 158.2 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="furnitureY"
                  label="Mabili: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 170.6 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="artY"
                  label="Arte: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 183 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="clothingY"
                  label="Vestiti: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 195.4 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="otherMovableY"
                  label="Altro: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 207.8 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationY"
                  label="Partecipazione: Y"
                  md={3}
                  defaultValue={props.mode === 'create' ? 232.2 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="participationDelta"
                  label="Partecipazione: Delta"
                  md={3}
                  defaultValue={props.mode === 'create' ? 2 : undefined}
                />
              </>
            )
          }
        </FormDataConsumer>
        <AppDivider />
        <AppColorInput source="textColor" label="Colore testo" md={3} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'real-estate' && (
              <>
                <AppColorInput
                  {...rest}
                  source="residentialColor"
                  label="Colore residenziali"
                  md={3}
                />
                <AppColorInput
                  {...rest}
                  source="industrialColor"
                  label="Colore industriali"
                  md={3}
                />
                <AppColorInput
                  {...rest}
                  source="commercialColor"
                  label="Colore commerciali"
                  md={3}
                />
                <AppColorInput
                  {...rest}
                  source="otherRealEstateColor"
                  label="Colore altro"
                  md={3}
                />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'movable' && (
              <>
                <AppColorInput {...rest} source="carsColor" label="Colore automobili" md={3} />
                <AppColorInput {...rest} source="shipsColor" label="Colore barche" md={3} />
                <AppColorInput
                  {...rest}
                  source="machineriesColor"
                  label="Colore macchinari"
                  md={3}
                />
                <AppColorInput
                  {...rest}
                  source="informaticsColor"
                  label="Colore informatica"
                  md={3}
                />
                <AppColorInput {...rest} source="furnitureColor" label="Colore mobili" md={3} />
                <AppColorInput {...rest} source="artColor" label="Colore arte" md={3} />
                <AppColorInput
                  {...rest}
                  source="clothingColor"
                  label="Colore abbigliamento"
                  md={3}
                />
                <AppColorInput {...rest} source="otherMovableColor" label="Colore altro" md={3} />
              </>
            )
          }
        </FormDataConsumer>
        <AppFileInput
          md={12}
          source="indexFile"
          accept="image/jpeg,image/png"
          label="Indice"
          className={classes.fileInput}
          previewLabel="Indice corrente"
          previewSource="metadata"
          previewType="base64"
          previewClassName={classes.fileInputPreview}
        />
      </AppCard>
    </AppBaseForm>
  );
};

export default MagazineIndexDetails;
