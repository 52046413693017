import { Grid } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { AppImageInput } from '../../../Components/ui/input/AppImageInput';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import { LINK_TYPES_CHOICES } from '../../Booking/constants';
import PageSelection from '../Pages/PageSelection';

const FooterSlideDetails = (props: Record<any, any>): ReactElement => {
  const [urlType, setUrlType] = useState<string>(props.record.urlType ?? '');

  return (
    <AppBaseForm {...props}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppSelectInput
          source="urlType"
          label="Tipo"
          md={6}
          required
          choices={LINK_TYPES_CHOICES}
          onChange={(e) => setUrlType(e.target.value)}
          defaultValue={props.record.urlType}
          disabled={props.mode === 'show'}
        />
        {urlType === LINK_TYPES_CHOICES[0].id && (
          <PageSelection mode={props.mode} source="url" label="Pagina" />
        )}
        {urlType === LINK_TYPES_CHOICES[1].id && (
          <AppTextInput source="url" label="URL" md={6} disabled={props.mode === 'show'} />
        )}
        <AppBooleanInput
          source="enabled"
          label="Abilitato"
          md={3}
          required
          initialValue={true}
          disabled={props.mode === 'show'}
        />
        <AppNumberInput
          source="order"
          label="Ordine"
          md={1}
          defaultValue={0}
          disabled={props.mode === 'show'}
        />
        <AppImageInput
          source="imageFile"
          accept="image/png,image/jpeg"
          label="Immagine"
          previewSource="image"
          previewLabel="Immagine corrente"
          isRequired
        />
        {props.mode === 'show' ? (
          <Grid item md={12}>
            <AppSectionTitle>SITI</AppSectionTitle>
            <ReferenceArrayField reference="properties" source="propertyIds" label="Siti">
              <SingleFieldList>
                <ChipField source="name" style={{ marginBottom: '15px' }} />
              </SingleFieldList>
            </ReferenceArrayField>
          </Grid>
        ) : (
          <AppAutocompleteInput
            source="propertyIds"
            reference="properties"
            label="Siti"
            resettable
            variant="outlined"
            isArray
            md={12}
            filter={{
              schema: null,
            }}
          />
        )}
      </AppCard>
    </AppBaseForm>
  );
};

export default FooterSlideDetails;
