import { makeStyles } from '@material-ui/core';
import React, { cloneElement, FC, ReactElement } from 'react';
import { Datagrid, useListContext } from 'react-admin';

const useStyles = makeStyles(
  () => ({
    table: {
      display: 'block',
      overflow: 'auto',
      //maxHeight: 'calc(100vh - 17rem)',
    },
    rowCell: {},
    headerRow: {
      height: '3rem',
      backgroundColor: '#f6f6f6', //rgba(0,0,0,0.04) palette.action.hover (problems with transparency)
    },
  }),
  { name: 'AppDataGrid' },
);

const AppDatagrid: FC<any> = (props): ReactElement => {
  const classes = useStyles(props);
  const listCtx = useListContext();
  const { additionalInfo } = props;
  return (
    <React.Fragment>
      {additionalInfo && <div>{cloneElement(additionalInfo, { listCtx })}</div>}
      <Datagrid {...props} classes={classes} />
    </React.Fragment>
  );
};

export default AppDatagrid;

/*
TODO: Dynamic table max-height

import React, { FC, ReactElement, useState } from 'react';
import {Datagrid} from 'react-admin';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(
  {
    table: (props: Record<string, any>) => ({
      display: 'block',
      overflow: 'auto',
      maxHeight: `calc(100vh - ${props.positionY/16 ?? '17'}rem)`, // 1rem = 16px
    }),
    rowCell: {},
    headerRow: {
      height: '3rem',
    },
  },
  { name: 'AppDataGrid' }
);

const AppDatagrid: FC<any> = (props): ReactElement => {
  const [positionY, setPositionY] = useState(272);
  const classes = useStyles({ positionY });

  const positionHandler = (newPositionY) => {
    if(newPositionY !== positionY){
      console.log(0);
      setPositionY(newPositionY);
    }
  }

  return <Datagrid {...props} classes={classes} ref={el => el && positionHandler(el.getBoundingClientRect().top)} />;
};

export default AppDatagrid;

 */
