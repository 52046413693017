import PeopleIcon from '@material-ui/icons/People';
import { createElement } from 'react';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import PersonDetailActions from './PersonDetailActions';
import PersonDetails from './PersonDetails';
import PersonList from './PersonList';

export const PERSON_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Person: ResourceExport = {
  list: PersonList,
  create: resourceWrapper('create', PersonDetails, undefined, undefined, PERSON_PERMISSIONS),
  edit: resourceWrapper(
    'edit',
    PersonDetails,
    undefined,
    createElement(PersonDetailActions),
    PERSON_PERMISSIONS,
  ),
  show: resourceWrapper('show', PersonDetails, undefined, undefined, PERSON_PERMISSIONS),
  icon: PeopleIcon,
  options: { permissions: PERSON_PERMISSIONS },
};

export default Person;
