import { Box, Button, ButtonGroup, Grid, makeStyles, Typography } from '@material-ui/core';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { AppAutocompleteInput, AppInputFiller } from '../../Components/ui/input';
import {
  AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BACKGROUND_COLOR,
  AUCTION_ROOM_BOOKING_CONFIRMED_BACKGROUND_COLOR,
  AUCTION_ROOM_BOOKING_SENT_BACKGROUND_COLOR,
} from '../../utils/constants';

const useLegendStyles = makeStyles(
  (theme) => {
    return {
      container: {
        textAlign: 'center',
        maxWidth: '24rem',
        minWidth: '24rem',
      },
      element: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        marginRight: theme.spacing(2),
      },
    };
  },
  { name: 'AuctionRoomBookingCalendarLegend' },
);

const useToolbarStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      buttons: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        maxHeight: theme.spacing(5),
      },
    };
  },
  { name: 'AuctionRoomBookingCalendarToolbar' },
);

const AuctionRoomBookingCalendarToolbar: FC<any> = (props) => {
  const navigate = (action) => props.onNavigate(action);
  const view = (action) => props.onView(action);
  const { calendarFilters, setCalendarFilters, isAuctioneer } = props;

  const legendClasses = useLegendStyles();
  const toolbarClasses = useToolbarStyles();

  return (
    <div className={toolbarClasses.root}>
      <Form
        onSubmit={() => undefined} //no submit needed
        render={() => {
          return (
            <Grid container>
              <ButtonGroup
                className={toolbarClasses.buttons}
                disableElevation
                variant="contained"
                color="primary"
              >
                <Button onClick={() => navigate('PREV')}>
                  <KeyboardArrowLeftIcon />
                </Button>
                <Button>{props.label.toUpperCase()}</Button>
                <Button onClick={() => navigate('NEXT')}>
                  <KeyboardArrowRightIcon />
                </Button>
              </ButtonGroup>
              <ButtonGroup
                className={toolbarClasses.buttons}
                disableElevation
                variant="outlined"
                color="primary"
              >
                {!isAuctioneer && (
                  <Button
                    onClick={() => view('month')}
                    variant={props.view === 'month' ? 'contained' : 'outlined'}
                  >
                    MONTH
                  </Button>
                )}
                <Button
                  onClick={() => view('week')}
                  variant={props.view === 'week' ? 'contained' : 'outlined'}
                >
                  WEEK
                </Button>
                <Button
                  onClick={() => view('day')}
                  variant={props.view === 'day' ? 'contained' : 'outlined'}
                >
                  DAY
                </Button>
              </ButtonGroup>
              <AppInputFiller md={12} />
              <AppAutocompleteInput
                resettable={!isAuctioneer}
                reference="auction-rooms"
                optionText="title"
                source="auctionRoomId"
                label="Sala d'asta"
                variant="outlined"
                helperText={false}
                suggestionLimit={5}
                md={4}
                filter={
                  {
                    _fulltextFields: ['title', 'description'],
                  } as any
                }
                onChange={(auctionRoomId) =>
                  setCalendarFilters((prevFilters) => ({
                    ...prevFilters,
                    auctionRoom: auctionRoomId,
                  }))
                }
                defaultValue={calendarFilters.auctionRoom}
              />
              {!isAuctioneer && (
                <AppAutocompleteInput
                  resettable={true}
                  reference="people"
                  optionText={(selectedPerson) => {
                    if (!selectedPerson) return '';
                    return `${selectedPerson.firstName} ${selectedPerson.lastName}`;
                  }}
                  source="personId"
                  label="Persona"
                  variant="outlined"
                  helperText={false}
                  suggestionLimit={5}
                  md={4}
                  filter={
                    {
                      _fulltextFields: ['firstName', 'lastName'],
                    } as any
                  }
                  onChange={(personId) =>
                    setCalendarFilters((prevFilters) => ({
                      ...prevFilters,
                      person: personId,
                    }))
                  }
                  defaultValue={calendarFilters.person}
                />
              )}
            </Grid>
          );
        }}
      />
      <div className={legendClasses.container}>
        <Typography variant="h6">
          <Box fontWeight="bold">Legenda</Box>
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: AUCTION_ROOM_BOOKING_SENT_BACKGROUND_COLOR }} />
          Inviata
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: AUCTION_ROOM_BOOKING_CONFIRMED_BACKGROUND_COLOR }} />
          Confermata
        </Typography>
        {calendarFilters.auctionRoom && (
          <>
            <br />
            <Typography className={legendClasses.element}>
              <LegendIcon
                style={{ color: AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BACKGROUND_COLOR }}
              />
              Orario di apertura della sala d'asta
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default AuctionRoomBookingCalendarToolbar;
