import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { PaymentMethod } from './constants';

export const PaymentExpand: FC<any> = ({ record }) => {
  const { participation } = record;
  return (
    <Grid container>
      <DetailPanelTitle>Dettagli pagamento</DetailPanelTitle>
      <DetailPanelField
        label="Importo"
        value={amountFormatter(record.amount)}
        md={record.feesAmount ? 2 : 12}
      />
      {!!record.feesAmount && (
        <DetailPanelField
          label="Commissioni di servizio"
          value={amountFormatter(record.feesAmount)}
          md={10}
        />
      )}
      {record.paymentMethod === PaymentMethod.WireTransfer && (
        <>
          <DetailPanelField label="CRO" value={record.cro} md={2} />
          <DetailPanelField label="Data CRO" value={dateFormatter(record.croDate, true)} md={2} />
          <DetailPanelField label="Iban restituzione cauzione" value={record.returnIban} md={2} />
          <DetailPanelField label="Iban beneficiario" value={record.beneficiaryIban} md={2} />
        </>
      )}
      {record.paymentMethod === PaymentMethod.Check && (
        <>
          <DetailPanelField label="Numero assegno" value={record.checkNumber} md={2} />
        </>
      )}
      {record.paymentMethod === PaymentMethod.CreditCard && (
        <>
          <DetailPanelField label="Tipo carta di credito" value={record.creditCardType} md={2} />
          <DetailPanelField
            label="Intestatario carta"
            value={record.creditCardOwner?.name}
            md={2}
          />
          <DetailPanelField
            label="Email intestatario"
            value={record.creditCardOwner?.email}
            md={2}
          />
          <DetailPanelField
            label="ID transazione"
            value={record.transactionId ?? record.axervePaymentId ?? '-'}
            md={2}
          />
        </>
      )}
      <DetailPanelField label="Termine saldo" value={record.balanceDueDate} md={2} />
      <DetailPanelField label="Note" value={record.notes} md={12} />
      <DetailPanelTitle>Dettagli anagrafica</DetailPanelTitle>
      <DetailPanelField
        label="Nominativo"
        value={`${participation.presenter.lastName} ${participation.presenter.firstName}`}
        md={2}
      />
      <DetailPanelField label="Codice fiscale" value={participation.presenter.taxCode} md={2} />
      <DetailPanelField
        label="Data di nascita"
        value={dateFormatter(participation.presenter.birthday, true)}
        md={2}
      />
    </Grid>
  );
};
