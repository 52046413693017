import React, { ReactElement } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppNumberInput, AppTextInput } from '../../Components/ui/input';

const CourtDetails = (props: Record<string, any>): ReactElement => {
  return (
    <AppBaseForm {...props}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppTextInput source="name" label="Nome" disabled md={5} />
        <AppTextInput source="code" label="Codice" disabled md={5} />
        <AppNumberInput source="order" label="Ordine" md={2} />
      </AppCard>
    </AppBaseForm>
  );
};

export default CourtDetails;
