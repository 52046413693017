import React, { FC, Fragment } from 'react';
import { PvpOfferField } from './pvp-offer-field';

export enum PvpOfferLocationType {
  Residence = 'Residenza',
  Domicile = 'Domicilio',
}

interface PvpOfferLocation {
  location: Record<string, any>;
  type: PvpOfferLocationType;
}

export const PvpOfferLocation: FC<PvpOfferLocation> = ({ location, type }) => {
  return (
    <Fragment>
      <PvpOfferField
        label={type === PvpOfferLocationType.Residence ? 'Residente a' : 'Domicilio a'}
        customValue={`${location[`comune${type}`]} (${location[`provincia${type}`]}, ${
          location[`regione${type}`]
        }, ${location[`nazione${type}`]})`}
        md={6}
      />
      <PvpOfferField
        label="Via/viale/piazza"
        customValue={`${location[`indirizzo${type}`]} ${location[`civico${type}`]} (${
          location[`capZipCode${type}`]
        })`}
        md={6}
      />
    </Fragment>
  );
};
