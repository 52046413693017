import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ChipField, ListProps, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppEditButton from '../../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../../Components/ui/detail/AppList';
import { AppImageField } from '../../../Components/ui/field/AppImageField';
import AppTextField from '../../../Components/ui/field/AppTextField';

import AppTitle from '../../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    image: {
      minWidth: theme.spacing(30),
    },
    url: {
      minWidth: theme.spacing(30),
    },
    order: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'FooterSlideList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const FooterSlideList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList {...props} title={<AppTitle title="Footer" />}>
      <AppDatagrid resource="footer-slides" rowClick="show">
        <AppImageField source="image" label="Immagine" headerClassName={classes.image} />
        <AppTextField source="url" label="URL" headerClassName={classes.url} />
        <AppTextField source="order" label="Ordine" headerClassName={classes.order} />
        {!IS_IVG && (
          <ReferenceArrayField
            reference="properties"
            source="propertyIds"
            label="Siti"
            headerClassName={classes.lastCol}
          >
            <SingleFieldList>
              <ChipField source="name" style={{ marginBottom: '15px' }} />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default FooterSlideList;
