import MessageIcon from '@material-ui/icons/Message';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import MessageDetails from './MessageDetails';
import MessageList from './MessageList';

export const MESSAGES_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Messages: ResourceExport = {
  list: MessageList,
  create: resourceWrapper('create', MessageDetails, undefined, undefined, MESSAGES_PERMISSIONS),
  edit: resourceWrapper('edit', MessageDetails, undefined, undefined, MESSAGES_PERMISSIONS),
  show: resourceWrapper('show', MessageDetails, undefined, undefined, MESSAGES_PERMISSIONS),
  icon: MessageIcon,
};
export default Messages;
