import { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput';
import React, { FC, ReactElement } from 'react';
import { DateTimeInput, Validator } from 'react-admin';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

export const AppDateTimeInput: FC<AppDateTimeInputProps> = (
  props: AppDateTimeInputProps,
): ReactElement => {
  const { required, validate: customValidators, ...rest } = props;
  return gridWrappedInput(
    { ...rest },
    <DateTimeInput
      validate={getValidators(customValidators as Validator[], required)}
      {...inputProps({ ...rest })}
    />,
  );
};

export type AppDateTimeInputProps = TextInputProps & AppGridProps;
