import { Grid, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { FormDataConsumer } from 'react-admin';
import { RelatedPublicationsList } from '../../Components/publication-proof/related-publications-list';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppCard from '../../Components/ui/card/AppCard';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppBooleanInput,
  AppDateInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { ItemGenre } from '../../utils/constants';
import { minDate } from '../../utils/validators';
import { GenerationStatus } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    booleanInput: {
      marginLeft: theme.spacing(1),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'PublicationProofDetails' },
);

const PublicationProofDetails: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <AppBaseForm {...props} disabledCards={props.mode !== 'create'}>
      <AppCard collapsable={false} title="Impostazioni generazione giustificativo">
        <AppTextInput source="title" label="Titolo" md={6} />
        {props.mode !== 'create' && props.record?.generationStatus === GenerationStatus.Done && (
          <AppTextInput source="fileName" label="Nome file" md={6} />
        )}
        <AppDivider />
        <AppDateInput source="saleStartFrom" label="Inizio vendita dal" md={3} />
        <AppDateInput
          source="saleStartTo"
          label="Inizio vendita al"
          md={3}
          validate={[minDate('saleStartFrom', 'Inizio vendita dal')]}
        />
        <AppDateInput source="saleEndFrom" label="Fine vendita dal" md={3} />
        <AppDateInput
          source="saleEndTo"
          label="Fine vendita al"
          md={3}
          validate={[minDate('saleEndFrom', 'Fine vendita dal')]}
        />
        <AppSelectInput
          source="genreId"
          choices={[
            { id: ItemGenre.RealEstate, name: 'IMMOBILI' },
            { id: ItemGenre.Movable, name: 'MOBILI' },
          ]}
          label="Genere"
          md={2}
        />
        <AppDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <AppBooleanInput
                {...rest}
                source="expressionOfInterestOnly"
                label="Includi SOLO Manifestazioni di interesse"
                className={classes.booleanInput}
              />
              {!formData.expressionOfInterestOnly && (
                <AppBooleanInput
                  {...rest}
                  source="includeExpressionOfInterest"
                  label="Includi ANCHE manifestazioni di interesse"
                  className={classes.booleanInput}
                />
              )}
            </>
          )}
        </FormDataConsumer>
        {props.mode !== 'create' &&
          props.record?.generationStatus === GenerationStatus.Done && [
            <AppDivider />,
            <Grid item md={12}>
              <AppDownloadFromGCPBucketField record={props.record} source="publicUrl" />
            </Grid>,
          ]}
      </AppCard>
      <AppCard
        title="Lista pubblicazioni incluse"
        hideContent={props.mode === 'create' || !props.record?.publicationsList}
        expanded={props.mode !== 'create'}
      >
        {props.record?.generationStatus === GenerationStatus.Done ? (
          <RelatedPublicationsList publicationsList={props.record.publicationsList} />
        ) : (
          <AppAlert
            severity="warning"
            children="Il giustificativo non è ancora stato generato."
            className={classes.alert}
          />
        )}
      </AppCard>
    </AppBaseForm>
  );
};

export default PublicationProofDetails;
