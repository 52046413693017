import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import {
  ACTION_CHOICES,
  ADVERTISEMENT_OUTCOME_TYPES,
} from '../../../../Resources/SaleExperiment/constants';
import { AdvertisementOutcomeType } from '../../../../Resources/SaleExperiment/enums';
import { CompletedAdvertisementInfo } from '../../../../Resources/SaleExperiment/types';
import { DetailPanelField, DetailPanelTitle } from '../../../detail-panel';
import AppDivider from '../../../ui/layout/AppDivider';

const useStyles = makeStyles(
  (theme) => ({
    updateAfterPostponedButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'AdvertisementAuctionCompletedNotSold' },
);

interface AdvertisementAuctionCompletedNotSoldProps {
  completedAuctionInfo: CompletedAdvertisementInfo;
  setCompletedAuctionInfo: React.Dispatch<
    React.SetStateAction<CompletedAdvertisementInfo | undefined>
  >;
}

export const AdvertisementAuctionCompletedNotSold: FC<
  AdvertisementAuctionCompletedNotSoldProps
> = ({ completedAuctionInfo, setCompletedAuctionInfo }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <DetailPanelTitle
        variant="h5"
        children={
          ADVERTISEMENT_OUTCOME_TYPES.find((type) => type.id === completedAuctionInfo.outcomeType)
            ?.name
        }
      />
      {completedAuctionInfo.outcomeType !== AdvertisementOutcomeType.PartiallySold && (
        <>
          <AppDivider />
          <DetailPanelField
            label="Motivazione"
            value={
              ACTION_CHOICES[completedAuctionInfo.outcomeType]?.find(
                (choice) => choice.id === completedAuctionInfo.auctionStatusReason,
              )?.name
            }
            md={3}
          />
          {!!completedAuctionInfo.auctionStatusReasonDetails && (
            <DetailPanelField
              label="Dettagli"
              value={completedAuctionInfo.auctionStatusReasonDetails}
              md={9}
            />
          )}
          {completedAuctionInfo.outcomeType === AdvertisementOutcomeType.Postponed && (
            <Grid container justify="flex-end">
              <AppDivider />
              <Grid item md={2} className={classes.updateAfterPostponedButton}>
                <Button
                  onClick={() => setCompletedAuctionInfo(undefined)}
                  color="primary"
                  variant="outlined"
                >
                  Aggiorna esito
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
