import { Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { OriginType } from '../../../../utils/constants';
import { amountFormatter } from '../../../../utils/data-formatters';
import AuctionCountdown from '../AuctionCountdown';

const useStyles = makeStyles(
  (theme) => ({
    auctionStatusItem: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    chipLabel: {
      fontSize: 15,
    },
    chipTitle: {
      marginBottom: theme.spacing(1),
    },
    bestOffer: {
      transition: 'transform .2s',
    },
    bestOfferHighlighted: {
      transform: 'scale(1.5)',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  }),
  { name: 'AuctionActive' },
);

interface AuctionActiveProps {
  bestOffer: Record<string, any>;
  isPaused: boolean;
  saleMode: number;
  raiseLimitTS: number;
  serverTimeDeviceTimeOffset: number;
  reservePrice: number;
  updatedReservePrice: number;
  reservePriceRemoved: boolean;
}

export const AuctionActive: FC<AuctionActiveProps> = (props) => {
  const { bestOffer } = props;
  const classes = useStyles();

  const [bestOfferStyle, setBestOfferStyle] = useState<string>(classes.bestOffer);
  const [bestBidder, setBestBidder] = useState<Record<string, any>>({});
  const [mutateBestBidder, { loaded: bidderIsLoaded }] = useMutation();

  useEffect(() => {
    bestOffer.bidder &&
      mutateBestBidder(
        {
          type: 'getOne',
          resource: 'participations',
          payload: {
            id: bestOffer.bidder,
          },
        },
        {
          onSuccess: (res) => setBestBidder(res.data),
          onFailure: (err) => console.error(err),
        },
      );

    // highlight new best offer if origin FE/PVP (very simple animation :) )
    if (bestOffer.origin && bestOffer.origin !== OriginType.Paper) {
      setBestOfferStyle(() => `${classes.bestOffer} ${classes.bestOfferHighlighted}`);
      setInterval(() => setBestOfferStyle(classes.bestOffer), 3000);
    }
  }, [bestOffer]);

  const hasReservePrice: boolean = !props.reservePriceRemoved && props.updatedReservePrice > 0;

  return (
    <Fragment>
      <Grid item md={hasReservePrice ? 4 : 6} className={classes.auctionStatusItem}>
        <Typography variant="h6" className={classes.chipTitle}>
          Offerta migliore
        </Typography>
        <Chip
          classes={{
            label: classes.chipLabel,
          }}
          label={amountFormatter(bestOffer.offer)}
          className={bestOfferStyle}
        />
      </Grid>
      <Grid item md={hasReservePrice ? 4 : 6} className={classes.auctionStatusItem}>
        <Typography variant="h6" className={classes.chipTitle}>
          Offerente
        </Typography>
        <Chip
          classes={{
            label: classes.chipLabel,
          }}
          label={
            bidderIsLoaded && bestBidder
              ? `${bestBidder.presenter.lastName} ${bestBidder.presenter.firstName} (${
                  bestBidder.presenter.taxCode ?? '-'
                })`
              : 'Non disponibile'
          }
        />
      </Grid>
      {hasReservePrice && (
        <Grid item md={4} className={classes.auctionStatusItem}>
          <Typography variant="h6" className={classes.chipTitle}>
            Prezzo di riserva
          </Typography>
          <Chip
            classes={{
              label: classes.chipLabel,
            }}
            label={amountFormatter(
              props.reservePrice < props.updatedReservePrice
                ? props.updatedReservePrice
                : props.reservePrice,
            )}
            className={bestOfferStyle}
            // add underline if reserve price is lower than updated reserve price
            style={{
              textDecoration:
                props.reservePrice > props.updatedReservePrice ? 'line-through' : 'none',
            }}
          />

          {props.reservePrice > props.updatedReservePrice && (
            <Chip
              classes={{
                label: classes.chipLabel,
              }}
              label={amountFormatter(props.updatedReservePrice)}
              className={bestOfferStyle}
            />
          )}
        </Grid>
      )}
      <Grid item md={12} className={classes.auctionStatusItem}>
        <AuctionCountdown
          isPaused={props.isPaused}
          raiseLimitTS={props.raiseLimitTS}
          saleMode={props.saleMode}
          serverTimeDeviceTimeOffset={props.serverTimeDeviceTimeOffset}
        />
      </Grid>
    </Fragment>
  );
};
