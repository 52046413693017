import { Grid } from '@material-ui/core';
import { useNotify } from 'ra-core';
import React, { Children, cloneElement, FC, isValidElement } from 'react';
import { useMutation } from 'react-admin';
import { Form } from 'react-final-form';
import { CompletedAdvertisementInfo } from '../../../../Resources/SaleExperiment/types';

interface AdvertisementOutcomeProps {
  children: React.ReactNode;
  setCompletedAuctionInfo: React.Dispatch<
    React.SetStateAction<CompletedAdvertisementInfo | undefined>
  >;
  resource?: string;
  saleExpId?: number;
}

export const AdvertisementOutcomeForm: FC<AdvertisementOutcomeProps> = ({
  children,
  resource,
  setCompletedAuctionInfo,
  saleExpId,
}) => {
  const notify = useNotify();

  const [saveAdvertisementOutcome] = useMutation();
  const onSubmit = (data) => {
    if (!resource || !saleExpId) return;

    saveAdvertisementOutcome(
      {
        type: 'update',
        resource: `${resource}/${saleExpId}/save-advertisement-outcome`,
        payload: {
          data,
        },
      },
      {
        onSuccess: ({ data }) => {
          setCompletedAuctionInfo(data);
          notify('Operazione effettuata con successo.', 'info');
        },
        onFailure: (err) => {
          console.error('[saveAdvertisementOutcome]:', err);
          notify(err.message ? err.message : 'Operazione fallita.', 'error');
        },
      },
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={(formProps) => {
        return (
          <Grid container spacing={2}>
            {Children.map(
              children,
              (child) =>
                isValidElement(child) &&
                cloneElement(child, {
                  ...child.props,
                  variant: 'outlined',
                  formProps,
                }),
            )}
          </Grid>
        );
      }}
    />
  );
};
