import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { FC, Fragment, useState } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import { useBasePath } from '../../../hooks/use-base-path';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: -theme.spacing(5.5),
    },
    resumeButton: {
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.warning.dark,
      },
    },
    pauseButton: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.error.dark,
      },
    },
    dialog: {
      width: theme.spacing(60),
      height: theme.spacing(25),
      maxWidth: 'none',
      textAlign: 'center',
    },
    dialogButton: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'AuctionControlButton' },
);

const AuctionControlButton: FC<any> = (props) => {
  const { saleExperiment } = props;
  const { fkSaleMode, auctionStatus, id: saleExpId, auctionIsPaused } = saleExperiment;
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const basePath = useBasePath();

  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [mutateAuctionIsPaused] = useMutation();
  const handleAuctionPauseToggle = () =>
    mutateAuctionIsPaused(
      {
        type: 'create',
        resource: `${basePath}/${saleExpId}/toggle-auction-is-paused`,
        payload: {
          data: {
            id: saleExpId,
            offering: false,
          },
        },
      },
      {
        onSuccess: (res) => {
          setOpen(false);
          notify(
            res.data.auctionIsPaused ? "L'asta è attualmente in pausa." : "L'asta è ricominciata.",
            'info',
          );
          refresh();
        },
        onFailure: (err) => {
          setOpen(false);
          notify(err.message, 'error');
          console.log(err);
        },
      },
    );

  const isVisible = fkSaleMode === 3 && auctionStatus === 'active';

  let title;
  let buttonStatusClass;
  let Icon;
  let dialogText;
  if (!auctionIsPaused) {
    title = 'Metti in pausa la gara';
    Icon = PauseCircleFilledIcon;
    buttonStatusClass = classes.pauseButton;
    dialogText = 'Vuoi mettere in pausa la gara?';
  } else {
    title = 'Riavvia la gara';
    Icon = PlayCircleFilledWhiteIcon;
    buttonStatusClass = classes.resumeButton;
    dialogText = 'Vuoi riavviare la gara?';
  }

  return isVisible ? (
    <Fragment>
      <Button
        children={title}
        startIcon={<Icon />}
        variant="contained"
        className={`${classes.button} ${buttonStatusClass}`}
        onClick={() => (!auctionIsPaused ? handleAuctionPauseToggle() : setOpen(true))}
      />
      <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.dialog }}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">{dialogText}</Typography>
          </DialogContentText>
        </DialogContent>
        <AppDivider flex={false} />
        <DialogActions>
          <Button children="Annulla" onClick={handleClose} className={classes.dialogButton} />
          <Button
            children="Conferma"
            variant="contained"
            color="primary"
            onClick={handleAuctionPauseToggle}
            className={classes.dialogButton}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : (
    <span />
  );
};

export default AuctionControlButton;
