import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { BooleanField } from 'react-admin';
import AppAddressData from '../../Components/person-company/address-data.component';
import AppContactsData from '../../Components/person-company/contacts-data.component';
import AppRolesData from '../../Components/person-company/roles-data.component';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppLinkToEntityField from '../../Components/ui/field/AppLinkToEntityField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppRichTextInput, AppTextInput } from '../../Components/ui/input';
import { ITEM_BASE_SOURCE } from '../Booking/constants';
import { FOLDER_PERMISSIONS } from '../Folder';
import FolderDatagrid from '../Folder/FolderDatagrid';

const useBookingsDgStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(80),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'BookingDatagrid' },
);

const CompanyDetails = (props: Record<string, any>): ReactElement => {
  const bookingsDgClasses = useBookingsDgStyles();
  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        <AppCard title="Anagrafica" expanded>
          <AppTextInput source="businessName" required label="Ragione sociale" md={12} />
          <AppTextInput source="vat" required label="Partita IVA" />
          <AppTextInput source="taxCode" label="Codice fiscale" />
        </AppCard>
        <AppAddressData
          primaryAddress="Sede legale"
          secondaryAddress="Sede amministrativa"
          geocoderRef={props.options.geocoderRef}
          {...props}
        />
        <AppContactsData {...props} emailRequired />
        <AppRolesData {...props} personType="juridicalPerson" />
        <AppCard title="Annotazioni">
          <AppRichTextInput source="notes" label="" />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Fascicoli" path="folders" maxWidth={false}>
        <AppCard header={false} hideContent={props.mode === 'create'} collapsable={false}>
          <AppRelatedResource relatedResource="/folders" actionsHidden>
            <FolderDatagrid
              rowClick={(id) =>
                `/folders/${id}/show?goBack=true&resourceLabel=Azienda&previousResourcePath=${props.location.pathname}`
              }
              editButton={
                <AppGoToResourceButton
                  mode="edit"
                  destinationResourceName="folders"
                  currentResourceLabel="Azienda"
                  permissions={props.permissions}
                  resourcePermissions={FOLDER_PERMISSIONS}
                />
              }
              hideEditButton={props.mode === 'show'}
              showButton={
                <AppGoToResourceButton
                  mode="show"
                  destinationResourceName="folders"
                  currentResourceLabel="Azienda"
                  permissions={props.permissions}
                  resourcePermissions={FOLDER_PERMISSIONS}
                />
              }
              permissions={props.permissions}
              currentResourceLabel="Azienda"
              resource={props.resource}
            />
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Prenotazioni" path="bookings" maxWidth={false}>
        <AppCard hideContent={props.mode === 'create'} collapsable={false} header={false}>
          <AppRelatedResource relatedResource="/bookings" actionsHidden>
            <AppDatagrid>
              <AppDateField
                source="createdAt"
                label="Data richiesta"
                headerClassName={bookingsDgClasses.createdAt}
              />
              <AppLinkToEntityField
                label="Lotto da visitare"
                targetResource="items"
                originName="azienda"
                value={(r) => r[ITEM_BASE_SOURCE].title}
                targetResourceId={(r) => r[ITEM_BASE_SOURCE].id}
                truncate
                maxTextLength={60}
                headerClassName={bookingsDgClasses.itemTitle}
              />
              <BooleanField
                source="alreadyExported"
                label="Già esportato"
                headerClassName={bookingsDgClasses.lastCol}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default CompanyDetails;
