import { Fragment, ReactElement } from 'react';
import { DeleteWithConfirmButton, FormDataConsumer } from 'react-admin';
import { useForm } from 'react-final-form';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppTextField } from '../../Components/ui/field';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppNumberInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import RolePermissionManager from './RolePermissionManager';

const RoleDetails = (props: Record<any, any>): ReactElement => {
  return (
    <AppTabbedForm {...props}>
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        <AppCard expanded collapsable={false} header={false}>
          <AppTextInput source="description" label="Descrizione" required disabled md={9} />
          <AppNumberInput source="priority" label="Priorità" md={3} />
          <AppDivider />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { change } = useForm();
              return (
                <Fragment>
                  <AppBooleanInput
                    {...rest}
                    source="judiciary"
                    label="Giudiziario"
                    md={2}
                    onChange={() => {
                      change('ritesOnWhichIsMandatory', []);
                      change('ritesOnWhichIsSuggested', []);
                    }}
                  />
                  <AppAutocompleteInput
                    reference="pvp/rites"
                    filter={{
                      judiciary: formData.judiciary,
                    }}
                    optionText={(rite) => {
                      if (!rite) return '';
                      return `${rite.description.substr(0, 50)}...`;
                    }}
                    source="ritesOnWhichIsMandatory"
                    label="Riti in cui è obbligatorio"
                    md={5}
                    suggestionLimit={5}
                    variant="outlined"
                    isArray
                  />
                  <AppAutocompleteInput
                    reference="pvp/rites"
                    filter={{
                      judiciary: formData.judiciary,
                    }}
                    optionText={(rite) => {
                      if (!rite) return '';
                      return `${rite.description.substr(0, 50)}...`;
                    }}
                    source="ritesOnWhichIsSuggested"
                    label="Riti in cui è suggerito"
                    md={5}
                    suggestionLimit={5}
                    variant="outlined"
                    isArray
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
          <AppDivider />
          <AppBooleanInput source="juridicalPerson" label="Persona giuridica" md={3} />
          <AppBooleanInput source="naturalPerson" label="Persona fisica" md={3} />
          <AppBooleanInput source="folder" label="Fascicolo" md={3} />
          <AppBooleanInput source="item" label="Lotto" md={3} />
          <AppDivider />
          <AppBooleanInput
            source="login"
            label="Utilizzabile per accesso alla piattaforma"
            md={12}
          />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Permessi" path="permissions">
        <AppCard title="Lista permessi" hideContent={props.mode === 'create'} collapsable={false}>
          <AppRelatedResource
            relatedResource="/permissions"
            errorMessage="Errore durante il caricamento dei ruoli"
            manager={<RolePermissionManager />}
            // actionsHidden={disabled}
          >
            <AppDatagrid>
              <AppTextField
                label="Descrizione"
                source="permission.description"
                // headerClassName={classes.roleDesc}
              />
              <AppTextField
                label="Percorso"
                source="permission.path"
                // headerClassName={classes.roleDesc}
              />
              <AppTextField
                label="Metodo"
                source="permission.method"
                // headerClassName={classes.roleDesc}
              />
              {/* <AppRelatedResourceEditButton /> */}
              <DeleteWithConfirmButton
                redirect={false}
                confirmTitle="Eliminazione permesso"
                confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                // disabled={disabled}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default RoleDetails;
