import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router';
import BackIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(
  (theme) => ({
    card: {
      margin: theme.spacing(2),
    },
    goBackButton: {
      margin: `${theme.spacing(5)}px auto`,
      fontSize: 20,
      display: 'flex',
    },
  }),
  { name: 'AppPageNotFound' },
);

const AppPageNotFound: FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h3" align="center">
          PAGINA NON TROVATA!
        </Typography>
        <Button
          className={classes.goBackButton}
          children="Torna indietro"
          onClick={() => history.goBack()}
          variant="outlined"
          color="primary"
          startIcon={<BackIcon />}
        />
      </CardContent>
    </Card>
  );
};

export default AppPageNotFound;
