import { FC, memo } from 'react';
import { useFormState } from 'react-final-form';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { PaymentMethod } from '../constants';

export const CheckDetails: FC<any> = memo((props) => {
  const { values } = useFormState();
  return (
    <AppCard
      {...props}
      title="Informazioni assegno circolare"
      hidden={values.paymentMethod !== PaymentMethod.Check}
    >
      <AppTextInput source="checkNumber" label="Numero assegno" />
    </AppCard>
  );
});
