import { FC, Fragment } from 'react';
import { useFormState } from 'react-final-form';
import { PageSection, PAGE_SECTION_CHOICES } from '../../utils/constants';
import { AppRichTextInput, AppSelectInput, AppTextInput } from '../ui/input';
import { AppImageInput } from '../ui/input/AppImageInput';
import AppPageSectionMultipleForm from './app-page-section-multiple-form';
import AppPageSectionMultipleWithImageForm from './app-page-section-multiple-with-image-form';

const AppPageSectionForm: FC<any> = (props) => {
  const { values } = useFormState();

  return (
    <Fragment>
      <AppSelectInput
        md={6}
        xs={6}
        variant="outlined"
        label="Tipo"
        choices={PAGE_SECTION_CHOICES}
        defaultValue={PageSection.Text}
        source="type"
        disabled={props.mode === 'edit'}
        required
      />
      <AppTextInput source="title" label="Titolo" md={12} required />

      {/* Sezione con testo */}
      {values.type === PageSection.Text && <AppRichTextInput source="text" label="Testo" />}

      {/* Sezione con testo + immagine a sx/dx/bottom/top */}
      {[
        PageSection.ImageLeft,
        PageSection.ImageRight,
        PageSection.ImageTop,
        PageSection.ImageBottom,
      ].includes(values.type) && (
        <>
          <AppRichTextInput source="text" label="Testo" />
          <AppImageInput
            source="imageFile"
            accept="image/png,image/jpeg"
            label="Immagine"
            previewSource="imageUrl"
            previewLabel="Immagine corrente"
            isRequired
          />
        </>
      )}

      {values.type === PageSection.Carousel && (
        <AppPageSectionMultipleForm formSource="carousels" formLabel="Carosello" />
      )}

      {values.type === PageSection.InfoTriple && (
        <AppPageSectionMultipleWithImageForm formSource="infoTriples" formLabel="Tripletta" />
      )}

      {values.type === PageSection.InformativeCards && (
        <AppPageSectionMultipleWithImageForm
          formSource="informativeCards"
          formLabel="Card informative"
        />
      )}

      {values.type === PageSection.VideoYoutube && (
        <AppTextInput source="videoYoutubeUrl" label="Url video YouTube" md={12} required />
      )}
    </Fragment>
  );
};

export default AppPageSectionForm;
