import { green, grey, orange, red, yellow } from '@material-ui/core/colors';

export const ENTITIES_CHOICES = [
  { id: '', name: 'Tutti' },
  { id: 'Item', name: 'Lotti' },
  { id: 'Folder', name: 'Fascicoli' },
  { id: 'Capability', name: 'Ruoli' },
  { id: 'SaleExperiment', name: 'Esperimenti di vendita' },
  { id: 'Media', name: 'Media' },
  { id: 'Publication', name: 'Pubblicazioni' },
];

export const UPDATE_STATUS_CHOICES = [
  { id: '', name: 'Tutti' },
  { id: 'pending', name: 'In attesa' },
  { id: 'processing', name: 'Processando' },
  { id: 'success', name: 'Successo' },
  { id: 'error', name: 'Errore' },
  { id: 'skip', name: 'Saltata' },
];

/// region COLORS

type ExternalPropertyUpdateColors = {
  pending: [string, string];
  processing: [string, string];
  success: [string, string];
  error: [string, string];
  skip: [string, string];
};

export const PENDING_BACKGROUND_COLOR: string = yellow[300];
export const PENDING_TEXT_COLOR: string = grey[50];

export const PROCESSING_BACKGROUND_COLOR: string = orange[500];
export const PROCESSING_TEXT_COLOR: string = grey[900];

export const SUCCESS_BACKGROUND_COLOR: string = green[500];
export const SUCCESS_TEXT_COLOR: string = grey[900];

export const ERROR_BACKGROUND_COLOR: string = red[700];
export const ERROR_TEXT_COLOR: string = grey[50];

export const SKIP_BACKGROUND_COLOR: string = grey[500];
export const SKIP_TEXT_COLOR: string = grey[50];

export const PUBLICATION_COLORS: ExternalPropertyUpdateColors = {
  pending: [PENDING_BACKGROUND_COLOR, PENDING_TEXT_COLOR],
  processing: [PROCESSING_BACKGROUND_COLOR, PROCESSING_TEXT_COLOR],
  success: [SUCCESS_BACKGROUND_COLOR, SUCCESS_TEXT_COLOR],
  error: [ERROR_BACKGROUND_COLOR, ERROR_TEXT_COLOR],
  skip: [SKIP_BACKGROUND_COLOR, SKIP_TEXT_COLOR],
};
