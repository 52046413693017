import { Grid, GridSize, makeStyles } from '@material-ui/core';
import React, { FC, PropsWithChildren } from 'react';
import { ArrayInput, InputProps, SimpleFormIterator } from 'react-admin';
import AppSectionTitle from '../layout/AppSectionTitle';
import { inputProps } from '../utils';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'AppArrayInput' },
);

type AppArrayInputProps = {
  disabled?: boolean;
  md?: boolean | GridSize;
} & InputProps;

export const AppArrayInput: FC<PropsWithChildren<AppArrayInputProps>> = React.memo<
  PropsWithChildren<AppArrayInputProps>
>((props) => {
  const classes = useStyles();
  return (
    <Grid item md={props.md ?? 6} className={classes.container}>
      <AppSectionTitle>{props.label}</AppSectionTitle>
      <ArrayInput {...inputProps(props)} label={false}>
        <SimpleFormIterator>{props.children}</SimpleFormIterator>
      </ArrayInput>
    </Grid>
  );
});
