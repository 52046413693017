import { Send } from '@material-ui/icons';
import { FC } from 'react';
import { useMutation, useNotify } from 'react-admin';
import AppFormDialog from '../../Components/ui/dialog/form-dialog/form-dialog';
import { AppSelectInput, AppTextInput } from '../../Components/ui/input';
import AppToolbarAction from '../../Components/ui/layout/AppToolbarAction';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import { useToggleState } from '../../hooks/use-toggle-state';

const CommunicationDetailActions: FC<any> = (props) => {
  const notify = useNotify();
  const [dialogOpen, toggleDialog] = useToggleState();

  const [forwardCommunication] = useMutation();
  const onForwardCommunicationSubmit = (formData) => {
    return forwardCommunication(
      {
        type: 'create',
        resource: `communications/${props.data.id}/forward`,
        payload: {
          data: {
            type: formData.type,
            to: formData.to,
            cc: formData.cc,
            bcc: formData.bcc,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Operazione conclusa con successo.');
          toggleDialog();
        },
        onFailure: (err) => {
          console.error('Error forwarding communication:', err);
          notify(`Operazione non riuscita: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`, 'error');
        },
      },
    );
  };
  return (
    <AppToolbarActions {...props}>
      <AppToolbarAction onClick={toggleDialog} text="Re-invia comunicazione" icon={Send} />
      <AppFormDialog
        open={dialogOpen}
        onClose={toggleDialog}
        title="Form reinvio"
        onSubmit={onForwardCommunicationSubmit}
        record={{}}
        width={100}
        height={55}
      >
        <AppSelectInput
          source="type"
          choices={[
            { id: 'email', name: 'Email' },
            { id: 'pec', name: 'PEC' },
          ]}
          defaultValue={'email'}
          label="Tipo"
          required
        />
        <AppTextInput source="to" label="Destinatario" required />
        <AppTextInput source="cc" label="CC" />
        <AppTextInput source="bcc" label="BCC" />
      </AppFormDialog>
    </AppToolbarActions>
  );
};

export default CommunicationDetailActions;
