import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import AppPdfViewer from '../../Components/pdf-viewer/pdf-viewer';
import PecViewer from '../../Components/pec-viewer/pec-viewer';
import AppDownloadMediaButton from '../../Components/ui/button/AppDownloadMediaButton';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    media: {
      width: '70%',
      height: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'Attachment' },
);

type Props = {
  attachment: any;
};

const Attachment: FC<Props> = ({ attachment }) => {
  const classes = useStyles();
  const attachmentSrc = `data:${attachment.metadata.mimeType};base64,${attachment.metadata.base64}`;

  return (
    <Card className={classes.card} key={`attachment-${attachment.id}`}>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          {attachment.name}
        </Typography>
        <AppDownloadMediaButton fileName={attachment.name} base64={attachmentSrc} />
      </CardContent>
      {attachment.metadata.mimeType === 'application/pdf' ? (
        <AppPdfViewer file={attachmentSrc} />
      ) : attachment.metadata.mimeType === 'message/rfc822' ? (
        <PecViewer pecBase64={attachmentSrc.split(';base64,')[1]} />
      ) : (
        <CardMedia
          className={classes.media}
          component="img"
          alt="Anteprima documento non disponibile"
          src={attachmentSrc}
        />
      )}
    </Card>
  );
};

export default Attachment;
