import { green, grey, orange, red } from '@material-ui/core/colors';

type ComplaintColors = {
  open: [string, string];
  unsettled: [string, string];
  closed: [string, string];
};

export const COMPLAINT_STATUS_CHOICES = [
  { id: null, name: '\xa0' },
  { id: 'open', name: 'Aperta' },
  { id: 'unsettled', name: 'Non risolta' },
  { id: 'closed', name: 'Chiusa' },
];
export const COMPLAINT_TYPE_CHOICES = [
  { id: null, name: '\xa0' },
  { id: 'not-received', name: 'Non ricevuto' },
  { id: 'other', name: 'Altro' },
];

export const COMPLAINT_OPEN_BACKGROUND_COLOR: string = orange[500];
export const COMPLAINT_OPEN_TEXT_COLOR: string = grey[900];
export const COMPLAINT_UNSETTLED_BACKGROUND_COLOR: string = red[700];
export const COMPLAINT_UNSETTLED_TEXT_COLOR: string = grey[50];
export const COMPLAINT_CLOSED_BACKGROUND_COLOR: string = green[500];
export const COMPLAINT_CLOSED_TEXT_COLOR: string = grey[900];

export const COMPLAINT_COLORS: ComplaintColors = {
  open: [COMPLAINT_OPEN_BACKGROUND_COLOR, COMPLAINT_OPEN_TEXT_COLOR],
  unsettled: [COMPLAINT_UNSETTLED_BACKGROUND_COLOR, COMPLAINT_UNSETTLED_TEXT_COLOR],
  closed: [COMPLAINT_CLOSED_BACKGROUND_COLOR, COMPLAINT_CLOSED_TEXT_COLOR],
};
