import { GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBalanceTwoTone } from '@material-ui/icons';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { useDashboardContext } from '../../../hooks';
import { PersonRoles } from '../../../utils/constants';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import { PublishItemButton } from '../components/publish-item-button';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'AppDashboardExternalPropertiesPendingPublications' },
);

const AppDashboardExternalPropertiesPendingPublications: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useDashboardContext();

  if (user?.roleId === PersonRoles.Owner || user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="items/external/to-be-published"
        tableTitle="Lotti esterni da pubblicare"
        tableSubtitle="Di seguito sono elencati i lotti ricevuti da altri enti ancora da pubblicare"
        noItemMessage="Non ci sono nuovi lotti da publicare"
        noItemIcon={<AccountBalanceTwoTone />}
        rowClick={(id, basePath, record) => history.push(`items/${record.id}`)}
      >
        <AppTextField
          source="title"
          label="Titolo lotto"
          headerClassName={classes.title}
          truncate
        />
        <AppTextField
          source="description"
          label="Descrizione lotto"
          headerClassName={classes.title}
          truncate
        />
        <AppTextField
          source="jugaadExternalPropertyName"
          label="Codice mittente"
          headerClassName={classes.lastCol}
        />
        <PublishItemButton />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardExternalPropertiesPendingPublications;
