import HighlightIcon from '@material-ui/icons/Highlight';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import HighlightDetails from './HighlightDetails';
import HighlightsList from './HighlightList';

export const HIGHLIGHT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Highlight: ResourceExport = {
  list: HighlightsList,
  create: resourceWrapper('create', HighlightDetails, undefined, undefined, HIGHLIGHT_PERMISSIONS),
  edit: resourceWrapper('edit', HighlightDetails, undefined, undefined, HIGHLIGHT_PERMISSIONS),
  show: resourceWrapper('show', HighlightDetails, undefined, undefined, HIGHLIGHT_PERMISSIONS),
  icon: HighlightIcon,
};
export default Highlight;
