/**
 * Doc: https://casesandberg.github.io/react-color/
 */
// TODO: improve input -> make picker show/hide when clicking the preview, using position absolute to make it float
import { Grid, GridSize, makeStyles, Theme } from '@material-ui/core';
import _get from 'lodash/get';
import React, { FC, useState } from 'react';
import { ChromePicker, Color, ColorResult } from 'react-color';
import { Field, useForm } from 'react-final-form';
import AppSectionTitle from '../layout/AppSectionTitle';

const useStyles = makeStyles<Theme, { currentColor: string }>(
  (theme) => ({
    container: {
      margin: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    colorContainer: {
      padding: theme.spacing(1),
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      width: '100%',
      //cursor: 'pointer',
      // position: 'relative',
    },
    colorPreview: (props) => ({
      height: '14px',
      borderRadius: '2px',
      backgroundColor: props.currentColor,
      marginTop: theme.spacing(2),
    }),
    colorPicker: {
      // position: 'absolute',
      width: 'auto !important',
    },
  }),
  { name: 'AppColorPickerInput' },
);

type AppColorInputProps = {
  source: string;
  label?: string;
  record?: Record<string, any>;
  disabled?: boolean;
  md?: GridSize;
  initialValue?: Color;
  hexSource?: string;
  rgbSource?: string;
};

export const AppColorInput: FC<AppColorInputProps> = React.memo<AppColorInputProps>(
  ({
    source,
    label = 'Seleziona un colore',
    record = {},
    disabled = false,
    md = 6,
    initialValue = '#FF0000',
    hexSource,
    rgbSource,
  }) => {
    const [currentColor, setCurrentColor] = useState<Color>(_get(record, source, initialValue));
    // const [showPicker, setShowPicker] = useState(false);

    const classes = useStyles({ currentColor: currentColor as string });

    const { change } = useForm();

    /// region COLOR PICKER HANDLING

    const onColorChange = (color: ColorResult) => {
      if (disabled) return;

      setCurrentColor(color.hex);

      // Update form's field value
      if (hexSource || rgbSource) {
        !!hexSource && change(`${source}.${hexSource}`, color.hex);
        !!rgbSource && change(`${source}.${rgbSource}`, color.rgb);
      } else {
        change(source, color.hex);
      }
    };

    /// endregion

    return (
      <Grid item className={classes.container} md={md}>
        <AppSectionTitle className={classes.title}>{label}</AppSectionTitle>
        <Field
          defaultValue={_get(record, `${source}${hexSource ? `.${hexSource}` : ''}`, initialValue)}
          name={source}
          component={() => (
            <div className={classes.colorContainer}>
              <ChromePicker
                color={currentColor}
                onChangeComplete={onColorChange}
                disableAlpha
                className={classes.colorPicker}
              />
              <div className={classes.colorPreview} />
            </div>
          )}
        />
      </Grid>
    );
  },
);
