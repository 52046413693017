import ClicksCounterIcon from '@material-ui/icons/Mouse';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import ClicksCounterList from './ClicksCounterList';

export const CLICKS_COUNTER_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const ClicksCounter: ResourceExport = {
  list: ClicksCounterList,
  icon: ClicksCounterIcon,
};
export default ClicksCounter;
