import { isEmpty as _empty } from 'lodash';
import { stringify as stringifyQuery } from 'query-string';
import {
  CreateResult,
  fetchUtils,
  GetListResult,
  GetManyResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';
import AuthManager from './AuthManager';

const { fetchJson } = fetchUtils;

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Query = Record<string, any> | undefined | null;
type Body = Record<string, any> | undefined | null;
type Headers = Record<string, string> | string[][];

const DEFAULT_HEADERS: Headers = { 'Content-Type': 'application/json' };

export class ApiProvider {
  private readonly baseUrl: string;
  constructor() {
    if (!process.env.REACT_APP_BASE_URL)
      throw new Error('AUTH_PROVIDER ERROR: API BASE URL NOT SET!');
    this.baseUrl = process.env.REACT_APP_BASE_URL;
  }

  public get<T>(
    uri: string,
    query: Query,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<GetManyResult<T>> {
    return this.request<any>(uri, 'GET', query, null, headers);
  }

  public getOne<T>(
    uri: string,
    id: string | number | null,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<GetOneResult<T>> {
    if (id) {
      uri = `${uri}/${id}`;
    }

    return this.request<T>(uri, 'GET', null, null, headers).then((data) => {
      //console.info('ApiProvider - getOne (result)', data);
      return { data };
    });
  }

  public list<T>(
    uri: string,
    query: Query,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<GetListResult<T>> {
    return this.request<any>(uri, 'GET', query, null, headers);
  }

  public post<T>(
    uri: string,
    query: Query,
    body: Body,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<CreateResult<T>> {
    return this.request<T>(uri, 'POST', query, body, headers).then((res) => ({
      data: res,
    }));
  }

  public put<T>(
    uri: string,
    query: Query,
    body: Body,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<T> {
    return this.request(uri, 'PUT', query, body, headers);
  }

  public putOne<T>(
    uri: string,
    id: string | number,
    body: Body,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<UpdateResult<T>> {
    if (id) {
      uri = `${uri}/${id}`;
    }

    return this.request<T>(uri, 'PUT', null, body, headers).then((res) => ({
      data: res,
    }));
  }

  public patch<T>(
    uri: string,
    query: Query,
    body: Body,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<T> {
    return this.request(uri, 'PATCH', query, body, headers);
  }

  public patchOne<T>(
    uri: string,
    id: string | number,
    body: Body,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<UpdateResult<T>> {
    return this.request<T>(`${uri}/${id}`, 'PATCH', null, body, headers).then((res) => ({
      data: res,
    }));
  }

  public delete<T>(uri: string, query: Query, headers: Headers = DEFAULT_HEADERS): Promise<T> {
    console.log('ApiProvider - delete', uri, query);
    return this.request<T>(uri, 'DELETE', query, null, headers);
  }

  public deleteOne(
    uri: string,
    id: string | number,
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<void> {
    return this.request(`${uri}/${id}`, 'DELETE', null, null, headers);
  }

  public async upload(
    uri: string,
    file: Record<string, any> | Record<string, any>[],
    headers: Headers = DEFAULT_HEADERS,
  ): Promise<CreateResult<any>> {
    if (file instanceof Array) {
      const convertedFiles: Record<string, any>[] = [];
      for (const singleFile of file) {
        const convertedFile = await convertFileToBase64(singleFile);
        convertedFiles.push(convertedFile);
      }
      return this.request(uri, 'POST', null, { file: convertedFiles }, headers).then((res) => ({
        data: res,
      }));
    }

    return convertFileToBase64(file).then((convertedFile) =>
      this.request(uri, 'POST', null, { file: convertedFile }, headers).then((res) => ({
        data: res,
      })),
    );
  }

  private request<T>(
    uri: string,
    method: HttpMethod,
    query: Query,
    body: Body,
    customHeaders: Headers,
  ): Promise<T> {
    if (body && _empty(body)) return Promise.reject('No data specified');

    let url = `${this.baseUrl}/${uri}`;

    if (query) {
      url += `?${stringifyQuery({
        filter: JSON.stringify(query.filter ?? {}),
        pagination: JSON.stringify(query.pagination ?? {}),
        sort: JSON.stringify(query.sort ?? {}),
      })}`;
    }

    const headers = new Headers({
      Accept: 'application/json',
      ...customHeaders,
    });
    const authToken = AuthManager.getAuthToken();
    if (authToken) {
      headers.append('Authorization', 'Bearer ' + authToken);
    }

    return fetchJson(url, {
      method: method || 'GET',
      body: body && JSON.stringify(body),
      headers: headers,
    }).then((data) => {
      //console.info('ApiProvider - request (result)', data);
      return data.json;
    });
  }
}

const convertFileToBase64 = (file): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      file.$base64 = reader.result;
      if (file.$raw.name) file.$name = file.$raw.name;
      if (file.$raw.lastModified) file.$lastModified = file.$raw.lastModified;
      if (file.$raw.size) file.$size = file.$raw.size;
      if (file.$raw.type) file.$type = file.$raw.type;
      delete file.$raw;
      resolve(file);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file.$raw);
  });
