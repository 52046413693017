import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import { FC, Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { FormDataConsumer, FunctionField, ListContextProvider, useMutation } from 'react-admin';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppTextField } from '../../Components/ui/field';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import { AppFileInput } from '../../Components/ui/input/AppFileInput';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { AppBold, AppText } from '../../Components/ui/text';
import { useCmsConfiguration } from '../../hooks/use-cms-configuration';
import { ITEM_GENRES, MAGAZINE_KINDS, MAGAZINE_METHODS, PAGE_ITEMS } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';
import { blobToBase64 } from '../../utils/files';
import { minDate2 } from '../../utils/validators';
import { GenerationStatusText } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    booleanInput: {
      marginLeft: theme.spacing(1),
    },
    coverFilePreview: {
      maxWidth: theme.spacing(30),
    },
    alert: {
      margin: theme.spacing(2),
    },
    statusChipLabel: {
      fontSize: 18,
    },
    otherChipLabel: {
      fontSize: 13,
    },
  }),
  { name: 'MagazineDetails' },
);

const MagazineDetails: FC<any> = (props): ReactElement => {
  //#region MAGAZINE LENGTHS

  // TODO: MOVE FROM HERE
  const cmsConfig = useCmsConfiguration();

  const magazineTitleLengthPremium = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLengthPremium ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLengthPremium = useMemo(
    () =>
      parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLengthPremium ?? '0'),
    [cmsConfig],
  );
  const magazineTitleLength2X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLength2X ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLength2X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLength2X ?? '0'),
    [cmsConfig],
  );
  const magazineTitleLength3X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLength3X ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLength3X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLength3X ?? '0'),
    [cmsConfig],
  );

  //#endregion

  const classes = useStyles();
  const [updatePublicationsList] = useMutation();

  const listBaseCtx = {
    resource: 'magazine/publications-to-include',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    total: 0,
    loading: false,
  };

  const [itemsListCtx, setItemsListCtx] = useState<Record<string, any>>(listBaseCtx);

  const handleUpdatePublicationsList = async (formData: Record<string, any>) => {
    await setItemsListCtx({ ...listBaseCtx, loaded: false, loading: true });
    updatePublicationsList(
      {
        type: 'getList',
        resource: 'magazine/publications-to-include',
        payload: {
          filter: formData,
        },
      },
      {
        onSuccess: ({ data, total }) => {
          setItemsListCtx(createList(data, total, true, false));
        },
        onFailure: (err) => {
          setItemsListCtx({ ...listBaseCtx, loading: false });
          console.error('[handleUpdatePublicationsList]:', err);
        },
      },
    );
  };

  const createList = (data, total, loaded, loading) => {
    return _assign({}, listBaseCtx, {
      data: _keyBy(data, 'id'),
      ids: _map(data, 'id'),
      total,
      loaded,
      loading,
    }) as any;
  };

  const handleFilesAndSave = async (record: any, redirect: any, options: any) => {
    if (record.method === 'upload' && record.file) {
      await blobToBase64(record.file.file).then((result) => {
        record.file = {
          base64: result,
          filename: record.file.filename,
          type: record.file.rawFile.type,
        };
      });
    }
    if (record.coverFile) {
      await blobToBase64(record.coverFile.coverFile).then((result) => {
        record.coverFile = {
          base64: result,
          filename: record.coverFile.filename,
          type: record.coverFile.rawFile.type,
        };
      });
    }

    // Send to API server
    props.save(record, redirect, options);
  };

  const getPublicationsListTitle = (list) => {
    return `Lista lotti che saranno inclusi nel bollettino${
      list.ids?.length > 0 ? ` (${list.ids?.length})` : ''
    }`;
  };

  const getPublicationsListTable = (list) => {
    return (
      <ListContextProvider value={list}>
        <AppDatagrid>
          <AppTextField source="title" label="Titolo" />
          <AppTextField source="ivgCode" label="Codice IVG" style={{ whiteSpace: 'nowrap' }} />
          <FunctionField
            label="Premium"
            style={{ whiteSpace: 'nowrap' }}
            render={(record) => {
              if (record?.magazinePremium) {
                return renderCounters(
                  record,
                  magazineTitleLengthPremium,
                  magazineDescriptionLengthPremium,
                );
              }
            }}
          />
          <FunctionField
            label="2 per pagina"
            style={{ whiteSpace: 'nowrap' }}
            render={(record) => {
              return renderCounters(record, magazineTitleLength2X, magazineDescriptionLength2X);
            }}
          />
          <FunctionField
            label="3 per pagina"
            style={{ whiteSpace: 'nowrap' }}
            render={(record) => {
              return renderCounters(record, magazineTitleLength3X, magazineDescriptionLength3X);
            }}
          />
          <FunctionField
            label="Link"
            render={(record) => {
              return (
                <AppItemLinksButton
                  resource={props.resource}
                  folderIdSource="fkFolder"
                  itemIdSource="id"
                  record={record}
                  currentResourceLabel="Magazine"
                />
              );
            }}
          />
        </AppDatagrid>
      </ListContextProvider>
    );
  };

  const renderCounters = (record, titleLengthCheck, descriptionLengthCheck) => {
    let titleSource: 'T' | 'TM' | null = null;
    let titleLength = 0;
    let descriptionSource: 'D' | 'DM' | null = null;
    let descriptionLength = 0;
    if (record.magazineTitle?.length) {
      titleSource = 'TM';
      titleLength = record.magazineTitle.length;
    } else if (record.title?.length) {
      titleSource = 'T';
      titleLength = record.title.length;
    }
    if (record.magazineDescription?.length) {
      descriptionSource = 'DM';
      descriptionLength = record.magazineDescription.length;
    } else if (record.description?.length) {
      descriptionSource = 'D';
      descriptionLength = record.description.length;
    }
    return (
      <>
        <Grid container md={12}>
          <Grid item md={4}>
            <AppTextField
              customText={`${titleSource === 'TM' ? 'M' : ''}`}
              color={titleLength > titleLengthCheck ? 'error' : 'primary'}
              type="text"
              variant="button"
              component={'div'}
            />
          </Grid>
          <Grid item md={8}>
            <AppTextField
              customText={`${titleLength} / ${titleLengthCheck}`}
              color={titleLength > titleLengthCheck ? 'error' : 'primary'}
              type="text"
              variant="button"
              component={'div'}
            />
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <AppTextField
              customText={`${descriptionSource === 'DM' ? 'M' : ''}`}
              color={descriptionLength > descriptionLengthCheck ? 'error' : 'primary'}
              type="text"
              variant="button"
              component={'div'}
            />
          </Grid>
          <Grid item md={8}>
            <AppTextField
              customText={`${descriptionLength} / ${descriptionLengthCheck}`}
              color={descriptionLength > descriptionLengthCheck ? 'error' : 'primary'}
              type="text"
              variant="button"
              component={'div'}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    const publicationsListCardTitle = document.querySelector('#publicationsList>div>h6');
    if (publicationsListCardTitle) {
      publicationsListCardTitle.innerHTML = getPublicationsListTitle(itemsListCtx);
    }
  }, [itemsListCtx]);

  return (
    <AppBaseForm
      {...props}
      save={(record, redirect, options) => handleFilesAndSave(record, redirect, options)}
      disabledCards={props.mode !== 'create'}
    >
      <AppCard expanded title="Impostazioni generazione bollettino">
        <AppSelectInput
          source="method"
          label="Metodo"
          choices={MAGAZINE_METHODS}
          required
          defaultValue="generate"
        />
        <AppSelectInput
          source="genre"
          label="Genere"
          choices={ITEM_GENRES}
          defaultValue="real-estate"
        />
        <AppDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AppTextInput
              {...rest}
              source="title"
              label="Titolo"
              variant="outlined"
              md={formData.genre === 'real-estate' ? 12 : 6}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'movable' && (
              <>
                <AppTextInput {...rest} source="subtitle" label="Sottotitolo" variant="outlined" />
                <AppTextInput {...rest} source="edition" label="Edizione" variant="outlined" />
                <AppTextInput {...rest} source="year" label="Anno" variant="outlined" />
              </>
            )
          }
        </FormDataConsumer>
        <AppDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.method === 'generate' ? (
              <>
                <AppDateInput
                  {...rest}
                  source="date"
                  label="Data inizio"
                  variant="outlined"
                  defaultValue={new Date(1970, 0, 1).toISOString()}
                />
                <AppDateInput
                  {...rest}
                  source="endDate"
                  label="Data fine"
                  variant="outlined"
                  defaultValue={new Date(2100, 11, 31).toISOString()}
                />
                <AppAutocompleteInput
                  {...rest}
                  source="courts"
                  reference="courts"
                  label="Tribunali"
                  variant="outlined"
                  md={6}
                  isArray
                />
                <AppAutocompleteInput
                  {...rest}
                  source="fkProperty"
                  reference="properties"
                  filter={{
                    schema: null,
                  }}
                  label="Sito *"
                  required
                  resettable
                  variant="outlined"
                  md={6}
                  defaultValue={1} // Sito Istituto / CDA Giudiziarie
                />
                <AppAutocompleteInput
                  {...rest}
                  label="Includi solo fascicolo"
                  source="fkFolder"
                  resettable
                  reference="folders"
                  optionText={(selectedFolder) => {
                    if (!selectedFolder) return;
                    return selectedFolder.procedureCode
                      ? selectedFolder.procedureCode
                      : selectedFolder.title;
                  }}
                  md={6}
                />
                <AppAutocompleteInput
                  {...rest}
                  label="Escludi fascicoli"
                  source="excludeFolders"
                  resettable
                  reference="folders"
                  optionText={(selectedFolder) => {
                    if (!selectedFolder) return;
                    return selectedFolder.procedureCode
                      ? selectedFolder.procedureCode
                      : selectedFolder.title;
                  }}
                  isArray
                  md={6}
                />
                <AppDivider />
                <AppBooleanInput
                  {...rest}
                  source="expressionOfInterestOnly"
                  label="Includi SOLO Manifestazioni di interesse"
                  className={classes.booleanInput}
                />
                {!formData.expressionOfInterestOnly && (
                  <AppBooleanInput
                    {...rest}
                    source="includeExpressionOfInterest"
                    label="Includi ANCHE manifestazioni di interesse"
                    className={classes.booleanInput}
                  />
                )}
                <AppDivider />
                <AppSelectInput
                  {...rest}
                  source="items"
                  required
                  label="Beni per pagina"
                  choices={PAGE_ITEMS}
                  md={3}
                  variant="outlined"
                  defaultValue="auto"
                />
                <AppSelectInput
                  {...rest}
                  source="kind"
                  required
                  label="Tipo di magazine"
                  choices={MAGAZINE_KINDS}
                  md={3}
                  variant="outlined"
                  defaultValue="print"
                />
                <AppAutocompleteInput
                  {...rest}
                  source="fkMagazineCover"
                  label="Prima pagina del magazine *"
                  reference="magazine-cover"
                  filter={{
                    genre: formData.genre,
                    kind: formData.kind,
                  }}
                  required
                  md={3}
                />
                <AppAutocompleteInput
                  {...rest}
                  source="fkMagazineIndex"
                  label="Indice del magazine *"
                  reference="magazine-index"
                  filter={{
                    genre: formData.genre,
                    kind: formData.kind,
                  }}
                  required
                  md={3}
                />
              </>
            ) : props.mode === 'create' ? (
              <AppFileInput
                source="file"
                accept="application/pdf"
                label="Bollettino"
                md={12}
                previewLabel="Anteprima"
              />
            ) : (
              <Fragment />
            )
          }
        </FormDataConsumer>
        {props.mode !== 'create' && (
          <Grid item md={12}>
            <AppDownloadFromGCPBucketField record={props.record} source="publicUrl" />
          </Grid>
        )}
      </AppCard>
      {props.mode !== 'create' && props.record?.publicationsList && (
        <AppCard
          title={getPublicationsListTitle(
            createList(
              props.record.publicationsList,
              props.record.publicationsList.length,
              true,
              false,
            ),
          )}
          expanded={false}
        >
          {getPublicationsListTable(
            createList(
              props.record.publicationsList,
              props.record.publicationsList.length,
              true,
              false,
            ),
          )}
        </AppCard>
      )}
      {props.mode === 'create' && (
        <AppCard
          id="publicationsList"
          title={getPublicationsListTitle(
            createList(
              props.record?.publicationsList,
              props.record?.publicationsList?.length,
              true,
              false,
            ),
          )}
          expanded={false}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <>
                <a
                  onClick={() => {
                    handleUpdatePublicationsList(formData);
                  }}
                  {...rest}
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '3px 9px',
                    color: '#007bff',
                    border: '1px solid rgba(0, 123, 255, 0.5)',
                    minWidth: '64px',
                    fontWeight: 500,
                    lineHeight: 1.75,
                    borderRadius: '4px',
                    letterSpacing: '0.02857em',
                    textTransform: 'uppercase',
                    justifyContent: 'center',
                    marginBottom: '10px',
                  }}
                >
                  AGGIORNA
                </a>
              </>
            )}
          </FormDataConsumer>
          {itemsListCtx.loaded !== undefined && (
            <div style={{ marginBottom: '10px' }}>
              {itemsListCtx.loading ? (
                <>Caricamento...</>
              ) : (
                <>
                  {itemsListCtx.total > 0 ? (
                    getPublicationsListTable(itemsListCtx)
                  ) : (
                    <>Nessun risultato</>
                  )}
                </>
              )}
            </div>
          )}
        </AppCard>
      )}
      <AppCard title="Impostazioni di pubblicazione su sito">
        <AppTextInput
          source="publicationTitle"
          label="Titolo pubblicazione"
          md={6}
          disabled={props.mode === 'show'}
          helperText="Se non indicato verrà utilizzato il titolo del bollettino"
        />
        <AppDateInput
          source="publicationFrom"
          label="Inizio pubblicazione su sito"
          md={3}
          disabled={props.mode === 'show'}
          helperText="Compilare per pubblicare a sito"
        />
        <AppDateInput
          source="publicationTo"
          label="Termine pubblicazione su sito"
          md={3}
          validate={[minDate2('publicationFrom', 'Pubblicazione da', false, false)]}
          disabled={props.mode === 'show'}
        />
        <AppFileInput
          source="coverFile"
          accept="image/jpeg,image/png"
          label="Copertina a sito"
          previewLabel="Copertina corrente"
          previewSource="coverUrl"
          previewType="url"
          previewClassName={classes.coverFilePreview}
          md={12}
          disabled={props.mode === 'show'}
          helperText={
            props.mode === 'create'
              ? 'Se non caricata verrà utilizzata la prima pagina del magazine'
              : undefined
          }
        />
      </AppCard>
      <AppCard title="Generazione bollettino" hideContent={props.mode === 'create'}>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <Grid item md={12}>
                <AppAlert severity="info" className={classes.alert}>
                  <AppText>
                    Informazioni utili in caso di bollettino in modalità <AppBold>"GENERA"</AppBold>
                  </AppText>
                </AppAlert>
              </Grid>
              <Grid item md={12}>
                <Typography align="center">
                  <Box fontWeight="bold">Stato generazione bollettino</Box>
                  <Box display="block">
                    <Chip
                      // className={classes.chip}
                      color="primary"
                      label={(GenerationStatusText[formData.generationStatus] ?? '-').toUpperCase()}
                      classes={{ label: classes.statusChipLabel }}
                    />
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography align="center">
                  <Box fontWeight="bold">{'Tentativi (max 3)'}</Box>
                  <Box display="block">
                    <Chip
                      // className={classes.chip}
                      color="primary"
                      label={formData.generationAttempts}
                      classes={{ label: classes.otherChipLabel }}
                    />
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography align="center">
                  <Box fontWeight="bold">Data generazione</Box>
                  <Box display="block">
                    <Chip
                      // className={classes.chip}
                      color="primary"
                      label={dateFormatter(formData.generatedAt)}
                      classes={{ label: classes.otherChipLabel }}
                    />
                  </Box>
                </Typography>
              </Grid>
              <AppTextInput
                {...rest}
                source="notes"
                record={formData}
                label="Note"
                multiline
                expandable
                md={12}
              />
            </>
          )}
        </FormDataConsumer>
      </AppCard>
    </AppBaseForm>
  );
};

export default MagazineDetails;
