import React, { FC, useEffect, useState } from 'react';
import { dateFormatter } from '../../../../utils/data-formatters';
import AuctionAlert from './auction-alert';

const AuctionCompletedAlert: FC<any> = (props) => {
  // TODO: tutti questi messaggi andrebbero riportati a db in modo da poterli visualizzare anche nel momento in cui il nodo asta su firebase venisse eliminato!!
  const { reservePriceInfo } = props;

  const [detail, setDetail] = useState<string>('Non sono presenti ulteriori informazioni.');

  useEffect(() => {
    setDetail(
      reservePriceInfo.waitingForOwner
        ? 'Il prezzo di riserva non è stato raggiunto. Il proprietario ha 3 ore di tempo per accettare o rifiutare la migliore offerta'
        : reservePriceInfo.waitingForWinner
        ? `Il proprietario ha rifiutato la migliore offerta. Il miglior offerente ha tempo fino al ${
            reservePriceInfo.dueDate
              ? dateFormatter(reservePriceInfo.dueDate.toISOString())
              : '_data_ora_termine_non_disponibile_'
          } per decidere se pareggiare il prezzo di riserva o rinunciare`
        : reservePriceInfo.renounced
        ? 'Il miglior offerente ha rinunciato a pareggiare il prezzo di riserva, asta conclusa ma non aggiudicata'
        : 'Non sono presenti ulteriori informazioni.',
    );
  }, [reservePriceInfo]);

  return (
    <AuctionAlert severity="warning" title="Asta terminata ma non aggiudicata" detail={detail} />
  );
};

export default AuctionCompletedAlert;
