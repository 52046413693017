import { createStyles, SvgIconProps, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TreeItem, TreeItemProps } from '@material-ui/lab';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type StyledTreeItemProps = TreeItemProps & {
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  sidebarIsOpen: boolean;
  to?: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.main,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    labelIcon: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      lineHeight: 2.5,
    },
    link: {
      display: 'flex',
      flexGrow: 1,
      color: 'inherit',
      textDecoration: 'none',
      fontWeight: 'inherit',
    },
  }),
);

const AppMenuItem: FC<StyledTreeItemProps> = (props) => {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    labelInfo = '',
    sidebarIsOpen,
    to,
    ...other
  } = props;

  const LinkComponent = to ? Link : React.Fragment;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LinkComponent className={classes.link} to={to ?? '/'}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            {sidebarIsOpen && (
              <Typography variant="body1" className={classes.labelText}>
                {labelText}
              </Typography>
            )}
          </LinkComponent>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

export default AppMenuItem;
