import { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import ComplaintDatagrid from './ComplaintDatagrid';
import { COMPLAINT_STATUS_CHOICES, COMPLAINT_TYPE_CHOICES } from './constants';

const ComplaintListFilters = (
  <AppListFilter disableFulltextSearch>
    <AppSelectInput source="complaint_type" label="Tipo" choices={COMPLAINT_TYPE_CHOICES} md={2} />
    <AppSelectInput
      source="complaint_status"
      label="Stato"
      choices={COMPLAINT_STATUS_CHOICES}
      md={2}
    />
    <AppAutocompleteInput
      label="Lotto"
      source="saleExperiment_fkItem"
      resettable
      reference="complaints/get-items"
      optionText={(record) => {
        if (!record?.title) return '';
        return record.title;
      }}
      md={3}
    />
  </AppListFilter>
);

const ComplaintList: FunctionComponent<ListProps> = (props) => {
  return (
    <AppList {...props} title={<AppTitle title="Controversie" />} filters={ComplaintListFilters}>
      <ComplaintDatagrid rowClick="show" />
    </AppList>
  );
};

export default ComplaintList;
