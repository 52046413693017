import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import arrayMutators from 'final-form-arrays';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { Form, useForm, useFormState } from 'react-final-form';
import {
  BIDDER_RECEIVING_METHODS,
  BIDDER_RIGHTS,
  BIDDER_TITLES,
  PARTICIPANT_TYPES,
} from '../../../utils/constants';
import { AppArrayInput, AppNumberInput, AppSelectInput, AppTextInput } from '../../ui/input';
import { AppAutocompleteInput } from '../../ui/input/AppAutocompleteInput';
import AppDivider from '../../ui/layout/AppDivider';
import AppSectionTitle from '../../ui/layout/AppSectionTitle';

const useStyles = makeStyles(
  (theme) => ({
    dialog: {
      width: theme.spacing(170),
      height: theme.spacing(120),
      //marginLeft: '16rem',
      maxWidth: 'none',
    },
  }),
  { name: 'HandleBidder' },
);

interface HandleBidderProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listCtx: Record<string, any>;
  bidder?: Record<string, any>;
}

const HandleBidder: FC<HandleBidderProps> = (props) => {
  const { open, setOpen, listCtx, bidder = {} } = props;
  const notify = useNotify();
  const classes = useStyles();
  const [create] = useCreate('participants');
  //const [mutateParticipant] = useMutation();

  const { values } = useFormState();
  const { change } = useForm();

  const [selectedBidder, setSelectedBidder] = useState<Record<string, any>>({});
  const [selectedBidderType, setSelectedBidderType] = useState<string>(bidder.type ?? 'person');

  const isCreating = useMemo(() => Object.keys(bidder).length < 1, [bidder]);
  useEffect(() => {
    if (!isCreating) return;

    setSelectedBidder({});
    setSelectedBidderType('person');
  }, [isCreating]);

  const submit = (formValues, bidderId) => {
    const { right, title, receivingMethod, share, ...participantData } = formValues;

    if (isCreating) {
      // ADD EXISTING PARTICIPANT AS BIDDER OR CREATE ONE FIRST
      if (!bidderId && !selectedBidder?.id) {
        // create new participant
        create(
          {
            payload: {
              data: participantData,
            },
          },
          {
            onSuccess: (res) => {
              // update bidders
              change('bidders', [
                ...values.bidders,
                { ...res.data, right, title, receivingMethod, share },
              ]);
              notify('Offerente aggiunto con successo.');
              setOpen(false);
            },
            onFailure: (err) => {
              notify(err.message, 'error');
              console.error(err);
            },
          },
        );
      } else {
        // add participant as bidder
        // check if participant id already exists in list
        if (!listCtx.ids.includes(selectedBidder.id)) {
          // check if added bidder is presenter too
          if (selectedBidder.id === values.fkPresenter) {
            change('presenterIsBidder', true);
            selectedBidder.isPresenter = true;
          }

          // update bidders
          change('bidders', [
            ...values.bidders,
            { ...selectedBidder, right, title, receivingMethod, share },
          ]);

          notify('Offerente aggiunto con successo.');
          setOpen(false);
        } else {
          notify('La persona scelta è già presente tra gli offerenti.', 'error');
        }
      }
    } else {
      // EDIT BIDDER
      const bidderToUpdateIndex: number = values.bidders.findIndex(
        (bidder) => bidder.id === formValues.id,
      );
      if (bidderToUpdateIndex < 0) {
        notify("Impossibile aggiornare l'offerente.", 'error');
        return;
      }

      // Update on db as well? I don't think it is ok, for now I update locally
      // mutateParticipant({
      //   type: 'update',
      //   resource: `participants/${formValues.id}`,
      //   payload: { data: participantData }
      // }, {
      //   onSuccess: res => {
      //     change(`bidders[${bidderToUpdateIndex}]`, { ...res.data, right, title, receivingMethod, share });
      //     notify('Offerente aggiornato con successo.');
      //     setOpen(false);
      //   },
      //   onFailure: err => {
      //       notify(err.message, 'error');
      //       console.error(err);
      //   }
      // })

      change(`bidders[${bidderToUpdateIndex}]`, formValues);
      notify('Offerente aggiornato con successo.');
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        {isCreating ? 'Scegli un nuovo offerente' : 'Modifica dati offerente'}
      </DialogTitle>
      <Form
        initialValues={bidder}
        onSubmit={(values) => submit(values, bidder?.id)}
        mutators={arrayMutators as any}
        render={(formProps) => {
          const { handleSubmit, submitting, pristine } = formProps;
          return (
            <React.Fragment>
              <DialogContent>
                <Grid container spacing={2}>
                  {isCreating && (
                    <AppAutocompleteInput
                      md={12}
                      xs={6}
                      variant="outlined"
                      label="Offerente"
                      reference="participants"
                      filter={{
                        blacklist: false,
                      }}
                      optionText={(option) => {
                        if (!option) return '';
                        return `${option.lastName} ${option.firstName} (${option.taxCode ?? '-'})`;
                      }}
                      source="id"
                      resettable
                      onChange={(bidderId) => (bidderId as any).length < 1 && setSelectedBidder({})}
                      onSelect={(selectedBidder) => setSelectedBidder(selectedBidder)}
                      suggestionLimit={5}
                    />
                  )}
                  <AppDivider />
                  {((isCreating && !selectedBidder.id) || !isCreating) && (
                    <React.Fragment>
                      <Grid item md={12} hidden={!isCreating}>
                        <Alert severity="info">
                          <Typography>
                            Se non trovi l'anagrafica desiderata, aggiungila compilando i campi
                            richiesti
                          </Typography>
                        </Alert>
                      </Grid>
                      <AppSelectInput
                        source="type"
                        label="Tipologia anagrafica"
                        choices={PARTICIPANT_TYPES}
                        onChange={(e) => setSelectedBidderType(e.target.value)}
                        required
                        defaultValue={selectedBidderType}
                        md={12}
                      />
                      <AppSectionTitle>Anagrafica</AppSectionTitle>
                      <AppTextInput
                        source="firstName"
                        label="Nome"
                        variant="outlined"
                        required
                        md={4}
                      />
                      <AppTextInput
                        source="lastName"
                        label="Cognome"
                        variant="outlined"
                        required
                        md={4}
                      />
                      <AppTextInput
                        source="taxCode"
                        label="Codice fiscale"
                        variant="outlined"
                        required
                        md={4}
                      />
                      <AppDivider />
                      <AppSectionTitle>Contatti anagrafica</AppSectionTitle>
                      <AppTextInput source="email" type="email" label="Email" variant="outlined" />
                      <AppTextInput source="pec" type="email" label="PEC" variant="outlined" />
                      <AppDivider />
                      <AppArrayInput source="phones" label="Numeri di telefono">
                        <AppTextInput source="name" label="Nome" />
                        <AppTextInput source="number" label="Numero" />
                      </AppArrayInput>
                      <AppDivider />
                      <AppArrayInput source="mobiles" label="Numeri di cellulare">
                        <AppTextInput source="name" label="Nome" />
                        <AppTextInput source="number" label="Numero" />
                      </AppArrayInput>
                      {selectedBidderType === 'company' && (
                        <>
                          <AppDivider />
                          <AppSectionTitle>Informazioni azienda</AppSectionTitle>
                          <AppTextInput source="businessName" label="Ragione sociale" />
                          <AppTextInput source="vat" label="Partita IVA" />
                          <AppTextInput
                            source="sdiCode"
                            label="Codice SDI"
                            defaultValue="0000000"
                            md={3}
                          />
                          <AppTextInput source="registeredOffice" label="Sede legale" md={3} />
                          <AppTextInput
                            source="roleInsideCompany"
                            label="Ruolo adottato nell'azienda"
                            md={3}
                          />
                        </>
                      )}
                      <AppDivider />
                    </React.Fragment>
                  )}
                  <Grid item md={12}>
                    <Alert severity="info">
                      <Typography>Informazioni aggiuntive sull'offerente</Typography>
                    </Alert>
                  </Grid>
                  <AppSelectInput
                    source="right"
                    label="Diritto"
                    choices={BIDDER_RIGHTS}
                    variant="outlined"
                    required
                  />
                  <AppSelectInput
                    source="title"
                    label="Titolo"
                    choices={BIDDER_TITLES}
                    variant="outlined"
                    required
                  />
                  <AppSelectInput
                    source="receivingMethod"
                    label="Metodo di ricezione"
                    choices={BIDDER_RECEIVING_METHODS}
                    variant="outlined"
                    required
                  />
                  <AppNumberInput
                    source="share"
                    label="Quota"
                    variant="outlined"
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid container>
                  <Grid item md={2}>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      disabled={submitting || pristine}
                    >
                      {isCreating ? 'Inserisci' : 'Aggiorna'}
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                      Annulla
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          );
        }}
      />
    </Dialog>
  );
};

export default HandleBidder;
