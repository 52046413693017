import PagesIcon from '@material-ui/icons/Description';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import PageDetails from './PageDetails';
import PageList from './PageList';

export const PAGE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Page: ResourceExport = {
  list: PageList,
  create: resourceWrapper('create', PageDetails, undefined, undefined, PAGE_PERMISSIONS),
  edit: resourceWrapper('edit', PageDetails, undefined, undefined, PAGE_PERMISSIONS),
  show: resourceWrapper('show', PageDetails, undefined, undefined, PAGE_PERMISSIONS),
  icon: PagesIcon,
};
export default Page;
