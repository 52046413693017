import { makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { Children, FC } from 'react';
import { FunctionField, ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppColorField, AppSelectField, AppTextField } from '../../Components/ui/field';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { ITEM_GENRES, MAGAZINE_KINDS, MAGAZINE_TEXT_ALIGNMENTS } from '../../utils/constants';
import { MAGAZINE_COVER_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(30),
    },
    titleColor: {
      minWidth: theme.spacing(25),
    },
    genre: {
      minWidth: theme.spacing(30),
    },
    kind: {
      minWidth: theme.spacing(30),
    },
    titleX: {
      minWidth: theme.spacing(30),
    },
    titleY: {
      minWidth: theme.spacing(30),
    },
    titleWidth: {
      minWidth: theme.spacing(30),
    },
    titleHeight: {
      minWidth: theme.spacing(30),
    },
    titleFontSize: {
      minWidth: theme.spacing(30),
    },
    titleAlignment: {
      minWidth: theme.spacing(30),
    },
    subtitleX: {
      minWidth: theme.spacing(30),
    },
    subtitleY: {
      minWidth: theme.spacing(30),
    },
    subtitleWidth: {
      minWidth: theme.spacing(30),
    },
    subtitleHeight: {
      minWidth: theme.spacing(30),
    },
    subtitleFontSize: {
      minWidth: theme.spacing(30),
    },
    editionX: {
      minWidth: theme.spacing(30),
    },
    editionY: {
      minWidth: theme.spacing(30),
    },
    editionWidth: {
      minWidth: theme.spacing(30),
    },
    editionHeight: {
      minWidth: theme.spacing(30),
    },
    editionFontSize: {
      minWidth: theme.spacing(30),
    },
    yearX: {
      minWidth: theme.spacing(30),
    },
    yearY: {
      minWidth: theme.spacing(30),
    },
    yearWidth: {
      minWidth: theme.spacing(30),
    },
    yearHeight: {
      minWidth: theme.spacing(30),
    },
    yearFontSize: {
      minWidth: theme.spacing(30),
    },
  }),
  { name: 'MagazineCoverList' },
);

const MagazineCoverListHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell colSpan={4}></TableCell>
      <TableCell colSpan={6}>Titolo</TableCell>
      <TableCell colSpan={5}>Sottotitolo</TableCell>
      <TableCell colSpan={5}>Edizione</TableCell>
      <TableCell colSpan={5}>Anno</TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow>
      {Children.map(children, (child) => (
        <TableCell key={child.props.label}>{child.props.label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const MagazineCoverList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Copertine" />}
      resourcePermissions={MAGAZINE_COVER_PERMISSIONS}
    >
      <AppDatagrid rowClick="show" header={<MagazineCoverListHeader children={null} />}>
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <FunctionField
          label="Colore titolo"
          source="titleColor"
          render={(record) => <AppColorField color={record.titleColor} />}
          headerClassName={classes.titleColor}
        />
        <AppSelectField
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          headerClassName={classes.genre}
        />
        <AppSelectField
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo"
          headerClassName={classes.kind}
        />
        <AppTextField source="titleX" label="X" headerClassName={classes.titleX} />
        <AppTextField source="titleY" label="Y" headerClassName={classes.titleY} />
        <AppTextField source="titleWidth" label="Larghezza" headerClassName={classes.titleWidth} />
        <AppTextField source="titleHeight" label="Altezza" headerClassName={classes.titleHeight} />
        <AppTextField
          source="titleFontSize"
          label="Testo"
          headerClassName={classes.titleFontSize}
        />
        <FunctionField
          label="Allineamento"
          render={(record) =>
            record.genre === 'real-estate' ? (
              <AppSelectField
                record={record}
                source="titleAlignment"
                choices={MAGAZINE_TEXT_ALIGNMENTS}
                label="Allineamento"
                headerClassName={classes.titleAlignment}
              />
            ) : (
              <AppTextField label="Allineamento" headerClassName={classes.titleAlignment} />
            )
          }
        />
        <FunctionField
          label="X"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="subtitleX"
                label="X"
                headerClassName={classes.subtitleX}
              />
            ) : (
              <AppTextField label="X" headerClassName={classes.subtitleX} />
            )
          }
        />
        <FunctionField
          label="Y"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="subtitleY"
                label="Y"
                headerClassName={classes.subtitleY}
              />
            ) : (
              <AppTextField label="Y" headerClassName={classes.subtitleY} />
            )
          }
        />
        <FunctionField
          label="Larghezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="subtitleWidth"
                label="Larghezza"
                headerClassName={classes.subtitleWidth}
              />
            ) : (
              <AppTextField label="Larghezza" headerClassName={classes.subtitleWidth} />
            )
          }
        />
        <FunctionField
          label="Altezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="subtitleHeight"
                label="Altezza"
                headerClassName={classes.subtitleHeight}
              />
            ) : (
              <AppTextField label="Altezza" headerClassName={classes.subtitleHeight} />
            )
          }
        />
        <FunctionField
          label="Testo"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="subtitleFontSize"
                label="Testo"
                headerClassName={classes.subtitleFontSize}
              />
            ) : (
              <AppTextField label="Testo" headerClassName={classes.subtitleFontSize} />
            )
          }
        />
        <FunctionField
          label="X"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="editionX"
                label="X"
                headerClassName={classes.editionX}
              />
            ) : (
              <AppTextField label="X" headerClassName={classes.editionX} />
            )
          }
        />
        <FunctionField
          label="Y"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="editionY"
                label="Y"
                headerClassName={classes.editionY}
              />
            ) : (
              <AppTextField label="Y" headerClassName={classes.editionY} />
            )
          }
        />
        <FunctionField
          label="Larghezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="editionWidth"
                label="Larghezza"
                headerClassName={classes.editionWidth}
              />
            ) : (
              <AppTextField label="Larghezza" headerClassName={classes.editionWidth} />
            )
          }
        />
        <FunctionField
          label="Altezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="editionHeight"
                label="Altezza"
                headerClassName={classes.editionHeight}
              />
            ) : (
              <AppTextField label="Altezza" headerClassName={classes.editionHeight} />
            )
          }
        />
        <FunctionField
          label="Testo"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="editionFontSize"
                label="Testo"
                headerClassName={classes.editionFontSize}
              />
            ) : (
              <AppTextField label="Testo" headerClassName={classes.editionFontSize} />
            )
          }
        />

        <FunctionField
          label="X"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="yearX"
                label="X"
                headerClassName={classes.yearX}
              />
            ) : (
              <AppTextField label="X" headerClassName={classes.yearX} />
            )
          }
        />
        <FunctionField
          label="Y"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="yearY"
                label="Y"
                headerClassName={classes.yearY}
              />
            ) : (
              <AppTextField label="Y" headerClassName={classes.yearY} />
            )
          }
        />
        <FunctionField
          label="Larghezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="yearWidth"
                label="Larghezza"
                headerClassName={classes.yearWidth}
              />
            ) : (
              <AppTextField label="Larghezza" headerClassName={classes.yearWidth} />
            )
          }
        />
        <FunctionField
          label="Altezza"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="yearHeight"
                label="Altezza"
                headerClassName={classes.yearHeight}
              />
            ) : (
              <AppTextField label="Altezza" headerClassName={classes.yearHeight} />
            )
          }
        />
        <FunctionField
          label="Testo"
          render={(record) =>
            record.genre === 'movable' ? (
              <AppTextField
                record={record}
                source="yearFontSize"
                label="Testo"
                headerClassName={classes.yearFontSize}
              />
            ) : (
              <AppTextField label="Testo" headerClassName={classes.yearFontSize} />
            )
          }
        />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default MagazineCoverList;
