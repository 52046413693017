import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC, Fragment, useEffect, useState } from 'react';
import { Record as RaRecord, useMutation } from 'react-admin';
import { useHistory } from 'react-router';
import { useBasePath } from '../../hooks/use-base-path';
import { SaleStatus } from '../../utils/constants';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { DetailPanelField, DetailPanelTitle } from '../detail-panel';
import { AppText } from '../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      margin: theme.spacing(1),
    },
    winner: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
  { name: 'ClosedInfo' },
);

interface ClosedInfoProps {
  item: RaRecord;
}

export const ClosedInfo: FC<ClosedInfoProps> = ({ item }) => {
  // TODO: handle deserted/postponed/settled

  const classes = useStyles();
  const history = useHistory();

  const basePath = useBasePath();

  const [closedInfo, setClosedInfo] = useState<Record<string, any>>();

  const [mutateClosedInfo, { loading: loadingClosedInfo }] = useMutation();
  const getClosedInfo = () =>
    mutateClosedInfo(
      {
        type: 'getOne',
        resource: `${basePath}/${item.id}/sale-experiments/closed-info`,
        payload: {},
      },
      {
        onSuccess: (res) => setClosedInfo(res.data),
        onFailure: (err) => {
          console.error(err);
        },
      },
    );

  useEffect(() => {
    item.fkSaleStatus === SaleStatus.ClosedAndAwarded && getClosedInfo();
  }, []);

  const [mutateWinner, { loading: loadingWinner }] = useMutation();
  const [winner, setWinner] = useState<Record<string, any>>();
  const getWinner = (winnerId: number): void => {
    mutateWinner(
      {
        type: 'getOne',
        resource: 'participations',
        payload: {
          id: winnerId,
        },
      },
      {
        onSuccess: (res) => setWinner(res.data),
        onFailure: (err) => console.error(err),
      },
    );
  };

  useEffect(() => {
    closedInfo && closedInfo.winner && getWinner(closedInfo.winner);
  }, [closedInfo]);

  return (
    <Grid
      container
      className={classes.container}
      spacing={1}
      // alignItems="center"
      // justify="space-between"
    >
      {closedInfo && closedInfo.awardPrice ? (
        <Fragment>
          <DetailPanelTitle variant="h5" children="Informazioni sulla vendita" />
          <DetailPanelField
            label="Prezzo di vendita"
            value={amountFormatter(closedInfo.awardPrice)}
            md={3}
          />
          {!!closedInfo.auctionClosedAt && (
            <DetailPanelField
              label="Data/ora fine gara"
              value={dateFormatter(closedInfo.auctionClosedAt)}
              md={3}
            />
          )}
          <DetailPanelField
            label="Vincitore"
            value={
              winner
                ? `${winner.presenter.lastName} ${winner.presenter.firstName} (${
                    winner.presenter.taxCode ?? '-'
                  })`
                : loadingWinner
                ? 'Caricamento in corso...'
                : 'Non indicato'
            }
            onClick={() =>
              winner &&
              history.push(
                `/participants/${winner.presenter.id}/show?goBack=true&resourceLabel=Lotto&previousResourcePath=${history.location.pathname}`,
              )
            }
            className={classes.winner}
          />
        </Fragment>
      ) : loadingClosedInfo ? (
        <AppText children="Caricamento esito gara..." />
      ) : (
        <Fragment />
      )}
    </Grid>
  );
};
