import { makeStyles } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import React, { FC, ReactElement } from 'react';
import { DeleteWithConfirmButton, SelectField } from 'react-admin';
import UploadMediaButton from '../../Components/media/upload/upload-media-button';
import UploadMediaManager from '../../Components/media/upload/upload-media-manager';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppButtonGroup from '../../Components/ui/button/AppButtonGroup';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDownloadField from '../../Components/ui/field/AppDownloadField';
import AppMediaAdditionalFields from '../../Components/ui/field/AppMediaAdditionalFields';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppTextInput } from '../../Components/ui/input';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { MEDIA_GENRES } from '../../utils/constants';
import { DECREE_PERMISSIONS } from './index';

const useMediaDgStyles = makeStyles(
  (theme) => ({
    fileNameAndTitle: {
      minWidth: theme.spacing(30),
    },
    additionalFields: {
      minWidth: theme.spacing(40),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'DecreeDetailsMediaDatagrid' },
);

const DecreeDetails: FC<any> = (props): ReactElement => {
  const mediaDgClasses = useMediaDgStyles();
  return (
    <AppTabbedForm
      {...props}
      disabledCards={!hasViewPermissions(props.permissions.loginRole, 'create', DECREE_PERMISSIONS)}
    >
      <AppFormTab label="Parametri" disabledCards={props.mode === 'show'}>
        <AppCard collapsable={false}>
          <AppTextInput
            source="title"
            reference="title"
            label="Titolo"
            variant="outlined"
            md={12}
            required
          />
          <AppTextInput
            source="description"
            reference="description"
            label="Descrizione"
            md={12}
            required
            multiline
            expandable
          />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Media" path="media" disabledCards={props.mode === 'show'} maxWidth={false}>
        <AppCard expanded hideContent={props.mode === 'create'} title="Gestione media">
          <AppRelatedResource
            relatedResource="/media"
            sort={{} /* Managed by Backend */}
            manager={<UploadMediaManager mainResource="decree" />}
            actions={[
              <AppButtonGroup title="Documenti" icon={<Description />}>
                <UploadMediaButton mediaType="document" label="Caricamento" />
              </AppButtonGroup>,
            ]}
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <AppDownloadField
                source="name"
                label="Nome file"
                sort
                sortable={false}
                headerClassName={mediaDgClasses.fileNameAndTitle}
              />
              <AppTextField
                source="description"
                label="Titolo"
                sortable={false}
                headerClassName={mediaDgClasses.fileNameAndTitle}
                truncate
              />
              <AppMediaAdditionalFields
                sortable={false}
                people={[]}
                label="Dati aggiuntivi"
                headerClassName={mediaDgClasses.additionalFields}
              />
              <SelectField
                choices={MEDIA_GENRES}
                label="Genere"
                source="role.type"
                sortable={false}
              />
              <AppTextField
                source="role.name"
                label="Tipologia"
                sortable={false}
                headerClassName={mediaDgClasses.lastCol}
              />
              <UploadMediaButton
                label="Modifica"
                mode="edit"
                mainResource="items"
                disabled={props.mode === 'show'}
              />
              <DeleteWithConfirmButton
                disabled={props.mode === 'show'}
                redirect={false}
                confirmTitle="Eliminazione allegato"
                confirmContent="Sei sicuro di voler procedere con la cancellazione?"
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default DecreeDetails;
