import { makeStyles } from '@material-ui/core';
import { FC, Fragment } from 'react';
import {
  BooleanField,
  Button,
  Identifier,
  ListProps,
  SelectField,
  useListContext,
  useMutation,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import {
  AppTextInput,
  AppAutocompleteInput,
  AppDateInput,
  AppNumberInput,
} from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import { AUCTION_STATUS } from '../../utils/constants';
import PublishIcon from '@material-ui/icons/Publish';
import { AppLinkToEntityField } from '../../Components/ui/field';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';

const useStyles = makeStyles(
  (theme) => ({
    code: {
      minWidth: theme.spacing(30),
    },
    title: {
      minWidth: theme.spacing(40),
    },
    procedureName: {
      minWidth: theme.spacing(20),
    },
    published: {
      minWidth: theme.spacing(20),
    },
    auctionStatus: {
      minWidth: theme.spacing(20),
    },
    dates: {
      whiteSpace: 'nowrap',
    },
    price: {
      minWidth: theme.spacing(20),
    },
    notes: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    buttons: {
      whiteSpace: 'nowrap',
    },
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'MassivePublicationBuyNowList' },
);

const PublicationButton = (props) => {
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(resource);

  const notify = useNotify();
  const [addPublications] = useMutation();
  const handleAddPublications = async (itemIds: Identifier[]) => {
    await addPublications(
      {
        type: 'create',
        resource: 'massive-publications-buy-now',
        payload: {
          data: {
            itemIds: itemIds.map((id) => Number(id)),
            site: true,
          },
        },
      },
      {
        onSuccess: () => {
          unselectAll();
          notify('Lotti inviati per la pubblicazione', 'info');
        },
        onFailure: (err) => {
          notify(err, 'error');
          console.error(err);
        },
      },
    );
  };

  return (
    <Button label={props.label} onClick={() => handleAddPublications(selectedIds)}>
      <PublishIcon />
    </Button>
  );
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <PublicationButton label="Pubblica sul sito e ECWID" site {...props} />
  </Fragment>
);

const ItemListFilters = (
  <AppListFilter>
    <AppAutocompleteInput
      label="Pubblicato sul sito"
      source="publishedOnSite"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
    />
    <AppAutocompleteInput
      label="Pubblicato su ECWID"
      source="publishedOnEcwid"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
    />
    <AppAutocompleteInput
      reference="pvp/rites"
      source="folder_fkPvpRite"
      label="Rito"
      optionText="description"
      resettable
      suggestionLimit={5}
    />
    <AppTextInput source="procedureCode" label="Numero procedura" md={4} />
    <AppDateInput source="buyItNowStartFrom" label="Inizio asta dal..." md={3} />
    <AppDateInput source="buyItNowStartTo" label="Inizio asta al..." md={3} />
    <AppDateInput source="buyItNowEndFrom" label="Termine asta dal..." md={3} />
    <AppDateInput source="buyItNowEndTo" label="Termine asta al..." md={3} />
    <AppAutocompleteInput
      label="Stato attivo"
      source="saleActiveStatus"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
      suggestionLimit={2}
    />
    <AppNumberInput label="Prezzo al pezzo maggiore di" source="priceApiece" md={3} />
  </AppListFilter>
);

const MassivePublicationBuyNowList: FC<ListProps> = (props) => {
  const classes = useStyles();
  const MASSIVE_PUBLICATIONS_ENABLED =
    process.env.REACT_APP_MASSIVE_PUBLICATIONS_ENABLED === 'true';

  return MASSIVE_PUBLICATIONS_ENABLED ? (
    <AppList
      {...props}
      title={<AppTitle title="Pubblicazione massiva sul sito e Ecwid" />}
      filters={ItemListFilters}
      bulkActionButtons={<PostBulkActionButtons />}
      hideCreateButton
    >
      <AppDatagrid
        isRowSelectable={(r) =>
          !r.publishedOnSite ||
          (!r.publishedOnEcwid && (r.handledStockQuantity === 0 || r.allStocksHandled === true))
        }
        resource="publications"
      >
        <AppTextField
          source="relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={classes.code}
        />
        <AppTextField source="code" label="Codice lotto" headerClassName={classes.code} />
        <AppTextField source="title" label="Nome lotto" headerClassName={classes.title} />
        <BooleanField
          source="publishedOnSite"
          label="Pubblicato sul sito"
          headerClassName={classes.published}
        />
        <BooleanField
          source="publishedOnEcwid"
          label="Pubblicato su Ecwid"
          headerClassName={classes.published}
        />
        <AppDateField
          source="currentSaleExperiment.buyItNowStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={classes.dates}
        />
        <AppDateField
          source="currentSaleExperiment.buyItNowEndAt"
          label="Data/ora termine asta"
          showTime
          headerClassName={classes.dates}
        />
        <AppTextField
          source="currentSaleExperiment.priceApiece"
          label="Prezzo al pezzo"
          type="amount"
          headerClassName={classes.price}
        />
        <SelectField
          source="currentSaleExperiment.auctionStatus"
          label="Stato asta"
          choices={AUCTION_STATUS}
          headerClassName={classes.auctionStatus}
        />
        <AppTextField source="notes" label="Note" truncate headerClassName={classes.notes} />

        <AppLinkToEntityField
          label="Link al lotto"
          targetResource="items"
          originName="massivePublication"
          value="Link"
          targetResourceId={(r) => r.id}
          truncate
          maxTextLength={60}
        />
      </AppDatagrid>
    </AppList>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità della "Pubblicazione massiva" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default MassivePublicationBuyNowList;
