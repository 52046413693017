import { Box, Button, Theme, Typography, makeStyles } from '@material-ui/core';
import { Cancel, Delete } from '@material-ui/icons';
import { FC, useContext } from 'react';
import { useToggleState } from '../../hooks/use-toggle-state';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppConfirmDialog from '../ui/dialog/confirm-dialog/app-confirm-dialog';
import AppTooltip from '../ui/tooltip/AppTooltip';

const useStyles = makeStyles<Theme, { selectedMedia: number[] }>(
  (theme) => ({
    container: (props) => ({
      width: '100%',
      color: '#007bff',
      height: '50px',
      zIndex: 3,
      minHeight: '50px',
      transition:
        'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      justifyContent: 'space-between',
      backgroundColor: 'rgb(224, 239, 255)',
      position: 'relative',
      alignItems: 'center',
      px: '18px',
      display: 'flex',
      ...(props.selectedMedia.length < 1 && {
        minHeight: 0,
        height: 0,
        overflowY: 'hidden',
      }),
    }),
    contentContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'MediaHeader' },
);

interface MediaHeaderProps {
  selectedMedia: number[];
  clearSelectedMedia: () => void;
}

export const MediaHeader: FC<MediaHeaderProps> = ({ selectedMedia, clearSelectedMedia }) => {
  const classes = useStyles({ selectedMedia });
  const ctx = useContext(AppRelatedResourceContext);

  const [confirmDialogOpen, toggleConfirmDialog] = useToggleState();

  const onDeleteSelectedMedia = () => {
    ctx.manager.removeMany(selectedMedia, undefined, () => {
      clearSelectedMedia();
      toggleConfirmDialog();
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <AppTooltip title="Annulla">
          <Button children={<Cancel />} onClick={clearSelectedMedia} />
        </AppTooltip>
        <Typography>
          {selectedMedia.length} media {selectedMedia.length === 1 ? 'selezionato' : 'selezionati'}
        </Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <AppTooltip title="Cancella i media selezionati">
          <Button
            children="Cancella selezionati"
            startIcon={<Delete />}
            className={classes.cancelButton}
            onClick={toggleConfirmDialog}
          />
        </AppTooltip>
      </Box>
      <AppConfirmDialog
        open={confirmDialogOpen}
        onClose={toggleConfirmDialog}
        onConfirm={onDeleteSelectedMedia}
        title="Eliminazione media selezionati"
        details="Attenzione! I media selezionati saranno eliminati definitivamente."
      />
    </Box>
  );
};
