import { Button, Grid, makeStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import AppCard from '../../ui/card/AppCard';
import { AppTextInput } from '../../ui/input';

const useStyles = makeStyles(
  () => ({
    authButton: {
      backgroundColor: green[500],
      color: 'black',
      fontWeight: 'bold',
      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'black',
      },
    },
    rejectButton: {
      backgroundColor: red[500],
      color: 'black',
      fontWeight: 'bold',
      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'black',
      },
    },
  }),
  { name: 'AuthorizationButtons' },
);

export const AuthorizationButtons: FC<any> = React.memo(
  ({ exprOfInterestOrOffCollection, observer, actionsDisabled, isAsync }) => {
    const classes = useStyles();

    const { values } = useFormState();
    const { change } = useForm();

    const onAuthorizedChange = (authorize: boolean): void => {
      change('authorized', authorize);
      change('pending', false);
      change('hidden', Math.random() * 1000); // Used to change "pristine"'s form status (doesn't change for some reason...) and activate "save" button
    };

    return (
      <AppCard title="Gestione autorizzazione" expanded hidden={exprOfInterestOrOffCollection}>
        <Grid item md={2}>
          <Button
            children={values.authorized ? 'Autorizzato' : 'Autorizza'}
            disabled={values.authorized || (!isAsync && actionsDisabled)}
            onClick={() => onAuthorizedChange(true)}
            variant="outlined"
            className={values.authorized ? classes.authButton : ''}
          />
        </Grid>
        <Grid item md={2}>
          <Button
            children={!values.pending && !values.authorized ? 'Rifiutato' : 'Rifiuta'}
            disabled={(!values.pending && !values.authorized) || (!isAsync && actionsDisabled)}
            variant="outlined"
            onClick={() => onAuthorizedChange(false)}
            className={!values.pending && !values.authorized ? classes.rejectButton : ''}
          />
        </Grid>

        <AppTextInput source="hidden" style={{ display: 'none' }} />

        {!values.pending && !values.authorized && (
          <AppTextInput
            source="notes"
            label="Motivazione rifiuto"
            required
            variant="outlined"
            multiline
            expandable
            rows={3}
            md={12}
          />
        )}
        {observer && (
          <AppTextInput
            source="observerReason"
            label="Motivazione dell'osservatore"
            multiline
            expandable
            rows={3}
            md={12}
            required
          />
        )}
      </AppCard>
    );
  },
);
