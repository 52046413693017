import { Button } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import _get from 'lodash/get';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, usePermissions } from 'react-admin';
import { User } from '../../../types/user.type';
import { getPublicationUrl } from '../../../utils/publications';
import { getReference } from '../../../utils/reference-selector';
import AppButtonGroup from './AppButtonGroup';
import AppGoToResourceButton from './AppGoToResourceButton';

/*
const useStyles = makeStyles(
  () => ({
    button: {
      width: theme.spacing(100),
      color: 'red',
    },
  }),
  { name: 'AppLinkToItemButton' },
);
*/

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

interface AppItemLinksButtonProps {
  resource: string;
  className?: string;
  currentResourceLabel?: string;
  record?: Record<string, any>;
  folderIdSource?: string;
  itemIdSource?: string;
  saleExpIdSource?: string;
  size?: 'small' | 'medium' | 'large';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'contained' | 'outlined';
  forceJudiciaryTargetResource?: boolean;
  historySaleId?: number;
}

/**
 * Used to show a list of links:
 * - Link to BE' Item
 * - Link to BE's Folder
 * - One link for each active publication of the item
 */
const AppItemLinksButton: FC<AppItemLinksButtonProps> = React.memo(
  ({
    resource,
    className,
    currentResourceLabel,
    record,
    folderIdSource = '',
    itemIdSource = '',
    saleExpIdSource = '',
    size,
    color = 'primary',
    variant = 'outlined',
    forceJudiciaryTargetResource = false,
    historySaleId,
  }) => {
    const { permissions: user } = usePermissions<User>();

    const folderId = useMemo(() => _get(record, folderIdSource), [record, folderIdSource]);
    const itemId = useMemo(() => _get(record, itemIdSource), [record, itemIdSource]);
    const saleExpId = useMemo(() => _get(record, saleExpIdSource), [record, saleExpIdSource]);

    // const classes = useStyles();
    const goToFe = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, url: string) => {
      e.stopPropagation();
      window.open(url, '_blank');
    };

    /// region GET ACTIVE PUBLICATIONS AND THIRD LEVEL

    const [publications, setPublications] = useState<Record<string, any>[]>([]);
    const [thirdLevel, setThirdLevel] = useState<Record<string, any> | null>(null);

    const [mutate] = useMutation();
    useEffect(() => {
      if (!itemId) return;

      mutate(
        {
          type: 'getList',
          resource: `items/${itemId}/publications`,
          payload: {
            filter: {
              isPublished: true,
            },
          },
        },
        {
          onSuccess: (res) => {
            setPublications(res.data);
          },
          onFailure: (err) => {
            console.error(err);
            setPublications([]);
          },
        },
      );

      if (!IS_IVG) {
        mutate(
          {
            type: 'getOne',
            resource: `items/${itemId}/third-level`,
            payload: {},
          },
          {
            onSuccess: (res) => {
              setThirdLevel(res.data);
            },
            onFailure: (err) => {
              console.error(err);
              setThirdLevel(null);
            },
          },
        );
      }
    }, [itemId]);

    /// endregion

    return (
      <AppButtonGroup
        title="VISITA DETTAGLI"
        className={className}
        icon={<CallMadeIcon />}
        variant={variant}
        size={size}
        color={color}
      >
        {/* GO TO SALE EXPERIMENT */}
        {(!resource.includes('sale-experiments') || resource.includes('calendar')) &&
          !!saleExpId && (
            <AppGoToResourceButton
              destinationResourceName={
                forceJudiciaryTargetResource
                  ? 'sale-experiments-judiciary'
                  : getReference(resource, 'sale-experiments')
              }
              mode="show"
              destinationResourceId={saleExpId}
              buttonLabel="Scheda VENDITA"
              currentResourceLabel={currentResourceLabel}
              variant="outlined"
            />
          )}

        {/* GO TO ITEM */}
        {!resource.includes('items') && !!itemId && (
          <AppGoToResourceButton
            destinationResourceName={
              forceJudiciaryTargetResource ? 'items-judiciary' : getReference(resource, 'items')
            }
            mode="show"
            destinationResourceId={itemId}
            buttonLabel="Scheda LOTTO"
            currentResourceLabel={currentResourceLabel}
            variant="outlined"
          />
        )}

        {/* GO TO FOLDER */}
        {!user?.agency && !resource.includes('folders') && !!folderId && (
          <AppGoToResourceButton
            destinationResourceName={
              forceJudiciaryTargetResource ? 'folders-judiciary' : getReference(resource, 'folders')
            }
            mode="show"
            destinationResourceId={folderId}
            buttonLabel="Scheda FASCICOLO"
            currentResourceLabel={currentResourceLabel}
            variant="outlined"
          />
        )}

        {/* GO TO FE'S PUBLICATIONS */}
        {publications.map((pub) => {
          if (IS_IVG) {
            return (
              <Button
                children={pub.property.name}
                variant="outlined"
                onClick={(e) => goToFe(e, getPublicationUrl(pub as any, historySaleId))}
                size="small"
              />
            );
          }

          if (thirdLevel && pub.property.name === 'Casa delle Aste') {
            const cdaUrl = getPublicationUrl(pub as any, historySaleId);
            const thirdLevelUrl = new URL(cdaUrl);
            thirdLevelUrl.hostname = `${thirdLevel.host}.${thirdLevelUrl.hostname}`;

            return [
              <Button
                children={pub.property.name}
                variant="outlined"
                onClick={(e) => goToFe(e, cdaUrl)}
                size="small"
              />,
              <Button
                children={thirdLevel.agencyName}
                variant="outlined"
                onClick={(e) => goToFe(e, thirdLevelUrl.toString())}
                size="small"
              />,
            ];
          }

          return (
            <Button
              children={pub.property.name}
              variant="outlined"
              onClick={(e) => goToFe(e, getPublicationUrl(pub as any, historySaleId))}
              size="small"
            />
          );
        })}
      </AppButtonGroup>
    );
  },
);

export default AppItemLinksButton;
