import React, { FC, Fragment } from 'react';
import { FormRenderProps } from 'react-final-form';
import { AdvertisementOutcomeType } from '../../../../Resources/SaleExperiment/enums';
import {
  AppAutocompleteInput,
  AppDateTimeInput,
  AppNumberInput,
  AppTextInput,
} from '../../../ui/input';
import AppDivider from '../../../ui/layout/AppDivider';
import AppSectionTitle from '../../../ui/layout/AppSectionTitle';

interface AdvertisementOutcomeFormSoldProps {
  formProps?: FormRenderProps<any, Partial<any>>;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
}

export const AdvertisementOutcomeFormSold: FC<AdvertisementOutcomeFormSoldProps> = ({
  formProps,
  variant,
}) => {
  if (!formProps) return <Fragment />;

  const { values } = formProps;

  if (values['outcomeType'] !== AdvertisementOutcomeType.Sold) return <Fragment />;

  return (
    <>
      <AppDivider />
      <AppSectionTitle>Dati della vincita</AppSectionTitle>
      <AppNumberInput
        source="awardPrice"
        label="Prezzo di vendita"
        isAmount
        required
        variant={variant}
      />
      <AppDateTimeInput source="soldAt" label="Data/ora vendita" required variant={variant} />
      <AppDivider />
      <AppSectionTitle subtitle="Cerca un'anagrafica esistente o compila manualmente i dati">
        Dati del vincitore
      </AppSectionTitle>
      <AppAutocompleteInput
        reference="participants"
        source="existingParticipantId"
        label="Anagrafica esistente (Partecipanti)"
        md={12}
        variant={variant}
        optionText={(p) => {
          if (!p) return '';
          return `Nominativo: ${p.lastName} ${p.firstName} - Cod. fiscale: ${
            p.taxCode ?? '-'
          } - Email: ${p.email}`;
        }}
        suggestionLimit={5}
        resettable
      />
      {!values['existingParticipantId'] && (
        <>
          <AppTextInput source="firstName" label="Nome" md={3} variant={variant} />
          <AppTextInput source="lastName" label="Cognome" md={3} variant={variant} />
          <AppTextInput source="email" label="Email" md={3} variant={variant} />
          <AppTextInput source="taxCode" label="Codice fiscale" md={3} variant={variant} />
        </>
      )}
    </>
  );
};
