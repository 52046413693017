import { Button } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/GetApp';
import Papa from 'papaparse';
import { useNotify } from 'ra-core';
import React, { FC, Fragment, useState } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import { ITEM_GENRE_CHOICES } from '../../Resources/Booking/constants';
import { dateFormatter } from '../../utils/data-formatters';
import { downloadFileFromBufferOrString } from '../../utils/files';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import { AppBooleanInput, AppDateInput, AppSelectInput, AppTextInput } from '../ui/input';
import AppFiller from '../ui/layout/AppFiller';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';

const ExportToCsvButton: FC<any> = ({ handleClose, resource }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const toggleExportDialog = () => setExportDialogOpen((prevState) => !prevState);

  const [loaderOpen, setLoaderOpen] = useState<boolean>(false);
  const toggleLoader = () => setLoaderOpen((prevState) => !prevState);

  const [executeExport] = useMutation();
  const handleExecuteExport = (formData: Record<string, any>) => {
    const { fileName, ...filter } = formData;
    executeExport(
      {
        type: 'getList',
        resource: `${resource}/export-for-csv`,
        payload: {
          filter,
        },
      },
      {
        onSuccess: ({ data: bookings, total }) => {
          if (total < 1) {
            notify('Nessuna prenotazione esportabile.', 'info');
          } else {
            const bookingsCsv = _exportToCsv(bookings);
            downloadFileFromBufferOrString(
              bookingsCsv,
              `${fileName && !!fileName.length ? fileName : 'prenotazioni-visite-beni'}.csv`,
              'text/csv',
            );
            refresh();
          }
          toggleExportDialog();
          handleClose();
          toggleLoader();
        },
        onFailure: (err) => {
          toggleLoader();
          console.error('[handleExecuteExport]:', err);
          notify(`Operazione fallita${err.message ? `: ${err.message}` : ''}.`, 'error');
        },
      },
    );
  };

  return (
    <Fragment>
      <Button
        children="Esporta in CSV"
        onClick={toggleExportDialog}
        variant="text"
        color="primary"
        startIcon={<ExportIcon />}
      />
      <AppFormDialog
        open={exportDialogOpen}
        onClose={toggleExportDialog}
        title="Configurazione esportazione prenotazioni in CSV"
        onSubmit={(data) => {
          toggleLoader();
          handleExecuteExport(data);
        }}
        record={{
          excludeAlreadyExported: true,
        }}
        mode="insert"
        width={100}
        height={70}
        saveBtnConfig={{
          label: 'Esporta',
          icon: <ExportIcon />,
        }}
        variant="outlined"
        canSubmitIfPristine
      >
        <AppTextInput
          source="fileName"
          label="Nome file"
          md={12}
          helperText="Generato automaticamente se lasciato vuoto"
        />
        <AppDateInput source="bookingsFrom" label="Prenotazioni dal" />
        <AppDateInput source="bookingsTo" label="al" />
        <AppSelectInput choices={ITEM_GENRE_CHOICES} source="genre" label="Genere" />
        <AppFiller />
        <AppBooleanInput source="excludeAlreadyExported" label="Escludi già esportate" />
        <AppCircularLoader open={loaderOpen} />
      </AppFormDialog>
    </Fragment>
  );
};

const CSV_CONFIG: Papa.UnparseConfig = {
  quotes: true,
  delimiter: ',',
};
function _exportToCsv(bookings): string {
  return Papa.unparse(
    bookings.map(({ booker, item, createdAt }) => ({
      ['Nome']: booker.firstName,
      ['Cognome']: booker.lastName,
      ['Ragione Sociale']: booker.businessName,
      ['Tipo persona']: booker.type === 'person' ? 'Persona fisica' : 'Persona giuridica',
      ['Indirizzo']: booker.residenceAddress,
      ['Civico']: booker.residenceAddressNumber,
      ['Cap']: booker.residenceZipCode,
      ['Città']: booker.cityName,
      ['Provincia']: booker.provinceName,
      ['Telefono']: booker.mobile ?? booker.phone,
      ['Fax']: booker.fax,
      ['Email']: booker.email,
      ['Luogo Nascita']: null, // missing in Booker entity
      ['Data nascita']: booker.birthday ? dateFormatter(booker.birthday, true) : null,
      ['Tipo Documento']: null, // ??
      ['Numero Documento']: null, // ??
      ['Accompagnatore']: null, // ??
      ['Numero Procedura']: item.relatedFolder?.procedureCode ?? null,
      ['Numero Lotto']: item.code,
      ['Data Vendita']: item.soldAt ? dateFormatter(item.soldAt, true) : null,
      ['Numero IVG']: item.ivgCode,
      ['Data Richiesta']: dateFormatter(createdAt, true),
    })),
    CSV_CONFIG,
  );
}

export default ExportToCsvButton;
