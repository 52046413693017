import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DoneIcon from '@material-ui/icons/Done';
import { FC, Fragment, useEffect, useState } from 'react';
import {
  Record as RaRecord,
  useMutation,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import AppAlert from '../../../../Components/ui/alert/AppAlert';
import { useBasePath } from '../../../../hooks/use-base-path';
import { User } from '../../../../types/user.type';
import { PersonRoles, SaleMode } from '../../../../utils/constants';
import { dateFormatter } from '../../../../utils/data-formatters';

const useStyles = makeStyles(
  (theme) => ({
    activeButton: {
      backgroundColor: green[500],
      color: 'black',
      fontWeight: 'bold',
      '&:disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'black',
      },
    },
    arrowItem: {
      textAlign: 'center',
    },
    buttonItem: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: 'AuctionAuthorization' },
);

interface AuctionAuthorizationProps {
  mode: string;
  auctionStatus: string;
  saleExperiment: RaRecord;
  multipleParticipations: boolean;
}

//* Roles that can see the auction panel
const CAN_SEE_WORKFLOW = [
  PersonRoles.SuperAdmin,
  PersonRoles.Administrator,
  PersonRoles.Auctioneer,
  PersonRoles.Owner,
];

export const AuctionAuthorization: FC<AuctionAuthorizationProps> = (props) => {
  const { auctionStatus, saleExperiment, mode, multipleParticipations } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const basePath = useBasePath();

  const { permissions: user } = usePermissions<User>();

  /// region STATES

  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [newAuctionState, setNewAuctionState] = useState('');

  const [authNotVisibleReason, setAuthNotVisibleReason] = useState<string | null>(null); // default reason

  /// endregion

  /// region AUCTION STATUS HANDLING

  const handleDialogInformationChange = (
    newAuctionState: string,
    dialogTitle: string,
    dialogContent: string,
  ) => {
    setDialogTitle(dialogTitle);
    setDialogContent(dialogContent);
    setNewAuctionState(newAuctionState);
    setOpen(true);
  };

  const handleAuctionStatusChangeDialog = () => {
    setOpen(false);
  };

  const [mutateAuctionStatus, { loading }] = useMutation();
  const handleAuctionStatusChange = () =>
    mutateAuctionStatus(
      {
        type: 'create',
        resource: `${basePath}/${saleExperiment.id}/auction-status`,
        payload: {
          data: {
            status: newAuctionState,
          },
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          notify('Operazione conclusa con successo', 'info');
          refresh();
        },
        onFailure: (err) => {
          setOpen(false);
          notify(err.message, 'error');
          console.log(err);
        },
      },
    );

  /// endregion

  /// region AUTHORIZATION FLOW VISIBILITY

  useEffect(() => {
    if (!saleExperiment.relatedItem) return setAuthNotVisibleReason('Informazioni mancanti');

    const { auctionStartAt, relatedItem, fkSaleMode } = saleExperiment;

    if (fkSaleMode === SaleMode.ExpressionOfInterest || fkSaleMode === SaleMode.OffersCollection)
      return setAuthNotVisibleReason('Per questa modalità di vendità non è previsto il workflow');

    if (fkSaleMode === SaleMode.AsyncOnline && relatedItem.fkGenre === 2) {
      // async movable auction
      return setAuthNotVisibleReason(`La gara inizierà in data ${dateFormatter(auctionStartAt)}`);
    }

    if (!user || !CAN_SEE_WORKFLOW.includes(user.roleId))
      return setAuthNotVisibleReason("Non disponi di un ruolo valido per la gestione dell'asta");

    // hide auth flow if auction start is more than 3 hours away
    const threeHoursFromStart = new Date(auctionStartAt).getTime() - 1000 * 60 * 60 * 3;
    const interval = setInterval(() => {
      if (Date.now() < threeHoursFromStart) {
        return setAuthNotVisibleReason("Mancano più di 3 ore all'inizio della gara");
      }
      setAuthNotVisibleReason(null);
      clearInterval(interval);
    }, 1000);
  }, [saleExperiment, user]);

  /// endregion

  return !authNotVisibleReason ? (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleAuctionStatusChangeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAuctionStatusChangeDialog} color="primary" disabled={loading}>
            Indietro
          </Button>
          <Button onClick={handleAuctionStatusChange} color="primary" autoFocus disabled={loading}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%',
          marginBottom: '12px',
        }}
      >
        <Grid item md={2} className={classes.buttonItem}>
          <Button
            color="primary"
            variant="outlined"
            children={
              auctionStatus === 'authorization'
                ? 'Apertura buste in corso'
                : 'Avvia fase di apertura buste'
            }
            className={auctionStatus === 'authorization' ? classes.activeButton : ''}
            disabled={mode !== 'edit' || auctionStatus !== 'inactive'}
            onClick={() =>
              handleDialogInformationChange(
                'authorization',
                'Avvia fase di apertura buste',
                'Sei sicuro di voler avviare la fase di apertura delle buste?',
              )
            }
            endIcon={
              auctionStatus !== 'inactive' && auctionStatus !== 'authorization' ? (
                <DoneIcon />
              ) : (
                <span />
              )
            }
          />
        </Grid>
        <Grid item md={1} className={classes.arrowItem}>
          <ArrowRightAltIcon fontSize="large" />
        </Grid>
        <Grid item md={2} className={classes.buttonItem}>
          <Button
            color="primary"
            variant="outlined"
            children={
              auctionStatus === 'inactive' || auctionStatus === 'authorization'
                ? 'Termina fase di apertura buste'
                : 'Verifiche completate'
            }
            className={auctionStatus === 'ready' ? classes.activeButton : ''}
            disabled={mode !== 'edit' || auctionStatus !== 'authorization'}
            onClick={() =>
              handleDialogInformationChange(
                'ready',
                'Termina fase di apertura buste',
                'Sei sicuro di voler terminare la fase di apertura delle buste?',
              )
            }
            endIcon={auctionStatus === 'ready' ? <DoneIcon /> : <span />}
          />
        </Grid>
        <Grid item md={1} className={classes.arrowItem}>
          <ArrowRightAltIcon fontSize="large" />
        </Grid>
        <Grid item md={2} className={classes.buttonItem}>
          <Button
            color="primary"
            variant="outlined"
            children="Avvia gara"
            className={auctionStatus === 'active' ? classes.activeButton : ''}
            disabled={mode !== 'edit' || auctionStatus !== 'ready'}
            onClick={() =>
              handleDialogInformationChange(
                'active',
                'Avvia gara',
                multipleParticipations
                  ? 'Sei sicuro di voler avviare la gara?'
                  : 'Sei sicuro di voler avviare la gara? Una volta avviata la gara, questa sarà immediatamente aggiudicata all’unico offerente autorizzato',
              )
            }
          />
        </Grid>
      </div>
    </Fragment>
  ) : (
    <Grid item md={12}>
      <AppAlert severity="info">{authNotVisibleReason}</AppAlert>
    </Grid>
  );
};
