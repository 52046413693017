import { Breadcrumbs, Link, makeStyles, Typography } from '@material-ui/core';
import { Record as RaRecord } from 'ra-core';
import { FC, ReactElement } from 'react';
import { CreateProps, EditProps } from 'react-admin';

const useStyles = makeStyles(() => {
  return {
    item: {
      textTransform: 'uppercase',
    },
    link: {
      cursor: 'pointer',
      textTransform: 'uppercase',
      color: 'inherit',
      textDecoration: 'underline',
    },
    lastItem: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  };
});

type AppTitleProps = {
  title?: string | ((record) => string);
  record?: RaRecord;
  mode?: 'create' | 'edit' | 'show' | ReactElement;
} & CreateProps &
  EditProps;

const IVG_NAME = process.env.REACT_APP_IVG_NAME;
const cdaName = IVG_NAME ? `IVG ${IVG_NAME}` : 'Casa delle Aste';

const AppTitle: FC<AppTitleProps> = (props): ReactElement => {
  const classes = useStyles();
  const { record, title, mode, options, resource, history } = props;

  //if custom title is passed
  if (title) {
    return <Typography className={classes.lastItem}>{title}</Typography>;
  }

  //otherwise set breadcrumbs

  const handleBreadcrumbClick = () => {
    if (resource && history) {
      return history.push(`/${resource}`);
    }
  };

  if (mode) {
    return (
      <Breadcrumbs aria-label="breadcrumbs" separator="»">
        <Link className={classes.link} onClick={handleBreadcrumbClick}>
          {options.label}
        </Link>
        {mode === 'create' ? (
          <Typography className={classes.lastItem}>crea</Typography>
        ) : (
          [
            resource && record && (
              <Typography className={classes.item}>
                {getTitleFromRecord(resource, record)}
              </Typography>
            ),
            <Typography className={classes.lastItem}>
              {mode === 'edit' ? 'modifica' : 'dettaglio'}
            </Typography>,
          ]
        )}
      </Breadcrumbs>
    );
  }

  //otherwise return default title
  return <Typography className={classes.lastItem}>{cdaName}</Typography>;
};

export default AppTitle;

function getTitleFromRecord(resource: string, record: RaRecord): string {
  let title: string;
  switch (resource) {
    case 'people':
    case 'participants':
      title = `${record.lastName} ${record.firstName}`;
      break;
    case 'companies':
      title = record.businessName;
      break;
    case 'folders':
    case 'folders-judiciary':
    case 'folders-private':
    case 'folders-utp-npl':
      title = record.title ?? record.id.toString();
      break;
    case 'items':
    case 'items-judiciary':
    case 'items-private':
    case 'items-utp-npl':
      title = record.title;
      break;
    case 'properties':
      title = record.name;
      break;
    case 'media-roles':
      title = record.name;
      break;
    case 'sale-experiments':
    case 'sale-experiments-judiciary':
    case 'sale-experiments-private':
    case 'sale-experiments-utp-npl':
      if (record.auctionStartAt) {
        title = `Inizio asta: ${new Date(record.auctionStartAt).toLocaleDateString()}`;
      } else {
        title = `Id asta: ${record.id}`;
      }
      break;
    case 'roles':
      title = record.description;
      break;
    case 'publication-proofs':
    case 'invoices':
      title = record.title ?? record.id;
      break;
    // Add new case when new Resource is added (if has edit/show view)
    default:
      title = '' + record.id;
  }

  return title.length > 50 ? `${title.substr(0, 50)}...` : title;
}
