import { Grid, makeStyles } from '@material-ui/core';
import { FileInputProps } from 'ra-ui-materialui/lib/input/FileInput';
import React, { FC } from 'react';
import { FileField, FileInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import AppPdfViewer from '../../pdf-viewer/pdf-viewer';
import AppSectionTitle from '../layout/AppSectionTitle';
import { AppGridProps } from '../types';

const useStyles = makeStyles(
  () => ({
    removeButton: {
      display: 'none',
    },
  }),
  { name: 'AppFileInput' },
);

type AppFileInputProps = FileInputProps &
  AppGridProps & {
    source: string;
    label?: string;
    previewSource?: string;
    previewLabel?: string;
    previewType?: 'url' | 'base64';
    previewClassName?: string;
    helperText?: string;
    disabled?: boolean;
    base64Preview?: boolean;
    previewMaxWidth?: boolean;
    isRequired?: boolean;
  };

export const AppFileInput: FC<AppFileInputProps> = ({
  source,
  label,
  accept,
  helperText,
  md = 6,
  className,
  previewSource = '',
  previewLabel,
  previewType = 'url',
  previewClassName,
  disabled = false,
  base64Preview = false,
  previewMaxWidth = false,
  isRequired = false,
}) => {
  const classes = useStyles();
  const { values } = useFormState();
  return (
    <Grid item md={md} className={className}>
      {!disabled && (
        <>
          <AppSectionTitle>{label}</AppSectionTitle>
          <FileInput
            source={source}
            label={false}
            accept={accept}
            helperText={helperText}
            classes={classes}
            isRequired={isRequired}
          >
            <FileField source={source} title="filename" />
          </FileInput>
        </>
      )}
      {(!!values[source] || !!values[previewSource]) && (
        <>
          <AppSectionTitle>{previewLabel}</AppSectionTitle>
          {accept === 'application/pdf' ? (
            <AppPdfViewer
              file={values[source] ? values[source][source] : values[previewSource]}
              height={350}
            />
          ) : !base64Preview && values[source]?.base64 ? (
            <img
              src={values[source].base64}
              alt="Preview"
              className={previewClassName}
              style={{ maxWidth: previewMaxWidth ? 'inherit' : undefined }}
            />
          ) : (
            <img
              src={
                base64Preview
                  ? values[source].base64 ?? values[source][source]
                  : values[source]
                  ? values[source][source]
                  : previewType === 'url'
                  ? values[previewSource]
                  : `data:${values[previewSource].mimeType};base64,${values[previewSource].base64}`
              }
              alt="Preview"
              className={previewClassName}
              style={{ maxWidth: previewMaxWidth ? 'inherit' : undefined }}
            />
          )}
        </>
      )}
    </Grid>
  );
};
