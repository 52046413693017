import { Button, debounce, Fade, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { FC, Fragment, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { dateFormatter } from '../../utils/data-formatters';
import { maxDate } from '../../utils/validators';
import AppAlert from '../ui/alert/AppAlert';
import { AppDateInput } from '../ui/input';
import { AppAutocompleteInput } from '../ui/input/AppAutocompleteInput';
import AppTooltip from '../ui/tooltip/AppTooltip';
import AddPersonWithRole from './app-capability-add-person-with-role';

const useStyles = makeStyles(
  (theme) => ({
    addPersonRoleButton: {
      marginTop: theme.spacing(1.8),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(3),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AppCapabilityForm' },
);

const AppCapabilityForm: FC<any> = (props) => {
  const {
    isJudiciaryFolder,
    resource,
    getPeople,
    setAddPersonButtonIsDisabled,
    addPersonButtonIsDisabled,
    selectedRoleInfo,
    setSelectedRoleInfo,
    peopleLoading,
    peopleChoices,
    getOptionText,
    showCreatePersonButton,
    handleClickOpen,
    setOpen,
    ctx,
    type,
    courtId,
    open,
  } = props;

  const classes = useStyles();

  const { change, mutators: formMutators } = useForm();
  const { values } = useFormState();

  const outdatedCapability = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const validityTo = new Date(ctx.item.validityTo);
    validityTo.setHours(0, 0, 0, 0);
    return ctx.manager.mode === 'edit' && validityTo.getTime() < today.getTime();
  }, []);

  return (
    <Fragment>
      <Grid item md={12}>
        {outdatedCapability && (
          <AppAlert severity="info" className={classes.alert}>
            {`Il ruolo è scaduto in data ${dateFormatter(
              ctx.item.validityTo,
              true,
            )}, aggiorna le date di validtà per riattivarlo`}
          </AppAlert>
        )}
      </Grid>
      <AppAutocompleteInput
        md={4}
        xs={6}
        variant="outlined"
        label="Ruolo"
        reference="roles"
        filter={{
          judiciary: isJudiciaryFolder,
          [resource]: true,
        }}
        optionText="description"
        source="fkRole"
        resettable
        onChange={(fkRole) => {
          getPeople(fkRole);
          formMutators.clearInputs('fkPerson');
          setAddPersonButtonIsDisabled(true);
        }}
        required
        suggestionLimit={5}
        onSelect={(selected) => setSelectedRoleInfo(selected)}
        disabled={ctx.manager.mode === 'edit'}
      />
      <AppAutocompleteInput
        md={4}
        xs={6}
        disabled={ctx.manager.mode === 'edit' || peopleLoading || !values.fkRole}
        variant="outlined"
        label="Anagrafica"
        choices={peopleChoices}
        optionText={getOptionText}
        onInputValueChange={debounce((value) => showCreatePersonButton(value), 500)}
        source="fkPerson"
        resettable
        required
        onChange={() => setAddPersonButtonIsDisabled(true)}
      />
      <Grid item md={1} xs={12} className={classes.addPersonRoleButton}>
        <AppTooltip
          title="Seleziona o crea una persona da collegare al ruolo selezionato"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <Button
            color="primary"
            variant="outlined"
            disabled={addPersonButtonIsDisabled || !selectedRoleInfo.naturalPerson}
            onClick={() => handleClickOpen('person')}
            children={<PersonAddIcon />}
          />
        </AppTooltip>
      </Grid>
      <Grid item md={1} xs={12} className={classes.addPersonRoleButton}>
        <AppTooltip
          title="Seleziona o crea una azienda da collegare al ruolo selezionato"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <Button
            color="primary"
            variant="outlined"
            disabled={addPersonButtonIsDisabled || !selectedRoleInfo.juridicalPerson}
            onClick={() => handleClickOpen('company')}
            children={<HomeWorkIcon />}
          />
        </AppTooltip>
      </Grid>
      <AppDateInput
        source="validityFrom"
        label="Inizio validità"
        variant="outlined"
        md={4}
        required
        validate={[maxDate('validityTo', 'Termine validità')]}
      />
      <AppDateInput source="validityTo" label="Termine validità" variant="outlined" md={4} />
      <AddPersonWithRole
        open={open}
        setOpen={setOpen}
        ctx={ctx}
        type={type}
        courtId={courtId}
        currentChoices={peopleChoices}
        formValueChanger={change}
        capabilityFormValues={values}
        getPeople={getPeople}
      />
    </Fragment>
  );
};

export default AppCapabilityForm;
