import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { FC, useState } from 'react';
import { Button, useMutation, useNotify, useQuery } from 'react-admin';
import ItemMediaVerticalLayout from '../../Resources/Item/ItemMediaVerticalLayout';
import { useBasePath } from '../../hooks/use-base-path';
import AppDivider from '../ui/layout/AppDivider';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';

const useStyles = makeStyles((theme) => ({
  // Dialog
  dialog: {
    width: theme.spacing(500),
    height: theme.spacing(200),
    marginLeft: '16rem',
    maxWidth: 'none',
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogContent: {
    overflow: 'hidden',
  },
}));

const OrderMediaManager: FC<any> = ({
  reference,
  orderMediaType,
  onDialogClose,
  onOrderFinish,
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const basePath = useBasePath();

  const [medias, setMedias] = useState<Record<string, any>[]>([]);
  const [orderedMedias, setOrderedMedias] = useState<number[]>([]);
  const [mutateMediaOrder] = useMutation();
  // circular loader state
  const [openLoader, setOpenLoader] = useState(true);

  useQuery(
    {
      type: 'getList',
      resource: 'media',
      payload: {
        filter: {
          refId: reference,
          refType: 'item',
        },
      },
    },
    {
      onSuccess: (res) => {
        setOpenLoader(false);
        let results;
        switch (orderMediaType) {
          case 'virtual-tour':
            results = res.data.filter((r) => r.fkMediaRole === 9);
            break;
          case 'image':
            results = res.data.filter((r) => r.fkMediaRole === 11);
            break;
          case 'video':
            results = res.data.filter((r) => r.fkMediaRole === 12);
            break;
          default:
            results = res.data.filter((r) => ![9, 11, 12, 15, 17, 23].includes(r.fkMediaRole));
            break;
        }
        const sortedResults = results.sort((m1, m2) => m1.order - m2.order);
        setMedias(sortedResults);
      },
      onFailure: (err) => {
        setOpenLoader(false);
        console.error(err);
        notify('Non è stato possibile caricare i media.', 'error');
        setMedias([]);
      },
    },
  );

  const updateMediaOrder = () => {
    mutateMediaOrder(
      {
        type: 'update',
        resource: `${basePath}/${reference}/media/${orderMediaType}/order`,
        payload: {
          data: {
            order: orderedMedias,
          },
        },
      },
      {
        onSuccess: () => {
          setOpenLoader(false);
          onOrderFinish(true);
        },
        onFailure: (err) => {
          console.error(err);
          setOpenLoader(false);
          notify(err.message, 'error');
          onOrderFinish(false);
        },
      },
    );
  };

  const handleOrderChanged = (order: number[]) => {
    if (orderedMedias !== order) setOrderedMedias(order);
  };

  const getMediaType = () => {
    switch (orderMediaType) {
      case 'virtual-tour':
        return 'virtual tour';
      case 'image':
        return 'immagini';
      case 'video':
        return 'video';
      default:
        return 'documenti';
    }
  };
  return (
    <Dialog open={true} classes={{ paper: classes.dialog }} onClose={onDialogClose}>
      <DialogTitle className={classes.dialogTitle} children={'Modifica ordine ' + getMediaType()} />
      <AppDivider flex={false} />
      <DialogActions>
        <Button label="Chiudi" color="primary" variant="contained" onClick={onDialogClose} />
        <Button
          label="Conferma"
          color="primary"
          variant="contained"
          onClick={updateMediaOrder}
          disabled={medias.length < 2}
        />
      </DialogActions>
      <AppDivider flex={false} />
      <DialogContent className={classes.dialogContent}>
        <ItemMediaVerticalLayout
          items={medias}
          onLayoutChange={(layout) => {
            const itemsOrder: number[] = layout.map((item) => {
              return item.y;
            });
            handleOrderChanged(itemsOrder);
          }}
        />
        {medias.length < 2 && !openLoader && (
          <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '150px' }}>
            Non ci sono {getMediaType()} sufficenti per effettuare l'ordinamento
          </p>
        )}
      </DialogContent>
      <div className="absolute">
        <AppCircularLoader open={openLoader} />
      </div>
    </Dialog>
  );
};

export default OrderMediaManager;
