import React, { FunctionComponent } from 'react';
import { EditButton, ListProps, TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import AppTitle from '../../Components/ui/layout/AppTitle';
import AppList from '../../Components/ui/detail/AppList';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

const useStyles = makeStyles(
  {
    description: {
      width: '100%',
    },
  },
  { name: 'CategoryList' },
);

const CategoryList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title='Categorie' />}
      bulkActionButtons={false}
      filters={<AppListFilter helperText='Descrizione' />}
    >
      <AppDatagrid>
        <TextField source='description' label='Nome' headerClassName={classes.description} />
        <EditButton variant='outlined' icon={<span />} label='Modifica' />
      </AppDatagrid>
    </AppList>
  );
};

export default CategoryList;
