import { Button, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import { FC, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';

const usePublishItemButtonStyles = makeStyles(() => ({
  button: {
    '&:disabled': {
      backgroundColor: green[500],
      color: 'black',
      fontWeight: 'bold',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
}));

interface PublishItemButtonProps {
  record?: any;
}

export const PublishItemButton: FC<PublishItemButtonProps> = ({ record }) => {
  const notify = useNotify();
  const classes = usePublishItemButtonStyles();
  const [published, setPublished] = useState(false);
  const [mutate] = useMutation();
  const handleItemPublication = (e) => {
    e.preventDefault();
    e.stopPropagation();

    mutate(
      {
        type: 'create',
        resource: `items/${record.id}/publications`,
        payload: {
          data: {
            publicationDate: new Date(),
            fkProperty: 1,
            isSale: true,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Lotto pubblicato con successo');
          setPublished(true);
        },
        onFailure: (err) => {
          notify('Errore durante la pubblicazione del lotto', 'error');
          console.error('[handleItemPublication]:', err);
        },
      },
    );
  };

  return (
    <Button
      children={published ? 'Pubblicato' : 'Pubblica'}
      onClick={handleItemPublication}
      startIcon={published ? <CheckIcon /> : <></>}
      disabled={published}
      className={classes.button}
      variant="outlined"
    />
  );
};
