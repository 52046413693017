import BookingIcon from '@material-ui/icons/Visibility';
import { ResourceExport } from '../../types/resource-export.type';
import BookingList from './BookingList';

const Booking: ResourceExport = {
  list: BookingList,
  icon: BookingIcon,
};

export default Booking;
