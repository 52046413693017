import React, { FC, PropsWithChildren } from 'react';
import AppCard, { AppCardProps } from '../../ui/card/AppCard';

type ConfigurationSectionProps = {
  title: string;
  baseSource?: string;
  required?: boolean;
} & AppCardProps;

export const ConfigurationSection: FC<PropsWithChildren<ConfigurationSectionProps>> = React.memo<
  PropsWithChildren<ConfigurationSectionProps>
>(({ children, baseSource, ...appCardProps }) => {
  // const { values } = useFormState();
  // useEffect(() => {
  //   console.log(get(values, 'websiteCustomization.Generic.Colors'));
  // }, [get(values, 'websiteCustomization.Generic.Colors')]);
  return (
    <AppCard {...appCardProps}>
      {React.Children.map(children, (child) => {
        if (!child || !React.isValidElement(child)) return;

        return React.cloneElement(child, {
          ...child.props,
          source: `${baseSource ? `${baseSource}.` : ''}${child.props.source}`,
        });
      })}
    </AppCard>
  );
});
