import { GridProps } from '@material-ui/core';
import ComplaintIcon from '@material-ui/icons/Warning';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { ResourceAction, userIsAllowed } from '../../../Providers/AppPermissionsProvider';
import ComplaintDatagrid from '../../../Resources/Complaint/ComplaintDatagrid';
import { useDashboardContext } from '../../../hooks';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const AppDashboardComplaints: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const { isIvg, user } = useDashboardContext();

  const allowView = userIsAllowed(ResourceAction.Get, 'complaints', user);

  if (isIvg || !allowView) return null;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="complaints"
        tableTitle="Controversie"
        tableSubtitle="Di seguito sono elencate le controversie"
        noItemMessage="Non ci sono controversie da visualizzare"
        noItemIcon={<ComplaintIcon />}
        rowClick={(id, basePath, record) =>
          history.push(
            `complaints/${record.id}?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
          )
        }
        CustomDatagrid={<ComplaintDatagrid rowClick={() => history.push('complaints')} />}
      />
    </AppDashboardCard>
  );
};

export default AppDashboardComplaints;
