import { Tooltip, TooltipProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      maxWidth: theme.spacing(90),
    },
  }),
  { name: 'AppTooltip' },
);

const AppTooltip: FC<TooltipProps> = ({ children, title, className }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<Typography variant="button" children={title} />}
      arrow
      classes={{ tooltip: classes.tooltip }}
      className={className}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default AppTooltip;
