import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { BooleanField, FunctionField, SelectField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AUCTION_STATUS } from '../../utils/constants';
import { getResourceType } from '../../utils/reference-selector';
import { ITEM_PERMISSIONS } from './index';
import ItemExpand from './ItemExpand';

export const useItemListStyles = makeStyles(
  (theme) => ({
    title: {
      minWidth: theme.spacing(30),
    },
    handleSale: {
      whiteSpace: 'nowrap',
    },
    code: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(25),
    },
    typology: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ItemList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const ItemDatagrid: FC<any> = (props) => {
  const { rowClick, permissions, ...rest } = props;
  const classes = useItemListStyles();

  return (
    <AppDatagrid {...rest} rowClick={rowClick} expand={<ItemExpand />}>
      {getResourceType(props.resource) === 'judiciary' ? (
        <AppTextField
          source="relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={classes.code}
        />
      ) : (
        <React.Fragment />
      )}
      <AppTextField
        source="relatedFolder.title"
        label={getResourceType(props.resource) === 'judiciary' ? 'Nome procedura' : 'Nome pratica'}
        truncate
        headerClassName={classes.title}
      />
      <BooleanField
        source="handleSale"
        label="Gestiamo la vendita"
        headerClassName={classes.handleSale}
      />
      {IS_IVG && (
        <AppTextField source="ivgCode" label="Numero IVG" headerClassName={classes.code} />
      )}
      <AppTextField source="code" label="Codice lotto" headerClassName={classes.code} />
      <AppTextField source="id" label="Progressivo interno lotto" headerClassName={classes.code} />
      <AppTextField source="title" label="Nome lotto" truncate headerClassName={classes.title} />
      <AppTextField
        source="fullTextTypology"
        label="Tipologia"
        headerClassName={classes.typology}
      />
      <FunctionField
        render={(record) => {
          const currentSaleExperiment = record.relatedSaleExperiments
            ? record.relatedSaleExperiments.find((saleExp) => saleExp.isCurrent)
            : undefined;
          if (currentSaleExperiment) {
            return (
              <SelectField
                choices={AUCTION_STATUS}
                record={currentSaleExperiment}
                source="auctionStatus"
              />
            );
          }
          return '-';
        }}
        label="Stato asta"
        headerClassName={classes.lastCol}
      />
      {props.showButton ?? (
        <AppShowButton
          icon={<span />}
          permissions={permissions}
          resourcePermissions={ITEM_PERMISSIONS}
        />
      )}
      {!props.hideEditButton ? (
        props.editButton ?? (
          <AppEditButton
            icon={<span />}
            permissions={permissions}
            resourcePermissions={ITEM_PERMISSIONS}
          />
        )
      ) : (
        <span />
      )}
    </AppDatagrid>
  );
};

export default ItemDatagrid;
