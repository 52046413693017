//TODO: WIP
import React, { FunctionComponent } from 'react';
import { TabbedShowLayout, TabbedShowLayoutTabs, SimpleShowLayout } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
//import AppBottomToolbar from '../form/AppBottomToolbar';
import { omit as _omit } from 'lodash';

const useStyles = makeStyles(
  (theme: Theme) => {
    const height = 'calc(100vh - 11rem)';
    return {
      appTabbedShow: {
        //backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        //backgroundColor: 'green',
        flex: '1 1 100%',
        height: height,
        minHeight: height,
        maxHeight: height,
        overflowY: 'auto',
        paddingRight: theme.spacing(1),
      },
    };
  },
  { name: 'AppTabbedShow' },
);

const AppShowLayout: FunctionComponent<any> = (props) => {
  const classes = useStyles(props);

  if (props.tabbed) {
    return (
      <TabbedShowLayout
        {...props}
        className={classes.appTabbedShow}
        classes={_omit(classes, 'appTabbedShow')}
        //toolbar={<AppBottomToolbar />} //TODO: tabbedshowlayout doesn't accept 'toolbar' props...
        tabs={<TabbedShowLayoutTabs />}
      />
    );
  }

  return (
    <SimpleShowLayout
      {...props}
      className={classes.appTabbedShow}
      classes={_omit(classes, 'appTabbedShow')}
    />
  );
};

AppShowLayout.propTypes = TabbedShowLayout.propTypes;
AppShowLayout.defaultProps = TabbedShowLayout.defaultProps;

export default AppShowLayout;
