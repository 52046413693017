import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ArrayField, ChipField, ListProps, SingleFieldList } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    name: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    amount: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    errorMessage: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
  }),
  { name: 'BuyNowVariantList' },
);

const BuyNowVariantList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList {...props} title={<AppTitle title="Varianti del compra subito" />}>
      <AppDatagrid>
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <ArrayField source="values" label="Valori" headerClassName={classes.lastCol}>
          <SingleFieldList>
            <ChipField source="value" size="small" clickable={false} />
          </SingleFieldList>
        </ArrayField>
        <AppShowButton icon={<span />} permissions={props.permissions} />
        <AppEditButton icon={<span />} permissions={props.permissions} />
      </AppDatagrid>
    </AppList>
  );
};

export default BuyNowVariantList;
