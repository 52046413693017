import { Typography } from '@material-ui/core';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import firebase from 'firebase';
import fetch from 'node-fetch';
import { FunctionComponent, useEffect, useState } from 'react';
import { ListProps } from 'react-admin';
import ReactJson from 'react-json-view';
import AppCard from '../../Components/ui/card/AppCard';
import { useCmsConfiguration } from '../../hooks/use-cms-configuration';
import { useFirebaseApp } from '../../utils/firebase';

type LegendItemProps = {
  stateOk: boolean;
  label: string;
};

const LegendItem = (props: LegendItemProps) => {
  return (
    <div style={{ display: 'flex', height: '40px', marginLeft: '30px' }}>
      <LegendIcon style={{ color: props.stateOk ? 'green' : 'red' }} />
      <Typography>{props.label}</Typography>
    </div>
  );
};

const hideSensibleContent = (value: string) => {
  if (value.length > 6)
    return (
      value.substring(0, 3) +
      '*'.repeat(value.length - 6) +
      value.substring(value.length - 3, value.length)
    );
  else return '*'.repeat(value.length);
};

type PairProps = {
  name: string;
  value: string;
};

const Pair = (props: PairProps) => {
  return (
    <div style={{ display: 'flex', height: '30px', marginLeft: '30px' }}>
      <Typography style={{ fontWeight: 'bold' }}>{props.name}:</Typography>
      <Typography style={{ marginLeft: '5px' }}>
        {props.name.includes('KEY') ? hideSensibleContent(props.value) : props.value}
      </Typography>
    </div>
  );
};

const ServicesStatusList: FunctionComponent<ListProps> = () => {
  const [isConnectionOk, setConnectionOk] = useState<boolean>(true);
  const [isApiConnectionOk, setApiConnectionOk] = useState<boolean>(true);
  const envProps: Record<string, any> = process.env;
  const cmsConfig = useCmsConfiguration();

  const handleApiConnection = async () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${apiUrl}\\status`);
    setApiConnectionOk(response.status === 200);
  };

  useEffect(() => {
    useFirebaseApp();
    firebase
      .database()
      .ref('.info/connected')
      .on('value', (snap) => {
        setConnectionOk(snap.val() === true);
      });

    handleApiConnection();
  }, []);

  return (
    <div style={{ overflowY: 'scroll', backgroundColor: 'white' }}>
      <Typography style={{ fontWeight: 'bold', margin: '30px' }} variant="h4">
        STATO SERVIZI
      </Typography>
      <LegendItem stateOk={isConnectionOk} label="Connessione internet" />
      <LegendItem stateOk={isConnectionOk} label="Connessione a Firebase" />
      <LegendItem stateOk={isApiConnectionOk} label="Connessione ad API" />

      <AppCard title="ENV" expanded>
        <div>
          {Object.keys(envProps).map((key) => {
            if (!key.startsWith('WDS_SOCKET')) return <Pair name={key} value={envProps[key]} />;
          })}
        </div>
      </AppCard>
      <AppCard title="STRAPI" expanded>
        <Pair
          name="Data ultimo aggiornamento"
          value={new Date(cmsConfig?.updatedAt).toLocaleString()}
        />
        <ReactJson src={(cmsConfig as Record<string, any>) ?? {}} style={{ marginLeft: '30px' }} />
      </AppCard>
    </div>
  );
};

export default ServicesStatusList;
