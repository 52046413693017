/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container, ContainerProps, Grid, GridSpacing, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { omit as _omit } from 'lodash';
import PropTypes from 'prop-types';
import {
  FormWithRedirect,
  FormWithRedirectProps,
  Record as RaRecord,
  RedirectionSideEffect,
} from 'ra-core';
import * as React from 'react';
import { FC, HtmlHTMLAttributes, ReactElement, ReactNode } from 'react';
import { FormRenderProps } from 'react-final-form';
import AppBottomToolbar from './AppBottomToolbar';
import { innerGridTabsWrapper } from './form-utils';

/// region BASE FORM

/**
 * Copied from SimpleForm
 */
const AppBaseForm: FC<AppBaseFormProps> = (props: AppBaseFormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <AppBaseFormView
          {...formProps}
          disabled={props.disabledCards}
          resourceOptions={props.options}
        />
      )}
    />
  );
};

AppBaseForm.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  save: PropTypes.func,
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number,
  sanitizeEmptyValues: PropTypes.bool,
  disabledCards: PropTypes.bool,
  //grid infos
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
};

export interface AppBaseFormProps
  extends Omit<FormWithRedirectProps, 'render'>,
    Omit<HtmlHTMLAttributes<HTMLFormElement>, 'defaultValue' | 'onSubmit' | 'children'> {
  basePath?: string;
  children: ReactNode;
  className?: string;
  initialValues?: never;
  margin?: 'none' | 'normal' | 'dense';
  resource?: string;
  submitOnEnter?: boolean;
  toolbar?: ReactElement;
  undoable?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  disabledCards?: boolean;

  /// region GRID

  spacing?: GridSpacing;

  /// endregion
}

const useStyles = makeStyles(
  (theme: Theme) => {
    const height = 'calc(100vh - 8rem)';
    return {
      globalForm: {
        flex: '1 1 100%',
        //backgroundColor: 'pink',
        display: 'flex',
        flexDirection: 'column',
      },
      globalContainer: {
        flex: '1 1 100%',
        height: height,
        minHeight: height,
        maxHeight: height,
        overflowY: 'auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      },
      gridContainer: {
        marginTop: theme.spacing(2),
      },
    };
  },
  { name: 'AppBaseForm' },
);

/// endregion

/// region BASE FORM VIEW

const AppBaseFormView: FC<AppBaseFormViewProps> = (props) => {
  /// region DECLARATIONS
  const {
    basePath,
    children,
    //className,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    margin,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    toolbar,
    undoable,
    variant,
    disabled = false,
    maxWidth = 'md',
    resourceOptions = {},

    /// region GRID

    spacing = 2,

    /// endregion
    ...rest
  } = props;
  /// endregion
  //TODO: create clearInputs mutator (is it possible here?)
  const classes = useStyles();
  return (
    <form
      className={classnames('app-base-form', classes.globalForm)}
      {...sanitizeRestProps(rest as never)}
    >
      <div className={classes.globalContainer}>
        <Container maxWidth={maxWidth}>
          <Grid container spacing={spacing} className={classes.gridContainer}>
            {innerGridTabsWrapper(children as ReactElement[], {
              basePath,
              record,
              resource,
              variant,
              margin,
              disabled,
            })}
          </Grid>
        </Container>
      </div>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
          resourceOptions,
        })}
    </form>
  );
};

AppBaseFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  /* @ts-ignore */
  handleSubmit: PropTypes.func, // passed by react-final-form
  /* @ts-ignore */
  invalid: PropTypes.bool,
  /* @ts-ignore */
  pristine: PropTypes.bool,
  /* @ts-ignore */
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  resourceOptions: PropTypes.object,
};

type FormContainerRenderProps = FormRenderProps & Pick<ContainerProps, 'maxWidth'>;

export interface AppBaseFormViewProps extends FormContainerRenderProps {
  basePath?: string;
  className?: string;
  margin?: 'none' | 'normal' | 'dense';
  handleSubmitWithRedirect?: (redirectTo: RedirectionSideEffect) => void;
  record?: RaRecord;
  redirect?: RedirectionSideEffect;
  resource?: string;
  save?: () => void;
  saving?: boolean;
  toolbar?: ReactElement;
  undoable?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  submitOnEnter?: boolean;
  disabled?: boolean;
  resourceOptions?: Record<string, any>;

  /// region GRID

  spacing?: GridSpacing;

  /// endregion
  __versions?: any; // react-final-form internal prop, missing in their type
}

AppBaseFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <AppBottomToolbar />,
  variant: 'outlined',
};

/// endregion

const sanitizeRestProps = (props) =>
  _omit(props, [
    'active',
    'dirty',
    'dirtyFields',
    'dirtyFieldsSinceLastSubmit',
    'dirtySinceLastSubmit',
    'error',
    'errors',
    'form',
    'hasSubmitErrors',
    'hasValidationErrors',
    'hasShow',
    'hasEdit',
    'hasCreate',
    'hasList',
    'initialValues',
    'modified',
    'modifiedSinceLastSubmit',
    'save',
    'submitError',
    'submitErrors',
    'submitFailed',
    'submitSucceeded',
    'submitting',
    'touched',
    'valid',
    'validating',
    'values',
    'visited',
    '__versions',
  ]);

export default AppBaseForm;
