import React, { FC, Fragment } from 'react';
import { ImportState } from '../../../Resources/ChatbotImport/constants';
import AppAlert from '../../ui/alert/AppAlert';
import ItemInfo from '../item-info/item-info';
import PendingMedia from '../pending-media/pending-media';

interface ChatbotImportListExpandedProps {
  record?: Record<string, any>;
}

const ChatbotImportListExpanded: FC<ChatbotImportListExpandedProps> = ({
  record: chatbotImport,
}) => {
  if (!chatbotImport) return <Fragment />;

  return chatbotImport.importState === ImportState.Successful ? (
    <Fragment>
      <PendingMedia pendingMedia={chatbotImport.pendingMedia} />
      <ItemInfo relatedItem={chatbotImport.relatedItem} />
    </Fragment>
  ) : (
    <AppAlert severity="warning">Lotto non ancora importato/aggiornato</AppAlert>
  );
};

export default ChatbotImportListExpanded;
