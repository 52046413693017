import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Record } from 'ra-core';
import React, { FC } from 'react';
import RichTextInput from 'ra-input-rich-text';
import { RichTextField } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'AppRichTextInput' },
);

interface AppRichTextInputProps {
  source: string;
  disabled?: boolean;
  record?: Record;
  label?: string;
}

export const AppRichTextInput: FC<AppRichTextInputProps> = (props) => {
  const { source, label = 'Note', disabled, record } = props;
  const classes = useStyles();
  if (disabled) {
    return (
      <div className={classes.container}>
        <Typography variant="h6">{label}</Typography>
        <RichTextField record={record} source={source} emptyText="-" />
      </div>
    );
  }

  return <RichTextInput source={source} label={label} toolbar={true} />;
};
