import React, { FC } from 'react';
import AppCard from '../../ui/card/AppCard';
import {
  PvpOfferContacts,
  PvpOfferField,
  PvpOfferLocation,
  PvpOfferLocationType,
  PvpOfferPerson,
} from './utility';

interface PvpOfferPresenter {
  presentatore: Record<string, any>;
}

const PvpOfferPresenter: FC<PvpOfferPresenter> = ({ presentatore }) => {
  const { datiAnagrafici, residenza, domicilio, contatti } = presentatore;
  return (
    <AppCard title="PRESENTATORE" expanded spacing={4}>
      <PvpOfferPerson datiAnagrafici={datiAnagrafici} />
      <PvpOfferLocation location={residenza} type={PvpOfferLocationType.Residence} />
      {domicilio && Object.values(domicilio).length ? (
        <PvpOfferLocation location={domicilio} type={PvpOfferLocationType.Domicile} />
      ) : (
        <PvpOfferField label="Domicilio" customValue="Residenza come domicilio" md={12} />
      )}
      <PvpOfferContacts contatti={contatti} />
    </AppCard>
  );
};

export default PvpOfferPresenter;
