import { Card, CardContent, Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    card: {
      margin: theme.spacing(2),
      textAlign: 'center',
    },
  }),
  { name: 'AppDashboardCard' },
);

const AppDashboardCard: FC<GridProps> = (props) => {
  const { md = 6, children } = props;
  const classes = useStyles();
  return (
    <Grid item md={md}>
      <Card className={classes.card}>
        <CardContent>{children ?? '_PLACEHOLDER_'}</CardContent>
      </Card>
    </Grid>
  );
};

export default AppDashboardCard;
