// Utils for calculation components (AppDateCalculator, AppAmountCalculator)

//#region GENERIC

export const getRefFieldSource = (values: Record<string, any>, refToSource: string): string =>
  Object.keys(values).find((key) => key === values[refToSource]) ?? '';

//#endregion

//#region DATE CALCULATOR INPUT

export const addDays = (oldDate: Date | string, days = 0): Date | undefined => {
  if (!oldDate) return;
  const result = new Date(oldDate);
  result.setDate(result.getDate() + days);
  return result;
};

export const calculateDaysDifference = (date1: Date | string, date2: Date | string): number => {
  if (!date1 || !date2) return 0;

  return Math.floor(
    (Date.parse(typeof date1 !== 'string' ? date1.toISOString() : date1) -
      Date.parse(typeof date2 !== 'string' ? date2.toISOString() : date2)) /
      86400000,
  );
};

//#endregion

//#region AMOUNT CALCULATOR INPUT

export const getRefToChoice = (
  refToSourceValue: string,
  refToChoices: Record<string, any>[],
): Record<string, any> | undefined =>
  refToChoices.find((refToChoice) => refToChoice.id === refToSourceValue);

//#endregion
