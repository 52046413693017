import { Grid } from '@material-ui/core';
import { FC, Fragment } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import AppDivider from '../../Components/ui/layout/AppDivider';
import AppFiller from '../../Components/ui/layout/AppFiller';
import { paymentFromId } from '../../utils/payments';
import { PaymentMethod } from '../Payment/constants';
import { INFO_REQUEST_TYPE_CHOICES } from './constants';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const InfoRequestExpanded: FC<any> = ({ record: infoRequest }) => {
  const { item, infoRequester, infoRequestPayments } = infoRequest;

  return (
    <Grid container spacing={3}>
      <DetailPanelTitle variant="h5" children="DETTAGLIO RICHIESTA" />
      {/* TYPE */}
      <DetailPanelField
        md={2}
        label="Tipo"
        value={INFO_REQUEST_TYPE_CHOICES.find((type) => type.id === infoRequest.requestType)?.name}
      />
      {/* ITEM */}
      {item && (
        <Fragment>
          <DetailPanelTitle children="INFORMAZIONI LOTTO" />
          <DetailPanelField md={12} label="Lotto" value={item.title} />
          <DetailPanelField md={2} label="Codice lotto" value={item.code} />
          {IS_IVG && <DetailPanelField md={2} label="Numero IVG" value={item.ivgCode} />}
          <DetailPanelField
            md={2}
            label={item.relatedFolder.type === 'judiciary' ? 'Procedura' : 'Pratica'}
            value={item.relatedFolder.procedureCode || item.relatedFolder.title}
          />
        </Fragment>
      )}
      {/* PERSON */}
      <DetailPanelTitle children="INFORMAZIONI PERSONALI" />
      <Fragment>
        <DetailPanelField md={2} label="Nome" value={infoRequester.firstName} />
        <DetailPanelField md={2} label="Cognome" value={infoRequester.lastName} />
      </Fragment>
      <DetailPanelTitle children="CONTATTI" />
      <DetailPanelField md={2} label="Email" value={infoRequester.email} />
      <DetailPanelField md={2} label="Telefono" value={infoRequester.phone} />
      <DetailPanelField
        md={2}
        label="Con PEC"
        value={infoRequester.withPec ? 'true' : 'false'}
        isBoolean
      />
      <DetailPanelField
        md={2}
        label="Con firma digitale"
        value={infoRequester.withDigitalSignature ? 'true' : 'false'}
        isBoolean
      />
      <DetailPanelTitle children="ALTRE INFORMAZIONI" />
      <DetailPanelField md={12} label="Note del richiedente" value={infoRequest.notes} />
      {infoRequestPayments?.map((infoRequestPayment: Record<string, any>, index: number) => (
        <Fragment key={index}>
          <DetailPanelTitle children={`PAGAMENTO ${index + 1}`} />
          <DetailPanelField
            md={12}
            label=""
            value="Link di pagamento"
            url={`${infoRequestPayment.paymentLink}/${infoRequestPayment.id}`}
          />
          {infoRequestPayment.paymentMethod && (
            <>
              <DetailPanelField
                md={2}
                label="Metodo di pagamento"
                value={paymentFromId(infoRequestPayment.paymentMethod)}
              />
              <DetailPanelField md={2} label="Importo" value={infoRequestPayment.amount} isAmount />
              <AppFiller />
              {infoRequestPayment.paymentMethod === PaymentMethod.WireTransfer && (
                <>
                  <DetailPanelField md={2} label="Cro" value={infoRequestPayment.cro} />
                  <DetailPanelField
                    md={2}
                    label="Data cro"
                    value={infoRequestPayment.croDate}
                    isDate
                  />
                </>
              )}
              {infoRequestPayment.paymentMethod === PaymentMethod.CreditCard && (
                <>
                  <DetailPanelField
                    md={2}
                    label="Tipo di carta"
                    value={infoRequestPayment.creditCardType}
                  />
                  {infoRequestPayment.creditCardOwner && (
                    <>
                      <DetailPanelField
                        md={2}
                        label="Intestatario carta"
                        value={infoRequestPayment.creditCardOwner.name}
                      />
                      <DetailPanelField
                        md={2}
                        label="Email intestatario carta"
                        value={infoRequestPayment.creditCardOwner.email}
                      />
                    </>
                  )}
                  <DetailPanelField
                    md={2}
                    label="ID transazione"
                    value={infoRequestPayment.transactionId}
                  />
                </>
              )}
            </>
          )}
          {index !== infoRequestPayments.length - 1 && <AppDivider />}
        </Fragment>
      ))}
    </Grid>
  );
};

export default InfoRequestExpanded;
