import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { throttle as _throttle } from 'lodash';
import React, { cloneElement, FunctionComponent, ReactElement } from 'react';
import { TextInput, useListContext } from 'react-admin';
import { Form } from 'react-final-form';
import AppListFilterButton from '../button/AppListFilterButton';

const useStyles = makeStyles(
  (theme) => ({
    formContent: {
      padding: '4px 12px 12px 12px',
      width: '100%',
      '& ~ *': {
        display: 'none',
      },
    },
    fulltext: {
      display: 'flex',
    },
    filterForm: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    buttons: {
      paddingTop: (props) => theme.spacing((props as any).displayedFilters ? 0 : 1),
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  }),
  { name: 'AppListFilterForm' },
);

type AppListFilterFormProps = {
  helperText?: string;
  buttons?: ReactElement[];
  filtersList: Array<string>;
  initialFilters?: Record<string, any>;
  disableFulltextSearch?: boolean;
};

const AppListFilterForm: FunctionComponent<AppListFilterFormProps> = (props) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const { children, buttons, filtersList, initialFilters, disableFulltextSearch } = props;
  const hasAdditionalFilters = Object.keys(displayedFilters).length > 1;
  const classes = useStyles({ displayedFilters });

  const filterUpdateHandler = _throttle(
    (value: any, source: string, fulltext = false, minLength = 1) => {
      if (fulltext) {
        if (!value || value.length < 1) {
          setFilters({ ...filterValues, [source]: '' }, displayedFilters);
        } else if (value.length >= minLength) {
          setFilters({ ...filterValues, [source]: value }, displayedFilters);
        }
      } else {
        setFilters({ ...filterValues, [source]: value }, displayedFilters);
      }
    },
    350,
  );

  const onInputChange = (event: any, source: string, fulltext = false, minLength = 1): void => {
    filterUpdateHandler.cancel(); // delete pending invocations before calling a new one

    const value = typeof event === 'object' ? event.target.value ?? event.target.checked : event;
    filterUpdateHandler(value, source, fulltext, minLength);
  };

  const FilterFormContent = (
    <div className={classes.formContent}>
      <div className={classes.fulltext}>
        <TextInput
          style={{ flexGrow: 1 }}
          variant="outlined"
          source="q"
          resettable
          onChange={(e) => onInputChange(e, 'q', true, 3)}
          label="Filtra"
          placeholder="Inserisci almeno 3 caratteri"
          helperText={
            props.helperText
              ? `La ricerca libera è effettuata sui campi: ${props.helperText}`
              : false
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
          disabled={disableFulltextSearch}
        />
        <AppListFilterButton filtersList={filtersList} initialFilters={initialFilters} />
      </div>
      {hasAdditionalFilters && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.filterForm}
        >
          {hasAdditionalFilters &&
            React.Children.map(children, (child: any, index: number) => {
              return cloneElement(child as any, {
                key: index,
                variant: 'outlined',
                helperText: false,
                onChange: (e) =>
                  onInputChange(
                    e,
                    child.props.source || child.props.name,
                    child.props.fulltext,
                    child.props.minLength,
                  ),
              });
            })}
        </Grid>
      )}
      {buttons && (
        <div className={classes.buttons}>
          {buttons.map((btn, key) => cloneElement(btn, { key }))}
        </div>
      )}
    </div>
  );

  return (
    <Form
      onSubmit={() => console.log('submitted')} // not needed
      initialValues={filterValues}
      render={() => FilterFormContent}
    />
  );
};

export default AppListFilterForm;
