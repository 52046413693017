/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormControlProps, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { omit as _omit, pick as _pick } from 'lodash';
import React, { cloneElement, createElement, FC, Fragment, ReactElement } from 'react';
import { FormInput, Record as RaRecord } from 'react-admin';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { AppCreate } from './detail/AppCreate';
import { AppEdit } from './detail/AppEdit';
import { AppShow } from './detail/AppShow';
import AppTitle from './layout/AppTitle';
import { AppGridProps, Extended } from './types';

export const GRID_PROPS = [
  'alignContent',
  'alignItems',
  //'classes',
  //'className',
  //'component',
  //'container',
  'direction',
  //'item',
  'justifyContent',
  'lg',
  'md',
  'sm',
  'spacing',
  'wrap',
  'xl',
  'xs',
  'zeroMinWidth',
];

export function gridProps(props: Extended<AppGridProps>): AppGridProps {
  return _pick(props, GRID_PROPS);
}

const useStyles = makeStyles(
  {
    legend: {
      ['& label']: { display: 'none' },
      ['& legend']: { width: '0' },
    },
  },
  { name: 'GridWrappedInput' },
);

// Find a generic return type good for all inputs... I tried but I spent too much time
export function inputProps(props: Extended<FormControlProps>): never {
  return _omit(props, GRID_PROPS) as never;
  //return _omit(props, GRID_PROPS, 'basePath', 'children') as never;
}

export function gridWrappedInput(
  props: Extended<AppGridProps>,
  input: ReactElement,
  outOfGrid = false,
): ReactElement {
  /*
  <FormInput
                basePath={child.props.basePath ?? basePath}
                input={cloneElement(child, {
                  source: getChildSource(child),
                  index,
                  label: getChildLabel(child),
                })}
                record={record}
                resource={resource}
                variant={variant}
                margin={margin}
              />
   */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { basePath, record, resource, variant, margin, fullWidth, ...rest } = props;

  const classes = useStyles();
  if (rest.legend === false) {
    rest.className = (rest.className ?? '') + ' ' + classes.legend;
  }

  const formInput = (
    <FormInput
      basePath={basePath}
      // @ts-ignore
      input={cloneElement(input, { ...inputProps(rest), fullWidth: true })}
      record={record}
      resource={resource ?? ''}
      variant={variant}
      margin={margin}
    />
  );

  if (outOfGrid) return formInput;

  return (
    <Grid item {...gridProps(props)} xs={props.xs ?? 12} md={props.md ?? 6}>
      {formInput}
    </Grid>
  );
}

// *** CREATE/EDIT WRAPPER ***
export function resourceWrapper(
  mode: 'edit' | 'create' | 'show' | ReactElement,
  Child: FC<any> | ReactElement,
  title?: string | ((record: RaRecord) => string),
  actions?: false | ReactElement,
  resourcePermissions?: ResourcePermissions,
): (props) => ReactElement {
  let Component;

  if (typeof mode === 'string') {
    switch (mode) {
      case 'create':
        Component = AppCreate;
        break;
      case 'edit':
        Component = AppEdit;
        break;
      case 'show':
        Component = AppShow;
        break;
    }
  } else {
    Component = mode;
  }

  if (actions === false) actions = createElement(Fragment);

  return function AppContainer(props) {
    return (
      <Component
        {...props}
        actions={actions}
        title={<AppTitle {...props} title={title} mode={mode} />}
        resourcePermissions={resourcePermissions}
      >
        {React.isValidElement(Child) ? Child : <Child {...props} mode={mode} />}
      </Component>
    );
  };
}
