import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { ListProps } from 'react-admin';
import AppEditButton from '../../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../../Components/ui/detail/AppList';
import { AppTextField } from '../../../Components/ui/field';
import AppTitle from '../../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  () => ({
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ConfigurationList' },
);

const ConfigurationAgencyList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList {...props} title={<AppTitle title="Configurazioni agenzie" />}>
      <AppDatagrid rowClick="show">
        <AppTextField source="fullName" label="Agenzia" headerClassName={classes.lastCol} />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default ConfigurationAgencyList;
