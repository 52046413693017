import { AccountBox } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import ParticipantDetails from './ParticipantDetails';
import ParticipantList from './ParticipantList';

const Participant: ResourceExport = {
  list: ParticipantList,
  create: resourceWrapper('create', ParticipantDetails, undefined, undefined, undefined),
  edit: resourceWrapper('edit', ParticipantDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', ParticipantDetails, undefined, undefined, undefined),
  icon: AccountBox,
  options: { permissions: undefined, label: 'Partecipanti' },
};

export default Participant;
