import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { FunctionField, ListProps, NumberField, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import CommunicationsListLegend from './communications-list-legend';
import {
  COMMUNICATION_COLORS,
  COMMUNICATION_STATUSES,
  COMMUNICATION_TEMPLATES,
  COMMUNICATION_TYPES,
} from './constants';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    status: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    to: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    cc: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    bcc: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    property: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    template: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    remoteId: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    attempts: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    params: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
  }),
  { name: 'CommunicationList' },
);

const CommunicationListFilters: FunctionComponent = () => {
  return (
    <AppListFilter helperText="Indirizzo email">
      <AppSelectInput
        source="status"
        choices={COMMUNICATION_STATUSES}
        label="Stato"
        resettable={true}
        md={4}
      />
      <AppSelectInput
        source="template"
        choices={COMMUNICATION_TEMPLATES}
        label="Template"
        resettable={true}
        md={4}
      />
      <AppSelectInput
        source="type"
        choices={COMMUNICATION_TYPES}
        label="Tipo"
        resettable={true}
        md={4}
      />
    </AppListFilter>
  );
};

const CommunicationList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Comunicazioni" />}
      filters={<CommunicationListFilters />}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
    >
      <AppDatagrid
        resource="communication"
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor: COMMUNICATION_COLORS[record.status][0],
        })}
        additionalInfo={<CommunicationsListLegend />}
      >
        <FunctionField
          label="Data creazione"
          render={(record) => (
            <AppDateField
              source="createdAt"
              label="Data creazione"
              showTime
              headerClassName={classes.createdAt}
              cellClassName={classes.createdAt}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.createdAt}
          cellClassName={classes.createdAt}
        />
        <FunctionField
          label="Stato"
          render={(record) => (
            <SelectField
              choices={COMMUNICATION_STATUSES}
              source="status"
              label="Stato"
              headerClassName={classes.status}
              cellClassName={classes.status}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.status}
          cellClassName={classes.status}
        />
        <FunctionField
          label="Tipo"
          render={(record) => (
            <SelectField
              choices={COMMUNICATION_TYPES}
              source="data.type"
              label="Tipo"
              headerClassName={classes.type}
              cellClassName={classes.type}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.type}
          cellClassName={classes.type}
        />
        <FunctionField
          label="Destinatario"
          render={(record) => (
            <AppTextField
              source="data.to"
              record={record}
              label="Destinatario"
              headerClassName={classes.to}
              cellClassName={classes.to}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.to}
          cellClassName={classes.to}
        />
        <FunctionField
          label="CC"
          render={(record) => (
            <AppTextField
              source="data.cc"
              record={record}
              label="CC"
              headerClassName={classes.cc}
              cellClassName={classes.cc}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.cc}
          cellClassName={classes.cc}
        />
        <FunctionField
          label="BCC"
          render={(record) => (
            <AppTextField
              source="data.bcc"
              record={record}
              label="BCC"
              headerClassName={classes.bcc}
              cellClassName={classes.bcc}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.bcc}
          cellClassName={classes.bcc}
        />
        <FunctionField
          label="Property"
          render={(record) => (
            <AppTextField
              customText={
                record?.data.property ?? parseInt(record?.data?.propertyId) === 1
                  ? 'giudiziarie'
                  : parseInt(record?.data?.propertyId) === 2
                  ? 'immobili'
                  : parseInt(record?.data?.propertyId) === -1
                  ? 'internal'
                  : 'cda'
              }
              label="Property"
              headerClassName={classes.property}
              cellClassName={classes.property}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.property}
          cellClassName={classes.property}
        />
        <FunctionField
          label="Template"
          render={(record) => (
            <SelectField
              choices={COMMUNICATION_TEMPLATES}
              source="data.template"
              label="Template"
              headerClassName={classes.template}
              cellClassName={classes.template}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.template}
          cellClassName={classes.template}
        />
        <FunctionField
          label="Tentativi"
          render={(record) => (
            <NumberField
              source="attempts"
              label="Tentativi"
              headerClassName={classes.attempts}
              cellClassName={classes.attempts}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.attempts}
          cellClassName={classes.attempts}
        />
        <FunctionField
          label="ID remoto"
          render={(record) => (
            <NumberField
              source="remoteId"
              label="ID remoto"
              headerClassName={classes.remoteId}
              cellClassName={classes.remoteId}
              style={{
                color: COMMUNICATION_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.remoteId}
          cellClassName={classes.remoteId}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default CommunicationList;
