import { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { AppRelatedResourceContext } from '../../Components/related-resource/app-related-resource';
import AppFormDialog from '../../Components/ui/dialog/form-dialog/form-dialog';
import { AppAutocompleteInput } from '../../Components/ui/input';

const RolePermissionManager: FC<any> = (props) => {
  const { title, ...others } = props;
  const ctx = useContext(AppRelatedResourceContext);

  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [mutate] = useMutation();
  useEffect(() => {
    if (ctx.manager.isOpen && ctx.mainResourceRecord.id) {
      mutate(
        {
          type: 'getList',
          resource: `roles/${ctx.mainResourceRecord.id}/permissions/available`,
          payload: {},
        },
        {
          onSuccess: ({ data }) => {
            setAvailablePermissions(data);
          },
          onFailure: (error) => {
            console.log(error);
          },
        },
      );
    }
  }, [ctx.manager.isOpen, ctx.mainResourceRecord.id]);

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        ctx.manager.submit(data, close);
      }}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={120}
      height={60}
      {...others}
    >
      <AppAutocompleteInput
        label="Permesso"
        choices={availablePermissions}
        source="permissionId"
        optionText="description"
        required
      />
    </AppFormDialog>
  );
};

export default RolePermissionManager;
