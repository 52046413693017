import React, { FC, ReactElement } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppSelectArrayInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { AUCTION_ROOM_SLOT_LENGTHS, WEEK_DAYS } from '../../utils/constants';
import { padString } from '../../utils/data-formatters';
import { AUCTION_ROOM_PERMISSIONS } from './index';

const AuctionRoomDetails: FC<any> = (props): ReactElement => {
  const hours: string[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push(padString(i.toString()));
  }
  const timeValuesInt = hours.map((hour) => {
    const value = `${hour}:00:00`;
    return { id: value, name: value };
  });

  const timeValuesHalf = hours.map((hour) => {
    const valueHalf = `${hour}:30:00`;
    return { id: valueHalf, name: valueHalf };
  });

  const timeValues = [...timeValuesInt, ...timeValuesHalf].sort((a, b) => a.id.localeCompare(b.id));

  return (
    <AppBaseForm
      {...props}
      disabledCards={
        !hasViewPermissions(props.permissions?.loginRole, 'create', AUCTION_ROOM_PERMISSIONS) ||
        props.mode === 'show'
      }
    >
      <AppCard expanded title="Sala d'asta">
        <AppTextInput source="title" label="Titolo" variant="outlined" md={8} />
        <AppSelectInput
          source="slotLength"
          choices={AUCTION_ROOM_SLOT_LENGTHS}
          label="Slot"
          required
          md={4}
        />
        <AppTextInput
          source="description"
          reference="description"
          label="Descrizione"
          md={12}
          multiline
          expandable
        />
        <ArrayInput source="ranges" label="Intervalli" md={12}>
          <SimpleFormIterator reOrderButtons={<></>}>
            <AppSelectArrayInput source="weekDays" label="Giorni" choices={WEEK_DAYS} md={4} />
            <AppSelectInput source="from" label="Dalle" choices={timeValues} />
            <AppSelectInput source="to" label="Alle" choices={timeValues} />
          </SimpleFormIterator>
        </ArrayInput>
      </AppCard>
    </AppBaseForm>
  );
};

export default AuctionRoomDetails;
