import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import { FormRenderProps } from 'react-final-form';
import AppDivider from '../../../ui/layout/AppDivider';

const useStyles = makeStyles(
  (theme) => ({
    formButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'AdvertisementOutcomeFormActions' },
);

interface AdvertisementOutcomeFormActionsProps {
  formProps?: FormRenderProps<any, Partial<any>>;
}

export const AdvertisementOutcomeFormActions: FC<AdvertisementOutcomeFormActionsProps> = ({
  formProps,
}) => {
  const classes = useStyles();
  if (!formProps) return <Fragment />;

  const { handleSubmit, submitting, pristine, values } = formProps;

  if (!values['outcomeType']) return <Fragment />;

  return (
    <>
      <AppDivider />
      <Grid container justify="flex-end">
        <Grid item md={2} className={classes.formButton}>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
            disabled={submitting || pristine}
          >
            Invia esito
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
