import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { DateField, FunctionField, ListProps, NumberField, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import { ECWID_EVENT_COLORS, ECWID_EVENT_STATUSES, ECWID_EVENT_TYPES } from './constants';
import EcwidEventsListLegend from './ecwid-events-list-legend';

const useStyles = makeStyles(
  (theme) => ({
    eventCreated: {
      whiteSpace: 'nowrap',
    },
    status: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    type: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    entityId: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    attempts: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
  }),
  { name: 'EcwidEventList' },
);

const EcwidEventListFilters: FunctionComponent = () => {
  return (
    <AppListFilter helperText="Evento">
      <AppSelectInput
        source="status"
        choices={ECWID_EVENT_STATUSES}
        label="Stato"
        resettable={true}
        md={4}
      />
      <AppSelectInput
        source="eventType"
        choices={ECWID_EVENT_TYPES}
        label="Tipo"
        resettable={true}
        md={4}
      />
    </AppListFilter>
  );
};

const EcwidEventList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Eventi ecwid" />}
      filters={<EcwidEventListFilters />}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
    >
      <AppDatagrid
        resource="ecwid"
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor: ECWID_EVENT_COLORS[record.status][0],
        })}
        additionalInfo={<EcwidEventsListLegend />}
      >
        <FunctionField
          label="Stato"
          render={(record) => (
            <SelectField
              choices={ECWID_EVENT_STATUSES}
              source="status"
              label="Stato"
              headerClassName={classes.status}
              cellClassName={classes.status}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.status}
          cellClassName={classes.status}
        />
        <FunctionField
          label="Tipo"
          render={(record) => (
            <SelectField
              choices={ECWID_EVENT_TYPES}
              source="eventType"
              label="Tipo"
              headerClassName={classes.type}
              cellClassName={classes.type}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.type}
          cellClassName={classes.type}
        />
        <FunctionField
          label="Data evento"
          render={(record) => (
            <DateField
              source="eventCreated"
              label="Data evento"
              headerClassName={classes.eventCreated}
              cellClassName={classes.eventCreated}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
              options={{ hour: 'numeric', minute: 'numeric', second: 'numeric' }}
            />
          )}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="ID entità"
          render={(record) => (
            <NumberField
              source="entityId"
              label="ID entità"
              headerClassName={classes.entityId}
              cellClassName={classes.entityId}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
              options={{ useGrouping: false }}
            />
          )}
          headerClassName={classes.entityId}
          cellClassName={classes.entityId}
        />
        <FunctionField
          label="Tentativi"
          render={(record) => (
            <NumberField
              source="attempts"
              label="Tentativi"
              headerClassName={classes.attempts}
              cellClassName={classes.attempts}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
            />
          )}
          headerClassName={classes.attempts}
          cellClassName={classes.attempts}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default EcwidEventList;
