import { ListItemIcon, MenuItem } from '@material-ui/core';
import { ExitToApp as LogoutIcon } from '@material-ui/icons';
import { useLogout } from 'ra-core';
import React, { useCallback } from 'react';
import { AppText } from '../../../../text';

export const LogoutButton = React.memo(() => {
  const logout = useLogout();
  const handleLogout = useCallback(() => logout(undefined, undefined, true), [logout]);

  return (
    <MenuItem onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <AppText children="Logout" />
    </MenuItem>
  );
});
