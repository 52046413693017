import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import AppFormDialog from '../../Components/ui/dialog/form-dialog/form-dialog';
import { AppBooleanInput } from '../../Components/ui/input';

const useStyles = makeStyles(
  (theme) => ({
    message: {},
    resetPasswordFlag: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'AppToolbarReEnableAccess' },
);

const AppToolbarReEnableAccess: FC<any> = (props) => {
  const { handleClose, record, open } = props;
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const changeStatus = (data) => {
    mutate(
      {
        type: 'create',
        resource: 'auth/set-access-state',
        payload: {
          data: {
            user: record.id,
            state: 'active',
            resetPassword: data.resetPassword,
          },
        },
      },
      {
        onSuccess: () => {
          handleClose();
          refresh();
          notify(
            `L'utente è stato riattivato e potrà nuovamente accedere alla piattaforma${
              data.resetPassword ? ' dopo aver eseguito il reset della password' : ''
            }.`,
          );
        },
        onFailure: (err) => {
          console.error(err);
          handleClose();
          notify(`Riattivazione fallita. ${err.message}.`, 'error');
        },
      },
    );
  };

  return (
    <AppFormDialog
      open={open}
      onClose={handleClose}
      title="Riattivazione utente"
      subtitle="Confermando la procedura l'utente sarà riattivato. Spunta il relativo flag per eseguire
        anche il reset della password."
      onSubmit={changeStatus}
      record={{}}
      canSubmitIfPristine
      width={80}
      height={45}
    >
      <AppBooleanInput
        source="resetPassword"
        label="Invia mail per il reset della password"
        md={12}
        className={classes.resetPasswordFlag}
        helperText={false}
      />
    </AppFormDialog>
  );
};

export default AppToolbarReEnableAccess;
