import {
  Button,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import React, { Children, cloneElement, FC, ReactElement, useRef } from 'react';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { useToggleState } from '../../hooks/use-toggle-state';
import AppAlert from '../ui/alert/AppAlert';
import AppConfirmDialog from '../ui/dialog/confirm-dialog/app-confirm-dialog';

const useStyles = makeStyles(
  (theme) => {
    return {
      drawer: {
        overflow: 'hidden',
      },
      formContainer: (props: any) => ({
        maxWidth: props.width || 'calc(100vw - 35rem)', //TODO dimensione in base a media-query
        width: props.width || 'calc(100vw - 35rem)',
        maxHeight: '100vh',
        height: '100vh',
        overflow: 'hidden',
      }),
      content: {
        //backgroundColor: 'red',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingTop: theme.spacing(3),
        paddingBottom: 0,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      title: {
        flex: 'auto',
        marginRight: theme.spacing(2),
      },
      toolbar: {
        '& button:not(.close)': {
          marginLeft: theme.spacing(2),
        },
      },
    };
  },
  { name: 'AppDrawerForm' },
);

interface AppDrawerFormProps {
  anchor: 'top' | 'right' | 'bottom' | 'left';
  open: boolean;
  onClose: (event?: any) => void;
  title: string;
  onSubmit: (data, close) => void;
  onRemove?: (data, close) => void;
  saveBtn?: boolean;
  saveCloseBtn?: boolean;
  deleteBtn?: boolean;
  width?: string;
  forceDisable?: boolean;
  record: Record<string, any>;
  mode: 'edit' | 'insert' | null;
}

const AppDrawerForm: FC<AppDrawerFormProps> = (props) => {
  const {
    anchor,
    open,
    onClose,
    children,
    title,
    onSubmit,
    saveBtn = false,
    saveCloseBtn = true,
    deleteBtn = false,
    onRemove,
    width,
    forceDisable = false,
    ...others
  } = props;
  const classes = useStyles({ width });
  const notify = useNotify();

  const closeMe = useRef(false);

  const [confirmDialogOpen, toggleConfirmDialog] = useToggleState();

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <div className={classes.formContainer}>
        <Form
          initialValues={others.record}
          mutators={arrayMutators as any}
          onSubmit={(data) => {
            onSubmit(data, closeMe.current);
          }}
          keepDirtyOnReinitialize
          //validate={handleFormValidation}
          //validateOnBlur={true}
          render={(formProps) => {
            const { handleSubmit, submitting, pristine, hasValidationErrors /*errors*/ } =
              formProps;
            const disabled = forceDisable || submitting || pristine;

            const checkValidationErrors = () =>
              hasValidationErrors && notify('ra.message.invalid_form', 'error');

            return (
              <React.Fragment>
                <MuiAppBar position="relative">
                  <Toolbar className={classes.toolbar}>
                    <IconButton onClick={onClose} className="close">
                      <Close />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                      {title.length > 45 ? `${title.substr(0, 45)}...` : title}
                    </Typography>
                    {saveBtn && (
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          checkValidationErrors();
                          closeMe.current = false;
                          handleSubmit();
                        }}
                        variant="contained"
                        color="secondary"
                        autoFocus
                        startIcon={<Save />}
                        children="Salva"
                      />
                    )}
                    {saveCloseBtn && (
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          checkValidationErrors();
                          closeMe.current = true;
                          handleSubmit();
                        }}
                        variant="contained"
                        color="secondary"
                        autoFocus
                        startIcon={<Save />}
                        children="Salva e chiudi"
                      />
                    )}
                    {deleteBtn && others.mode === 'edit' && (
                      <>
                        <Button
                          onClick={() => toggleConfirmDialog()}
                          variant="contained"
                          color="secondary"
                          children={<Delete color="error" />}
                        />
                        <AppConfirmDialog
                          onConfirm={() => {
                            toggleConfirmDialog();
                            onRemove && onRemove(others.record, true);
                          }}
                          onClose={toggleConfirmDialog}
                          open={confirmDialogOpen}
                          title="Confermi la cancellazione?"
                          details={
                            <AppAlert
                              title="Attenzione"
                              children={"L'azione non è reversibile."}
                              severity="warning"
                            />
                          }
                        />
                      </>
                    )}
                  </Toolbar>
                </MuiAppBar>
                <div className={classes.content}>
                  <Grid container spacing={2}>
                    {Children.map(children as ReactElement[], (child: ReactElement) =>
                      cloneElement(child, others),
                    )}
                  </Grid>
                </div>
              </React.Fragment>
            );
          }}
        />
      </div>
    </Drawer>
  );
};

export default AppDrawerForm;
