import { ResourceExport } from '../../types/resource-export.type';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AuctionRoomBookingCalendar from './AuctionRoomBookingCalendar';

const auctionRoomBookingCalendar: ResourceExport = {
  list: AuctionRoomBookingCalendar,
  icon: CalendarTodayIcon,
};

export default auctionRoomBookingCalendar;
