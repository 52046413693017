import ComplaintIcon from '@material-ui/icons/Warning';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import ComplaintDetails from './ComplaintDetails';
import ComplaintList from './ComplaintList';

const Complaint: ResourceExport = {
  list: ComplaintList,
  create: resourceWrapper('create', ComplaintDetails, undefined, undefined),
  edit: resourceWrapper('edit', ComplaintDetails, undefined, undefined),
  show: resourceWrapper('show', ComplaintDetails, undefined, undefined),
  icon: ComplaintIcon,
};

export default Complaint;
