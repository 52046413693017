import { Button, Grid, makeStyles } from '@material-ui/core';
import { Check, Clear, CreditCard } from '@material-ui/icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useBasePath } from '../../hooks/use-base-path';
import AppGoToResourceButton from '../ui/button/AppGoToResourceButton';
import AppConfirmDialog from '../ui/dialog/confirm-dialog/app-confirm-dialog';

const useStyles = makeStyles(
  (theme) => ({
    verifyButton: {
      marginTop: -theme.spacing(1.5),
      marginLeft: theme.spacing(4),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: 15,
      '&:disabled': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
      },
    },
    goToPaymentsButton: {
      marginTop: -theme.spacing(1.5),
      marginLeft: theme.spacing(4),
    },
  }),
  { name: 'VerifyPaymentButton' },
);

export const VerifyBalanceButton: FC<any> = ({ record }) => {
  const notify = useNotify();
  const basePath = useBasePath();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const toggleDialogOpen = () => setDialogOpened((prevState) => !prevState);

  const [buttonState, setButtonState] = useState<Record<string, any>>({
    label: 'Verifica pagamento',
    icon: CreditCard,
    disabled: false,
  });

  const currentSaleExperimentId: number | null = useMemo(
    () => record.relatedSaleExperiments?.find((sale) => sale.isCurrent)?.id ?? null,
    [record.relatedSaleExperiments],
  );

  const [mutatePayment] = useMutation();

  const [balancePayment, setBalancePayment] = useState<Record<string, any> | null>(null);

  const classes = useStyles();

  useEffect(() => {
    mutatePayment(
      {
        type: 'getOne',
        resource: `${basePath}/${record.id}/get-balance-payment`,
        payload: {},
      },
      {
        onSuccess: (res) => setBalancePayment(Object.values(res.data).length > 0 ? res.data : null),
        onFailure: (err) => {
          console.error('[VerifyPaymentButton] Error retrieving balance info:', err);
        },
      },
    );
  }, []);

  const handleVerifyPayment = () => {
    if (!balancePayment) return;

    mutatePayment(
      {
        type: 'getOne',
        resource: `items/${record.id}/verify-balance`,
        payload: {},
      },
      {
        onSuccess: () => {
          setButtonState({
            label: 'Pagamento verificato',
            icon: Check,
            disabled: true,
          });
          toggleDialogOpen();
          notify('Operazione conclusa con successo.', 'info');
        },
        onFailure: (err) => {
          console.error(err);
          notify(err.message ?? "Errore nello svolgimento dell'operazione.", 'error');
        },
      },
    );
  };

  useEffect(() => {
    if (!balancePayment || balancePayment.pending) return;

    setButtonState({
      label: balancePayment.verified ? 'Pagamento verificato' : 'Pagamento rifiutato',
      icon: balancePayment.verified ? Check : Clear,
      disabled: true,
    });
  }, [balancePayment]);

  return (
    <Grid item>
      {!!balancePayment && (
        <>
          <Button
            children={buttonState.label}
            className={classes.verifyButton}
            endIcon={<buttonState.icon />}
            disabled={buttonState.disabled}
            variant="outlined"
            onClick={toggleDialogOpen}
          />
          <AppConfirmDialog
            open={dialogOpened}
            onConfirm={handleVerifyPayment}
            onClose={toggleDialogOpen}
            details="Confermando l'operatore comunica al sistema l'avvenuta verifica del pagamento del saldo per questo lotto."
          />
        </>
      )}
      <AppGoToResourceButton
        mode="list"
        destinationResourceName="payments"
        buttonLabel="Vai ai pagamenti"
        size="large"
        className={classes.goToPaymentsButton}
        listQueryString={
          currentSaleExperimentId
            ? `filter=%7B"saleExperimentId"%3A"${currentSaleExperimentId}"%7D`
            : ''
        }
        targetBlank
      />
    </Grid>
  );
};
