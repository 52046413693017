import { ContainerProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import arrayMutators from 'final-form-arrays';
import { omit as _omit } from 'lodash';
import React, { FunctionComponent } from 'react';
import { TabbedForm, TabbedFormProps, TabbedFormTabs } from 'react-admin';
import AppBottomToolbar from './AppBottomToolbar';

const useStyles = makeStyles(
  (theme: Theme) => {
    const height = 'calc(100vh - 11rem)';
    return {
      appTabbedForm: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        //backgroundColor: 'green',
        flex: '1 1 100%',
        height: height,
        minHeight: height,
        maxHeight: height,
        overflowY: 'auto',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
      },
    };
  },
  { name: 'AppTabbedForm' },
);

type AppTabbedFormProps = TabbedFormProps & Pick<ContainerProps, 'maxWidth'>;

const AppTabbedForm: FunctionComponent<AppTabbedFormProps> = (props) => {
  const classes = useStyles(props);
  const { alwaysEnableSaveButton, children, maxWidth, ...rest } = props;

  const clearInputs = ([name], state, { changeValue }) => {
    //accepts both a single field name and an array of names
    if (name instanceof Array) {
      name.forEach((name) => changeValue(state, name, () => undefined));
    } else {
      changeValue(state, name, () => undefined);
    }
  };

  return (
    <TabbedForm
      children={React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { maxWidth, ...child.props })
          : child,
      )}
      {..._omit(rest, ['hasEdit', 'hasList', 'hasShow', 'hasCreate'])}
      className={classes.appTabbedForm}
      classes={_omit(classes, 'appTabbedForm')}
      toolbar={<AppBottomToolbar {...rest} alwaysEnableSaveButton={alwaysEnableSaveButton} />}
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      mutators={{ clearInputs, ...arrayMutators /*setInput*/ }}
    />
  );
};

AppTabbedForm.propTypes = TabbedForm.propTypes;
AppTabbedForm.defaultProps = TabbedForm.defaultProps;

export default AppTabbedForm;
