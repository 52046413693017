import { Grid } from '@material-ui/core';
import _get from 'lodash/get';
import React, { FC } from 'react';
import { AppBold, AppText } from '../../ui/text';

interface ItemInfoFieldProps {
  record?: Record<string, any>;
  field?: string;
  customValue?: string;
  label?: string;
  md?: boolean | 6 | 'auto' | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12;
  placeholder?: boolean;
}

const ItemInfoField: FC<ItemInfoFieldProps> = ({
  record,
  field = '',
  customValue,
  label = '',
  md = 6,
  placeholder,
}) =>
  placeholder ? (
    <Grid item md={md} />
  ) : (
    <Grid item md={md}>
      <AppText>
        <AppBold>{label.toUpperCase()}</AppBold>
      </AppText>
      <AppText>{customValue ?? _get(record, field, '-')}</AppText>
    </Grid>
  );

export default ItemInfoField;
