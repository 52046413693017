import { ListItemIcon, MenuItem } from '@material-ui/core';
import { VpnKey as ChangePasswordIcon } from '@material-ui/icons';
import React, { FC } from 'react';
import { AppText } from '../../../../text';

interface ChangePasswordButtonProps {
  toggleUpdatePasswordDialog: () => void;
}

export const ChangePasswordButton: FC<ChangePasswordButtonProps> = React.memo(
  ({ toggleUpdatePasswordDialog }) => (
    <MenuItem onClick={toggleUpdatePasswordDialog}>
      <ListItemIcon>
        <ChangePasswordIcon />
      </ListItemIcon>
      <AppText children="Cambia Password" />
    </MenuItem>
  ),
);
