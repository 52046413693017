import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ChipField, ListProps, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { PARTICIPATION_FORMS_SET_PERMISSIONS } from './index';

const useStyles = makeStyles(
  () => ({
    title: { whiteSpace: 'nowrap' },
    description: { whiteSpace: 'nowrap' },
    genres: { whiteSpace: 'nowrap' },
    pvpRites: { whiteSpace: 'nowrap' },
    saleTypes: { whiteSpace: 'nowrap' },
    saleModes: { whiteSpace: 'nowrap' },
  }),
  { name: 'ParticipationFormsSetList' },
);

const ParticipationFormsSetList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Moduli di partecipazione" />}
      resourcePermissions={PARTICIPATION_FORMS_SET_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField
          source="title"
          label="Titolo"
          headerClassName={classes.title}
          cellClassName={classes.title}
        />
        <AppTextField
          source="description"
          label="Descrizione"
          headerClassName={classes.description}
          cellClassName={classes.description}
        />
        <ReferenceArrayField
          reference="genres"
          source="genres"
          label="Generi"
          headerClassName={classes.genres}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          reference="pvp/rites"
          source="pvpRites"
          label="Riti"
          headerClassName={classes.pvpRites}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          reference="sale-types"
          source="saleTypes"
          label="Tipi di vendita"
          headerClassName={classes.saleTypes}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          reference="sale-modes"
          source="saleModes"
          label="Modalità di vendita"
          headerClassName={classes.saleModes}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
      </AppDatagrid>
    </AppList>
  );
};

export default ParticipationFormsSetList;
