import { User } from '../types/user.type';
import { PersonRoles } from '../utils/constants';

export const WHITE_LIST = [PersonRoles.SuperAdmin, PersonRoles.Administrator];

export interface ResourcePermissions {
  canEdit: number[];
  canShow: number[];
  canCreate: number[];
  canList: number[];
}

/**
 * Permissions check for standard views (CREATE, EDIT, SHOW, LIST)
 * @param loginRole
 * @param view
 * @param resourcePermissions
 * @deprecated
 */
export const hasViewPermissions = (
  loginRole: Record<string, any>,
  view: string,
  resourcePermissions: ResourcePermissions,
): boolean => {
  if (loginRole) {
    if (WHITE_LIST.includes(loginRole.fkRole)) return true;

    switch (view) {
      case 'edit':
        return checkRoleMatch(resourcePermissions.canEdit, loginRole);
      case 'show':
        return checkRoleMatch(resourcePermissions.canShow, loginRole);
      case 'create':
        return checkRoleMatch(resourcePermissions.canCreate, loginRole);
      case 'list':
        return (
          !!resourcePermissions?.canList?.length &&
          checkRoleMatch(resourcePermissions.canList, loginRole)
        );
      default:
        throw 'View is invalid';
    }
  }

  return true;
};

export enum ResourceAction {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export const userIsAllowed = (
  action: ResourceAction,
  resource: string,
  user: User | undefined,
): boolean =>
  !!user &&
  (user.admin ||
    user.permissions.findIndex((p) => p.path === resource && p.method === action) > -1);

/**
 * Permissions check for dashboard tables
 * @param loginRole
 * @param permissionsList
 */
export const hasDashboardTableViewPermissions = (
  loginRole: Record<string, any>,
  permissionsList: PersonRoles[],
): boolean => !!loginRole && [...WHITE_LIST, ...permissionsList].includes(loginRole.fkRole);

/// region UTILS

/**
 * @param viewRoles
 * @param loginRole
 * @returns boolean
 * @deprecated
 */
const checkRoleMatch = (viewRoles: number[], loginRole: Record<string, any>): boolean => {
  // check if user's login role matches any of the roles of the current view
  return viewRoles.includes(loginRole.fkRole);
};

/// endregion
