import React, { FC } from 'react';
import PageSelection from '../../../../Resources/Cms/Pages/PageSelection';
import AppCard from '../../../ui/card/AppCard';
import {
  AppBooleanInput,
  AppColorInput,
  AppNumberInput,
  AppRichTextInput,
  AppTextInput,
} from '../../../ui/input';
import { AppImageInput } from '../../../ui/input/AppImageInput';
import AppDivider from '../../../ui/layout/AppDivider';
import AppFiller from '../../../ui/layout/AppFiller';
import AppSectionTitle from '../../../ui/layout/AppSectionTitle';
import { ConfigurationSection } from '../configuration-section';

const BASE_SOURCE = 'websiteCustomization';

export const WebsiteCustomizationDetails: FC<any> = React.memo((props) => {
  return (
    <AppCard {...props} title="Configurazione sito">
      <ConfigurationSection
        {...props}
        baseSource={`${BASE_SOURCE}.Generic`}
        title="Generiche"
        required
      >
        <AppTextInput source="Title" label="Titolo" />
        <AppBooleanInput source="RoundedCorners" label="Angoli arrotondati" />
        <AppNumberInput source="CreditCardFee" label="Commissione carta di credito" />
        <AppNumberInput source="WireTransferFee" label="Commissione bonifico" />
        <AppTextInput source="SenderEmail" label="Mittente email automatiche" />
        <AppTextInput source="Loader" label="Loader (JSON)" multiline expandable />
        <PageSelection
          source="PaymentDoneThankYouPageSlug"
          label="Pagina ringraziamento saldo"
          mode={props.mode}
        />
        <AppNumberInput source="RealEstateExpenses" label="Spese immobiliari" />
        <AppNumberInput source="RealEstateBalance" label="Compensi immobiliari" />
        <AppNumberInput source="MovableExpenses" label="Spese mobiliari" />
        <AppNumberInput source="MovableBalance" label="Compensi mobiliari" />
        <AppBooleanInput
          source="DownloadAttachmentsOnlyIfLoggedIn"
          label="Scarica allegati solo se loggato"
        />
        <AppNumberInput
          source="MaxNumberOfPhotosToUpload"
          label="Limite foto annunci da FE"
          md={12}
          required={false}
        />
        <AppNumberInput source="DaysOf107" label="Giorni a107" />
        <AppNumberInput source="DaysBeforeItemIsLegacy" label="Giorni prima che lotto sia legacy" />
        <AppTextInput
          source="MapSearchInitialCoordinates"
          label="Coordinate mappa della pagina di ricerca all'atterraggio"
          required={false}
        />
        <AppNumberInput
          source="MapSearchInitialZoom"
          label="Zoom mappa della pagina di ricerca all'atterraggio"
          required={false}
        />
        <AppTextInput
          source="WireTransfersBeneficiary"
          label="Beneficiario bonifici"
          required={false}
        />
        <AppFiller />
        <AppDivider />
        <AppSectionTitle titleVariant="h5">
          Impostazioni di default per saldo con bonifico
        </AppSectionTitle>
        <AppTextInput
          source="DefaultWireTransfersIBAN"
          label="IBAN di default per saldo con bonifico"
          required={false}
        />
        <AppTextInput
          source="DefaultWireTransfersBeneficiary"
          label="Beneficiario di default per saldo con bonifico"
          required={false}
        />
        <AppTextInput
          source="DefaultWireTransfersBank"
          label="Banca di default per saldo con bonifico"
          required={false}
        />
        <AppDivider />
        <AppBooleanInput
          source="AllowBookingOnlyIfLoggedIn"
          label="Richiesta di visita solo se loggato"
        />
        <AppBooleanInput
          source="AllowInfoRequestOnlyIfLoggedIn"
          label="Richiesta di informazioni solo se loggato"
        />
        <AppTextInput
          source="RealEstateGuaranteesDisclaimerText"
          label="Disclaimer garanzie (immobiliari)"
          multiline
          expandable
        />
        <AppTextInput
          source="MovableGuaranteesDisclaimerText"
          label="Disclaimer garanzie (mobiliari)"
          multiline
          expandable
        />
        <AppTextInput source="PinTutorialUrl" label="URL tutorial utilizzo PIN di gara" md={12} />
        <AppBooleanInput
          source="VisitsAttachedDocumentsNeeded"
          label="Documenti obbligatori per prenotazione visita"
          md={3}
        />
        <AppBooleanInput
          source="AllowSingleBookingOnlyForUser"
          label="Una sola prenotazione per utente a lotto"
          md={3}
        />
        <AppBooleanInput
          source="AllowBookingThroughPvp"
          label="Accetta prenotazioni tramite PVP"
          md={3}
        />
        <AppBooleanInput
          source="VisitsTaxIdMandatory"
          label="Codice fiscale obbligatorio per le richieste di visita"
          md={3}
        />
        <AppBooleanInput
          source="CollectExpressionsOfInterestAndOffersCollectionIfAdv"
          label="Gestisci iscrizioni a Manifestazione di Interesse e Raccolta Offerte sulla piattaforma anche se in pubblicità"
          md={12}
        />
        {/* MAGAZINE */}
        <AppNumberInput
          source="MagazineTitleLengthPremium"
          label="MagazineTitleLengthPremium"
          md={4}
          required={false}
        />
        <AppNumberInput
          source="MagazineTitleLength2X"
          label="MagazineTitleLength2X"
          md={4}
          required={false}
        />
        <AppNumberInput
          source="MagazineTitleLength3X"
          label="MagazineTitleLength3X"
          md={4}
          required={false}
        />
        <AppNumberInput
          source="MagazineDescriptionLengthPremium"
          label="MagazineDescriptionLengthPremium"
          md={4}
          required={false}
        />
        <AppNumberInput
          source="MagazineDescriptionLength2X"
          label="MagazineDescriptionLength2X"
          md={4}
          required={false}
        />
        <AppNumberInput
          source="MagazineDescriptionLength3X"
          label="MagazineDescriptionLength3X"
          md={4}
          required={false}
        />
        {/* COLORS */}
        <AppSectionTitle titleVariant="h5">Colori</AppSectionTitle>
        <AppColorInput
          source="Colors.Primary"
          label="Primary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.Secondary"
          label="Secondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.Accent"
          label="Accent"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.AccentSecondary"
          label="AccentSecondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.HeaderPayoff"
          label="HeaderPayoff"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.TextOverSearchPayoff"
          label="TextOverSearchPayoff"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.TextOverBgSecondary"
          label="TextOverBgSecondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchRealEstate"
          label="SearchRealEstate"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchMovable"
          label="SearchMovable"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchOther"
          label="SearchOther"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        {/* COOKIE POLICY */}
        <AppDivider />
        <AppSectionTitle titleVariant="h5">Cookie Policy</AppSectionTitle>
        <AppRichTextInput source="CookiePolicy.TextBefore" label="Testo cookie policy (prima)" />
        <AppRichTextInput source="CookiePolicy.TextAfter" label="Testo cookie policy (dopo)" />
        {/* INFO REQUEST */}
        <AppDivider />
        <AppSectionTitle titleVariant="h5">Richiesta informazioni</AppSectionTitle>
        <AppTextInput source="InfoRequest.Title" label="Titolo" required={false} />
        <AppRichTextInput source="InfoRequest.Text" label="Testo" />
        {/* LIVEN UP */}
        <AppDivider />
        <AppSectionTitle titleVariant="h5">Vivacizzazione</AppSectionTitle>
        <AppTextInput source="LivenUp.HomepageTitle" label="Titolo homepage" required={false} />
        <AppRichTextInput source="LivenUp.HomepageText" label="Testo homepage" />
        <AppTextInput
          source="LivenUp.DetailPageTitle"
          label="Titolo pagina dettaglio"
          required={false}
        />
        <AppRichTextInput source="LivenUp.DetailPageText" label="Testo pagina dettaglio" />
        <AppTextInput
          source="LivenUp.RequestPageTitle"
          label="Titolo pagina richiesta"
          required={false}
        />
        <AppRichTextInput source="LivenUp.RequestPageText" label="Testo pagina richiesta" />
        <AppTextInput
          source="LivenUp.RequestPageWithItemTitle"
          label="Titolo pagina richiesta (con lotto)"
          required={false}
        />
        <AppRichTextInput
          source="LivenUp.RequestPageWithItemText"
          label="Testo pagina richiesta (con lotto)"
        />
        <AppTextInput
          source="LivenUp.RequestPageDisclaimer"
          label="Disclaimer pagina richiesta"
          required={false}
        />
        <AppRichTextInput
          source="LivenUp.RequestPageBannerText"
          label="Testo banner pagina richiesta"
        />
        <AppTextInput
          source="LivenUp.RequestPageBannerLink"
          label="Link banner pagina richiesta"
          required={false}
        />
        <AppFiller />
        <AppTextInput
          source="LivenUp.PaymentPageTitle"
          label="Titolo pagina pagamento"
          required={false}
        />
        <AppRichTextInput source="LivenUp.PaymentPageText" label="Testo pagina pagamento" />
        <AppDivider />
        <AppImageInput
          source="LivenUp.HomepageImageFile"
          accept="image/png"
          label="Immagine homepage"
          isRequired
          previewSource="LivenUp.HomepageImage"
          previewLabel="Immagine homepage corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LivenUp.DetailPageImageFile"
          accept="image/png"
          label="Immagine pagina dettaglio"
          isRequired
          previewSource="LivenUp.DetailPageImage"
          previewLabel="Immagine pagina dettaglio corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LivenUp.RequestPageBannerImageFile"
          accept="image/png"
          label="Immagine banner pagina richiesta"
          isRequired
          previewSource="LivenUp.RequestPageBannerImage"
          previewLabel="Immagine banner pagina richiesta corrente"
        />
        {/* IMAGES */}
        <AppDivider />
        <AppImageInput
          source="LogoFile"
          accept="image/png"
          label="Logo gestionale"
          isRequired
          previewSource="Logo"
          previewLabel="Logo gestionale corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoSmallFile"
          accept="image/png"
          label="Logo gestionale (piccolo)"
          isRequired
          previewSource="LogoSmall"
          previewLabel="Logo gestionale (piccolo) corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoDesktopFile"
          accept="image/png"
          label="Logo sito desktop"
          isRequired
          previewSource="LogoDesktop"
          previewLabel="Logo sito desktop corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoMobileFile"
          accept="image/png"
          label="Logo sito mobile"
          isRequired
          previewSource="LogoMobile"
          previewLabel="Logo sito mobile corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoVirtualTourFile"
          accept="image/png"
          label="Logo virtual tour"
          isRequired
          previewSource="LogoVirtualTour"
          previewLabel="Logo VT corrente"
        />
        <AppDivider />
        <AppImageInput
          source="PlaceholderImageFile"
          accept="image/png"
          label="Immagine placeholder"
          isRequired
          previewSource="PlaceholderImage"
          previewLabel="Immagine placeholder corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LoaderGifFile"
          accept="image/gif"
          label="Loader"
          isRequired
          previewSource="LoaderGif"
          previewLabel="Loader corrente"
        />
        <AppImageInput
          source="WatermarkFile"
          accept="image/png"
          label="Watermark"
          isRequired
          previewSource="Watermark"
          previewLabel="Watermark corrente"
        />
      </ConfigurationSection>
      <ConfigurationSection {...props} baseSource={`${BASE_SOURCE}.DefaultSEO`} title="SEO">
        <AppTextInput source="Title" label="Titolo" required />
        <AppTextInput source="Description" label="Descrizione" required />
        <AppImageInput
          source="ImageFile"
          accept="image/png"
          label="Immagine"
          isRequired
          previewSource="Image.url"
          previewLabel="Immagine corrente"
        />
      </ConfigurationSection>
      <ConfigurationSection
        {...props}
        baseSource={`${BASE_SOURCE}.ComponentsVisibility`}
        title="Visibilità componenti sito"
      >
        <AppBooleanInput source="HideInfoDeposit" label="Nascondi informazioni cauzione" />
        <AppTextInput
          source="InfoDepositAltText"
          label="Testo personalizzato informazioni cauzione"
          multiline
          expandable
        />
        <AppBooleanInput source="HidePricesComparison" label="Nascondi comparazione prezzi" />
        <AppBooleanInput source="HideSaleHistory" label="Nascondi storico vendite" />
      </ConfigurationSection>
    </AppCard>
  );
});
