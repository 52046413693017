import { makeStyles } from '@material-ui/core/styles';
import { MenuBookTwoTone } from '@material-ui/icons';
import React, { FC } from 'react';
import { Record as RaRecord } from 'react-admin';
import { MediaSecurityType } from '../../utils/constants';

const useStyles = makeStyles(
  (theme) => {
    const BADGE = {
      top: theme.spacing(3),
      left: theme.spacing(4.5),
      backgroundColor: 'white',
      border: '1px solid',
      borderRadius: '23px',
      padding: '3px',
    };

    return {
      imagePreview: {
        width: '80%',
        maxHeight: theme.spacing(25),
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      magazine1: {
        position: 'absolute',
        color: 'red',
        ...BADGE,
      },
      magazine2: {
        position: 'absolute',
        color: 'blue',
        ...BADGE,
      },
      mediaOrigin: {
        textAlign: 'center',
      },
    };
  },
  { name: 'ImagePreview' },
);

interface ImagePreviewProps {
  record: RaRecord;
}

const ImagePreview: FC<ImagePreviewProps> = React.memo((props) => {
  const { record } = props;
  const classes = useStyles();

  if (!['image', 'virtual-tour'].includes(record.role.type)) return <></>;

  return (
    <>
      <img
        src={
          record.role.mediaSecurityType === MediaSecurityType.Protected
            ? `data:${record.metadata.mimeType};base64,${record.metadata.base64}`
            : //: `${process.env.REACT_APP_CDN_BASE_URI}/${record.metadata.remotePath}/${record.metadata.generatedName}`
              record.url
        }
        className={classes.imagePreview}
        alt="Anteprima non disponibile"
      />
      {record.magazineRole && (
        <MenuBookTwoTone className={classes[`magazine${record.magazineRole}`]} />
      )}
    </>
  );
});

export default ImagePreview;
