import {
  LocalAtm as AccountingIcon,
  HomeWork as AuctionRoomIcon,
  Settings as ConfigurationIcon,
  MenuBookTwoTone as DocumentsIcon,
  Gavel as JudiciaryIcon,
  ImportContacts as MagazineIcon,
  FolderShared as PrivateIcon,
  Publish as PublishIcon,
  AccountBalance as PvpIcon,
  SvgIconComponent,
} from '@material-ui/icons';
import SecurityIcon from '@material-ui/icons/Security';
import { LoadScript } from '@react-google-maps/api';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import italianMessages from 'ra-language-italian';
import { ReactElement, useRef } from 'react';
import { Admin, Resource } from 'react-admin';
import AppDashboard from './Components/dashboard/app-dashboard';
import AppPageNotFound from './Components/page-not-found/app-page-not-found';
import AppHeader from './Components/ui/layout/AppHeader';
import AppLayout from './Components/ui/layout/AppLayout';
import AppDataProvider from './Providers/AppDataProvider';
import AuthManager from './Providers/AuthManager';
import AuthProvider from './Providers/AuthProvider';
import CustomRoutesProvider from './Providers/CustomRoutesProvider';
import AppTheme from './Providers/ThemeProvider';
import auctionRoomResourceProps from './Resources/AuctionRoom';
import auctionRoomBookingResourceProps from './Resources/AuctionRoomBooking';
import auctionRoomBookingCalendarResourceProps from './Resources/AuctionRoomBookingCalendar';
import AppLogin from './Resources/Auth/Login/AppLogin';
import bookingResourceProps from './Resources/Booking';
import buyNowVariantsResourceProps from './Resources/BuyNowVariant';
import categoryResourceProps from './Resources/Category';
import chatbotImportResourceProps from './Resources/ChatbotImport';
import clicksCounterProps from './Resources/ClicksCounter';
import configurationResourceProps from './Resources/Cms/Configuration';
import footerSlidesResourceProps from './Resources/Cms/FooterSlides';
import highlightsResourceProps from './Resources/Cms/Highlights';
import linksResourceProps from './Resources/Cms/Links';
import messagesResourceProps from './Resources/Cms/Messages';
import pagesResourceProps from './Resources/Cms/Pages';
import configurationAgencyResourceProps from './Resources/CmsAgency/Configuration';
import linksAgenciesResourceProps from './Resources/CmsAgency/Links';
import pagesAgencyResourceProps from './Resources/CmsAgency/Pages';
import communicationResourceProps from './Resources/Communication';
import companyResourceProps from './Resources/Company';
import complaintResourceProps from './Resources/Complaint';
import courtResourceProps from './Resources/Court';
import decreeResourceProps from './Resources/Decree';
import ecwidEventResourceProps from './Resources/EcwidEvent';
import externalPropertiesUpdatesResourceProps from './Resources/ExternalPropertiesUpdates';
import feeProfileProps from './Resources/FeeProfile';
import folderResourceProps from './Resources/Folder';
import frontEndUsersResourceProps from './Resources/FrontEndUsers';
import frontEndUsersCdaResourceProps from './Resources/FrontEndUsersCda';
import frontEndUsersImmoResourceProps from './Resources/FrontEndUsersImmo';
import frontEndUsersProfileModificationResourceProps from './Resources/FrontEndUsersProfileModification';
import infoRequestResourceProps from './Resources/InfoRequest';
import servicePaymentResourceProps from './Resources/InfoRequestPayment';
import invoiceResourceProps from './Resources/Invoice';
import itemResourceProps from './Resources/Item';
import magazineResourceProps from './Resources/Magazine';
import magazineCoverResourceProps from './Resources/MagazineCover';
import magazineIndexResourceProps from './Resources/MagazineIndex';
import magazinePlaceholderResourceProps from './Resources/MagazinePlaceholder';
import massivePublicationResourceProps from './Resources/MassivePubblication';
import massivePublicationBuyNowResourceProps from './Resources/MassivePubblicationBuyNow';
import mediaRolesResourceProps from './Resources/MediaRoles';
import opLogResourceProps from './Resources/OpLog';
import participantResourceProps from './Resources/Participant';
import participationAttemptResourceProps from './Resources/ParticipationAttempt';
import participationFormsSetResourceProps from './Resources/ParticipationFormsSet';
import paymentResourceProps from './Resources/Payment';
import permissionResourceProps from './Resources/Permission';
import personResourceProps from './Resources/Person';
import personSellerResourceProps from './Resources/PersonSeller';
import propertyResourceProps from './Resources/Property';
import publicationProofProps from './Resources/PublicationProof';
import pvpAssignmentResourceProps from './Resources/PvpAssignment';
import publicationRequestResourceProps from './Resources/PvpPublicationRequest';
import releaseNotesProps from './Resources/ReleaseNotes';
import personRoleResourceProps from './Resources/Role';
import saleExperimentResourceProps from './Resources/SaleExperiment';
import saleExperimentCalendarProps from './Resources/SaleExperimentCalendar';
import screenResourceProps from './Resources/Screen';
import servicesStatusProps from './Resources/ServicesStatus';
import shippingResourceProps from './Resources/Shipping';
import { User } from './types/user.type';

const appDataProvider = new AppDataProvider();
const i18nProvider = polyglotI18nProvider(() => italianMessages, 'it');

interface GroupOption {
  id: string;
  label?: string;
  icon?: SvgIconComponent;
  visibleByIvg?: boolean;
  adminOnly?: boolean; // Group is visible in the menu only if user is an admin
}

const GRP_GENERAL: GroupOption = { id: 'general', label: 'Generale' };
const GRP_JUDICIARY: GroupOption = {
  id: 'judiciary',
  label: 'Giudiziario',
  icon: JudiciaryIcon,
  visibleByIvg: true,
};
const GRP_PRIVATE: GroupOption = {
  id: 'private',
  label: 'Privato',
  icon: PrivateIcon,
};
const GRP_PVP: GroupOption = { id: 'pvp', label: 'PVP', icon: PvpIcon, visibleByIvg: true };
const GRP_CONFIGURATIONS: GroupOption = {
  id: 'cms',
  label: 'CMS',
  icon: ConfigurationIcon,
  visibleByIvg: true,
};

const GRP_AGENCIES_CONFIGURATIONS: GroupOption = {
  id: 'cms-agencies',
  label: 'CMS agenzie',
  icon: ConfigurationIcon,
  visibleByIvg: false,
};

const GRP_MAGAZINE: GroupOption = {
  id: 'magazine',
  label: 'Bollettini',
  icon: MagazineIcon,
  visibleByIvg: true,
};
const GRP_DOCUMENTS: GroupOption = {
  id: 'documents',
  label: 'Documenti',
  icon: DocumentsIcon,
  visibleByIvg: true,
};
const GRP_ACCOUNTING: GroupOption = {
  id: 'accounting',
  label: 'Contabilità',
  icon: AccountingIcon,
  visibleByIvg: true,
};
const GRP_AUCTIONROOM: GroupOption = {
  id: 'auction-room',
  label: "Sale d'asta",
  icon: AuctionRoomIcon,
  visibleByIvg: true,
};
const GRP_CATALOGS: GroupOption = {
  id: 'catalogs',
  label: 'Cataloghi',
  visibleByIvg: true,
  adminOnly: true,
};
const GRP_MASSIVE_PUBLICATION: GroupOption = {
  id: 'massive-publication',
  label: 'Pubblicazione massiva',
  icon: PublishIcon,
  visibleByIvg: true,
};

const GRP_PERMISSIONS: GroupOption = {
  id: 'permissions',
  label: 'Ruoli e permessi',
  icon: SecurityIcon,
  visibleByIvg: true,
};

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const App = (): ReactElement => {
  const geocoderRef = useRef<any>();

  //* Workaround to customize private menu items for agency users
  const userJson = AuthManager.getUserInfo();
  const user: User = userJson ? JSON.parse(userJson) : {};
  const isAgency = user.agency ? true : false;

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      libraries={['places']}
      onLoad={() => {
        geocoderRef.current = new window['google'].maps.Geocoder();
      }}
    >
      <Admin
        theme={AppTheme}
        authProvider={AuthProvider}
        dataProvider={appDataProvider}
        i18nProvider={i18nProvider}
        customRoutes={CustomRoutesProvider}
        dashboard={AppDashboard}
        catchAll={AppPageNotFound}
        layout={AppLayout}
        loginPage={AppLogin}
      >
        <AppHeader />

        {/* Importante!
        Le risorse non appartenenti a un tree non hanno bisogno della property "group" nelle options

        Le risorse appartenenti allo stesso tree devono avere, nelle options, lo stesso valore assegnato alla property "group";
        Se devo aggiungere una gruppo di risorse come tree-view è possibile, dentro AppMenu.tsx, andare a indicare nell'apposito array globale "GROUPS_OPTIONS"
        la label e l'icona che avrà il nodo padre del tree-view

        Allo stato attuale è gestito solo il tree-view a 1 livello
    */}

        {/* Person */}
        <Resource
          {...personResourceProps}
          name="people"
          options={{
            ...personResourceProps.options,
            label: 'Persone',
            geocoderRef: geocoderRef,
          }}
        />
        <Resource
          name="companies"
          options={{ label: 'Aziende', geocoderRef: geocoderRef }}
          {...companyResourceProps}
        />

        {/* Participant */}
        <Resource
          {...participantResourceProps}
          name="participants"
          options={{
            ...participantResourceProps.options,
            geocoderRef: geocoderRef,
          }}
        />

        {/* FRONT END USERS GIUDIZIARIE */}
        <Resource
          name="front-end-users"
          options={{ label: `Utenti ${IS_IVG ? 'sito istituto' : 'CdA Giudiziarie'}` }}
          {...frontEndUsersResourceProps}
        />

        {/* FRONT END USERS IMMOBILIARE */}
        <Resource
          name="front-end-users-immo"
          options={{ label: 'Utenti CdA Immobiliare' }}
          {...frontEndUsersImmoResourceProps}
        />

        {/* FRONT END USERS CDA */}
        <Resource
          name="front-end-users-cda"
          options={{ label: 'Utenti CdA Mobiliare' }}
          {...frontEndUsersCdaResourceProps}
        />

        {/* FRONT END USERS PROFILE MODIFICATION */}
        <Resource
          name="profile-modification-requests"
          options={{
            label: 'Richiesta modifica dati',
            noEdit: true,
          }}
          {...frontEndUsersProfileModificationResourceProps}
        />

        {/* Booking */}
        <Resource name="bookings" options={{ label: 'Prenotazioni' }} {...bookingResourceProps} />

        {/* Info request */}
        <Resource
          name="info-requests"
          options={{ label: 'Richieste info' }}
          {...infoRequestResourceProps}
        />

        {/* Complaints */}
        {!IS_IVG ? (
          <Resource
            name="complaints"
            options={{ label: 'Controversie', noEdit: true }}
            {...complaintResourceProps}
          />
        ) : (
          <></>
        )}

        {!IS_IVG ? (
          <Resource
            name="shippings"
            options={{ label: 'Spedizioni', noEdit: true }}
            {...shippingResourceProps}
          />
        ) : (
          <></>
        )}

        {/* Group: ACCOUNTING */}
        <Resource
          name="payments"
          options={{ label: 'Pagamenti', group: { ...GRP_ACCOUNTING } }}
          {...paymentResourceProps}
        />
        <Resource
          name="info-request-payments"
          options={{ label: 'Pagamenti servizi', group: { ...GRP_ACCOUNTING } }}
          {...servicePaymentResourceProps}
        />
        <Resource
          name="invoices"
          options={{ label: 'Fatturazioni CSV', noEdit: true, group: { ...GRP_ACCOUNTING } }}
          {...invoiceResourceProps}
        />

        {/* GROUP: GENERAL */}
        <Resource
          name="folders"
          options={{ label: 'Tutti i fascicoli', group: { ...GRP_GENERAL } }}
          {...folderResourceProps}
        />
        <Resource
          name="items"
          options={{ label: 'Tutti i lotti', group: { ...GRP_GENERAL }, geocoderRef: geocoderRef }}
          {...itemResourceProps}
        />
        <Resource
          name="sale-experiments"
          options={{
            label: 'Tutte le vendite',
            group: { ...GRP_GENERAL },
            geocoderRef: geocoderRef,
          }}
          {...saleExperimentResourceProps}
        />

        <Resource
          name="sale-experiments-calendar"
          options={{ label: 'Calendario vendite', group: { ...GRP_GENERAL } }}
          {...saleExperimentCalendarProps}
        />

        {/* GROUP: JUDICIARY */}
        <Resource
          name="folders-judiciary"
          options={{ label: 'Fascicoli giudiziari', group: { ...GRP_JUDICIARY } }}
          {...folderResourceProps}
        />
        <Resource
          name="items-judiciary"
          options={{
            label: 'Lotti giudiziari',
            group: { ...GRP_JUDICIARY },
            geocoderRef: geocoderRef,
          }}
          {...itemResourceProps}
        />
        <Resource
          name="sale-experiments-judiciary"
          options={{
            label: 'Vendite giudiziarie',
            group: { ...GRP_JUDICIARY },
            geocoderRef: geocoderRef,
          }}
          {...saleExperimentResourceProps}
        />
        <Resource
          name="sale-experiments-judiciary-calendar"
          options={{ label: 'Calendario vendite', group: { ...GRP_JUDICIARY } }}
          {...saleExperimentCalendarProps}
        />
        <Resource
          name="chatbot-import"
          options={{
            label: 'Stato importazioni',
            group: { ...GRP_JUDICIARY },
            hidden: process.env.REACT_APP_CHATBOT_OWNER !== 'true',
          }}
          {...chatbotImportResourceProps}
        />
        <Resource
          name="fee-profiles"
          options={{ label: 'Profili cauzioni', group: GRP_JUDICIARY }}
          {...feeProfileProps}
        />

        {/* GROUP: PVP */}
        <Resource
          name="pvp-publication-requests"
          options={{ label: 'Richieste di pubblicazione', group: { ...GRP_PVP } }}
          {...publicationRequestResourceProps}
        />
        <Resource
          name="pvp-assignments"
          options={{ label: 'Registro incarichi', group: { ...GRP_PVP } }}
          {...pvpAssignmentResourceProps}
        />

        {/* GROUP: PRIVATE */}
        {!isAgency ? (
          <Resource
            name="folders-private"
            options={{ label: 'Pratiche private', group: { ...GRP_PRIVATE } }}
            {...folderResourceProps}
          />
        ) : (
          <></>
        )}
        <Resource
          name="items-private"
          options={{
            label: isAgency ? 'Elenco lotti' : 'Lotti privati',
            group: isAgency ? undefined : { ...GRP_PRIVATE },
            geocoderRef: geocoderRef,
          }}
          {...itemResourceProps}
        />
        <Resource
          name="sale-experiments-private"
          options={{
            label: isAgency ? 'Elenco vendite' : 'Vendite private',
            group: isAgency ? undefined : { ...GRP_PRIVATE },
            geocoderRef: geocoderRef,
          }}
          {...saleExperimentResourceProps}
        />
        <Resource
          name="sale-experiments-private-calendar"
          options={{
            label: 'Calendario vendite',
            group: isAgency ? undefined : { ...GRP_PRIVATE },
          }}
          {...saleExperimentCalendarProps}
        />

        {/* GROUP: Magazine */}
        <Resource
          name="magazine"
          options={{ label: 'Gestione bollettini', group: { ...GRP_MAGAZINE } }}
          {...magazineResourceProps}
        />
        <Resource
          name="magazine-cover"
          options={{ label: 'Copertine', group: { ...GRP_MAGAZINE } }}
          {...magazineCoverResourceProps}
        />
        <Resource
          name="magazine-index"
          options={{ label: 'Indici', group: { ...GRP_MAGAZINE } }}
          {...magazineIndexResourceProps}
        />
        <Resource
          name="magazine-placeholder"
          options={{ label: 'Tappi', group: { ...GRP_MAGAZINE } }}
          {...magazinePlaceholderResourceProps}
        />

        {/* GROUP: Auction room */}
        <Resource
          name="auction-rooms"
          options={{ label: "Sale d'asta", group: { ...GRP_AUCTIONROOM } }}
          {...auctionRoomResourceProps}
        />
        <Resource
          name="auction-rooms-bookings"
          options={{ label: 'Prenotazioni', group: { ...GRP_AUCTIONROOM } }}
          {...auctionRoomBookingResourceProps}
        />
        <Resource
          name="auction-rooms-bookings-calendar"
          options={{ label: 'Calendario prenotazioni', group: { ...GRP_AUCTIONROOM } }}
          {...auctionRoomBookingCalendarResourceProps}
        />

        {/* Participation Forms Sets */}
        <Resource
          name="participation-forms-sets"
          options={{ label: 'Moduli di partecipazione', group: { ...GRP_DOCUMENTS } }}
          {...participationFormsSetResourceProps}
        />

        {/* Decrees */}
        <Resource
          name="decrees"
          options={{ label: 'Decreti e provvedimenti', group: { ...GRP_DOCUMENTS } }}
          {...decreeResourceProps}
        />

        {/* Participation attempts */}
        <Resource
          name="participation-attempts"
          options={{ label: 'Tentativi di partecipazione', noEdit: true }}
          {...participationAttemptResourceProps}
        />

        {/* Communications */}
        <Resource
          name="communications"
          options={{ label: 'Comunicazioni', noEdit: true }}
          {...communicationResourceProps}
        />

        {/* Publication Proof */}
        <Resource
          name="publication-proofs"
          options={{ label: 'Giustificativi', noEdit: true }}
          {...publicationProofProps}
        />

        {/* GROUP: CONFIGURATIONS */}
        <Resource
          name="configurations"
          options={{ label: 'Configurazioni siti', group: { ...GRP_CONFIGURATIONS } }}
          {...configurationResourceProps}
        />

        <Resource
          name="highlights"
          options={{ label: 'Procedure in evidenza', group: { ...GRP_CONFIGURATIONS } }}
          {...highlightsResourceProps}
        />

        <Resource
          name="footer-slides"
          options={{ label: 'Footer', group: { ...GRP_CONFIGURATIONS } }}
          {...footerSlidesResourceProps}
        />

        <Resource
          name="messages"
          options={{ label: 'Popup', group: { ...GRP_CONFIGURATIONS } }}
          {...messagesResourceProps}
        />

        <Resource
          name="pages"
          options={{ label: 'Pagine', group: { ...GRP_CONFIGURATIONS } }}
          {...pagesResourceProps}
        />

        <Resource
          name="links"
          options={{ label: 'Links', group: { ...GRP_CONFIGURATIONS } }}
          {...linksResourceProps}
        />

        {/* GROUP: AGENCIES CONFIGURATIONS */}
        <Resource
          name="configurations-agency"
          options={{
            label: 'Configurazioni agenzie',
            group: { ...GRP_AGENCIES_CONFIGURATIONS },
            visibleByAgency: false,
          }}
          {...configurationAgencyResourceProps}
        />

        <Resource
          name="pages-agencies"
          options={{
            label: 'Pagine agenzie',
            group: { ...GRP_AGENCIES_CONFIGURATIONS },
            visibleByAgency: false,
          }}
          {...pagesAgencyResourceProps}
        />

        <Resource
          name="links-agencies"
          options={{
            label: 'Links agenzie',
            group: { ...GRP_AGENCIES_CONFIGURATIONS },
            visibleByAgency: false,
          }}
          {...linksAgenciesResourceProps}
        />

        {/* Publications */}
        <Resource
          name="external-properties-update"
          options={{ label: 'Pubblicazioni', noEdit: true }}
          {...externalPropertiesUpdatesResourceProps}
        />

        {/* Massive publications */}
        <Resource
          name="massive-publications"
          options={{
            label: 'Idealista/Immobiliare.it',
            group: { ...GRP_MASSIVE_PUBLICATION },
            noEdit: true,
          }}
          {...massivePublicationResourceProps}
        />
        <Resource
          name="massive-publications-buy-now"
          options={{
            label: 'Compra subito',
            group: { ...GRP_MASSIVE_PUBLICATION },
            noEdit: true,
          }}
          {...massivePublicationBuyNowResourceProps}
        />

        {/* Logs */}
        <Resource name="op-log" options={{ label: 'Logs', noEdit: true }} {...opLogResourceProps} />

        {/* Participation attempts */}
        <Resource
          name="participation-attempts"
          options={{ label: 'Tentativi di partecipazione', noEdit: true }}
          {...participationAttemptResourceProps}
        />

        {/* Buy Now Variants */}
        <Resource
          name="buy-now-variants"
          options={{ label: 'Varianti compra subito' }}
          {...buyNowVariantsResourceProps}
        />

        {/* Ecwid Events */}
        <Resource
          name="ecwid"
          options={{ label: 'Eventi ecwid', noEdit: true }}
          {...ecwidEventResourceProps}
        />

        {/* Publications */}
        <Resource
          name="external-properties-update"
          options={{ label: 'Pubblicazioni', noEdit: true }}
          {...externalPropertiesUpdatesResourceProps}
        />

        {/* GROUP: CATALOGS */}
        <Resource
          name="properties"
          options={{ label: 'Siti', group: { ...GRP_CATALOGS } }}
          {...propertyResourceProps}
        />
        <Resource
          name="media-roles"
          options={{ label: 'Ruoli Media', group: { ...GRP_CATALOGS } }}
          {...mediaRolesResourceProps}
        />
        <Resource
          name="categories"
          options={{ label: 'Categorie', group: { ...GRP_CATALOGS } }}
          {...categoryResourceProps}
        />
        <Resource
          name="courts"
          options={{ label: 'Tribunali', group: { ...GRP_CATALOGS } }}
          {...courtResourceProps}
        />
        <Resource
          name="screens"
          options={{ label: "Schermi d'asta", group: { ...GRP_CATALOGS } }}
          {...screenResourceProps}
        />
        <Resource
          name="person-sellers"
          options={{ label: 'Proprietari', group: { ...GRP_CATALOGS } }}
          {...personSellerResourceProps}
        />

        {/* GROUP: PERMISSIONS */}
        <Resource
          name="roles"
          options={{ label: 'Ruoli', group: { ...GRP_PERMISSIONS } }}
          {...personRoleResourceProps}
        />
        <Resource
          name="permissions"
          options={{ label: 'Catalogo permessi', group: { ...GRP_PERMISSIONS } }}
          {...permissionResourceProps}
        />

        {/* Services status */}
        <Resource
          name="services-status"
          options={{ label: 'Stato servizi', noEdit: true }}
          {...servicesStatusProps}
        />

        {/* Release notes */}
        <Resource
          name="release-notes"
          options={{ label: 'Aggiornamenti', noEdit: true }}
          {...releaseNotesProps}
        />

        {/* Clicks counter */}
        {!IS_IVG ? (
          <Resource
            name="clicks-counter"
            options={{ label: 'Contatore click', noEdit: true }}
            {...clicksCounterProps}
          />
        ) : (
          <></>
        )}

        {/* Logs */}
        <Resource name="op-log" options={{ label: 'Logs', noEdit: true }} {...opLogResourceProps} />

        {/* Route-only resources*/}
        {isAgency ? <Resource name="folders-private" intent="route" /> : <></>}
        <Resource name="countries" intent="route" />
        <Resource name="regions" intent="route" />
        <Resource name="provinces" intent="route" />
        <Resource name="cities" intent="route" />
        <Resource name="genres" intent="route" />
        <Resource name="categories" intent="route" />
        <Resource name="typologies" intent="route" />
        <Resource name="pvp/registers" />
        <Resource name="pvp/rites" />
        <Resource name="sale-types" />
        <Resource name="sale-modes" />
        <Resource name="sale-status" />
        <Resource name="competences" />
        <Resource name="bookings/get-people-and-companies" />
        <Resource name="info-requests/get-people" />
        <Resource name="participations" />
        <Resource name="shippings/get-participations" />
        <Resource name="complaints/get-items" />
      </Admin>
    </LoadScript>
  );
};

export default App;
