import { Box, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StoreIcon from '@material-ui/icons/Store';
import { FC } from 'react';
import { ArrayField, Datagrid, DateField, FunctionField } from 'react-admin';
import { useHistory } from 'react-router';
import { ECWID_EVENT_COLORS } from '../../../Resources/EcwidEvent/constants';
import { useDashboardContext } from '../../../hooks';
import { ConfigurationAgencyGenre, PersonRoles } from '../../../utils/constants';
import { AppTextField } from '../../ui/field';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  () => ({
    eventCreated: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'AppDashboardEcwidEvents' },
);

const AppDashboardEcwidEvents: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useDashboardContext();

  if (
    user?.agency?.genre === ConfigurationAgencyGenre.RealEstate ||
    user?.roleId === PersonRoles.Auctioneer
  )
    return null;

  return (
    <AppDashboardCard {...gridProps} md={12}>
      <AppDashboardTable
        resource="ecwid"
        filter={{
          eventType: 'order.created',
        }}
        tableTitle="Ordini ricevuti tramite Compra Subito"
        tableSubtitle="Di seguito sono elencati gli ordini ricevuti tramite Compra Subito"
        noItemMessage="Non sono presenti ordini"
        noItemIcon={<StoreIcon />}
        rowClick={(id) =>
          history.push(
            `ecwid/${id}/show?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
          )
        }
      >
        <FunctionField
          label="Data evento"
          render={(record) => (
            <DateField
              source="eventCreated"
              label="Data evento"
              headerClassName={classes.eventCreated}
              cellClassName={classes.eventCreated}
              style={{
                color: ECWID_EVENT_COLORS[record?.status][1],
              }}
              options={{ hour: 'numeric', minute: 'numeric', second: 'numeric' }}
            />
          )}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="Email"
          render={(record) => <AppTextField customText={record?.entityData?.email} />}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="Totale"
          render={(record) => <AppTextField type="amount" customText={record?.entityData?.total} />}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="Totale"
          render={(record) => <AppTextField customText={record?.entityData?.paymentMethod} />}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="Tipo di spedizione"
          render={(record) => (
            <AppTextField customText={record?.entityData?.shippingOption?.shippingMethodName} />
          )}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <FunctionField
          label="Indirizzo di spedizione"
          render={(record) => (
            <Box sx={{ display: 'block' }}>
              <AppTextField component="p" customText={record?.entityData?.shippingPerson?.name} />
              <AppTextField component="p" customText={record?.entityData?.shippingPerson?.phone} />
              <AppTextField
                component="p"
                customText={`${record?.entityData?.shippingPerson?.street}, ${record?.entityData?.shippingPerson?.city}, ${record?.entityData?.shippingPerson?.stateOrProvinceName} (${record?.entityData?.shippingPerson?.stateOrProvinceCode})`}
                truncate
              />
            </Box>
          )}
          headerClassName={classes.eventCreated}
          cellClassName={classes.eventCreated}
        />
        <ArrayField
          source="entityData.items"
          label=""
          headerClassName={classes.lastCol}
          cellClassName={classes.lastCol}
          sortable={false}
        >
          <Datagrid>
            <AppTextField source="name" label="Nome" truncate sortable={false} />
            <AppTextField source="sku" label="Sku" sortable={false} />
            <AppTextField type="amount" source="price" label="Prezzo" sortable={false} />
            <AppTextField source="quantity" label="Quantità" sortable={false} />
          </Datagrid>
        </ArrayField>
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardEcwidEvents;
