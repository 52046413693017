import { ListProps, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { BooleanField, ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppSelectField, AppTextField } from '../../Components/ui/field';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { FEE_PROFILE_TYPE_CHOICES } from './constants';
import { FeeProfileExpand } from './FeeProfileExpand';

const useStyles = makeStyles(
  () => ({
    name: { whiteSpace: 'nowrap' },
    type: { whiteSpace: 'nowrap' },
    genres: { whiteSpace: 'nowrap' },
    pvpRites: { whiteSpace: 'nowrap' },
    saleTypes: { whiteSpace: 'nowrap' },
    saleModes: { width: '100%' },
  }),
  { name: 'FeeProfileList' },
);

const FeeProfileList: FC<ListProps> = React.memo<ListProps>((props) => {
  const classes = useStyles();
  return (
    <AppList {...props} title={<AppTitle title="Profili cauzioni" />}>
      <AppDatagrid rowClick="show" expand={FeeProfileExpand}>
        <AppTextField
          source="name"
          label="Nome"
          headerClassName={classes.name}
          cellClassName={classes.name}
        />
        <BooleanField source="active" label="Abilitato" />
        <AppSelectField
          source="type"
          choices={FEE_PROFILE_TYPE_CHOICES}
          label="Tipologia profilo"
          headerClassName={classes.type}
        />
        <ReferenceArrayField
          reference="genres"
          source="genres"
          label="Generi"
          headerClassName={classes.genres}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        {/* <ReferenceArrayField
          reference="pvp/rites"
          source="pvpRites"
          label="Riti"
          headerClassName={classes.pvpRites}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          reference="sale-types"
          source="saleTypes"
          label="Tipi di vendita"
          headerClassName={classes.saleTypes}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField> */}
        <ReferenceArrayField
          reference="sale-modes"
          source="saleModes"
          label="Modalità di vendita"
          headerClassName={classes.saleModes}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
});

export default FeeProfileList;
