import { makeStyles } from '@material-ui/core/styles';
import { CreateProps } from 'ra-ui-materialui/src/types';
import React, { Fragment, ReactElement } from 'react';
import {
  CreateContextProvider,
  CreateView,
  useCheckMinimumRequiredProps,
  useCreateController,
} from 'react-admin';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';

const useStyles = makeStyles(
  {
    card: {
      overflow: 'hidden',
    },
  },
  {
    name: 'AppCreate',
  },
);

export const AppCreate = (
  props: CreateProps & { children: ReactElement } & { resourcePermissions?: ResourcePermissions },
): ReactElement => {
  useCheckMinimumRequiredProps('AppCreate', ['children'], props);
  const controllerProps = useCreateController(props);
  const appCreateClasses = useStyles();
  const { actions, permissions, resource, ...rest } = props;

  /*
  const notify = useNotify();
  const redirect = useRedirect();
  // check if user can access create view
  useEffect(() => {
    if(resourcePermissions && !hasViewPermissions(permissions.loginRole, 'create', resourcePermissions)) {
      if(resource === 'magazine') {
        redirect(() => '/');
      } else {
        redirect('list', props.basePath);
      }
      notify('Accesso alla creazione non consentito.', 'error', {}, false, 5000);
    }
  }, []);
   */

  return (
    <CreateContextProvider value={controllerProps}>
      <CreateView
        {...rest}
        {...controllerProps}
        permissions={permissions}
        resource={resource}
        actions={actions ?? <Fragment />}
        component='div'
        classes={appCreateClasses}
      />
    </CreateContextProvider>
  );
};
