import { makeStyles } from '@material-ui/core/styles';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { FunctionField, ListContextProvider } from 'react-admin';
import { DetailPanelTitle } from '../../detail-panel';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';

const useStyles = makeStyles(
  (theme) => ({
    lastCol: {
      width: '100%',
    },
    titleCol: {
      minWidth: theme.spacing(30),
    },
    categoryAndTypologyCol: {
      minWidth: theme.spacing(30),
    },
    addressCol: {
      minWidth: theme.spacing(20),
    },
    list: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'RequestDetailsItemsList' },
);

interface RequestDetailsItemsListProps {
  items: Record<string, any>[];
}

const RequestDetailsItemsList: FC<RequestDetailsItemsListProps> = ({ items }) => {
  const classes = useStyles();
  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  useEffect(() => {
    const itemsWithIds = items.map((item, index) => ({ id: index + 1, ...item }));
    setListCtx(
      _assign({}, listBaseCtx, {
        data: _keyBy(itemsWithIds, 'id'),
        ids: _map(itemsWithIds, 'id'),
        total: itemsWithIds.length,
        loaded: true,
        loading: false,
      }),
    );
  }, [items]);

  return (
    <Fragment>
      <DetailPanelTitle children="LISTA DEI BENI" />
      <ListContextProvider value={listCtx}>
        <AppDatagrid>
          <AppTextField source="primoIdentificativoBene" label="Identificativo del bene" />
          <AppTextField
            source="descrizioneIT"
            label="Titolo"
            truncate
            headerClassName={classes.titleCol}
          />
          <AppTextField
            source="tipologia"
            label="Categoria"
            truncate
            headerClassName={classes.categoryAndTypologyCol}
          />
          <AppTextField
            source="categoria"
            label="Tipologia"
            truncate
            headerClassName={classes.categoryAndTypologyCol}
          />
          <FunctionField
            render={(record) => (
              <AppTextField customText={_getLocation(record.ubicazione, true)} truncate />
            )}
            label="Indirizzo"
            headerClassName={classes.addressCol}
          />
          <FunctionField
            render={(record) => (
              <AppTextField customText={_getLocation(record.ubicazione)} truncate />
            )}
            label="Città"
            headerClassName={classes.lastCol}
          />
        </AppDatagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export default RequestDetailsItemsList;

function _getLocation(locationData: Record<string, any>, getAddress?: boolean): string {
  if (!locationData) return '';

  return getAddress
    ? locationData.indirizzo ?? ''
    : locationData.citta
    ? `${locationData.citta} (${locationData.capZipCode}), ${locationData.provincia}, ${locationData.regione}, ${locationData.nazione}`
    : '';
}
