import { FC, ReactElement } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import { AppTextInput } from '../../Components/ui/input';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';

const BuyNowVariantDetails: FC<any> = (props): ReactElement => {
  return (
    <AppTabbedForm variant="outlined" {...props} title="Variante del compra subito">
      <AppFormTab label="Generale">
        <AppCard title="Informazioni generali" expanded>
          <div>
            <AppTextInput source="name" label="Nome" md={12} disabled={props.mode === 'show'} />
          </div>

          <ArrayInput
            source="values"
            label="Valori variante"
            isRequired
            disabled={props.mode === 'show'}
          >
            <SimpleFormIterator disableRemove TransitionProps={{ enter: false, exit: false }}>
              <AppTextInput source="value" label="Valore" />
            </SimpleFormIterator>
          </ArrayInput>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default BuyNowVariantDetails;
