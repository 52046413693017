import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import AppCard from '../ui/card/AppCard';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../ui/input';
import AppDivider from '../ui/layout/AppDivider';
import AppSectionTitle from '../ui/layout/AppSectionTitle';

export const AppPublicationIdealista: FC<any> = React.memo(
  ({ item, metadata, typologyType, mode }) => {
    const { values } = useFormState();
    const { change } = useForm();

    const idealistaMetadata = useMemo(() => JSON.parse(metadata), [metadata]);

    const [selectedCategory, setSelectedCategory] = useState<Record<string, any> | null>(null);

    useEffect(() => {
      if (mode === 'insert') {
        if (idealistaMetadata.categories && idealistaMetadata.categories.length) {
          const matchingCategory = idealistaMetadata?.mapping?.find(
            (category) => category.key === typologyType,
          );
          const idealistaCategory = idealistaMetadata.categories?.find(
            (category) => category.key === matchingCategory.value,
          );
          if (matchingCategory && idealistaCategory) {
            const realEstate = item.realEstate;
            setSelectedCategory(idealistaCategory);
            change('categoriesMapping', {
              category: idealistaCategory.key,
              subcategory: idealistaCategory?.sub?.find(
                (subCat) => subCat.key === matchingCategory.subvalue,
              )?.key,
            });
            if (realEstate.occupationStatus) {
              change(
                'extraFields.currentOccupation',
                realEstate.occupationStatus === 'free' ? 'free' : 'not_free',
              );
            }

            if (
              realEstate.hasHeating !== undefined &&
              realEstate.heatingType !== undefined &&
              realEstate.hasAutonomousHeating !== undefined
            ) {
              if (realEstate.hasHeating) {
                if (realEstate.hasAutonomousHeating) {
                  change(
                    'extraFields.heatingType',
                    realEstate.heatingType === 'boiler' ? 'central_gas' : 'central_other',
                  );
                } else {
                  change(
                    'extraFields.heatingType',
                    realEstate.heatingType === 'electric'
                      ? 'individual_electric'
                      : realEstate.heatingType === 'heat-pump'
                      ? 'individual_air_conditioning_heat_pump'
                      : realEstate.heatingType === 'boiler'
                      ? 'individual_gas'
                      : 'individual_other',
                  );
                }
              } else {
                change('extraFields.heatingType', 'no_heating');
              }
            }
            if (realEstate.area) {
              change('extraFields.areaPlot', realEstate.area);
            }
          } else {
            setSelectedCategory(idealistaMetadata.categories.find((cat) => cat.key === 'flat'));
            // Init metadata inputs
            change('categoriesMapping', { category: 'flat' });
            change('extraFields', {});
          }
        } else {
          setSelectedCategory(idealistaMetadata.categories.find((cat) => cat.key === 'flat'));
          // Init metadata inputs
          change('categoriesMapping', { category: 'flat' });
          change('extraFields', {});
        }
      }

      if (mode === 'edit' && idealistaMetadata?.categories) {
        // Restore selected category
        setSelectedCategory(
          idealistaMetadata.categories.find((cat) => cat.key === values.categoriesMapping.category),
        );
      }
    }, [idealistaMetadata]);

    const handleSelectedCategory = (category) => {
      if (!category.sub) {
        change('categoriesMapping.subcategory', undefined);
      }
      setSelectedCategory(category);
    };

    const getInputField = (field: Record<string, any>): [Record<string, any>, any] => {
      const props = {
        source: `extraFields.${field.key}`,
        label: field.label,
        defaultValue: field.default,
        required: field.mandatory,
        variant: 'outlined',
        key: field.key,
        disabled: mode !== 'insert' && mode !== 'edit',
      };

      let InputComponent;

      switch (field.type) {
        case 'text':
          InputComponent = AppTextInput;
          break;
        case 'checkbox':
          InputComponent = AppBooleanInput;
          Object.assign(props, {
            initialValue: field.default,
            md: 3,
          });
          break;
        case 'select':
          InputComponent = AppSelectInput;
          Object.assign(props, {
            choices: field.options,
          });
          break;
        case 'number':
          InputComponent = AppNumberInput;
          Object.assign(props, {
            choices: field.options,
          });
          break;
      }

      return [props, InputComponent];
    };

    return (
      <AppCard title="Campi aggiuntivi" expanded>
        <AppSectionTitle>Categorizzazione</AppSectionTitle>
        <AppAutocompleteInput
          source="categoriesMapping.category"
          choices={idealistaMetadata.categories}
          defaultValue={idealistaMetadata.categories?.[0].key}
          optionValue="key"
          optionText="label"
          label="Categoria"
          onSelect={handleSelectedCategory}
          required
        />
        {!!selectedCategory?.sub && (
          <AppAutocompleteInput
            source="categoriesMapping.subcategory"
            choices={selectedCategory.sub}
            optionValue="key"
            optionText="label"
            label="Sottocategoria"
            required
            disabled={mode !== 'insert' && mode !== 'edit'}
          />
        )}
        {!!selectedCategory?.fields?.length && (
          <>
            <AppDivider />
            <AppSectionTitle>Info relative alla categoria</AppSectionTitle>
            {selectedCategory.fields.map((field) => {
              const [props, InputComponent] = getInputField(field);
              return React.createElement(InputComponent as any, props);
            })}
          </>
        )}
        {!!idealistaMetadata?.commonFields?.length && (
          <>
            <AppDivider />
            <AppSectionTitle>Info generali</AppSectionTitle>
            {idealistaMetadata.commonFields.map((commonField) => {
              const [props, InputComponent] = getInputField(commonField);
              return React.createElement(InputComponent as any, props);
            })}
          </>
        )}
      </AppCard>
    );
  },
);
