import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _get from 'lodash/get';
import React, { FC, useEffect, useMemo } from 'react';
import { ImageInput, ImageInputProps } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { blobToBase64 } from '../../../utils/files';
import { AppImageField } from '../field/AppImageField';
import AppSectionTitle from '../layout/AppSectionTitle';
import { AppGridProps } from '../types';

const useStyles = makeStyles<Theme>(
  (theme) => ({
    container: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    previewContainer: {
      display: 'table',
      margin: '0 auto',
    },
    preview: {
      maxWidth: theme.spacing(40),
      borderRadius: '1px',
      border: '5px solid rgba(28,110,164,0.1)',
      //padding: theme.spacing(1),
      transition: '1s',
      '&:hover': {
        boxShadow: '0px 10px 13px -7px #000000, 21px 26px 26px -5px rgba(0,0,0,0)',
      },
    },
  }),
  { name: 'AppImageInput' },
);

type AppImageInputProps = {
  previewSource: string;
  previewLabel: string;
  disabled?: boolean;
} & ImageInputProps &
  AppGridProps;

export const AppImageInput: FC<AppImageInputProps> = React.memo<AppImageInputProps>(
  ({
    source,
    accept,
    label,
    isRequired,
    previewSource,
    previewLabel,
    md = 12,
    disabled = false,
  }) => {
    const { values } = useFormState();
    const { change } = useForm();

    const classes = useStyles();

    const actualPreviewSource = useMemo(() => {
      if (source.indexOf('.') === -1) return previewSource;

      const sourceParts = source.split('.');
      const previewSourceParts = previewSource.split('.');
      sourceParts[sourceParts.length - 1] = previewSourceParts[previewSourceParts.length - 1];
      return sourceParts.join('.');
    }, [source]);

    const showPreview = useMemo(() => {
      return !!_get(values, source, null) || !!_get(values, actualPreviewSource, null);
    }, [_get(values, source, null), _get(values, actualPreviewSource, null)]);

    useEffect(() => {
      const file = _get(values, source, null);
      if (!file) return;

      blobToBase64(_get(file, source, null))
        .then((result) => {
          change(source, {
            oldImage: _get(values, actualPreviewSource, null),
            base64: result,
            filename: file.rawFile.name,
            type: file.rawFile.type,
          });
        })
        .catch(console.error);
    }, [_get(values, source, null)]);

    return (
      <Grid item md={md} className={classes.container}>
        {!disabled && (
          <>
            <AppSectionTitle>{label}</AppSectionTitle>
            <ImageInput source={source} accept={accept} label={false} isRequired={isRequired}>
              <AppImageField source={source} />
            </ImageInput>
          </>
        )}
        {showPreview && (
          <div className={classes.previewContainer}>
            <AppSectionTitle>{previewLabel}</AppSectionTitle>
            <img
              src={
                _get(values, source, null)
                  ? _get(values, source, null).base64
                  : _get(values, actualPreviewSource, null)
              }
              alt="preview"
              className={classes.preview}
            />
          </div>
        )}
      </Grid>
    );
  },
);
