import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { EmailField, ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';
import { COMPANY_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    businessName: {
      minWidth: theme.spacing(25),
    },
    email: {
      minWidth: theme.spacing(20),
    },
    address: {
      width: '100%',
    },
  }),
  { name: 'CompanyList' },
);

const CompanyList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title='Aziende' />}
      bulkActionButtons={false}
      filters={<AppListFilter helperText='Ragione sociale' />}
      resourcePermissions={COMPANY_PERMISSIONS}
    >
      <AppDatagrid rowClick='show'>
        <AppTextField
          source='businessName'
          label='Ragione Sociale'
          headerClassName={classes.businessName}
        />
        <EmailField emptyText='-' source='email' label='Email' headerClassName={classes.email} />
        <EmailField emptyText='-' source='pec' label='PEC' headerClassName={classes.email} />
        <AppTextField source='vat' label='VAT' />
        <AppTextField
          source='residenceAddress'
          label='Indirizzo principale'
          headerClassName={classes.address}
        />
        <AppShowButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={COMPANY_PERMISSIONS}
        />
        <AppEditButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={COMPANY_PERMISSIONS}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default CompanyList;
