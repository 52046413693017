import { FixedWrapper, ThemeProvider } from '@livechat/ui-kit';
import { FC } from 'react';
import ChatWidgetMaximized from './chat-widget-maximized';
import ChatWidgetMinimized from './chat-widget-minimized';
import ChatWidgetProvider from './chat-widget.provider';

const WRAP_STYLE = {
  position: 'relative',
  width: 'auto',
  height: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

const ChatWidget: FC<ChatWidgetProps> = ({ saleExperimentId, auctionStatus }) => {
  return auctionStatus !== 'inactive' ? (
    <ChatWidgetProvider auctionId={saleExperimentId} auctionStatus={auctionStatus}>
      <ThemeProvider>
        <FixedWrapper.Root style={WRAP_STYLE}>
          <FixedWrapper.Maximized style={{ position: 'fixed' }}>
            <ChatWidgetMaximized />
          </FixedWrapper.Maximized>
          <FixedWrapper.Minimized style={WRAP_STYLE}>
            <ChatWidgetMinimized />
          </FixedWrapper.Minimized>
        </FixedWrapper.Root>
      </ThemeProvider>
    </ChatWidgetProvider>
  ) : (
    <></>
  );
};

export default ChatWidget;

export interface ChatWidgetProps {
  saleExperimentId: number;
  auctionStatus: string;
}
