import React, { FC, Fragment } from 'react';
import { ItemGenre } from '../../utils/constants';
import ArtJeweleryWatchesAntiquesDetails from './ArtJeweleryWatchesAntiques/ArtJeweleryWatchesAntiquesDetails';
import CarsAndCyclesDetails from './CarsAndCycles/CarsAndCyclesDetails';
import ClothingAndFootwearDetails from './ClothingAndFootwear/ClothingAndFootwearDetails';
import InformaticsAndElectronicsDetails from './InformaticsAndElectronics/InformaticsAndElectronicsDetails';
import RealEstateDetails from './RealEstate/RealEstateDetails';

export interface ItemRelatedDataProps {
  mode: 'create' | 'edit' | 'show';
  formData: Record<string, any>;
  resource: string;
}

const ItemRelatedData: FC<ItemRelatedDataProps> = (props) => {
  const { formData } = props;
  return (
    <Fragment>
      {formData.fkGenre === ItemGenre.RealEstate && <RealEstateDetails {...props} />}
      <ClothingAndFootwearDetails {...props} />
      <CarsAndCyclesDetails {...props} />
      <ArtJeweleryWatchesAntiquesDetails {...props} />
      <InformaticsAndElectronicsDetails {...props} />
    </Fragment>
  );
};

export default ItemRelatedData;
