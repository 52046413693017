import React, { FC, Fragment } from 'react';
import { PvpOfferField } from './pvp-offer-field';

interface PvpOfferPersonProps {
  datiAnagrafici: Record<string, any>;
}

export const PvpOfferPerson: FC<PvpOfferPersonProps> = ({ datiAnagrafici }) => {
  return (
    <Fragment>
      <PvpOfferField record={datiAnagrafici} field="nome" label="Nome" />
      <PvpOfferField record={datiAnagrafici} field="cognome" label="Cognome" />
      <PvpOfferField record={datiAnagrafici} field="codiceFiscale" label="Codice fiscale" />
      <PvpOfferField
        label="Nato il"
        customValue={`${datiAnagrafici['giornoNascita']}/${datiAnagrafici['meseNascita']}/${datiAnagrafici['annoNascita']}`}
      />
      <PvpOfferField
        label="A"
        customValue={`${datiAnagrafici['comuneNascita']} (${datiAnagrafici['provinciaNascita']}, ${datiAnagrafici['regioneNascita']}, ${datiAnagrafici['nazioneNascita']})`}
        md={8}
      />
    </Fragment>
  );
};
