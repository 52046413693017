import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { useToggleState } from '../../hooks/use-toggle-state';
import { GenerationStatus } from '../../Resources/Magazine/constants';
import { checkAndGetDate } from '../../utils/dates';
import AppConfirmDialog from '../ui/dialog/confirm-dialog/app-confirm-dialog';
import { AppText } from '../ui/text';
import AppTooltip from '../ui/tooltip/AppTooltip';

export const RetryMagazineGenerationButton: FC<any> = React.memo(({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [confirmDialogOpen, toggleConfirmDialog] = useToggleState();

  const [mutateMagazine] = useMutation();
  const retryGeneration = () => {
    mutateMagazine(
      {
        type: 'getOne',
        resource: `magazine/${record.id}/retry-generation`,
        payload: {},
      },
      {
        onSuccess: () => {
          refresh();
          toggleConfirmDialog();
          notify('Il magazine sarà rigenerato.');
        },
        onFailure: (err) => {
          console.error(err);

          toggleConfirmDialog();
          notify(err?.message ?? 'Operazione fallita.', 'error');
        },
      },
    );
  };

  if (
    (record.generationStatus === GenerationStatus.Error && record.generationAttempts > 2) ||
    (record.generationStatus === GenerationStatus.InProgress &&
      Date.now() > checkAndGetDate(record.updatedAt).getTime() + 1000 * 60 * 30)
  )
    return (
      <>
        <AppTooltip title="Ritenta la generazione se è passato troppo tempo e lo stato rimane 'in corso'">
          <Button
            children="Ritenta generazione"
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              toggleConfirmDialog();
            }}
            style={{ whiteSpace: 'nowrap' }}
          />
        </AppTooltip>
        <AppConfirmDialog
          title="Ritenta generazione"
          details={
            <AppText>
              "Confermi di voler impostare il bollettino come 'in attesa di generazione' in modo che
              si ritenti la generazione?"
            </AppText>
          }
          onConfirm={retryGeneration}
          open={confirmDialogOpen}
          onClose={toggleConfirmDialog}
        />
      </>
    );

  return <></>;
});
