import { FC } from 'react';
import { BIDDER_RIGHTS, BIDDER_TITLES } from '../../../../utils/constants';
import translatedCountries from '../../../../utils/countries.json';
import { dateFormatter } from '../../../../utils/data-formatters';
import { PdfDataLine, PdfSection } from '../utils';
import { PdfSingleSectionProps } from './sections';

export const InfoBidder: FC<PdfSingleSectionProps> = (props) => {
  const bidder = props.additionalData.bidders[0];
  return (
    <PdfSection subtitle="INFORMAZIONI OFFERENTE">
      <PdfDataLine label="Nominativo" value={`${bidder.firstName} ${bidder.lastName}`} />
      <PdfDataLine label="Codice fiscale" value={`${bidder.taxCode}`} />
      <PdfDataLine label="Email" value={`${bidder.email}`} />
      <PdfDataLine label="PEC" value={bidder.pec && bidder.pec.length > 0 ? bidder.pec : '-'} />
      <PdfDataLine
        label="Telefono"
        value={`${bidder.phones ? bidder.phones.map((phone) => phone.number).join(', ') : '-'}`}
      />
      <PdfDataLine
        label="Cellulare"
        value={`${bidder.mobiles ? bidder.mobiles.map((mobile) => mobile.number).join(', ') : '-'}`}
      />
      <PdfDataLine label="Data di nascita" value={`${dateFormatter(bidder.birthday, true)}`} />
      <PdfDataLine
        label="Luogo di nascita"
        value={
          bidder.birthCountry && bidder.birthCity && bidder.birthProvince
            ? `${bidder.birthCountry} - ${bidder.birthCity} (${bidder.birthProvince})`
            : '-'
        }
      />
      <PdfDataLine
        label="Residenza"
        value={
          bidder.residenceCountry === 'IT'
            ? `${translatedCountries[bidder.residenceCountry]} - ${bidder.residenceCity} (${
                bidder.residenceProvince
              }) ${bidder.residenceZipCode} - ${bidder.residenceAddress}`
            : `${translatedCountries[bidder.residenceCountry]} - ${bidder.residenceForeignCity} - ${
                bidder.residenceAddress
              }`
        }
      />
      <PdfDataLine
        label="Domicilio"
        value={
          bidder.domicileCountry === 'IT'
            ? `${translatedCountries[bidder.domicileCountry]} - ${bidder.domicileCity} (${
                bidder.domicileProvince
              }) ${bidder.domicileZipCode} - ${bidder.domicileAddress}`
            : `${translatedCountries[bidder.domicileCountry]} - ${bidder.domicileForeignCity} - ${
                bidder.domicileAddress
              }`
        }
      />
      <PdfDataLine
        label="Partecipazione"
        value={`Diritto: ${BIDDER_RIGHTS.find((t) => t.id === bidder.right)?.name} - Quota: ${
          bidder.share
        }% - Titolo: ${BIDDER_TITLES.find((t) => t.id === bidder.title)?.name}`}
      />
    </PdfSection>
  );
};
