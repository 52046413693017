import FrontEndUsersIcon from '@material-ui/icons/SupervisedUserCircle';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import FrontEndUsersCdaDetails from './FrontEndUsersCdaDetails';
import FrontEndUsersCdaList from './FrontEndUsersCdaList';

const { Auctioneer } = PersonRoles;

export const FRONT_END_USERS_CDA_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const FrontEndUsersCda: ResourceExport = {
  list: !IS_IVG ? FrontEndUsersCdaList : undefined,
  show: !IS_IVG
    ? resourceWrapper(
        'show',
        FrontEndUsersCdaDetails,
        undefined,
        undefined,
        FRONT_END_USERS_CDA_PERMISSIONS,
      )
    : undefined,
  edit: !IS_IVG
    ? resourceWrapper(
        'edit',
        FrontEndUsersCdaDetails,
        undefined,
        undefined,
        FRONT_END_USERS_CDA_PERMISSIONS,
      )
    : undefined,
  icon: FrontEndUsersIcon,
};

export default FrontEndUsersCda;
