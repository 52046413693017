import { GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AssignmentTurnedIn as NoSalesIcon } from '@material-ui/icons';
import { FC, useMemo } from 'react';
import { SelectField } from 'react-admin';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import { useDashboardContext } from '../../../hooks';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { AUCTION_STATUS, PersonRoles } from '../../../utils/constants';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useDatagridColumnsStyles = makeStyles(
  (theme) => ({
    pvpId: {
      minWidth: theme.spacing(10),
    },
    procedureCode: {
      minWidth: theme.spacing(25),
    },
    procedureTitle: {
      minWidth: theme.spacing(40),
    },
    itemTitle: {
      minWidth: theme.spacing(40),
    },
    saleMode: {
      minWidth: theme.spacing(25),
    },
    auctionStatus: {
      minWidth: theme.spacing(30),
    },
    auctionDates: {
      minWidth: theme.spacing(40),
    },
    auctionPrices: {
      minWidth: theme.spacing(25),
    },
  }),
  { name: 'datagridColumnStyles' },
);

const AppDashboardTodaySales: FC<GridProps> = (gridProps) => {
  const datagridColumnsStyles = useDatagridColumnsStyles();

  const { isIvg, user } = useDashboardContext();

  const path = useMemo(
    () =>
      isIvg
        ? 'sale-experiments-judiciary'
        : user?.agency
        ? 'sale-experiments-private'
        : 'sale-experiments',
    [isIvg, user?.agency],
  );

  const allowView =
    useUserIsAllowed(ResourceAction.Get, path) || user?.roleId === PersonRoles.Auctioneer;

  if (!allowView) return null;

  return (
    <AppDashboardCard {...gridProps} md={12}>
      <AppDashboardTable
        resource={path}
        filter={{
          _source: 'list',
          auctionStartFrom: new Date().toISOString().substr(0, 10),
          auctionStartTo: new Date().toISOString().substr(0, 10),
        }}
        tableTitle="Vendite del giorno"
        dataError="Non è stato possibile recuperare le vendite del giorno."
        noItemMessage="Nessuna vendita trovata per il giorno corrente."
        noItemIcon={<NoSalesIcon />}
      >
        {!user?.agency && (
          <AppTextField
            source="relatedItem.relatedFolder.pvpProcedureId"
            label="Id PVP"
            headerClassName={datagridColumnsStyles.pvpId}
          />
        )}
        <AppTextField
          source="relatedItem.relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={datagridColumnsStyles.procedureCode}
        />
        <AppTextField
          source="relatedItem.relatedFolder.title"
          label="Nome procedura"
          headerClassName={datagridColumnsStyles.procedureTitle}
          truncate
        />
        <AppTextField
          source="relatedItem.title"
          label="Titolo lotto"
          truncate
          headerClassName={datagridColumnsStyles.itemTitle}
        />
        <AppTextField
          source="relatedSaleMode.description"
          label="Modalità di vendita"
          headerClassName={datagridColumnsStyles.saleMode}
        />
        <SelectField
          source="auctionStatus"
          label="Stato asta"
          choices={AUCTION_STATUS}
          headerClassName={datagridColumnsStyles.auctionStatus}
        />
        <AppTextField
          source="basePrice"
          label="Prezzo base"
          type="amount"
          headerClassName={datagridColumnsStyles.auctionPrices}
        />
        <AppTextField
          source="minPrice"
          label="Prezzo minimo"
          type="amount"
          headerClassName={datagridColumnsStyles.auctionPrices}
        />
        <AppDateField
          source="auctionStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={datagridColumnsStyles.auctionDates}
        />
        <AppDateField
          source="auctionEndAt"
          label="Data/ora termine asta"
          showTime
          headerClassName={datagridColumnsStyles.auctionDates}
        />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardTodaySales;
