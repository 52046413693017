import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, ErrorOutline } from '@material-ui/icons';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { AppSelectInput, AppTextInput } from '../../../../Components/ui/input';
import { ACTION_CHOICES } from '../../constants';

const TITLE = {
  settled: 'Annulla asta',
  postponed: 'Rimanda asta',
  deserted: 'Asta deserta',
};

const useStyles = makeStyles(
  (theme) => ({
    dialogPaper: {
      maxWidth: 'none',
      width: theme.spacing(70),
      //height: theme.spacing(23),
    },
  }),
  { name: 'AuctionManagementConfirmDialog' },
);

const AuctionManagementConfirmDialog: FC<AuctionManagementConfirmDialogProps> = (props) => {
  const { open, onCancel, onConfirm, action } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={onCancel}>
      <Form
        initialValues={{
          reason: ACTION_CHOICES[action].length === 1 ? ACTION_CHOICES[action][0].id : '',
          reasonDetails: '',
        }}
        onSubmit={(data) => {
          onCancel();
          onConfirm(data);
        }}
        render={(formProps) => {
          const { handleSubmit, submitting, pristine, hasValidationErrors } = formProps;
          const disabled = submitting || pristine || hasValidationErrors;
          return (
            <React.Fragment>
              <DialogTitle>{TITLE[action]}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <AppSelectInput
                    source="reason"
                    label="Motivazione"
                    required={true}
                    choices={ACTION_CHOICES[action]}
                    variant="outlined"
                    md={12}
                    disabled={ACTION_CHOICES[action].length === 1}
                  />
                  <AppTextInput
                    source="reasonDetails"
                    label="Note"
                    multiline
                    variant="outlined"
                    md={12}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel}>
                  <ErrorOutline />
                  Annulla
                </Button>
                <Button color="primary" disabled={disabled} onClick={handleSubmit}>
                  <CheckCircle />
                  Conferma
                </Button>
              </DialogActions>
            </React.Fragment>
          );
        }}
      />
    </Dialog>
  );
};

export default AuctionManagementConfirmDialog;

export interface AuctionManagementConfirmDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (...args) => void;
  action: 'settled' | 'postponed' | 'deserted';
}
