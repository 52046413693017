import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { FormDataConsumer } from 'react-admin';
import { FeeConditionsInput } from '../../Components/fee-profile';
import { FeeConditionsRecap } from '../../Components/fee-profile/fee-conditions-recap';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppSelectArrayInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import AppSectionTitle from '../../Components/ui/layout/AppSectionTitle';
import { AppBold, AppText } from '../../Components/ui/text';
import { useCmsConfiguration } from '../../hooks/use-cms-configuration';
import { PaymentMethod, PAYMENT_METHODS } from '../Payment/constants';
import { APPLY_A_107_CHOICES, FEE_PROFILE_TYPE_CHOICES } from './constants';
import { FeeProfileType, FeeProfileTypeText } from './enums';

const useStyles = makeStyles(
  (theme) => ({
    typeInfoAlert: {
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
  }),
  { name: 'FeeProfileDetails' },
);

const IVG_NAME = process.env.REACT_APP_IVG_NAME;

const FeeProfileDetails: FC<any> = React.memo((props) => {
  const cmsConfig = useCmsConfiguration();
  const paymentsBeneficiaryChoices = useMemo(
    () => [
      { id: 'cda', name: IVG_NAME ?? 'Casa delle Aste' },
      {
        id: 'procedure',
        name: cmsConfig?.websiteCustomization?.Generic?.WireTransfersBeneficiary ?? 'La procedura',
      },
    ],
    [cmsConfig],
  );
  const classes = useStyles();
  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard collapsable={false}>
        <AppTextInput source="name" label="Nome profilo" required md={12} />
        <AppSelectInput
          source="type"
          choices={FEE_PROFILE_TYPE_CHOICES}
          label="Tipologia profilo"
          initialValue={FeeProfileType.Filters}
          required
        />
        <AppBooleanInput source="active" label="Profilo abilitato" initialValue={true} />
        <FormDataConsumer>
          {({ formData }) => (
            <Grid item md={12}>
              <AppAlert
                title={FeeProfileTypeText[formData.type].toUpperCase()}
                severity="info"
                className={classes.typeInfoAlert}
              >
                <AppText>
                  La tipologia <AppBold>"{FeeProfileTypeText[formData.type]}"</AppBold> ti permette
                  di
                  {formData.type === FeeProfileType.Filters
                    ? ' impostare dei filtri per applicare automaticamente il profilo alle gare.'
                    : ' associare il profilo a gare specifiche.'}
                </AppText>
              </AppAlert>
            </Grid>
          )}
        </FormDataConsumer>
        <AppDivider />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === FeeProfileType.Filters && (
              <>
                <AppSectionTitle>Filtri</AppSectionTitle>
                <AppAutocompleteInput
                  source="genres"
                  reference="genres"
                  label="Generi"
                  variant="outlined"
                  optionText="description"
                  md={6}
                  isArray
                />
                <AppAutocompleteInput
                  source="saleModes"
                  reference="sale-modes"
                  label="Modalità di vendita"
                  variant="outlined"
                  optionText="description"
                  md={6}
                  isArray
                />
                <AppDivider />
              </>
            )
          }
        </FormDataConsumer>
        <AppSectionTitle>Impostazioni</AppSectionTitle>
        <AppSelectInput
          source="applyFee"
          label="Applica cauzione"
          choices={APPLY_A_107_CHOICES}
          allowEmpty
          md={3}
        />
        <FormDataConsumer>
          {({ formData }) =>
            (formData.applyFee === undefined || formData.applyFee) && (
              <AppSelectArrayInput
                source="feePaymentMethods"
                choices={PAYMENT_METHODS}
                label="Modalità di versamento cauzione"
                variant="outlined"
                md={9}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.applyFee === undefined || formData.applyFee) &&
            formData.feePaymentMethods?.includes(PaymentMethod.WireTransfer) && (
              <>
                <AppSectionTitle>Pagamento con bonifico</AppSectionTitle>
                <AppTextInput {...rest} source="ibanCode" label="IBAN beneficiario" md={4} />
                <AppSelectInput
                  {...rest}
                  source="paymentsBeneficiary"
                  label="Beneficiario pagamenti"
                  choices={paymentsBeneficiaryChoices}
                  md={4}
                  resettable
                />
                <AppTextInput {...rest} source="bank" label="Istituto bancario" md={4} />
              </>
            )
          }
        </FormDataConsumer>
        <FeeConditionsInput
          source="feeConditions"
          label="Condizioni cauzione"
          applyFeeSource="applyFee"
        />
        <FeeConditionsRecap conditionsSource="feeConditions" applyFeeSource="applyFee" />
        <AppDivider />
        <AppSectionTitle subtitle="Se non specificate si applicano le impostazioni indicate sopra">
          Impostazioni in caso di offerta 107
        </AppSectionTitle>
        <AppSelectInput
          source="applyFeeToA107"
          label="Applica cauzione"
          choices={APPLY_A_107_CHOICES}
          allowEmpty
          md={3}
        />
        <FormDataConsumer>
          {({ formData }) =>
            (formData.applyFeeToA107 === undefined || formData.applyFeeToA107) && (
              <AppSelectArrayInput
                source="a107FeePaymentMethods"
                choices={PAYMENT_METHODS}
                label="Modalità di versamento cauzione"
                variant="outlined"
                md={9}
              />
            )
          }
        </FormDataConsumer>
        <FeeConditionsInput
          source="a107FeeConditions"
          label="Condizioni cauzione per il 107"
          applyFeeSource="applyFeeToA107"
        />
        <FeeConditionsRecap conditionsSource="a107FeeConditions" applyFeeSource="applyFeeToA107" />
      </AppCard>
    </AppBaseForm>
  );
});

export default FeeProfileDetails;
