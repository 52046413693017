import CardContent from '@material-ui/core/CardContent';
import React, { FC, useEffect, useState } from 'react';
import { extract as parseMail, LetterparserMail } from 'letterparser';
import { dateFormatter } from '../../utils/data-formatters';
import { AppBold, AppText } from '../ui/text';

interface PecViewerProps {
  pecBase64: string;
}

const PecViewer: FC<PecViewerProps> = ({ pecBase64 }) => {
  const [parsedPec, setParsedPec] = useState<LetterparserMail>();

  const handleParsedPec = () => {
    if (!pecBase64) return setParsedPec(undefined);
    const pec = Buffer.from(pecBase64, 'base64')?.toString();
    setParsedPec(parseMail(pec));
  };

  useEffect(handleParsedPec, [pecBase64]);

  /*
  TODO: handle pec attachments
  parsedPec.attachments?.forEach((attachment) => {
    console.log(attachment);
  });
   */

  return parsedPec ? (
    <CardContent>
      {parsedPec.from && (
        <AppText>
          <AppBold>Da:</AppBold> {parsedPec.from}
        </AppText>
      )}
      {parsedPec.to && (
        <AppText>
          <AppBold>A:</AppBold> {parsedPec.to.join(', ')}
        </AppText>
      )}
      {parsedPec.date && (
        <AppText>
          <AppBold>Data:</AppBold> {dateFormatter(parsedPec.date.toISOString())}
        </AppText>
      )}
      {parsedPec.subject && (
        <AppText>
          <AppBold>Oggetto:</AppBold> {parsedPec.subject}
        </AppText>
      )}
      {parsedPec.text && <AppText>{parsedPec.text}</AppText>}
    </CardContent>
  ) : (
    <AppText align="center">Non disponibile</AppText>
  );
};

export default PecViewer;
