export enum GenerationStatus {
  Error = 'error',
  Pending = 'pending',
  Done = 'done',
  InProgress = 'in-progress',
}

export enum GenerationStatusText {
  'pending' = 'In attesa',
  'done' = 'Completato',
  'error' = 'Errore',
  'in-progress' = 'In corso',
}
