import React, { FC, Fragment } from 'react';
import AppCard from '../../ui/card/AppCard';
import { PvpOfferField } from './utility';

interface PvpOfferBidProps {
  offerta: Record<string, any>;
}

const PvpOfferBid: FC<PvpOfferBidProps> = ({ offerta }) => {
  return (
    <AppCard title="OFFERTA" expanded spacing={4}>
      <PvpOfferField label="Importo offerta" record={offerta} field="importoOfferta" md={12} />
      <PvpOfferField label="Importo cauzione" record={offerta} field="importoCauzione" />
      <PvpOfferField label="Pagamento cauzione con" record={offerta} field="tipoDepositoCauzione" />
      <PvpOfferField label="Termine di pagamento" record={offerta} field="termineDiPagamento" />
      {offerta['tipoDepositoCauzione'] === 'BONIFICO' ? (
        <Fragment>
          <PvpOfferField label="Iban restituzione cauzione" record={offerta} field="ibanCauzione" />
          <PvpOfferField label="Iban beneficiario cauzione" record={offerta} field="iban" md={8} />
          <PvpOfferField label="CRO" record={offerta} field="cro" />
          <PvpOfferField label="Data CRO" record={offerta} field="dataCro" />
          <PvpOfferField label="Iban versamento" record={offerta} field="ibanVersamento" />
        </Fragment>
      ) : offerta['tipoDepositoCauzione'] === 'FIDEIUSSIONE' ? (
        <PvpOfferField
          label="Riferimento fideiussione"
          record={offerta}
          field="riferimentoFideiussione"
        />
      ) : (
        <PvpOfferField label="Tipo di carta" record={offerta} field="tipoCarta" />
      )}
    </AppCard>
  );
};

export default PvpOfferBid;
