import React, { FC, useContext, useState } from 'react';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import AppAgentForm from './app-agent-form';
import { blobToBase64 } from '../../utils/files';

const AppAgentManager: FC<any> = (props) => {
  const { title, resource, ...others } = props;

  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  /// endregion

  /// region CONTEXT

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  const handleFilesAndSave = async (record: any, close: any) => {
    if (!record.imageFile?.base64) {
      const result = await blobToBase64(record.imageFile.imageFile);
      record.imageFile = {
        base64: result,
        filename: record.imageFile.filename,
        type: record.imageFile.rawFile.type,
      };
    }

    // Send to API server
    ctx.manager.submit(record, close);
  };

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        handleFilesAndSave(data, close);
      }}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={120}
      height={60}
      {...others}
    >
      <AppAgentForm
        resource={resource}
        handleClickOpen={handleClickOpen}
        setOpen={setOpen}
        ctx={ctx}
        open={open}
      />
    </AppFormDialog>
  );
};

export default AppAgentManager;
