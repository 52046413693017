import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ArrayField, Datagrid, ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppTextField } from '../../Components/ui/field';

const useStyles = makeStyles(
  (theme) => ({
    releaseDate: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    lastCol: { width: '100%', whiteSpace: 'pre-wrap' },
  }),
  { name: 'ReleaseNotesList' },
);

const ReleaseNotesList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Aggiornamenti" />}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
      bulkActionButtons={false}
      pagination={false}
      perPage={10000}
    >
      <AppDatagrid resource="release-notes">
        <AppTextField
          source="releaseDate"
          label="Data rilascio"
          headerClassName={classes.releaseDate}
          cellClassName={classes.releaseDate}
          sortable={false}
        />
        <ArrayField
          source="releaseNotes"
          label="Descrizione"
          headerClassName={classes.lastCol}
          cellClassName={classes.lastCol}
          sortable={false}
        >
          <Datagrid>
            <AppTextField source="description" label="" />
          </Datagrid>
        </ArrayField>
      </AppDatagrid>
    </AppList>
  );
};

export default ReleaseNotesList;
