import { makeStyles } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { FunctionField, ListProps, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppAutocompleteInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { AUCTION_ROOM_BOOKING_COLORS, AUCTION_ROOM_BOOKING_STATUSES } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';

type AuctionRoomBookingListFiltersProps = {
  isAuctioneer: boolean;
};

const useStyles = makeStyles(
  () => ({
    status: { whiteSpace: 'nowrap' },
    from: { whiteSpace: 'nowrap' },
    to: { whiteSpace: 'nowrap' },
    firstName: { whiteSpace: 'nowrap' },
    lastName: { whiteSpace: 'nowrap' },
    title: { whiteSpace: 'nowrap' },
    notes: { whiteSpace: 'nowrap' },
  }),
  { name: 'AuctionRoomList' },
);

const AuctionRoomBookingListFilters: FunctionComponent<AuctionRoomBookingListFiltersProps> = (
  props,
) => {
  return (
    <AppListFilter>
      {!props.isAuctioneer && (
        <AppSelectInput
          source="status"
          choices={AUCTION_ROOM_BOOKING_STATUSES}
          label="Stato"
          resettable={true}
          md={4}
        />
      )}
      <AppAutocompleteInput
        resettable={!props.isAuctioneer}
        reference="auction-rooms"
        optionText="title"
        source="auctionRoomId"
        label="Sala d'asta"
        variant="outlined"
        helperText={false}
        suggestionLimit={5}
        md={4}
        filter={
          {
            _fulltextFields: ['title', 'description'],
          } as any
        }
      />
      {!props.isAuctioneer && (
        <AppAutocompleteInput
          resettable={true}
          reference="people"
          optionText={(selectedPerson) => {
            if (!selectedPerson) return '';
            return `${selectedPerson.firstName} ${selectedPerson.lastName}`;
          }}
          source="personId"
          label="Persona"
          variant="outlined"
          helperText={false}
          suggestionLimit={5}
          md={4}
          filter={
            {
              _fulltextFields: ['firstName', 'lastName'],
            } as any
          }
        />
      )}
    </AppListFilter>
  );
};

const AuctionRoomBookingList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  const AUCTION_ROOM_BOOKINGS_ENABLED =
    process.env.REACT_APP_AUCTION_ROOM_BOOKINGS_ENABLED === 'true';

  const isAuctioneer = useMemo(() => props.permissions?.roleId === 17, [props.permissions]);

  return AUCTION_ROOM_BOOKINGS_ENABLED ? (
    <AppList
      {...props}
      title={<AppTitle title="Prenotazioni sale d'asta" />}
      filters={isAuctioneer ? <></> : <AuctionRoomBookingListFilters isAuctioneer={isAuctioneer} />}
      filter={{
        _source: 'list',
      }}
      hideCreateButton={isAuctioneer}
    >
      <AppDatagrid
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor: AUCTION_ROOM_BOOKING_COLORS[record.status][0],
        })}
      >
        <FunctionField
          render={(record) => {
            return (
              <SelectField
                choices={AUCTION_ROOM_BOOKING_STATUSES}
                record={record}
                source="status"
              />
            );
          }}
          label="Stato"
          headerClassName={classes.status}
        />
        <FunctionField
          label="Dalle"
          render={(record) => {
            return dateFormatter(record.from);
          }}
        />
        <FunctionField
          label="Alle"
          render={(record) => {
            return dateFormatter(record.to);
          }}
        />
        {!isAuctioneer && (
          <FunctionField
            label="Persona"
            render={(record) => {
              return record && record.person && record.person.firstName && record.person.lastName
                ? `${record.person.firstName} ${record.person.lastName}`
                : '';
            }}
          />
        )}
        <AppTextField
          source="auctionRoom.title"
          label="Sala d'asta"
          headerClassName={classes.title}
          cellClassName={classes.title}
        />
        <AppTextField
          source="notes"
          label="Note"
          headerClassName={classes.notes}
          cellClassName={classes.notes}
        />
      </AppDatagrid>
    </AppList>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità della "Prenotazione sale d\'asta" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default AuctionRoomBookingList;
