import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useCmsConfiguration } from '../../../hooks/use-cms-configuration';

const AppHeader: FC<any> = React.memo(() => {
  const cmsConfig = useCmsConfiguration();
  return (
    <Helmet>
      <title>{`${
        cmsConfig?.websiteCustomization?.Generic?.Title ?? 'Casa delle Aste'
      } | Admin`}</title>
    </Helmet>
  );
});

export default AppHeader;
