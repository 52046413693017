import { Grid } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList, useMutation } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppNumberInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { AppImageInput } from '../../../Components/ui/input/AppImageInput';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';

const HighlightDetails = (props: Record<any, any>): ReactElement => {
  const [folders, setFolders] = useState([]);

  const [mutateFolders] = useMutation();
  useEffect(() => {
    mutateFolders(
      {
        type: 'getList',
        resource: 'folders',
        payload: {},
      },
      {
        onSuccess: (res) =>
          setFolders(
            res.data
              .filter((folder) => !!folder.procedureCode)
              .map((folder) => ({
                id: folder.procedureCode,
                name: folder.procedureCode,
              })),
          ),
        onFailure: (err) => {
          console.error('Could not get folders:', err);
          setFolders([]);
        },
      },
    );
  }, []);

  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard collapsable={false} title="Informazioni generali">
        <AppTextInput source="title" label="Titolo" md={6} required />
        <AppAutocompleteInput source="rgeNumber" label="Numero RGE" choices={folders} />
        <AppTextInput
          source="description"
          label="Description"
          md={12}
          multiline
          expandable
          required
        />
        {props.mode !== 'create' && <AppTextInput source="slug" label="Slug" md={5} disabled />}
        <AppImageInput
          source="thumbnailFile"
          accept="image/png,image/jpeg"
          label="Miniatura"
          previewSource="thumbnail"
          previewLabel="Miniatura corrente"
          isRequired
        />
        <AppImageInput
          source="coverFile"
          accept="image/png,image/jpeg"
          label="Copertina"
          previewSource="cover"
          previewLabel="Copertina corrente"
          isRequired
        />
        <AppDateInput
          source="dateFrom"
          label="Data inizio"
          md={3}
          required
          defaultValue={new Date().toDateString()}
        />
        <AppDateInput source="dateTo" label="Data fine" md={3} required />
        <AppBooleanInput source="enabled" label="Abilitato" md={3} required initialValue={true} />
        <AppNumberInput source="order" label="Ordine" md={1} defaultValue={0} />
        {props.mode === 'show' ? (
          <Grid item md={12}>
            <AppSectionTitle>SITI</AppSectionTitle>
            <ReferenceArrayField reference="properties" source="propertyIds" label="Siti">
              <SingleFieldList>
                <ChipField source="name" style={{ marginBottom: '15px' }} />
              </SingleFieldList>
            </ReferenceArrayField>
          </Grid>
        ) : (
          <AppAutocompleteInput
            source="propertyIds"
            reference="properties"
            label="Siti"
            resettable
            variant="outlined"
            isArray
            md={12}
            filter={{
              schema: null,
            }}
          />
        )}
      </AppCard>
    </AppBaseForm>
  );
};

export default HighlightDetails;
