import { Grid } from '@material-ui/core';
import _get from 'lodash/get';
import React, { FC } from 'react';
import { AppBold, AppText } from '../../../ui/text';

interface PvpOfferFieldProps {
  record?: Record<string, any>;
  field?: string;
  customValue?: string;
  label: string;
  md?: boolean | 6 | 'auto' | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const PvpOfferField: FC<PvpOfferFieldProps> = ({
  record,
  field = '',
  label,
  customValue,
  md = 4,
}) => {
  const value: string = customValue ?? _get(record, field, '-');
  return (
    <Grid item md={md}>
      <AppText>
        <AppBold>{label.toUpperCase()}</AppBold>
      </AppText>
      <AppText>{value.toUpperCase()}</AppText>
    </Grid>
  );
};
