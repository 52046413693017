import { AddBox } from '@material-ui/icons';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import { useNotify } from 'ra-core';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { FunctionField, ListContextProvider, SelectField, useMutation } from 'react-admin';
import { useItemListStyles } from '../../../Resources/Item/ItemDatagrid';
import ItemExpand from '../../../Resources/Item/ItemExpand';
import { AUCTION_STATUS } from '../../../utils/constants';
import AppAlert from '../../ui/alert/AppAlert';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';
import { AppText } from '../../ui/text';
import RequestFulltextSearch from '../utilities/request-fulltext-search';
import RequestLinkSaleCreation from './request-link-sale-creation';

interface RequestLinkExistingItemsListProps {
  pvpRequest: Record<string, any>;
  closeDialog: () => void;
}

const RequestLinkExistingItemsList: FC<RequestLinkExistingItemsListProps> = ({
  pvpRequest,
  closeDialog,
}) => {
  const notify = useNotify();
  const dgClasses = useItemListStyles();

  const listBaseCtx = {
    resource: 'items',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
    loading: true,
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  const [fulltextSearch, setFulltextSearch] = useState<string>('');
  const handleFulltextSearchUpdate = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value.length < 1) {
      setFulltextSearch('');
    } else if (value.length >= 3) {
      setFulltextSearch(value);
    }
  };

  const [mutateProcedureItems] = useMutation();
  const getProcedureItems = () => {
    if (!pvpRequest || !Object.values(pvpRequest).length) return;
    mutateProcedureItems(
      {
        type: 'getList',
        resource: 'items',
        payload: {
          filter: {
            _source: 'list',
            ['relatedFolder.courtProcedureYear']: pvpRequest.procedureYear,
            ['relatedFolder.courtProcedureNumber']: pvpRequest.procedureNumber,
            q: fulltextSearch,
          },
        },
      },
      {
        onSuccess: (res) => {
          setListCtx(
            _assign({}, listBaseCtx, {
              data: _keyBy(res.data, 'id'),
              ids: _map(res.data, 'id'),
              total: res.total,
              loaded: true,
              loading: false,
            }) as any,
          );
        },
        onFailure: (err) => {
          setListCtx({ ...listBaseCtx, loading: false });
          notify('Impossibile recuperare i lotti della procedura.', 'error');
          console.error(err);
        },
      },
    );
  };

  useEffect(getProcedureItems, [pvpRequest, fulltextSearch]);

  return listCtx.loading ? (
    <AppText>Caricamento lotti della procedura...</AppText>
  ) : (
    <Fragment>
      <RequestFulltextSearch
        onChange={handleFulltextSearchUpdate}
        updateList={getProcedureItems}
        title="Lista dei lotti della procedura"
        description="La ricerca è effettuata tramite nome del lotto, tipologia, ecc..."
      />
      {listCtx.total > 0 ? (
        <ListContextProvider value={listCtx}>
          <AppDatagrid expand={<ItemExpand />}>
            <AppTextField
              source="relatedFolder.procedureCode"
              label="Numero procedura"
              headerClassName={dgClasses.code}
            />
            <AppTextField
              source="relatedFolder.title"
              label="Nome procedura"
              truncate
              headerClassName={dgClasses.title}
            />
            <AppTextField source="ivgCode" label="Numero IVG" headerClassName={dgClasses.code} />
            <AppTextField source="code" label="Numero lotto" headerClassName={dgClasses.code} />
            <AppTextField
              source="title"
              label="Nome lotto"
              truncate
              headerClassName={dgClasses.title}
            />
            <AppTextField
              source="fullTextTypology"
              label="Tipologia"
              headerClassName={dgClasses.typology}
            />
            <FunctionField
              render={(record) => {
                const currentSaleExperiment = record.relatedSaleExperiments
                  ? record.relatedSaleExperiments.find((saleExp) => saleExp.isCurrent)
                  : undefined;
                if (currentSaleExperiment) {
                  return (
                    <SelectField
                      choices={AUCTION_STATUS}
                      record={currentSaleExperiment}
                      source="auctionStatus"
                    />
                  );
                }
                return '-';
              }}
              label="Stato asta"
              headerClassName={dgClasses.lastCol}
            />
            <FunctionField
              render={(record) => (
                <RequestLinkSaleCreation
                  pvpRequestId={pvpRequest.id}
                  closeDialog={closeDialog}
                  label="Crea nuova vendita"
                  itemId={record.id}
                  Icon={AddBox}
                />
              )}
            />
          </AppDatagrid>
        </ListContextProvider>
      ) : (
        <AppAlert children="Nessun lotto trovato." severity="warning" />
      )}
    </Fragment>
  );
};

export default RequestLinkExistingItemsList;
