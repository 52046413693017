import PropertyIcon from '@material-ui/icons/Web';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import PropertyDetails from './PropertyDetails';
import PropertyList from './PropertyList';

const { Auctioneer } = PersonRoles;

export const PROPERTY_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const Property: ResourceExport = {
  list: PropertyList,
  create: resourceWrapper('create', PropertyDetails, undefined, undefined, PROPERTY_PERMISSIONS),
  edit: resourceWrapper('edit', PropertyDetails, undefined, undefined, PROPERTY_PERMISSIONS),
  show: resourceWrapper('show', PropertyDetails, undefined, undefined, PROPERTY_PERMISSIONS),
  icon: PropertyIcon,
};

export default Property;
