import { Button, Fade, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { FC, Fragment } from 'react';
import AppTooltip from '../ui/tooltip/AppTooltip';

interface NewPersonRoleButtonsProps {
  role: Record<string, any>;
  handleClickOpen: (...args) => void;
  choices: Record<string, any>[];
  changeTarget: string;
  hidden?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: -theme.spacing(1.8),
      marginLeft: theme.spacing(1),
      marginRight: -theme.spacing(2),
    },
  }),
  { name: 'AddPersonRoleButtons' },
);

const AddPersonRoleButtons: FC<NewPersonRoleButtonsProps> = (props) => {
  const { role, handleClickOpen, choices, changeTarget, hidden = false } = props;
  const classes = useStyles();

  return !hidden ? (
    <Fragment>
      <Grid item md={1} xs={12} className={classes.button}>
        <AppTooltip
          title={`Non trovi la persona desiderata tra quelle disponibili? Seleziona o crea una nuova persona da collegare al ruolo ${role.description}`}
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <Button
            color="primary"
            variant="outlined"
            disabled={!role.naturalPerson}
            onClick={() => handleClickOpen('person', choices, changeTarget, role.id)}
            children={<PersonAddIcon />}
          />
        </AppTooltip>
      </Grid>
      <Grid item md={1} xs={12} className={classes.button}>
        <AppTooltip
          title={`Non trovi l'azienda desiderata tra quelle disponibili? Seleziona o crea una nuova azienda da collegare al ruolo ${role.description}`}
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <Button
            color="primary"
            variant="outlined"
            disabled={!role.juridicalPerson}
            onClick={() => handleClickOpen('company', choices, changeTarget, role.id)}
            children={<HomeWorkIcon />}
          />
        </AppTooltip>
      </Grid>
    </Fragment>
  ) : (
    <span />
  );
};

export default AddPersonRoleButtons;
