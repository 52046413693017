import ShippingIcon from '@material-ui/icons/LocalShipping';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import ShippingDetails from './ShippingDetails';
import ShippingList from './ShippingList';

const Shipping: ResourceExport = {
  list: ShippingList,
  create: resourceWrapper('create', ShippingDetails, undefined, undefined),
  edit: resourceWrapper('edit', ShippingDetails, undefined, undefined),
  show: resourceWrapper('show', ShippingDetails, undefined, undefined),
  icon: ShippingIcon,
};

export default Shipping;
