import { Button } from '@material-ui/core';
import PublicationCertificateIcon from '@material-ui/icons/Description';
import { FC, useCallback } from 'react';
import { useMutation, useNotify } from 'react-admin';
import AppTooltip from '../ui/tooltip/AppTooltip';

const PublicationCertificateButton: FC<any> = ({ record, itemId }) => {
  const notify = useNotify();

  const [generateCertificate] = useMutation();
  const handleCertificateGeneration = useCallback(() => {
    generateCertificate(
      {
        type: 'getOne',
        resource: `items/${itemId}/publications/${record.id}/generate-certificate`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          const a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.pdfBase64;
          a.download = res.data.fileName;
          a.click();
        },
        onFailure: (err) => {
          notify(`Generazione del certificato non possibile: ${err.message}`, 'error');
          console.error(err);
        },
      },
    );
  }, []);

  return (
    <AppTooltip title="Genera il certificato relativo ai giorni di pubblicazione dell'esperimento di vendita corrente.">
      <Button
        children="Certificato"
        endIcon={<PublicationCertificateIcon />}
        variant="outlined"
        onClick={handleCertificateGeneration}
      />
    </AppTooltip>
  );
};

export default PublicationCertificateButton;
