import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
  ChipField,
  FunctionField,
  ListProps,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import AppEditButton from '../../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../../Components/ui/detail/AppList';
import AppTextField from '../../../Components/ui/field/AppTextField';
import AppTitle from '../../../Components/ui/layout/AppTitle';
import { LINK_SECTIONS_AGENCIES_CHOICES } from '../../Booking/constants';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      minWidth: theme.spacing(50),
    },
    section: {
      minWidth: theme.spacing(50),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'LinksList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const LinkList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList {...props} title={<AppTitle title="Links" />}>
      <AppDatagrid resource="links" rowClick="show" permissions={props.permissions}>
        <AppTextField source="title" label="Titolo" headerClassName={classes.title} />
        <FunctionField
          render={(record) => {
            if (!record) {
              return '-';
            }
            return (
              LINK_SECTIONS_AGENCIES_CHOICES.find((section) => section.id === record.section)
                ?.name ?? '-'
            );
          }}
          label="Sezione"
          headerClassName={classes.section}
        />
        {!IS_IVG && (
          <ReferenceArrayField
            reference="person-sellers"
            source="personSellerIds"
            label="Proprietari"
            headerClassName={classes.lastCol}
          >
            <SingleFieldList>
              <FunctionField
                render={(record) => `${record?.person?.firstName} ${record?.person?.lastName}`}
              />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default LinkList;
