import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'carsAndCycles';

const RuoteDaSognoDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 5) return <React.Fragment />;

  return (
    <AppCard title="Dettagli personalizzati" disabled={mode === 'show'}>
      {formData.fkTypology === 60 && (
        <AppTextInput source={`${BASE_SOURCE}.carType`} label="Carrozzeria" md={4} />
      )}
      {formData.fkTypology === 61 && (
        <>
          <AppTextInput source={`${BASE_SOURCE}.condition`} label="Condizione" md={4} />
          <AppTextInput source={`${BASE_SOURCE}.vintageRegistration`} label="Iscrizione" md={4} />
        </>
      )}
      <AppTextInput source={`${BASE_SOURCE}.showroom`} label="Showroom" md={4} />
    </AppCard>
  );
};

export default RuoteDaSognoDetails;
