import { FormControlProps } from '@material-ui/core/FormControl';
import { SelectProps } from '@material-ui/core/Select';
import { ChoicesProps, InputProps } from 'ra-core';
import React, { FC, ReactElement } from 'react';
import { SelectArrayInput, Validator } from 'react-admin';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

export const AppSelectArrayInput: FC<AppSelectArrayInputProps> = (
  props: AppSelectArrayInputProps,
): ReactElement => {
  const { required, validate: customValidators, ...rest } = props;
  return gridWrappedInput(
    { ...rest },
    <SelectArrayInput
      validate={getValidators(customValidators as Validator[], required)}
      {...inputProps({ ...rest })}
    />,
  );
};

interface SelectArrayInputProps
  extends Omit<ChoicesProps, 'choices'>,
    Omit<InputProps<SelectProps>, 'source'>,
    Omit<FormControlProps, 'defaultValue' | 'onBlur' | 'onChange' | 'onFocus'> {
  choices?: Record<string, any>[];
  source?: string;
}

export type AppSelectArrayInputProps = SelectArrayInputProps & AppGridProps;
