import { MenuBookTwoTone } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import ParticipationFormsSetDetails from './ParticipationFormsSetDetails';
import ParticipationFormsSetList from './ParticipationFormsSetList';

export const PARTICIPATION_FORMS_SET_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const ParticipationFormsSet: ResourceExport = {
  list: ParticipationFormsSetList,
  create: resourceWrapper(
    'create',
    ParticipationFormsSetDetails,
    undefined,
    undefined,
    PARTICIPATION_FORMS_SET_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    ParticipationFormsSetDetails,
    undefined,
    undefined,
    PARTICIPATION_FORMS_SET_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    ParticipationFormsSetDetails,
    undefined,
    undefined,
    PARTICIPATION_FORMS_SET_PERMISSIONS,
  ),
  icon: MenuBookTwoTone,
};

export default ParticipationFormsSet;
