import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import { AppImageField } from '../../Components/ui/field/AppImageField';
import AppSectionTitle from '../../Components/ui/layout/AppSectionTitle';
import { dateFormatter } from '../../utils/data-formatters';
import { GenerationStatusText } from './constants';

export const MagazineExpanded: FC<any> = React.memo(({ courtsCatalog, ...rest }) => {
  const { record } = rest;
  return (
    <Grid container spacing={3}>
      <DetailPanelTitle>Impostazioni bollettino</DetailPanelTitle>
      <DetailPanelField
        label="Tribunali"
        value={
          record.courts?.length > 0
            ? courtsCatalog
                .filter((court) => record.courts.includes(court.id))
                .map((court) => court.name)
                .join(', ')
            : 'Includi tutti'
        }
        md={12}
      />
      <DetailPanelField
        label="Fascicolo"
        value={record.relatedFolder?.title}
        emptyText="Non specificato"
        md={6}
      />
      <DetailPanelField
        label="Numero procedura"
        value={record.relatedFolder?.procedureCode}
        md={6}
      />
      <DetailPanelField
        label="Solo manifestazioni di interesse"
        value={record.expressionOfInterestOnly ? 'SI' : 'NO'}
        md={3}
      />
      <DetailPanelField
        label="Includi manifestazioni di interesse"
        value={record.includeExpressionOfInterest ? 'SI' : 'NO'}
        md={3}
      />
      <DetailPanelTitle>Impostazioni pubblicazione a sito</DetailPanelTitle>
      {!!record.coverUrl && (
        <Grid item md={3}>
          <AppSectionTitle>Copertina</AppSectionTitle>
          <AppImageField {...rest} source="coverUrl" />
        </Grid>
      )}
      <DetailPanelField
        label="Inizio pubblicazione"
        value={dateFormatter(record.publicationFrom)}
        md={2}
      />
      <DetailPanelField
        label="Termine pubblicazione"
        value={dateFormatter(record.publicationTo)}
        md={2}
      />
      <DetailPanelField label="Titolo pubblicazione" value={record.publicationTitle} md={5} />
      {record.method === 'generate' && (
        <>
          <DetailPanelTitle>Generazione bollettino</DetailPanelTitle>
          <DetailPanelField
            label="Stato"
            value={GenerationStatusText[record.generationStatus].toUpperCase()}
            md={1}
          />
          <DetailPanelField label="Tentativi" value={record.generationAttempts} md={1} />
          {!!record.generatedAt && (
            <DetailPanelField
              label="Data generazione"
              value={dateFormatter(record.generatedAt)}
              md={2}
            />
          )}
          {!!record.notes && <DetailPanelField label="Note" value={record.notes} md={12} />}
        </>
      )}
    </Grid>
  );
});
