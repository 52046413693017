import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ListProps } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppDateField, AppSelectField, AppTextField } from '../../Components/ui/field';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppEditButton from '../../Components/ui/button/AppEditButton';

const useStyles = makeStyles(
  (theme) => ({
    date: {
      whiteSpace: 'nowrap',
    },
    name: {
      minWidth: theme.spacing(30),
    },
    taxCode: {
      whiteSpace: 'nowrap',
    },
    mobile: {
      minWidth: theme.spacing(30),
    },
    phone: {
      minWidth: theme.spacing(30),
    },
    ibanCode: {
      minWidth: theme.spacing(30),
    },
    cardOwner: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'FrontEndUsersImmoList' },
);

const FrontEndUsersImmoList: FC<ListProps> = React.memo((props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title={props.options.label} />}
      filters={<AppListFilter helperText="nome, cognome, email, codice fiscale" />}
      hideCreateButton
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <AppDatagrid rowClick="show">
        <AppDateField source="createdAt" label="Data iscrizione" headerClassName={classes.date} />
        <AppTextField source="firstName" label="Nome" headerClassName={classes.name} />
        <AppTextField source="lastName" label="Cognome" headerClassName={classes.name} />
        <AppTextField source="email" label="Email" clickToCopy />
        <AppTextField source="pec" label="Pec" />
        <AppTextField source="taxCode" label="Codice fiscale" headerClassName={classes.taxCode} />
        <AppDateField
          source="birthday"
          label="Data di nascita"
          headerClassName={classes.date}
          sortable={false}
        />
        <AppSelectField
          source="gender"
          choices={[
            { id: 'male', name: 'M' },
            { id: 'female', name: 'F' },
          ]}
          label="Sesso"
          sortable={false}
        />
        <AppTextField
          source="mobile"
          label="Cellulare"
          clickToCopy
          sortable={false}
          headerClassName={classes.mobile}
        />
        <AppTextField
          source="phone"
          label="Telefono"
          clickToCopy
          headerClassName={classes.phone}
          sortable={false}
        />
        <AppTextField
          source="ibanCode"
          label="IBAN"
          clickToCopy
          sortable={false}
          headerClassName={classes.ibanCode}
        />
        <AppTextField
          source="cardOwner"
          label="Intestatario"
          sortable={false}
          headerClassName={classes.cardOwner}
        />
        <AppTextField
          source="cardBank"
          label="Banca"
          sortable={false}
          headerClassName={classes.lastCol}
        />
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
});

export default FrontEndUsersImmoList;
