import MagazinePlaceholderIcon from '@material-ui/icons/ChromeReaderMode';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import MagazinePlaceholderDetails from './MagazinePlaceholderDetails';
import MagazinePlaceholderList from './MagazinePlaceholderList';

export const MAGAZINE_PLACEHOLDER_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const MagazinePlaceholder: ResourceExport = {
  list: MagazinePlaceholderList,
  create: resourceWrapper(
    'create',
    MagazinePlaceholderDetails,
    undefined,
    undefined,
    MAGAZINE_PLACEHOLDER_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    MagazinePlaceholderDetails,
    undefined,
    undefined,
    MAGAZINE_PLACEHOLDER_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    MagazinePlaceholderDetails,
    undefined,
    undefined,
    MAGAZINE_PLACEHOLDER_PERMISSIONS,
  ),
  icon: MagazinePlaceholderIcon,
};

export default MagazinePlaceholder;
