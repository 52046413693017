export const INFO_REQUESTER_BASE_SOURCE = 'infoRequester';
export const ITEM_BASE_SOURCE = 'item';
export const PERSON_BASE_SOURCE = 'person';

export const INFO_REQUEST_STATE_CHOICES = [
  { id: null, name: '\xa0' },
  { id: 'pending', name: 'In attesa' },
  { id: 'processing', name: 'In gestione' },
  { id: 'completed', name: 'Completata' },
];
export const INFO_REQUEST_TYPE_CHOICES = [
  { id: null, name: '\xa0' },
  { id: 'item', name: 'Lotto' },
  { id: 'service', name: 'Vivacizzazione' },
];
