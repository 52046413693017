import React, { FC, Fragment } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { AppAutocompleteInput } from '../../../Components/ui/input/AppAutocompleteInput';

export const PrivateDetails: FC<any> = (props) => {
  const { formData, resource, disabled } = props;

  if (!(formData.type === 'private' || resource === 'folders-private')) return <Fragment />;

  return (
    <AppCard title="Privato" disabled={disabled}>
      <AppTextInput source="title" label="Titolo" required />
      <AppAutocompleteInput
        reference="pvp/rites"
        filter={{
          judiciary: false,
        }}
        sort={{
          field: 'description',
          order: 'ASC',
        }}
        optionText="description"
        source="fkPvpRite"
        label="Tipo di vendita *"
        required
        resettable
        onSelect={(rite) => (formData.fkPvpRegister = rite.fkPvpRegister)}
      />
      <AppAutocompleteInput
        reference="competences"
        source="fkCompetence"
        label="Sede di competenza"
        required
      />
    </AppCard>
  );
};
