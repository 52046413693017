import {
  AppBar,
  AppBar as MuiAppBar,
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import React, { Children, cloneElement, FC, useEffect, useRef, useState } from 'react';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';

const useStyles = makeStyles(
  (theme) => {
    return {
      drawer: {
        overflow: 'hidden',
      },
      formContainer: {
        maxWidth: theme.spacing(150),
        // width: 'calc(100vw - 35rem)',
        minHeight: '100vh',
        //height: '100vh',
        overflow: 'hidden',
        //backgroundColor: 'red',
        boxShadow:
          'inset 0px -1px 10px 0px rgba(0,0,0,0.12), inset 0px -4px 5px 0px rgba(0,0,0,0.14), inset 0px -2px 4px -1px rgba(0,0,0,0.12)',
      },
      content: {
        //backgroundColor: 'red',
        position: 'relative',
        height: `calc(100vh - ${theme.spacing(8)}px)`,
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingTop: theme.spacing(3),
        paddingBottom: 0,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      title: {
        flex: 'auto',
        marginRight: theme.spacing(2),
      },
      toolbar: {
        '& button:not(.close)': {
          marginLeft: theme.spacing(2),
        },
      },
      tabButton: {
        color: 'black',
        backgroundColor: 'white',
      },
    };
  },
  { name: 'AppDrawerForm' },
);

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AppDrawerTabbedForm: FC<any> = (props) => {
  const {
    anchor,
    open,
    onClose,
    children,
    title,
    onSubmit,
    saveBtn = false,
    saveCloseBtn = true,
    deleteBtn = false,
    onRemove,
    ...others
  } = props;
  const classes = useStyles();
  const notify = useNotify();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, [children]);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };

  const closeMe = useRef(false);

  const activeChildren = children.filter((child) => !child.props.isHidden); //filter out hidden tabs

  const clearInputs = ([name], state, { changeValue }) => {
    //accepts both a single field name and an array of names
    if (name instanceof Array) {
      name.forEach((name) => changeValue(state, name, () => undefined));
    } else {
      changeValue(state, name, () => undefined);
    }
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <div className={classes.formContainer}>
        <Form
          initialValues={others.record}
          mutators={{ clearInputs, ...(arrayMutators as any) }}
          onSubmit={(data) => {
            onSubmit(data, closeMe.current);
          }}
          render={(formProps) => {
            const { handleSubmit, submitting, hasValidationErrors, pristine } = formProps;
            const disabled = submitting || pristine;

            const checkValidationErrors = () =>
              hasValidationErrors && notify('ra.message.invalid_form', 'error');

            return (
              <React.Fragment>
                <MuiAppBar position="relative">
                  <Toolbar className={classes.toolbar}>
                    <IconButton onClick={onClose} className="close">
                      <Close />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                      {title}
                    </Typography>
                    {saveBtn && (
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          checkValidationErrors();
                          closeMe.current = false;
                          handleSubmit();
                        }}
                        variant="contained"
                        color="secondary"
                        autoFocus
                        startIcon={<Save />}
                        children="Salva"
                      />
                    )}
                    {saveCloseBtn && (
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          checkValidationErrors();
                          closeMe.current = true;
                          handleSubmit();
                        }}
                        variant="contained"
                        color="secondary"
                        autoFocus
                        startIcon={<Save />}
                        children="Salva e chiudi"
                      />
                    )}
                    {deleteBtn && others.mode === 'edit' && (
                      <Button
                        onClick={() => onRemove(others.record, true)}
                        variant="contained"
                        children={<Delete color="error" />}
                      />
                    )}
                  </Toolbar>
                </MuiAppBar>
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      aria-label="simple tabs example"
                      scrollButtons="auto"
                      variant="scrollable"
                      className={classes.tabButton}
                      indicatorColor="primary"
                    >
                      {Children.map(activeChildren, (child, index) => (
                        <Tab label={child.props.tabTitle} {...a11yProps(index)} />
                      ))}
                    </Tabs>
                  </AppBar>
                  {Children.map(activeChildren, (child, index) =>
                    cloneElement(child, { ...others, index, value: activeTab }),
                  )}
                </div>
              </React.Fragment>
            );
          }}
        />
      </div>
    </Drawer>
  );
};

export default AppDrawerTabbedForm;
