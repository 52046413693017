import { Grid, makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { Description, PhotoCamera, ThreeDRotation, VideoCall } from '@material-ui/icons';
import queryString from 'query-string';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  DeleteWithConfirmButton,
  FormDataConsumer,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SingleFieldList,
  useRefresh,
} from 'react-admin';
import AppCapabilityManager from '../../Components/capability/app-capability-manager';
import ChatbotExecuteImportButton, {
  ImportType,
} from '../../Components/chatbot-import/buttons/chatbot-execute-import-button';
import AppCodeManager from '../../Components/code/app-code-manager';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import MandatoryOrSuggestedRoles from '../../Components/folder/MandatoryOrSuggestedRoles';
import {
  ClosedInfo,
  JugaadExternalPropertyInfo,
  PublicationDisclaimer,
  TotalClicks,
  VerifyBalanceButton,
} from '../../Components/item';
import ImagePreview from '../../Components/media/image-preview';
import OrderMediaManager from '../../Components/media/order-media-manager';
import UploadMediaButton from '../../Components/media/upload/upload-media-button';
import UploadMediaManager from '../../Components/media/upload/upload-media-manager';
import PublicationCertificateButton from '../../Components/publication/publication-certificate-button';
import AppPublicationManager from '../../Components/publication/publication-manager';
import AppRelatedResource, {
  AppRelatedResourceEditButton,
} from '../../Components/related-resource/app-related-resource';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppButtonGroup from '../../Components/ui/button/AppButtonGroup';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import AppItemLinksButton from '../../Components/ui/button/AppItemLinksButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';

import AppDownloadField from '../../Components/ui/field/AppDownloadField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppUrlField from '../../Components/ui/field/AppUrlField';
import AppFormTab from '../../Components/ui/form/AppFormTab';

import { useHistory } from 'react-router';
import AppAgentManager from '../../Components/item/app-agent-manager';
import AppBuyNowItemStocksManager from '../../Components/item/buy-now-item-stocks-manager';
import AppBuyNowItemVariantsManager from '../../Components/item/buy-now-item-variants-manager';
import { MediaHeader } from '../../Components/media/media-header';
import { MediaSelectButton } from '../../Components/media/media-select-button';
import { AppImageField } from '../../Components/ui/field/AppImageField';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppInputFiller,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppCategorizationInput from '../../Components/ui/input/AppCategorizationInput';
import AppGeographiesInput from '../../Components/ui/input/AppGeographiesInput';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import { useCmsConfiguration } from '../../hooks/use-cms-configuration';
import { useUserIsAllowed } from '../../hooks/use-user-is-allowed';
import {
  CAPABILITY_SAVED_AS,
  CODES_TYPES,
  ItemGenre,
  OWNERSHIP_TYPES,
  PersonRoles,
  SaleStatus,
} from '../../utils/constants';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { getPublicationUrl } from '../../utils/publications';
import { getReference } from '../../utils/reference-selector';
import ItemRelatedData from './ItemRelatedData';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';
const CHATBOT_OWNER = process.env.REACT_APP_CHATBOT_OWNER === 'true';
const CAN_SEE_VARIANTS_TAB = [
  PersonRoles.SuperAdmin,
  PersonRoles.Administrator,
  PersonRoles.Auctioneer,
];
const INFO_REQUEST_ENABLED = process.env.REACT_APP_INFO_REQUEST_ENABLED === 'true';

const useStyles = makeStyles(
  (theme) => ({
    // Form
    highlighted: {
      marginLeft: theme.spacing(2),
    },

    // --- DATAGRIDS ---

    // Generic last column of a datagrid
    lastDatagridColumn: {
      width: '100%',
    },

    // Media
    mediaGridNameCol: {
      minWidth: theme.spacing(30),
    },
    mediaGridDescCol: {
      minWidth: theme.spacing(30),
    },
    mediaGridTypeCol: {
      minWidth: theme.spacing(20),
    },
    mediaGridPreview: {
      position: 'relative',
    },

    // Publications datagrid
    propertyName: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    publicationDates: {
      whiteSpace: 'nowrap',
    },
    isPublished: {
      whiteSpace: 'nowrap',
    },
    notes: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },

    // Codes
    codeName: {
      minWidth: theme.spacing(30),
    },
    code: {
      minWidth: theme.spacing(20),
    },

    // Capabilities
    roleNameCol: {
      minWidth: theme.spacing(40),
    },
    nameCol: {
      minWidth: theme.spacing(30),
    },
    dateCol: {
      minWidth: theme.spacing(25),
    },

    // Sale experiments
    saleMode: {
      minWidth: theme.spacing(25),
    },
    price: {
      minWidth: theme.spacing(15),
    },
    date: {
      minWidth: theme.spacing(25),
    },
    goToResourceButton: {
      marginRight: theme.spacing(3),
    },
    appItemLinkButton: {
      marginBottom: theme.spacing(2.5),
      marginLeft: theme.spacing(4),
    },

    // Chatbot
    executeChatbotImportButton: {
      textAlign: 'center',
      marginTop: -theme.spacing(1.5),
    },
    itemNotFromChatbotAlert: {
      marginBottom: theme.spacing(2),
    },

    // Agents
    agentNameCol: {
      minWidth: theme.spacing(40),
    },
    agentEmailCol: {
      minWidth: theme.spacing(40),
    },
    agentPhoneCol: {
      minWidth: theme.spacing(40),
    },
    agentMobileCol: {
      minWidth: theme.spacing(40),
    },
  }),
  { name: 'ItemDetails' },
);

const MediaExpand: FC<any> = ({ record }) => {
  const { additionalFields: mediaAdditionalFields } = record.metadata;
  const { additionalFields: additionalFieldsInfo } = record.role;

  if (!mediaAdditionalFields) return <Fragment />;

  return (
    <Grid container spacing={2}>
      <DetailPanelTitle children="Campi aggiuntivi" />
      {additionalFieldsInfo
        .sort((a, b) => a.order ?? 0 - b.order ?? 0)
        .map((fieldInfo) => {
          let mediaAdditionalFieldValue = mediaAdditionalFields[fieldInfo.fieldName];
          // Format additional field value by checking its type
          switch (fieldInfo.type) {
            case 'text':
            case 'number':
              break;
            case 'amount':
              mediaAdditionalFieldValue = amountFormatter(mediaAdditionalFieldValue);
              break;
            case 'date':
              mediaAdditionalFieldValue = dateFormatter(mediaAdditionalFieldValue, true);
              break;
          }

          return <DetailPanelField label={fieldInfo.label} value={mediaAdditionalFieldValue} />;
        })}
    </Grid>
  );
};

const ItemDetail = (props: Record<any, any>): React.ReactElement => {
  //#region MAGAZINE

  // TODO: MOVE FROM HERE -> maybe in wrapper
  const cmsConfig = useCmsConfiguration();

  const history = useHistory();

  const magazineTitleLengthPremium = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLengthPremium ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLengthPremium = useMemo(
    () =>
      parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLengthPremium ?? '0'),
    [cmsConfig],
  );
  const magazineTitleLength2X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLength2X ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLength2X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLength2X ?? '0'),
    [cmsConfig],
  );
  const magazineTitleLength3X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineTitleLength3X ?? '0'),
    [cmsConfig],
  );
  const magazineDescriptionLength3X = useMemo(
    () => parseInt(cmsConfig?.websiteCustomization?.Generic?.MagazineDescriptionLength3X ?? '0'),
    [cmsConfig],
  );

  //#endregion

  const classes = useStyles();
  const refresh = useRefresh();

  const [selectedFolderType, setSelectedFolderType] = useState<string>('');
  const [orderMediaType, setOrderMediaType] = useState<string>('');

  const isAdvertisement = useMemo(() => !props.record?.handleSale, [props.record]);

  //get folder info from query string (if exists)
  const { currentResourceId: folderId, folderType } = queryString.parse(props.location.search);

  useEffect(() => {
    if ((props.mode === 'edit' || props.mode === 'show') && props.record.relatedFolder) {
      setSelectedFolderType(props.record.relatedFolder.type);
      return;
    }

    if (folderType) setSelectedFolderType(folderType as string);
  }, []);

  const checkLength = (field: 'title' | 'description', event) => {
    const fieldName = field.charAt(0).toUpperCase() + field.slice(1);
    const lengthCheckPremium = document.getElementById(`magazine${fieldName}LengthCheckPremium`);
    const lengthCheck2X = document.getElementById(`magazine${fieldName}LengthCheck2X`);
    const lengthCheck3X = document.getElementById(`magazine${fieldName}LengthCheck3X`);
    const fieldValueLength =
      field === 'title' ? event.target.value.length : event.target.textLength;
    if (lengthCheckPremium) {
      const fieldMaxLengthPremium =
        field === 'title' ? magazineTitleLengthPremium : magazineDescriptionLengthPremium;
      lengthCheckPremium.innerText = `pagina premium: ${fieldValueLength} / ${fieldMaxLengthPremium}`;
      if (fieldValueLength > fieldMaxLengthPremium) {
        lengthCheckPremium.classList.remove('MuiTypography-colorPrimary');
        lengthCheckPremium.classList.add('MuiTypography-colorError');
      } else {
        lengthCheckPremium.classList.remove('MuiTypography-colorError');
        lengthCheckPremium.classList.add('MuiTypography-colorPrimary');
      }
    }
    if (lengthCheck2X) {
      const fieldMaxLength2X =
        field === 'title' ? magazineTitleLength2X : magazineDescriptionLength2X;
      lengthCheck2X.innerText = `2 per pagina: ${fieldValueLength} / ${fieldMaxLength2X}`;
      if (fieldValueLength > fieldMaxLength2X) {
        lengthCheck2X.classList.remove('MuiTypography-colorPrimary');
        lengthCheck2X.classList.add('MuiTypography-colorError');
      } else {
        lengthCheck2X.classList.remove('MuiTypography-colorError');
        lengthCheck2X.classList.add('MuiTypography-colorPrimary');
      }
    }
    if (lengthCheck3X) {
      const fieldMaxLength3X =
        field === 'title' ? magazineTitleLength3X : magazineDescriptionLength3X;
      lengthCheck3X.innerText = `3 per pagina: ${fieldValueLength} / ${fieldMaxLength3X}`;
      if (fieldValueLength > fieldMaxLength3X) {
        lengthCheck3X.classList.remove('MuiTypography-colorPrimary');
        lengthCheck3X.classList.add('MuiTypography-colorError');
      } else {
        lengthCheck3X.classList.remove('MuiTypography-colorError');
        lengthCheck3X.classList.add('MuiTypography-colorPrimary');
      }
    }
  };

  const openOrderDialog = (mediaType: string) => {
    setOrderMediaType(mediaType);
  };

  const handleDialogClose = () => {
    setOrderMediaType('');
  };

  const handleOrderFinish = (succesful: boolean) => {
    setOrderMediaType('');
    if (succesful) refresh();
  };

  const renderCounters = (title, field, checkPremium, check2X, check3X) => {
    if (props.permissions?.agency) return null;

    return (
      <Grid md={12}>
        <FunctionField
          label={title}
          render={() => {
            return (
              <Grid container spacing={0}>
                <Grid item md={4}>
                  <AppTextField
                    id={`${field}LengthCheckPremium`}
                    customText={`pagina premium: ${
                      document.getElementById(field)?.textContent?.length ?? 0
                    } / ${checkPremium}`}
                    color={
                      (document.getElementById(field)?.textContent?.length ?? 0) > checkPremium
                        ? 'error'
                        : 'primary'
                    }
                    type="text"
                    variant="button"
                    component={'div'}
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      padding: '6px',
                      paddingTop: '0',
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <AppTextField
                    id={`${field}LengthCheck2X`}
                    customText={`2 per pagina: ${
                      document.getElementById(field)?.textContent?.length ?? 0
                    } / ${check2X}`}
                    color={
                      (document.getElementById(field)?.textContent?.length ?? 0) > check2X
                        ? 'error'
                        : 'primary'
                    }
                    type="text"
                    variant="button"
                    component={'div'}
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      padding: '6px',
                      paddingTop: '0',
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <AppTextField
                    id={`${field}LengthCheck3X`}
                    customText={`3 per pagina: ${
                      document.getElementById(field)?.textContent?.length ?? 0
                    } / ${check3X}`}
                    color={
                      (document.getElementById(field)?.textContent?.length ?? 0) > check3X
                        ? 'error'
                        : 'primary'
                    }
                    type="text"
                    variant="button"
                    component={'div'}
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      padding: '6px',
                      paddingTop: '0',
                    }}
                  />
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
    );
  };

  const totalQuantity = useMemo(
    () =>
      props.record?.relatedSaleExperiments?.find((saleExp) => saleExp.isCurrent)?.totalQuantity ??
      0,
    [props.record],
  );
  const soldQuantity = useMemo(
    () =>
      props.record?.relatedSaleExperiments?.find((saleExp) => saleExp.isCurrent)?.soldQuantity ?? 0,
    [props.record],
  );
  const stockQuantity = useMemo(() => totalQuantity - soldQuantity, [totalQuantity, soldQuantity]);
  const [handledStockQuantity, setHandledStockQuantity] = useState<number>(0);

  const [variantChoices, setVariantChoices] = useState<Record<string, any>[]>([]);

  const allStocksHandled = useMemo(
    () => (variantChoices.length > 0 ? handledStockQuantity === stockQuantity : true),
    [handledStockQuantity, stockQuantity, variantChoices],
  );
  //#region MEDIA

  const [selectedMedia, setSelectedMedia] = useState<number[]>([]);

  //#endregion

  const allowedToSeeParticipationFormsSets = useUserIsAllowed(
    ResourceAction.Get,
    'participation-forms-sets',
  );

  return (
    <AppTabbedForm {...props} maxWidth="lg">
      <AppFormTab label="Panoramica" disabledCards={props.mode === 'show'}>
        {!!props.record?.id && props.record?.external ? (
          <JugaadExternalPropertyInfo itemId={props.record.id} />
        ) : (
          <></>
        )}
        <AppCard title="Informazioni generali" expanded>
          <AppAutocompleteInput
            source="fkFolder"
            reference={getReference(props.resource, 'folders')}
            label={
              props.resource === 'items-private'
                ? 'Pratica di appartenenza *'
                : 'Fascicolo di appartenenza *'
            }
            defaultValue={parseInt(folderId as string) ?? undefined}
            optionText={(selectedFolder) => {
              if (!selectedFolder) return;
              return selectedFolder.title ? selectedFolder.title : selectedFolder.procedureCode;
            }}
            disabled={props.mode !== 'create'}
            required //TODO: doesn't show the "*" in the label if "reference" prop is set (?)
            onSelect={(selectedFolder) => {
              setSelectedFolderType(selectedFolder.type);
            }}
            md={4}
          />
          <AppBooleanInput source="handleSale" label="Gestiamo la vendita" md={2} />
          <AppTextInput source="code" label="Codice del lotto" required md={2} />
          {IS_IVG ? (
            <AppTextInput source="ivgCode" label="Numero IVG" required md={2} />
          ) : (
            <AppTextInput source="cdaCode" label="Codice CDA" disabled md={2} />
          )}
          {props.mode !== 'create' && (
            <Grid md={2}>
              <AppItemLinksButton
                resource={props.resource}
                folderIdSource="fkFolder"
                itemIdSource="id"
                record={props.record}
                className={classes.appItemLinkButton}
                currentResourceLabel="Lotto"
              />
            </Grid>
          )}
          <AppTextInput source="title" label="Titolo" required multiline />
          {props.mode !== 'create' ? (
            <TotalClicks infoRequestEnabled={INFO_REQUEST_ENABLED} />
          ) : (
            <AppInputFiller md={INFO_REQUEST_ENABLED ? 2 : 4} />
          )}
          <AppBooleanInput
            source="highlighted"
            label="Bene in evidenza"
            md={2}
            className={classes.highlighted}
          />
          {INFO_REQUEST_ENABLED && (
            <AppBooleanInput
              source="infoRequestEnabled"
              label="Abilita richiesta info"
              md={2}
              className={classes.highlighted}
            />
          )}
          {renderCounters(
            'Lunghezza titolo',
            'title',
            magazineTitleLengthPremium,
            magazineTitleLength2X,
            magazineTitleLength3X,
          )}
          <AppTextInput
            source="description"
            label="Descrizione"
            md={12}
            style={{ marginTop: '40px' }}
            required
            multiline
            expandable
          />
          {renderCounters(
            'Lunghezza descrizione',
            'description',
            magazineDescriptionLengthPremium,
            magazineDescriptionLength2X,
            magazineDescriptionLength3X,
          )}
          <AppCategorizationInput mode={props.mode} resource={props.resource} />
          {selectedFolderType === 'judiciary' && (
            <AppNumberInput source="evaluation" label="Valore di perizia" isAmount md={4} />
          )}
          <AppNumberInput
            source="value"
            label="Valore commerciale"
            isAmount
            md={4}
            variant="outlined"
          />
          {selectedFolderType === 'private' && [
            <AppSelectInput
              source="ownershipType"
              label="Tipo di proprietà"
              choices={OWNERSHIP_TYPES}
              md={3}
            />,
            <AppInputFiller md={9} />,
          ]}
        </AppCard>
        <FormDataConsumer>
          {({ formData }) => (
            <ItemRelatedData mode={props.mode} formData={formData} resource={props.resource} />
          )}
        </FormDataConsumer>
        <AppCard title="Personalizzazione magazine" hidden={props.permissions?.agency}>
          <AppTextInput
            source="magazineTitle"
            label="Titolo"
            md={12}
            onChange={(e) => {
              checkLength('title', e);
            }}
          />
          {renderCounters(
            'Lunghezza titolo premium',
            'magazineTitle',
            magazineTitleLengthPremium,
            magazineTitleLength2X,
            magazineTitleLength3X,
          )}
          <AppTextInput
            source="magazineDescription"
            label="Descrizione"
            md={12}
            style={{ marginTop: '40px' }}
            multiline
            onChange={(e) => {
              checkLength('description', e);
            }}
          />
          {renderCounters(
            'Lunghezza descrizione premium',
            'magazineDescription',
            magazineDescriptionLengthPremium,
            magazineDescriptionLength2X,
            magazineDescriptionLength3X,
          )}
          <FormDataConsumer>
            {({ formData }) => {
              //const { change } = useForm();
              return [
                <AppBooleanInput
                  source="magazine"
                  label="Includi in bollettino"
                  initialValue={true}
                  disabled={props.mode === 'show'}
                  //onChange={(value) => !value && change('magazinePremium', false)}
                />,
                <AppBooleanInput
                  source="magazinePremium"
                  label="Scheda premium"
                  disabled={!formData.magazine || props.mode === 'show'}
                />,
              ];
            }}
          </FormDataConsumer>
        </AppCard>
        <AppCard title="Codici interni" hideContent={props.mode === 'create'}>
          <AppTextInput
            source="id"
            label="Progressivo interno"
            md={3}
            disabled
            helperText="Autogenerato alla creazione"
          />
          <AppRelatedResource
            relatedResource="/codes"
            manager={
              <AppCodeManager
                title={(mode) => (mode === 'insert' ? 'Inserisci nuovo codice' : 'Modifica codice')}
              />
            }
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <FunctionField
                render={(record) => {
                  if (record?.name === 3) {
                    return <AppTextField source="customName" emptyText="Altro" record={record} />;
                  }
                  return <SelectField choices={CODES_TYPES} source="name" record={record} />;
                }}
                label="Nome"
                headerClassName={classes.codeName}
              />
              <AppTextField source="value" label="Codice" headerClassName={classes.code} />
              <BooleanField source="publishOnline" label="Pubblicato online" />
              <FunctionField
                render={(record) => (record?.refType === 'folder' ? 'Fascicolo' : 'Lotto')}
                label="Associazione"
                headerClassName={classes.lastDatagridColumn}
              />
              <FunctionField
                render={(record) => (
                  <AppRelatedResourceEditButton
                    disabled={props.mode === 'show' || record?.refType === 'folder'}
                    record={record}
                  />
                )}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    redirect={false}
                    confirmTitle="Eliminazione codice"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                    disabled={props.mode === 'show' || record?.refType === 'folder'}
                    record={record}
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Posizione" path="geographies" disabledCards={props.mode === 'show'}>
        <AppCard title="Informazioni indirizzo" expanded>
          <FormDataConsumer>
            {({ formData }) => (
              <AppGeographiesInput
                addressSource="address"
                addressNumberSource="addressNumber"
                zipCodeSource="zipCode"
                variant="outlined"
                countrySource="fkCountry"
                regionSource="fkRegion"
                provinceSource="fkProvince"
                citySource="fkCity"
                latitudeSource="latitude"
                longitudeSource="longitude"
                streetViewLatitudeSource="streetViewLatitude"
                streetViewLongitudeSource="streetViewLongitude"
                streetViewPovSource="streetViewPov"
                mode={props.mode}
                required={formData.fkGenre === ItemGenre.RealEstate}
                record={props.record}
                geocoderRef={props.options.geocoderRef}
                fillCityOnly={formData.fkGenre === ItemGenre.Movable}
              />
            )}
          </FormDataConsumer>
          <AppDivider />
          <AppTextInput source="commercialAddress" label="Indirizzo commerciale" md={12} />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Persone & Ruoli" path="capabilities" disabledCards={props.mode === 'show'}>
        <MandatoryOrSuggestedRoles resourceType="item" />
        <AppCard title="Lista dei ruoli del lotto" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/capabilities"
            manager={
              <AppCapabilityManager
                title={(mode, record) =>
                  mode === 'insert'
                    ? 'Collega nuovo ruolo'
                    : `Modifica il ruolo ${record.role?.description ?? ''} collegato a ${
                        record.person?.businessName ?? 'questa anagrafica'
                      }`
                }
                resource="item"
              />
            }
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid
              rowStyle={(record) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const validityTo = new Date(record.validityTo);
                validityTo.setHours(0, 0, 0, 0);
                return validityTo.getTime() < today.getTime() ? { backgroundColor: amber[50] } : {};
              }}
            >
              <AppTextField
                source="role.description"
                label="Ruolo"
                headerClassName={classes.roleNameCol}
              />
              <FunctionField
                label="Persona o azienda"
                render={(record) =>
                  record?.person.type === 'person'
                    ? `${record.person.firstName} ${record.person.lastName}`
                    : `${record?.person.businessName}`
                }
                headerClassName={classes.nameCol}
              />
              <AppDateField
                source="validityFrom"
                label="Inizio validità"
                headerClassName={classes.dateCol}
              />
              <AppDateField
                source="validityTo"
                label="Termine validità"
                headerClassName={classes.dateCol}
              />
              <SelectField source="savedAs" label="Salvato come" choices={CAPABILITY_SAVED_AS} />
              <FunctionField
                render={(record) => (record?.refType === 'folder' ? 'Fascicolo' : 'Lotto')}
                label="Associazione"
                headerClassName={classes.lastDatagridColumn}
              />
              <FunctionField
                render={(record) => (
                  <AppRelatedResourceEditButton
                    disabled={
                      record?.refType === 'folder' || record?.savedAs || props.mode === 'show'
                    }
                    record={record}
                  />
                )}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    record={record}
                    disabled={
                      record?.refType === 'folder' || record?.savedAs || props.mode === 'show'
                    }
                    redirect={false}
                    confirmTitle="Eliminazione assegnatario"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
        <AppCard title="Agenti" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/agents"
            manager={<AppAgentManager title={() => 'Nuovo agente'} resource="item" />}
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <AppTextField source="name" label="Nome" headerClassName={classes.agentNameCol} />
              <AppTextField source="email" label="Email" headerClassName={classes.agentEmailCol} />
              <AppTextField
                source="phone"
                label="Telefono"
                headerClassName={classes.agentPhoneCol}
              />
              <AppTextField
                source="mobile"
                label="Mobile"
                headerClassName={classes.agentMobileCol}
              />
              <FunctionField
                render={(record) => (
                  <AppRelatedResourceEditButton
                    disabled={record?.savedAs || props.mode === 'show'}
                    record={record}
                  />
                )}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    record={record}
                    disabled={record?.savedAs || props.mode === 'show'}
                    redirect={false}
                    confirmTitle="Eliminazione agente"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab
        label="Esperimenti di vendita"
        path="sale-experiments"
        disabledCards={props.mode === 'show'}
      >
        <AppCard title="Dati di vendita" expanded>
          <ReferenceInput
            reference="sale-status"
            source="fkSaleStatus"
            filter={
              selectedFolderType
                ? { [selectedFolderType === 'judiciary' ? 'judiciary' : 'private']: true }
                : {}
            }
            sort={{ field: 'description', order: 'ASC' }}
            label="Stato di vendita"
            disabled
            md={4}
          >
            <AppSelectInput optionText="description" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.fkSaleStatus === SaleStatus.ClosedAndAwarded && (
                <>
                  <AppDateInput
                    source="soldAt"
                    label="Data di vendita"
                    md={2}
                    variant="outlined"
                    required
                    disabled
                  />
                </>
              )
            }
          </FormDataConsumer>
          {props.mode !== 'create' && !isAdvertisement && (
            <VerifyBalanceButton record={props.record} />
          )}
          {isAdvertisement && (
            <AppTextInput
              source="saleUrl"
              label="Indirizzo web della vendita"
              placeholder="https://www.sitodellavendita.it/bene-in-vendita"
            />
          )}
        </AppCard>
        <AppCard
          title="Lista degli esperimenti di vendita del lotto"
          hideContent={props.mode === 'create'}
          expanded={props.mode !== 'create'}
        >
          <AppRelatedResource
            relatedResource="/sale-experiments"
            actionsHidden={
              props.mode === 'show' ||
              (props.record.fkSaleStatus === SaleStatus.ClosedAndAwarded &&
                !props.record.currentSaleExperimentHasA107)
            }
            actions={[
              <AppGoToResourceButton
                mode="create"
                currentResourceLabel="Lotto"
                currentResourceId={props.id}
                destinationResourceName={getReference(props.resource, 'sale-experiments')}
                buttonLabel="Crea"
                detailParameters={{
                  folderId: props.record.fkFolder,
                }}
                className={classes.goToResourceButton}
              />,
            ]}
          >
            <AppDatagrid additionalInfo={<ClosedInfo item={props.record} />}>
              <ReferenceField
                reference="sale-modes"
                source="fkSaleMode"
                label="Modalità di vendita"
                basePath="sale-modes"
                link={false}
                headerClassName={classes.saleMode}
              >
                <AppTextField source="description" />
              </ReferenceField>
              <AppTextField
                source="basePrice"
                label="Prezzo base"
                type="amount"
                headerClassName={classes.price}
              />
              {selectedFolderType === 'judiciary' && (
                <AppTextField
                  source="minPrice"
                  label="Prezzo minimo"
                  type="amount"
                  headerClassName={classes.price}
                />
              )}
              <AppDateField
                source="auctionStartAt"
                label="Data inizio asta"
                showTime
                headerClassName={classes.date}
              />
              <AppDateField
                source="auctionEndAt"
                label="Data fine asta"
                showTime
                headerClassName={classes.date}
              />
              <BooleanField
                source="isCurrent"
                label="Esperimento corrente"
                headerClassName={classes.lastDatagridColumn}
              />
              <AppGoToResourceButton
                mode="show"
                destinationResourceName={getReference(props.resource, 'sale-experiments')}
                currentResourceLabel="Lotto"
                buttonLabel="Mostra"
              />
              {props.mode === 'edit' && (
                <AppGoToResourceButton
                  mode="edit"
                  destinationResourceName={getReference(props.resource, 'sale-experiments')}
                  currentResourceLabel="Lotto"
                  buttonLabel="Modifica"
                />
              )}
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Pubblicazioni" path="publications" maxWidth={false}>
        <AppCard
          title="Lista delle pubblicazioni del lotto"
          hideContent={props.mode === 'create'}
          expanded
        >
          <AppRelatedResource
            relatedResource="/publications"
            manager={
              <AppPublicationManager
                anchor="right"
                title={(mode, record) =>
                  mode === 'insert'
                    ? 'Nuova pubblicazione'
                    : `Modifica la pubblicazione ${
                        record.property ? `su ${record.property.name.toUpperCase()}` : ''
                      }`
                }
                itemTitle={props.record?.title}
                canCreateEcwidPublication={allStocksHandled}
                handledStockQuantity={handledStockQuantity}
              />
            }
            actionsHidden={
              props.mode === 'show' ||
              !props.record.relatedSaleExperiments ||
              !props.record.relatedSaleExperiments.find((saleExp) => saleExp.isCurrent)
            }
          >
            <AppDatagrid additionalInfo={<PublicationDisclaimer record={props.record} />}>
              <AppTextField
                source="property.name"
                label="Sito"
                headerClassName={classes.propertyName}
              />
              <AppDateField
                source="publicationDate"
                label="Data di pubblicazione"
                headerClassName={classes.publicationDates}
              />
              <AppDateField
                source="publicationEndDate"
                label="Termine pubblicazione"
                headerClassName={classes.publicationDates}
              />
              <BooleanField
                source="isPublished"
                label="Attualmente pubblicato"
                headerClassName={classes.isPublished}
              />
              <BooleanField
                source="isSale"
                label="Gestisce la vendita"
                headerClassName={classes.isPublished}
              />
              <AppTextField source="notes" label="Note" truncate headerClassName={classes.notes} />
              <FunctionField
                headerClassName={classes.lastDatagridColumn}
                render={(publication) =>
                  publication?.isPublished && (
                    <AppUrlField
                      url={getPublicationUrl(publication as any)}
                      text="Dettaglio sul sito"
                      variant="button"
                    />
                  )
                }
              />
              <PublicationCertificateButton itemId={props.id} />
              <AppRelatedResourceEditButton disabled={props.mode === 'show'} />

              <FunctionField
                headerClassName={classes.lastDatagridColumn}
                render={(publication) =>
                  publication?.property.schema && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        history.push(
                          `/external-properties-update?filter={"fkPublication":"${publication?.id}"}&order=DESC&page=1&perPage=20&sort=id`,
                        );
                      }}
                      label="Storico aggiornamenti"
                    />
                  )
                }
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Media" path="media" disabledCards={props.mode === 'show'} maxWidth={false}>
        <AppCard expanded hideContent={props.mode === 'create'} title="Gestione media">
          <AppBooleanInput source="zoomEnabled" label="Abilita zoom" md={2} />
          <AppTextInput source="virtualTourUrl" label="URL del virtual-tour" md={4} />
          <AppTextInput source="videoUrl" label="URL del video" md={4} />
          <AppTextInput source="virtualTourCode" label="Codice tour Ricoh360" md={2} />
          <AppDivider marginBottom={0} />
          <AppRelatedResource
            relatedResource="/media"
            sort={{} /* Managed by Backend */}
            manager={<UploadMediaManager mainResource="item" />}
            actions={[
              <AppButtonGroup title="Documenti" icon={<Description />}>
                <UploadMediaButton mediaType="document" label="Caricamento" />
              </AppButtonGroup>,
              <AppButtonGroup title="Immagini" icon={<PhotoCamera />}>
                <UploadMediaButton mediaType="image" label="Caricamento singolo" />
                <UploadMediaButton
                  mediaType="image"
                  label="Caricamento multiplo web"
                  multiple
                  role="Foto"
                />
                <UploadMediaButton
                  mediaType="image"
                  label="Caricamento multiplo uso interno"
                  multiple
                  role="Foto ad uso interno"
                />
              </AppButtonGroup>,
              <AppButtonGroup title="Virtual Tour" icon={<ThreeDRotation />}>
                <UploadMediaButton mediaType="virtual-tour" label="Caricamento singolo" />
                <UploadMediaButton mediaType="virtual-tour" label="Caricamento multiplo" multiple />
              </AppButtonGroup>,
              <AppButtonGroup title="Video" icon={<VideoCall />}>
                <UploadMediaButton mediaType="video" label="Caricamento" />
              </AppButtonGroup>,
              <Button label="Ordina:" disabled style={{ marginLeft: '80px' }} />,
              <Button
                label="Doc"
                variant="contained"
                color="primary"
                startIcon={<Description />}
                style={{ marginRight: '10px' }}
                onClick={() => openOrderDialog('document')}
              />,
              <Button
                label="Immagini"
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
                startIcon={<PhotoCamera />}
                onClick={() => openOrderDialog('image')}
              />,
              <Button
                label="VT"
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
                startIcon={<ThreeDRotation />}
                onClick={() => openOrderDialog('virtual-tour')}
              />,
              <Button
                label="Video"
                variant="contained"
                color="primary"
                startIcon={<VideoCall />}
                onClick={() => openOrderDialog('video')}
              />,
            ]}
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid
              resource={props.resource}
              expand={<MediaExpand />}
              header={
                <MediaHeader
                  selectedMedia={selectedMedia}
                  clearSelectedMedia={() => setSelectedMedia([])}
                />
              }
            >
              <MediaSelectButton
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
              />
              <AppDownloadField
                source="name"
                label="File"
                sortable={false}
                headerClassName={classes.mediaGridNameCol}
              />
              <AppTextField
                source="description"
                label="Titolo"
                sortable={false}
                headerClassName={classes.mediaGridDescCol}
                cellClassName={classes.mediaGridDescCol}
              />
              <AppTextField
                source="role.name"
                label="Tipologia"
                sortable={false}
                headerClassName={classes.mediaGridTypeCol}
              />
              <FunctionField
                render={(record) => (record?.refType === 'folder' ? 'Fascicolo' : 'Lotto')}
                label="Associazione"
              />
              <FunctionField
                cellClassName={classes.mediaGridPreview}
                render={(record) => (record ? <ImagePreview record={record} /> : <></>)}
              />
              {/* <ForceUploadToCdnButton disabled={props.mode === 'show'} /> */}
              <UploadMediaButton
                label="modifica"
                mode="edit"
                mainResource="items"
                disabled={props.mode === 'show'}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    record={record}
                    disabled={record?.refType === 'folder' || props.mode === 'show'}
                    redirect={false}
                    confirmTitle="Eliminazione allegato"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
          {orderMediaType !== '' && (
            <FunctionField
              render={(r) => {
                return (
                  <OrderMediaManager
                    mainResource="item"
                    reference={r?.id}
                    orderMediaType={orderMediaType}
                    onDialogClose={handleDialogClose}
                    onOrderFinish={handleOrderFinish}
                  />
                );
              }}
            />
          )}
        </AppCard>
        <AppCard
          expanded
          title="Moduli di partecipazione"
          hidden={!allowedToSeeParticipationFormsSets}
        >
          <ReferenceInput
            reference="participation-forms-sets"
            source="fkParticipationFormsSet"
            sort={{ field: 'title', order: 'ASC' }}
            label="Moduli di partecipazione"
            md={4}
          >
            <AppSelectInput optionText="title" />
          </ReferenceInput>
        </AppCard>
      </AppFormTab>
      {CHATBOT_OWNER && (
        <AppFormTab label="Chatbot" path="chatbot" disabledCards={props.mode === 'show'}>
          <AppCard collapsable={false} hideContent={props.mode === 'create'}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.chatbotItemId ? (
                  <Fragment>
                    <AppTextInput source="chatbotItemId" label="ID CHATBOT" disabled />
                    <Grid item md={3} className={classes.executeChatbotImportButton}>
                      <ChatbotExecuteImportButton
                        importType={ImportType.Single}
                        chatbotId={formData.chatbotItemId}
                        disabled={props.mode !== 'edit'}
                      />
                    </Grid>
                    <AppBooleanInput
                      {...rest}
                      source="chatbotUpdatesEnabled"
                      label="Aggiornamenti automatici attivi"
                      md={3}
                    />
                  </Fragment>
                ) : (
                  <Grid item md={12}>
                    <AppAlert
                      children="Lotto non importato da CHATBOT"
                      severity="info"
                      className={classes.itemNotFromChatbotAlert}
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
          </AppCard>
        </AppFormTab>
      )}
      {CAN_SEE_VARIANTS_TAB.includes(props.permissions?.roleId) && (
        <AppFormTab label="Varianti" path="buy-now-variants" maxWidth={false}>
          <AppCard
            title="Lista delle varianti del compra subito"
            hideContent={props.mode === 'create'}
            expanded
          >
            <AppRelatedResource
              relatedResource="/buy-now-variants"
              manager={
                <AppBuyNowItemVariantsManager
                  anchor="right"
                  title={(mode, record) =>
                    mode === 'insert'
                      ? 'Nuova variante'
                      : `Modifica la variante ${
                          record.property ? `su ${record.property.name.toUpperCase()}` : ''
                        }`
                  }
                  itemTitle={props.record?.title}
                />
              }
              onChange={(variants) => {
                setVariantChoices(variants);
              }}
              actionsHidden={
                props.mode === 'show' || (variantChoices.length > 0 && allStocksHandled)
              }
            >
              <AppDatagrid>
                <AppTextField source="name" label="Nome" headerClassName={classes.propertyName} />
                <ArrayField
                  source="values"
                  label="Valori"
                  headerClassName={classes.lastDatagridColumn}
                >
                  <SingleFieldList>
                    <ChipField source="value" size="small" clickable={false} />
                  </SingleFieldList>
                </ArrayField>

                <AppRelatedResourceEditButton disabled={props.mode === 'show'} />
              </AppDatagrid>
            </AppRelatedResource>
          </AppCard>
          <AppCard title="Giacenze" hideContent={props.mode === 'create'} expanded>
            <Grid item md={12} style={{ marginBottom: '12px' }}>
              <AppAlert severity={allStocksHandled ? 'success' : 'warning'}>
                {allStocksHandled
                  ? 'Le giacenze sono configurate correttamente, è possibile procedere con la pubblicazione del lotto.'
                  : `Sono state configurate ${handledStockQuantity} giacenze su un totale di ${stockQuantity}. Per poter pubblicare il lotto è necessario configurare tutte le giacenze.`}
              </AppAlert>
            </Grid>
            <AppRelatedResource
              relatedResource="/buy-now-stocks"
              manager={
                <AppBuyNowItemStocksManager
                  anchor="right"
                  title={(mode, record) =>
                    mode === 'insert'
                      ? 'Nuova giacenza'
                      : `Modifica la giacenza ${
                          record.property ? `su ${record.property.name.toUpperCase()}` : ''
                        }`
                  }
                  itemTitle={props.record?.title}
                />
              }
              actionsHidden={props.mode === 'show' || allStocksHandled}
              onChange={(stocks) =>
                setHandledStockQuantity(stocks.reduce((acc, stock) => acc + stock.quantity, 0))
              }
            >
              <AppDatagrid>
                <AppTextField
                  source="quantity"
                  label="Quantità"
                  headerClassName={classes.propertyName}
                />

                <ArrayField source="values" label={'Valori'}>
                  <SingleFieldList>
                    <ChipField source="name" size="small" />
                  </SingleFieldList>
                </ArrayField>
                <AppImageField
                  source="mediaUrl"
                  label="Immagine"
                  className={classes.mediaGridNameCol}
                />

                <AppRelatedResourceEditButton disabled={props.mode === 'show'} />
              </AppDatagrid>
            </AppRelatedResource>
          </AppCard>
        </AppFormTab>
      )}
    </AppTabbedForm>
  );
};

export default ItemDetail;
