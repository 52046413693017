import React, { FC } from 'react';
import { FunctionField } from 'react-admin';
import { amountFormatter, dateFormatter } from '../../../utils/data-formatters';
import { AppBold, AppText } from '../text';

export const AppMediaAdditionalFields: FC<any> = (props) => {
  const { people, ...rest } = props;
  const { additionalFields: mediaAdditionalFields } = props.record.metadata;
  const { additionalFields: additionalFieldsInfo } = props.record.role;

  if (!mediaAdditionalFields) return <AppText children="-" />;

  const getPerson = (personId) => {
    const person = people.find((person) => person.id === personId);
    return person ? `${person.firstName} ${person.lastName}` : '-';
  };

  return (
    <FunctionField
      {...rest}
      render={() =>
        additionalFieldsInfo
          .sort((a, b) => a.order ?? 0 - b.order ?? 0)
          .map((fieldInfo) => {
            let mediaAdditionalFieldValue = mediaAdditionalFields[fieldInfo.fieldName];
            // Format additional field value by checking its type
            switch (fieldInfo.type) {
              case 'text':
              case 'number':
                break;
              case 'amount':
                mediaAdditionalFieldValue = amountFormatter(mediaAdditionalFieldValue);
                break;
              case 'date':
                mediaAdditionalFieldValue = dateFormatter(mediaAdditionalFieldValue, true);
                break;
              case 'registry':
                mediaAdditionalFieldValue = getPerson(mediaAdditionalFieldValue);
                break;
            }

            return (
              //TODO: could use a button to open a modal that contains the additional fields, could be more readable this way
              <AppText>
                <AppBold>{fieldInfo.label}:</AppBold> {mediaAdditionalFieldValue ?? '-'}
              </AppText>
            );
          })
      }
    />
  );
};

export default AppMediaAdditionalFields;
