import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { ReferenceInput } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppDateInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { minDate } from '../../utils/validators';

const InvoiceDetails: FC<any> = (props) => {
  return (
    <AppBaseForm {...props} disabledCards={props.mode !== 'create'}>
      <AppCard collapsable={false} title="Impostazioni generazione CSV">
        <AppTextInput source="title" label="Titolo" md={6} />
        {props.mode !== 'create' && <AppTextInput source="fileName" label="Nome file" md={6} />}
        <AppDivider />
        <AppDateInput source="soldAtFrom" label="Data aggiudicazione dal" md={3} required />
        <AppDateInput
          source="soldAtTo"
          label="Data aggiudicazione al"
          md={3}
          validate={[minDate('soldAtFrom', 'Data aggiudicazione dal')]}
          required
        />
        <ReferenceInput reference="genres" source="genreId" label="Genere" md={3}>
          <AppSelectInput optionText="description" />
        </ReferenceInput>
        <ReferenceInput
          reference="sale-modes"
          source="saleModeId"
          label="Modalità di vendita"
          md={3}
        >
          <AppSelectInput optionText="description" />
        </ReferenceInput>
        {props.mode !== 'create' && [
          <AppDivider />,
          <Grid item md={12}>
            <AppDownloadFromGCPBucketField record={props.record} alwaysShow protectedFile />
          </Grid>,
        ]}
      </AppCard>
    </AppBaseForm>
  );
};

export default InvoiceDetails;
