import { AutocompleteInputProps } from 'ra-ui-materialui/lib/input/AutocompleteInput';
import { ReferenceInputProps } from 'ra-ui-materialui/lib/input/ReferenceInput';
import { FC, ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  Validator,
} from 'react-admin';
import { getValidators } from '../../../utils/validators';

import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

type AppAutocompleteInputProps = AutocompleteInputProps &
  Omit<ReferenceInputProps, 'children' | 'reference'> &
  AppGridProps & { isArray?: boolean; reference?: string };

export const AppAutocompleteInput: FC<AppAutocompleteInputProps> = (
  props: AppAutocompleteInputProps,
): ReactElement => {
  const {
    outOfGrid,
    allowEmpty,
    filter,
    filterToQuery,
    perPage,
    reference,
    sort,
    source, // must be on ReferenceInput
    record, // must be on ReferenceInput
    label, // must be on ReferenceInput
    required,
    validate: customValidators,
    isArray = false,
    ...autocompleteAndGridProps
  } = props;

  if (!source) throw new Error('AppAutocompleteInput. source prop required');

  const autocompleteProps = inputProps(autocompleteAndGridProps);
  const referenceProps = { filter, filterToQuery, perPage, reference, sort };
  const commonProps = { allowEmpty, source, record, label };

  const validators = getValidators(customValidators as Validator[], required);

  let AutocompleteComponent = AutocompleteInput;
  let ReferenceComponent = ReferenceInput;
  if (isArray) {
    AutocompleteComponent = AutocompleteArrayInput;
    ReferenceComponent = ReferenceArrayInput;
  }

  if (!reference) {
    return gridWrappedInput(
      autocompleteAndGridProps,
      <AutocompleteComponent validate={validators} {...autocompleteProps} {...commonProps} />,
      outOfGrid,
    );
  }

  return gridWrappedInput(
    autocompleteAndGridProps,
    <ReferenceComponent {...referenceProps} {...commonProps}>
      <AutocompleteComponent validate={validators} {...autocompleteProps} />
    </ReferenceComponent>,
    outOfGrid,
  );
};

export default AppAutocompleteInput;
