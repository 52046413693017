import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'artJeweleryWatchesAntiques';

const ArtJeweleryWatchesAntiquesDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 10) return <React.Fragment />;

  return (
    <AppCard title='Informazioni aggiuntive sul bene' disabled={mode === 'show'}>
      <AppTextInput source={`${BASE_SOURCE}.type`} label='Tipologia' md={4} />
      <AppTextInput source={`${BASE_SOURCE}.material`} label='Materiale' md={4} />
    </AppCard>
  );
};

export default ArtJeweleryWatchesAntiquesDetails;
