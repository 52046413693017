import ManageMagazineIcon from '@material-ui/icons/PostAdd';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import MagazineDetails from './MagazineDetails';
import MagazineList from './MagazineList';

const Magazine: ResourceExport = {
  list: MagazineList,
  create: resourceWrapper('create', MagazineDetails, undefined, undefined, undefined),
  edit: resourceWrapper('edit', MagazineDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', MagazineDetails, undefined, undefined, undefined),
  icon: ManageMagazineIcon,
};

export default Magazine;
