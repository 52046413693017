import { Box, Chip, Grid, GridProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}));

interface ChipPanelFieldProps extends GridProps {
  color?: 'default' | 'primary' | 'secondary' | undefined;
  label: string;
  value: string | undefined;
  className?: string;
  emptyText?: string;
  style?: any;
}

export const ChipPanelField: FC<ChipPanelFieldProps> = ({
  md = 6,
  color = 'default',
  label,
  value,
  className,
  emptyText = '-',
  style = {},
}) => (
  <Grid item md={md} className={useStyles().container}>
    <Typography align="left" className={className}>
      <Box fontWeight="bold">{label.toUpperCase()}</Box>
      <Box display="block">
        <Chip color={color} label={value ?? emptyText} style={style} />
      </Box>
    </Typography>
  </Grid>
);
