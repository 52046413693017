import { useEffect, useRef } from 'react';
type Callback = () => void | (() => void | undefined);
/**
 * Invokes `effect` only once, when the component is mounted.
 * Differently from `useEffect`, `useDidMount` is invoked only after the first render,
 * rendering its behaviour similar to `componentDidMount` on class components.
 */
export const useDidMount = (effect: Callback): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) return;

    didMountRef.current = true;
    effect();
  }, []);
};

/*
 Thank you, Flavio ;)
 */
