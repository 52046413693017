import { createMuiTheme } from '@material-ui/core';

const PALETTE = {
  primary: {
    main: process.env.REACT_APP_IS_IVG === 'true' ? '#007bff' : '#2f9934',
    //light: //
    //dark: //
    //contrastText: '#FFF',
  },
  secondary: {
    main: '#5a6169',
    light: '#F5F5F5',
  },
  //colors to be used for notifications type
  error: {
    main: '#c4183c',
  },
  success: {
    main: '#17c671',
  },
  warning: {
    main: '#ffb400',
  },
  info: {
    main: '#00b8d8',
  },
  // not changed from base
  action: {
    selected: 'rgba(0, 0, 0, 0.08)',
    hover: 'rgba(0, 0, 0, 0.04)',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.8)',
  },
  background: {
    default: '#fafafa',
  },
};

const AppTheme = createMuiTheme({
  palette: {
    type: 'light', //default setting (we could also add a type: 'dark' palette)
    contrastThreshold: 3,
    tonalOffset: 0.2,
    ...PALETTE,
  },
  spacing: 6,
  typography: {
    //htmlFontSize: 18,
    fontSize: 12,
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginBottom: 0,
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: '0 !important',
      },
    },
    MuiInputBase: {
      input: {
        //fontSize: '0.75rem'
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: '#FFF',
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 6,
        },
      },
    },
    MuiGrid: {
      item: {
        paddingTop: '0 !important',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#FFF',
      },
      scroller: {
        backgroundColor: PALETTE.background.default,
      },
    },
    MuiTablePagination: {
      root: {
        minHeight: '3rem',
        overflow: 'hidden',
      },
    },
    MuiSwitch: {
      root: {
        position: 'relative',
        top: '6px',
        '&+ span': {
          position: 'relative',
          top: '6px',
        },
      },
    },
    //MuiButton: {}, // Edit default buttons' style
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    RaConfirm: {
      iconPaddingStyle: {
        paddingRight: '0.3rem',
      },
    },
    RaAutocompleteInput: {
      suggestionsContainer: {
        zIndex: 1500,
      },
    },
    RaListToolbar: {
      toolbar: {
        minHeight: 'auto',
        backgroundColor: '#FFF',
      },
    },
    RaMenuItemLink: {
      XXactive: {
        color: PALETTE.primary.main,
        backgroundColor: PALETTE.action.selected,
        fontWeight: 'bold',
        borderLeft: `4px solid ${PALETTE.primary.main}`,
        '& div': {
          color: 'inherit',
          marginLeft: -4,
        },
      },
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: 'inherit',
      },
    },
  },
});

export default AppTheme;
