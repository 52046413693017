import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppTextField } from '../../Components/ui/field';
import AppTitle from '../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    description: {
      minWidth: theme.spacing(50),
    },
    path: {
      width: '100%',
    },
  }),
  { name: 'PermissionList' },
);

const PermissionList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Catalogo permessi" />}
      bulkActionButtons={false}
      //   filters={<AppListFilter helperText="Nome" />}
    >
      <AppDatagrid>
        <AppTextField source="code" label="Codice unico" />
        <AppTextField
          source="description"
          label="Descrizione"
          truncate
          maxTextLength={40}
          headerClassName={classes.description}
        />
        <AppTextField source="method" label="Metodo" />
        <AppTextField source="path" label="Percorso" headerClassName={classes.path} />
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default PermissionList;
