export const FEE_PROFILE_TYPE_CHOICES = [
  { id: 'manual', name: 'Manuale' },
  { id: 'filters', name: 'Filtri' },
];

export const CONDITION_TYPE_CHOICES = [
  { id: '>', name: 'Maggiore di' },
  { id: '>=', name: 'Maggiore o uguale a' },
  { id: '<', name: 'Minore di' },
  { id: '<=', name: 'Minore o uguale a' },
];

export const APPLY_A_107_CHOICES = [
  { id: true, name: 'SI' },
  { id: false, name: 'NO' },
];
