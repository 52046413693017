import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Record as RaRecord, RedirectionSideEffect } from 'ra-core';
import * as React from 'react';
import { FC, Fragment, ReactElement, ReactNode } from 'react';

import { ClassesOverride, SaveButton } from 'react-admin';
import { FormRenderProps } from 'react-final-form';
import { useLocation } from 'react-router';
import AppCancelButton from '../button/AppCancelButton';

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      boxShadow: theme.shadows[4],
    },
    desktopToolbar: {
      //marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '16px',
      width: '100%',
      boxSizing: 'border-box',
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    spacer: {
      [theme.breakpoints.down('xs')]: {
        height: '5em',
      },
    },
    withLeftMargin: { marginLeft: theme.spacing(1) },
  }),
  { name: 'AppBottomToolbar' },
);

const valueOrDefault = (value, defaultValue) =>
  typeof value === 'undefined' ? defaultValue : value;

const AppBottomToolbar: FC<AppBottomToolbarProps> = (props) => {
  const {
    alwaysEnableSaveButton,
    basePath,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    //record,
    //redirect,
    //resource,
    saving,
    submitOnEnter,
    //undoable,
    width,
    //mode
    mode,
    resourceOptions,
    //...rest
  } = props;
  const classes = useStyles(props);

  // Use form pristine to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(alwaysEnableSaveButton, !pristine);

  //get query string
  const location = useLocation();
  const { goBack, resourceLabel, previousResourcePath } = queryString.parse(location.search);
  const saveButtonText = goBack ? `Salva e torna a ${resourceLabel}` : 'Salva e torna alla lista';

  const defaultToolbarButtons: ReactElement[] = [
    <AppCancelButton
      basePath={basePath}
      goBack={goBack}
      resourceLabel={resourceLabel}
      previousResourcePath={previousResourcePath}
      mode={mode}
    />,
  ];

  if (mode !== 'show') {
    defaultToolbarButtons.push(
      <Box flex={1} />,
      <SaveButton
        label={saveButtonText}
        className={classes.withLeftMargin}
        handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
        variant="outlined"
        color="primary"
        disabled={disabled}
        invalid={invalid}
        redirect={goBack && previousResourcePath ? (previousResourcePath as string) : 'list'}
        saving={saving}
        submitOnEnter={false}
      />,
      <SaveButton
        label="Salva"
        className={classes.withLeftMargin}
        handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
        variant="outlined"
        color="primary"
        disabled={disabled}
        invalid={invalid}
        redirect={(basePath, id) =>
          goBack
            ? `${basePath}/${id}${
                resourceOptions?.noEdit ? '/show' : ''
              }?goBack=${goBack}&resourceLabel=${resourceLabel}&previousResourcePath=${previousResourcePath}`
            : `${basePath}/${id}${resourceOptions?.noEdit ? '/show' : ''}`
        }
        saving={saving}
        submitOnEnter={submitOnEnter}
      />,
    );
  }

  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          {
            [classes.mobileToolbar]: width === 'xs',
            [classes.desktopToolbar]: width !== 'xs',
          },
          className,
        )}
        role="toolbar"
        //{...rest}
      >
        <div className={classes.defaultToolbar}>{children || defaultToolbarButtons}</div>
      </MuiToolbar>
      <div className={classes.spacer} />
    </Fragment>
  );
};

export interface AppBottomToolbarProps<RecordType extends RaRecord = RaRecord> {
  children?: ReactNode;
  alwaysEnableSaveButton?: boolean;
  className?: string;
  classes?: ClassesOverride<typeof useStyles>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  handleSubmit?: FormRenderProps['handleSubmit'];
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  redirect?: RedirectionSideEffect;
  basePath?: string;
  record?: RecordType;
  resource?: string;
  undoable?: boolean;
  width?: string;
  mode?: 'create' | 'edit' | 'show';
  resourceOptions?: Record<string, any>;
}

AppBottomToolbar.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  width: PropTypes.string,
  resourceOptions: PropTypes.object,
};

AppBottomToolbar.defaultProps = {
  submitOnEnter: true,
};

export default withWidth({ initialWidth: 'xs' })(AppBottomToolbar);
