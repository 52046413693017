import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { FC } from 'react';

const AppMessageDialog: FC<AppMessageDialogProps> = (props) => {
  const { open, title, content, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppMessageDialog;

export interface AppMessageDialogProps {
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
}
