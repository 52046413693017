import CourtIcon from '@material-ui/icons/Gavel';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import CourtDetails from './CourtDetails';
import CourtList from './CourtList';

export const COURT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Court: ResourceExport = {
  list: CourtList,
  icon: CourtIcon,
  edit: resourceWrapper('edit', CourtDetails, undefined, undefined, COURT_PERMISSIONS),
};
export default Court;
