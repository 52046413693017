import { AdvertisementOutcomeType } from './enums';

export const ADVERTISEMENT_OUTCOME_TYPES = [
  { id: AdvertisementOutcomeType.Sold, name: 'Venduto' },
  { id: AdvertisementOutcomeType.PartiallySold, name: 'Venduto parzialmente' },
  { id: AdvertisementOutcomeType.Deserted, name: 'Vendita deserta' },
  { id: AdvertisementOutcomeType.Settled, name: 'Vendita annullata' },
  { id: AdvertisementOutcomeType.Postponed, name: 'Vendita sospesa' },
];

export const ACTION_CHOICES = {
  settled: [
    { id: 'assegnazione', name: 'Istanza di assegnazione' },
    { id: 'estinzione', name: 'Per estinzione procedura' },
    { id: 'altro', name: 'Altro' },
  ],
  postponed: [
    { id: 'problemi-tecnici', name: 'Problemi tecnici' },
    { id: 'no-delegato', name: 'Delegato non presente' },
    { id: 'rinvia-autor-giudiz', name: 'Rinviata ad Autorità Giudiziaria' },
    { id: 'altro', name: 'Altro' },
  ],
  deserted: [{ id: 'deserta', name: 'Asta deserta per mancanza di offerte' }],
};
