import { Typography } from '@material-ui/core';
import { get as _get } from 'lodash';
import { FC } from 'react';
import { TextFieldProps } from 'react-admin';

interface AppUrlFieldProps extends TextFieldProps {
  source?: string;
  text?: string;
  url?: string;
  target?: string;
}

export const AppUrlField: FC<AppUrlFieldProps> = ({
  record = {},
  source = '',
  url,
  text,
  target = '_blank',
  variant = 'body2',
  component = 'span',
  ...rest
}) => {
  url = url ?? _get(record, source) ?? '#';

  return (
    <Typography component={component} variant={variant} {...rest}>
      <a href={url} target={target}>
        {text ?? url}
      </a>
    </Typography>
  );
};

export default AppUrlField;
