import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BooleanField, ListProps, TextField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import { ROLE_PERMISSIONS } from './index';

const useStyles = makeStyles(
  {
    full: {
      width: '100%',
    },
  },
  {
    name: 'RolesList',
  },
);

const RolesList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title='Ruoli' />}
      bulkActionButtons={false}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      filters={<AppListFilter helperText='Descrizione' />}
      sort={{ field: 'description', order: 'ASC' }}
    >
      <AppDatagrid>
        <TextField
          source='description'
          label='Descrizione'
          headerClassName={classes.full}
          sortable={false}
        />
        <BooleanField source='judiciary' label='Giudiziario' sortable={false} />
        <BooleanField source='folder' label='Fascicolo' sortable={false} />
        <BooleanField source='item' label='Lotto' sortable={false} />
        <BooleanField source='naturalPerson' label='Persona' sortable={false} />
        <BooleanField source='juridicalPerson' label='Azienda' sortable={false} />
        <AppShowButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={ROLE_PERMISSIONS}
        />
        <AppEditButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={ROLE_PERMISSIONS}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default RolesList;
