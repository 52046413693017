import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { Record as RaRecord } from 'react-admin';
import AppButtonGroup from '../../../../../Components/ui/button/AppButtonGroup';
import { SaleMode } from '../../../../../utils/constants';
import MailToOwnerButton from './mail-to-owner-button';
import ReservePriceActions from './ReservePrice/reserve-price-actions';
import PersonIcon from '@material-ui/icons/Person';

interface OwnerActionsProps {
  record: RaRecord;
  mode: string;
  reservePriceInfo: Record<string, any>;
}

const useStyles = makeStyles(
  (theme) => ({
    buttonGroup: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'OwnerActions' },
);

const OwnerActions: FC<OwnerActionsProps> = (props) => {
  const { reservePriceInfo, record: saleExperiment } = props;
  const classes = useStyles();

  const [actor, setActor] = useState<'owner' | 'bestBidder' | null>(null);

  useEffect(() => {
    if (saleExperiment.fkSaleMode !== SaleMode.SyncMixed) return;

    setActor(
      reservePriceInfo.waitingForOwner
        ? 'owner'
        : reservePriceInfo.waitingForWinner
        ? 'bestBidder'
        : null,
    );
  }, [reservePriceInfo]);

  return (
    <AppButtonGroup
      className={classes.buttonGroup}
      title="Azioni proprietario"
      disabled={props.mode !== 'edit'}
      icon={<PersonIcon />}
    >
      <MailToOwnerButton record={saleExperiment} />
      {actor && [
        <ReservePriceActions record={saleExperiment} actor={actor} actionType={'accept'} />,
        <ReservePriceActions record={saleExperiment} actor={actor} actionType={'reject'} />,
      ]}
    </AppButtonGroup>
  );
};

export default OwnerActions;
