import { FC } from 'react';
import { ListProps } from 'react-admin';
import { InfoRequestPaymentLegend } from '../../Components/info-request-payment/info-request-payment-legend';
import AppList from '../../Components/ui/detail/AppList';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { SERVICE_PAYMENT_COLORS } from '../../utils/constants';
import InfoRequestPaymentDatagrid from './InfoRequestPaymentDatagrid';
import { InfoRequestPaymentExpand } from './InfoRequestPaymentExpand';

const InfoRequestPaymentListFilters = (
  <AppListFilter helperText="Anagrafica, codici del lotto, codici della procedura"></AppListFilter>
);

const InfoRequestPaymentList: FC<ListProps> = (props) => {
  return (
    <AppList
      {...props}
      title={<AppTitle title="Pagamenti servizi" />}
      hideCreateButton
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      filters={InfoRequestPaymentListFilters}
    >
      <InfoRequestPaymentDatagrid
        expand={<InfoRequestPaymentExpand />}
        rowStyle={(record) => ({
          backgroundColor: record.paymentMethod
            ? SERVICE_PAYMENT_COLORS.paid
            : SERVICE_PAYMENT_COLORS.unpaid,
        })}
        additionalInfo={<InfoRequestPaymentLegend />}
      />
    </AppList>
  );
};

export default InfoRequestPaymentList;
