import React, { FC, useContext } from 'react';
import { UrlField } from 'react-admin';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';

export const AppDownloadField: FC<any> = React.memo((props) => {
  const { resource, record, ...rest } = props;
  const { id } = record;

  const ctx = useContext(AppRelatedResourceContext);

  return (
    <UrlField
      href={`${process.env.REACT_APP_BASE_URL}/${ctx ? ctx.resource : resource}/download/${id}`}
      target="_blank"
      record={record}
      {...rest}
    />
  );
});

export default AppDownloadField;
