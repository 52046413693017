import ConfigurationIcon from '@material-ui/icons/SettingsApplications';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourceExport } from '../../../types/resource-export.type';
import ConfigurationDetails from './ConfigurationDetails';
import ConfigurationList from './ConfigurationList';

const Configuration: ResourceExport = {
  list: ConfigurationList,
  create: resourceWrapper('create', ConfigurationDetails, undefined, undefined, undefined),
  edit: resourceWrapper('edit', ConfigurationDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', ConfigurationDetails, undefined, undefined, undefined),
  icon: ConfigurationIcon,
};
export default Configuration;
