import { FunctionComponent } from 'react';
import { FunctionField, ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppNumberInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import { UPDATE_STATUS_CHOICES, ENTITIES_CHOICES, PUBLICATION_COLORS } from './constants';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppDateField, AppLinkToEntityField, AppTextField } from '../../Components/ui/field';
import { makeStyles } from '@material-ui/core';
import ExternalPropertiesUpdateListLegend from './ExternalPropertiesListLegend';

const useStyles = makeStyles(
  {
    entity: {
      width: '20%',
    },
    entityId: {
      width: '20%',
    },
    publicationId: {
      width: '15%',
    },
    processedAt: {
      width: '20%',
    },
    updateStatus: {
      width: '15%',
    },
    lastCol: {
      width: '100%',
    },
  },
  {
    name: 'ExternalPropertiesUpdatesList',
  },
);

const ExternalPropertiesUpdatesListFilters = (
  <AppListFilter disableFulltextSearch>
    <AppNumberInput source="fkOpLog" label="Id entità" />
    <AppNumberInput source="fkPublication" label="Id pubblicazione" />
    <AppSelectInput choices={ENTITIES_CHOICES} source="entity" label="Entità" />
    <AppSelectInput
      choices={UPDATE_STATUS_CHOICES}
      source="updateStatus"
      label="Stato aggiornamento"
    />
  </AppListFilter>
);

const ExternalPropertiesUpdatesList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Pubblicazioni" />}
      filters={ExternalPropertiesUpdatesListFilters}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
    >
      <AppDatagrid
        additionalInfo={<ExternalPropertiesUpdateListLegend />}
        rowStyle={(record) => ({
          backgroundColor: PUBLICATION_COLORS[record.updateStatus][0],
        })}
      >
        <AppTextField source={'fkOpLog'} label="Id entità" headerClassName={classes.entityId} />
        <FunctionField
          render={(r) => {
            return (
              <AppTextField
                customText={ENTITIES_CHOICES.find((c) => c.id === r?.opLog?.entity)?.name ?? '-'}
              />
            );
          }}
          label="Entità"
          headerClassName={classes.entity}
        />
        <AppTextField
          source={'fkPublication'}
          label="Id pubblicazione"
          headerClassName={classes.publicationId}
        />
        <FunctionField
          render={(r) => (
            <AppTextField
              customText={UPDATE_STATUS_CHOICES.find((c) => c.id === r?.updateStatus)?.name ?? '-'}
            />
          )}
          label="Stato aggiornamento"
          headerClassName={classes.updateStatus}
        />
        <AppDateField
          source={'processedAt'}
          label="Data"
          headerClassName={classes.processedAt}
          showTime
        />
        <AppLinkToEntityField
          label="Lotto"
          targetResource="items"
          originName=""
          value="Link lotto"
          targetResourceId={(r) => r?.publication?.fkItem}
          truncate
          headerClassName={classes.lastCol}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default ExternalPropertiesUpdatesList;
