import { concat as _concat } from 'lodash';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { addDays, calculateDaysDifference, getRefFieldSource } from '../../../utils/calculators';
import { AppCalculatorDaysInput } from './AppCalculatorInputs';
import { AppDateInput, AppDateInputProps, AppDateTimeInput, AppSelectInput } from './index';

interface AppDateCalculatorProps extends AppDateInputProps {
  refToSource: string;
  refToChoices?: Record<string, any>[];
  hasTime: boolean;
}

const BASE_CHOICE = { id: 'no-ref', name: 'Inserimento manuale data' };

const AppDateCalculator: FC<AppDateCalculatorProps> = (props) => {
  const {
    source: originalFieldSource,
    label,
    refToSource, // field of reference select source
    refToChoices = [],
    required = false,
    variant = 'outlined',
    record,
    mode,
    hasTime = false,
    validate,
    disabled,
  } = props;
  const fieldDisabled = disabled ?? mode === 'show';

  const { values } = useFormState();
  const { change } = useForm();

  const [days, setDays] = useState(() => {
    if (mode === 'create') {
      return 0;
    }

    return calculateDaysDifference(record[originalFieldSource], record[record[refToSource]]);
  });

  const DateComponent = useMemo(() => (hasTime ? AppDateTimeInput : AppDateInput), [hasTime]);

  /// region INPUT FIELDS CHANGES

  // if referenced input changes (e.g. auctionStartAt)
  useEffect(() => {
    if (
      values[refToSource] === 'no-ref' ||
      !values[values[refToSource]] ||
      new Date(values[originalFieldSource]).getTime() ===
        addDays(values[values[refToSource]], days)?.getTime()
    ) {
      return;
    }

    change(originalFieldSource, addDays(values[values[refToSource]], days));
  }, [values[getRefFieldSource(values, refToSource)]]);

  const onDaysNumberChange = (newDaysNumber: number) => {
    if (isNaN(newDaysNumber)) {
      return setDays(0);
    }

    setDays(newDaysNumber);

    change(
      originalFieldSource,
      addDays(refToChoices.length == 0 ? new Date() : values[values[refToSource]], +newDaysNumber),
    );
  };

  // e.g. from "Inserimento manuale data" to "Inizio asta"
  const onRefSourceChange = (newSource: string) => {
    if (newSource !== 'no-ref') {
      change(originalFieldSource, addDays(values[values[refToSource]], days));
    }
  };

  const onOriginalFieldChange = (newValue: string) => {
    if (values[refToSource] === 'no-ref') return;

    const daysDifference = calculateDaysDifference(newValue, values[values[refToSource]]);

    setDays(daysDifference);
  };

  /// endregion

  return (
    <Fragment>
      <AppCalculatorDaysInput
        value={days}
        variant={variant}
        onChange={(e) => onDaysNumberChange(+e.target.value)}
        disabled={fieldDisabled || values[refToSource] === 'no-ref'}
      />
      <AppSelectInput
        source={refToSource}
        label="Data di riferimento"
        choices={
          !refToChoices || refToChoices.length === 0
            ? _concat<Record<string, any>>(refToChoices, BASE_CHOICE, {
                id: 'today',
                name: 'Data odierna',
              })
            : _concat<Record<string, any>>(refToChoices, BASE_CHOICE)
        }
        md={4}
        variant={variant}
        initialValue={values[refToSource] ?? 'no-ref'}
        onChange={(e) => onRefSourceChange(e.target.value)}
        required={required}
        disabled={fieldDisabled}
      />
      <DateComponent
        label={label}
        source={originalFieldSource}
        md={4}
        required={required}
        variant={variant}
        onChange={(e) => onOriginalFieldChange(e.target.value)}
        disabled={fieldDisabled}
        validate={validate}
      />
    </Fragment>
  );
};

export default AppDateCalculator;
