import { makeStyles } from '@material-ui/core/styles';
import { Record as RaRecord } from 'ra-core';
import { FC, Fragment } from 'react';
import AppAlert from '../ui/alert/AppAlert';

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'PublicationDisclaimer' },
);

interface PublicationDisclaimerProps {
  record: RaRecord;
  listCtx?: Record<string, any>;
}

export const PublicationDisclaimer: FC<PublicationDisclaimerProps> = ({
  record: item,
  listCtx: publicationsListCtx,
}) => {
  const classes = useStyles();

  if (!item || !publicationsListCtx) return <Fragment />;

  const { relatedSaleExperiments } = item;
  const { data: relatedPublications } = publicationsListCtx;

  return (
    <Fragment>
      {!relatedSaleExperiments ||
        (!relatedSaleExperiments.find((saleExp) => saleExp.isCurrent) && (
          <AppAlert title="Attenzione" severity="warning" className={classes.alert}>
            {`Il lotto non ha ${
              !relatedSaleExperiments?.length
                ? 'esperimenti di vendita associati'
                : 'esperimenti di vendita correnti'
            }, le pubblicazioni non possono essere effettuate.`}
          </AppAlert>
        ))}
      {item.handleSale && !Object.values(relatedPublications as RaRecord[]).find((p) => p.isSale) && (
        <AppAlert title="Lotto in vendita" severity="error" className={classes.alert}>
          Il lotto è in vendita ma nessuna pubblicazione è indicata come gestore della vendita
        </AppAlert>
      )}
    </Fragment>
  );
};
