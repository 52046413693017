import { TextFieldProps } from '@material-ui/core';
import { FC, ReactElement } from 'react';
import { ChoicesInputProps, SelectInput, Validator } from 'react-admin';
import { getValidators } from '../../../utils/validators';
import { AppGridProps } from '../types';
import { gridWrappedInput, inputProps } from '../utils';

export const AppSelectInput: FC<AppSelectInputProps> = (
  props: AppSelectInputProps,
): ReactElement => {
  const { required, validate: customValidators, ...rest } = props;
  return gridWrappedInput(
    { ...rest, variant: 'outlined' },
    <SelectInput
      validate={getValidators(customValidators as Validator[], required)}
      {...inputProps({ ...rest })}
    />,
  );
};

type SelectInputProps = ChoicesInputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

export type AppSelectInputProps = SelectInputProps & AppGridProps;
