import React, { FC, ReactElement } from 'react';
import { BooleanInput } from 'react-admin';
import { AppGridProps } from '../types';
import { InputProps } from 'ra-core'; //BooleanInput.d.ts doesn't export the BooleanInputProps type (like other inputs), so I use the general input props
import { gridWrappedInput, inputProps } from '../utils';
import _get from 'lodash/get';

export const AppBooleanInput: FC<AppBooleanInputProps> = (
  props: AppBooleanInputProps,
): ReactElement =>
  gridWrappedInput(
    props,
    <BooleanInput
      initialValue={_get(props.record ?? {}, props.source, false)}
      {...inputProps(props)}
    />,
  );

export type AppBooleanInputProps = InputProps & AppGridProps;
