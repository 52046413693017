import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import { ItemGenre } from '../../../utils/constants';
import { AppText } from '../../ui/text';
import ItemInfoField from './item-info-field';
import { omitBy as _omitBy, isNull as _isNull } from 'lodash';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
  }),
  { name: 'ChatbotImportListExpanded' },
);

interface ItemInfoProps {
  relatedItem: Record<string, any>;
}

const ItemInfo: FC<ItemInfoProps> = ({ relatedItem }) => {
  if (!relatedItem) return <Fragment />;
  relatedItem = _omitBy(relatedItem, _isNull); // sanitize from nulls

  const classes = useStyles();

  const location = relatedItem.locationCity
    ? `${relatedItem.locationCity} (${relatedItem.zipCode}), ${relatedItem.locationProvince} ${relatedItem.locationRegion} ${relatedItem.locationCountry}`
    : '-';

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item md={12}>
        <AppText variant="h5">Dati del lotto importato</AppText>
      </Grid>
      <ItemInfoField label="Titolo" record={relatedItem} field="title" md={12} />

      <ItemInfoField label="Indirizzo" record={relatedItem} field="address" md={2} />
      <ItemInfoField label="Città" customValue={location} md={4} />
      <ItemInfoField label="Valore commerciale" record={relatedItem} field="value" md={2} />
      {relatedItem.fkGenre === ItemGenre.RealEstate ? (
        <ItemInfoField label="Valore di perizia" record={relatedItem} field="evaluation" md={2} />
      ) : (
        <ItemInfoField placeholder md={2} />
      )}
      <ItemInfoField placeholder md={2} />
      <ItemInfoField label="Codice lotto" record={relatedItem} field="code" md={2} />
      <ItemInfoField label="Codice IVG" record={relatedItem} field="ivgCode" md={2} />
      <ItemInfoField label="Tipologia" record={relatedItem} field="fullTextTypology" />
    </Grid>
  );
};

export default ItemInfo;
