import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SaveTwoTone, SentimentVeryDissatisfiedTwoTone, LockTwoTone } from '@material-ui/icons';
import React, { cloneElement, FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.secondary.light,
    },
    icon: { width: '100px', height: '100px' },
  }),
  { name: 'AppEmpty' },
);

const AppEmpty: FC<AppEmptyProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <p>{cloneElement(props.icon, { className: classes.icon })}</p>
      <p>
        <Typography variant="h4">{props.message}</Typography>
      </p>
    </div>
  );
};

export default AppEmpty;

interface AppEmptyNoItemsProps {
  message?: string;
  icon?: any;
}

export const AppEmptyNoItems: FC<AppEmptyNoItemsProps> = (props) => (
  <AppEmpty
    message={props.message ?? 'Nessun elemento presente.'}
    icon={props.icon ?? <SentimentVeryDissatisfiedTwoTone />}
  />
);

export const AppEmptySaveRequired: FC = () => (
  <AppEmpty
    message="Per poter accedere a questa sezione devi prima salvare."
    icon={<SaveTwoTone />}
  />
);

export const AppEmptyPermissionDenied: FC = () => (
  <AppEmpty
    message="Non hai l'autorizzazione per visualizzare questa informazione"
    icon={<LockTwoTone />}
  />
);

export interface AppEmptyProps {
  message: string;
  icon: any;
}
