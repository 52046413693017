export const BOOKER_BASE_SOURCE = 'booker';
export const ITEM_BASE_SOURCE = 'item';
export const PERSON_BASE_SOURCE = 'person';

export const ITEM_GENRE_CHOICES = [
  { id: 'real-estate', name: 'Immobili' },
  { id: 'movable', name: 'Mobili' },
];

export const PERSON_TYPES = [
  { id: 'person', name: 'Persona fisica' },
  { id: 'company', name: 'Persona giuridica' },
];

export enum PersonType {
  Company = 'company',
  Person = 'person',
}

export const ENTITIES_CHOICES = [
  { id: '', name: 'Tutti' },
  { id: 'Item', name: 'Lotti' },
  { id: 'Folder', name: 'Fascicoli' },
  { id: 'RealEstate', name: 'Immobili' },
  { id: 'SaleExperiment', name: 'Esperimenti di vendita' },
  { id: 'Person', name: 'Persone' },
  { id: 'Media', name: 'Media' },
];

export const METHODS_CHOICES = [
  { id: '', name: 'Tutti' },
  { id: 'save', name: 'Save' },
  { id: 'update', name: 'Update' },
  { id: 'delete', name: 'Delete' },
];

// TODO: move to CMS constants.ts
export const MESSAGES_TYPE_CHOICES = [
  { id: 'danger', name: 'Importante' },
  { id: 'info', name: 'Informazione' },
  { id: 'warning', name: 'Attenzione' },
];

export const MESSAGES_PAGES_CHOICES = [
  { id: 'all', name: 'Tutte' },
  { id: 'homepage', name: 'Solo homepage' },
];

export const LINK_SECTIONS_CHOICES = [
  { id: 'header', name: 'Link al top' },
  { id: 'prefooterleft', name: 'Footer (sinistra)' },
  { id: 'prefootercenter', name: 'Footer (centro)' },
  { id: 'prefooterright', name: 'Footer (destra)' },
  { id: 'social', name: 'Area social' },
  { id: 'legalarea', name: 'Area legale' },
];

export const LINK_SECTIONS_AGENCIES_CHOICES = [
  { id: 'header', name: 'Link al top' },
  { id: 'prefooterright', name: 'Footer (destra)' },
];

export const LINK_TYPES_CHOICES = [
  { id: 'page', name: 'Pagina' },
  { id: 'url', name: 'URL' },
];
