import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'carsAndCycles';

const MeridaDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 5 || formData.fkTypology === 118) return <React.Fragment />;

  return (
    <AppCard title="Dettagli personalizzati" disabled={mode === 'show'}>
      {[116, 117].includes(formData.fkTypology) && (
        <>
          <AppTextInput
            source={`${BASE_SOURCE}.customCategory`}
            label="Categoria personalizzata"
            md={4}
          />
          <AppTextInput
            source={`${BASE_SOURCE}.customTypology`}
            label="Tipologia personalizzata"
            md={4}
          />
          <AppTextInput source={`${BASE_SOURCE}.chassisSize`} label="Misura telaio" md={4} />
          <AppTextInput source={`${BASE_SOURCE}.tireSize`} label="Misura pneumatico" md={4} />
          <AppTextInput source={`${BASE_SOURCE}.weightRange`} label="Fascia di peso" md={4} />
          <AppTextInput source={`${BASE_SOURCE}.mainColor`} label="Colore principale" md={4} />
          <AppTextInput source={`${BASE_SOURCE}.technology`} label="Tecnologia" md={4} />
        </>
      )}
      {formData.fkTypology === 116 && <></>}
      {formData.fkTypology === 117 && (
        <>
          <AppTextInput source={`${BASE_SOURCE}.engine`} label="Motore" md={4} />
          <AppTextInput
            source={`${BASE_SOURCE}.batteryCapacity`}
            label="Capacità della batteria"
            md={4}
          />
          <AppTextInput source={`${BASE_SOURCE}.decalColor`} label="Colore decalcomania" md={4} />
        </>
      )}
    </AppCard>
  );
};

export default MeridaDetails;
