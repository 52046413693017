import ServicePaymentIcon from '@material-ui/icons/Payment';
import { ResourceExport } from '../../types/resource-export.type';
import InfoRequestPaymentList from './InfoRequestPaymentList';

const ServicePayment: ResourceExport = {
  list: InfoRequestPaymentList,
  icon: ServicePaymentIcon,
};

export default ServicePayment;
