import { Grid, GridProps } from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import { dateFormatter } from '../../../utils/data-formatters';
import { DetailPanelField, DetailPanelTitle } from '../../detail-panel';
import AppAlert from '../../ui/alert/AppAlert';
import { AppBold, AppText } from '../../ui/text';
import RequestDetailsEventsList from './request-details-events-list';
import RequestDetailsItemsList from './request-details-items-list';
import RequestDetailsPublicationsList from './request-details-publications-list';

interface RequestDetailsProps extends GridProps {
  pvpRequest: Record<string, any>;
  showAsyncSalesAlert?: boolean;
}

const RequestDetails: FC<RequestDetailsProps> = ({ pvpRequest, showAsyncSalesAlert = true }) => {
  return (
    <Grid container spacing={3}>
      <DetailPanelTitle variant="h5" children="DETTAGLIO RICHIESTA" />
      <DetailPanelField label="Titolo" md={12} value={pvpRequest.title} />

      <DetailPanelTitle children="DATI DELLA PROCEDURA" />
      <DetailPanelField md={3} label="Tribunale" value={pvpRequest.court} />
      <DetailPanelField md={3} label="Anno procedura" value={pvpRequest.procedureYear} />
      <DetailPanelField md={3} label="Numero procedura" value={pvpRequest.procedureNumber} />
      <DetailPanelField md={3} label="Numero del lotto" value={pvpRequest.code} />
      <DetailPanelField label="Rito" value={pvpRequest.rite} />
      <DetailPanelField label="Registro" value={pvpRequest.register} />
      <DetailPanelField
        label="Data di pubblicazione sul PVP"
        value={dateFormatter(pvpRequest.publishedOnPvpAt, true)}
      />

      <DetailPanelTitle children="DATI DI VENDITA" />
      <DetailPanelField label="Modalità di vendita" value={pvpRequest.saleMode} />
      <DetailPanelField label="Tipologia di vendita" value={pvpRequest.saleType} />
      <DetailPanelField
        label="Data/ora inizio gara"
        value={dateFormatter(pvpRequest.auctionStartAt)}
      />
      <DetailPanelField
        label="Data/ora termine presentazione offerte"
        value={dateFormatter(pvpRequest.bidsEndAt)}
      />
      <DetailPanelField label="Prezzo base" value={pvpRequest.basePrice} md={4} />
      {pvpRequest.minPrice && (
        <DetailPanelField label="Prezzo minimo" value={pvpRequest.minPrice} md={4} />
      )}
      <DetailPanelField label="Rialzo minimo" value={pvpRequest.minRaise} md={4} />
      <DetailPanelField label="Indirizzo di vendita" value={pvpRequest.saleAddress} md={12} />

      {pvpRequest.saleMode && pvpRequest.saleMode.startsWith('ASINCRONA') && showAsyncSalesAlert && (
        <AppAlert severity="warning" title="Attenzione">
          <AppText>
            Per le gare con modalità di vendita <AppBold>ASINCRONA TELEMATICA</AppBold> è necessario
            assicurarsi di aver compilato la <AppBold>data/ora di fine vendita</AppBold> nel
            dettaglio dell'esperimento di vendita
          </AppText>
        </AppAlert>
      )}

      {/* beni's LIST */}
      <RequestDetailsItemsList items={pvpRequest.itemsList} />

      {pvpRequest.processed ? (
        <RequestDetailsPublicationsList publicationsList={pvpRequest.publicationsList} />
      ) : (
        <Fragment />
      )}

      {pvpRequest.eventsList && pvpRequest.eventsList.length ? (
        <RequestDetailsEventsList eventsList={pvpRequest.eventsList} />
      ) : (
        <Fragment />
      )}
    </Grid>
  );
};

export default RequestDetails;
