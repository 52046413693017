import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Record as RaRecord } from 'ra-core';
import React, { FC } from 'react';
import AuctionAlert from './AuctionAlert/auction-alert';
import { AuctionActive, AuctionAuthorization, AuctionCompleted } from './AuctionState';

const useStyles = makeStyles(
  (theme) => ({
    reservePriceAlert: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AuctionWorkflow' },
);

interface AuctionWorkflowProps {
  mode: string;
  bestOffer: Record<string, any>;
  raiseLimitTS: number;
  isPaused: boolean;
  isActive: boolean;
  completedAuction: Record<string, any> | null;
  reservePriceInfo: Record<string, any>;
  record: RaRecord;
  serverTimeDeviceTimeOffset: number;
}

const AuctionWorkflow: FC<AuctionWorkflowProps> = (props) => {
  // TODO (ASAP): to improve real-time feeling and functionality we should use queue's status
  //  instead of the status from the DB (and use DB's status only if queue's one is not available) !!
  const { bestOffer, completedAuction, record, mode } = props;
  const {
    id: saleExpId,
    auctionStatus,
    auctionStatusReason,
    auctionStatusReasonDetails,
    auctionPostponed,
    unsuccessfulAuction,
    reservePrice,
    relatedParticipations,
  } = record;
  const classes = useStyles();

  const updatedReservePrice =
    props.reservePriceInfo?.newReservePrice && props.reservePriceInfo?.newReservePrice > 0
      ? props.reservePriceInfo?.newReservePrice
      : reservePrice;
  if (!saleExpId)
    return <Typography color="error">ID esperimento di vendita non trovato.</Typography>;

  if (auctionPostponed) {
    return (
      <AuctionAlert
        severity="info"
        {...{
          auctionStatus: 'postponed',
          auctionStatusReason,
        }}
        detail={
          <React.Fragment>
            <span style={{ display: 'block' }}>{auctionStatusReasonDetails}</span>
            <span style={{ textDecoration: 'underline', fontStyle: 'italic' }}>
              Per riattivare l'esperimento di vendita devi aggiornare la data di vendita dell'asta.
            </span>
          </React.Fragment>
        }
      />
    );
  }

  if (auctionStatus === 'settled') {
    return (
      <AuctionAlert
        severity="warning"
        {...{
          auctionStatus,
          auctionStatusReason,
          auctionStatusReasonDetails,
        }}
      />
    );
  }

  if (auctionStatus === 'completed' || completedAuction?.status === 'completed') {
    return completedAuction?.unsuccessfulAuction || unsuccessfulAuction ? (
      <AuctionAlert
        severity="warning"
        {...{
          auctionStatus: 'deserted',
          auctionStatusReason: completedAuction?.reason ?? auctionStatusReason,
          auctionStatusReasonDetails: completedAuction?.reasonDetails ?? auctionStatusReasonDetails,
        }}
      />
    ) : (
      <AuctionCompleted
        saleExperiment={props.record}
        completedAuction={completedAuction}
        reservePriceInfo={props.reservePriceInfo}
        updatedReservePrice={updatedReservePrice}
      />
    );
  }

  return (
    <Grid container alignItems="center" justify="space-evenly">
      {process.env.REACT_APP_IS_IVG === 'false' &&
        bestOffer &&
        bestOffer.offer &&
        !props.reservePriceInfo?.removed &&
        reservePrice &&
        reservePrice > 0 && (
          <Grid item md={12} className={classes.reservePriceAlert}>
            {bestOffer.offer < updatedReservePrice ? (
              <Alert severity="error">Prezzo di riserva non ancora raggiunto.</Alert>
            ) : (
              <Alert severity="success">il prezzo di riserva è stato raggiunto!</Alert>
            )}
          </Grid>
        )}
      {auctionStatus === 'active' || props.isActive ? (
        <AuctionActive
          bestOffer={bestOffer}
          isPaused={props.isPaused}
          raiseLimitTS={props.raiseLimitTS}
          saleMode={record.fkSaleMode}
          serverTimeDeviceTimeOffset={props.serverTimeDeviceTimeOffset}
          reservePrice={reservePrice}
          updatedReservePrice={updatedReservePrice}
          reservePriceRemoved={props.reservePriceInfo?.removed ?? false}
        />
      ) : auctionStatus !== 'completed' ? (
        <AuctionAuthorization
          auctionStatus={auctionStatus}
          saleExperiment={record}
          mode={mode}
          multipleParticipations={relatedParticipations && relatedParticipations.length > 1}
        />
      ) : (
        <span />
      )}
    </Grid>
  );
};

export default AuctionWorkflow;
