import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppTextInput } from '../../../Components/ui/input';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'informaticsAndElectronics';

const InformaticsAndElectronicsDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 8) return <React.Fragment />;

  return (
    <AppCard title='Informazioni aggiuntive sul bene' disabled={mode === 'show'}>
      <AppTextInput source={`${BASE_SOURCE}.brand`} label='Marca' md={4} />
      <AppTextInput source={`${BASE_SOURCE}.model`} label='Modello' md={4} />
      <AppTextInput source={`${BASE_SOURCE}.type`} label='Tipologia' md={4} />
    </AppCard>
  );
};

export default InformaticsAndElectronicsDetails;
