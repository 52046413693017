import React, { FC, useContext, useState } from 'react';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import AppFormDialog from '../../ui/dialog/form-dialog/form-dialog';
import AppShippingForm from './app-shipping-form';
import { blobToBase64 } from '../../../utils/files';

const AppShippingMaganer: FC<any> = (props) => {
  const { title, resource, ...others } = props;

  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  /// endregion

  /// region CONTEXT

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  const handleFilesAndSave = async (record: any, close: any) => {
    if (!record.mediaFile?.base64) {
      const result = await blobToBase64(record.mediaFile.mediaFile);
      record.mediaFile = {
        base64: result,
        filename: record.mediaFile.filename,
        type: record.mediaFile.rawFile.type,
      };
    }

    // Send to API server
    ctx.manager.submit(record, close);
  };

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        // TODO: HANDLE FILE SAVE
        // handleFilesAndSave(data, close);
        ctx.manager.submit(data, close);
      }}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={120}
      height={60}
      {...others}
    >
      <AppShippingForm
        resource={resource}
        handleClickOpen={handleClickOpen}
        setOpen={setOpen}
        ctx={ctx}
        open={open}
      />
    </AppFormDialog>
  );
};

export default AppShippingMaganer;
