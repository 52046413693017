import { blue, green, grey, orange, red, yellow } from '@material-ui/core/colors';

// TODO: move to smaller dedicated files in each Resource (e.g. folder constants and enums in Folder resource directory)

/// region GENERICS

export const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const WEEK_DAYS = [
  { id: 0, name: 'Lunedì' },
  { id: 1, name: 'Martedì' },
  { id: 2, name: 'Mercoledì' },
  { id: 3, name: 'Giovedì' },
  { id: 4, name: 'Venerdì' },
  { id: 5, name: 'Sabato' },
  { id: 6, name: 'Domenica' },
];

/// endregion

/// region PEOPLE/PARTICIPANTS

export const GENDER_CHOICES = [
  { id: 'm', name: 'Maschio' },
  { id: 'f', name: 'Femmina' },
];

export const PARTICIPANT_TYPES = [
  { id: 'person', name: 'Persona fisica' },
  { id: 'company', name: 'Rappresentante azienda' },
];

/// endregion

/// region FOLDER

export const FOLDER_STATUSES = [
  { id: 'active', name: 'Attivo' },
  { id: 'inactive', name: 'Non attivo' },
  { id: 'archived', name: 'Archiviato' },
];
export const FOLDER_TYPES = [
  { id: 'judiciary', name: 'Giudiziario' },
  { id: 'private', name: 'Privato' },
  { id: 'utp', name: 'UTP' },
  { id: 'npl', name: 'NPL' },
];

export const FOLDER_ASSIGNMENTS = [
  { id: 'custody', name: 'Custodia' },
  { id: 'judiciary-administration', name: 'Amministrazione giudiziaria' },
  { id: 'advertisement', name: 'Pubblicità / Vivacizzazione' },
  { id: 'sale', name: 'Vendita (Gestore della vendita)' },
  { id: 'specialized-subject', name: 'Soggetto specializzato (Art.107 LF)' },
];

export const CAPABILITY_SAVED_AS = [
  { id: undefined, name: 'Standard' },
  { id: 'mandatory', name: 'Obbligatorio' },
  { id: 'suggested', name: 'Suggerito' },
];

/// endregion

/// region SALE EXPERIMENT

export const OBSERVERS_OPTIONS = [
  { id: 'open-auction', name: 'Vendita visibile al pubblico' },
  { id: 'authorized-only', name: 'Solo osservatori autorizzati e partecipanti' },
  { id: 'participants-only', name: 'Solo partecipanti' },
];

export const SECURITY_DEPOSIT_TYPES = [
  { id: 'bank-account', name: 'Conto corrente bancario' },
  { id: 'cashiers-check', name: 'Assegno circolare' },
  { id: 'bank-book', name: 'Libretto bancario' },
];

export const AUCTION_STATUS: Record<string, any>[] = [
  { id: 'inactive', name: 'Inattiva' },
  { id: 'authorization', name: 'Apertura buste in corso' },
  { id: 'ready', name: 'Pronta per iniziare' },
  { id: 'active', name: 'In corso' },
  { id: 'completed', name: 'Terminata' },
  { id: 'settled', name: 'Annullata' },
];

export enum SaleMode { // names are the same on jugaad-api's enum
  AtSeller = 1, // PRESSO IL VENDITORE
  SyncOnline = 2, // SINCRONA PURA
  SyncMixed = 3, // SINCRONA MISTA
  AsyncOnline = 4, // ASINCRONA
  ExpressionOfInterest = 5, // MANIFESTAZIONE DI INTERESSE
  OffersCollection = 6, // RACCOLTA OFFERTE
  BuyItNow = 7, // COMPRALO SUBITO
  Retail = 8, // VENDITA AL DETTAGLIO
  WithAuction = 9, // CON INCANTO
}

export enum SaleType {
  WithoutAuction = 1, // SENZA incanto
  WithAuction = 2, // CON incanto
  WithoutAuctionPlus5 = 3, // SENZA INCANTO - SEGUITO AUMENTO DI UN QUINTO
  Competitive = 4, // COMPETITIVA
  ByAgent = 5, // TRAMITE COMMISSIONARIO
}

export enum ReservePriceActionsEnum {
  acceptBestOffer = 'accept-best-offer',
  rejectBestOffer = 'reject-best-offer',
  // -------
  equalsReservePrice = 'equals-reserve-price',
  renounce = 'renounce',
}

/// region SALE EXP LOGS

export const LOG_TYPES = [
  { id: null, name: '-' },
  { id: 'chat', name: 'Chat' },
  { id: 'offer', name: 'Offerte iniziali' },
  { id: 'bid', name: 'Rilanci' },
  { id: 'system', name: 'Sistema' },
];

export const ACTOR_TYPES = [
  { id: null, name: '-' },
  { id: 'system', name: 'Sistema' },
  { id: 'participant', name: 'Partecipante' },
  { id: 'observer', name: 'Osservatore' },
  { id: 'auctioneer', name: 'Delegato' },
  { id: 'adminUser', name: 'Operatore' },
];

/// endregion

/// endregion

/// region PARTICIPATION

export enum OriginType {
  FrontEnd = 'front-end', // from FE properties
  Pvp = 'pvp', // from PVP
  Paper = 'paper', // from Admin
}

export const ORIGIN_TYPES = [
  { id: 'paper', name: 'CARTACEO' },
  { id: 'front-end', name: 'SITO' },
  { id: 'pvp', name: 'PVP' },
];

/// region BIDDER

export const BIDDER_RIGHTS = [
  { id: 'property', name: 'Proprietà' },
  { id: 'bare-ownership', name: 'Nuda proprietà' },
  { id: 'long-leasehold-property', name: 'Proprietà superficiaria' },
  { id: 'usufruct', name: 'Usufrutto' },
  { id: 'long-leasehold-property-usufruct', name: 'Usufrutto su proprietà superficiaria' },
];

export const BIDDER_TITLES = [
  { id: 'personal', name: 'A titolo personale' },
  { id: 'legal-representer', name: 'In qualità di rappresentante legale' },
  { id: 'for-person-to-be-nominated', name: 'Per persona da nominare (solo avvocati)' },
  { id: 'prosecutor', name: 'Procuratore' },
  { id: 'legal-guardian', name: 'Tutore legale' },
  { id: 'other', name: 'Altro' },
];

export const BIDDER_RECEIVING_METHODS = [
  { id: 'pec', name: 'Tramite email PEC' },
  { id: 'receiver-unique-id', name: 'Codice univoco destinatario' },
  { id: 'paper-offer-deposit', name: 'Deposito offerta cartacea' },
];

///  endregion

/// endregion

/// region CODE

export const CODES_TYPES = [
  { id: 1, name: 'Codice identificativo del cliente' },
  { id: 2, name: 'Codice identificativo agenzia' },
  { id: 3, name: 'Altro - Personalizzato' },
];

/// endregion

/// region ITEM

export enum SaleStatus {
  Opened = 1, // APERTO
  ClosedAndAwarded = 2, // CHIUSO E AGGIUDICATO
  ClosedAndNotAwarded = 3, // CHIUSO E NON AGGIUDICATO
  Updating = 4, // IN AGGIORNAMENTO
}

export enum ItemGenre {
  RealEstate = 1,
  Movable = 2,
  CreditsOrValuables = 3,
  Companies = 4,
  Other = 5,
}

// TODO: category enum

/// region REAL ESTATE

export const HEATING_TYPE: Record<any, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'underfloor', name: 'A pavimento' },
  { id: 'electric', name: 'A elettricità' },
  { id: 'boiler', name: 'A caldaia' },
  { id: 'ceiling', name: 'A soffitto' },
  { id: 'wall', name: 'A parete' },
  { id: 'heat-pump', name: 'A pompa di calore' },
  { id: 'solar-panel', name: 'Tramite pannello solare termico' },
];

export const ENERGY_CLASS: Record<any, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'A4', name: 'A4 (APE)' },
  { id: 'A3', name: 'A3 (APE)' },
  { id: 'A2', name: 'A2 (APE)' },
  { id: 'A1', name: 'A1 (APE)' },
  { id: 'A+', name: 'A+ (IPE)' },
  { id: 'A', name: 'A (IPE)' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'F', name: 'F' },
  { id: 'G', name: 'G' },
  { id: 'NC', name: 'NC' },
  { id: 'Esente', name: 'Esente' },
];

export const OCCUPATION_STATUS: Record<string, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'occupied-by-the-debtor', name: 'Occupato dal debitore' },
  { id: 'occupied-by-third-parties-with-title', name: 'Occupato da terzi con titolo opponibile' },
  {
    id: 'occupied-by-third-parties-without-title',
    name: 'Occupato da terzi senza titolo opponibile',
  },
  { id: 'free', name: 'Libero' },
];

export const CONDO_FEES_TYPES: Record<string, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'annual', name: 'Annuali' },
  { id: 'monthly', name: 'Mensili' },
];

/// endregion

/// region CLOTHING AND FOOTWEAR

export const CLOTHING_AND_FOOTWEAR_TARGETS: Record<string, any>[] = [
  { id: 'man', name: 'Uomo' },
  { id: 'woman', name: 'Donna' },
  { id: 'baby', name: 'Bambino' },
];

/// endregion

/// region CARS AND CYCLES

export const FUEL_TYPES: Record<string, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'petrol', name: 'Benzina' },
  { id: 'petrol-lpg', name: 'Benzina + GPL' },
  { id: 'petrol-methane', name: 'Benzina + Metano' },
  { id: 'diesel', name: 'Diesel' },
  { id: 'methane', name: 'Metano' },
  { id: 'lpg', name: 'GPL' },
  { id: 'electric', name: 'Elettrica' },
  { id: 'hybrid', name: 'Ibrida' },
];

export const ENV_CLASS_TYPES: Record<string, any>[] = [
  { id: null, name: '\xa0' },
  { id: 'Euro 0', name: 'Euro 0' },
  { id: 'Euro 1', name: 'Euro 1' },
  { id: 'Euro 2', name: 'Euro 2' },
  { id: 'Euro 3', name: 'Euro 3' },
  { id: 'Euro 4', name: 'Euro 4' },
  { id: 'Euro 5', name: 'Euro 5' },
  { id: 'Euro 6', name: 'Euro 6' },
];

/// endregion

export const OWNERSHIP_TYPES = [
  { id: 'full', name: 'Piena proprietà' },
  { id: 'shared', name: 'Quota di proprietà' },
  { id: 'bare', name: 'Nuda proprietà' },
];

/// region MEDIA

export const MEDIA_GENRES = [
  { id: 'image', name: 'Immagine' },
  { id: 'document', name: 'Documento' },
  { id: 'virtual-tour', name: 'Virtual Tour' },
  { id: 'video', name: 'Video' },
];

export enum MediaOrigin {
  Cdn = 'Sirv',
  Bucket = 'Bucket di Google',
}

/// endregion

/// endregion

/// region PERSON ROLES / PERMISSIONS / AGENCY

export enum PersonRoles {
  // SYSTEM
  Undefined = 1, // Required to prevent error while import esperimentoDiVendita
  SuperAdmin = 2,
  Administrator = 3,
  Specialist = 4,
  // GENERIC
  Judge = 5, // Giudice
  Curator = 6, // Curatore
  Caretaker = 7, // Custode
  TechnicalAdviser = 8, // CTU
  DominusLawyer = 9, // Avvocato dominus
  ProsecutingLawyer = 10, // Avvocato della procedura
  SentencedLawyer = 11, // Avvocato dell'esecutato
  Sentenced = 12, // Esecutato
  DomiciliaryLawyer = 13, // Avvocato procedente domiciliatario
  //DELETED DomiciliarySentencedLawyer = 14, // Avvocato dell’esecutato domiciliatario
  Bailiff = 15, // Ufficiale Giudiziario
  //DELETED Advertiser = 16, // Incaricato della pubblicità
  Auctioneer = 17, // Delegato alla Vendita
  // PVP ONLY
  SpecialCommissioner = 18, // Commissario straordinario
  JudicialCommissioner = 19, // Commissario giudiziario
  JudicialAdjuster = 20, // Liquidatore giudiziale
  Adjuster = 21, // Liquidatore
  Commissioner = 22, // Commissario
  DelegatedExpert = 23, // Professionista Delegato
  OfficialReceiver = 24, // Curatore fallimentare
  IVG = 25, // Istituto Vendite Giudiziarie
  Agent = 26, // Commissionario
  SupportAdministrator = 27, // Amministratore di sostegno
  Tutor = 28, // Tutore
  ClearanceSaleSupervisor = 29, // Gestore della liquidazione
  Foreclosurer = 30, // "Pignorante"
  AuthorizedForSale = 31, // Persona autorizzata alla vendita
  AdjusterCommissioner = 32, // Commissario liquidatore
  Other = 33, // Altro soggetto non definito
  SaleExpert = 34, //Soggetto specializzato alla vendita
  Contact = 35, // Referente procedura
  ProceedingCreditor = 36, // Creditore procedente
  Occupant = 37, // Occupante
  BuildingManager = 38, // Amministratore di condominio
  Owner = 39, // Proprietario
  OfficerInCharge = 40, // Agente incaricato
}

export const ROLES_INFO = [
  {
    id: PersonRoles.SuperAdmin,
    description: "Questo ruolo ti fornisce accesso a tutte le funzionalità dell'applicazione",
  },
  {
    id: PersonRoles.Auctioneer,
    description: "Questo ruolo ti fornisce la possibilità di gestire l'andamento delle aste",
  },
];

export enum ConfigurationAgencyGenre {
  RealEstate = 'real-estate',
  Movable = 'movable',
}

/// endregion

/// region MEDIA

export enum MediaSecurityType {
  Protected = 'protected',
  Public = 'public',
  Private = 'private',
}

/// endregion

/// region MAGAZINE

export const ITEM_GENRES = [
  { id: 'real-estate', name: 'Immobili' },
  { id: 'movable', name: 'Mobili' },
];
export const PAGE_ITEMS = [
  { id: undefined, name: '\xa0' },
  { id: 'auto', name: 'Automatico (3 oltre i 100 beni)' },
  { id: '2', name: '2 beni per pagina' },
  { id: '3', name: '3 beni per pagina' },
];
export const MAGAZINE_METHODS = [
  { id: 'generate', name: 'Genera' },
  { id: 'upload', name: 'Carica' },
];
export const MAGAZINE_KINDS = [
  { id: undefined, name: '\xa0' },
  { id: 'draft', name: 'Bozza' },
  { id: 'web', name: 'Web' },
  { id: 'print', name: 'Stampa' },
];
export const MAGAZINE_PLACEHOLDER_TYPES = [
  { id: undefined, name: '\xa0' },
  { id: 'full', name: 'Intero' },
  { id: 'partial', name: 'Parziale' },
  { id: 'both', name: 'Entrambi' },
];
export const MAGAZINE_PLACEHOLDER_SIDES = [
  { id: undefined, name: '\xa0' },
  { id: 'left', name: 'Sinistra' },
  { id: 'right', name: 'Destra' },
  { id: 'both', name: 'Entrambi' },
];
export const MAGAZINE_VERSIONS = [
  { id: undefined, name: '\xa0' },
  { id: 1, name: 'Copertina arancione' },
  { id: 2, name: 'Copertina azzurra' },
];

export const MAGAZINE_TEXT_ALIGNMENTS = [
  { id: undefined, name: '\xa0' },
  { id: 'center', name: 'Centro' },
  { id: 'left', name: 'Sinistra' },
  { id: 'right', name: 'Destra' },
];

/// endregion

/// region AUCTION ROOM

export const AUCTION_ROOM_SLOT_LENGTHS = [
  { id: undefined, name: '\xa0' },
  { id: 'min05', name: '5 min' },
  { id: 'min10', name: '10 min' },
  { id: 'min15', name: '15 min' },
  { id: 'min20', name: '20 min' },
  { id: 'min25', name: '25 min' },
  { id: 'min30', name: '30 min' },
  { id: 'min35', name: '35 min' },
  { id: 'min40', name: '40 min' },
  { id: 'min45', name: '45 min' },
  { id: 'min50', name: '50 min' },
  { id: 'min55', name: '55 min' },
  { id: 'min60', name: '60 min' },
];

/// endregion

/// region AUCTION ROOM BOOKING

export const AUCTION_ROOM_BOOKING_STATUSES = [
  { id: undefined, name: '\xa0' },
  { id: 'sent', name: 'Inviata' },
  { id: 'confirmed', name: 'Confermata' },
  { id: 'rejected', name: 'Rifiutata' },
];

export const AUCTION_ROOM_BOOKING_CONFIRMED_BACKGROUND_COLOR = green[500];
export const AUCTION_ROOM_BOOKING_CONFIRMED_BORDER_COLOR = green[900];
export const AUCTION_ROOM_BOOKING_REJECTED_BACKGROUND_COLOR = red[700];
export const AUCTION_ROOM_BOOKING_REJECTED_BORDER_COLOR = red[900];
export const AUCTION_ROOM_BOOKING_SENT_BACKGROUND_COLOR = orange[500];
export const AUCTION_ROOM_BOOKING_SENT_BORDER_COLOR = orange[900];
export const AUCTION_ROOM_BOOKING_UNDEFINED_BACKGROUND_COLOR = grey[700];
export const AUCTION_ROOM_BOOKING_UNDEFINED_BORDER_COLOR = grey[900];
export const AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BACKGROUND_COLOR = blue[700];
export const AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BORDER_COLOR = blue[900];

export const AUCTION_ROOM_BOOKING_COLORS = {
  sent: [AUCTION_ROOM_BOOKING_SENT_BACKGROUND_COLOR, AUCTION_ROOM_BOOKING_SENT_BORDER_COLOR],
  rejected: [
    AUCTION_ROOM_BOOKING_REJECTED_BACKGROUND_COLOR,
    AUCTION_ROOM_BOOKING_REJECTED_BORDER_COLOR,
  ],
  confirmed: [
    AUCTION_ROOM_BOOKING_CONFIRMED_BACKGROUND_COLOR,
    AUCTION_ROOM_BOOKING_CONFIRMED_BORDER_COLOR,
  ],
};

export const INFO_REQUEST_PENDING_BACKGROUND_COLOR = grey[400];
export const INFO_REQUEST_PROCESSING_BACKGROUND_COLOR = yellow[600];
export const INFO_REQUEST_COMPLETED_BACKGROUND_COLOR = green[300];

export const INFO_REQUEST_COLORS = {
  pending: INFO_REQUEST_PENDING_BACKGROUND_COLOR,
  processing: INFO_REQUEST_PROCESSING_BACKGROUND_COLOR,
  completed: INFO_REQUEST_COMPLETED_BACKGROUND_COLOR,
};

export const SERVICE_PAYMENT_UNPAID_BACKGROUND_COLOR = grey[400];
export const SERVICE_PAYMENT_PAID_BACKGROUND_COLOR = green[300];

export const SERVICE_PAYMENT_COLORS = {
  unpaid: SERVICE_PAYMENT_UNPAID_BACKGROUND_COLOR,
  paid: SERVICE_PAYMENT_PAID_BACKGROUND_COLOR,
};

/// endregion

/// region PAYMENTS
export const PAYMENT_METHOD = [
  { id: 'credit-card', name: 'Carta di credito/debito' },
  { id: 'wire-transfer', name: 'Bonifico bancario' },
  { id: 'check', name: 'Assegno' },
  { id: 'bank-guarantee', name: 'Fideiussione' },
  { id: 'other', name: 'Altro' },
];
/// endregion

/// region PAGE SECTION

export enum PageSection {
  Text = 'text',
  ImageLeft = 'image-left',
  ImageRight = 'image-right',
  ImageTop = 'image-top',
  ImageBottom = 'image-bottom',
  Carousel = 'carousel',
  InfoTriple = 'info-triple',
  InformativeCards = 'informative-cards',
  VideoYoutube = 'video-youtube',
}

export const PAGE_SECTION_CHOICES = [
  { id: PageSection.Text, name: 'Sezione con solo testo' },
  { id: PageSection.ImageLeft, name: 'Sezione con immagine a sinistra' },
  { id: PageSection.ImageRight, name: 'Sezione con immagine a destra' },
  { id: PageSection.ImageTop, name: 'Sezione con immagine in testa' },
  { id: PageSection.ImageBottom, name: 'Sezione con immagine in fondo' },
  { id: PageSection.Carousel, name: 'Sezione con carosello' },
  { id: PageSection.InfoTriple, name: 'Sezione con tripletta' },
  { id: PageSection.InformativeCards, name: 'Sezione con card informative' },
  { id: PageSection.VideoYoutube, name: 'Sezione con video YouTube' },
];
/// endregion
