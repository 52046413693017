import InfoIcon from '@material-ui/icons/Info';
import { ResourceExport } from '../../types/resource-export.type';
import InfoRequestList from './InfoRequestList';

const InfoRequest: ResourceExport = {
  list: InfoRequestList,
  icon: InfoIcon,
};

export default InfoRequest;
