import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppSelectField, AppTextField } from '../../Components/ui/field';
import AppTitle from '../../Components/ui/layout/AppTitle';
import {
  ITEM_GENRES,
  MAGAZINE_KINDS,
  MAGAZINE_PLACEHOLDER_SIDES,
  MAGAZINE_PLACEHOLDER_TYPES,
} from '../../utils/constants';
import { MAGAZINE_PLACEHOLDER_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(30),
    },
    genre: {
      minWidth: theme.spacing(30),
    },
    kind: {
      minWidth: theme.spacing(30),
    },
    type: {
      minWidth: theme.spacing(30),
    },
    side: {
      width: '100%',
    },
  }),
  { name: 'MagazinePlaceholderList' },
);

const MagazinePlaceholderList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Tappi" />}
      resourcePermissions={MAGAZINE_PLACEHOLDER_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <AppSelectField
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          headerClassName={classes.genre}
        />
        <AppSelectField
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo di bollettino"
          headerClassName={classes.kind}
        />
        <AppSelectField
          source="type"
          choices={MAGAZINE_PLACEHOLDER_TYPES}
          label="Tipo di tappo"
          headerClassName={classes.type}
        />
        <AppSelectField
          source="side"
          choices={MAGAZINE_PLACEHOLDER_SIDES}
          label="Lato del tappo"
          headerClassName={classes.side}
        />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default MagazinePlaceholderList;
