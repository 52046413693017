import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ListProps } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';

const useStyles = makeStyles(
  (theme) => ({
    id: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ScreenList' },
);

const PersonSellerList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Proprietari" />}
      bulkActionButtons={false}
      actions={<AppToolbarActions children={null} buttons={[]} />}
    >
      <AppDatagrid>
        <AppTextField source="id" label="ID" headerClassName={classes.id} />
        <AppTextField source="description" label="Descrizione" headerClassName={classes.lastCol} />
      </AppDatagrid>
    </AppList>
  );
};

export default PersonSellerList;
