import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, Fragment } from 'react';
import AppDivider from '../../../ui/layout/AppDivider';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AppDashboardTableTitle' },
);

interface AppDashboardTableTitleProps {
  title: string;
  subtitle: string;
}

const AppDashboardTableTitle: FC<AppDashboardTableTitleProps> = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography variant="h4" className={classes.title}>
        {props.title}
      </Typography>
      <Typography variant="body1">{props.subtitle}</Typography>
      <AppDivider />
    </Fragment>
  );
};

export default AppDashboardTableTitle;
