import { DialogActions, DialogContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { parse } from 'query-string';
import React, { Fragment, FunctionComponent, ReactElement, useMemo } from 'react';
import { useRedirect } from 'react-admin';
import { Form } from 'react-final-form';
import { AppTextInput } from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { AppText } from '../../Components/ui/text';
import { ApiProvider } from '../../Providers/ApiProvider';
import AuthManager from '../../Providers/AuthManager';
import { validateConfirmPassword, validatePassword } from '../../utils/validators';

const useStyles = makeStyles(
  (theme) => ({
    card: {
      width: '50%',
      margin: 'auto',
      marginTop: theme.spacing(5),
    },
  }),
  { name: 'CreatePassword' },
);

type CreatePasswordFormFields = {
  newPassword: string;
  confirmNewPassword: string;
};

interface CreatePasswordProps {
  history?: any;
  location?: any;
  match?: any;
  staticContext?: any;
}

const AppCreatePassword: FunctionComponent<CreatePasswordProps> = (props): ReactElement => {
  const classes = useStyles();
  const redirect = useRedirect();

  const { user, name, token, expiry, op } = useMemo(
    () => parse(props.location.search),
    [props.location.search],
  );

  const passwordSubmitHandler = (data: CreatePasswordFormFields) => {
    if (!user || !token) return;

    new ApiProvider()
      .post(`auth/${op}-password`, null, {
        user: parseInt(user as string),
        token,
        password: data.newPassword,
        confirm: data.confirmNewPassword,
      })
      .then((res: Record<string, any>) => {
        AuthManager.setAuthInfo(res.data.accessToken, res.data.user);
        redirect('/'); //login is automatic after password creation
      })
      .catch((err) => {
        console.log(err);
        alert(`Errore: ${err.message ?? 'UNKNOWN_ERROR'}`);
      });
  };

  return (
    <Card className={classes.card} variant="outlined">
      {!user || !token || !expiry || !op ? (
        <CardContent>
          <AppText variant="h5" align="center">
            Dati non sufficienti per eseguire l'operazione. Contattare un tecnico.
          </AppText>
        </CardContent>
      ) : Date.now() < parseInt(expiry as string) ? (
        <CardContent>
          <AppText variant="h5">Benvenuto, {name}</AppText>
          <AppText variant="button">Inserisci la tua nuova password</AppText>
          <AppDivider />
          <Form
            onSubmit={passwordSubmitHandler}
            render={(formProps) => {
              const { handleSubmit, submitting, pristine, hasValidationErrors } = formProps;
              const disabled = submitting || pristine || hasValidationErrors;

              return (
                <Fragment>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <AppTextInput
                        source="newPassword"
                        label="Nuova password"
                        type="password"
                        required
                        validate={[validatePassword()]}
                      />
                      <AppTextInput
                        source="confirmNewPassword"
                        label="Conferma nuova password"
                        type="password"
                        required
                        validate={[validateConfirmPassword('newPassword')]}
                      />
                      <AppText variant="button">
                        La password deve contenere almeno un numero, una lettera maiuscola, un
                        carattere speciale ed essere di almeno 8 caratteri
                      </AppText>
                    </Grid>
                  </DialogContent>
                  <AppDivider />
                  <DialogActions>
                    <Grid container justify="center">
                      <Button
                        disabled={disabled}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        autoFocus
                        children="Conferma nuova password"
                      />
                    </Grid>
                  </DialogActions>
                </Fragment>
              );
            }}
          />
        </CardContent>
      ) : (
        <CardContent>
          <AppText variant="h5" align="center">
            Il Token è scaduto
          </AppText>
        </CardContent>
      )}
    </Card>
  );
};

export default AppCreatePassword;
