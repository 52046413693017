import { Box, Button, ButtonGroup, Grid, makeStyles, Typography } from '@material-ui/core';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { AppAutocompleteInput, AppInputFiller, AppSelectInput } from '../../Components/ui/input';
import { SaleMode } from '../../utils/constants';
import { getResourceType } from '../../utils/reference-selector';
import {
  ASYNC_SALE_EXP_END_COLOR,
  ASYNC_SALE_EXP_START_COLOR,
  EXP_OF_INT_SALE_EXP_END_COLOR,
  EXP_OF_INT_SALE_EXP_START_COLOR,
  OFF_COLL_SALE_EXP_END_COLOR,
  OFF_COLL_SALE_EXP_START_COLOR,
  SYNC_SALE_EXP_COLOR,
} from './constants';

const useLegendStyles = makeStyles(
  (theme) => {
    return {
      container: {
        textAlign: 'center',
        maxWidth: '24rem',
        minWidth: '24rem',
      },
      element: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        marginRight: theme.spacing(2),
      },
    };
  },
  { name: 'SaleExperimentCalendarLegend' },
);

const useToolbarStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      buttons: {
        marginBottom: theme.spacing(1),
        maxHeight: theme.spacing(5),
      },
    };
  },
  { name: 'SaleExperimentCalendarToolbar' },
);

const SaleExperimentCalendarToolbar: FC<any> = (props) => {
  const navigate = (action) => props.onNavigate(action);
  const { currentResource, calendarFilters, setCalendarFilters } = props;

  const legendClasses = useLegendStyles();
  const toolbarClasses = useToolbarStyles();

  return (
    <div className={toolbarClasses.root}>
      <Form
        onSubmit={() => undefined} //no submit needed
        render={() => {
          return (
            <Grid container spacing={2}>
              <ButtonGroup
                className={toolbarClasses.buttons}
                disableElevation
                variant="contained"
                color="primary"
              >
                <Button onClick={() => navigate('PREV')}>
                  <KeyboardArrowLeftIcon />
                </Button>
                <Button>{props.label.toUpperCase()}</Button>
                <Button onClick={() => navigate('NEXT')}>
                  <KeyboardArrowRightIcon />
                </Button>
              </ButtonGroup>
              <AppInputFiller md={12} />
              {!props.isAgency && (
                <AppSelectInput
                  source="sales"
                  label="Vendita interna"
                  choices={[
                    { id: null, name: '\xa0' },
                    { id: true, name: 'SI' },
                    { id: false, name: 'NO' },
                  ]}
                  md={2}
                  onChange={(event) => {
                    setCalendarFilters((prevFilters) => ({
                      ...prevFilters,
                      sales: event.target.value,
                    }));
                  }}
                  defaultValue={calendarFilters.sales}
                />
              )}
              {getResourceType(currentResource) !== 'private' && [
                <AppAutocompleteInput
                  resettable
                  reference="courts"
                  optionText="name"
                  source="courtId"
                  label="Tribunale"
                  variant="outlined"
                  helperText={false}
                  suggestionLimit={5}
                  md={4}
                  onChange={(courtId) =>
                    setCalendarFilters((prevFilters) => ({
                      ...prevFilters,
                      court: courtId,
                    }))
                  }
                  defaultValue={calendarFilters.court}
                />,
              ]}
              <AppSelectInput
                source="saleModeId"
                choices={[
                  { id: null, name: '\xa0' },
                  { id: SaleMode.AsyncOnline, name: 'Asincrona' },
                  { id: SaleMode.SyncOnline, name: 'Sincrona pura' },
                  { id: SaleMode.SyncMixed, name: 'Sincrona mista' },
                ]}
                variant="outlined"
                label="Tipologia di vendita"
                helperText={false}
                onChange={(event) =>
                  setCalendarFilters((prevFilters) => ({
                    ...prevFilters,
                    saleMode: event.target.value,
                  }))
                }
                defaultValue={calendarFilters.saleMode}
                md={2}
              />
              <AppAutocompleteInput
                reference="cities"
                resettable
                source="cityId"
                label="Città in cui è ubicato il bene"
                variant="outlined"
                helperText={false}
                suggestionLimit={5}
                md={3}
                onChange={(cityId) =>
                  setCalendarFilters((prevFilters) => ({
                    ...prevFilters,
                    city: cityId,
                  }))
                }
                defaultValue={calendarFilters.city}
              />
            </Grid>
          );
        }}
      />
      <div className={legendClasses.container}>
        <Typography variant="h6">
          <Box fontWeight="bold">Dicitura</Box>
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: ASYNC_SALE_EXP_START_COLOR }} />
          Inizio aste asincrone
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: ASYNC_SALE_EXP_END_COLOR }} />
          Termine aste asincrone
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: SYNC_SALE_EXP_COLOR }} />
          Aste sincrone pure o miste
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: EXP_OF_INT_SALE_EXP_START_COLOR }} />
          Inizio manifestazioni di interesse
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: EXP_OF_INT_SALE_EXP_END_COLOR }} />
          Termine manifestazioni di interesse
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: OFF_COLL_SALE_EXP_START_COLOR }} />
          Inizio raccolta offerte
        </Typography>
        <Typography className={legendClasses.element}>
          <LegendIcon style={{ color: OFF_COLL_SALE_EXP_END_COLOR }} />
          Termine raccolta offerte
        </Typography>
      </div>
    </div>
  );
};

export default SaleExperimentCalendarToolbar;
