import { FC } from 'react';
import { ArrayInput, required, SimpleFormIterator, TextInput } from 'react-admin';
import { AppRichTextInput } from '../ui/input';

interface Props {
  formSource: string;
  formLabel: string;
}

const AppPageSectionMultipleForm: FC<Props> = (props: Props) => {
  const { formSource, formLabel } = props;

  return (
    <ArrayInput source={formSource} label={formLabel} validate={[required()]}>
      <SimpleFormIterator>
        <TextInput source="title" label="Titolo" variant="outlined" />
        <AppRichTextInput source="text" label="Testo" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default AppPageSectionMultipleForm;
