import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ArrayField, Datagrid, FunctionField, ListProps } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { AUCTION_ROOM_SLOT_LENGTHS, WEEK_DAYS } from '../../utils/constants';
import { AUCTION_ROOM_PERMISSIONS } from './index';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';

const useStyles = makeStyles(
  () => ({
    title: { whiteSpace: 'nowrap' },
    description: { whiteSpace: 'nowrap' },
    slotLength: { whiteSpace: 'nowrap' },
    ranges: { whiteSpace: 'nowrap' },
  }),
  { name: 'AuctionRoomList' },
);

const AuctionRoomList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  const AUCTION_ROOM_BOOKINGS_ENABLED =
    process.env.REACT_APP_AUCTION_ROOM_BOOKINGS_ENABLED === 'true';

  return AUCTION_ROOM_BOOKINGS_ENABLED ? (
    <AppList
      {...props}
      title={<AppTitle title="Sale d'asta" />}
      resourcePermissions={AUCTION_ROOM_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField
          source="title"
          label="Titolo"
          headerClassName={classes.title}
          cellClassName={classes.title}
        />
        <AppTextField
          source="description"
          label="Descrizione"
          headerClassName={classes.description}
          cellClassName={classes.description}
        />
        <FunctionField
          render={(record) => {
            return AUCTION_ROOM_SLOT_LENGTHS.find((length) => length.id === record.slotLength)
              ?.name;
          }}
          label="Durata"
        />
        <ArrayField source="ranges" label="Intervalli">
          <Datagrid>
            <FunctionField
              render={(record) => {
                return record.weekDays
                  .map((weekDay) => WEEK_DAYS.find((day) => day.id === weekDay)?.name)
                  .join(', ');
              }}
              label="Giorni"
            />
            <AppTextField source="from" label="Dalle" />
            <AppTextField source="to" label="Alle" />
          </Datagrid>
        </ArrayField>
      </AppDatagrid>
    </AppList>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità della "Prenotazione sale d\'asta" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default AuctionRoomList;
