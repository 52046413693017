import { FC } from 'react';
import { ArrayInput, SimpleFormIterator, useCheckMinimumRequiredProps } from 'react-admin';
import AppCard from '../ui/card/AppCard';
import { AppTextInput } from '../ui/input';
import AppDivider from '../ui/layout/AppDivider';

type AppContactsDataProps = {
  record?: any;
  disabled?: boolean;
  sourcePrefix?: string;
  father?: any;
  emailRequired?: boolean;
};

const AppContactsData: FC<AppContactsDataProps> = (props: AppContactsDataProps) => {
  useCheckMinimumRequiredProps('AppContactsData', ['record'], props);
  const { record, disabled, sourcePrefix, father, emailRequired } = props;
  let phonesSource = 'phones';
  let mobilesSource = 'mobiles';
  let emailSource = 'email';
  let secondaryEmailsSource = 'secondaryEmails';
  let pecSource = 'pec';
  let secondaryPecsSource = 'secondaryPecs';
  let faxesSource = 'faxes';

  if (father && father.length) {
    if (sourcePrefix && sourcePrefix.length) {
      phonesSource = `${father}.${sourcePrefix}Phones`;
      mobilesSource = `${father}.${sourcePrefix}Mobiles`;
      emailSource = `${father}.${sourcePrefix}Email`;
      secondaryEmailsSource = `${father}.${sourcePrefix}SecondaryEmails`;
      pecSource = `${father}.${sourcePrefix}Pec`;
      secondaryPecsSource = `${father}.${sourcePrefix}SecondaryPecs`;
      faxesSource = `${father}.${sourcePrefix}Faxes`;
    } else {
      phonesSource = `${father}.phones`;
      mobilesSource = `${father}.mobiles`;
      emailSource = `${father}.email`;
      secondaryEmailsSource = `${father}.secondaryEmails`;
      pecSource = `${father}.pec`;
      secondaryPecsSource = `${father}.secondaryPecs`;
      faxesSource = `${father}.faxes`;
    }
  } else if (sourcePrefix && sourcePrefix.length) {
    phonesSource = `${sourcePrefix}Phones`;
    mobilesSource = `${sourcePrefix}Mobiles`;
    emailSource = `${sourcePrefix}Email`;
    secondaryEmailsSource = `${sourcePrefix}SecondaryEmails`;
    pecSource = `${sourcePrefix}Pec`;
    secondaryPecsSource = `${sourcePrefix}SecondaryPecs`;
    faxesSource = `${sourcePrefix}Faxes`;
  }

  return (
    <AppCard {...{ record, disabled }} title="Contatti">
      <ArrayInput source={phonesSource} label="Numeri di telefono">
        <SimpleFormIterator>
          <AppTextInput source="name" label="Nome" />
          <AppTextInput source="number" label="Numero" />
        </SimpleFormIterator>
      </ArrayInput>
      <AppDivider />
      <ArrayInput source={mobilesSource} label="Numeri di cellulare">
        <SimpleFormIterator>
          <AppTextInput source="name" label="Nome" />
          <AppTextInput source="number" label="Numero" />
        </SimpleFormIterator>
      </ArrayInput>
      <AppDivider />
      <AppTextInput
        source={emailSource}
        required={emailRequired}
        type="email"
        label="Email"
        md={4}
      />
      <AppTextInput source={pecSource} type="email" label="PEC" md={4} />
      <ArrayInput source={secondaryEmailsSource} label="Email secondarie">
        <SimpleFormIterator>
          <AppTextInput source="email" type="email" label="Email" />
        </SimpleFormIterator>
      </ArrayInput>
      <AppDivider />
      <ArrayInput source={secondaryPecsSource} label="PEC secondarie">
        <SimpleFormIterator>
          <AppTextInput source="pec" type="email" label="PEC" />
        </SimpleFormIterator>
      </ArrayInput>
      <AppDivider />
      <ArrayInput source={faxesSource} label="Fax">
        <SimpleFormIterator>
          <AppTextInput source="fax" type="number" label="Fax" />
        </SimpleFormIterator>
      </ArrayInput>
    </AppCard>
  );
};

export default AppContactsData;
