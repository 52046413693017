import { FC, ReactElement, memo } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppNumberInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { PAYMENT_METHODS, PAYMENT_REASONS, PAYMENT_TYPES } from './constants';
import { CheckDetails } from './detail-components/CheckDetails';
import { CreditCardDetails } from './detail-components/CreditCardDetails';
import { OwnerDetails } from './detail-components/OwnerDetails';
import { OwnerSelection } from './detail-components/OwnerSelection';
import { WireTransferDetails } from './detail-components/WireTransferDetails';

const PaymentDetails: FC<any> = memo((props): ReactElement => {
  return (
    <AppBaseForm {...props}>
      {props.mode === 'create' && <OwnerSelection />}
      {props.mode !== 'create' && <OwnerDetails record={props.record} />}

      <AppCard expanded={props.mode !== 'create'} title="Informazioni principali">
        <AppSelectInput
          choices={PAYMENT_TYPES}
          source="paymentType"
          label="Tipo di pagamento"
          disabled
          defaultValue={PAYMENT_TYPES[0].id}
          md={4}
          required
        />
        <AppSelectInput
          choices={PAYMENT_REASONS}
          source="paymentReason"
          label="Causale"
          md={4}
          disabled
          defaultValue={PAYMENT_REASONS[1].id}
          required
        />
        <AppSelectInput
          choices={PAYMENT_METHODS}
          source="paymentMethod"
          label="Modalità di pagamento"
          md={4}
          required
        />
        <AppNumberInput source="amount" label="Importo" isAmount md={3} required />
        <AppNumberInput source="feesAmount" label="Commissioni" isAmount md={3} />
        <AppTextInput source="notes" label="Note" md={12} multiline />
      </AppCard>
      <WireTransferDetails mode={props.mode} />
      <CreditCardDetails />
      <CheckDetails />
    </AppBaseForm>
  );
});

export default PaymentDetails;
