import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { FunctionField, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppLinkToEntityField, AppTextField } from '../../Components/ui/field';
import { AppText } from '../../Components/ui/text';
import { useUserIsAllowed } from '../../hooks';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import { PAYMENT_METHODS } from '../Payment/constants';

const useStyles = makeStyles(
  (theme) => ({
    // --- Datagrid columns ---
    paymentMethod: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    amount: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    participation: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(40),
    },
    itemTitle: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      width: '100%',
    },
    link: {
      color: theme.palette.common.black,
    },
  }),
  { name: 'InfoRequestPaymentDatagrid' },
);

const InfoRequestPaymentDatagrid: FC<any> = (props) => {
  const classes = useStyles();

  const allowedToSeePeople = useUserIsAllowed(ResourceAction.Get, 'people');

  return (
    <AppDatagrid {...props} expand={props.expand}>
      <FunctionField
        render={(payment?: Record<string, any>) =>
          payment?.paymentMethod ? (
            <SelectField
              choices={PAYMENT_METHODS}
              source="paymentMethod"
              label="Modalità di pagamento"
            />
          ) : (
            <AppTextField customText="-" />
          )
        }
        headerClassName={classes.paymentMethod}
      />
      <AppTextField source="amount" label="Importo" headerClassName={classes.amount} />
      <FunctionField
        label="Anagrafica"
        render={(payment?: Record<string, any>) => {
          if (!payment?.personProperty?.person) return <AppTextField customText="-" />;

          const text = `${payment?.personProperty.person.lastName} ${
            payment.personProperty.person.firstName
          } (${payment.personProperty.person.taxCode ?? payment.personProperty.person.vat ?? '-'})`;
          return allowedToSeePeople ? (
            <AppLinkToEntityField
              mode="show"
              targetResource="people"
              targetResourceId={payment.personProperty.person.id}
              originName="pagamenti servizi"
              value={text}
              className={classes.link}
            />
          ) : (
            <AppTextField customText={text} />
          );
        }}
        headerClassName={classes.participation}
      />
      <FunctionField
        label="Lotto"
        headerClassName={classes.lastCol}
        render={(payment) =>
          payment?.infoRequest?.item ? (
            <AppLinkToEntityField
              label="Lotto"
              targetResource="items"
              originName="richieste informazioni"
              record={payment}
              value={(r) => r.infoRequest.item.title}
              targetResourceId={(r) => r.infoRequest.item.id}
              truncate
              maxTextLength={50}
              className={classes.link}
            />
          ) : (
            <AppText children="-" />
          )
        }
      />
    </AppDatagrid>
  );
};

export default InfoRequestPaymentDatagrid;
