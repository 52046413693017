import MagazineIndexIcon from '@material-ui/icons/List';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import MagazineIndexDetails from './MagazineIndexDetails';
import MagazineIndexList from './MagazineIndexList';

export const MAGAZINE_INDEX_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const MagazineIndex: ResourceExport = {
  list: MagazineIndexList,
  create: resourceWrapper(
    'create',
    MagazineIndexDetails,
    undefined,
    undefined,
    MAGAZINE_INDEX_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    MagazineIndexDetails,
    undefined,
    undefined,
    MAGAZINE_INDEX_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    MagazineIndexDetails,
    undefined,
    undefined,
    MAGAZINE_INDEX_PERMISSIONS,
  ),
  icon: MagazineIndexIcon,
};

export default MagazineIndex;
