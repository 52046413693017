import { makeStyles } from '@material-ui/core';
import { assign as _assign, concat as _concat, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { ListContextProvider, ReferenceField } from 'react-admin';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';
import AppUrlField from '../../ui/field/AppUrlField';
import { AppText } from '../../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    // PENDING MEDIA DATAGRID
    pendingMediaNameCol: {
      minWidth: theme.spacing(35),
    },
    pendingMediaUrlCol: {
      minWidth: theme.spacing(60),
    },
    pendingMediaLastCol: {
      width: '100%',
    },
  }),
  { name: 'PendingMedia' },
);

interface PendingMediaProps {
  pendingMedia: Record<string, any>;
}

const PendingMedia: FC<PendingMediaProps> = ({ pendingMedia }) => {
  const classes = useStyles();

  const pendingMediaListBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
  };
  const [pendingMediaListCtx, setPendingMediaListCtx] =
    useState<Record<string, any>>(pendingMediaListBaseCtx);

  useEffect(() => {
    if (!pendingMedia) return;

    const allPendingMedia = _concat<Record<string, any>>(
      [],
      ...Object.values<Record<string, any>>(pendingMedia),
    );
    const pendingMediaWithIds = allPendingMedia.map((pendingMedia, index) => ({
      id: index + 1,
      ...pendingMedia,
    }));

    setPendingMediaListCtx(
      _assign({}, pendingMediaListBaseCtx, {
        data: _keyBy(pendingMediaWithIds, 'id'),
        ids: _map(pendingMediaWithIds, 'id'),
        total: pendingMediaWithIds.length,
        loaded: true,
        loading: false,
      }),
    );
  }, [pendingMedia]);

  if (pendingMediaListCtx.total < 1) return <Fragment />;

  return (
    <Fragment>
      <AppText variant="h5">Media rimanenti da importare</AppText>
      <ListContextProvider value={pendingMediaListCtx}>
        <AppDatagrid>
          <AppTextField
            source="name"
            label="Nome"
            headerClassName={classes.pendingMediaNameCol}
            truncate
          />
          <AppUrlField source="url" label="URL" headerClassName={classes.pendingMediaUrlCol} />
          <ReferenceField
            reference="media-roles"
            source="role"
            label="Ruolo"
            basePath="/media-roles"
            link={false}
            headerClassName={classes.pendingMediaLastCol}
          >
            <AppTextField source="name" />
          </ReferenceField>
        </AppDatagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export default PendingMedia;
