import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useMutation, usePermissions } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { User } from '../../../types/user.type';
import AppAutocompleteInput from './AppAutocompleteInput';

const AppCategorizationInput: FC<any> = (props): ReactElement => {
  const { variant = 'outlined', disabled = false } = props;

  const { permissions: user } = usePermissions<User>();
  // console.log('user', user);

  /// region SETUP

  // Initial states
  const [genreChoices, setGenreChoices] = useState<any[]>([]);
  const [categoryChoices, setCategoryChoices] = useState<any[]>([]);
  const [typologyChoices, setTypologyChoices] = useState<any[]>([]);

  // Get form mutators and values
  const { mutators: formMutators } = useForm();
  const { values } = useFormState();

  // Mutation function for choices changes
  const [mutateCategories] = useMutation();
  const [mutateTypologies] = useMutation();

  // Get Genre choices
  const [mutateGenres, { loaded: genresLoaded }] = useMutation();
  useEffect(() => {
    mutateGenres(
      {
        type: 'getList',
        resource: 'genres',
        payload: {},
      },
      {
        onSuccess: (res) => setGenreChoices(res.data),
        onFailure: (err) => console.error(err),
      },
    );

    // Get initial choices using existing values if not in 'create' mode
    if (!values.fkGenre || !values.fkCategory) return;

    getCategories(values.fkGenre, false);
    getTypologies(values.fkCategory, false);
  }, []);

  /// endregion

  /// region GETTERS

  const getCategories = (genreId, changed = true) =>
    mutateCategories(
      {
        type: 'getList',
        resource: 'categories',
        payload: {
          filter: {
            fkGenre: genreId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setCategoryChoices(res.data);

          if (changed) {
            //done only if the previous autocomplete has changed
            formMutators.clearInputs(['fkCategory', 'fkTypology']); //TODO: check if value is already undefined
            setTypologyChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs(['fkCategory', 'fkTypology']);
          setCategoryChoices([]);
          setTypologyChoices([]);
        },
      },
    );

  const getTypologies = (categoryId, changed = true) =>
    mutateTypologies(
      {
        type: 'getList',
        resource: 'typologies',
        payload: {
          filter: {
            fkCategory: categoryId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setTypologyChoices(res.data);

          if (changed) {
            formMutators.clearInputs('fkTypology');
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs('fkTypology');
          setTypologyChoices([]);
        },
      },
    );

  /// endregion

  return genresLoaded ? (
    <React.Fragment>
      <AppAutocompleteInput
        source="fkGenre"
        label="Genere"
        variant={variant}
        md={4}
        optionText="description"
        onChange={getCategories}
        choices={
          user?.agency?.genre
            ? genreChoices.filter((g) => g.type === user?.agency?.genre)
            : genreChoices
        }
        required
        disabled={disabled}
      />
      <AppAutocompleteInput
        source="fkCategory"
        label="Categoria"
        variant={variant}
        md={4}
        optionText="description"
        onChange={getTypologies}
        choices={
          user?.agency?.category
            ? categoryChoices.filter((c) => c.type === user?.agency?.category)
            : categoryChoices
        }
        required
        disabled={disabled}
      />
      <AppAutocompleteInput
        source="fkTypology"
        label="Tipologia"
        variant={variant}
        md={4}
        optionText="description"
        choices={
          user?.agency?.category
            ? typologyChoices.filter((t) => user?.agency?.typologies.includes(t.type))
            : typologyChoices
        }
        required
        disabled={disabled}
      />
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export default AppCategorizationInput;
