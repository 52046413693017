import { Button, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddBox as CreateNewSaleIcon,
  CreateNewFolder as CreateNewItemIcon,
  Link as LinkToSaleIcon,
} from '@material-ui/icons';
import React, { FC, Fragment } from 'react';
import { AppDialog } from '../../ui/dialog';
import AppDivider from '../../ui/layout/AppDivider';
import { AppBold, AppText } from '../../ui/text';
import RequestDetails from '../request-details/request-details';
import RequestLinkExistingItemsList from './request-link-existing-items-list';
import RequestLinkSaleCreation from './request-link-sale-creation';
import RequestLinkSalesToLinkToList from './request-link-sales-to-link-to-list';

const useStyles = makeStyles(
  (theme) => ({
    dialogContainer: {
      maxWidth: 'none',
      width: '100%',
      height: theme.spacing(150),
    },
    createSaleButton: {},
    requestInfoContainer: {
      marginBottom: theme.spacing(2),
    },
    sectionDivider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'RequestLinkDialog' },
);

interface RequestLinkDialogProps {
  open: boolean;
  closeDialog: () => void;
  pvpRequest: Record<string, any>;
}

const RequestLinkDialog: FC<RequestLinkDialogProps> = ({ open, closeDialog, pvpRequest }) => {
  const classes = useStyles();

  const { id: pvpRequestId, saleExperimentInsertionId } = pvpRequest;

  return (
    <AppDialog
      open={open}
      onClose={closeDialog}
      title={
        <Grid container>
          <Grid item md={10}>
            <AppText variant="h6">
              Gestione associazione della vendita - Inserzione numero {saleExperimentInsertionId}
            </AppText>
          </Grid>
          <Grid item>
            <RequestLinkSaleCreation
              pvpRequestId={pvpRequestId}
              closeDialog={closeDialog}
              label="Crea nuovo lotto"
              Icon={CreateNewItemIcon}
            />
          </Grid>
        </Grid>
      }
      content={
        <Fragment>
          <List
            subheader={
              <AppText id="nested-list-subheader">
                <AppBold>IN QUESTA SCHERMATA PUOI:</AppBold>
              </AppText>
            }
          >
            <ListItem>
              <ListItemIcon>
                <CreateNewItemIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={<AppText variant="button">Creare un nuovo lotto</AppText>}
                secondary="Clicca il tasto in alto a destra se la ricerca di una vendita associabile o di un lotto esistente non è andata a buon fine per creare un nuovo lotto e una nuova vendita."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LinkToSaleIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <AppText variant="button">
                    Associare la vendita a un esperimento esistente
                  </AppText>
                }
                secondary='Utilizza la prima lista di risultati ("LISTA VENDITE ASSOCIABILI") per cercare un esperimento di vendita associabile alla vendita del PVP.'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CreateNewSaleIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <AppText variant="button">Creare una nuova vendita su un lotto esistente</AppText>
                }
                secondary={
                  'Utilizza la seconda lista di risultati ("LISTA DEI LOTTI DELLA PROCEDURA") per creare l\'esperimento di vendita su un lotto già presente a sistema.'
                }
              />
            </ListItem>
          </List>
          <AppDivider className={classes.sectionDivider} />
          <RequestDetails pvpRequest={pvpRequest} showAsyncSalesAlert={false} />
          <RequestLinkSalesToLinkToList pvpRequestId={pvpRequestId} closeDialog={closeDialog} />
          <AppDivider className={classes.sectionDivider} />
          <RequestLinkExistingItemsList pvpRequest={pvpRequest} closeDialog={closeDialog} />
        </Fragment>
      }
      actions={
        <Fragment>
          <Button onClick={closeDialog}>Chiudi</Button>
        </Fragment>
      }
      classes={{ paper: classes.dialogContainer }}
    />
  );
};

export default RequestLinkDialog;
