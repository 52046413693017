import { FC, ReactElement } from 'react';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import ItemDatagrid from '../Item/ItemDatagrid';
import { PROPERTY_SCHEMAS } from './constants';

const PropertyDetails: FC<any> = (props): ReactElement => {
  return (
    <AppTabbedForm {...props}>
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        <AppCard title="Informazioni generali" expanded>
          <AppTextInput source="name" label="Nome" disabled={props.mode !== 'create'} required />
          <AppBooleanInput source="enabled" label="Abilitato" />
          <AppDivider />
          <AppAutocompleteInput
            reference="genres"
            sort={{
              field: 'id',
              order: 'ASC',
            }}
            optionText="description"
            isArray
            source="genres"
            label="Generi pubblicabili *"
            required
            md={12}
          />
          <AppBooleanInput source="judiciary" label="Lotti giudiziari" md={6} />
          <AppBooleanInput source="private" label="Lotti privati" md={6} />
          <AppSelectInput source="schema" label="Schema sito" choices={PROPERTY_SCHEMAS} />
          {/* // TODO: use a JSON editor: https://www.npmjs.com/package/react-json-editor-ajrm */}
          <AppTextInput
            source="schemaParams"
            label="Parametri di configurazione"
            multiline
            md={12}
            format={(value) => JSON.stringify(value)}
            disabled
          />
          <AppTextInput source="metadata" label="Metadati" multiline rows={10} md={12} />
          <AppTextInput source="host" label="URL del sito" placeholder="https://nome-del-sito.it" />
          <AppTextInput
            source="itemsPath"
            label="Percorso annunci"
            placeholder="/annunci"
            defaultValue="/annunci"
          />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Lotti" path="items">
        <AppCard title="Lista dei lotti pubblicati" hideContent={props.mode === 'create'} expanded>
          <AppRelatedResource relatedResource="/items" actions={[]}>
            <ItemDatagrid
              resource="items"
              editButton={
                <AppGoToResourceButton
                  mode="edit"
                  destinationResourceName="items"
                  currentResourceLabel="Proprietà"
                />
              }
              showButton={
                <AppGoToResourceButton
                  mode="show"
                  destinationResourceName="items"
                  currentResourceLabel="Proprietà"
                />
              }
              permissions={props.permissions}
            />
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default PropertyDetails;
