import PaymentIcon from '@material-ui/icons/Payment';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import PaymentDetails from './PaymentDetails';
import PaymentList from './PaymentList';

export const PAYMENT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Payment: ResourceExport = {
  list: PaymentList,
  icon: PaymentIcon,
  create: resourceWrapper('create', PaymentDetails, undefined, undefined, undefined),
  edit: resourceWrapper('edit', PaymentDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', PaymentDetails, undefined, undefined, undefined),
};

export default Payment;
