import { Block } from '@material-ui/icons';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { Confirm, useEditContext, useMutation, useNotify, useRefresh } from 'react-admin';
import AppMessageDialog from '../../Components/ui/dialog/message-dialog/app-message-dialog';
import AppToolbarAction from '../../Components/ui/layout/AppToolbarAction';

const AppToolbarDisableAccess: FC<any> = (props) => {
  const { handleClose: closeMenu, record } = props;
  const { $formStatus } = useEditContext() as any;
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  const content =
    "Se confermi la disattivazione l'utente non potrà più accedere alla piattaforma e dovrà essere riattivato manualmente.";
  const title = 'Disattivazione utente.';

  const openDialog = () => {
    closeMenu();
    setHasError($formStatus?.dirty);
    setOpen(true);
  };
  const closeDialog = useCallback(() => setOpen(false), []);

  const changeStatus = () => {
    mutate(
      {
        type: 'create',
        resource: 'auth/set-access-state',
        payload: {
          data: {
            user: record.id,
            state: 'disabled',
          },
        },
      },
      {
        onSuccess: () => {
          closeDialog();
          refresh();
          notify("L'utente è stato disattivato e non potrà accedere alla piattaforma.");
        },
        onFailure: (err) => {
          console.error(err);
          closeDialog();
          notify(`Disattivazione fallita. ${err?.message ?? 'ERRORE_SCONOSCIUTO'}.`, 'error');
        },
      },
    );
  };

  return (
    <Fragment>
      <AppToolbarAction onClick={openDialog} text="Disattiva accesso" icon={Block} />
      {hasError ? (
        <AppMessageDialog
          open={open}
          title="Impossibile procedere"
          content="Sono presenti delle modifiche non salvate."
          onClose={closeDialog}
        />
      ) : (
        <Confirm
          isOpen={open}
          content={content}
          onClose={closeDialog}
          onConfirm={changeStatus}
          title={title}
        />
      )}
    </Fragment>
  );
};

export default AppToolbarDisableAccess;
