import React, { FC, Fragment } from 'react';
import { AppTextInput } from '../ui/input';
import { AppFileInput } from '../ui/input/AppFileInput';

const AppAgentForm: FC<any> = ({ ctx }) => {
  return (
    <Fragment>
      <AppTextInput source="name" label="Nome" variant="outlined" md={6} required />
      <AppTextInput source="email" label="Email" variant="outlined" md={6} />
      <AppTextInput source="phone" label="Telefono" variant="outlined" md={6} />
      <AppTextInput source="mobile" label="Cellulare" variant="outlined" md={6} />
      <AppFileInput
        source="imageFile"
        previewSource="image"
        label="Immagine"
        base64Preview={ctx.manager.mode !== 'insert' && ctx.item.imageFile && !ctx.item.image}
        md={12}
        previewMaxWidth
      />
    </Fragment>
  );
};

export default AppAgentForm;
