/**
 * Used to calculate target resource for "go-to" buttons or links using current resource
 * @param currentResource
 * @param targetBaseResource
 */
export const getReference = (currentResource: string, targetBaseResource: string): string => {
  if (currentResource.search('judiciary') !== -1) return `${targetBaseResource}-judiciary`;
  if (currentResource.search('private') !== -1) return `${targetBaseResource}-private`;
  if (currentResource.search('utp-npl') !== -1) return `${targetBaseResource}-utp-npl`;

  // default
  return targetBaseResource;
};

/**
 * Used to calculate current resource type, useful if component is in a nested resource
 * @param resource
 */
export const getResourceType = (resource: string): string => {
  if (resource.search('judiciary') !== -1) return 'judiciary';
  if (resource.search('private') !== -1) return 'private';
  if (resource.search('utp-npl') !== -1) return 'utp-npl';

  // default
  return resource;
};
