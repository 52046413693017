import { FC, memo } from 'react';
import { useFormState } from 'react-final-form';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppSelectInput, AppTextInput } from '../../../Components/ui/input';
import { CREDIT_CARD_TYPES, PaymentMethod } from '../constants';

export const CreditCardDetails: FC<any> = memo((props) => {
  const { values } = useFormState();
  return (
    <AppCard
      {...props}
      title="Informazioni carta di credito"
      hidden={values.paymentMethod !== PaymentMethod.CreditCard}
    >
      <AppTextInput source="creditCardOwner.name" label="Intestatario carta" md={3} />
      <AppTextInput
        source="creditCardOwner.email"
        label="Email intestatario carta"
        type="email"
        md={3}
      />
      <AppSelectInput
        source="creditCardType"
        choices={CREDIT_CARD_TYPES}
        label="Tipo carta"
        md={3}
      />
      <AppTextInput source="transactionId" label="ID transazione" md={3} />
    </AppCard>
  );
});
