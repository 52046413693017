import { Button, debounce, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { ChangeEvent, FC } from 'react';
import { DetailPanelTitle } from '../../detail-panel';
import { AppText } from '../../ui/text';
import AppTooltip from '../../ui/tooltip/AppTooltip';

const useStyles = makeStyles(
  (theme) => ({
    filterContainer: {
      marginBottom: theme.spacing(3),
    },
    description: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'RequestLinkExistingItemsList' },
);

interface RequestFulltextSearchProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  updateList: () => void;
  title: string;
  description?: string;
}

const RequestFulltextSearch: FC<RequestFulltextSearchProps> = ({
  onChange,
  updateList,
  title,
  description = '',
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.filterContainer}>
      <DetailPanelTitle children={title} />
      <Grid item md={12}>
        <AppText className={classes.description}>{description}</AppText>
      </Grid>
      <Grid item md={3}>
        <TextField
          fullWidth
          variant="outlined"
          id={title}
          label="Ricerca"
          size="small"
          onChange={debounce((e) => onChange(e), 500)}
        />
      </Grid>
      <Grid item md={8} />
      <Grid item>
        <AppTooltip title="Aggiorna">
          <Button variant="outlined" onClick={updateList}>
            <RefreshIcon />
          </Button>
        </AppTooltip>
      </Grid>
    </Grid>
  );
};

export default RequestFulltextSearch;
