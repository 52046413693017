const TOKEN_FIELD = 'accessToken';
const USER_FIELD = 'user';

export default class AuthManager {
  public static setAuthInfo(token: string, user: Record<string, any>): void {
    localStorage.setItem(TOKEN_FIELD, token);
    localStorage.setItem(USER_FIELD, JSON.stringify(user));
  }

  public static getAuthToken(): string | null {
    return localStorage.getItem(TOKEN_FIELD);
  }

  public static getUserInfo(): string | null {
    return localStorage.getItem(USER_FIELD);
  }

  public static destroyAuthInfo(): void {
    localStorage.removeItem(TOKEN_FIELD);
    localStorage.removeItem(USER_FIELD);
  }

  public static updatePermissions(permissions: any[], admin: boolean): void {
    const user = AuthManager.getUserInfo();
    if (user) {
      localStorage.setItem(USER_FIELD, JSON.stringify({ ...JSON.parse(user), permissions, admin }));
    }
  }
}
