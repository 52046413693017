import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { History } from 'history';
import { Record } from 'ra-core';
import React, { Children, cloneElement, FC, Fragment, ReactElement } from 'react';
import { createPortal } from 'react-dom';

const AppToolbarActions: FC<AppToolbarActionsProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { children, data, buttons, ...rest } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const container =
    typeof document !== 'undefined'
      ? document.getElementById('jugaad-toolbar-context-actions')
      : null;
  if (!container) return null;

  const Component = (
    <Fragment>
      {buttons ? (
        buttons.map((button, index) =>
          cloneElement(button, {
            ...rest,
            key: index,
            record: data ?? {},
          }),
        )
      ) : (
        <Fragment />
      )}
      {children ? (
        <IconButton
          color='inherit'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Fragment />
      )}
      {children ? (
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          /*anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}*/
        >
          {Children.map(children, (child: any, index) => {
            return cloneElement(child as any, {
              ...rest,
              record: data,
              key: index,
              handleClose: handleClose,
            });
          })}
        </Menu>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );

  return createPortal(Component, container);
};

export default AppToolbarActions;

export interface AppToolbarActionsProps {
  children?: ReactElement | null; //menu items (in list view it has to be forced to null, otherwise the list passes his children automatically and the App breaks)
  // ----
  basePath?: string;
  data?: Record;
  resource?: string;
  undoable?: boolean;
  // ----
  buttons?: ReactElement[]; //array of toolbar buttons that renders aside the toolbar menu
  // ----
  id?: string;
  history?: History;
  hasEdit?: boolean;
  hasCreate?: boolean;
  hasList?: boolean;
  hasShow?: boolean;
  permissions?: any;
  options?: any;
}
