import { GridProps } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { useDashboardContext, useUserIsAllowed } from '../../../hooks';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import InfoRequestPaymentDatagrid from '../../../Resources/InfoRequestPayment/InfoRequestPaymentDatagrid';
import { PersonRoles } from '../../../utils/constants';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const AppDashboardInfoRequestPayments: FC<GridProps> = (props) => {
  const history = useHistory();
  const { user } = useDashboardContext();

  const allowView = useUserIsAllowed(ResourceAction.Get, 'info-request-payments');

  if (!allowView || user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...props} md={12}>
      <AppDashboardTable
        resource="info-request-payments"
        pagination={{
          page: 1,
          perPage: 12,
        }}
        tableTitle="Ultimi pagamenti servizi"
        tableSubtitle="Di seguito sono elencati gli ultimi 10 pagamenti servizi"
        noItemMessage="Non sono presenti pagamenti servizi"
        noItemIcon={<InfoIcon />}
        CustomDatagrid={
          <InfoRequestPaymentDatagrid
            rowClick={() => {
              history.push('info-request-payments');
            }}
          />
        }
      />
    </AppDashboardCard>
  );
};

export default AppDashboardInfoRequestPayments;
