import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Dialog as MuiDialog,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Cancel, CloudUpload, Edit } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import {
  Children,
  EventHandler,
  FC,
  Fragment,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ArrayInput,
  FileInput,
  FormDataConsumer,
  SimpleFormIterator,
  useCheckMinimumRequiredProps,
  useCreate,
  useNotify,
} from 'react-admin';
import { Form } from 'react-final-form';
import Resizer from 'react-image-file-resizer';
import AppCircularLoader from '../../ui/loaders/AppCircularLoader';

const Dialog = withStyles(() => {
  return {
    paperWidthSm: {
      maxWidth: '45rem',
      minWidth: '45rem',
    },
  };
})(MuiDialog);

const UploadMediaForm: FC<AppUploadProps> = (props) => {
  useCheckMinimumRequiredProps('AppUpload', ['children'], props);

  const {
    // required
    accept,
    // NOT required
    children,
    multiple,
    singleRole,
    minSize,
    maxSize,
    resource,
    //---
    onUpload = noop,
    onClose = noop,
    record = {},
    isOpen = false,
    close = noop,
    mode,
    updateMedia = noop,
  } = props;

  /// region DIALOG MANAGEMENT
  //const openDialogHandler = setOpen.bind(null, true);
  const closeDialogHandler = () => {
    //setOpen(false);
    close();
    onClose({ cancel: true, upload: false });
  };
  const dialogClickHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);
  /// endregion

  /// region BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  /// endregion

  const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
  const [loadingText, setLoadingText] = useState<string>('');

  const [create, { loading: uploading }] = useCreate(resource);
  const notify = useNotify();

  const resizeFile = (file): Promise<Blob> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3840,
        2160,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        1024,
        768,
      );
    });

  const handleUpload = async (values) => {
    const { files } = values;
    if (!files || !files.length) {
      notify('Nessun file selezionato', 'error');
      return;
    }

    setOpenBackdrop(true);

    setLoadingText('Ridimensiono i file...');

    for (let i = 0; i < files.length; i++) {
      if (multiple) files[i].$role = singleRole; // Set default role if bulk upload
      if (files[i].$role === 11) files[i].$raw = await resizeFile(files[i].$raw); // Resize image
    }

    setFilesToUpload(files);
  };

  const uploadFile = (fileToUpload: any): void => {
    create(
      {
        payload: {
          $file: fileToUpload,
        },
      },
      {
        onSuccess: () => {
          const isLastFile = filesToUpload.length === 1;
          const newFilesToUpload = filesToUpload.slice(1);
          setFilesToUpload(newFilesToUpload);
          if (isLastFile) {
            notify('Caricamento eseguito con successo.', 'info');
            onUpload();
            setOpenBackdrop(false);
            closeDialogHandler();
          }
        },
        onFailure: (err) => {
          console.error(err);
          notify(err.message ?? 'Errore di caricamento.', 'error');
          onUpload();
          setOpenBackdrop(false);
        },
      },
    );
  };

  useEffect(() => {
    if (filesToUpload.length > 0) {
      setLoadingText(
        `Carico i file... ${filesToUpload.length} ${
          filesToUpload.length === 1 ? 'rimanente' : 'rimanenti'
        }`,
      );
      uploadFile(filesToUpload[0]);
    } else {
      setLoadingText('Fatto');
    }
  }, [filesToUpload]);

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        onClose={closeDialogHandler}
        onClick={dialogClickHandler}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {mode === 'insert' ? 'Caricamento files' : 'Modifica informazioni del media'}
        </DialogTitle>
        <Form
          initialValues={record}
          resource="media"
          onSubmit={(data) => (mode === 'insert' ? handleUpload(data) : updateMedia(data, true))}
          mutators={arrayMutators as any}
          render={(formProps) => {
            const { submitting, handleSubmit, hasValidationErrors, pristine } = formProps;
            return (
              <Fragment>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12} hidden={mode === 'edit'}>
                      <FileInput
                        source="files"
                        label="Allegati"
                        disabled={uploading || submitting || hasValidationErrors}
                        accept={accept}
                        multiple={multiple}
                        parse={parseFiles}
                        minSize={minSize}
                        maxSize={maxSize}
                        options={{
                          onDropRejected: (files) => {
                            console.log('onDropRejected', files);
                            notify('File rifiutato.', 'error');
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {mode === 'insert' ? (
                      <ArrayInput source="files">
                        <SimpleFormIterator
                          disableAdd
                          TransitionProps={{
                            classNames: {},
                          }}
                        >
                          <FormDataConsumer>
                            {({ formData, getSource }) => (
                              <>
                                {Children.map(
                                  children,
                                  (child) =>
                                    child &&
                                    !child.props.hidden &&
                                    cloneElement(child, {
                                      source: getSource ? getSource(child.props.source) : '',
                                      record: formData,
                                      variant: 'outlined',
                                    }),
                                )}
                              </>
                            )}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    ) : (
                      <Grid container spacing={2}>
                        {Children.map(
                          children,
                          (child) =>
                            child &&
                            cloneElement(child, {
                              source: child.props.source,
                              record: formProps.values,
                              variant: 'outlined',
                            }),
                        )}
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={uploading || submitting}
                    onClick={closeDialogHandler}
                    variant={'outlined'}
                    startIcon={<Cancel />}
                  >
                    Annulla
                  </Button>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <Button
                        disabled={
                          (mode === 'insert' && !formData?.files?.length) ||
                          uploading ||
                          submitting ||
                          hasValidationErrors ||
                          pristine
                        }
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        autoFocus
                        startIcon={mode === 'insert' ? <CloudUpload /> : <Edit />}
                      >
                        {mode === 'insert' ? 'Carica' : 'Applica modifiche'}
                      </Button>
                    )}
                  </FormDataConsumer>
                </DialogActions>
              </Fragment>
            );
          }}
        />
        <AppCircularLoader open={openBackdrop} text={loadingText} />
      </Dialog>
    </Fragment>
  );
};

export default UploadMediaForm;

UploadMediaForm.defaultProps = {
  multiple: false,
  minSize: 3000, //3KB
  maxSize: 20000000, //20MB
} as AppUploadProps;

export interface AppUploadProps {
  accept: string; //if multiple use , as separator. https://marmelab.com/react-admin/Inputs.html#fileinput
  resource: string;
  children?: any;
  multiple?: boolean;
  singleRole?: number;
  minSize?: number;
  maxSize?: number;
  onClose?: EventHandler<any>;
  onUpload?: EventHandler<any>;
  // ---- ?? ----
  classes?: Record<string, any>;
  record: Record<string, any>;
  isOpen: boolean;
  close: () => void;
  mode: 'insert' | 'edit' | null;
  updateMedia: any;
}

function transformFile(file) {
  return !(file instanceof File) ? file : { $raw: file };
}

function parseFiles(files: any) {
  if (!files) return [];
  if (Array.isArray(files)) return files.map(transformFile);
  return [transformFile(files)];
}
