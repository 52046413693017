import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FormDataConsumer } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppColorInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import { AppFileInput } from '../../Components/ui/input/AppFileInput';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { ITEM_GENRES, MAGAZINE_KINDS, MAGAZINE_TEXT_ALIGNMENTS } from '../../utils/constants';
import { blobToBase64 } from '../../utils/files';
import { MAGAZINE_COVER_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    fileInput: {
      marginLeft: theme.spacing(5),
    },
    fileInputPreview: {
      maxWidth: theme.spacing(30),
    },
  }),
  { name: 'MagazineCoverDetails' },
);

const MagazineCoverDetails: FC<any> = (props) => {
  const classes = useStyles();

  const handleFileAndSave = async (record: any, redirect: any, options: any) => {
    if (record.coverFile)
      await blobToBase64(record.coverFile.coverFile).then((result) => {
        record.coverFile = {
          base64: result,
          filename: record.coverFile.filename,
          type: record.coverFile.rawFile.type,
        };
      });

    // Send to API server
    props.save(record, redirect, options);
  };

  return (
    <AppBaseForm
      {...props}
      save={(record, redirect, options) => handleFileAndSave(record, redirect, options)}
      disabledCards={
        !hasViewPermissions(props.permissions?.loginRole, 'create', MAGAZINE_COVER_PERMISSIONS) ||
        props.mode === 'show'
      }
    >
      <AppCard collapsable={false} title="Impostazioni copertina">
        <AppTextInput source="name" label="Nome copertina" required md={4} />
        <AppSelectInput
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <AppNumberInput
                {...rest}
                required
                source="titleX"
                label="Titolo: X"
                md={2}
                defaultValue={
                  props.mode === 'create'
                    ? formData.genre === 'real-estate'
                      ? 35
                      : formData.genre === 'movable'
                      ? 44
                      : undefined
                    : undefined
                }
              />
              <AppNumberInput
                {...rest}
                required
                source="titleY"
                label="Titolo: Y"
                md={2}
                defaultValue={
                  props.mode === 'create'
                    ? formData.genre === 'real-estate'
                      ? 87
                      : formData.genre === 'movable'
                      ? 90
                      : undefined
                    : undefined
                }
              />
              <AppNumberInput
                {...rest}
                required
                source="titleWidth"
                label="Titolo: Larghezza"
                md={2}
                defaultValue={
                  props.mode === 'create'
                    ? formData.genre === 'real-estate'
                      ? 170
                      : formData.genre === 'movable'
                      ? 85
                      : undefined
                    : undefined
                }
              />
              <AppNumberInput
                {...rest}
                required
                source="titleHeight"
                label="Titolo: Altezza"
                md={2}
                defaultValue={
                  props.mode === 'create'
                    ? formData.genre === 'real-estate'
                      ? 8
                      : formData.genre === 'movable'
                      ? 6
                      : undefined
                    : undefined
                }
              />
              <AppNumberInput
                {...rest}
                required
                source="titleFontSize"
                label="Titolo: Dimensione testo"
                md={formData.genre === 'real-estate' ? 2 : 4}
                defaultValue={
                  props.mode === 'create'
                    ? formData.genre === 'real-estate'
                      ? 22
                      : formData.genre === 'movable'
                      ? 16
                      : undefined
                    : undefined
                }
              />
              {formData.genre === 'real-estate' && (
                <AppSelectInput
                  source="titleAlignment"
                  choices={MAGAZINE_TEXT_ALIGNMENTS}
                  label="Allineamento"
                  required
                  md={2}
                  disabled={props.mode === 'show'}
                  defaultValue={
                    props.mode === 'create'
                      ? formData.genre === 'real-estate'
                        ? 'right'
                        : formData.genre === 'movable'
                        ? 'left'
                        : undefined
                      : undefined
                  }
                />
              )}
            </>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.genre === 'movable' && (
              <>
                <AppNumberInput
                  {...rest}
                  required
                  source="subtitleX"
                  label="Sottotitolo: X"
                  md={2}
                  defaultValue={props.mode === 'create' ? 44 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="subtitleY"
                  label="Sottotitolo: Y"
                  md={2}
                  defaultValue={props.mode === 'create' ? 107 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="subtitleWidth"
                  label="Sottotitolo: Larghezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 85 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="subtitleHeight"
                  label="Sottotitolo: Altezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 6 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="subtitleFontSize"
                  label="Sottotitolo: Dimensione testo"
                  md={4}
                  defaultValue={props.mode === 'create' ? 16 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="editionX"
                  label="Edizione: X"
                  md={2}
                  defaultValue={props.mode === 'create' ? 125 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="editionY"
                  label="Edizione: Y"
                  md={2}
                  defaultValue={props.mode === 'create' ? 84 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="editionWidth"
                  label="Edizione: Larghezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 40 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="editionHeight"
                  label="Edizione: Altezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 32 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="editionFontSize"
                  label="Edizione: Dimensione testo"
                  md={4}
                  defaultValue={props.mode === 'create' ? 64 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="yearX"
                  label="Anno: X"
                  md={2}
                  defaultValue={props.mode === 'create' ? 129 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="yearY"
                  label="Anno: Y"
                  md={2}
                  defaultValue={props.mode === 'create' ? 107 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="yearWidth"
                  label="Anno: Larghezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 35 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="yearHeight"
                  label="Anno: Altezza"
                  md={2}
                  defaultValue={props.mode === 'create' ? 6 : undefined}
                />
                <AppNumberInput
                  {...rest}
                  required
                  source="yearFontSize"
                  label="Anno: Dimensione testo"
                  md={4}
                  defaultValue={props.mode === 'create' ? 16 : undefined}
                />
              </>
            )
          }
        </FormDataConsumer>
        <AppColorInput source="titleColor" label="Colore titolo" md={3} />
        <AppFileInput
          md={8}
          source="coverFile"
          accept="image/jpeg,image/png"
          label="Copertina"
          className={classes.fileInput}
          previewLabel="Copertina corrente"
          previewSource="metadata"
          previewType="base64"
          previewClassName={classes.fileInputPreview}
        />
      </AppCard>
    </AppBaseForm>
  );
};

export default MagazineCoverDetails;
