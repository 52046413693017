import { FC } from 'react';
import translatedCountries from '../../../../utils/countries.json';
import { PdfDataLine, PdfSection } from '../utils';
import { PdfSingleSectionProps } from './sections';

export const InfoPresenter: FC<PdfSingleSectionProps> = (props) => {
  const presenter = props.additionalData.presenter;
  return (
    <PdfSection subtitle="INFORMAZIONI PRESENTATORE">
      <PdfDataLine label="Nominativo" value={`${presenter.firstName} ${presenter.lastName}`} />
      <PdfDataLine label="Codice fiscale" value={`${presenter.taxCode}`} />
      <PdfDataLine label="Email" value={`${presenter.email}`} />
      <PdfDataLine
        label="PEC"
        value={presenter.pec && presenter.pec.length > 0 ? presenter.pec : '-'}
      />
      <PdfDataLine
        label="Telefono"
        value={`${
          presenter.phones ? presenter.phones.map((phone) => phone.number).join(', ') : '-'
        }`}
      />
      <PdfDataLine
        label="Cellulare"
        value={`${
          presenter.mobiles ? presenter.mobiles.map((mobile) => mobile.number).join(', ') : '-'
        }`}
      />
      <PdfDataLine
        label="Residenza"
        value={
          presenter.residenceCountry === 'IT'
            ? `${translatedCountries[presenter.residenceCountry]} - ${presenter.residenceCity} (${
                presenter.residenceProvince
              }) ${presenter.residenceZipCode} - ${presenter.residenceAddress}`
            : `${translatedCountries[presenter.residenceCountry]} - ${
                presenter.residenceForeignCity
              } - ${presenter.residenceAddress}`
        }
      />
    </PdfSection>
  );
};
