import Firebase from 'firebase';
import { useEffect, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import { AppChatMessage } from '../Components/chat-widget/chat-widget-types';

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
};

export const useFirebaseApp = (): Firebase.app.App => {
  let app;
  try {
    app = Firebase.app();
  } catch (e) {
    app = Firebase.initializeApp(FIREBASE_CONFIG);
  }
  return app;
};

export const useAuctionHandler = (
  auctionId: number,
  cb: (data: Record<string, any>) => void,
): void => {
  getRef(`auction/${auctionId}`).on('child_added', (snapshot) => cb(snapshot.val()));
};

export const useOnlineSubscriptionHandler = (
  auctionId: number,
  cb: (data: Record<string, any>) => void,
): void => {
  getRef(`online-subscriptions/${auctionId}`).on('child_added', (snapshot) => cb(snapshot.val()));
};

const getRef = (path: string): Firebase.database.Reference => {
  return Firebase.database().ref(path);
};

/*
 Thank you, Flavio ;)
 */

export const useFirebase: any = (
  cb,
  appName: string,
  auth?: string | { url: string; params: any },
) => {
  //console.info('useFireBase', appName, auth);

  const mounted = useRef(new FirebaseManager(appName));
  const dataProvider = useDataProvider();

  useEffect(() => {
    //console.log('useFirebase - Effect', appName);
    if (!auth) return cb(null, mounted.current);

    const authUrl = typeof auth === 'string' ? auth : auth.url;
    const authParams = typeof auth === 'string' ? {} : auth.params;

    dataProvider
      .getOne(authUrl, authParams)
      .then((response) => mounted.current.signIn(response.data.token as string))
      .then(() => cb(null, mounted.current))
      .catch((error) => cb(error));
  }, []);

  return mounted.current;
};

export class FirebaseManager {
  firebase: any;
  private credentials: any;

  constructor(private readonly appName: string) {
    //console.info('FirebaseManager - Constructor', appName);
    try {
      this.firebase = Firebase.app(appName);
      //console.info('FirebaseManager - Constructor - App Found');
    } catch (e) {
      this.firebase = Firebase.initializeApp(FIREBASE_CONFIG, appName);
      //console.info('FirebaseManager - Constructor - App Initialized');
    }
  }

  async signIn(token: string): Promise<void> {
    this.credentials = await this.firebase.auth().signInWithCustomToken(token);
  }

  getRef(path: string): Firebase.database.Reference {
    return this.firebase.database().ref(path);
  }

  observe(
    event: Firebase.database.EventType,
    path: string,
    cb: (data: any, key: string | null) => void,
  ): void {
    this.getRef(path).on(event, (snapshot) => cb(snapshot.val(), snapshot.key));
  }

  push(path: string, message: AppChatMessage): void {
    message.ts = Date.now();
    message.origin = 'M';
    this.getRef(path).push(message);
  }
}
