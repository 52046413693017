import { makeStyles } from '@material-ui/core';
import { ShowProps } from 'ra-ui-materialui/src/types';
import React, { ReactElement } from 'react';
import {
  EditContextProvider,
  EditView,
  useCheckMinimumRequiredProps,
  useEditController,
} from 'react-admin';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import AppEditButton from '../button/AppEditButton';
import AppToolbarActions from '../layout/AppToolbarActions';
//import queryString from 'query-string';

const useStyles = makeStyles(
  {
    card: {
      overflow: 'hidden',
    },
  },
  {
    name: 'AppShow',
  },
);

interface AppShowProps extends ShowProps {
  children: ReactElement;
  resourcePermissions?: ResourcePermissions;
}

export const AppShow = (props: AppShowProps): ReactElement => {
  useCheckMinimumRequiredProps('AppShow', ['children'], props);
  const controllerProps = useEditController(props);
  const appShowClasses = useStyles();
  const { actions, permissions, resource, resourcePermissions, ...rest } = props;
  /*
  const queryStr = props.location ? queryString.parse(props.location.search) : {};
  console.log(queryString.stringify(queryStr));
  console.log(props.basePath);

   */

  /*
  const notify = useNotify();
  const redirect = useRedirect();
  // check if user can access create view
  useEffect(() => {
    if(resourcePermissions && !hasViewPermissions(permissions.loginRole, 'show', resourcePermissions)) {
      redirect('list', props.basePath);
      notify('Accesso alla vista non consentito.', 'error', {}, false, 5000);
    }
  }, []);
   */

  return (
    <EditContextProvider value={controllerProps}>
      <EditView
        {...rest}
        {...controllerProps}
        resource={resource}
        permissions={permissions}
        component="div"
        classes={appShowClasses}
        actions={
          actions ?? (
            <AppToolbarActions
              {...rest}
              children={null}
              buttons={
                // Simple handling since we have only one default button for now
                props.options.noEdit
                  ? []
                  : [
                      // TODO: pass query params (in case we arrive from another resource and want to go back after we pass to "edit" mode)
                      <AppEditButton
                        basePath={props.basePath}
                        color="inherit"
                        label="Passa alla modifica"
                        resourcePermissions={resourcePermissions}
                        permissions={permissions}
                      />,
                    ]
              }
            />
          )
        }
      />
    </EditContextProvider>
  );
};
