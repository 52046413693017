import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppSelectArrayInput, AppTextInput } from '../../../Components/ui/input';
import { CLOTHING_AND_FOOTWEAR_TARGETS } from '../../../utils/constants';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'clothingAndFootwear';

const ClothingAndFootwearDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData } = props;

  if (formData.fkCategory !== 11) return <React.Fragment />;

  return (
    <AppCard title='Informazioni aggiuntive sul bene' disabled={mode === 'show'}>
      <AppSelectArrayInput
        source={`${BASE_SOURCE}.targets`}
        label='Per chi'
        choices={CLOTHING_AND_FOOTWEAR_TARGETS}
        md={4}
      />
      <AppTextInput source={`${BASE_SOURCE}.type`} label='Tipologia' md={4} />
      <AppTextInput source={`${BASE_SOURCE}.brand`} label='Marca' md={4} />
    </AppCard>
  );
};

export default ClothingAndFootwearDetails;
