import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FC, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { PAYMENT_METHODS } from '../../Resources/Payment/constants';

import { useBasePath } from '../../hooks/use-base-path';
import translatedCountries from '../../utils/countries.json';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';
import AppTooltip from '../ui/tooltip/AppTooltip';

type AdditionalDataProps = {
  saleExperiment: any;
  item: any;
  court: any;
  pvpRegister: any;
  pvpRite: any;
  presenter: any;
  bidders: any[];
  amount: any;
  depositAmount: any;
  offeredAt: string;
  payment: any;
  documents: any;
  observerReason?: string;
};

type ObserverPdfProps = {
  additionalData: AdditionalDataProps;
};

const ObserverRowDownloadPdf: FC<any> = (props) => {
  const { record: observerRecord, saleExpRecord } = props;

  const notify = useNotify();

  const basePath = useBasePath();

  // get mutation function for choices changes
  const [mutateAdditionalData] = useMutation();

  const [creatingPdf, setCreatingPdf] = useState(false);

  // Font.register({
  //   family: 'Roboto',
  //   src: Roboto,
  // });

  const styles = StyleSheet.create({
    page: { paddingTop: '24px', paddingBottom: '24px' },
    pageNumber: {
      position: 'absolute',
      bottom: '24px',
      right: '24px',
      fontSize: '8px',
      lineHeight: 0.5,
      textAlign: 'right',
    },
    title: {
      position: 'relative',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'Helvetica',
      fontSize: '18px',
      lineHeight: 1,
      fontWeight: 700,
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    subtitle: {
      position: 'relative',
      marginTop: 24,
      marginBottom: 12,
      width: '100%',
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontSize: '14px',
      lineHeight: 1,
      fontWeight: 700,
      paddingLeft: '44px',
      paddingRight: '44px',
    },
    dataBlock: {},
    dataLine: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 0,
      marginBottom: 1,
      width: '100%',
    },
    dataLabel: {
      width: '30%',
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontSize: '10px',
      lineHeight: 1,
      fontWeight: 700,
      paddingLeft: '44px',
      paddingRight: '10px',
    },
    dataValue: {
      width: '70%',
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontSize: '10px',
      lineHeight: 1,
      fontWeight: 'normal',
      paddingLeft: '10px',
      paddingRight: '44px',
    },
  });

  type DataLineProps = {
    label: string;
    value: string;
  };

  type PdfSectionProps = {
    subtitle: string;
    children: JSX.Element | JSX.Element[];
  };

  type PdfSingleSectionProps = {
    additionalData: AdditionalDataProps;
  };

  const DataLine: FC<DataLineProps> = (props) => (
    <View style={styles.dataLine} wrap={false}>
      <View style={styles.dataLabel}>
        <Text>{props.label}</Text>
      </View>
      <View style={styles.dataValue}>
        <Text>{props.value}</Text>
      </View>
    </View>
  );

  type DataAttachmentProps = {
    doc: DocumentProps;
  };

  type DocumentProps = {
    name: string;
    base64: string;
    mimeType: string;
  };

  const DataAttachment: FC<DataAttachmentProps> = (props) => {
    const { name, base64, mimeType } = props.doc;
    const attachmentSrc = `data:${mimeType};base64,${base64}`;
    return (
      <View key={`${name}`} wrap={false} break>
        <Text style={styles.title}>{name}</Text>
        <Image
          src={attachmentSrc}
          style={{
            height: 700,
            objectFit: 'contain',
          }}
        />
      </View>
    );
  };

  const PdfTitle: FC = () => (
    <View style={styles.title}>
      <Text>RIEPILOGO DATI RICHIESTA DI VISITA</Text>
    </View>
  );

  const PdfSection: FC<PdfSectionProps> = (props) => (
    <View style={styles.dataBlock} wrap={false}>
      <View style={styles.subtitle}>
        <Text>{props.subtitle}</Text>
      </View>
      {props.children}
    </View>
  );

  const PdfSectionInfoProcedure: FC<PdfSingleSectionProps> = (props) => {
    const saleExp = props.additionalData.saleExperiment;
    return (
      <PdfSection subtitle="INFORMAZIONI PROCEDURA">
        <DataLine
          label="Numero / anno"
          value={`${saleExp.item.folder.courtProcedureNumber} / ${saleExp.item.folder.courtProcedureYear}`}
        />
        <DataLine label="Tribunale" value={`${saleExp.item.folder.court.name}`} />
        <DataLine
          label="Tipo procedura"
          value={`${saleExp.item.folder.pvpRegister.description} - ${saleExp.item.folder.pvpRite.description}`}
        />
      </PdfSection>
    );
  };

  const PdfSectionInfoSale: FC<PdfSingleSectionProps> = (props) => {
    return (
      <PdfSection subtitle="INFORMAZIONI VENDITA">
        <DataLine label="ID vendita" value={`${props.additionalData.saleExperiment.id}`} />
        <DataLine label="ID PVP" value={`${props.additionalData.saleExperiment.pvpId ?? '-'}`} />
        <DataLine
          label="Tipo"
          value={`${props.additionalData.saleExperiment.saleMode.description}`}
        />
        <DataLine label="Lotto" value={`${props.additionalData.saleExperiment.item.title}`} />
        <DataLine
          label="Inizio vendita"
          value={`${dateFormatter(props.additionalData.saleExperiment.auctionStartAt, false)}`}
        />
        <DataLine
          label="Termine iscrizioni"
          value={`${dateFormatter(props.additionalData.saleExperiment.bidsEndAt, false)}`}
        />
        <DataLine
          label="Cauzione richiesta"
          value={
            props.additionalData.saleExperiment.paymentMethods
              ? props.additionalData.saleExperiment.paymentMethods
                  .map(
                    (paymentMethod) =>
                      PAYMENT_METHODS.find((_paymentMethod) => _paymentMethod.id === paymentMethod)
                        ?.name,
                  )
                  .join(', ')
              : '-'
          }
        />
        <DataLine
          label="Prezzo base"
          value={`€ ${amountFormatter(props.additionalData.saleExperiment.basePrice, true)}`}
        />
        <DataLine
          label="Offerta minima"
          value={`€ ${amountFormatter(props.additionalData.saleExperiment.minPrice)}`}
        />
      </PdfSection>
    );
  };

  const PdfSectionInfoPresenter: FC<PdfSingleSectionProps> = (props) => {
    const presenter = props.additionalData.presenter;
    return (
      <PdfSection subtitle="INFORMAZIONI VISITATORE">
        <DataLine label="Nominativo" value={`${presenter.firstName} ${presenter.lastName}`} />
        <DataLine label="Codice fiscale" value={`${presenter.taxCode}`} />
        <DataLine label="Email" value={`${presenter.email}`} />
        <DataLine
          label="PEC"
          value={presenter.pec && presenter.pec.length > 0 ? presenter.pec : '-'}
        />
        <DataLine
          label="Telefono"
          value={`${
            presenter.phones ? presenter.phones.map((phone) => phone.number).join(', ') : '-'
          }`}
        />
        <DataLine
          label="Cellulare"
          value={`${
            presenter.mobiles ? presenter.mobiles.map((mobile) => mobile.number).join(', ') : '-'
          }`}
        />
        <DataLine
          label="Residenza"
          value={
            presenter.residenceCountry === 'IT'
              ? `${translatedCountries[presenter.residenceCountry]} - ${presenter.residenceCity} (${
                  presenter.residenceProvince
                }) ${presenter.residenceZipCode} - ${presenter.residenceAddress}`
              : `${translatedCountries[presenter.residenceCountry]} - ${
                  presenter.residenceForeignCity
                } - ${presenter.residenceAddress}`
          }
        />
        <DataLine
          label="Motivazione di richiesta visita"
          value={props.additionalData.observerReason ?? '-'}
        />
      </PdfSection>
    );
  };

  const PdfPageNumber: FC = () => (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );

  const ObserverPdf: FC<ObserverPdfProps> = (props) => {
    const additionalData = props.additionalData;
    return (
      <Document>
        <Page wrap size="A4" style={styles.page}>
          <PdfPageNumber />
          <PdfTitle />
          <PdfSectionInfoProcedure additionalData={additionalData} />
          <PdfSectionInfoSale additionalData={additionalData} />
          <PdfSectionInfoPresenter additionalData={additionalData} />
          {additionalData.documents &&
            additionalData.documents.map((doc) => {
              return <DataAttachment doc={doc} />;
            })}
        </Page>
      </Document>
    );
  };

  const generatePdfDocument = async (saleExpRecord, observerRecord, fileName) => {
    setCreatingPdf(true);

    mutateAdditionalData(
      {
        type: 'getOne',
        resource: `${basePath}/${saleExpRecord.id}/participations-additional-data`,
        payload: {
          id: observerRecord.id,
        },
      },
      {
        onSuccess: (res) => {
          pdf(<ObserverPdf additionalData={res.data} />)
            .toBlob()
            .then((result) => {
              saveAs(result, fileName);
            })
            .catch((e) => console.warn(e))
            .finally(() => setCreatingPdf(false));
        },
        onFailure: (err) => {
          console.error(err);
          notify('Impossibile scaricare PDF osservatore.', 'error');
          setCreatingPdf(false);
        },
      },
    );
  };

  return (
    <>
      <AppTooltip title="Scarica PDF osservatore" arrow>
        <Button
          children={<CloudDownloadIcon />}
          variant="contained"
          color="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            //downloadPDF(observerRecord);
            generatePdfDocument(
              saleExpRecord,
              observerRecord,
              `saleexp-${saleExpRecord.id}-observer-${observerRecord.id}.pdf`,
            );
          }}
          disabled={creatingPdf}
          style={{ lineHeight: 'initial' }}
        />
      </AppTooltip>
      <AppCircularLoader open={creatingPdf} text="Generazione PDF in corso..." />
    </>
  );
};

export default ObserverRowDownloadPdf;
