import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    totalClicksGrid: {
      marginTop: -theme.spacing(1),
    },
    chip: {
      marginTop: theme.spacing(1),
    },
    chipLabel: {
      fontSize: 15,
    },
  }),
  { name: 'TotalClicks' },
);

type TotalClicksProps = {
  infoRequestEnabled: boolean;
  record?: any;
};

export const TotalClicks: FC<TotalClicksProps> = (props) => {
  const classes = useStyles();
  const { record, infoRequestEnabled } = props;
  return (
    <Grid item md={infoRequestEnabled ? 2 : 4} className={classes.totalClicksGrid}>
      <Typography align="center">
        <Box fontWeight="bold">Numero di visite totali effettuate</Box>
        <Box display="block">
          <Chip
            className={classes.chip}
            color="primary"
            label={`${record.totalClicks}`}
            classes={{ label: classes.chipLabel }}
          />
        </Box>
      </Typography>
    </Grid>
  );
};
