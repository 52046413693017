import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import ChatbotImportList from './ChatbotImportList';
import ImportExportIcon from '@material-ui/icons/ImportExport';

export const CHATBOT_IMPORT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const ChatbotImport: ResourceExport = {
  list: ChatbotImportList,
  icon: ImportExportIcon,
};

export default ChatbotImport;
