import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import DeleteIcon from '@material-ui/icons/Delete';

const RemoveBidderButton: FC<any> = (props) => {
  const { record } = props;

  const { values } = useFormState();
  const { change } = useForm();

  const handleRemoveBidder = () => {
    change(
      'bidders',
      values.bidders.filter((bidder) => bidder.id !== record.id),
    );
  };

  return (
    <Button
      variant='outlined'
      children={<DeleteIcon color='error' />}
      onClick={handleRemoveBidder}
      disabled={record.isPresenter}
    />
  );
};

export default RemoveBidderButton;
