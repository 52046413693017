import omit from 'lodash/omit';
import { FC, ReactElement } from 'react';
import { ButtonProps, CreateButton } from 'react-admin';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';

interface AppCreateButtonProps extends ButtonProps {
  basePath?: string;
  icon?: ReactElement;
}

const AppCreateButton: FC<AppCreateButtonProps> = (props) => {
  const { label = 'Crea', variant = 'outlined', color = 'inherit', disabled, ...rest } = props;

  const allowCreate = useUserIsAllowed(ResourceAction.Post, props.resource);

  return (
    <CreateButton
      {...omit(rest, ['title', 'history', 'location', 'match', 'options', 'filter', 'filters'])}
      color={color}
      label={label}
      variant={variant}
      disabled={disabled || !allowCreate}
    />
  );
};

export default AppCreateButton;
