import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import 'moment/locale/it';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { AppAutocompleteInput, AppTextInput } from '../../Components/ui/input';
import { padString } from '../../utils/data-formatters';

const AuctionRoomBookingCalendarAddEventModal: FC<any> = (props) => {
  const [bookingPerson, setBookingPerson] = useState<number | null>(props.bookingPerson ?? null);
  const [bookingNotes, setBookingNotes] = useState<string>('');

  const bookingDate = useMemo(
    () =>
      props.bookingRange && props.bookingRange.from
        ? `${padString(props.bookingRange.from.getDate().toString())}/${padString(
            (props.bookingRange.from.getMonth() + 1).toString(),
          )}/${props.bookingRange.from.getFullYear()}`
        : '',
    [props.bookingRange],
  );
  const bookingFrom = useMemo(
    () =>
      props.bookingRange && props.bookingRange.from
        ? `${padString(props.bookingRange.from.getHours().toString())}:${padString(
            props.bookingRange.from.getMinutes().toString(),
          )}`
        : '',
    [props.bookingRange],
  );
  const bookingTo = useMemo(
    () =>
      props.bookingRange && props.bookingRange.to
        ? `${padString(props.bookingRange.to.getHours().toString())}:${padString(
            props.bookingRange.to.getMinutes().toString(),
          )}`
        : '',
    [props.bookingRange],
  );

  return (
    <Fragment>
      <Dialog
        open={props.isOpen}
        onClose={props.dialogCloseHandler}
        onClick={props.dialogClickHandler}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Richiesta di prenotazione sala d'asta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Riepilogo dei dati della richiesta di prenotazione:
            <p>
              Sala d'asta: <b>{props.currentAuctionRoom?.title}</b>
              <br />
              Data: <b>{bookingDate}</b>
              <br />
              Dalle: <b>{bookingFrom}</b>
              <br />
              Alle: <b>{bookingTo}</b>
            </p>
          </DialogContentText>
          <Form
            onSubmit={() =>
              props.handlePost(
                'sent',
                props.bookingRange.from,
                props.bookingRange.to,
                props.currentAuctionRoom.id,
                bookingPerson,
                bookingNotes,
              )
            }
            render={(formProps) => {
              const { submitting, handleSubmit, pristine } = formProps;
              return (
                <Grid container spacing={2}>
                  <AppTextInput
                    source={'notes'}
                    label="Note"
                    md={12}
                    defaultValue={bookingNotes}
                    onChange={(notes) => setBookingNotes(notes.target.value)}
                  />
                  {!props.isAuctioneer && (
                    <AppAutocompleteInput
                      source="fkPerson"
                      reference={'people'}
                      label="Persona"
                      optionText={(selectedPerson) => {
                        if (!selectedPerson) return '';
                        return `${selectedPerson.firstName} ${selectedPerson.lastName}`;
                      }}
                      filter={
                        {
                          _fulltextFields: ['firstName', 'lastName'],
                          role: 17,
                        } as any
                      }
                      disabled={props.mode === 'show'}
                      md={12}
                      onChange={setBookingPerson}
                    />
                  )}
                  <Grid item md={6}>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      disabled={submitting || pristine}
                    >
                      Invia richiesta
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button onClick={props.dialogCloseHandler} color="primary" autoFocus>
                      Annulla
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AuctionRoomBookingCalendarAddEventModal;
