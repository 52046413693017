import { GridProps, makeStyles } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { FC } from 'react';
import { FunctionField } from 'react-admin';
import { useHistory } from 'react-router';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  (theme) => ({
    saleExperimentInsertionId: {
      minWidth: theme.spacing(20),
    },
    procedure: {
      minWidth: theme.spacing(15),
    },
    title: {
      width: '100%',
    },
  }),
  { name: 'AppDashboardPvp' },
);

const AppDashboardPvp: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const classes = useStyles();

  const allowView = useUserIsAllowed(ResourceAction.Get, 'pvp-publication-requests');

  if (!allowView) return <></>;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="pvp-publication-requests"
        tableTitle="Ultime richieste dal PVP"
        tableSubtitle="Di seguito sono elencate le richieste dal PVP arrivate nelle ultime 24 ore e non ancora processate."
        dataError="Non è stato possibile recuperare le richieste."
        noItemMessage="Nessuna nuova richiesta."
        noItemIcon={<DoneAllIcon />}
        rowClick={() => history.push('pvp-publication-requests')}
        filter={{
          latest: true,
        }}
      >
        <AppTextField
          source="saleExperimentInsertionId"
          label="N° inserzione"
          headerClassName={classes.saleExperimentInsertionId}
        />
        <AppTextField source="genre" label="Genere" />
        <FunctionField
          label="Procedura"
          render={(record) => `${record.procedureNumber}/${record.procedureYear}`}
          headerClassName={classes.procedure}
        />
        <AppTextField
          source="title"
          label="Titolo"
          truncate
          maxTextLength={40}
          headerClassName={classes.title}
        />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardPvp;
