import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Delete, ErrorOutline } from '@material-ui/icons';
import React, { FC, Fragment, useContext, useState } from 'react';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
    },
    dialogPaper: {
      maxWidth: 'none',
      width: theme.spacing(60),
      height: theme.spacing(23),
    },
  }),
  { name: 'DeleteMediaButton' },
);

const DeleteMediaButton: FC<any> = (props) => {
  const { disabled, record } = props;
  const classes = useStyles();

  const ctx = useContext(AppRelatedResourceContext);
  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = () => {
    ctx.manager.remove(record, true);
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        children='Cancella'
        startIcon={<Delete />}
        disabled={disabled}
        onClick={() => setOpen(true)}
        className={classes.deleteButton}
      />
      <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Eliminazione allegato</DialogTitle>
        <DialogContent>
          <DialogContentText>Sei sicuro di voler procedere con la cancellazione?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <ErrorOutline />
            Annulla
          </Button>
          <Button onClick={handleDelete}>
            <CheckCircle />
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeleteMediaButton;
