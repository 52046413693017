import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, cloneElement } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'RequestButton' },
);

interface RequestButtonProps extends ButtonProps {
  CustomButton?: JSX.Element;
}

const RequestButton: FC<RequestButtonProps> = ({
  disabled,
  onClick,
  color = 'primary',
  children,
  variant = 'outlined',
  className,
  CustomButton,
  startIcon,
}) => {
  const classes = useStyles();
  return CustomButton ? (
    cloneElement(CustomButton, { className: classes.button })
  ) : (
    <Button
      color={color}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      children={children}
      className={className ?? classes.button}
      startIcon={startIcon}
    />
  );
};

export default RequestButton;
