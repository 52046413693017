import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import {
  BooleanField,
  DeleteWithConfirmButton,
  NumberField,
  SelectField,
  TextField,
} from 'react-admin';
import AppMediaRoleAction from '../../Components/media-role/app-media-role-action';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppBooleanInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';

const useStyles = makeStyles(
  {
    otherColumns: {
      width: '15%',
    },
    lastColumn: {
      width: '100%',
    },
  },
  { name: 'MediaRolesDetails' },
);

const MediaRolesDetails = (props: Record<any, any>): ReactElement => {
  const classes = useStyles();
  return (
    <AppTabbedForm {...props}>
      <AppFormTab label="Informazioni sul ruolo" disabledCards={props.mode === 'show'}>
        <AppCard expanded>
          <AppTextInput source="name" required label="Nome" />
          <AppSelectInput
            source="type"
            choices={[
              { id: 'image', name: 'Immagine' },
              { id: 'video', name: 'Video' },
              { id: 'virtual-tour', name: 'Tour Virtuale' },
              { id: 'document', name: 'Documento' },
            ]}
            label="Tipo"
            required
          />
          <AppBooleanInput source="canBeMultiple" required label="Accetta file multipli" />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Campi aggiuntivi" path="additional-fields">
        <AppCard
          expanded
          hideContent={props.mode === 'create'}
          title="Gestione campi aggiuntivi del ruolo media"
        >
          <AppRelatedResource
            defaultManagerMode="insert"
            relatedResource="/mediaRoleAdditionalFields"
            actions={[<AppMediaRoleAction />]}
          >
            <AppDatagrid>
              <TextField source="label" label="Etichetta" headerClassName={classes.otherColumns} />
              <TextField
                source="fieldName"
                label="Nome campo"
                headerClassName={classes.otherColumns}
              />
              <SelectField
                source="type"
                choices={[
                  { id: 'number', name: 'Numero' },
                  { id: 'date', name: 'Data' },
                  { id: 'amount', name: 'Importo' },
                  { id: 'text', name: 'Testo' },
                  { id: 'registry', name: 'Anagrafica' },
                ]}
                label="Tipo"
              />
              <NumberField source="order" label="Ordine" headerClassName={classes.otherColumns} />
              <BooleanField
                source="mandatory"
                label="Obbligatorio"
                headerClassName={classes.lastColumn}
              />
              <DeleteWithConfirmButton
                redirect={false}
                confirmTitle="Eliminazione campo aggiuntivo"
                confirmContent="Sei sicuro di voler procedere con la cancellazione?"
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default MediaRolesDetails;
