import { makeStyles } from '@material-ui/core';
import { FunctionField } from 'ra-ui-materialui';
import { FC } from 'react';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import { AppDateField, AppLinkToEntityField, AppTextField } from '../../Components/ui/field';
import { COMPLAINT_COLORS, COMPLAINT_STATUS_CHOICES, COMPLAINT_TYPE_CHOICES } from './constants';

const useStyles = makeStyles(
  () => ({
    type: { whiteSpace: 'nowrap' },
    status: { whiteSpace: 'nowrap' },
    itemTitle: { whiteSpace: 'nowrap' },
  }),
  { name: 'ComplaintDatagrid' },
);

const ComplaintDatagrid: FC<any> = (props) => {
  const { rowClick, ...rest } = props;
  const classes = useStyles();

  return (
    <AppDatagrid
      {...rest}
      rowClick={rowClick}
      rowStyle={(record) => ({
        backgroundColor: COMPLAINT_COLORS[record.status][0],
      })}
    >
      <AppTextField source="id" label="Identificativo univoco" />
      <AppDateField source="createdAt" label="Data/ora apertura" showTime />
      <AppDateField source="updatedAt" label="Data/ora ultimo update" showTime />
      <AppDateField source="closedDate" label="Data/ora chiusura" showTime />
      <FunctionField
        label="Tipo"
        render={(record) => (
          <AppTextField
            customText={
              record ? COMPLAINT_TYPE_CHOICES.find((type) => type.id === record.type)?.name : '-'
            }
            style={{
              color: COMPLAINT_COLORS[record?.status][1],
            }}
          />
        )}
        headerClassName={classes.type}
      />
      <FunctionField
        label="Stato"
        render={(record) => (
          <AppTextField
            customText={
              record
                ? COMPLAINT_STATUS_CHOICES.find((status) => status.id === record.status)?.name
                : '-'
            }
            style={{
              color: COMPLAINT_COLORS[record?.status][1],
            }}
          />
        )}
        headerClassName={classes.type}
      />
      <FunctionField
        label="Lotto"
        render={(record) => {
          return record && record.itemTitle && record.itemId ? (
            <AppLinkToEntityField
              label="Lotto"
              targetResource="items"
              originName="complaints"
              value={record?.itemTitle}
              targetResourceId={record?.itemId}
              truncate
              headerClassName={classes.itemTitle}
              style={{
                color: COMPLAINT_COLORS[record?.status][1],
              }}
            />
          ) : (
            <>-</>
          );
        }}
        headerClassName={classes.type}
      />
      <AppShowButton variant="contained" color="default" />
      <AppEditButton variant="contained" color="default" />
    </AppDatagrid>
  );
};

export default ComplaintDatagrid;
