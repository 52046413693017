import { InputAdornment } from '@material-ui/core';
import { FC, Fragment, useMemo } from 'react';
import { LegalCharges } from '../../../Components/sale-experiment/legal-charges/legal-charges';
import AppAlert from '../../../Components/ui/alert/AppAlert';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppAmountCalculator,
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateTimeInput,
  AppInputFiller,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import AppDateCalculator from '../../../Components/ui/input/AppDateCalculator';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import { ItemGenre, OBSERVERS_OPTIONS, SaleMode } from '../../../utils/constants';
import { folderIsJudiciary } from '../../../utils/folder';
import { isDetailComponentVisible } from '../../../utils/sale-experiment';
import { maxDate, minDate } from '../../../utils/validators';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const WHERE_IS_VISIBLE = [
  SaleMode.AtSeller,
  SaleMode.SyncOnline,
  SaleMode.SyncMixed,
  SaleMode.AsyncOnline,
  SaleMode.WithAuction,
];

export const AuctionInfo: FC<any> = ({ formData, mode, record, selectedItem, cannotModify }) => {
  const { fkSaleMode, hasFee, paymentMethods = [] } = formData;

  const isVisible = useMemo(
    () => isDetailComponentVisible(fkSaleMode, WHERE_IS_VISIBLE),
    [fkSaleMode],
  );

  const { relatedFolder } = selectedItem;
  const isJudiciary = useMemo(() => folderIsJudiciary(relatedFolder), [relatedFolder]);

  return isVisible ? (
    <Fragment>
      <AppCard title="Dati relativi alla vendita" disabled={mode === 'show'}>
        <AppAutocompleteInput
          label="Tipo di vendita"
          source="fkSaleType"
          reference="sale-types"
          optionText="description"
          md={4}
        />
        <AppTextInput source="saleAddress" label="Luogo della vendita" md={12} />
        <AppTextInput source="description" label="Descrizione" md={12} multiline expandable />
        <AppAmountCalculator
          source="minRaise"
          label="Rilancio minimo"
          refToSource="minRaiseRefTo"
          refToExternalChoices={[{ id: 'minRaisePercentage', name: 'Offerta più alta' }]}
          refToDefaultValue="no-ref"
          required={fkSaleMode !== SaleMode.WithAuction}
          disabled={cannotModify}
        />
        <AppBooleanInput
          label="Rilancio minimo anche su prima offerta"
          source="minRaiseOnFirstOffer"
          md={4}
        />
        {fkSaleMode !== SaleMode.AsyncOnline && (
          <AppNumberInput
            label="Tempo di rilancio"
            source="raiseTimeSec"
            md={2}
            required={fkSaleMode !== SaleMode.WithAuction}
            variant="outlined"
            InputProps={{ endAdornment: <InputAdornment position="end">Sec</InputAdornment> }}
          />
        )}
        {fkSaleMode === SaleMode.AsyncOnline && [
          <AppNumberInput
            variant="outlined"
            label="Tempo di estensione asta"
            source="extensionTimeMin"
            md={4}
            InputProps={{ endAdornment: <InputAdornment position="end">Min</InputAdornment> }}
            helperText="Il valore predefinito è 3 minuti"
          />,
          <AppNumberInput
            variant="outlined"
            label="Numero massimo di prolungamenti"
            source="maxExtensionsCount"
            md={4}
            helperText="Lascia il campo vuoto per avere infiniti prolungamenti"
          />,
        ]}
        <AppDivider />
        <LegalCharges
          mode={mode}
          record={record}
          selectedItem={selectedItem}
          saleMode={fkSaleMode}
        />
        {isJudiciary && [
          <AppDivider />,
          <AppSectionTitle>Valori PVP</AppSectionTitle>,
          <AppNumberInput source="expenses" label="Spese" isAmount md={2} />,
          <AppNumberInput source="balance" label="Compensi" isAmount md={2} />,
          <AppDivider />,
          <AppBooleanInput
            source="a107"
            label="Art.107 LF"
            md={2}
            initialValue={
              relatedFolder &&
              relatedFolder.fkPvpRite &&
              (IS_IVG
                ? relatedFolder.fkPvpRite >= 11 && relatedFolder.fkPvpRite <= 14
                : relatedFolder.fkPvpRite > 7 && relatedFolder.fkPvpRite < 16)
            } // TODO: enum for rites/registers
          />,
          formData.a107 ? (
            [
              <AppNumberInput
                source="a107Days"
                label="Giorni di validità art.107"
                helperText="Se non specificato verrà utilizzato il valore impostato nella configurazione CMS"
                md={4}
              />,
              <AppBooleanInput
                label="Mostra data termine validità art.107 sul sito"
                source="showA107ExpirationDate"
                md={4}
              />,
            ]
          ) : (
            <></>
          ),
        ]}
      </AppCard>
      <AppCard title="Dati relativi all'esperimento di vendita" disabled={mode === 'show'}>
        <AppSectionTitle>Prezzi</AppSectionTitle>
        <AppNumberInput
          label="Prezzo base"
          source="basePrice"
          inputMode="decimal"
          xs={6}
          md={3}
          required
          isAmount
          disabled={cannotModify}
        />
        <AppInputFiller md={9} />
        {isJudiciary && (
          <AppAmountCalculator
            source="minPrice"
            label="Prezzo minimo"
            refToSource="minPriceRefTo"
            refToChoices={[{ id: 'basePrice', name: 'Prezzo base' }]}
            required={selectedItem.fkGenre === ItemGenre.RealEstate}
            mode={mode}
            record={record}
            disabled={cannotModify}
          />
        )}
        {!isJudiciary && [
          <AppNumberInput
            source="reservePrice"
            label="Prezzo di riserva"
            isAmount
            md={3}
            disabled={cannotModify}
          />,
          <AppBooleanInput
            source="showReservePriceReached"
            label="Mostra avviso quando raggiunto"
            md={4}
          />,
          <AppBooleanInput
            source="showReservePriceOwnerPanel"
            label="Mostra il pannello proprietario"
            md={3}
          />,
        ]}
        <AppDivider />
        <AppSectionTitle>Date asta</AppSectionTitle>
        <AppDateTimeInput
          label="Inizio asta"
          source="auctionStartAt"
          md={3}
          required
          validate={[maxDate('auctionEndAt', 'Termine asta')]}
          disabled={cannotModify}
        />
        {fkSaleMode === SaleMode.AsyncOnline && [
          <AppInputFiller md={9} />,
          <AppDateCalculator
            refToSource="auctionEndAtRefTo"
            hasTime={true}
            source="auctionEndAt"
            label="Termine asta"
            required
            refToChoices={[{ id: 'auctionStartAt', name: 'Inizio asta' }]}
            record={record}
            mode={mode}
            disabled={cannotModify}
          />,
          <AppInputFiller md={1} />,
        ]}
        <AppDivider />
        <AppSectionTitle>Date offerte</AppSectionTitle>
        {fkSaleMode !== SaleMode.SyncMixed && (
          <AppDateTimeInput
            label="Data inizio ricezione offerte"
            source="bidsStartAt"
            md={3}
            validate={[
              maxDate('bidsEndAt', 'Data fine ricezione offerte'),
              maxDate('bidsCreditCardEndAt', 'Data fine ricezione offerte (con carta)'),
              maxDate('bidsWireTransferEndAt', 'Data fine ricezione offerte (con bonifico)'),
              maxDate('bidsCashierCheckEndAt', 'Data fine ricezione offerte (con assegno)'),
              maxDate('bidsOtherEndAt', 'Data fine ricezione offerte (con altro)'),
            ]}
            required={fkSaleMode !== SaleMode.AtSeller}
            disabled={cannotModify}
          />
        )}
        {!(
          fkSaleMode === SaleMode.AsyncOnline &&
          selectedItem.fkGenre !== ItemGenre.RealEstate &&
          hasFee
        ) ? (
          [
            <AppInputFiller md={12} />,
            <AppDateCalculator
              refToSource="bidsEndAtRefTo"
              hasTime={true}
              source="bidsEndAt"
              label="Data fine ricezione offerte"
              refToChoices={[{ id: 'auctionStartAt', name: 'Inizio asta' }]}
              record={record}
              mode={mode}
              validate={
                fkSaleMode === SaleMode.AsyncOnline && selectedItem.fkGenre !== ItemGenre.RealEstate
                  ? []
                  : [maxDate('auctionStartAt', 'Inizio asta')]
              }
              disabled={cannotModify}
              required
            />,
          ]
        ) : (
          <Fragment>
            {!paymentMethods.length && (
              <AppAlert severity="warning" title="Nessun metodo di pagamento scelto">
                La cauzione è richiesta ma nessun metodo di pagamento è stato scelto,
                <strong> provvedere a selezionare almeno un metodo di pagamento</strong> per
                abilitare la scelta della <strong>data di fine ricezione offerte</strong>
              </AppAlert>
            )}
            {paymentMethods.includes('credit-card') && [
              <AppInputFiller md={12} />,
              <AppDateCalculator
                refToSource="bidsCreditCardEndAtRefTo"
                hasTime={true}
                source="bidsCreditCardEndAt"
                label="Data fine ricezione offerte (con carta)"
                refToChoices={[{ id: 'bidsStartAt', name: 'Data inizio ricezione offerte' }]}
                record={record}
                mode={mode}
                required
                disabled={cannotModify}
              />,
            ]}
            {paymentMethods.includes('wire-transfer') && [
              <AppInputFiller md={12} />,
              <AppDateCalculator
                refToSource="bidsWireTransferEndAtRefTo"
                hasTime={true}
                source="bidsWireTransferEndAt"
                label="Data fine ricezione offerte (con bonifico)"
                refToChoices={[{ id: 'bidsStartAt', name: 'Data inizio ricezione offerte' }]}
                record={record}
                mode={mode}
                required
                disabled={cannotModify}
              />,
            ]}
            {paymentMethods.includes('check') && [
              <AppInputFiller md={12} />,
              <AppDateCalculator
                refToSource="bidsCashierCheckEndAtRefTo"
                hasTime={true}
                source="bidsCashierCheckEndAt"
                label="Data fine ricezione offerte (con assegno)"
                refToChoices={[{ id: 'bidsStartAt', name: 'Data inizio ricezione offerte' }]}
                record={record}
                mode={mode}
                required
                disabled={cannotModify}
              />,
            ]}
            {paymentMethods.includes('other') && [
              <AppInputFiller md={12} />,
              <AppDateCalculator
                refToSource="bidsOtherEndAtRefTo"
                hasTime={true}
                source="bidsOtherEndAt"
                label="Data fine ricezione offerte (con altro)"
                refToChoices={[{ id: 'bidsStartAt', name: 'Data inizio ricezione offerte' }]}
                record={record}
                mode={mode}
                required
                disabled={cannotModify}
              />,
            ]}
          </Fragment>
        )}
        <AppDivider />
        <AppSectionTitle>Saldo</AppSectionTitle>
        {selectedItem.fkGenre === ItemGenre.RealEstate && (
          <AppBooleanInput
            source="disableBalance"
            label="Disabilita la possibilità di saldare l'aggiudicazione sulla piattaforma"
            md={12}
          />
        )}
        <AppDateCalculator
          refToSource="balanceDueDateRefTo"
          hasTime={true}
          source="balanceDueDate"
          label="Data ultima per il saldo"
          refToChoices={
            fkSaleMode === SaleMode.AsyncOnline
              ? [{ id: 'auctionEndAt', name: 'Termine asta' }]
              : [{ id: 'auctionStartAt', name: 'Inizio asta' }]
          }
          validate={[minDate('auctionStartAt', 'Data inizio asta')]}
          record={record}
          mode={mode}
        />
        {fkSaleMode === SaleMode.SyncOnline && (
          <AppDateTimeInput label="Termine pagamento cauzione" source="depositEndAt" md={3} />
        )}
        <AppDivider />
        <AppSectionTitle>Altre opzioni</AppSectionTitle>
        <AppSelectInput
          source="observersOption"
          choices={OBSERVERS_OPTIONS}
          label="Impostazione per gli osservatori"
          required
          md={4}
          defaultValue="open-auction"
        />
        <AppBooleanInput
          source="allowOnlineSubscriptions"
          label="Abilita iscrizioni online"
          initialValue={true}
        />
      </AppCard>
    </Fragment>
  ) : (
    <Fragment />
  );
};
