import { makeStyles } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { List, ListProps } from 'react-admin';

import AppCreateButton from '../button/AppCreateButton';
import AppToolbarActions from '../layout/AppToolbarActions';
import AppListTopToolbar from '../toolbar/List/AppListTopToolbar';
import AppPaginationToolbar from '../toolbar/List/AppPaginationToolbar';
import { PropsWithRequiredChildren } from '../types';

const useStyles = makeStyles(
  (theme) => {
    const rootHeight = 'calc(100vh - 4rem)';
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(2),
        maxHeight: rootHeight,
        minHeight: 0,
        overflow: 'hidden',
      },
      main: {
        flex: '1 1 100%',
        minHeight: 0,
      },
      content: {
        overflow: 'hidden',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
      },
    };
  },
  { name: 'AppList' },
);

const AppList: FC<
  PropsWithRequiredChildren<
    ListProps & {
      hideCreateButton?: boolean;
      ToolbarActions?: ReactElement;
    }
  >
> = (props): ReactElement => {
  const classes = useStyles(props);
  const { children, hideCreateButton, ToolbarActions, sort, bulkActionButtons, ...rest } = props;

  const toolbarButtons: ReactElement[] = [];
  if (!hideCreateButton) toolbarButtons.push(<AppCreateButton />);

  return (
    <React.Fragment>
      <AppToolbarActions {...rest} children={ToolbarActions} buttons={toolbarButtons} />
      <List
        perPage={20}
        pagination={<AppPaginationToolbar />}
        actions={<AppListTopToolbar />}
        {...rest}
        children={children}
        classes={classes}
        className="MuiPaper-elevation1 MuiPaper-rounded"
        empty={false}
        bulkActionButtons={bulkActionButtons ?? false}
        sort={
          sort ?? {
            field: 'id',
            order: 'DESC',
          }
        }
      />
    </React.Fragment>
  );
};

export default AppList;
