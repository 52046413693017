import React, { FC } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppSelectInput } from '../../Components/ui/input';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { MONTHS } from '../../utils/constants';
import { minValue } from '../../utils/validators';
import { PVP_ASSIGNMENT_PERMISSIONS } from './index';

const PvpAssignmentDetails: FC<any> = (props) => {
  const now = new Date();
  // calculate year:
  //  - if current month is january -> last year
  //  - if current month is after january -> current year
  const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();

  // build months choices:
  //  - if current month is january --> take all months (I can choose any month from previous year)
  //  - if not -> take all months before current month
  const monthsChoices = MONTHS.filter(
    (month, index) => now.getMonth() === 0 || index < now.getMonth(),
  ).map((month, index) => ({
    id: index,
    name: month,
  }));

  return (
    <AppBaseForm
      {...props}
      disabledCards={
        !hasViewPermissions(props.permissions.loginRole, 'create', PVP_ASSIGNMENT_PERMISSIONS)
      }
    >
      <AppCard title="Configurazione registro incarichi" collapsable={false}>
        <AppSelectInput source="monthFrom" label="Incarichi da" choices={monthsChoices} required />
        <AppSelectInput
          source="yearFrom"
          label="del"
          choices={[{ id: year, name: year }]}
          defaultValue={year}
          disabled
        />
        <AppSelectInput
          source="monthTo"
          label="Incarichi a (incluso)"
          choices={monthsChoices}
          required
          validate={[minValue('monthFrom', undefined, false, true, monthsChoices)]}
        />
        <AppSelectInput
          source="yearTo"
          label="del"
          choices={[{ id: year, name: year }]}
          defaultValue={year}
          disabled
        />
      </AppCard>
    </AppBaseForm>
  );
};

export default PvpAssignmentDetails;
