import { Group } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import RoleDetails from './RoleDetails';
import RoleList from './RoleList';

const { Auctioneer } = PersonRoles;

export const ROLE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const AssignRolesResource: ResourceExport = {
  list: RoleList,
  edit: resourceWrapper('edit', RoleDetails, undefined, false, ROLE_PERMISSIONS),
  show: resourceWrapper('show', RoleDetails, undefined, undefined, ROLE_PERMISSIONS), //FIXME: mode is not passed properly to the AppBottomToolbar
  icon: Group,
};

export default AssignRolesResource;
