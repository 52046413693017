import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useResourceContext } from 'react-admin';
import { useFirebase } from '../../utils/firebase';
import { AppChatMessage, AppChatParticipant, AppChatWidgetData } from './chat-widget-types';

export const ChatWidgetContext = React.createContext<AppChatWidgetData>({} as AppChatWidgetData);

const ChatWidgetProvider: FC<ChatWidgetProviderProps> = ({
  auctionId,
  auctionStatus,
  children,
}) => {
  const [participants, setParticipants] = useState<AppChatParticipant[]>([]);

  const [directMessages, setDirectMessages] = useState<Record<string, any>>({});
  useEffect(() => {
    console.log('directMessages', directMessages);
  }, [directMessages]);
  const [directMessagesUnread, setDirectMessagesUnread] = useState({});

  const [globalMessages, setGlobalMessages] = useState<Record<string, any>[]>([]);
  useEffect(() => {
    console.log('globalMessages', globalMessages);
  }, [globalMessages]);
  const [globalMessagesUnread, setGlobalMessagesUnread] = useState(0);

  const [onlineTS, setOnlineTS] = useState({});
  const [onlineStatuses, setStatus] = useState({});

  const [activeChat, setActiveChat] = useState('');
  const [chatError, setError] = useState({});

  const intervalRef = useRef<number>();

  const resource = useResourceContext();

  const setCurrentChat = (chatId) => {
    setActiveChat(`p${chatId}`);
  };

  const sendMessage = useCallback(
    (participationId: string, message: Partial<AppChatMessage>) => {
      const path =
        `chats/${auctionId}/` +
        (participationId === '0' ? 'messages' : `direct/p${participationId}`);
      firebase.push(path, { ...message, participationId: 'M' });
    },
    [auctionId],
  );

  const setAsRead = useCallback(
    (participationId: string, message: Record<string, any>, kind: 'private' | 'global') => {
      if (kind === 'private') {
        if (participationId === '0' || message.read || message.origin === 'M') return;
        setTimeout(() => {
          //timeout to keep bold for a small time before update
          firebase
            .getRef(`chats/${auctionId}/direct/p${participationId}/${message.$msgId}`)
            .update({ read: true });
          // TODO: should be better to update the directMessages and directMessagesUnread states and remove firebase child_changed listener (row 221) as for global messages
        }, 200);
      } else {
        if (message['M'] || message.origin === 'M') return;
        setTimeout(() => {
          //timeout to keep bold for a small time before update
          if (message.$msgId) {
            firebase.getRef(`chats/${auctionId}/messages/${message.$msgId}`).update({ M: true });
            setGlobalMessages((prev) => {
              const index = prev.findIndex((m) => m.$msgId === message.$msgId);
              if (index === -1) return prev;
              prev[index].M = true;
              return [...prev];
            });

            setGlobalMessagesUnread((prev) => prev - 1);
          }
        }, 200);
      }
    },
    [auctionId],
  );

  const setAsBanned = useCallback(
    (participationId: number) => {
      if (!participationId || participationId === 0) return;
      setTimeout(() => {
        //timeout to keep bold for a small time before update
        firebase
          .getRef(`chats/${auctionId}/participants/p${participationId}`)
          .update({ banned: true });
      }, 200);
    },
    [auctionId],
  );

  useEffect(() => {
    const statuses = {};
    Object.keys(onlineTS).forEach((k) => (statuses[k] = onlineTS[k] > Date.now() - 15 * 1000));
    setStatus(statuses);
  }, [onlineTS]);

  const handleGlobalMessages = (data: Record<string, any>, key: string | null) => {
    // data = data || {};
    // try {
    //   Object.keys(data).forEach((k) => (data[k].$msgId = k));
    // } catch (e) {
    //   console.log('error', e);
    // }
    // setGlobalMessages(Object.values(data));
    setGlobalMessages((prev) => [...prev, { ...data, $msgId: key }]);

    // let unread = 0;
    // Object.values(data).forEach((message) => {
    //   if (message.origin === 'P' && !message['M']) unread++;
    // });
    // setGlobalUnread({ all: unread });
    if (data.origin === 'P' && !data['M']) {
      setGlobalMessagesUnread((prev) => prev + 1);
    }
  };

  const handleDirectMessages = (data: Record<string, any>, participantId: string | null) => {
    // snapshot = snapshot || []; // set as array to prevent error with forEach
    // const result = {};
    // const unread = {};
    // snapshot.forEach((participant) => {
    //   const messages = participant.val();
    //   if (!messages) return (result[participant.key] = []);
    //   try {
    //     Object.keys(messages).forEach((k) => (messages[k].$msgId = k));
    //   } catch (e) {
    //     console.log('error', e);
    //   }
    //   result[participant.key] = Object.values(messages);
    //   unread[participant.key] = result[participant.key].reduce((a, c) => {
    //     return c.origin === 'P' && !c.read ? a + 1 : a;
    //   }, 0);
    // });
    // setDirectMessages(result);
    // setPrivateUnread(unread);
    if (!participantId) return;

    for (const directMessageKey in data) {
      if (!Object.prototype.hasOwnProperty.call(data, directMessageKey)) {
        continue;
      }
      data[directMessageKey].$msgId = directMessageKey;
    }

    setDirectMessages((prev) => ({
      ...prev,
      [participantId]: Object.values(data),
    }));
    setDirectMessagesUnread((prev) => ({
      ...prev,
      [participantId]: Object.values(data).reduce((a, c) => {
        return c.origin === 'P' && !c.read ? a + 1 : a;
      }, 0),
    }));
  };

  const handleParticipants = (data) => {
    if (!data) return;

    setParticipants((prev) => {
      const index = prev.findIndex((p) => p.participationId === data.participationId);
      if (index === -1) {
        return [...prev, data];
      } else {
        prev[index] = data;
        return [...prev];
      }
    });
  };

  const firebase = useFirebase(
    (err) => {
      if (auctionStatus === 'inactive') return;
      if (err) return setError(err);

      intervalRef.current = window.setInterval(() => {
        firebase.getRef(`chats/${auctionId}/online/manager`).set(Date.now());
      }, 5000);

      // firebase.observe('value', `chats/${auctionId}/online`, (data) => {
      //   data = data || {};
      //   delete data['manager'];
      //   setOnlineTS(data);
      // });

      // firebase.observe('value', `chats/${auctionId}/participants`, (data) =>
      //   handleParticipants(data),
      // );
      firebase.observe('child_added', `chats/${auctionId}/participants`, (data) =>
        handleParticipants(data),
      );
      firebase.observe('child_removed', `chats/${auctionId}/participants`, (data) =>
        handleParticipants(data),
      );
      firebase.observe('child_changed', `chats/${auctionId}/participants`, (data) =>
        handleParticipants(data),
      );
      firebase.observe('child_moved', `chats/${auctionId}/participants`, (data) =>
        handleParticipants(data),
      );

      firebase.observe('value', `chats/${auctionId}/online`, (data) => {
        data = data || {};
        delete data['manager'];
        setOnlineTS(data);
      });

      // probably child_added & child_changed & once(value) is more efficient
      // firebase.observe('value', `chats/${auctionId}/messages`, (data) =>
      //   handleGlobalMessages(data),
      // );
      firebase.observe('child_added', `chats/${auctionId}/messages`, (data, key) =>
        handleGlobalMessages(data, key),
      );

      // probably child_added & child_changed & once(value) is more efficient
      // firebase
      //   .getRef(`chats/${auctionId}/direct`)
      //   .on('value', (snapshot) => handleDirectMessages(snapshot));

      // firebase
      //   .getRef(`chats/${auctionId}/direct`)
      //   .on('child_added', (snapshot) => handleDirectMessages(snapshot));
      firebase.observe('child_added', `chats/${auctionId}/direct`, (data, key) =>
        handleDirectMessages(data, key),
      );
      firebase.observe('child_changed', `chats/${auctionId}/direct`, (data, key) =>
        handleDirectMessages(data, key),
      );
    },
    'auction-chat',
    auctionStatus && auctionStatus !== 'inactive'
      ? `${resource}/${auctionId}/get-chat-manager-token`
      : null,
  );

  useEffect(
    () => () => {
      intervalRef.current && clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    },
    [],
  );

  return (
    <ChatWidgetContext.Provider
      value={{
        participants,
        globalMessages,
        globalMessagesUnread,
        directMessages: directMessages[activeChat] || [],
        directMessagesUnread,
        onlineStatuses,
        setCurrentChat,
        sendMessage,
        setAsRead,
        setAsBanned,
        error: chatError as Error,
      }}
    >
      {children}
    </ChatWidgetContext.Provider>
  );
};

export default ChatWidgetProvider;

export interface ChatWidgetProviderProps {
  auctionId: number;
  auctionStatus: string;
  children: React.ReactNode;
}
