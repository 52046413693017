import { GridProps } from '@material-ui/core';
import BookingIcon from '@material-ui/icons/Visibility';
import { FC } from 'react';
import { useHistory } from 'react-router';
import BookingDatagrid from '../../../Resources/Booking/BookingDatagrid';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';
import { useDashboardContext } from '../../../hooks';
import { PersonRoles } from '../../../utils/constants';

const AppDashboardBookings: FC<GridProps> = (props) => {
  const history = useHistory();
  const { user } = useDashboardContext();

  if (user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...props} md={12}>
      <AppDashboardTable
        resource="bookings"
        pagination={{
          page: 1,
          perPage: 12,
        }}
        tableTitle="Ultime prenotazioni visite"
        tableSubtitle="Di seguito sono elencate le ultime 10 prenotazioni effettuate"
        noItemMessage="Non sono presenti prenotazioni"
        noItemIcon={<BookingIcon />}
        CustomDatagrid={
          <BookingDatagrid
            rowClick={() => {
              history.push('bookings');
            }}
          />
        }
      />
    </AppDashboardCard>
  );
};

export default AppDashboardBookings;
