import OpLogIcon from '@material-ui/icons/ViewHeadline';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import OpLogList from './OpLogList';

export const OP_LOG_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const OpLog: ResourceExport = {
  list: OpLogList,
  icon: OpLogIcon
};
export default OpLog;
