import { Record as RaRecord } from 'ra-core';
import { FC, ReactElement } from 'react';
import { ButtonProps, EditButton } from 'react-admin';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';

interface AppEditButtonProps extends ButtonProps {
  basePath?: string;
  record?: RaRecord;
  icon?: ReactElement;
}

const AppEditButton: FC<AppEditButtonProps> = (props) => {
  const { variant = 'outlined', icon = <span />, disabled, ...rest } = props;

  const allowEdit = useUserIsAllowed(ResourceAction.Put, props.resource);

  return <EditButton {...rest} variant={variant} icon={icon} disabled={disabled || !allowEdit} />;
};

export default AppEditButton;
