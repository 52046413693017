import Menu from '@material-ui/core/Menu';
import React, { FC, Fragment } from 'react';
import { useToggleState } from '../../../../../hooks/use-toggle-state';
import { LogoutButton } from './logout/logout-button';
import { ChangePasswordButton } from './password/change-password-button';
import { ChangePasswordForm } from './password/change-password-form';

interface ActionsMenuProps {
  actionsMenuAnchor: any;
  closeActionsMenu: () => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = React.memo(
  ({ actionsMenuAnchor, closeActionsMenu }) => {
    const [updatePasswordDialogOpen, toggleUpdatePasswordDialog] = useToggleState();

    return (
      <Fragment>
        <Menu
          anchorEl={actionsMenuAnchor}
          open={!!actionsMenuAnchor}
          onClose={closeActionsMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <LogoutButton />
          <ChangePasswordButton toggleUpdatePasswordDialog={toggleUpdatePasswordDialog} />
        </Menu>
        <ChangePasswordForm
          closeMenu={closeActionsMenu}
          toggleUpdatePasswordDialog={toggleUpdatePasswordDialog}
          updatePasswordDialogOpen={updatePasswordDialogOpen}
        />
      </Fragment>
    );
  },
);
