import { Grid } from '@material-ui/core';
import { useMutation } from 'ra-core';
import { Labeled } from 'ra-ui-materialui';
import { ReactElement, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { ChipPanelField } from '../../Components/chip/chip-panel-field';
import { DetailPanelField } from '../../Components/detail-panel';
import AppCard from '../../Components/ui/card/AppCard';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { dateFormatter } from '../../utils/data-formatters';
import {
  COMMUNICATION_COLORS,
  COMMUNICATION_PRIORITIES,
  COMMUNICATION_STATUSES,
  COMMUNICATION_TYPES,
} from './constants';

const CommunicationDetails = (props: Record<any, any>): ReactElement => {
  const [mutateCommunicationDetail] = useMutation();
  const [mutateRemoteCommunicationDetail] = useMutation();
  const [communicationDetail, setCommunicationDetail] = useState<any>();
  const [remoteCommunicationDetail, setRemoteCommunicationDetail] = useState<any>();

  useEffect(() => {
    mutateCommunicationDetail(
      {
        type: 'getOne',
        resource: `communications/${props.record.id}`,
        payload: {},
      },
      {
        onSuccess: (res) =>
          setCommunicationDetail(Object.values(res.data).length > 0 ? res.data : null),
        onFailure: (err) => {
          console.error('[CommunicationDetail] Error retrieving communication detail:', err);
        },
      },
    );

    if (props.record.remoteId) {
      mutateRemoteCommunicationDetail(
        {
          type: 'getOne',
          resource: `communications/${props.record.id}/detail`,
          payload: {},
        },
        {
          onSuccess: (res) =>
            setRemoteCommunicationDetail(Object.values(res.data).length > 0 ? res.data : null),
          onFailure: (err) => {
            console.error(
              '[CommunicationDetail] Error retrieving communication remote detail:',
              err,
            );
          },
        },
      );
    }
  }, []);

  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale">
        {communicationDetail && (
          <>
            <AppCard title="Informazioni generali" expanded>
              <DetailPanelField
                label="Data creazione"
                value={dateFormatter(communicationDetail.createdAt)}
                md={6}
              />
              <DetailPanelField
                label="Data aggiornamento"
                value={dateFormatter(communicationDetail.updatedAt)}
                md={6}
              />
              <DetailPanelField
                label="Tipo"
                value={
                  COMMUNICATION_TYPES.find((c) => c.id === communicationDetail.data.type)?.name
                }
                md={6}
              />
              <ChipPanelField
                label="Stato"
                value={
                  COMMUNICATION_STATUSES.find((c) => c.id === communicationDetail.status)?.name
                }
                style={{
                  backgroundColor: COMMUNICATION_COLORS[communicationDetail.status][0],
                  color: COMMUNICATION_COLORS[communicationDetail.status][1],
                }}
              />
              <DetailPanelField label="Tentativi" value={communicationDetail.attempts} md={6} />
              <DetailPanelField label="Da" value={communicationDetail.data.from} md={6} />
              <DetailPanelField label="Destinatario" value={communicationDetail.data.to} md={6} />
              <DetailPanelField label="CC" value={communicationDetail.data.cc} md={6} />
              <DetailPanelField label="BCC" value={communicationDetail.data.bcc} md={6} />
            </AppCard>

            <AppCard title="Informazioni aggiuntive" expanded>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Labeled label="Data">
                    <ReactJson
                      name={null}
                      src={communicationDetail.data ?? {}}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </Labeled>
                </Grid>
                <Grid item md={6}>
                  <Labeled label="Log">
                    <ReactJson
                      name={null}
                      src={communicationDetail.log ?? {}}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </Labeled>
                </Grid>
              </Grid>
            </AppCard>
          </>
        )}
      </AppFormTab>

      <AppFormTab label="Dati remoti">
        <AppCard title="Dati" expanded>
          {remoteCommunicationDetail && (
            <Grid container>
              <DetailPanelField
                label="Data creazione"
                value={dateFormatter(remoteCommunicationDetail.createdAt)}
                md={6}
              />
              <DetailPanelField
                label="Data aggiornamento"
                value={dateFormatter(remoteCommunicationDetail.updatedAt)}
                md={6}
              />
              <DetailPanelField
                label="Tipo"
                value={
                  COMMUNICATION_TYPES.find((c) => c.id === remoteCommunicationDetail.type)?.name
                }
                md={6}
              />
              <DetailPanelField
                label="Priorità"
                value={
                  COMMUNICATION_PRIORITIES.find((c) => c.id === remoteCommunicationDetail.priority)
                    ?.name
                }
                md={6}
              />
              <ChipPanelField
                label="Stato"
                value={
                  COMMUNICATION_STATUSES.find((c) => c.id === remoteCommunicationDetail.status)
                    ?.name
                }
                style={{
                  backgroundColor: COMMUNICATION_COLORS[remoteCommunicationDetail.status][0],
                  color: COMMUNICATION_COLORS[remoteCommunicationDetail.status][1],
                }}
              />
              <DetailPanelField
                label="Tentativi"
                value={remoteCommunicationDetail.attempts}
                md={6}
              />
              <DetailPanelField label="Da" value={remoteCommunicationDetail.from} md={6} />
              <DetailPanelField label="Destinatario" value={remoteCommunicationDetail.to} md={6} />
              <DetailPanelField label="CC" value={remoteCommunicationDetail.cc} md={6} />
              <DetailPanelField label="BCC" value={remoteCommunicationDetail.bcc} md={6} />
              <DetailPanelField label="Oggetto" value={remoteCommunicationDetail.subject} md={12} />
              <Grid item md={12}>
                <iframe
                  src={`data:text/html;charset=utf-8,${encodeURIComponent(
                    remoteCommunicationDetail.bodyFormatted,
                  )}`}
                  height="600px"
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          )}
        </AppCard>
        <AppCard title="Informazioni aggiuntive" expanded>
          {remoteCommunicationDetail && remoteCommunicationDetail.log && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Labeled label="Log">
                  <ReactJson
                    name={null}
                    src={remoteCommunicationDetail.log ?? {}}
                    collapsed={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </Labeled>
              </Grid>
            </Grid>
          )}
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default CommunicationDetails;
