import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { AppText } from '../../../../text';

const useStyles = makeStyles(
  (theme) => ({
    passwordRulesContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    passwordRulesList: {
      margin: 0,
    },
  }),
  { name: 'ChangePasswordRules' },
);

export const ChangePasswordRules = React.memo(() => {
  const classes = useStyles();
  return (
    <Grid item md={12} className={classes.passwordRulesContainer}>
      <AppText variant="h6" children="Regole nuova password:" />
      <ul className={classes.passwordRulesList}>
        <li>
          <AppText variant="button" children="Almeno 8 caratteri" />
        </li>
        <li>
          <AppText variant="button" children="Almeno una lettera maiuscola" />
        </li>
        <li>
          <AppText variant="button" children="Almeno un numero" />
        </li>
        <li>
          <AppText variant="button" children="Almeno un carattere speciale" />
        </li>
      </ul>
    </Grid>
  );
});
