import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Gavel, PictureAsPdf as Report } from '@material-ui/icons';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { Record as RaRecord, useMutation, useNotify, useRefresh } from 'react-admin';
import ChatWidget from '../../../../Components/chat-widget/chat-widget';
import AppButtonGroup from '../../../../Components/ui/button/AppButtonGroup';
import { useBasePath } from '../../../../hooks/use-base-path';
import { SaleMode } from '../../../../utils/constants';
import AuctionReportButton from '../AuctionReportButton';
import AuctionWorkflow from '../AuctionWorkflow';
import AuctionToScreen from './AuctionToScreen/auction-to-screen';
import OwnerActions from './Owner/owner-actions';
import AuctionManagementButton from './auction-management-button';
import AuctionManagementConfirmDialog from './auction-management-confirm-dialog';

/// region STYLES

const containerStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
    },
  }),
  { name: 'AuctionManagementContainer' },
);

const chatCardStyles = makeStyles(
  (theme) => ({
    card: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      minWidth: '200px',
      maxWidth: '200px',
    },
    header: {
      backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.action.disabled}`,
      textAlign: 'center',
    },
  }),
  { name: 'AuctionManagementChatCard' },
);

const workflowCardStyles = makeStyles(
  (theme) => ({
    card: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    header: {
      backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.action.disabled}`,
      minHeight: theme.spacing(10),
      maxHeight: theme.spacing(10),
      //textAlign: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(2.6666)}px`,
      '& .MuiCardHeader-action': {
        margin: 0,
      },
    },
  }),
  { name: 'AuctionManagementWorkflowCard' },
);

/// endregion

interface AuctionManagementProps {
  isAsync: boolean;
  isJudiciary: boolean;
  isActive: boolean;
  isPaused: boolean;
  bestOffer: Record<string, any>;
  raiseLimitTS: number;
  completedAuction: Record<string, any> | null;
  reservePriceInfo: Record<string, any>;
  record: RaRecord;
  mode: string;
  isConnectionOk: boolean;
  serverTimeDeviceTimeOffset: number;
}

const AuctionManagement: FC<AuctionManagementProps> = (props) => {
  const { mode } = props;
  const containerClasses = containerStyles();
  const chatCardClasses = chatCardStyles();
  const wfCardClasses = workflowCardStyles();

  //TODO: remove after fix
  // const { dirty } = useFormState();

  /// region ACTIONS
  // TODO: move to dedicated component!!

  const [updateSaleExperiment] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  const basePath = useBasePath();

  const onActionSuccess = (message?: string) => {
    refresh();
    notify(message ?? 'Operazione effettuata correttamente', 'info');
  };
  const onActionFailure = (err) => {
    console.error(err);
    notify("Errore verificatosi durante lo svolgimento dell'operazione.", 'error');
  };

  // Annulla asta
  const handleSettled = useCallback((data) => {
    updateSaleExperiment(
      {
        type: 'update',
        resource: 'sale-experiments',
        payload: {
          data: {
            auctionStatus: 'settled',
            auctionStatusReason: data.reason,
            auctionStatusReasonDetails: data.reasonDetails,
          },
          id: props.record.id,
        },
      },
      {
        onSuccess: () => onActionSuccess('Asta annullata correttamente.'),
        onFailure: (err) => onActionFailure(err),
      },
    );
  }, []);

  // Rimanda asta
  const handlePostponed = useCallback((data) => {
    updateSaleExperiment(
      {
        type: 'update',
        resource: 'sale-experiments',
        payload: {
          data: {
            auctionPostponed: true,
            auctionStatusReason: data.reason,
            auctionStatusReasonDetails: data.reasonDetails,
          },
          id: props.record.id,
        },
      },
      {
        onSuccess: () => onActionSuccess('Asta rimandata correttamente.'),
        onFailure: (err) => onActionFailure(err),
      },
    );
  }, []);

  // Asta deserta
  const handleDeserted = useCallback((data) => {
    updateSaleExperiment(
      {
        type: 'update',
        resource: 'sale-experiments',
        payload: {
          data: {
            unsuccessfulAuction: true,
            auctionStatus: 'completed',
            auctionStatusReason: data.reason,
            auctionStatusReasonDetails: data.reasonDetails,
          },
          id: props.record.id,
        },
      },
      {
        onSuccess: () => onActionSuccess(),
        onFailure: (err) => onActionFailure(err),
      },
    );
  }, []);

  // auction management states
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<'settled' | 'postponed' | 'deserted'>('settled');
  const [actionHandler, setActionHandler] = useState<(...args) => void>(() => handleSettled);

  // Auction management confirm dialog handlers
  const cancelHandler = useCallback(() => setOpen(false), []);
  const clickBtnHandler = useCallback(
    (action: 'settled' | 'postponed' | 'deserted', actionHandler: (...args) => void) => {
      setAction(action);
      setActionHandler(() => actionHandler);
      setOpen(true);
    },
    [],
  );

  // generate report handler
  const [generateReport] = useMutation();
  const generateReportHandler = useCallback(() => {
    generateReport(
      {
        type: 'getOne',
        resource: `${basePath}/${props.record.id}/generate-auction-report`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          const a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.pdfBase64;
          a.download = res.data.fileName;
          a.click();
        },
        onFailure: (err) => {
          notify(`Generazione del report non possibile: ${err.message}`, 'error');
          console.error(err);
        },
      },
    );
  }, []);

  const auctionActionsHidden =
    props.completedAuction || ['settled', 'completed'].includes(props.record.auctionStatus);
  /// endregion

  return (
    <div className={containerClasses.container}>
      <Card className={wfCardClasses.card}>
        <CardHeader
          title={'Gestione'}
          titleTypographyProps={{ variant: 'h6' }}
          className={wfCardClasses.header}
          action={
            <React.Fragment>
              <Button
                disabled
                variant="text"
                children={
                  props.isConnectionOk ? 'Connessione internet ok' : 'Connessione internet assente'
                }
                style={{
                  background: props.isConnectionOk ? 'green' : 'red',
                  color: 'white',
                  marginRight: '10px',
                }}
                size="small"
              />
              {props.record.fkSaleMode === SaleMode.SyncMixed && (
                <AuctionToScreen mode={mode} record={props.record} />
              )}
              {!props.isJudiciary && (
                <OwnerActions
                  mode={mode}
                  record={props.record}
                  reservePriceInfo={props.reservePriceInfo}
                />
              )}
              {!auctionActionsHidden ? (
                <AppButtonGroup color="primary" title="Azioni asta" icon={<Gavel />}>
                  <AuctionManagementButton
                    caption="Annulla asta"
                    variant="contained"
                    color="secondary"
                    clickBtnHandler={() => clickBtnHandler('settled', handleSettled)}
                  />
                  <AuctionManagementButton
                    caption="Rimanda asta"
                    variant="contained"
                    color="secondary"
                    disabled={props.record.auctionPostponed}
                    clickBtnHandler={() => clickBtnHandler('postponed', handlePostponed)}
                  />
                  <AuctionManagementButton
                    caption="Asta deserta"
                    variant="contained"
                    color="secondary"
                    disabled={props.isAsync}
                    clickBtnHandler={() => clickBtnHandler('deserted', handleDeserted)}
                  />
                </AppButtonGroup>
              ) : (
                <Fragment>
                  <>
                    <AuctionReportButton saleExpId={props.record.id} />
                    <AuctionManagementButton
                      caption="Genera il report"
                      clickBtnHandler={generateReportHandler}
                      variant="outlined"
                      endIcon={<Report />}
                      tooltipText="Genera e visualizza il report dettagliato della gara terminata in formato pdf"
                    />
                  </>
                </Fragment>
              )}
            </React.Fragment>
          }
        />
        <CardContent>
          <AuctionWorkflow
            mode={mode}
            bestOffer={props.bestOffer}
            isPaused={props.isPaused}
            isActive={props.isActive}
            raiseLimitTS={props.raiseLimitTS}
            completedAuction={props.completedAuction}
            reservePriceInfo={props.reservePriceInfo}
            record={props.record}
            serverTimeDeviceTimeOffset={props.serverTimeDeviceTimeOffset}
          />
        </CardContent>
      </Card>
      <Card
        className={chatCardClasses.card}
        hidden={
          mode === 'create' ||
          props.record.unsuccessfulAuction ||
          ['inactive', 'settled'].includes(props.record.auctionStatus) ||
          props.isAsync
        }
      >
        <CardHeader
          title={'Gestione CHAT'}
          titleTypographyProps={{ variant: 'h6' }}
          className={chatCardClasses.header}
        />
        <CardContent>
          <ChatWidget
            saleExperimentId={props.record.id as number}
            auctionStatus={props.record.auctionStatus}
          />
        </CardContent>
      </Card>
      {
        /* TODO: remove after fix
        dirty
        ? <AppMessageDialog open={open}
                            title="Impossibile procedere"
                            content="Sono presenti delle modifiche non salvate."
                            onClose={cancelHandler} />
        :*/ <AuctionManagementConfirmDialog
          open={open}
          onCancel={cancelHandler}
          onConfirm={actionHandler}
          action={action}
        />
      }
    </div>
  );
};

export default AuctionManagement;
