import React, { FC, Fragment } from 'react';
import { LegalCharges } from '../../../Components/sale-experiment/legal-charges/legal-charges';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppAutocompleteInput,
  AppDateTimeInput,
  AppNumberInput,
  AppTextInput,
} from '../../../Components/ui/input';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import { SaleMode, SaleType } from '../../../utils/constants';
import { maxValue } from '../../../utils/validators';

interface BuyItNowProps {
  formData: Record<string, any>;
  mode: string;
  selectedItem: Record<string, any>;
  record: Record<string, any>;
}

const BuyItNow: FC<BuyItNowProps> = ({ formData, mode, selectedItem, record }) => {
  const { fkSaleMode } = formData;

  return fkSaleMode === SaleMode.BuyItNow ? (
    <AppCard
      title="Informazioni sulla vendita compralo subito"
      expanded={true}
      disabled={mode === 'show'}
    >
      <AppAutocompleteInput
        label="Tipo di vendita"
        source="fkSaleType"
        reference="sale-types"
        optionText="description"
        md={4}
        defaultValue={SaleType.ByAgent}
      />
      <AppDivider />
      <AppNumberInput
        label="Prezzo di vendita al pezzo"
        source="priceApiece"
        isAmount
        md={3}
        validate={[maxValue('priceTotal')]}
        required
      />
      <AppNumberInput label="Prezzo di vendita di listino" source="priceTag" isAmount md={3} />
      <AppNumberInput
        label="Prezzo di vendita totale"
        source="priceTotal"
        isAmount
        md={2}
        required
      />
      <AppNumberInput label="Quantità venduta" source="soldQuantity" md={2} required />
      <AppNumberInput label="Quantità totale" source="totalQuantity" md={2} required />
      <AppDateTimeInput source="buyItNowStartAt" label="Data/ora inizio vendita" md={3} required />
      <AppDateTimeInput source="buyItNowEndAt" label="Data/ora fine vendita" md={3} required />
      <AppDivider />
      <LegalCharges mode={mode} record={record} selectedItem={selectedItem} saleMode={fkSaleMode} />
      <AppTextInput source="saleAddress" label="Luogo della vendita" md={12} required />
    </AppCard>
  ) : (
    <Fragment />
  );
};

export default BuyItNow;
