import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { FormDataConsumer } from 'react-admin';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { AppFileInput } from '../../Components/ui/input/AppFileInput';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import {
  ITEM_GENRES,
  MAGAZINE_KINDS,
  MAGAZINE_PLACEHOLDER_TYPES,
  MAGAZINE_PLACEHOLDER_SIDES,
} from '../../utils/constants';
import { blobToBase64 } from '../../utils/files';
import { MAGAZINE_PLACEHOLDER_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    fileInput: {
      marginLeft: theme.spacing(5),
    },
    fileInputPreview: {
      maxWidth: theme.spacing(30),
    },
  }),
  { name: 'MagazinePlaceholderDetails' },
);

const MagazinePlaceholderDetails: FC<any> = (props) => {
  const classes = useStyles();

  const handleFileAndSave = async (record: any, redirect: any, options: any) => {
    if (record.fullLeftFile) {
      await blobToBase64(record.fullLeftFile.fullLeftFile).then((result) => {
        record.fullLeftFile = {
          base64: result,
          filename: record.fullLeftFile.filename,
          type: record.fullLeftFile.rawFile.type,
        };
      });
    }
    if (record.fullRightFile) {
      await blobToBase64(record.fullRightFile.fullRightFile).then((result) => {
        record.fullRightFile = {
          base64: result,
          filename: record.fullRightFile.filename,
          type: record.fullRightFile.rawFile.type,
        };
      });
    }
    if (record.halfLeftFile) {
      await blobToBase64(record.halfLeftFile.halfLeftFile).then((result) => {
        record.halfLeftFile = {
          base64: result,
          filename: record.halfLeftFile.filename,
          type: record.halfLeftFile.rawFile.type,
        };
      });
    }
    if (record.halfRightFile) {
      await blobToBase64(record.halfRightFile.halfRightFile).then((result) => {
        record.halfRightFile = {
          base64: result,
          filename: record.halfRightFile.filename,
          type: record.halfRightFile.rawFile.type,
        };
      });
    }
    if (record.thirdLeftFile) {
      await blobToBase64(record.thirdLeftFile.thirdLeftFile).then((result) => {
        record.thirdLeftFile = {
          base64: result,
          filename: record.thirdLeftFile.filename,
          type: record.thirdLeftFile.rawFile.type,
        };
      });
    }
    if (record.thirdRightFile) {
      await blobToBase64(record.thirdRightFile.thirdRightFile).then((result) => {
        record.thirdRightFile = {
          base64: result,
          filename: record.thirdRightFile.filename,
          type: record.thirdRightFile.rawFile.type,
        };
      });
    }
    if (record.twoThirdsLeftFile) {
      await blobToBase64(record.twoThirdsLeftFile.twoThirdsLeftFile).then((result) => {
        record.twoThirdsLeftFile = {
          base64: result,
          filename: record.twoThirdsLeftFile.filename,
          type: record.twoThirdsLeftFile.rawFile.type,
        };
      });
    }
    if (record.twoThirdsRightFile) {
      await blobToBase64(record.twoThirdsRightFile.twoThirdsRightFile).then((result) => {
        record.twoThirdsRightFile = {
          base64: result,
          filename: record.twoThirdsRightFile.filename,
          type: record.twoThirdsRightFile.rawFile.type,
        };
      });
    }

    // Send to API server
    props.save(record, redirect, options);
  };

  return (
    <AppBaseForm
      {...props}
      save={(record, redirect, options) => handleFileAndSave(record, redirect, options)}
      disabledCards={
        !hasViewPermissions(
          props.permissions?.loginRole,
          'create',
          MAGAZINE_PLACEHOLDER_PERMISSIONS,
        ) || props.mode === 'show'
      }
    >
      <AppCard collapsable={false} title="Impostazioni tappo">
        <AppTextInput
          source="name"
          label="Nome tappo"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="genre"
          choices={ITEM_GENRES}
          label="Genere"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="kind"
          choices={MAGAZINE_KINDS}
          label="Tipo di bollettino"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="type"
          choices={MAGAZINE_PLACEHOLDER_TYPES}
          label="Tipo di tappo"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="side"
          choices={MAGAZINE_PLACEHOLDER_SIDES}
          label="Lato del tappo"
          required
          md={4}
          disabled={props.mode !== 'create'}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === 'full' || formData.type === 'both') && (
              <>
                {(formData.side === 'left' || formData.side === 'both') && (
                  <AppFileInput
                    {...rest}
                    md={5}
                    source="fullLeftFile"
                    accept="image/jpeg"
                    label="Full Left"
                    className={classes.fileInput}
                    previewLabel="Full Left"
                    previewSource="fullLeftMetadata"
                    previewType="base64"
                    previewClassName={classes.fileInputPreview}
                  />
                )}
                {(formData.side === 'right' || formData.side === 'both') && (
                  <AppFileInput
                    {...rest}
                    md={5}
                    source="fullRightFile"
                    accept="image/jpeg"
                    label="Full Right"
                    className={classes.fileInput}
                    previewLabel="Full Right"
                    previewSource="fullRightMetadata"
                    previewType="base64"
                    previewClassName={classes.fileInputPreview}
                  />
                )}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === 'partial' || formData.type === 'both') && (
              <>
                {(formData.side === 'left' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="halfLeftFile"
                      accept="image/jpeg"
                      label="Half Left"
                      className={classes.fileInput}
                      previewLabel="Half Left"
                      previewSource="halfLeftMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
                {(formData.side === 'right' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="halfRightFile"
                      accept="image/jpeg"
                      label="Half Right"
                      className={classes.fileInput}
                      previewLabel="Half Right"
                      previewSource="halfRightMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === 'partial' || formData.type === 'both') && (
              <>
                {(formData.side === 'left' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="thirdLeftFile"
                      accept="image/jpeg"
                      label="Third Left"
                      className={classes.fileInput}
                      previewLabel="Third Left"
                      previewSource="thirdLeftMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
                {(formData.side === 'right' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="thirdRightFile"
                      accept="image/jpeg"
                      label="Third Right"
                      className={classes.fileInput}
                      previewLabel="Third Right"
                      previewSource="thirdRightMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === 'partial' || formData.type === 'both') && (
              <>
                {(formData.side === 'left' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="twoThirdsLeftFile"
                      accept="image/jpeg"
                      label="Two Thirds Left"
                      className={classes.fileInput}
                      previewLabel="Two Thirds Left"
                      previewSource="twoThirdsLeftMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
                {(formData.side === 'right' || formData.side === 'both') && (
                  <>
                    <AppFileInput
                      {...rest}
                      md={5}
                      source="twoThirdsRightFile"
                      accept="image/jpeg"
                      label="Two Thirds Right"
                      className={classes.fileInput}
                      previewLabel="Two Thirds Right"
                      previewSource="twoThirdsRightMetadata"
                      previewType="base64"
                      previewClassName={classes.fileInputPreview}
                    />
                  </>
                )}
              </>
            )
          }
        </FormDataConsumer>
      </AppCard>
    </AppBaseForm>
  );
};

export default MagazinePlaceholderDetails;
