import React, { FC, useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import { AppTextInput, AppSelectInput, AppNumberInput, AppBooleanInput } from '../ui/input';
import { Form } from 'react-final-form';

const AppMediaRoleAction: FC<any> = () => {
  const ctx = useContext(AppRelatedResourceContext);
  const notify = useNotify();
  return (
    <Form
      onSubmit={(data) => ctx.manager.submit(data, true)}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
        const disabled = submitting || pristine;

        const checkValidationErrors = () =>
          hasValidationErrors && notify('ra.message.invalid_form', 'error');

        return (
          <Grid container spacing={2}>
            <AppTextInput
              source='label'
              label='Etichetta'
              md={4}
              xs={6}
              variant='outlined'
              required
            />
            <AppSelectInput
              source='type'
              label='Tipo'
              choices={[
                { id: 'number', name: 'Numero' },
                { id: 'date', name: 'Data' },
                { id: 'amount', name: 'Importo' },
                { id: 'text', name: 'Testo' },
                { id: 'registry', name: 'Anagrafica' },
              ]}
              variant='outlined'
              md={4}
              xs={6}
              required
            />
            <AppNumberInput source='order' label='Ordine' variant='outlined' md={4} xs={6} />
            <AppBooleanInput
              source='mandatory'
              label='Obbligatorio'
              variant='outlined'
              md={2}
              xs={6}
            />
            <Grid item md={10} xs={6} />
            <Grid item md={12} xs={12} style={{ paddingBottom: '2rem', textAlign: 'right' }}>
              <Button
                disabled={disabled}
                onClick={() => {
                  checkValidationErrors();
                  handleSubmit();
                }}
                variant='contained'
                color='primary'
                autoFocus
                startIcon={<Save />}
                children='Aggiungi'
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AppMediaRoleAction;
