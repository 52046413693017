import { makeStyles } from '@material-ui/core';
import { FC, Fragment } from 'react';
import {
  BooleanField,
  Button,
  Identifier,
  ListProps,
  SelectField,
  useListContext,
  useMutation,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import {
  AppTextInput,
  AppAutocompleteInput,
  AppDateInput,
  AppNumberInput,
} from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import { AUCTION_STATUS } from '../../utils/constants';
import PublishIcon from '@material-ui/icons/Publish';
import UnpublishIcon from '@material-ui/icons/Delete';
import { AppLinkToEntityField } from '../../Components/ui/field';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';

const useStyles = makeStyles(
  (theme) => ({
    code: {
      minWidth: theme.spacing(30),
    },
    title: {
      minWidth: theme.spacing(40),
    },
    procedureName: {
      minWidth: theme.spacing(20),
    },
    published: {
      minWidth: theme.spacing(20),
    },
    auctionStatus: {
      minWidth: theme.spacing(20),
    },
    dates: {
      whiteSpace: 'nowrap',
    },
    price: {
      minWidth: theme.spacing(20),
    },
    notes: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    buttons: {
      whiteSpace: 'nowrap',
    },
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'MassivePublicationList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const PublicationButton = (props) => {
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(resource);

  const notify = useNotify();
  const [addPublications] = useMutation();
  const handleAddPublications = async (
    idealista: boolean,
    immobiliareIt: boolean,
    publishedModeOn: boolean,
    itemIds: Identifier[],
  ) => {
    await addPublications(
      {
        type: 'create',
        resource: 'massive-publications',
        payload: {
          data: {
            itemIds: itemIds.map((id) => Number(id)),
            idealista,
            immobiliareIt,
            publishedModeOn,
          },
        },
      },
      {
        onSuccess: () => {
          unselectAll();
          notify(
            `Lotti inviati per la ${publishedModeOn ? 'pubblicazione' : 'spubblicazione'}`,
            'info',
          );
        },
        onFailure: (err) => {
          notify(err, 'error');
          console.error(err);
        },
      },
    );
  };

  const { label, idealista, immobiliareIt, publishedModeOn } = props;

  return (
    <Button
      label={label}
      onClick={() => handleAddPublications(idealista, immobiliareIt, publishedModeOn, selectedIds)}
    >
      {publishedModeOn ? <PublishIcon /> : <UnpublishIcon />}
    </Button>
  );
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <PublicationButton
      label="Spubblica su Idealista"
      idealista
      publishedModeOn={false}
      {...props}
    />
    <PublicationButton
      label="Spubblica su Immobiliare.it"
      immobiliareIt
      publishedModeOn={false}
      {...props}
    />
    <PublicationButton label="Pubblica su Idealista" idealista publishedModeOn={true} {...props} />
    <PublicationButton
      label="Pubblica su Immobiliare.it"
      immobiliareIt
      publishedModeOn={true}
      {...props}
    />
    <PublicationButton
      label="Pubblica su entrambi"
      idealista
      immobiliareIt
      publishedModeOn={true}
      {...props}
    />
  </Fragment>
);

const ItemListFilters = (
  <AppListFilter>
    <AppAutocompleteInput
      label="Pubblicato su Idealista"
      source="publishedOnIdealista"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
      suggestionLimit={2}
    />
    <AppAutocompleteInput
      label="Pubblicato su Immobiliare"
      source="publishedOnImmobiliareIt"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
      suggestionLimit={2}
    />
    <AppAutocompleteInput
      reference="pvp/rites"
      source="folder_fkPvpRite"
      label="Rito"
      optionText="description"
      resettable
      suggestionLimit={5}
      md={4}
    />
    <AppAutocompleteInput
      source="saleModes"
      reference="sale-modes"
      label="Modalità di vendita"
      variant="outlined"
      optionText="description"
      md={4}
      resettable
    />
    <AppDateInput source="auctionStartFrom" label="Inizio asta dal..." md={3} />
    <AppDateInput source="auctionStartTo" label="Inizio asta al..." md={3} />
    <AppDateInput source="auctionEndFrom" label="Termine asta dal..." md={3} />
    <AppDateInput source="auctionEndTo" label="Termine asta al..." md={3} />
    <AppTextInput source="procedureCode" label="Numero procedura" md={2} />
    <AppTextInput source="ivgCode" label="Numero IVG" md={2} />
    <AppAutocompleteInput
      label="Stato attivo"
      source="saleActiveStatus"
      md={2}
      resettable
      choices={[
        { id: 'yes', name: 'Sì' },
        { id: 'no', name: 'No' },
      ]}
      suggestionLimit={2}
    />
    <AppNumberInput label="Prezzo minimo maggiore di" source="priceMin" md={3} />
  </AppListFilter>
);

const MassivePublicationList: FC<ListProps> = (props) => {
  const classes = useStyles();

  const MASSIVE_PUBLICATIONS_ENABLED =
    process.env.REACT_APP_MASSIVE_PUBLICATIONS_ENABLED === 'true';

  return MASSIVE_PUBLICATIONS_ENABLED ? (
    <AppList
      {...props}
      title={<AppTitle title="Pubblicazione massiva" />}
      filters={ItemListFilters}
      bulkActionButtons={<PostBulkActionButtons />}
      hideCreateButton
    >
      <AppDatagrid resource="publications">
        <AppTextField
          source="relatedFolder.procedureCode"
          label="Numero procedura"
          headerClassName={classes.code}
          sortable={false}
        />
        <AppTextField source="code" label="Codice lotto" headerClassName={classes.code} />
        {IS_IVG && (
          <AppTextField source="ivgCode" label="Numero IVG" headerClassName={classes.code} />
        )}
        <AppTextField source="title" label="Nome lotto" headerClassName={classes.title} />
        <BooleanField
          source="publishedOnIdealista"
          label="Pubblicato su Idealista"
          headerClassName={classes.published}
          sortable={false}
        />
        <BooleanField
          source="publishedOnImmobiliareIt"
          label="Pubblicato su Immobiliare.it"
          headerClassName={classes.published}
          sortable={false}
        />
        <AppDateField
          source="currentSaleExperiment.auctionStartAt"
          label="Data/ora inizio asta"
          showTime
          headerClassName={classes.dates}
          sortable={false}
        />
        <AppDateField
          source="currentSaleExperiment.auctionEndAt"
          label="Data/ora termine asta"
          showTime
          headerClassName={classes.dates}
          sortable={false}
        />
        <AppTextField
          source="currentSaleExperiment.minPrice"
          label="Prezzo minimo"
          type="amount"
          headerClassName={classes.price}
          sortable={false}
        />
        <AppTextField
          source="currentSaleExperiment.basePrice"
          label="Prezzo base"
          type="amount"
          headerClassName={classes.price}
          sortable={false}
        />
        <SelectField
          source="currentSaleExperiment.auctionStatus"
          label="Stato asta"
          choices={AUCTION_STATUS}
          headerClassName={classes.auctionStatus}
          sortable={false}
        />
        <AppTextField
          source="idealistaNotes"
          label="Note Idealista"
          truncate
          headerClassName={classes.notes}
          sortable={false}
        />
        <AppTextField
          source="immobiliareItNotes"
          label="Note Immobiliare.it"
          truncate
          headerClassName={classes.notes}
          sortable={false}
        />

        <AppLinkToEntityField
          label="Link al lotto"
          targetResource="items"
          originName="massivePublication"
          value="Link"
          targetResourceId={(r) => r.id}
          truncate
          maxTextLength={60}
          sortable={false}
        />
      </AppDatagrid>
    </AppList>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità della "Pubblicazione massiva" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default MassivePublicationList;
