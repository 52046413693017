import { Email } from '@material-ui/icons';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import EcwidEventDetails from './EcwidEventDetails';
import EcwidEventList from './EcwidEventList';

export const ECWID_EVENT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const EcwidEvent: ResourceExport = {
  list: EcwidEventList,
  show: resourceWrapper('show', EcwidEventDetails, undefined, false, ECWID_EVENT_PERMISSIONS),
  icon: Email,
};

export default EcwidEvent;
