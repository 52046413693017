import React, { FC, Fragment, useEffect, useState } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppBooleanInput,
  AppInputFiller,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import {
  CONDO_FEES_TYPES,
  ENERGY_CLASS,
  HEATING_TYPE,
  OCCUPATION_STATUS,
} from '../../../utils/constants';
import { minValue } from '../../../utils/validators';
import { ItemRelatedDataProps } from '../ItemRelatedData';

const BASE_SOURCE = 'realEstate';

const RealEstateDetails: FC<ItemRelatedDataProps> = (props) => {
  const { mode, formData, resource } = props;
  const [invalidRoomsTotal, setInvalidRoomsTotal] = useState<boolean>(false);

  const checkRoomsTotal = () => {
    const roomsTotal = formData[BASE_SOURCE]?.rooms;
    if (!roomsTotal || isNaN(roomsTotal)) {
      setInvalidRoomsTotal(false);
      return;
    }
    const minimumRooms =
      (formData[BASE_SOURCE].bathrooms ?? 0) + (formData[BASE_SOURCE].bedrooms ?? 0);
    setInvalidRoomsTotal(roomsTotal < minimumRooms);
  };
  useEffect(checkRoomsTotal, [
    formData[BASE_SOURCE]?.bathrooms,
    formData[BASE_SOURCE]?.bedrooms,
    formData[BASE_SOURCE]?.rooms,
  ]);

  return (
    <Fragment>
      <AppCard title="Dettagli dell'immobile" disabled={mode === 'show'}>
        <AppNumberInput
          source={`${BASE_SOURCE}.rooms`}
          label="Locali totali"
          error={invalidRoomsTotal}
          helperText={
            invalidRoomsTotal
              ? 'La somma dei bagni e delle camere da letto supera il totale dei locali'
              : undefined
          }
          md={3}
        />
        <AppNumberInput source={`${BASE_SOURCE}.bathrooms`} label="Bagni" md={3} />
        <AppNumberInput source={`${BASE_SOURCE}.bedrooms`} label="Camere da letto" md={3} />
        <AppNumberInput source={`${BASE_SOURCE}.doubleRooms`} label="Camere doppie" md={3} />
        <AppDivider />
        <AppTextInput source={`${BASE_SOURCE}.buildYear`} label="Anno di costruzione" md={3} />
        <AppNumberInput source={`${BASE_SOURCE}.area`} label="Superficie (mq)" md={3} />
        <AppNumberInput
          source={`${BASE_SOURCE}.commercialArea`}
          label="Superficie commerciale (mq)"
          md={3}
        />
        <AppSelectInput
          source={`${BASE_SOURCE}.energyClass`}
          label="Classe energetica"
          md={3}
          choices={ENERGY_CLASS}
        />
        <AppBooleanInput
          source={`${BASE_SOURCE}.airConditioning`}
          label="Impianto di condizionamento"
          md={3}
        />
        <AppBooleanInput source={`${BASE_SOURCE}.hasHeating`} label="Riscaldamento" md={3} />
        {formData[BASE_SOURCE] &&
          formData[BASE_SOURCE].hasHeating && [
            <AppBooleanInput
              source={`${BASE_SOURCE}.hasAutonomousHeating`}
              label="Riscaldamento autonomo"
              md={3}
            />,
            <AppSelectInput
              source={`${BASE_SOURCE}.heatingType`}
              label="Tipologia di riscaldamento"
              choices={HEATING_TYPE}
              md={3}
            />,
          ]}
        <AppDivider />
        <AppNumberInput source={`${BASE_SOURCE}.floor`} label="Piano" md={3} />
        <AppNumberInput
          source={`${BASE_SOURCE}.buildingFloorsCount`}
          label="Piani totali dell'edificio"
          md={3}
          validate={[minValue(`${BASE_SOURCE}.floor`, 'Piano')]}
        />
        <AppBooleanInput source={`${BASE_SOURCE}.hasElevator`} label="Ascensore" md={3} />
        <AppInputFiller md={3} />
        <AppDivider />
        <AppBooleanInput source={`${BASE_SOURCE}.hasCarPlace`} label="Posto auto" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasGarage`} label="Garage" md={3} />
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasGarage ? (
          [
            <AppNumberInput source={`${BASE_SOURCE}.garages`} label="Numero garages" md={3} />,
            <AppNumberInput
              source={`${BASE_SOURCE}.garageArea`}
              label="Superficie garage (mq)"
              md={3}
            />,
          ]
        ) : (
          <AppInputFiller md={6} />
        )}
        <AppBooleanInput source={`${BASE_SOURCE}.hasBalcony`} label="Balcone" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasTerrace`} label="Terrazzo" md={3} />
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasBalcony ? (
          <AppNumberInput source={`${BASE_SOURCE}.balconies`} label="Numero balconi" md={3} />
        ) : (
          <AppInputFiller md={3} />
        )}
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasTerrace ? (
          <AppNumberInput source={`${BASE_SOURCE}.terraces`} label="Numero terrazzi" md={3} />
        ) : (
          <AppInputFiller md={3} />
        )}
        <AppBooleanInput source={`${BASE_SOURCE}.hasBasement`} label="Cantina" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasPenthouse`} label="Mansarda" md={3} />
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasBasement ? (
          <AppNumberInput
            source={`${BASE_SOURCE}.basementArea`}
            label="Superficie cantina (mq)"
            md={3}
          />
        ) : (
          <AppInputFiller md={3} />
        )}
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasPenthouse ? (
          <AppNumberInput
            source={`${BASE_SOURCE}.penthouseArea`}
            label="Superficie mansarda (mq)"
            md={3}
          />
        ) : (
          <AppInputFiller md={3} />
        )}
        <AppBooleanInput source={`${BASE_SOURCE}.hasGarden`} label="Giardino" md={3} />
        <AppInputFiller md={3} />
        {formData[BASE_SOURCE] && formData[BASE_SOURCE].hasGarden ? (
          <AppNumberInput
            source={`${BASE_SOURCE}.gardenArea`}
            label="Superficie giardino (mq)"
            md={3}
          />
        ) : (
          <AppInputFiller md={3} />
        )}
        <AppDivider />
        <AppBooleanInput source={`${BASE_SOURCE}.hasFlue`} label="Canna fumaria" md={3} />
        <AppBooleanInput
          source={`${BASE_SOURCE}.hasDoubleGlazedWindows`}
          label="Doppi vetri"
          md={3}
        />
        <AppBooleanInput source={`${BASE_SOURCE}.hasLaundry`} label="Lavanderia" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasSecurityDoor`} label="Porta blindata" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasStorageRoom`} label="Ripostiglio" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasVideoIntercom`} label="Videocitofono" md={3} />
        <AppBooleanInput source={`${BASE_SOURCE}.hasMosquitoNets`} label="Zanzariere" md={3} />
        <AppDivider />
        <AppTextInput source={`${BASE_SOURCE}.furnitures`} label="Arredi" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.grade`} label="Grado" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.fixtures`} label="Infissi" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.fixtures2`} label="Serramenti" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.panorama`} label="Panorama" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.floorBathroom`} label="Pavimento bagno" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.floorKitchen`} label="Pavimento cucina" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.floorLivingArea`} label="Pavimento giorno" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.floorSleepingArea`} label="Pavimento notte" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.shutter`} label="Persiana" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.buildingCondition`} label="Stato immobile" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.kitchenType`} label="Tipo cucina" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.livingType`} label="Tipo soggiorno" md={3} />
        <AppNumberInput source={`${BASE_SOURCE}.freeSides`} label="Lati liberi" md={3} />
      </AppCard>
      <AppCard title="Dati catastali" disabled={mode === 'show'}>
        <AppTextInput source={`${BASE_SOURCE}.cadastralMap`} label="Mappa" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.cadastralSheet`} label="Foglio" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.cadastralParcel`} label="Particella" md={3} />
        <AppTextInput source={`${BASE_SOURCE}.cadastralSubordinate`} label="Subalterno" md={3} />
      </AppCard>
      <AppCard title="Dati aggiuntivi dell'immobile" disabled={mode === 'show'}>
        <AppSelectInput
          source={`${BASE_SOURCE}.occupationStatus`}
          choices={OCCUPATION_STATUS.map((status) => {
            if (status.id === 'occupied-by-the-debtor' && resource === 'items-private') {
              return {
                id: 'occupied-by-the-debtor',
                name: 'Occupato dal proprietario',
              };
            } else return status;
          })}
          label="Stato di occupazione immobile"
          md={3}
        />
        <AppNumberInput
          source={`${BASE_SOURCE}.condoFees`}
          label="Spese condominiali"
          isAmount
          md={3}
        />
        <AppSelectInput
          source={`${BASE_SOURCE}.condoFeesType`}
          label="Tipo di spese condominiali"
          choices={CONDO_FEES_TYPES}
          md={3}
        />
        <AppNumberInput
          source={`${BASE_SOURCE}.unpaidCondoFees`}
          label="Spese condominiali pregr. insol."
          isAmount
          md={3}
        />
        <AppBooleanInput
          source={`${BASE_SOURCE}.hasCadastralDiscrepancies`}
          label="Difformità catastali"
          md={3}
        />
        {formData.realEstate && formData.realEstate.hasCadastralDiscrepancies && (
          <AppNumberInput
            source={`${BASE_SOURCE}.amnestyAmount`}
            label="Importo sanatoria"
            isAmount
            md={3}
          />
        )}
      </AppCard>
    </Fragment>
  );
};

export default RealEstateDetails;
