export const blobToBase64 = async (blob: RequestInfo): Promise<any> => {
  if (!blob) throw new Error('[blobToBase64] Blob is not defined.');

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    fetch(blob).then((r) => {
      r.blob().then((actualBlob) => {
        reader.readAsDataURL(actualBlob);
      });
    });
  });
};

export const downloadFileFromBufferOrString = (
  data: Buffer | string,
  fileName: string,
  type: string,
): void => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(new Blob([data], { type }));
  a.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  );
  a.remove();
};
