import { GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBalanceTwoTone } from '@material-ui/icons';
import { FC, useMemo } from 'react';
import { FunctionField, ReferenceField } from 'react-admin';
import { useHistory } from 'react-router';
import { ResourceAction, userIsAllowed } from '../../../Providers/AppPermissionsProvider';
import { useDashboardContext } from '../../../hooks';
import { PersonRoles } from '../../../utils/constants';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  (theme) => ({
    person: {
      minWidth: theme.spacing(30),
    },
    from: {
      minWidth: theme.spacing(30),
    },
    to: {
      minWidth: theme.spacing(30),
    },
    auctionRoom: {
      minWidth: theme.spacing(30),
    },
    notes: {
      minWidth: theme.spacing(30),
    },
  }),
  { name: 'AppDashboardAuctionRoomsBookings' },
);

const AppDashboardAuctionRoomsBookings: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useDashboardContext();

  const allowView = userIsAllowed(ResourceAction.Get, 'auction-rooms-bookings', user);

  const isAuctioneer = useMemo(() => user?.roleId === PersonRoles.Auctioneer, [user]);

  if (!allowView) return null;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="auction-rooms-bookings"
        filter={{ status: 'sent' }}
        tableTitle="Prenotazioni sala d'asta da verificare"
        tableSubtitle="Di seguito sono elencate le richieste di prenotazione sala d'asta da verificare"
        noItemMessage="Non ci sono richieste di prenotazione da verificare"
        noItemIcon={<AccountBalanceTwoTone />}
        rowClick={(id, basePath, record) =>
          history.push(
            `auction-rooms-bookings/${record.id}?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
          )
        }
      >
        <AppDateField source="from" label="Dalle" showTime headerClassName={classes.from} />
        <AppDateField source="to" label="Alle" showTime headerClassName={classes.to} />
        {!isAuctioneer && (
          <ReferenceField
            label="Persona"
            headerClassName={classes.person}
            reference="people"
            source="fkPerson"
            basePath="/people"
            link={false}
          >
            <FunctionField render={(record) => `${record?.firstName} ${record?.lastName}`} />
          </ReferenceField>
        )}
        <ReferenceField
          label="Sala d'asta"
          headerClassName={classes.auctionRoom}
          reference="auction-rooms"
          source="fkAuctionRoom"
          basePath="/auction-rooms"
          link={false}
        >
          <FunctionField render={(record) => record?.title} />
        </ReferenceField>
        <AppTextField source="notes" label="Note" headerClassName={classes.notes} />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardAuctionRoomsBookings;
