import PanToolIcon from '@material-ui/icons/PanTool';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import PermissionDetails from './PermissionDetails';
import PermissionList from './PermissionList';

const Permission: ResourceExport = {
  list: PermissionList,
  create: resourceWrapper('create', PermissionDetails, undefined, undefined, undefined),
  show: resourceWrapper('show', PermissionDetails, undefined, undefined, undefined),
  edit: resourceWrapper('edit', PermissionDetails, undefined, undefined, undefined),
  icon: PanToolIcon,
};

export default Permission;
