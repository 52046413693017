import React, { FC } from 'react';
import { useNotify } from 'react-admin';
import { ApiProvider } from '../../../../../../Providers/ApiProvider';
import { validateConfirmPassword, validatePassword } from '../../../../../../utils/validators';
import AppFormDialog from '../../../../dialog/form-dialog/form-dialog';
import { AppTextInput } from '../../../../input';
import { ChangePasswordRules } from './change-password-rules';

type ChangePasswordFormFields = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

interface ChangePasswordFormProps {
  updatePasswordDialogOpen: boolean;
  toggleUpdatePasswordDialog: () => void;
  closeMenu: () => void;
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = React.memo(
  ({ updatePasswordDialogOpen, toggleUpdatePasswordDialog, closeMenu }) => {
    const notify = useNotify();

    const handlePasswordChange = (data: ChangePasswordFormFields) => {
      new ApiProvider()
        .post('people/change-password', null, {
          currentPsw: data.oldPassword,
          newPsw: data.newPassword,
          newPswConfirm: data.confirmNewPassword,
        })
        .then(() => {
          toggleUpdatePasswordDialog();
          closeMenu();
          notify('Password cambiata con successo.');
        })
        .catch((err) => {
          console.error(err);
          notify(`Errore: ${err.message ?? 'UNKNOWN_ERROR'}`, 'error');
        });
    };

    return (
      <AppFormDialog
        open={updatePasswordDialogOpen}
        onClose={toggleUpdatePasswordDialog}
        title="Cambia password"
        onSubmit={handlePasswordChange}
        record={{}}
        mode="insert"
        width={60}
        height={80}
      >
        <AppTextInput
          source="oldPassword"
          label="Password attuale"
          md={12}
          type="password"
          required
        />
        <ChangePasswordRules />
        <AppTextInput
          source="newPassword"
          label="Nuova password"
          md={12}
          type="password"
          required
          validate={[validatePassword()]}
        />
        <AppTextInput
          source="confirmNewPassword"
          label="Conferma nuova password"
          md={12}
          type="password"
          required
          validate={[validateConfirmPassword('newPassword')]}
        />
      </AppFormDialog>
    );
  },
);
