import React, { FC } from 'react';
import { ImageField, ImageFieldProps } from 'react-admin';

export const AppImageField: FC<ImageFieldProps> = React.memo((props) => {
  return (
    <ImageField
      {...props}
      record={
        typeof props.record === 'string'
          ? { id: 1, [props.source as string]: props.record }
          : props.record
      }
    />
  );
});
