import { Button, PropTypes } from '@material-ui/core';
import React, { FC, ReactElement, useContext } from 'react';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import { Record as RaRecord } from 'react-admin';

interface UploadMediaButtonProps {
  label: string;
  mediaType?: 'document' | 'image' | 'virtual-tour' | 'video';
  icon?: ReactElement;
  mode?: 'insert' | 'edit';
  record?: RaRecord;
  multiple?: boolean;
  mainResource?: string;
  disabled?: boolean;
  color?: PropTypes.Color;
  className?: string;
  role?: string;
}

/**
 * Used in "insert" mode at the TOP of the media list and in "edit" mode in each media row
 */
const UploadMediaButton: FC<UploadMediaButtonProps> = (props) => {
  const {
    mediaType,
    label,
    icon,
    mode = 'insert',
    record,
    multiple = false,
    mainResource,
    disabled,
    color = 'primary',
    className = '',
    role,
  } = props;

  const ctx = useContext(AppRelatedResourceContext);
  const { setMode, open, setAdditionalParams } = ctx.manager;

  const isDisabled =
    disabled || (mode === 'edit' && mainResource === 'items' && record?.refType === 'folder');

  return (
    <Button
      variant={mode === 'edit' ? 'outlined' : 'contained'}
      color={color}
      className={className}
      children={label}
      startIcon={icon ?? <span />}
      disabled={isDisabled}
      onClick={() => {
        setMode(mode);
        mode === 'edit' && ctx.getRelatedResource((record as any).id);
        setAdditionalParams({
          mediaType: mediaType ?? record?.role.type,
          multiple,
          role,
        });
        open();
      }}
    />
  );
};

export default UploadMediaButton;
