import { makeStyles } from '@material-ui/core/styles';
import { Error as ErrorIcon } from '@material-ui/icons';
import { keyBy as _keyBy, map as _map } from 'lodash';
import { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { ListContextProvider, Record as RaRecord, useMutation, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import { AppEmptyNoItems } from '../../../empty/app-empty';
import AppDatagrid from '../../../ui/datagrid/AppDatagrid';
import AppCircularLoader from '../../../ui/loaders/AppCircularLoader';
import AppDashboardTableTitle from './app-dashboard-table-title';

const useStyles = makeStyles(
  (theme) => ({
    table: {
      maxHeight: theme.spacing(40),
    },
  }),
  { name: 'AppDashboardTable' },
);

interface AppDashboardTableProps {
  resource: string;
  tableTitle?: string;
  tableSubtitle?: string;
  dataError?: string;
  filter?: Record<string, any>;
  noItemMessage?: string;
  noItemIcon?: any;
  rowClick?: (id, basePath, record) => void;
  rowStyle?: (record: RaRecord, index: number) => any;
  CustomDatagrid?: any;
  pagination?: Record<string, any>;
}

const AppDashboardTable: FC<PropsWithChildren<AppDashboardTableProps>> = (props) => {
  const {
    resource,
    filter,
    dataError = 'Non è stato possibile recuperare le informazioni sulla tabella.',
    tableTitle = '',
    tableSubtitle = '',
    noItemMessage,
    noItemIcon,
    rowClick,
    rowStyle,
    CustomDatagrid,
    pagination,
  } = props;
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();

  // TODO: add refresh button ?

  /// region TABLE DATA

  const [tableCtx, setTableCtx] = useState<Record<string, any>>({
    resource,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
    error: false,
    loading: true,
  });

  const [mutateTableData] = useMutation();
  const getTableData = () => {
    mutateTableData(
      {
        type: 'getList',
        resource,
        payload: {
          filter,
          pagination,
        },
      },
      {
        onSuccess: (res) =>
          setTableCtx((prevState) => ({
            ...prevState,
            data: _keyBy(res.data, 'id'),
            ids: _map(res.data, 'id'),
            total: res.data.length,
            loading: false,
            error: false,
          })),
        onFailure: (err) => {
          setTableCtx((prevState) => ({ ...prevState, error: true, loading: false }));
          notify(dataError, 'error');
          console.error(err);
        },
      },
    );
  };

  useEffect(getTableData, []);

  /// endregion

  return (
    <Fragment>
      <AppDashboardTableTitle title={tableTitle} subtitle={tableSubtitle} />
      {tableCtx.loading ? (
        <AppCircularLoader open={true} notFullscreen />
      ) : tableCtx.total !== 0 ? (
        <ListContextProvider value={tableCtx}>
          {CustomDatagrid ? (
            CustomDatagrid
          ) : (
            <AppDatagrid
              rowClick={(id, basePath, record) =>
                rowClick
                  ? rowClick(id, basePath, record)
                  : history.push(
                      `${resource}/${id}?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
                    )
              }
              rowStyle={rowStyle}
              className={classes.table}
            >
              {props.children}
            </AppDatagrid>
          )}
        </ListContextProvider>
      ) : (
        <AppEmptyNoItems
          message={tableCtx.error ? 'Recupero delle informazioni fallito.' : noItemMessage}
          icon={tableCtx.error ? <ErrorIcon /> : noItemIcon}
        />
      )}
    </Fragment>
  );
};

export default AppDashboardTable;
