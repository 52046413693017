import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import styles from '../styles';

type DataLineProps = {
  label: string;
  value: string;
};

export const PdfDataLine: FC<DataLineProps> = (props) => (
  <View style={styles.dataLine} wrap={false}>
    <View style={styles.dataLabel}>
      <Text>{props.label}</Text>
    </View>
    <View style={styles.dataValue}>
      <Text>{props.value}</Text>
    </View>
  </View>
);
