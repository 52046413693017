import { Grid, makeStyles } from '@material-ui/core';
import _get from 'lodash/get';
import React, { FC, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { CONDITION_TYPE_CHOICES } from '../../Resources/FeeProfile/constants';
import { amountFormatter } from '../../utils/data-formatters';
import { AppBold, AppText } from '../ui/text';

const useStyles = makeStyles(
  () => ({
    text: {
      textAlign: 'center',
    },
  }),
  { name: 'FeeConditionRecap' },
);

type FeeConditionTextProps = {
  feePercentage: string;
  type: string;
  offerAmount: number;
  className?: string;
};

export const FeeConditionText: FC<FeeConditionTextProps> = ({
  className,
  feePercentage,
  type,
  offerAmount,
}) => (
  <AppText className={className}>
    <AppBold>
      {'> '}
      {`Applica ${+feePercentage >= 2 ? 'il ' : "l'"}${amountFormatter(
        +feePercentage,
        false,
        1,
      )}% di cauzione se l'importo è ${CONDITION_TYPE_CHOICES.find(
        (condType) => condType.id === type,
      )?.name?.toLowerCase()} ${amountFormatter(offerAmount, true)} euro.`}
    </AppBold>
  </AppText>
);

const FEE_PERCENTAGE_SOURCE = 'feePercentage';
const OFFER_AMOUNT_SOURCE = 'offerAmount';
const TYPE_SORCE = 'type';

type FeeConditionRecapProps = {
  conditionsSource: string;
  applyFeeSource: string;
};

export const FeeConditionsRecap: FC<FeeConditionRecapProps> = React.memo(
  ({ conditionsSource, applyFeeSource }) => {
    const classes = useStyles();
    const { values } = useFormState();

    const conditions = useMemo<Record<string, any>[]>(
      () => _get(values, conditionsSource, []),
      [_get(values, conditionsSource)],
    );

    if (!conditions.length || (values[applyFeeSource] !== undefined && !values[applyFeeSource]))
      return <></>;

    return (
      <Grid item md={12}>
        <AppText className={classes.text}>Con le attuali condizioni impostate:</AppText>
        {conditions.map(
          (condition) =>
            !!condition?.[FEE_PERCENTAGE_SOURCE] &&
            !!condition?.[OFFER_AMOUNT_SOURCE] &&
            !!condition?.[TYPE_SORCE] && (
              <FeeConditionText
                className={classes.text}
                feePercentage={condition[FEE_PERCENTAGE_SOURCE]}
                offerAmount={condition[OFFER_AMOUNT_SOURCE]}
                type={condition[TYPE_SORCE]}
              />
            ),
        )}
      </Grid>
    );
  },
);
