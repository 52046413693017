import { Grid } from '@material-ui/core';
import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { truncateText } from '../../utils/data-formatters';
import { getReference } from '../../utils/reference-selector';
import { maxDate } from '../../utils/validators';
import AddPersonWithRole from '../capability/app-capability-add-person-with-role';
import AppAlert from '../ui/alert/AppAlert';
import AppCard from '../ui/card/AppCard';
import { AppAutocompleteInput, AppDateInput } from '../ui/input';
import AppDivider from '../ui/layout/AppDivider';
import AddPersonRoleButtons from './AddPersonRoleButtons';

interface MandatoryOrSuggestedRolesProps {
  resourceType: 'item' | 'folder';
  disabled?: boolean;
  basePath?: string;
}

const MandatoryOrSuggestedRoles: FC<MandatoryOrSuggestedRolesProps> = (props) => {
  const { resourceType, disabled } = props;
  const { values: resourceValues } = useFormState();
  const { change } = useForm();
  const notify = useNotify();

  const [relatedFolder, setRelatedFolder] = useState<Record<string, any>>({});
  const [mutateRelatedFolder] = useMutation();
  useEffect(() => {
    if (resourceType !== 'item' || !resourceValues.fkFolder) return;

    // only if resource is item, reset mandatory/suggested roles and get selected folder's info
    change('mandatoryRoles', undefined);
    change('suggestedRoles', undefined);

    const reference = getReference(props.basePath || '', 'folders');

    mutateRelatedFolder(
      {
        type: 'getOne',
        resource: reference,
        payload: {
          id: resourceValues.fkFolder,
        },
      },
      {
        onSuccess: (res) => setRelatedFolder(res.data),
        onFailure: (err) => console.error(err),
      },
    );
  }, [resourceValues.fkFolder]);

  const [roles, setRoles] = useState<Record<string, any>[]>([]);
  const [mutateRoles] = useMutation();

  useEffect(() => {
    handleRolesMutation();
  }, [resourceType === 'folder' ? resourceValues.fkPvpRite : relatedFolder.fkPvpRite]);

  const handleRolesMutation = () =>
    (resourceType === 'folder' ? resourceValues.fkPvpRite : relatedFolder.fkPvpRite) &&
    mutateRoles(
      {
        type: 'getList',
        resource: 'roles/mandatory-or-suggested',
        payload: {
          filter: {
            pvpRite: resourceType === 'folder' ? resourceValues.fkPvpRite : relatedFolder.fkPvpRite,
            [resourceType]: true,
          },
        },
      },
      {
        onSuccess: (res) => setRoles(res.data),
        onFailure: (err) => {
          notify('Non è stato possibile recuperare i ruoli obbligatori/suggeriti.', 'error');
          console.error(err);
        },
      },
    );

  const [personRoles, setPersonRoles] = useState<Record<string, any>[]>([]);
  const [mutatePersonRoles] = useMutation();

  useEffect(() => {
    handlePersonRolesMutation();
  }, [resourceType === 'folder' ? resourceValues.fkCourt : relatedFolder.fkCourt]);

  const handlePersonRolesMutation = () =>
    mutatePersonRoles(
      {
        type: 'getList',
        resource: 'person-roles',
        payload: {
          filter: {
            fkCourt:
              (resourceType === 'folder' ? resourceValues.fkCourt : relatedFolder.fkCourt) ?? null,
            relations: ['person'],
          },
        },
      },
      {
        onSuccess: (res) => setPersonRoles(res.data),
        onFailure: (err) => {
          notify(
            "Non è stato possibile recuperare le anagrafiche per l'assegnazione degli eventuali ruoli obbligatori/suggeriti.",
            'error',
          );
          console.error(err);
        },
      },
    );

  /// region ADD NEW PERSON-ROLE

  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<'person' | 'company' | null>(null);
  const [currentChoices, setCurrentChoices] = useState<Record<string, any>>([]);
  const [currentChangeTarget, setCurrentChangeTarget] = useState<string | undefined>();
  const [currentRoleId, setCurrentRoleId] = useState<number | undefined>();

  const handleClickOpen = (
    type: 'person' | 'company' | null,
    currentChoices: Record<string, any>[],
    currentChangeTarget: string,
    currentRoleId: number,
  ) => {
    setOpen(true);
    setType(type);
    setCurrentChoices(currentChoices);
    setCurrentChangeTarget(currentChangeTarget);
    setCurrentRoleId(currentRoleId);
  };

  /// endregion

  return (
    <Fragment>
      <AppCard title="Ruoli obbligatori" expanded disabled={disabled}>
        {roles
          .filter((role) =>
            role.ritesOnWhichIsMandatory.includes(
              resourceType === 'folder' ? resourceValues.fkPvpRite : relatedFolder.fkPvpRite,
            ),
          )
          .map((mandatoryRole) => {
            const choices = personRoles
              .filter((personRole) => personRole.fkRole === mandatoryRole.id)
              .map((personRole) => personRole.person);
            const isOutdated =
              resourceValues.mandatoryRoles &&
              resourceValues.mandatoryRoles[`role${mandatoryRole.id}`] &&
              resourceValues.mandatoryRoles[`role${mandatoryRole.id}`].validityTo <
                new Date().toISOString();

            return [
              isOutdated ? (
                <Grid item md={12}>
                  <AppAlert severity="warning">
                    Il ruolo è scaduto, è necessario aggiornare la data di termine validità
                  </AppAlert>
                </Grid>
              ) : (
                <span />
              ),
              <AppAutocompleteInput
                choices={choices}
                label={mandatoryRole.description}
                source={`mandatoryRoles.role${mandatoryRole.id}.fkPerson`}
                variant="outlined"
                optionText={(person) => {
                  if (!person) return '';
                  return `${person.businessName} (${(person.taxCode || person.vat) ?? '-'})`;
                }}
                suggestionLimit={5}
                required
                md={4}
              />,
              <AddPersonRoleButtons
                role={mandatoryRole}
                handleClickOpen={handleClickOpen}
                choices={choices}
                changeTarget={`mandatoryRoles.role${mandatoryRole.id}.fkPerson`}
                hidden={disabled}
              />,
              <AppDateInput
                source={`mandatoryRoles.role${mandatoryRole.id}.validityFrom`}
                label={`Inizio validità (${truncateText(mandatoryRole.description)})`}
                variant="outlined"
                required
                validate={[
                  maxDate(
                    `mandatoryRoles.role${mandatoryRole.id}.validityTo`,
                    'Termine validità',
                    false,
                  ),
                ]}
                md={3}
              />,
              <AppDateInput
                source={`mandatoryRoles.role${mandatoryRole.id}.validityTo`}
                label={`Termine validità (${truncateText(mandatoryRole.description)})`}
                variant="outlined"
                md={3}
              />,
              <AppDivider />,
            ];
          })}
      </AppCard>
      <AppCard title="Ruoli suggeriti" disabled={disabled}>
        {roles
          .filter((role) =>
            role.ritesOnWhichIsSuggested.includes(
              resourceType === 'folder' ? resourceValues.fkPvpRite : relatedFolder.fkPvpRite,
            ),
          )
          .map((suggestedRole) => {
            const choices = personRoles
              .filter((personRole) => personRole.fkRole === suggestedRole.id)
              .map((personRole) => personRole.person);
            const showDates =
              resourceValues.suggestedRoles &&
              resourceValues.suggestedRoles[`role${suggestedRole.id}`] &&
              resourceValues.suggestedRoles[`role${suggestedRole.id}`].fkPerson;

            const isOutdated =
              resourceValues.suggestedRoles &&
              resourceValues.suggestedRoles[`role${suggestedRole.id}`] &&
              resourceValues.suggestedRoles[`role${suggestedRole.id}`].validityTo <
                new Date().toISOString();

            return [
              isOutdated ? (
                <Grid item md={12}>
                  <AppAlert severity="warning">
                    Il ruolo è scaduto, è necessario aggiornare la data di termine validità
                  </AppAlert>
                </Grid>
              ) : (
                <span />
              ),
              <AppAutocompleteInput
                choices={choices}
                label={suggestedRole.description}
                source={`suggestedRoles.role${suggestedRole.id}.fkPerson`}
                variant="outlined"
                optionText={(person) => {
                  if (!person) return '';
                  return `${person.businessName} (${(person.taxCode || person.vat) ?? '-'})`;
                }}
                optionValue="id"
                suggestionLimit={5}
                md={4}
                resettable
              />,
              <AddPersonRoleButtons
                role={suggestedRole}
                handleClickOpen={handleClickOpen}
                choices={choices}
                changeTarget={`suggestedRoles.role${suggestedRole.id}.fkPerson`}
                hidden={disabled}
              />,
              showDates ? (
                [
                  <AppDateInput
                    source={`suggestedRoles.role${suggestedRole.id}.validityFrom`}
                    label={`Inizio validità (${truncateText(suggestedRole.description)})`}
                    variant="outlined"
                    required
                    validate={[
                      maxDate(
                        `suggestedRoles.role${suggestedRole.id}.validityTo`,
                        'Termine validità',
                        false,
                      ),
                    ]}
                    md={3}
                  />,
                  <AppDateInput
                    source={`suggestedRoles.role${suggestedRole.id}.validityTo`}
                    label={`Termine validità (${truncateText(suggestedRole.description)})`}
                    variant="outlined"
                    md={3}
                  />,
                ]
              ) : (
                <span />
              ),
              <AppDivider />,
            ];
          })}
      </AppCard>
      <AddPersonWithRole
        open={open}
        setOpen={setOpen}
        courtId={resourceType === 'folder' ? resourceValues.fkCourt : relatedFolder.fkCourt}
        type={type}
        getPersonRoles={handlePersonRolesMutation}
        currentChoices={currentChoices}
        changeTarget={currentChangeTarget}
        formValueChanger={change}
        roleId={currentRoleId}
      />
    </Fragment>
  );
};

export default MandatoryOrSuggestedRoles;
