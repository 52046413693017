import { Box, Button, Chip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Done, LocalAtm } from '@material-ui/icons';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Record as RaRecord, useMutation, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import AppAlert from '../../../../Components/ui/alert/AppAlert';
import AppButtonGroup from '../../../../Components/ui/button/AppButtonGroup';
import { AppBold, AppText } from '../../../../Components/ui/text';
import AppTooltip from '../../../../Components/ui/tooltip/AppTooltip';
import { useBasePath } from '../../../../hooks/use-base-path';
import { amountFormatter, dateFormatter } from '../../../../utils/data-formatters';
import AuctionCompletedAlert from '../AuctionAlert/auction-completed-alert';

const useStyles = makeStyles(
  (theme) => ({
    row: {
      display: 'flex',
      flex: '1 1 100%',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      '& div[role=group]': {
        margin: 0,
      },
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    container: {},
    winConfirmButton: {
      '&:disabled': {
        backgroundColor: green[500],
        color: 'black',
        fontWeight: 'bold',
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'AuctionCompleted' },
);

interface AuctionCompletedProps {
  saleExperiment: RaRecord;
  completedAuction: Record<string, any> | null;
  reservePriceInfo: Record<string, any>;
  updatedReservePrice: number | null;
}

export const AuctionCompleted: FC<AuctionCompletedProps> = (props) => {
  const { saleExperiment, completedAuction, updatedReservePrice } = props;
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();

  const basePath = useBasePath();

  /// region WIN CONFIRMATION

  const [winConfirmed, setWinConfirmed] = useState<boolean>(false);
  const [confirmWin, { loading: confirmWinLoading }] = useMutation();
  const handleWinConfirmation = useCallback(() => {
    confirmWin(
      {
        type: 'getOne',
        resource: `${basePath}/${saleExperiment.id}/confirm-win`,
        payload: {},
      },
      {
        onSuccess: () => {
          setWinConfirmed(true);
          notify('Aggiudicazione notificata al vincitore.', 'info');
        },
        onFailure: (err) => {
          notify(
            'Errore imprevisto durante la conferma di aggiudicazione. \nRiprovare più tardi o contattare un amministratore.',
          );
          console.error(err);
        },
      },
    );
  }, [saleExperiment]);

  /// endregion

  /// region DEPOSIT
  const allDepositsCancelled = useMemo(
    () => saleExperiment.allDepositsCanceled ?? false,
    [saleExperiment],
  );

  const [mutateCancelWinnerDeposit, { loading: cancelWDLoading }] = useMutation();
  const cancelWinnerDeposit = useCallback(() => {
    mutateCancelWinnerDeposit(
      {
        type: 'getOne',
        resource: `participations/${saleExperiment.winner}/cancel-deposit`,
        payload: {},
      },
      {
        onSuccess: () => notify('Deposito cauzionale rilasciato correttamente.', 'info'),
        onFailure: (err) => {
          notify(
            'Errore imprevisto durante il rilascio della cauzione. \nRiprovare più tardi o contattare un amministratore.',
          );
          console.error(err);
        },
      },
    );
  }, [saleExperiment]);

  const [mutateCaptureWinnerDeposit, { loading: captureWDLoading }] = useMutation();
  const captureWinnerDeposit = useCallback(() => {
    mutateCaptureWinnerDeposit(
      {
        type: 'getOne',
        resource: `participations/${saleExperiment.winner}/capture-deposit`,
        payload: {},
      },
      {
        onSuccess: () => notify('Deposito cauzionale incassato correttamente.', 'info'),
        onFailure: (err) => {
          notify(
            "Errore imprevisto durante l'incasso della cauzione. \nRiprovare più tardi o contattare un amministratore.",
          );
          console.error(err);
        },
      },
    );
  }, [saleExperiment]);

  /// endregion

  /// region GET WINNER

  const [mutateWinner] = useMutation();
  const [winner, setWinner] = useState<Record<string, any> | undefined>(undefined);
  const getWinner = (resource: string, payload: any = {}) =>
    mutateWinner(
      {
        type: 'getOne',
        resource,
        payload,
      },
      {
        onSuccess: (res) => setWinner(res.data),
        onFailure: (err) => console.error(err),
      },
    );

  /// endregion

  useEffect(() => {
    if (completedAuction) {
      if (completedAuction.winner > 0) {
        getWinner('participations', { id: completedAuction.winner });
      }
    } else if (saleExperiment) {
      if (saleExperiment.awardPrice) getWinner(`${basePath}/${saleExperiment.id}/winner`);
    }

    setWinConfirmed(saleExperiment.winConfirmed);
  }, [completedAuction, saleExperiment]);

  return !winner ? (
    <React.Fragment />
  ) : winner.awarded ? (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <AppText variant="h5">GARA TERMINATA</AppText>
      </Box>
      <Box className={classes.row}>
        <AppTooltip title="Invia la email di aggiudicazione al vincitore">
          <Button
            variant="outlined"
            color="primary"
            disabled={winConfirmed || confirmWinLoading}
            onClick={handleWinConfirmation}
            className={classes.winConfirmButton}
            endIcon={winConfirmed ? <Done /> : undefined}
          >
            {winConfirmed ? 'Aggiudicazione confermata' : 'Conferma aggiudicazione'}
          </Button>
        </AppTooltip>
      </Box>
      <Box className={classes.row}>
        <AppButtonGroup
          disabled={!winner.depositCanBeCanceled}
          classes={{ buttonGroup: { marginRight: 0 } }}
          variant="outlined"
          title="Azioni cauzione aggiudicatario"
          icon={<LocalAtm />}
        >
          <Button variant="outlined" disabled={captureWDLoading} onClick={captureWinnerDeposit}>
            Incassa cauzione
          </Button>
          <Button variant="outlined" disabled={cancelWDLoading} onClick={cancelWinnerDeposit}>
            Rilascia cauzione
          </Button>
        </AppButtonGroup>
      </Box>
      {allDepositsCancelled && (
        <Box className={classes.row}>
          <AppText>
            Le <AppBold>cauzioni dei non aggiudicatari</AppBold> versate con carta di credito sono
            state <AppBold>rilasciate</AppBold>.
          </AppText>
        </Box>
      )}
      <Box className={classes.row}>
        <AppText>
          <strong>Vincitore:&nbsp;</strong>
          <Chip
            color="primary"
            onClick={() =>
              winner &&
              winner.presenter &&
              history.push(
                `/participants/${winner.presenter.id}/show?goBack=true&resourceLabel=Esperimento di vendita&previousResourcePath=${history.location.pathname}`,
              )
            }
            label={
              winner && winner.presenter
                ? `${winner.presenter.lastName} ${winner.presenter.firstName} (${
                    winner.presenter.taxCode ?? '-'
                  })`
                : 'Non disponibile'
            }
          />
        </AppText>
        <AppText>
          <strong>Offerta vincente:&nbsp;</strong>
          <Chip
            label={amountFormatter(completedAuction?.bestOffer || saleExperiment?.awardPrice, true)}
          />
        </AppText>
        {updatedReservePrice && updatedReservePrice > 0 && (
          <AppText>
            <strong>Prezzo di riserva:&nbsp;</strong>
            <Chip label={amountFormatter(updatedReservePrice, true)} />
          </AppText>
        )}
        <AppText>
          <strong>Data/ora termine:</strong>{' '}
          <Chip
            label={dateFormatter(completedAuction?.closeTS || saleExperiment.auctionClosedAt)}
          />
        </AppText>
      </Box>

      {saleExperiment.notes && (
        <AppAlert
          title="Note riguardo l'esito della gara"
          severity="info"
          variant="filled"
          className={classes.alert}
        >
          {saleExperiment.notes}
        </AppAlert>
      )}
    </Box>
  ) : (
    <AuctionCompletedAlert reservePriceInfo={props.reservePriceInfo} />
  );
};
