import React, { FC } from 'react';
import { FormDataConsumer, useCheckMinimumRequiredProps } from 'react-admin';
import AppCard from '../ui/card/AppCard';
import { AppBooleanInput } from '../ui/input';
import AppInternationalGeographiesInput from '../ui/input/AppInternationalGeographiesInput';

export interface AddressDataProps {
  primaryAddress: string;
  secondaryAddress: string;
  geocoderRef?: any;

  [k: string]: any;
}

const AppAddressData: FC<AddressDataProps> = (props) => {
  useCheckMinimumRequiredProps(
    'AppAddressData',
    ['record', 'mode', 'primaryAddress', 'secondaryAddress'],
    props,
  );

  const { primaryAddress, secondaryAddress, mode, record, disabled } = props;

  return (
    <React.Fragment>
      <AppCard {...{ mode, record, disabled }} title={primaryAddress}>
        <AppInternationalGeographiesInput
          variant="outlined"
          addressSource="residenceAddress"
          addressNumberSource="residenceAddressNumber"
          zipCodeSource="residenceZipCode"
          countrySource="residenceCountry"
          regionSource="residenceRegion"
          provinceSource="residenceProvince"
          citySource="residenceCity"
          foreignCitySource="residenceForeignCity"
        />
      </AppCard>
      <AppCard {...{ mode, record, disabled }} title={secondaryAddress}>
        <AppBooleanInput
          source="domicileIsResidence"
          label={'Coincide con la ' + primaryAddress.toLowerCase()}
          initialValue={true}
        />
        <FormDataConsumer>
          {({ formData }) =>
            !formData.domicileIsResidence && (
              <AppInternationalGeographiesInput
                record={formData}
                variant="outlined"
                addressSource="domicileAddress"
                addressNumberSource="domicileAddressNumber"
                zipCodeSource="domicileZipCode"
                countrySource="domicileCountry"
                regionSource="domicileRegion"
                provinceSource="domicileProvince"
                citySource="domicileCity"
                foreignCitySource="domicileForeignCity"
                mode={mode}
                disabled={disabled}
              />
            )
          }
        </FormDataConsumer>
      </AppCard>
    </React.Fragment>
  );
};

export default AppAddressData;
