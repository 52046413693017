import { Button, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useToggleState } from '../../hooks/use-toggle-state';
import { OriginType } from '../../utils/constants';
import AppConfirmDialog from '../ui/dialog/confirm-dialog/app-confirm-dialog';
import AppMessageDialog from '../ui/dialog/message-dialog/app-message-dialog';
import AppTooltip from '../ui/tooltip/AppTooltip';

const useStyles = makeStyles(
  () => ({
    button: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'GenerateNewPinButton' },
);

export const GenerateNewPinButton: FC<any> = React.memo(({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [formDialogOpen, toggleFormDialog] = useToggleState();
  const [newPin, setNewPin] = useState<string | null>(null);
  const [pinDialogOpen, togglePinDialog] = useToggleState();

  const [mutateParticipationPin] = useMutation();
  const onConfirm = () => {
    mutateParticipationPin(
      {
        type: 'getOne',
        resource: `participations/${record.id}/generate-new-pin`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          toggleFormDialog();
          setNewPin(res.data.newPin);
          togglePinDialog();
          notify('Nuovo PIN generato e inviato con successo.');
        },
        onFailure: (err) => {
          console.error(err);
          toggleFormDialog();
          notify(`Generazione nuovo PIN fallita: ${err?.message ?? 'errore sconosciuto'}`, 'error');
        },
      },
    );
  };

  if (record.origin === OriginType.Paper) return <></>;

  return (
    <>
      <AppTooltip title="Permette di generare un nuovo PIN per il partecipante e sostituire quello precedente.">
        <Button
          children="Genera nuovo PIN"
          variant="outlined"
          onClick={toggleFormDialog}
          size="small"
          className={classes.button}
        />
      </AppTooltip>
      <AppConfirmDialog
        open={formDialogOpen}
        onClose={toggleFormDialog}
        onConfirm={onConfirm}
        title="Genera nuovo PIN"
        details="Attenzione! Il PIN corrente non sarà più valido"
      />
      <AppMessageDialog
        open={pinDialogOpen}
        onClose={togglePinDialog}
        title="Nuovo PIN partecipante"
        content={`Il nuovo PIN del partecipante ${record.presenter.lastName} ${record.presenter.firstName} è ${newPin}. Attenzione: alla chiusura di questa modale non sarà più possibile visualizzarlo.`}
      />
    </>
  );
});
