import { makeStyles } from '@material-ui/core';
import { useNotify } from 'ra-core';
import React, { FC } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import RequestButton from '../utilities/request-button';

const useStyles = makeStyles(
  () => ({
    createSaleButton: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'RequestLinkSaleCreation' },
);

interface RequestLinkSaleCreationProps {
  pvpRequestId: string;
  closeDialog: () => void;
  label: string;
  itemId?: number; // if defined create sale on existing item, otherwise create item and sale from scratch
  Icon?: any;
}

const RequestLinkSaleCreation: FC<RequestLinkSaleCreationProps> = ({
  pvpRequestId,
  closeDialog,
  label,
  itemId,
  Icon,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const classes = useStyles();

  const [createNewSale] = useMutation();
  const handleSaleCreation = () =>
    createNewSale(
      {
        type: 'getOne',
        resource: `pvp-publication-requests/${pvpRequestId}/create-sale-from-request${
          itemId ? `?itemId=${itemId}` : ''
        }`,
        payload: {},
      },
      {
        onSuccess: () => {
          notify(itemId ? 'Vendita creata con successo.' : 'Lotto creato con successo.', 'info');
          refresh();
          closeDialog();
        },
        onFailure: (err) => {
          console.error(err);
          notify(`Operazione fallita${err?.message ? `:${err.message}` : ''}.`, 'error');
        },
      },
    );

  return (
    <RequestButton
      variant={itemId ? 'outlined' : 'contained'}
      color={itemId ? 'secondary' : 'primary'}
      children={label}
      className={classes.createSaleButton}
      onClick={handleSaleCreation}
      startIcon={Icon ? <Icon /> : undefined}
    />
  );
};

export default RequestLinkSaleCreation;
