import { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppDateTimeInput, AppNumberInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import { METHODS_CHOICES, ENTITIES_CHOICES } from '../Booking/constants';
import OpLogDatagrid from './OpLogDatagrid';

const OpLogListFilters = (
  <AppListFilter disableFulltextSearch>
    <AppNumberInput source="user" label="Id utente" />
    <AppDateTimeInput source="ts" label="Data" />
    <AppSelectInput choices={ENTITIES_CHOICES} source="entity" label="Entità" />
    <AppSelectInput choices={METHODS_CHOICES} source="method" label="Metodo" />
  </AppListFilter>
);

const OpLogList: FunctionComponent<ListProps> = (props) => {
  return (
    <AppList
      {...props}
      title={<AppTitle title="Logs" />}
      filters={OpLogListFilters}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
    >
      <OpLogDatagrid showUser />
    </AppList>
  );
};

export default OpLogList;
