import { concat as _concat } from 'lodash';
import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { getRefFieldSource, getRefToChoice } from '../../../utils/calculators';
import { calcPercentageOfValue, getPercentage } from '../../../utils/numbers';
import AppSectionTitle from '../layout/AppSectionTitle';
import { AppCalculatorPercentageInput } from './AppCalculatorInputs';
import { AppInputFiller } from './AppInputFiller';
import { AppNumberInput, AppNumberInputProps } from './AppNumberInput';
import { AppSelectInput } from './AppSelectInput';

interface AppAmountCalculatorProps extends AppNumberInputProps {
  refToSource: string; // field of reference select source
  refToChoices?: Record<string, any>[]; // array of fields of the form that the original field can reference to
  refToExternalChoices?: Record<string, any>[]; // array of fields/values that are NOT part of the form (e.g. bid amount, award price...) that the original field can reference to
  refToDefaultValue?: string;
}

const BASE_CHOICE = { id: 'no-ref', name: 'Importo fisso' };

// TODO: case "depositPercentageOnFirstOffer" can be improved, and it's only used on a specific SaleExperiment field (depositPrice)

export const AppAmountCalculator: FC<AppAmountCalculatorProps> = (props) => {
  const {
    source: originalFieldSource,
    label,
    refToSource,
    refToChoices = [],
    refToExternalChoices = [],
    refToDefaultValue,
    required,
    variant = 'outlined',
    record,
    mode,
    disabled,
  } = props;
  const fieldDisabled = disabled ?? mode === 'show';

  const { values } = useFormState();
  const { change } = useForm();

  const [percentage, setPercentage] = useState(() => {
    if (mode === 'create' || !!getRefToChoice(record[refToSource], refToExternalChoices)) {
      return 0;
    }

    return getPercentage(record[originalFieldSource], record[record[refToSource]]);
  });

  /// region INPUT FIELDS CHANGES

  // if referenced input changes (e.g. "basePrice")
  useEffect(() => {
    if (
      values[refToSource] === 'no-ref' ||
      !values[values[refToSource]] ||
      values[originalFieldSource] === calcPercentageOfValue(values[values[refToSource]], percentage)
    ) {
      return;
    }

    change(originalFieldSource, calcPercentageOfValue(values[values[refToSource]], percentage));
  }, [values[getRefFieldSource(values, refToSource)]]);

  const onPercentageChange = (newPercentage: number) => {
    if (values[values[refToSource]] === undefined || isNaN(newPercentage)) {
      return setPercentage(0);
    }

    setPercentage(newPercentage);

    change(
      originalFieldSource,
      calcPercentageOfValue(values[values[refToSource]], newPercentage) ?? 0,
    );
  };

  // e.g. from "Importo fisso" to "Prezzo base"
  const onRefSourceChange = (refToSource: string) => {
    if (refToSource === 'no-ref') {
      return;
    }

    change(originalFieldSource, calcPercentageOfValue(values[values[refToSource]], percentage));
  };

  const onOriginalFieldChange = (newValue: number) => {
    if (values[refToSource] === 'no-ref') {
      return;
    }

    setPercentage(getPercentage(newValue, values[values[refToSource]]));
  };

  /// endregion

  return (
    <Fragment>
      <AppSectionTitle>{label}</AppSectionTitle>
      {!getRefToChoice(values[refToSource], refToExternalChoices) && (
        <AppCalculatorPercentageInput
          label={`Percentuale su ${
            getRefToChoice(values[refToSource], refToChoices)?.name.toLowerCase() ?? '-'
          }`}
          value={percentage}
          onChange={(e) => onPercentageChange(+e.target.value)}
          variant={variant}
          disabled={fieldDisabled || values[refToSource] === 'no-ref'}
        />
      )}
      <AppSelectInput
        source={refToSource}
        label="Importo di riferimento"
        choices={_concat<Record<string, any>>(BASE_CHOICE, refToChoices, refToExternalChoices)}
        md={4}
        variant={variant}
        initialValue={values[refToSource] ?? refToDefaultValue ?? 'no-ref'}
        onChange={(e) => onRefSourceChange(e.target.value)}
        required
        disabled={fieldDisabled}
      />
      {!getRefToChoice(values[refToSource], refToExternalChoices) ? (
        <AppNumberInput
          label={`${label} totale`}
          source={originalFieldSource}
          inputMode="decimal"
          md={4}
          required={required}
          isAmount
          variant={variant}
          onChange={(e) => onOriginalFieldChange(e.target.value)}
          disabled={fieldDisabled}
        />
      ) : (
        <Fragment>
          <AppNumberInput
            source={values[refToSource]}
            label={`% su ${
              getRefToChoice(values[refToSource], refToExternalChoices)?.name.toLowerCase() ?? '-'
            }`}
            md={4}
            variant="outlined"
            required
            disabled={fieldDisabled}
          />
          <AppInputFiller md={4} />
        </Fragment>
      )}
    </Fragment>
  );
};
