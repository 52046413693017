import React, { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { FOLDER_STATUSES } from '../../utils/constants';
import FolderDatagrid from './FolderDatagrid';
import { FOLDER_PERMISSIONS } from './index';

const FolderListFilters = (
  <AppListFilter
    initialFilters={{
      ['folder_status']: 'active',
    }}
  >
    <AppTextInput source="title" label="Nome procedura" md={3} />
    <AppTextInput source="procedureCode" label="Numero procedura" md={3} />
    <AppAutocompleteInput
      reference="pvp/rites"
      source="folder_fkPvpRite"
      label="Rito"
      optionText="description"
      resettable
      suggestionLimit={5}
    />
    <AppAutocompleteInput
      reference="courts"
      source="folder_fkCourt"
      label="Tribunale"
      md={4}
      resettable
      suggestionLimit={5}
    />
    <AppTextInput source="folder_pvpProcedureId" label="Identificativo PVP" md={4} />
    <AppTextInput source="folder_internalCode" label="Progressivo interno del gestore" md={4} />
    <AppSelectInput
      source="folder_status"
      label="Stato fascicolo"
      choices={FOLDER_STATUSES}
      md={4}
      allowEmpty
    />
  </AppListFilter>
);

const FolderList: FunctionComponent<ListProps> = (props) => {
  return (
    <AppList
      {...props}
      title={<AppTitle title={props.options.label} />}
      filters={FolderListFilters}
      filter={{ _source: 'list' }}
      filterDefaultValues={{
        ['folder_status']: 'active',
      }}
      resourcePermissions={FOLDER_PERMISSIONS}
    >
      <FolderDatagrid rowClick="show" permissions={props.permissions} />
    </AppList>
  );
};

export default FolderList;
