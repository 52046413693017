import {
  Container,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { useMutation } from 'ra-core';
import { Labeled } from 'ra-ui-materialui';
import { ReactElement, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { ChipPanelField } from '../../Components/chip/chip-panel-field';
import { DetailPanelField } from '../../Components/detail-panel';
import AppAlert from '../../Components/ui/alert/AppAlert';
import AppCard from '../../Components/ui/card/AppCard';
import { AppTextField } from '../../Components/ui/field';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { amountFormatter, dateFormatter } from '../../utils/data-formatters';
import { ECWID_EVENT_COLORS, ECWID_EVENT_STATUSES, ECWID_EVENT_TYPES } from './constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const EcwidEventDetails = (props: Record<any, any>): ReactElement => {
  const [mutateEcwidEventDetail, { loading: ecwidEventLoading }] = useMutation();
  const [ecwidEventDetail, setEcwidEventDetail] = useState<any>();

  useEffect(() => {
    mutateEcwidEventDetail(
      {
        type: 'getOne',
        resource: `ecwid/${props.record.id}`,
        payload: {},
      },
      {
        onSuccess: (res) =>
          setEcwidEventDetail(Object.values(res.data).length > 0 ? res.data : null),
        onFailure: (err) => {
          console.error('[EcwidEventDetail] Error retrieving ecwid event detail:', err);
        },
      },
    );
  }, []);

  const classes = useStyles();

  console.log('ecwidEventDetail', ecwidEventDetail);

  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale">
        {ecwidEventDetail && ecwidEventDetail.entityData ? (
          <>
            <AppCard title="Riepilogo ordine" expanded>
              <DetailPanelField label="ID ordine" value={ecwidEventDetail.data.orderId} md={6} />
              <DetailPanelField
                label="Data ordine"
                value={dateFormatter(ecwidEventDetail.entityData.createDate)}
                md={6}
              />
              <DetailPanelField label="Email" value={ecwidEventDetail.entityData.email} md={6} />
              <DetailPanelField
                label="URL annuncio"
                value={ecwidEventDetail.entityData.refererUrl}
                md={6}
              />
              <DetailPanelField
                label="Metodo di spedizione"
                value={ecwidEventDetail.entityData.shippingOption.shippingMethodName}
                md={6}
              />
              <DetailPanelField
                label="Metodo di pagamento"
                value={ecwidEventDetail.entityData.paymentMethod}
                md={6}
              />
              <DetailPanelField
                label="Prezzo di spedizione"
                value={amountFormatter(
                  ecwidEventDetail.entityData.shippingOption.shippingRate,
                  true,
                  2,
                )}
                md={6}
              />
              <DetailPanelField
                label="Totale"
                value={amountFormatter(ecwidEventDetail.entityData.total, true, 2)}
                md={6}
              />
              <DetailPanelField
                label="Commenti ordine"
                value={ecwidEventDetail.entityData.orderComments}
                md={12}
              />
              <Link
                href={`https://my.ecwid.com/store/${process.env.REACT_APP_ECWID_STORE_ID}#order:id=${ecwidEventDetail.data.orderId}&use_cache=true&return=orders`}
                target="_blank"
              >
                <AppTextField isUrl customText="Visualizza su Ecwid" />
              </Link>
            </AppCard>

            {ecwidEventDetail.entityData.items.length > 0 && (
              <AppCard title="Prodotti" expanded>
                <TableContainer component={Container}>
                  <Table className={classes.table} size="small" aria-label="Elenco prodotti">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Lotto</StyledTableCell>
                        <StyledTableCell align="right">ExternalReferenceId</StyledTableCell>
                        <StyledTableCell align="right">Prezzo</StyledTableCell>
                        <StyledTableCell align="right">Quantità</StyledTableCell>
                        <StyledTableCell align="right">Prezzo spedizione</StyledTableCell>
                        <StyledTableCell align="right">SKU</StyledTableCell>
                        <StyledTableCell align="right">Variante</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ecwidEventDetail.entityData.items.map((item: any) => (
                        <StyledTableRow key={item.name}>
                          <StyledTableCell component="th" scope="row">
                            <Link
                              href={`#/${item.judiciary ? 'items-judiciary' : 'items-private'}/${
                                item.itemId
                              }?goBack=true&resourceLabel=Dettaglio evento ecwid&previousResourcePath=/ecwid/${
                                ecwidEventDetail.id
                              }/show`}
                            >
                              {item.name}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.externalReferenceId}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {amountFormatter(item.price, true, 2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">{item.quantity}</StyledTableCell>
                          <StyledTableCell align="right">
                            {amountFormatter(item.shipping, true, 2)}
                          </StyledTableCell>
                          <StyledTableCell align="right">{item.sku}</StyledTableCell>
                          <StyledTableCell align="right">
                            {item.selectedOptions?.length > 0 ? (
                              <>
                                {item.selectedOptions.map((option: any) => (
                                  <>
                                    {option.name}: {option.value}
                                    <br />
                                  </>
                                ))}
                              </>
                            ) : (
                              <>---</>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AppCard>
            )}

            {ecwidEventDetail.entityData.shippingPerson && (
              <AppCard title="Spedizione" expanded>
                <DetailPanelField
                  label="Nome"
                  value={ecwidEventDetail.entityData.shippingPerson.firstName}
                  md={6}
                />
                <DetailPanelField
                  label="Cognome"
                  value={ecwidEventDetail.entityData.shippingPerson.lastName}
                  md={6}
                />
                <DetailPanelField
                  label="Telefono"
                  value={ecwidEventDetail.entityData.shippingPerson.phone}
                  md={6}
                />
                <DetailPanelField
                  label="Nazione"
                  value={`${ecwidEventDetail.entityData.shippingPerson.countryName} (${ecwidEventDetail.entityData.shippingPerson.countryCode})`}
                  md={6}
                />
                <DetailPanelField
                  label="Provincia"
                  value={`${ecwidEventDetail.entityData.shippingPerson.stateOrProvinceName} (${ecwidEventDetail.entityData.shippingPerson.stateOrProvinceCode})`}
                  md={6}
                />
                <DetailPanelField
                  label="Città"
                  value={ecwidEventDetail.entityData.shippingPerson.city}
                  md={6}
                />
                <DetailPanelField
                  label="Indirizzo"
                  value={ecwidEventDetail.entityData.shippingPerson.street}
                  md={12}
                />
              </AppCard>
            )}

            {ecwidEventDetail.entityData.additionalInfo &&
              Object.keys(ecwidEventDetail.entityData.additionalInfo).length > 0 && (
                <AppCard title="Informazioni aggiuntive">
                  <DetailPanelField
                    label="Numero carta"
                    value={ecwidEventDetail.entityData.additionalInfo.creditCard}
                    md={6}
                  />
                  <DetailPanelField
                    label="Scadenza carta"
                    value={`${ecwidEventDetail.entityData.additionalInfo.creditCardExpirationMonth} / ${ecwidEventDetail.entityData.additionalInfo.creditCardExpirationYear}`}
                    md={6}
                  />
                  <DetailPanelField
                    label="Circuito"
                    value={ecwidEventDetail.entityData.additionalInfo.stripeCreditCardBrand}
                    md={6}
                  />
                </AppCard>
              )}

            {ecwidEventDetail.entityData.customSurcharges?.length > 0 && (
              <AppCard title="Costi aggiuntivi">
                {ecwidEventDetail.entityData.customSurcharges.map((surcharge: any) => (
                  <DetailPanelField
                    label={ecwidEventDetail.entityData.extraFields[surcharge.id]}
                    value={surcharge.total}
                    md={6}
                  />
                ))}
              </AppCard>
            )}

            {ecwidEventDetail.entityData.orderExtraFields?.length > 0 && (
              <AppCard title="Campi aggiuntivi">
                {ecwidEventDetail.entityData.orderExtraFields
                  .filter((extraField) => extraField.customerInputType !== '')
                  .map((extraField) => (
                    <DetailPanelField label={extraField.title} value={extraField.value} md={6} />
                  ))}
              </AppCard>
            )}

            {/* INFORMAZIONI TECNICHE */}

            <AppCard title="Informazioni tecniche" collapsable expanded={false}>
              <DetailPanelField
                label="Tipo"
                value={ECWID_EVENT_TYPES.find((c) => c.id === ecwidEventDetail.eventType)?.name}
                md={6}
              />
              <ChipPanelField
                label="Stato"
                value={ECWID_EVENT_STATUSES.find((c) => c.id === ecwidEventDetail.status)?.name}
                style={{
                  backgroundColor: ECWID_EVENT_COLORS[ecwidEventDetail.status][0],
                  color: ECWID_EVENT_COLORS[ecwidEventDetail.status][1],
                }}
              />
              <DetailPanelField label="Tentativi" value={ecwidEventDetail.attempts} md={6} />
              <AppDivider />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Labeled label="Data">
                    <ReactJson
                      name={null}
                      src={ecwidEventDetail.data}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </Labeled>
                </Grid>
                <Grid item md={6}>
                  <Labeled label="Log">
                    <ReactJson
                      name={null}
                      src={ecwidEventDetail.log}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </Labeled>
                </Grid>
              </Grid>
              <AppDivider />
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Labeled label="Entity data">
                    <ReactJson
                      name={null}
                      src={ecwidEventDetail.entityData}
                      collapsed={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </Labeled>
                </Grid>
              </Grid>
            </AppCard>
          </>
        ) : (
          <AppCard expanded>
            {ecwidEventLoading ? (
              <AppAlert severity="info" title="Caricamento..." withGrid />
            ) : (
              <AppAlert severity="error" title="Nessun dettaglio disponibile" withGrid />
            )}
          </AppCard>
        )}
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default EcwidEventDetails;
