import { GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBalanceTwoTone } from '@material-ui/icons';
import { FC } from 'react';
import { FunctionField } from 'react-admin';
import { useHistory } from 'react-router';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';
import { useDashboardContext } from '../../../hooks';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { PersonRoles } from '../../../utils/constants';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import AppDashboardCard from '../components/app-dashboard-card';
import AppDashboardTable from '../components/table/app-dashboard-table';

const useStyles = makeStyles(
  (theme) => ({
    presenter: {
      minWidth: theme.spacing(30),
    },
    title: {
      minWidth: theme.spacing(30),
    },
    saleMode: {
      minWidth: theme.spacing(20),
    },
    auctionClosedAt: {
      minWidth: theme.spacing(30),
      width: '100%',
    },
  }),
  { name: 'AppDashboardPaymentsToBeVerified' },
);

const AppDashboardPaymentsToBeVerified: FC<GridProps> = (gridProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useDashboardContext();

  const allowView = useUserIsAllowed(ResourceAction.Get, 'participations');

  if (!allowView || user?.roleId === PersonRoles.Owner) return null;

  if (user?.roleId === PersonRoles.Auctioneer) return null;

  return (
    <AppDashboardCard {...gridProps}>
      <AppDashboardTable
        resource="participations/to-be-verified"
        tableTitle="Pagamenti da verificare"
        tableSubtitle="Di seguito sono elencati i partecipanti aggiudicatari il cui saldo deve essere verificato"
        noItemMessage="Tutti i pagamenti sono stati verificati."
        noItemIcon={<AccountBalanceTwoTone />}
        rowClick={(id, basePath, record) =>
          history.push(
            `items/${record.relatedSaleExperiment.relatedItem.id}/sale-experiments?goBack=true&resourceLabel=dashboard&previousResourcePath=${location.pathname}`,
          )
        }
      >
        <FunctionField
          label="Presentatore"
          headerClassName={classes.presenter}
          render={(record) => `${record?.presenter.firstName} ${record?.presenter.lastName}`}
        />
        <AppTextField
          source="relatedSaleExperiment.relatedItem.title"
          label="Lotto"
          headerClassName={classes.title}
          truncate
        />
        <AppTextField
          source="relatedSaleExperiment.relatedSaleMode.description"
          label="Modalità di vendita"
          headerClassName={classes.saleMode}
        />
        <AppDateField
          source="relatedSaleExperiment.auctionClosedAt"
          label="Data/ora di aggiudicazione"
          showTime
          headerClassName={classes.auctionClosedAt}
        />
      </AppDashboardTable>
    </AppDashboardCard>
  );
};

export default AppDashboardPaymentsToBeVerified;
