import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useContext } from 'react';
import { CODES_TYPES } from '../../utils/constants';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import { AppBooleanInput } from '../ui/input';
import { AppSelectInput } from '../ui/input';
import { AppTextInput } from '../ui/input';
import { FormDataConsumer } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    booleanInput: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'AppCodeManager' },
);

const AppCodeManager: FC<any> = (props) => {
  const { title, ...others } = props;
  const classes = useStyles();

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        ctx.manager.submit(data, close);
      }}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={80}
      height={60}
      {...others}
    >
      <AppSelectInput
        source="name"
        label="Nome"
        choices={CODES_TYPES}
        variant="outlined"
        md={12}
        required
        disabled={ctx.manager.mode === 'edit'}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.name === 3 && (
            <AppTextInput source="customName" label="Nome personalizzato" variant="outlined" />
          )
        }
      </FormDataConsumer>
      <AppTextInput source="value" label="Codice" variant="outlined" required />
      <AppBooleanInput
        source="publishOnline"
        label="Pubblica online"
        md={5}
        className={classes.booleanInput}
      />
    </AppFormDialog>
  );
};

export default AppCodeManager;
