import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { PAYMENT_METHODS, PaymentMethod } from '../../../Resources/Payment/constants';
import { minValue } from '../../../utils/validators';
import AppCard from '../../ui/card/AppCard';
import {
  AppBooleanInput,
  AppDateTimeInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../ui/input';
import AppDivider from '../../ui/layout/AppDivider';
import AppDateCalculator from '../../ui/input/AppDateCalculator';

const ParticipationOffer: FC<any> = React.memo((props) => {
  const { ctx, participationIsNotFromPaper, a107, auctionStatus, actionsDisabled } = props;

  const { values } = useFormState();
  const { change } = useForm();

  const { mainResourceRecord: saleExp } = ctx;

  /// region DEPOSIT

  const feeProfile: Record<string, any> = useMemo(
    () => ctx?.item?.feeProfile ?? {},
    [ctx?.item?.feeProfile],
  );

  const applyFee: boolean = useMemo(() => {
    if (feeProfile.applyFee !== undefined) {
      return feeProfile.applyFee;
    }

    return saleExp.hasFee;
  }, [feeProfile, saleExp.hasFee]);

  const paymentMethods = useMemo(() => {
    if (!applyFee) return [];

    return PAYMENT_METHODS.filter((paymentMethod) =>
      (feeProfile.feePaymentMethods?.length
        ? feeProfile.feePaymentMethods
        : saleExp.paymentMethods ?? []
      ).includes(paymentMethod.id),
    );
  }, [saleExp, feeProfile]);

  const calculateDepositTotal = (
    offerAmount: number | string,
    depositPaymentMethod: string,
  ): void => {
    if (!depositPaymentMethod || saleExp.depositPriceRefTo !== 'depositPercentageOnFirstOffer')
      return;

    const depositAmount = calculateDeposit(offerAmount);
    change('depositInfo.amount', depositAmount);
  };

  useEffect(() => {
    if (
      saleExp.depositPriceRefTo !== 'depositPercentageOnFirstOffer' ||
      !values.depositInfo?.paymentMethod ||
      !values.amount
    )
      return;

    calculateDepositTotal(values.amount, values.depositInfo.paymentMethod);
  }, [values.depositInfo?.paymentMethod, values.amount]);

  const calculateDeposit = (offerAmount: number | string): number => {
    const depositAmount = Number(
      (
        ((typeof offerAmount === 'string' ? parseFloat(offerAmount) : offerAmount) / 100) *
        saleExp.depositPercentageOnFirstOffer
      ).toFixed(2),
    );
    return isNaN(depositAmount) ? 0 : depositAmount;
  };

  /// endregion

  const minOffer = useMemo(() => {
    if (a107) {
      // Minimum art.107 offer
      return saleExp.awardPrice ? Math.ceil(+(saleExp.awardPrice * 1.1).toFixed(2)) : 0; // award price + 10%
    }

    // Minimum standard offer
    // TODO
    return 0;
  }, [a107, saleExp.awardPrice]);

  return (
    <Fragment>
      <AppCard
        title="Dati dell'offerta"
        expanded
        disabled={participationIsNotFromPaper || actionsDisabled}
      >
        <AppNumberInput
          source="amount"
          label="Importo offerta"
          required
          isAmount
          validate={[minValue(minOffer, 'offerta minima', true)]}
          initialValue={minOffer}
          helperText={"oltre oneri di legge, come indicato nell'avviso di vendita"}
        />
        <AppDateTimeInput source="offeredAt" label="Data/ora offerta" />
        <AppBooleanInput source="receivedAfterDueDate" label="Offerta fuori tempo massimo" md={5} />
      </AppCard>
      <AppCard
        hidden={!ctx.mainResourceRecord.hasFee}
        title="Deposito cauzionale"
        expanded
        disabled={auctionStatus === 'active' || participationIsNotFromPaper}
      >
        <AppSelectInput
          source="depositInfo.paymentMethod"
          label="Metodo di pagamento"
          md={12}
          choices={PAYMENT_METHODS}
          defaultValue={paymentMethods.length > 0 ? paymentMethods[0].id : PAYMENT_METHODS[0].id}
          required
          disabled={actionsDisabled}
        />
        {values.depositInfo &&
          values.depositInfo?.paymentMethod === PaymentMethod.WireTransfer && [
            <AppTextInput
              source="depositInfo.beneficiaryIban"
              label="IBAN beneficiario"
              variant="outlined"
              initialValue={feeProfile.ibanCode}
              md={3}
            />,
            <AppTextInput
              source="depositInfo.returnIban"
              label="IBAN per la restituzione"
              variant="outlined"
              md={3}
            />,
            <AppTextInput source="depositInfo.cro" label="CRO" variant="outlined" md={3} />,
            <AppDateTimeInput source="depositInfo.croDate" label="Data/ora cro" md={3} />,
          ]}
        {values.depositInfo &&
          values.depositInfo?.paymentMethod === PaymentMethod.Check && [
            <AppTextInput
              source="depositInfo.checkNumber"
              label="Numero assegno"
              variant="outlined"
              md={false}
            />,
          ]}
        {values.depositInfo &&
          values.depositInfo?.paymentMethod === PaymentMethod.CreditCard && [
            <AppTextInput
              source="depositInfo.creditCardType"
              label="Tipo di carta di credito"
              variant="outlined"
            />,
            <AppTextInput
              source="depositInfo.transactionId"
              label="ID Transazione"
              variant="outlined"
            />,
            <AppTextInput source="depositInfo.creditCardOwner.name" label="Intestatario carta" />,
            <AppTextInput
              source="depositInfo.creditCardOwner.email"
              label="Email intestatario carta"
            />,
          ]}
        {values.depositInfo && [
          <AppDateCalculator
            refToSource="depositInfo.balanceDueDateRefTo"
            hasTime={true}
            source="depositInfo.balanceDueDate"
            label="Termine saldo"
            record={ctx.mainResourceRecord}
          />,
        ]}
        <AppDivider />
        <AppNumberInput
          source="depositInfo.amount"
          label="Importo totale cauzione"
          required
          isAmount
          helperText={
            ctx.mainResourceRecord.depositPriceRefTo === 'depositPercentageOnFirstOffer'
              ? `Calcolata automaticamente - percentuale su prima offerta (${saleExp.depositPercentageOnFirstOffer}%) + oneri`
              : ''
          }
          disabled={actionsDisabled}
        />
      </AppCard>
    </Fragment>
  );
});

export default ParticipationOffer;
