import { makeStyles } from '@material-ui/core/styles';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import { useNotify } from 'ra-core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ListContextProvider, ReferenceField, useMutation } from 'react-admin';
import AppAlert from '../../ui/alert/AppAlert';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppDateField from '../../ui/field/AppDateField';
import AppTextField from '../../ui/field/AppTextField';
import { AppText } from '../../ui/text';
import RequestFulltextSearch from '../utilities/request-fulltext-search';
import RequestLinkButton from './request-link-button';
import RequestLinkListExpand from './request-link-list-expand';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(4),
    },
  }),
  { name: 'RequestLinkSalesToLinkToList' },
);

const useDgStyles = makeStyles(
  (theme) => ({
    pvpId: {
      minWidth: theme.spacing(25),
      whiteSpace: 'nowrap',
    },
    title: {
      minWidth: theme.spacing(30),
    },
    code: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(25),
    },
    genre: {
      minWidth: theme.spacing(20),
    },
    procedure: {
      minWidth: theme.spacing(20),
    },
    saleMode: {
      minWidth: theme.spacing(30),
    },
    price: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
  }),
  { name: 'RequestLinkListDatagrid' },
);

interface RequestLinkSalesToLinkToListProps {
  pvpRequestId: number;
  closeDialog: () => void;
}

const RequestLinkSalesToLinkToList: FC<RequestLinkSalesToLinkToListProps> = ({
  pvpRequestId,
  closeDialog,
}) => {
  const classes = useStyles();
  const dgClasses = useDgStyles();
  const notify = useNotify();

  /// region SUGGESTIONS

  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
    loading: true,
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  const [fulltextSearch, setFulltextSearch] = useState<string>('');
  const handleFulltextSearchUpdate = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value.length < 1) {
      setFulltextSearch('');
    } else if (value.length >= 3) {
      setFulltextSearch(value);
    }
  };

  const [mutateSuggestions] = useMutation();
  const getSalesToLinkTo = () => {
    if (!pvpRequestId) return;

    mutateSuggestions(
      {
        type: 'getList',
        resource: `pvp-publication-requests/${pvpRequestId}/sales-to-link-to`,
        payload: {
          filter: {
            fulltextSearch,
          },
        },
      },
      {
        onSuccess: (res) =>
          setListCtx(
            _assign({}, listBaseCtx, {
              data: _keyBy(res.data, 'id'),
              ids: _map(res.data, 'id'),
              total: res.total,
              loaded: true,
              loading: false,
            }) as any,
          ),
        onFailure: (err) => {
          closeDialog();
          notify('Impossibile caricare la lista di lotti associabili.', 'error');
          console.error(err);
        },
      },
    );
  };

  useEffect(getSalesToLinkTo, [pvpRequestId, fulltextSearch]);

  /// endregion

  return listCtx.loading ? (
    <AppText>Caricamento vendite associabili...</AppText>
  ) : (
    <div className={classes.container}>
      <RequestFulltextSearch
        onChange={handleFulltextSearchUpdate}
        updateList={getSalesToLinkTo}
        title="Lista delle vendite associabili"
        description="La ricerca è effettuata tramite numero di inserzione, se esso non è presente nel sistema si
        cercano le vendite più congrue utilizzando i dati dell'inserzione (numero e anno della
        procedura, tribunale, rito, ecc...)."
      />
      {listCtx.total > 0 ? (
        <ListContextProvider value={listCtx}>
          <AppDatagrid expand={<RequestLinkListExpand />}>
            <AppTextField
              source="pvpId"
              label="Numero inserzione PVP"
              headerClassName={dgClasses.pvpId}
            />
            <AppTextField
              source="relatedItem.title"
              label="Titolo"
              truncate
              headerClassName={dgClasses.title}
            />
            <AppTextField
              source="relatedItem.code"
              label="Numero lotto"
              headerClassName={dgClasses.code}
            />
            <ReferenceField
              basePath="items"
              reference="genres"
              source="relatedItem.fkGenre"
              label="Genere"
              headerClassName={dgClasses.genre}
              link={false}
            >
              <AppTextField source="description" />
            </ReferenceField>
            <AppTextField
              source="relatedItem.relatedFolder.procedureCode"
              label="Procedura"
              headerClassName={dgClasses.procedure}
            />
            <AppTextField
              source="relatedSaleMode.description"
              label="Modalità di vendita"
              headerClassName={dgClasses.saleMode}
            />
            <AppTextField
              source="basePrice"
              label="Prezzo base"
              type="amount"
              headerClassName={dgClasses.price}
            />
            <AppDateField
              source="auctionStartAt"
              label="Data di vendita"
              showTime
              headerClassName={dgClasses.lastCol}
            />
            <RequestLinkButton pvpRequestId={pvpRequestId} closeDialog={closeDialog} />
          </AppDatagrid>
        </ListContextProvider>
      ) : (
        <AppAlert children="Nessuna vendita associabile trovata." severity="warning" />
      )}
    </div>
  );
};

export default RequestLinkSalesToLinkToList;
