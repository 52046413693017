import AuctionRoomIcon from '@material-ui/icons/HomeWork';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import AuctionRoomDetails from './AuctionRoomDetails';
import AuctionRoomList from './AuctionRoomList';

export const AUCTION_ROOM_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const AuctionRoom: ResourceExport = {
  list: AuctionRoomList,
  create: resourceWrapper(
    'create',
    AuctionRoomDetails,
    undefined,
    undefined,
    AUCTION_ROOM_PERMISSIONS,
  ),
  edit: resourceWrapper('edit', AuctionRoomDetails, undefined, undefined, AUCTION_ROOM_PERMISSIONS),
  show: resourceWrapper('show', AuctionRoomDetails, undefined, undefined, AUCTION_ROOM_PERMISSIONS),
  icon: AuctionRoomIcon,
};

export default AuctionRoom;
