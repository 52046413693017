import ServicesIcon from '@material-ui/icons/SignalWifi1Bar';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import ServicesStatusList from './ServicesStatusList';

export const SERVICES_STATUS_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const ServicesStatus: ResourceExport = {
  list: ServicesStatusList,
  icon: ServicesIcon,
};
export default ServicesStatus;
