import { makeStyles } from '@material-ui/core';
import { Record as RaRecord } from 'ra-core';
import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  AdvertisementOutcomeCompleted,
  AdvertisementOutcomeForm,
  AdvertisementOutcomeFormActions,
  AdvertisementOutcomeFormNotSold,
  AdvertisementOutcomeFormSold,
} from '../../Components/sale-experiment/advertisement-outcome';
import AppCard from '../../Components/ui/card/AppCard';
import { AppSelectInput } from '../../Components/ui/input';
import { ADVERTISEMENT_OUTCOME_TYPES } from './constants';
import { AdvertisementOutcomeType, AuctionStatus } from './enums';
import { CompletedAdvertisementInfo } from './types';

const useStyles = makeStyles(
  (theme) => ({
    outcomeType: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'SaleExperimentAdvertisementOutcome' },
);

interface SaleExperimentAdvertisementOutcomeProps {
  record?: RaRecord;
  resource?: string;
  mode?: 'create' | 'edit';
}

const SaleExperimentAdvertisementOutcome: FC<SaleExperimentAdvertisementOutcomeProps> = ({
  record: saleExperiment,
  resource,
  mode,
}) => {
  const classes = useStyles();

  const [completedAuctionInfo, setCompletedAuctionInfo] = useState<CompletedAdvertisementInfo>();
  useEffect(() => {
    if (
      !saleExperiment ||
      (saleExperiment.auctionStatus === AuctionStatus.inactive && !saleExperiment.auctionPostponed)
    )
      return;

    setCompletedAuctionInfo({
      outcomeType: saleExperiment.unsuccessfulAuction
        ? AdvertisementOutcomeType.Deserted
        : saleExperiment.auctionPostponed
        ? AdvertisementOutcomeType.Postponed
        : saleExperiment.auctionStatus === AuctionStatus.settled
        ? AdvertisementOutcomeType.Settled
        : saleExperiment.partiallySold
        ? AdvertisementOutcomeType.PartiallySold
        : AdvertisementOutcomeType.Sold,
      auctionStatusReason: saleExperiment.auctionStatusReason,
      auctionStatusReasonDetails: saleExperiment.auctionStatusReasonDetails,
      winner: saleExperiment.winner,
      awardPrice: saleExperiment.awardPrice,
      soldAt: saleExperiment.relatedItem?.soldAt,
    });
  }, [saleExperiment]);

  if (!saleExperiment) return <Fragment />;

  return (
    <AppCard collapsable={false} header={false} hideContent={mode === 'create'}>
      {!completedAuctionInfo ? (
        <AdvertisementOutcomeForm
          setCompletedAuctionInfo={setCompletedAuctionInfo}
          resource={resource}
          saleExpId={saleExperiment.id as number}
        >
          <AppSelectInput
            choices={ADVERTISEMENT_OUTCOME_TYPES}
            source="outcomeType"
            label="Esito"
            required
            md={3}
            helperText={false}
            className={classes.outcomeType}
            disabled={mode !== 'edit'}
          />
          {/* SOLD */}
          <AdvertisementOutcomeFormSold />
          {/* PARTIALLY SOLD/DESERTED/SETTLED/POSTPONED */}
          <AdvertisementOutcomeFormNotSold />
          {/* ACTIONS */}
          <AdvertisementOutcomeFormActions />
        </AdvertisementOutcomeForm>
      ) : (
        <AdvertisementOutcomeCompleted
          completedAuctionInfo={completedAuctionInfo}
          setCompletedAuctionInfo={setCompletedAuctionInfo}
          saleExperiment={saleExperiment}
        />
      )}
    </AppCard>
  );
};

export default SaleExperimentAdvertisementOutcome;
