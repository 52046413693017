import { Card, makeStyles } from '@material-ui/core';
import _reduce from 'lodash/reduce';
import moment from 'moment';
import 'moment/locale/it';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Title, useMutation, useNotify } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppTitle from '../../Components/ui/layout/AppTitle';
import {
  AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BACKGROUND_COLOR,
  AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BORDER_COLOR,
  AUCTION_ROOM_BOOKING_COLORS,
  AUCTION_ROOM_BOOKING_UNDEFINED_BACKGROUND_COLOR,
  AUCTION_ROOM_BOOKING_UNDEFINED_BORDER_COLOR,
  PersonRoles,
} from '../../utils/constants';
import { getMonday } from '../../utils/dates';
import AuctionRoomBookingCalendarAddEventModal from './AuctionRoomBookingCalendarAddEventModal';
import AuctionRoomBookingCalendarShowEventModal from './AuctionRoomBookingCalendarShowEventModal';
import AuctionRoomBookingCalendarToolbar from './AuctionRoomBookingCalendarToolbar';
import { CalendarBackgroundEvent, CalendarEvent } from './types';
import { DeactivatedFeature } from '../../Components/deactivated-feature/deactivated-feature';

/// region STYLES

const useCalendarStyles = makeStyles(
  (theme) => ({
    container: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
      height: '100vh',
      padding: theme.spacing(2),
      '& .rbc-header': {
        lineHeight: '2rem',
      },
      '& .rbc-date-cell': {
        padding: 0,
      },
      '& .rbc-events-container': {
        marginRight: 0,
      },
      '& .rbc-event': {
        borderRadius: '10px',
        opacity: 0.8,
        color: 'black',
        border: '1px solid',
        display: 'block',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        textAlign: 'center',
        // width: 'auto',
        // height: '100%',
      },
      '& .rbc-today': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.3,
      },
      '& .rbc-show-more': {
        textAlign: 'center',
      },
    },
    linksToOtherCalendarsContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    linkButton: {
      maxWidth: theme.spacing(60),
    },
    dateHeader: {
      textAlign: 'center',
      lineHeight: '1.5rem',
    },
  }),
  { name: 'AuctionRoomBookingCalendar' },
);

/// endregion

/// region CALENDAR SETTINGS / UTILS

// calendar locale
moment.locale('it');

/// endregion

const AuctionRoomBookingCalendar: FC<any> = (props) => {
  const clickRef = useRef(null);
  //TODO: permissions check
  const classes = useCalendarStyles();
  const notify = useNotify();

  const AUCTION_ROOM_BOOKINGS_ENABLED =
    process.env.REACT_APP_AUCTION_ROOM_BOOKINGS_ENABLED === 'true';

  /// region STATES

  const [events, setEvents] = useState<CalendarEvent[]>([]); // calendar events setter
  const [backgroundEvents, setBackgroundEvents] = useState<CalendarBackgroundEvent[]>([]); // calendar events setter
  const [auctionRooms, setAuctionRooms] = useState<any[]>([]); // auction rooms setter
  const [currentView, setCurrentView] = useState<'month' | 'week' | 'day'>('week');
  const [isAuctioneer, setIsAuctioneer] = useState<boolean>(true);
  const [auctioneerId, setAuctioneerId] = useState<number | null>(null);

  const [bookingRange, setBookingRange] = useState<{ from: Date | null; to: Date | null }>({
    from: null,
    to: null,
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isShowModalOpen, setIsShowModalOpen] = useState<boolean>(false);

  const [currentEvent, setCurrentEvent] = useState<CalendarEvent | null>(null);

  /// endregion

  /// region FILTERS

  const [calendarFilters, setCalendarFilters] = useState({
    month: null,
    auctionRoom: null,
    person: null,
  });

  useEffect(() => {
    setIsAuctioneer(
      props.permissions?.roleId ? props.permissions?.roleId === PersonRoles.Auctioneer : true,
    );
    setAuctioneerId(
      props.permissions?.roleId && props.permissions.roleId === PersonRoles.Auctioneer
        ? props.permissions?.id
        : null,
    );
  }, [props.permissions]);

  useEffect(() => {
    if (auctionRooms.length > 0) {
      updateAuctionRoomsBookings();
    }
    updateBackgroundEvents();
  }, [calendarFilters, auctionRooms]);

  useEffect(() => {
    if (auctionRooms.length > 0 && isAuctioneer) {
      setCalendarFilters((prevFilters) => ({
        ...prevFilters,
        auctionRoom: auctionRooms[0].id,
      }));
    }
  }, [auctionRooms]);

  useEffect(() => {
    loadAuctionRooms();

    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  /// endregion

  const currentAuctionRoom = useMemo(
    () => auctionRooms.find((auctionRoom) => auctionRoom.id === calendarFilters.auctionRoom),
    [calendarFilters, auctionRooms],
  );
  const currentAuctionRoomRangeMin = useMemo(
    () => currentAuctionRoom?.ranges.reduce((prev, curr) => (prev.from < curr.from ? prev : curr)),
    [currentAuctionRoom],
  );
  const currentAuctionRoomRangeMax = useMemo(
    () => currentAuctionRoom?.ranges.reduce((prev, curr) => (prev.to > curr.to ? prev : curr)),
    [currentAuctionRoom],
  );
  const currentDateMonday = useMemo(() => getMonday(calendarFilters.month), [calendarFilters]);
  const currentDateSunday = useMemo(
    () =>
      new Date(
        currentDateMonday.getFullYear(),
        currentDateMonday.getMonth(),
        currentDateMonday.getDate() + 6,
      ),
    [currentDateMonday],
  );

  const calendarDefaultStep = useMemo(() => {
    // If no auction room is selected -> Minimum slot length among events' auction rooms
    if (!events.length) return 30;

    return _reduce(events, (prevEvent, currEvent) =>
      +prevEvent.auctionRoom.slotLength.split('min').pop() <
      +currEvent.auctionRoom.slotLength.split('min').pop()
        ? prevEvent
        : currEvent,
    )?.auctionRoom.slotLength;
  }, [events]);

  const updateBackgroundEvents = () => {
    if (calendarFilters.auctionRoom) {
      const startDate = getMonday(calendarFilters.month);
      const newBackgroundEvents: {
        start: Date;
        end: Date;
        backgroundColor: string;
        borderColor: string;
      }[] = [];
      for (let i = 0; i < 7; i++) {
        const range = currentAuctionRoom.ranges.find((range) => range.weekDays.includes(i));
        if (range) {
          const fromArr = range.from.split(':');
          const toArr = range.to.split(':');
          newBackgroundEvents.push({
            start: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + i,
              fromArr[0],
              fromArr[1],
              fromArr[2],
            ),
            end: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + i,
              toArr[0],
              toArr[1],
              toArr[2],
            ),
            backgroundColor: AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BACKGROUND_COLOR,
            borderColor: AUCTION_ROOM_BOOKING_BACKGROUND_EVENT_BORDER_COLOR,
          });
        }
      }
      setBackgroundEvents(newBackgroundEvents);
    } else {
      setBackgroundEvents([]);
    }
  };
  /// region MUTATE

  const [mutateAuctionRooms] = useMutation();
  const loadAuctionRooms = () =>
    mutateAuctionRooms(
      {
        type: 'getList',
        resource: 'auction-rooms',
        payload: {
          filter: {},
        },
      },
      {
        onSuccess: (res) => {
          setAuctionRooms(res.data);
        },
        onFailure: (err) => {
          console.error(err.message);
          notify("Non è stato possibile recuperare le informazioni delle sale d'asta", 'error');
        },
      },
    );

  const [mutateAuctionRoomsBookings] = useMutation();
  const updateAuctionRoomsBookings = () =>
    mutateAuctionRoomsBookings(
      {
        type: 'getList',
        resource: 'auction-rooms-bookings/calendar',
        payload: {
          filter: {
            date: calendarFilters.month,
            auctionRoomId: calendarFilters.auctionRoom,
            personId: calendarFilters.person,
          },
        },
      },
      {
        onSuccess: (res) => {
          getCalendarEventsArray(res.data);
        },
        onFailure: (err) => {
          console.error(err.message);
          notify('Non è stato possibile recuperare le informazioni del calendario', 'error');
        },
      },
    );

  /// endregion

  /// region CALENDAR EVENTS

  const getCalendarEventsArray = (data) => {
    const auctionRoomsEvents: CalendarEvent[] = [];
    data.forEach((auctionRoomBooking) => {
      const shouldHideDetails =
        isAuctioneer && auctionRoomBooking.fkPerson !== props.permissions?.id;
      auctionRoomsEvents.push({
        title: shouldHideDetails ? (
          'Non prenotabile'
        ) : (
          <p style={{ margin: 0, fontSize: '10px' }}>
            {`${
              !currentAuctionRoom
                ? `${auctionRoomBooking.auctionRoom.title}${!calendarFilters.person ? ' - ' : ''}`
                : ''
            }${
              !calendarFilters.person && auctionRoomBooking.person?.firstName
                ? `${auctionRoomBooking.person.firstName} `
                : ''
            }${
              !calendarFilters.person && auctionRoomBooking.person?.lastName
                ? auctionRoomBooking.person.lastName
                : ''
            }`}
            {auctionRoomBooking.notes ? (
              <>
                {(!calendarFilters.person || !currentAuctionRoom) && <br />}
                {auctionRoomBooking.notes}
              </>
            ) : (
              ''
            )}
          </p>
        ),
        start: new Date(auctionRoomBooking.from),
        end: new Date(auctionRoomBooking.to),
        backgroundColor: shouldHideDetails
          ? AUCTION_ROOM_BOOKING_UNDEFINED_BACKGROUND_COLOR
          : AUCTION_ROOM_BOOKING_COLORS[auctionRoomBooking.status][0],
        borderColor: shouldHideDetails
          ? AUCTION_ROOM_BOOKING_UNDEFINED_BORDER_COLOR
          : AUCTION_ROOM_BOOKING_COLORS[auctionRoomBooking.status][1],
        auctionRoom: auctionRoomBooking.auctionRoom,
        person: auctionRoomBooking.person,
        personId: auctionRoomBooking.fkPerson,
        details: { ...auctionRoomBooking },
      });
    });

    setEvents(auctionRoomsEvents);
  };

  /// endregion

  const slotLengthToStep = (slotLength) => {
    switch (slotLength) {
      case 'min05':
        return 5;
      case 'min10':
        return 10;
      case 'min15':
        return 15;
      case 'min20':
        return 20;
      case 'min25':
        return 25;
      case 'min30':
        return 30;
      case 'min35':
        return 35;
      case 'min40':
        return 40;
      case 'min45':
        return 45;
      case 'min50':
        return 50;
      case 'min55':
        return 55;
      case 'min60':
      default:
        return 60;
    }
  };

  const checkIfRangeOverlapsEvent = (start, end) => {
    return !!events.find((event) => {
      if (
        (start >= event.start && start < event.end) ||
        (end > event.start && end <= event.end) ||
        (start < event.start && end > event.end)
      ) {
        return true;
      }
      return false;
    });
  };

  const checkIfRangeIsWithinBackgroundEvent = (start, end) => {
    return !!backgroundEvents.find((backgroundEvent) => {
      if (start >= backgroundEvent.start && end <= backgroundEvent.end) {
        return true;
      }
      return false;
    });
  };

  const onSelectSlot = useCallback(
    (slotInfo) => {
      if (currentView === 'month') {
        return false;
      }
      window.clearTimeout(clickRef?.current);
      clickRef.current = window.setTimeout(() => {
        if (checkIfRangeOverlapsEvent(slotInfo.start, slotInfo.end)) {
          window.alert('Abbiamo già ricevuto una prenotazione per questo orario.');
          return false;
        }
        if (!checkIfRangeIsWithinBackgroundEvent(slotInfo.start, slotInfo.end)) {
          if (currentAuctionRoom) {
            window.alert('La sala non è prenotabile in questo orario.');
          }
          return false;
        }
        setBookingRange({ from: slotInfo.start, to: slotInfo.end });
        setIsAddModalOpen(true);
      }, 0);
    },
    [backgroundEvents],
  );

  const onSelecting = useCallback(
    (range) => {
      if (currentView === 'month') {
        return false;
      }
      if (
        checkIfRangeOverlapsEvent(range.start, range.end) ||
        !checkIfRangeIsWithinBackgroundEvent(range.start, range.end)
      ) {
        return false;
      }
    },
    [backgroundEvents, events],
  );

  const dialogCloseShowModalHandler = () => {
    setCurrentEvent(null);
    setIsShowModalOpen(false);
  };

  const dialogCloseAddModalHandler = () => {
    setIsAddModalOpen(false);
  };

  const dialogClickHandler = () => {
    // SERVE???
    console.log('onClick');
  };

  const dialogSubmitHandler = () => {
    // SERVE???
    console.log('onSubmit');
  };

  const [mutatePost] = useMutation();
  const handlePost = (status, from, to, fkAuctionRoom, fkPerson, notes) =>
    mutatePost(
      {
        type: 'create',
        resource: 'auction-rooms-bookings',
        payload: {
          data: {
            status,
            from,
            to,
            fkAuctionRoom,
            fkPerson,
            notes,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Prenotazione creata correttamente.', 'info');
          setIsAddModalOpen(false);
          updateAuctionRoomsBookings();
        },
        onFailure: (err) => {
          notify(err.message, 'error');
          console.log(err);
        },
      },
    );

  return AUCTION_ROOM_BOOKINGS_ENABLED ? (
    <Fragment>
      <Card className={classes.container}>
        <Title title={<AppTitle title={'Calendario prenotazioni'} />} />
        <Calendar
          localizer={momentLocalizer(moment)}
          events={events}
          backgroundEvents={backgroundEvents}
          min={
            currentAuctionRoomRangeMin
              ? new Date(
                  `${currentDateMonday.getFullYear()}-${
                    currentDateMonday.getMonth() + 1
                  }-${currentDateMonday.getDate()} ${currentAuctionRoomRangeMin.from}`,
                )
              : undefined
          }
          max={
            currentAuctionRoomRangeMax
              ? new Date(
                  `${currentDateSunday.getFullYear()}-${
                    currentDateSunday.getMonth() + 1
                  }-${currentDateSunday.getDate()} ${currentAuctionRoomRangeMax.to}`,
                )
              : undefined
          }
          startAccessor="start"
          formats={{
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
          }}
          endAccessor="end"
          onSelectEvent={(event) => {
            if (event?.details && !(isAuctioneer && event.personId !== props.permissions?.id)) {
              setCurrentEvent(event);
              setIsShowModalOpen(true);
            } else {
              setCurrentEvent(null);
            }
          }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
              borderColor: event.borderColor,
            },
          })}
          tooltipAccessor={(e) =>
            `${e.title?.props?.children?.[0]} ${e.details?.notes ? ` - ${e.details?.notes}` : ''}`
          }
          popup
          messages={{
            showMore: () => (
              <div
                style={{ cursor: 'pointer' }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {'Espandi'}
              </div>
            ),
          }}
          components={{
            toolbar: (toolbarProps) =>
              AuctionRoomBookingCalendarToolbar({
                ...toolbarProps,
                calendarFilters,
                setCalendarFilters,
                currentResource: props.resource,
                isAuctioneer: isAuctioneer,
              }),
            month: {
              dateHeader: ({ label }) => <div className={classes.dateHeader}>{label}</div>,
            },
          }}
          onNavigate={(date) => {
            setCalendarFilters((prevFilters) => ({
              ...prevFilters,
              month: date,
            }));
          }}
          views={['month', 'week', 'day']}
          defaultView={'week'}
          selectable={true}
          step={slotLengthToStep(currentAuctionRoom?.slotLength ?? calendarDefaultStep)}
          timeslots={1}
          onSelecting={onSelecting}
          onSelectSlot={onSelectSlot}
          onView={setCurrentView}
        />
      </Card>
      {isAddModalOpen && (
        <AuctionRoomBookingCalendarAddEventModal
          isOpen={isAddModalOpen}
          isAuctioneer={isAuctioneer}
          dialogCloseHandler={dialogCloseAddModalHandler}
          dialogClickHandler={dialogClickHandler}
          dialogSubmitHandler={dialogSubmitHandler}
          currentAuctionRoom={currentAuctionRoom}
          bookingPerson={isAuctioneer ? auctioneerId : null}
          bookingRange={bookingRange}
          handlePost={handlePost}
        />
      )}
      {currentEvent && (
        <AuctionRoomBookingCalendarShowEventModal
          isOpen={isShowModalOpen}
          dialogCloseHandler={dialogCloseShowModalHandler}
          event={currentEvent}
          isAuctioneer={isAuctioneer}
        />
      )}
    </Fragment>
  ) : (
    <DeactivatedFeature
      title={'La funzionalità della "Prenotazione sale d\'asta" attualmente non è attiva'}
      subtitle={"Contatta l'assistenza per attivarla"}
    />
  );
};

export default AuctionRoomBookingCalendar;
