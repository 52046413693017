import { Button, Grid } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { FC, Fragment, useState } from 'react';
import { DetailPanelField, DetailPanelTitle } from '../../Components/detail-panel';
import AppTooltip from '../../Components/ui/tooltip/AppTooltip';
import { GENDER_CHOICES } from '../../utils/constants';
import translatedCountries from '../../utils/countries.json';
import { dateFormatter } from '../../utils/data-formatters';
import AttachmentsDrawer from './attachments/attachments-drawer';
import { PersonType } from './constants';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const BookingExpanded: FC<any> = ({ record: booking }) => {
  const { item, booker } = booking;
  const [attachmentsWindowVisible, setAttachmentsWindowVisible] = useState<boolean>(false);

  return (
    <Grid container spacing={3}>
      <DetailPanelTitle variant="h5" children="DETTAGLIO RICHIESTA" />
      {/* ITEM */}
      <DetailPanelField md={12} label="Lotto" value={item.title} />
      <DetailPanelField md={2} label="Codice lotto" value={item.code} />
      {IS_IVG && <DetailPanelField md={2} label="Numero IVG" value={item.ivgCode} />}
      <DetailPanelField
        md={2}
        label={item.relatedFolder.type === 'judiciary' ? 'Procedura' : 'Pratica'}
        value={item.relatedFolder.procedureCode || item.relatedFolder.title}
      />
      <DetailPanelTitle children="INFORMAZIONI PERSONALI" />
      {booker.type === PersonType.Person ? (
        <Fragment>
          {/* PERSON */}
          <DetailPanelField md={2} label="Nome" value={booker.firstName} />
          <DetailPanelField md={2} label="Cognome" value={booker.lastName} />
          <DetailPanelField md={2} label="Codice fiscale" value={booker.taxCode as string} />
          <DetailPanelField
            md={1}
            label="Sesso"
            value={GENDER_CHOICES.find((gender) => gender.id === booker.gender)?.name}
          />
          <DetailPanelField
            md={2}
            label="Data di nascita"
            value={dateFormatter(booker.birthday, true)}
          />
        </Fragment>
      ) : (
        <Fragment>
          {/* COMPANY */}
          <DetailPanelField md={2} label="Ragione sociale" value={booker.businessName as string} />
          <DetailPanelField md={2} label="Partita IVA" value={booker.vat} />
          <DetailPanelField
            md={3}
            label="Referente/legale rappresentante"
            value={booker.representative}
          />
        </Fragment>
      )}
      {/* RESIDENCE */}
      <DetailPanelTitle children="RESIDENZA" />
      <DetailPanelField md={2} label="Indirizzo" value={booker.residenceAddress} />
      <DetailPanelField md={2} label="Civico" value={booker.residenceAddressNumber} />
      {booker.residenceCountry === 'IT' && (
        <DetailPanelField md={8} label="CAP" value={booker.residenceZipCode} />
      )}
      <DetailPanelField md={2} label="Nazione" value={translatedCountries[booker.countryName]} />
      {booker.residenceCountry === 'IT' && (
        <DetailPanelField md={2} label="Regione" value={booker.regionName} />
      )}
      {booker.residenceCountry === 'IT' && (
        <DetailPanelField md={2} label="Provincia" value={booker.provinceName} />
      )}
      <DetailPanelField md={2} label="Comune" value={booker.cityName} />
      {/* CONTACTS */}
      <DetailPanelTitle children="CONTATTI" />
      <DetailPanelField md={2} label="Email" value={booker.email} />
      <DetailPanelField md={2} label="Pec" value={booker.pec} />
      <DetailPanelField md={2} label="Cellulare" value={booker.mobile} />
      <DetailPanelField md={2} label="Telefono" value={booker.phone} />
      <DetailPanelField md={2} label="Fax" value={booker.fax} />
      {/* DOCUMENTS */}
      <DetailPanelTitle children="DOCUMENTI" />
      <AppTooltip title="Clicca per visualizzare gli allegati" arrow>
        <Button
          startIcon={<InsertDriveFileIcon />}
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => setAttachmentsWindowVisible(true)}
          style={{ lineHeight: 'initial', margin: '10px' }}
        />
      </AppTooltip>
      <AttachmentsDrawer
        booker={booker}
        isOpen={attachmentsWindowVisible}
        onClose={() => setAttachmentsWindowVisible(false)}
      />
    </Grid>
  );
};

export default BookingExpanded;
