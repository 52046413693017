import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { AppText, AppTextProps } from '../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'RequestDetailsTitle' },
);

export const DetailPanelTitle: FC<AppTextProps> = ({ variant = 'h6', children = '-' }) => {
  const classes = useStyles();
  return (
    <Grid item md={12} className={classes.container}>
      <AppText
        variant={variant}
        children={typeof children === 'string' ? children.toUpperCase() : children}
      />
    </Grid>
  );
};
