import { Button } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { useNotify } from 'ra-core';
import React, { FC } from 'react';
import { useMutation, useRefresh } from 'react-admin';

interface RequestLinkButtonProps {
  pvpRequestId: number;
  closeDialog: () => void;
  record?: Record<string, any>;
}

const RequestLinkButton: FC<RequestLinkButtonProps> = ({
  record = {},
  pvpRequestId,
  closeDialog,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [linkToSale] = useMutation();
  const handleSaleLink = () => {
    const { id: saleExpId } = record;
    saleExpId &&
      linkToSale(
        {
          type: 'update',
          resource: `pvp-publication-requests/${pvpRequestId}/link-to-sale`,
          payload: {
            data: {
              saleExpId,
            },
          },
        },
        {
          onSuccess: () => {
            notify('Vendita associata con successo.', 'info');
            refresh();
            closeDialog();
          },
          onFailure: (err) => {
            console.error(err);
            notify('Operazione fallita', 'error');
          },
        },
      );
  };
  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={handleSaleLink}
      children="Associa"
      startIcon={<Link />}
    />
  );
};

export default RequestLinkButton;
