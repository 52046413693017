import { makeStyles } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { ListContextProvider } from 'react-admin';
import { DetailPanelTitle } from '../../detail-panel';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';

interface RequestDetailsEventsListProps {
  eventsList: Record<string, any>[];
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    typeCol: {
      minWidth: theme.spacing(30),
    },
    noteCol: {
      minWidth: theme.spacing(40),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'RequestDetailsEventsList' },
);

const RequestDetailsEventsList: FC<RequestDetailsEventsListProps> = ({ eventsList }) => {
  const classes = useStyles();

  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  useEffect(() => {
    const eventsWithIds = eventsList.map((event, index) => ({ id: index + 1, ...event }));
    setListCtx(
      _assign({}, listBaseCtx, {
        data: _keyBy(eventsWithIds, 'id'),
        ids: _map(eventsWithIds, 'id'),
        total: eventsWithIds.length,
        loaded: true,
        loading: false,
      }),
    );
  }, [eventsList]);

  return (
    <div className={classes.container}>
      <DetailPanelTitle children="LISTA DEGLI EVENTI" />
      <ListContextProvider value={listCtx}>
        <AppDatagrid>
          <AppTextField source="tipologia" label="Tipo" headerClassName={classes.typeCol} />
          <AppTextField source="nota" label="Nota" headerClassName={classes.noteCol} />
          <AppTextField
            source="dataPubblicazione"
            label="Data pubblicazione"
            headerClassName={classes.lastCol}
          />
        </AppDatagrid>
      </ListContextProvider>
    </div>
  );
};

export default RequestDetailsEventsList;
