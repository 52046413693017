import React, { FC } from 'react';
import { useNotify } from 'react-admin';
import AppTooltip from '../tooltip/AppTooltip';

type AppColorFieldProps = {
  color: string;
};

export const AppColorField: FC<AppColorFieldProps> = React.memo<AppColorFieldProps>(({ color }) => {
  const notify = useNotify();

  const copyColorToClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(color)
      .then(() => notify('Colore copiato negli appunti.'))
      .catch();
  };

  return (
    <AppTooltip title={color}>
      <div style={{ backgroundColor: color, color: color }} onClick={copyColorToClipboard}>
        placeholder
      </div>
    </AppTooltip>
  );
});
