import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionField, ListProps, ReferenceField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppDateField, AppTextField } from '../../Components/ui/field';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import { FrontEndUsersProfileModificationListLegend } from './FrontEndUsersProfileModificationListLegend';
import { green, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles(
  (theme) => ({
    date: {
      whiteSpace: 'nowrap',
    },
    user: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'FrontEndUsersProfileModificationList' },
);

const FrontEndUsersProfileModificationList: FC<ListProps> = React.memo((props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title={props.options.label} />}
      filters={<AppListFilter helperText="nome, cognome, email" />}
      hideCreateButton
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <AppDatagrid
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor:
            record.status === 'accepted'
              ? green[400]
              : record.status === 'rejected'
              ? red[400]
              : grey[100],
        })}
        additionalInfo={<FrontEndUsersProfileModificationListLegend />}
      >
        <AppDateField
          source="createdAt"
          label="Data richiesta"
          showTime
          headerClassName={classes.date}
        />
        <FunctionField
          label="Utente"
          render={(record) => {
            const path =
              record?.fkProperty === 1
                ? 'front-end-users'
                : record?.fkProperty === 2
                ? 'front-end-users-immo'
                : record?.fkProperty === 7
                ? 'front-end-users-cda'
                : 'front-end-users';
            return (
              <ReferenceField
                label="Utente"
                headerClassName={classes.user}
                reference={path}
                source="userId"
                basePath={`/${path}`}
                link={true}
              >
                <FunctionField render={(record) => `${record?.firstName} ${record?.lastName}`} />
              </ReferenceField>
            );
          }}
          headerClassName={classes.user}
        />
        <AppTextField source="email" label="Email" headerClassName={classes.lastCol} />
        <AppShowButton />
      </AppDatagrid>
    </AppList>
  );
});

export default FrontEndUsersProfileModificationList;
