import {
  AppBar,
  Card,
  CardContent,
  CardMedia,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { MediaSecurityType } from '../../../utils/constants';
import AppPdfViewer from '../../pdf-viewer/pdf-viewer';
import PecViewer from '../../pec-viewer/pec-viewer';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import AppAlert from '../../ui/alert/AppAlert';
import AppDownloadMediaButton from '../../ui/button/AppDownloadMediaButton';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      maxWidth: `calc(100vw - ${theme.spacing(120)}px)`,
      width: `calc(100vw - ${theme.spacing(120)}px)`,
      maxHeight: '100vh',
      height: '100vh',
      overflow: 'hidden',
      boxShadow:
        'inset 0px -1px 10px 0px rgba(0,0,0,0.12), inset 0px -4px 5px 0px rgba(0,0,0,0.14), inset 0px -2px 4px -1px rgba(0,0,0,0.12)',
    },
    toolbar: {
      '& button:not(.close)': {
        marginLeft: theme.spacing(2),
      },
    },
    content: {
      position: 'relative',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingTop: theme.spacing(3),
      paddingBottom: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    media: {
      width: '70%',
      height: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
    },
    presenter: {
      margin: 'auto',
    },
    /*
    dialog: {
      width: theme.spacing(120),
      height: theme.spacing(120),
      maxWidth: 'none',
    },
    dialogMedia: {
      height: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 'auto'
    },
     */
  }),
  { name: 'AttachmentsDrawer' },
);

const AttachmentsDrawer: FC<any> = () => {
  const notify = useNotify();
  const classes = useStyles();

  /// region CONTEXT

  const ctx = useContext(AppRelatedResourceContext);
  const { item: participation } = ctx;
  const { presenter } = participation;

  /// endregion

  /// region ATTACHMENTS

  const [attachments, setAttachments] = useState<any[]>([]);
  const [mutateAttachments] = useMutation();
  const getAttachments = () =>
    mutateAttachments(
      {
        type: 'getList',
        resource: `participations/${participation.id}/media`,
        payload: {},
      },
      {
        onSuccess: (res) => setAttachments(res.data),
        onFailure: (err) => {
          setAttachments([]);
          notify('Non è stato possibile recuperare gli allegati.', 'error');
          console.error(err);
        },
      },
    );

  /// endregion

  /// region AUTH BUTTON

  // update attachments and auth button availability every time the drawer is opened
  useEffect(() => {
    if (ctx.manager.isOpen) {
      getAttachments();
    }
  }, [ctx.manager.isOpen]);

  /// endregion

  return presenter ? (
    <Drawer anchor="right" open={ctx.manager.isOpen} onClose={ctx.manager.close}>
      <div className={classes.container}>
        <AppBar position="relative">
          <Toolbar className={classes.toolbar}>
            <IconButton onClick={ctx.manager.close} className="close">
              <Close />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.presenter}>
              {presenter.type === 'person'
                ? `${presenter.firstName} ${presenter.lastName} (${presenter.taxCode ?? '-'})`
                : `${presenter.businessName} (${presenter.vat ?? '-'})`}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Typography variant="h4" className={classes.title}>
            Documentazione del partecipante
          </Typography>
          {attachments && attachments.length ? (
            attachments.map((attachment, index) => {
              if (!attachment.metadata || !Object.values(attachment.metadata).length) return;

              const attachmentSrc: string =
                attachment.role.mediaSecurityType === MediaSecurityType.Protected
                  ? `data:${attachment.metadata.mimeType};base64,${attachment.metadata.base64}`
                  : attachment.metadata.remoteUrl;

              return (
                <Card className={classes.card} key={`attachment-${index}`}>
                  <CardContent>
                    <Typography variant="h6" className={classes.title}>
                      {attachment.name}
                    </Typography>
                    <AppDownloadMediaButton fileName={attachment.name} base64={attachmentSrc} />
                  </CardContent>
                  {attachment.metadata.mimeType === 'application/pdf' ? (
                    <AppPdfViewer file={attachmentSrc} />
                  ) : attachment.metadata.mimeType === 'message/rfc822' ? (
                    <PecViewer pecBase64={attachmentSrc.split(';base64,')[1]} />
                  ) : (
                    <CardMedia
                      className={classes.media}
                      component="img"
                      alt="Anteprima documento non disponibile"
                      src={attachmentSrc}
                    />
                  )}
                </Card>
              );
            })
          ) : (
            <AppAlert severity="warning">Non sono presenti documenti</AppAlert>
          )}
        </div>
      </div>
    </Drawer>
  ) : (
    <Fragment />
  );
};

export default AttachmentsDrawer;

/*
<Dialog open={imgDialogOpen} onClose={onImgDialogClose} classes={{paper: classes.dialog}}>
  <CardMedia image={selectedImg} className={classes.dialogMedia} />
</Dialog>
 */
