import { Link } from '@material-ui/core';
import { isFunction as _isFn } from 'lodash';
import React, { FC } from 'react';
import { sanitizeFieldRestProps } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ValueOrField } from '../../../types/utility.type';
import AppTextField, { AppTextFieldProps } from './AppTextField';

export const AppLinkToEntityField: FC<AppLinkToEntityFieldProps> =
  React.memo<AppLinkToEntityFieldProps>((props) => {
    const {
      className,
      targetResource,
      targetResourceId,
      originName,
      mode = 'edit',
      query = {},
      value,
      goBack = true,
      truncate = false,
      maxTextLength,
      ...others
    } = props;

    //TODO: permissions check

    if (mode === 'edit' && !targetResourceId)
      throw new Error('AppLinkToEntityField: targetResourceId required in "edit".');

    if (goBack) {
      query.goBack = true;
      query.previousResourcePath = useLocation().pathname;
      query.resourceLabel = __getValue(originName);
    }

    let url = `#/${__getValue(targetResource)}`;

    if (mode === 'create') {
      url += '/create';
    } else {
      url += `/${__getValue(targetResourceId)}`;
      if (mode === 'show') url += '/show';
    }

    if (Object.keys(query).length > 0) {
      url += `?${Object.keys(query)
        .map((k) => `${k}=${encodeURIComponent(query[k])}`)
        .join('&')}`;
    }

    return (
      <Link {...sanitizeFieldRestProps(others)} className={className} href={url}>
        <AppTextField
          isUrl
          truncate={truncate}
          maxTextLength={maxTextLength}
          customText={__getValue(value)}
        />
      </Link>
    );

    function __getValue(src) {
      return _isFn(src) ? src(props.record ?? {}) : src;
    }
  });

export default AppLinkToEntityField;

export interface AppLinkToEntityFieldProps extends AppTextFieldProps {
  targetResource: ValueOrField<string>;
  originName: ValueOrField<string>;
  value: ValueOrField<string>;
  mode?: 'list' | 'edit' | 'create' | 'show';
  targetResourceId?: ValueOrField<number>;
  query?: Record<string, any>;
  goBack?: boolean;
}
