import { makeStyles } from '@material-ui/core';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import { FC } from 'react';
import { BooleanField, ListProps, ReferenceField } from 'react-admin';
import { PublicationProofListLegend } from '../../Components/publication-proof/publication-proof-list-legend';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppDownloadFromGCPBucketField from '../../Components/ui/field/AppDownloadFromGCPBucketField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { useAutomaticRefresh } from '../../hooks/use-automatic-refresh';
import { GenerationStatus } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    fileName: {
      minWidth: theme.spacing(30),
    },
    dates: {
      minWidth: theme.spacing(30),
    },
    genre: {
      minWidth: theme.spacing(20),
    },
    booleans: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    buttons: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'PublicationProofList' },
);

const PublicationProofList: FC<ListProps> = (props) => {
  const classes = useStyles();

  useAutomaticRefresh();

  return (
    <AppList {...props} title={<AppTitle title="Lista giustificativi" />}>
      <AppDatagrid
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor:
            record.generationStatus === GenerationStatus.Pending
              ? yellow[600]
              : record.generationStatus === GenerationStatus.InProgress
              ? blue[300]
              : record.generationStatus === GenerationStatus.Error
              ? red[400]
              : green[400],
        })}
        additionalInfo={<PublicationProofListLegend />}
      >
        <AppTextField source="title" label="Titolo" truncate headerClassName={classes.fileName} />
        <AppTextField
          source="fileName"
          label="Nome file"
          truncate
          headerClassName={classes.fileName}
        />
        <AppDateField
          source="saleStartFrom"
          label="Inizio vendita dal"
          headerClassName={classes.dates}
        />
        <AppDateField
          source="saleStartTo"
          label="Inizio vendita al"
          headerClassName={classes.dates}
        />
        <AppDateField
          source="saleEndFrom"
          label="Fine vendita dal"
          headerClassName={classes.dates}
        />
        <AppDateField source="saleEndTo" label="Fine vendita al" headerClassName={classes.dates} />
        <ReferenceField
          reference="genres"
          source="genreId"
          label="Genere"
          link={false}
          emptyText="-"
          headerClassName={classes.genre}
        >
          <AppTextField source="description" />
        </ReferenceField>
        <BooleanField
          source="includeExpressionOfInterest"
          label="Manifestazioni di interesse incluse"
          headerClassName={classes.booleans}
        />
        <BooleanField
          source="expressionOfInterestOnly"
          label="Solo manifestazioni di interesse"
          headerClassName={classes.booleans}
        />
        <AppTextField source="notes" label="Note" headerClassName={classes.lastCol} />
        <AppDownloadFromGCPBucketField
          source="publicUrl"
          label="PDF"
          alwaysShow
          sortable={false}
          headerClassName={classes.buttons}
          cellClassName={classes.buttons}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default PublicationProofList;
