import { FC, useContext, useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppCard from '../ui/card/AppCard';
import { AppAutocompleteInput, AppTextInput } from '../ui/input';
import AppDrawerForm from '../drawer-form/drawer-form';

const AppBuyNowItemVariantsManager: FC<any> = (props) => {
  const { anchor, title, ...others } = props;
  const notify = useNotify();

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  const [variantChoices, setVariantChoices] = useState<Record<string, any>[]>([]);
  const [defaultValues, setDefaultValues] = useState<Record<string, any>[]>([]);
  const [mutateVariantChoices] = useMutation();
  const refresh = useRefresh();

  /// region PROPERTY CHOICES
  useEffect(() => {
    if (ctx.manager.mode === 'insert') {
      handleVariantChoices();
    }
  }, [ctx.manager.isOpen]);

  const handleVariantChoices = () =>
    ctx.manager.isOpen &&
    mutateVariantChoices(
      {
        type: 'getList',
        resource: 'items/buy-now-variants',
        payload: {},
      },
      {
        onSuccess: (res) => {
          const listIds: number[] = Object.values(ctx.listContext.data).map((l) => l.id);
          const availableVariants = res.data.filter((d) => !listIds.includes(d.id));
          setVariantChoices(availableVariants);
        },
        onFailure: (err) => {
          notify('Non è stato possibile recuperare la lista delle varianti.', 'error');
          console.error(err);
        },
      },
    );

  // endregion

  const handleVariantSelect = (variant) => {
    ctx.item.values = variant.values;
  };

  return (
    <AppDrawerForm
      anchor={anchor}
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      saveBtn={true}
      deleteBtn={true}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        ctx.manager.submit(data, close);
        refresh();
      }}
      record={ctx.item}
      mode={ctx.manager.mode}
      onRemove={(data, close) => ctx.manager.remove(data, close)}
      {...others}
    >
      <AppCard title="Informazioni generali" expanded>
        {ctx.manager.mode === 'insert' && ( // cannot modify property once is saved in "insert" mode
          <>
            <p>Usa variante già esistente</p>
            <AppAutocompleteInput
              source="existingBuyNowVariant"
              label="Nome"
              choices={variantChoices}
              onSelect={(variant) => handleVariantSelect(variant)}
              onChange={(variant) => {
                if (!variant) {
                  setDefaultValues([]);
                }
              }}
              resettable
              md={12}
            />
          </>
        )}
        <div>
          {ctx.manager.mode === 'insert' && <p>oppure</p>}
          <p>{(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}</p>
          <AppTextInput source="name" label="Nome" md={12} />
        </div>

        <ArrayInput source="values" label="Valori variante" isRequired>
          <SimpleFormIterator
            disableRemove
            TransitionProps={{ enter: false, exit: false }}
            defaultValue={defaultValues}
          >
            <AppTextInput source="value" label="Valore" md={12} />
            <BooleanInput
              source="isActive"
              defaultValue={false}
              label="Abilitato"
              style={{ width: '50px', marginLeft: '200px', marginTop: '-60px' }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </AppCard>
    </AppDrawerForm>
  );
};

export default AppBuyNowItemVariantsManager;
