import { Button, Grid } from '@material-ui/core';
import { GoogleMap, Marker, StreetViewPanorama } from '@react-google-maps/api';
import { debounce as _debounce, get as _get, isEqual as _isEqual } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import AppDivider from '../layout/AppDivider';
import { AppAutocompleteInput, AppTextInput } from './';
import { AppGeographiesAutocomplete } from './AppGeographiesAutocomplete';
import AppInputHidden from './AppInputHidden';

type Props = {
  addressSource: string;
  zipCodeSource: string;
  countrySource: string;
  regionSource: string;
  provinceSource: string;
  citySource: string;
  latitudeSource?: string;
  longitudeSource?: string;
  streetViewLatitudeSource?: string;
  streetViewLongitudeSource?: string;
  streetViewPovSource?: string;
  disabled?: boolean;
  geocoderRef?: any;
  fillCityOnly?: boolean; // used for movable items in order to hide address/zipCode compilation
} & Record<string, any>;

const AppGeographiesInput: FC<Props> = React.memo<Props>((props) => {
  const {
    record,
    variant,
    addressSource,
    addressNumberSource,
    zipCodeSource,
    countrySource,
    regionSource,
    provinceSource,
    citySource,
    latitudeSource,
    longitudeSource,
    streetViewPovSource,
    streetViewLatitudeSource,
    streetViewLongitudeSource,
    mode = 'edit',
    required = false,
    disabled = false,
    geocoderRef,
    fillCityOnly = false,
  } = props;
  //set choices initial state
  const [regionChoices, setRegionChoices] = useState([]);
  const [provinceChoices, setProvinceChoices] = useState([]);
  const [cityChoices, setCityChoices] = useState([]);
  const countryChoices = [{ id: 1, name: 'Italia' }];

  const [mapMarkerType, setMapMarkerType] = useState<'street-view' | 'address'>('address');
  //const [inputMode, setInputMode] = useState<'auto' | 'manual'>(mode === 'create' ? 'manual' : 'auto');
  const [inputsEnabled, setInputsEnabled] = useState<boolean>(mode === 'edit' && !disabled);

  // Get form mutators and values
  const { mutators: formMutators, change } = useForm();
  const { values } = useFormState();

  // Update coordinates once every necessary field is filled
  const prevAddressState = useRef({
    region: values[regionSource],
    province: values[provinceSource],
    city: values[citySource],
    zipCode: values[zipCodeSource],
    address: values[addressSource],
    addressNumber: values[addressNumberSource],
  });

  // Zip code initial state
  const [zipCodeChoices, setZipChoices] = useState(() => {
    if (mode === 'create') {
      return;
    }

    const zipCode = _get(values, zipCodeSource);
    if (!zipCode) {
      return [];
    }

    return [
      {
        id: zipCode,
        name: zipCode,
      },
    ];
  });

  // get mutation function for choices changes
  const [mutateRegions] = useMutation();
  const [mutateProvinces] = useMutation();
  const [mutateCities] = useMutation();

  const showMap =
    (latitudeSource && longitudeSource) || (streetViewLatitudeSource && streetViewLongitudeSource);

  const mapRef = useRef<any>();
  //  const geocoderRef = useRef<any>();
  const povRef = useRef<Record<string, any>>(
    streetViewPovSource ? record[streetViewPovSource] : {},
  );
  const svLatitudeRef = useRef<number>(
    streetViewLatitudeSource ? record[streetViewLatitudeSource] : 0,
  );
  const svLongitudeRef = useRef<number>(
    streetViewLongitudeSource ? record[streetViewLongitudeSource] : 0,
  );

  if (showMap) {
    // Initialise map's POV
    useEffect(() => {
      if (mapRef.current && streetViewPovSource) {
        mapRef.current.streetView.setPov(record[streetViewPovSource]);
      }
    }, [mapRef.current]);
  }

  // Update inputs on mode change
  useEffect(() => {
    setInputsEnabled((mode === 'edit' || mode === 'create') && !disabled);
  }, [mode]);

  // get initial choices using existing values if 'edit' mode
  useEffect(() => {
    if (
      (mode === 'edit' || mode === 'show') &&
      (!!_get(values, countrySource) ||
        !!_get(values, regionSource) ||
        !!_get(values, provinceSource))
    ) {
      getRegions(_get(values, countrySource), false);
      getProvinces(_get(values, regionSource), false);
      getCities(_get(values, provinceSource), false);
    }
  }, [_get(values, countrySource), _get(values, regionSource), _get(values, provinceSource)]);

  useEffect(() => {
    const region = values[regionSource];
    const province = values[provinceSource];
    const city = values[citySource];
    const zipCode = values[zipCodeSource];
    const address = values[addressSource];
    const addressNumber = values[addressNumberSource];

    // All fields must be set
    if (!region || !province || !city || !zipCode || !address) return;

    // Fields must have changed
    const { current } = prevAddressState;
    if (
      current.region === region &&
      current.province === province &&
      current.city === city &&
      current.zipCode === zipCode &&
      current.address === address &&
      current.addressNumber === addressNumber
    )
      return;
    _debounce(() => {
      if (geocoderRef.current) {
        const currentCity: any = cityChoices.find((item: any) => item.id === city);
        const geocodeAddress = encodeURI(
          `${address} ${addressNumber ?? ''} ${currentCity?.name ?? ''} ${zipCode}`.replaceAll(
            ' ',
            '+',
          ),
        );

        geocoderRef.current.geocode(
          {
            address: geocodeAddress,
          },
          (result: any[], status: string) => {
            if (!result || status !== 'OK') return;
            const selected = result[0];
            const { location } = selected.geometry;
            const lat = location.lat();
            const lng = location.lng();

            if (latitudeSource) {
              change(latitudeSource, lat);
            }
            if (longitudeSource) {
              change(longitudeSource, lng);
            }
            if (streetViewLatitudeSource) {
              change(streetViewLatitudeSource, lat);
            }
            if (streetViewLongitudeSource) {
              change(streetViewLongitudeSource, lng);
            }
          },
        );
      }
    }, 1000)();
    // Set new prev value
    prevAddressState.current = {
      region,
      province,
      city,
      zipCode,
      address,
      addressNumber,
    };
  }, [
    values[citySource],
    values[zipCodeSource],
    values[addressSource],
    values[addressNumberSource],
  ]);

  // Update zip code choices
  useEffect(() => {
    if (!_get(values, citySource)) {
      return;
    }

    const city: any = cityChoices.find((city: any) => city.id === _get(values, citySource));
    if (!city) return;

    const zipCodes = city.zipCode || [];
    setZipChoices(
      zipCodes.map((zip) => ({
        id: zip,
        name: zip,
      })),
    );

    if (zipCodes.length === 1) {
      change(zipCodeSource, zipCodes[0]);
    }
  }, [_get(values, citySource), cityChoices]);

  const getRegions = (countryId, changed = true) =>
    countryId &&
    mutateRegions(
      {
        type: 'getList',
        resource: 'regions',
        payload: {
          filter: {
            fkCountry: countryId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setRegionChoices(res.data);

          if (changed) {
            //done only if the previous autocomplete has changed
            formMutators.clearInputs([regionSource, provinceSource, citySource]);
            setProvinceChoices([]);
            setCityChoices([]);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs([regionSource, provinceSource, citySource]);
          setRegionChoices([]);
          setProvinceChoices([]);
          setCityChoices([]);
        },
      },
    );

  const getProvinces = (regionId, changed = true) =>
    regionId &&
    mutateProvinces(
      {
        type: 'getList',
        resource: 'provinces',
        payload: {
          filter: {
            fkRegion: regionId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setProvinceChoices(res.data);

          if (changed) {
            formMutators.clearInputs([provinceSource, citySource]);
            setCityChoices([]);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs([provinceSource, citySource]);
          setProvinceChoices([]);
          setCityChoices([]);
        },
      },
    );

  const getCities = (provinceId, changed = true) =>
    provinceId &&
    mutateCities(
      {
        type: 'getList',
        resource: 'cities',
        payload: {
          filter: {
            fkProvince: provinceId,
          },
        },
      },
      {
        onSuccess: (res) => {
          setCityChoices(res.data);

          if (changed) {
            formMutators.clearInputs(citySource);
            setZipChoices([]);
          }
        },
        onFailure: (err) => {
          console.error(err);
          formMutators.clearInputs(citySource);
          setCityChoices([]);
        },
      },
    );

  const getComponent = (components, componentName, fieldName = 'long_name') => {
    for (let i = 0; i < components.length; i++) {
      for (let j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] === componentName) {
          /*
           *
           * Workaround to avoid problems with the mismatch between "Reggio Emilia" and "Reggio nell'Emilia" -> https://astech-ai.atlassian.net/browse/AS-2149
           * After finding a better solution we can restore the original code:
           * return components[i][fieldName];
           *
           */
          return componentName === 'administrative_area_level_3' &&
            components[i][fieldName] === 'Reggio Emilia'
            ? "Reggio nell'Emilia"
            : components[i][fieldName];
        }
      }
    }
  };

  const getCountry = (components) => {
    return getComponent(components, 'country');
  };

  const getRegion = (components) => {
    return getComponent(components, 'administrative_area_level_1');
  };

  /* MAY BE UNNECESSARY */
  /*const getProvince = (components) => {
    return getComponent(components, 'administrative_area_level_2');
  };*/

  const getProvinceCode = (components) => {
    return getComponent(components, 'administrative_area_level_2', 'short_name');
  };

  const getCity = (components) => {
    return getComponent(components, 'administrative_area_level_3');
  };

  /* MAY BE UNNECESSARY */
  /*const getLocality = (components) => {
    return getComponent(components, 'locality');
  };*/

  const getStreet = (components) => {
    return getComponent(components, 'route');
  };

  const getStreetNumber = (components) => {
    return getComponent(components, 'street_number');
  };

  const getPostalCode = (components) => {
    return getComponent(components, 'postal_code');
  };

  const getLatitude = (place) => {
    return place.geometry.location.lat() ?? 0;
  };

  const getLongitude = (place) => {
    return place.geometry.location.lng() ?? 0;
  };

  const onPlaceChanged = (autocomplete) => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    const countryId =
      countryChoices.find((country) => country.name === getCountry(place.address_components))?.id ??
      1;
    let regionId;
    let provinceId;
    let cityId;
    let zipCodes;
    change(countrySource, countryId);
    mutateRegions(
      {
        type: 'getList',
        resource: 'regions',
        payload: {
          filter: {
            fkCountry: countryId,
          },
        },
      },
      {
        onSuccess: ({ data: regions }) => {
          setRegionChoices(regions);
          regionId =
            regions.find((region) => region.name === getRegion(place.address_components))?.id ?? -1;
          change(regionSource, regionId);
          mutateProvinces(
            {
              type: 'getList',
              resource: 'provinces',
              payload: {
                filter: {
                  fkRegion: regionId,
                },
              },
            },
            {
              onSuccess: ({ data: provinces }) => {
                setProvinceChoices(provinces);
              },
              onFailure: console.error,
            },
          );
          mutateProvinces(
            {
              type: 'getList',
              resource: 'provinces',
              payload: {
                filter: {
                  fkRegion: regionId,
                  initials: getProvinceCode(place.address_components),
                },
              },
            },
            {
              onSuccess: ({ data: provinces }) => {
                provinceId = provinces[0].id ?? -1;
                change(provinceSource, provinceId);
                mutateCities(
                  {
                    type: 'getList',
                    resource: 'cities',
                    payload: {
                      filter: {
                        fkProvince: provinceId,
                      },
                    },
                  },
                  {
                    onSuccess: ({ data: cities }) => {
                      setCityChoices(cities);
                      const city =
                        cities.find((city) => city.name === getCity(place.address_components)) ??
                        null;
                      cityId = city?.id ?? -1;
                      zipCodes = city.zipCode || [];
                      change(citySource, cityId);
                      setZipChoices(
                        zipCodes.map((zip) => ({
                          id: zip,
                          name: zip,
                        })),
                      );
                      if (getPostalCode(place.address_components)) {
                        change(zipCodeSource, getPostalCode(place.address_components));
                      } else if (zipCodes.length === 1) {
                        change(zipCodeSource, zipCodes[0]);
                      } else {
                        formMutators.clearInputs(zipCodeSource);
                      }
                    },
                    onFailure: console.error,
                  },
                );
              },
              onFailure: console.error,
            },
          );
        },
        onFailure: console.error,
      },
    );

    // Set address
    if (getStreet(place.address_components)) {
      change(addressSource, getStreet(place.address_components));
      change(addressNumberSource, getStreetNumber(place.address_components));
    } else {
      formMutators.clearInputs([addressSource, addressNumberSource]);
    }

    // set lat/lng
    if (latitudeSource) {
      change(latitudeSource, getLatitude(place));
    }
    if (longitudeSource) {
      change(longitudeSource, getLongitude(place));
    }
    if (streetViewLatitudeSource) {
      change(streetViewLatitudeSource, getLatitude(place));
    }
    if (streetViewLongitudeSource) {
      change(streetViewLongitudeSource, getLongitude(place));
    }
  };

  //TODO: AGGIUNGERE IL MODO PER TROVARE LE COORDINATE DAI CAMPI ANZICHÉ DA PLACES API?
  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <p>Usa l'autocompletamento per cercare l'indirizzo</p>
      </Grid>
      <Grid item xs={12} md={12}>
        <AppGeographiesAutocomplete onPlaceChanged={onPlaceChanged} disabled={!inputsEnabled} />
      </Grid>
      <Grid item xs={12} md={12}>
        <p style={{ textAlign: 'center' }}>~~~ o ~~~</p>
      </Grid>
      <Grid item xs={12} md={12}>
        <p>Oppure compila manualmente i campi</p>
      </Grid>
      <AppAutocompleteInput
        source={countrySource}
        label="Nazione"
        variant={variant}
        md={3}
        onChange={getRegions}
        choices={countryChoices}
        disabled={!inputsEnabled}
        required={required}
      />
      <AppAutocompleteInput
        source={regionSource}
        label="Regione"
        variant={variant}
        md={3}
        choices={regionChoices}
        onChange={getProvinces}
        disabled={!inputsEnabled || regionChoices.length == 0}
        required={required}
      />
      <AppAutocompleteInput
        source={provinceSource}
        label="Provincia"
        variant={variant}
        md={3}
        choices={provinceChoices}
        onChange={getCities}
        disabled={!inputsEnabled || provinceChoices.length == 0}
        required={required}
      />
      <AppAutocompleteInput
        source={citySource}
        label="Comune"
        variant={variant}
        md={3}
        choices={cityChoices}
        disabled={!inputsEnabled || cityChoices.length == 0}
        required={required}
      />
      {!fillCityOnly && [
        <AppTextInput
          source={addressSource}
          required={required}
          label="Indirizzo"
          variant="outlined"
          md={6}
          disabled={!inputsEnabled}
        />,
        <AppTextInput
          source={addressNumberSource}
          label="Civico"
          variant="outlined"
          md={2}
          disabled={!inputsEnabled}
        />,
        <AppAutocompleteInput
          source={zipCodeSource}
          required={required}
          label="CAP"
          md={4}
          variant={variant}
          choices={zipCodeChoices}
          disabled={!inputsEnabled}
        />,
      ]}
      {showMap && <AppDivider />}
      {showMap && (
        <Grid container direction={'column'} alignItems={'center'} md={12}>
          {/* Marker type selection */}
          {streetViewLongitudeSource && streetViewLatitudeSource && (
            <div style={{ marginTop: '20px' }}>
              <Button
                variant={'contained'}
                onClick={() => setMapMarkerType('street-view')}
                color={mapMarkerType === 'street-view' ? 'primary' : 'secondary'}
              >
                Street view
              </Button>
              <Button
                variant={'contained'}
                onClick={() => setMapMarkerType('address')}
                color={mapMarkerType === 'address' ? 'primary' : 'secondary'}
              >
                Indirizzo
              </Button>
            </div>
          )}
          {/* Map view */}
          <GoogleMap
            mapContainerStyle={{ height: '360px', width: '520px', margin: '20px 0' }}
            center={{
              lat:
                mapMarkerType === 'address'
                  ? (latitudeSource && values[latitudeSource]) ||
                    (streetViewLatitudeSource && values[streetViewLatitudeSource]) ||
                    0
                  : 0,
              lng:
                mapMarkerType === 'address'
                  ? (longitudeSource && values[longitudeSource]) ||
                    (streetViewLongitudeSource && values[streetViewLongitudeSource]) ||
                    0
                  : 0,
            }}
            zoom={15}
            onLoad={(ref) => {
              mapRef.current = ref;
            }}
          >
            {mapMarkerType === 'street-view' && (
              <StreetViewPanorama
                onPovChanged={() => {
                  // Prevent continuous rerender
                  _debounce(() => {
                    const newPov = mapRef.current.streetView.pov;
                    if (_isEqual(povRef.current, newPov)) return;

                    streetViewPovSource && change(streetViewPovSource, newPov);
                    povRef.current = newPov;
                  }, 2000)();
                }}
                onPositionChanged={() => {
                  // Prevent continuous rerender
                  _debounce(() => {
                    const { position } = mapRef.current.streetView;
                    const lat = position.lat();
                    const lng = position.lng();

                    if (lat === svLatitudeRef.current && lng === svLongitudeRef.current) {
                      return;
                    }

                    streetViewLatitudeSource && change(streetViewLatitudeSource, lat);
                    streetViewLongitudeSource && change(streetViewLongitudeSource, lng);
                    svLatitudeRef.current = lat;
                    svLongitudeRef.current = lng;
                  }, 1000)();
                }}
                options={{
                  enableCloseButton: false,
                  position: {
                    lat: streetViewLatitudeSource ? values[streetViewLatitudeSource] : 0,
                    lng: streetViewLongitudeSource ? values[streetViewLongitudeSource] : 0,
                  },
                  visible: true,
                }}
              />
            )}
            {/* Address marker */}
            {mapMarkerType === 'address' && (
              <Marker
                draggable={mode !== 'show'}
                onDragEnd={({ latLng }) => {
                  if (!latLng) return;
                  latitudeSource && change(latitudeSource, latLng.lat());
                  longitudeSource && change(longitudeSource, latLng.lng());
                }}
                position={{
                  lat: latitudeSource ? +values[latitudeSource] : 0,
                  lng: longitudeSource ? +values[longitudeSource] : 0,
                }}
              />
            )}
          </GoogleMap>
        </Grid>
      )}
      {latitudeSource && (
        <AppInputHidden source={latitudeSource} label="Latitudine" variant={variant} />
      )}
      {longitudeSource && (
        <AppInputHidden source={longitudeSource} label="Longitudine" variant={variant} />
      )}
      {streetViewPovSource && (
        <React.Fragment>
          <AppInputHidden source={`${streetViewPovSource}.heading`} label="StreetViewPov.heading" />
          <AppInputHidden
            source={`${streetViewPovSource}.pitch`}
            label="StreetViewPov.pitch"
            variant={variant}
          />
          <AppInputHidden source={`${streetViewPovSource}.zoom`} label="StreetViewPov.zoom" />
        </React.Fragment>
      )}
      {streetViewLatitudeSource && (
        <AppInputHidden
          source={streetViewLatitudeSource}
          label="Latitudine streetview"
          variant={variant}
        />
      )}
      {streetViewLongitudeSource && (
        <AppInputHidden
          source={streetViewLongitudeSource}
          label="Longitudine streetview"
          variant={variant}
        />
      )}
    </React.Fragment>
  );
});

export default AppGeographiesInput;
