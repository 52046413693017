import { makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { get as _get } from 'lodash';
import React, { FC } from 'react';
import { useCheckMinimumRequiredProps, useMutation, useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useToggleState } from '../../hooks/use-toggle-state';
import { GENDER_CHOICES, PARTICIPANT_TYPES } from '../../utils/constants';
import AppContactsData from '../person-company/contacts-data.component';
import AppAlert from '../ui/alert/AppAlert';
import AppCard from '../ui/card/AppCard';
import {
  AppAutocompleteInput,
  AppBooleanInput,
  AppDateInput,
  AppSelectInput,
  AppTextInput,
} from '../ui/input';
import AppInternationalGeographiesInput from '../ui/input/AppInternationalGeographiesInput';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';

const useStyles = makeStyles(
  (theme) => {
    return {
      addPersonButton: {
        position: 'relative',
        top: -theme.spacing(1),
      },
      depositorAlert: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      presenterAlert: {
        marginBottom: theme.spacing(2),
      },
    };
  },
  { name: 'ParticipationPerson' },
);

const ParticipationPerson: FC<any> = (props) => {
  useCheckMinimumRequiredProps(
    'ParticipationPerson',
    ['type', 'participantChoiceSource', 'participantChoiceLabel'],
    props,
  );
  const {
    disabled,
    participantChoiceSource,
    participantChoiceLabel,
    type: participationPersonType,
    participationIsNotFromPaper,
    actionsDisabled,
  } = props;
  const classes = useStyles();

  const [loaderOpen, toggleLoader] = useToggleState();

  const [mutatePresenterInfo] = useMutation();

  const { change } = useForm();
  const { values } = useFormState();

  const notify = useNotify();

  const isDisabled =
    disabled || (participationPersonType === 'depositor' && values.presenterIsDepositor);

  const getExistingParticipantInfo = (participantId) => {
    if (!participantId) return;

    toggleLoader();

    mutatePresenterInfo(
      {
        type: 'getOne',
        resource: 'participants',
        payload: {
          id: participantId,
        },
      },
      {
        onSuccess: (res) => {
          toggleLoader();
          Object.keys(res.data).forEach((field) => {
            change(`${participationPersonType}.${field}`, res.data[field]);
          });
          notify('Compilazione automatica dei dati eseguita con successo.', 'info');
        },
        onFailure: (err) => {
          console.error(err);
          toggleLoader();
        },
      },
    );
  };

  return (
    <React.Fragment>
      {isDisabled && participationPersonType === 'depositor' && (
        <Alert severity="info" className={classes.depositorAlert} variant="filled">
          <Typography>
            Il presentatore è anche depositante, non è necessario compilare i campi sottostanti.{' '}
            <br />
            Con l'eventuale aggiornamento dei dati del presentatore verranno aggiornati anche i dati
            del depositante
          </Typography>
        </Alert>
      )}
      <AppCard title={participantChoiceLabel} disabled={isDisabled || actionsDisabled} expanded>
        {!participationIsNotFromPaper && (
          <AppAlert
            children={
              'Puoi usare il campo "Anagrafica" per ricercarne una già esistente a sistema e compilare in automatico i campi sottostanti. In caso l\'anagrafica desiderata non sia presente compila manualmente i campi, essa verrà creata al salvataggio dell\'offerta.'
            }
            severity="info"
            className={classes.presenterAlert}
          />
        )}
        <AppAutocompleteInput
          reference="participants"
          filter={{
            blacklist: false,
          }}
          optionText={(participant) => {
            if (!participant) return '';
            return `${participant.lastName} ${participant.firstName} (${(
              participant.taxCode || '-'
            ).toUpperCase()})`;
          }}
          source={participantChoiceSource}
          onChange={(participantId) => {
            getExistingParticipantInfo(participantId);
            if (!participantId) {
              change(`${participationPersonType}`, undefined);
            }
          }}
          suggestionLimit={5}
          md={6}
          resettable
          label="Anagrafica"
        />
      </AppCard>
      <AppCard title="Dati anagrafici" disabled={isDisabled}>
        <AppTextInput
          source={`${participationPersonType}.firstName`}
          label="Nome"
          required
          md={5}
        />
        <AppTextInput
          source={`${participationPersonType}.lastName`}
          label="Cognome"
          required
          md={4}
        />
        <AppSelectInput
          source={`${participationPersonType}.type`}
          label="Tipologia anagrafica"
          choices={PARTICIPANT_TYPES}
          required
          defaultValue={
            participationPersonType === 'depositor' ? PARTICIPANT_TYPES[0].id : undefined
          }
          md={3}
        />
        <AppSelectInput
          source={`${participationPersonType}.gender`}
          label="Sesso"
          choices={GENDER_CHOICES}
          md={2}
        />
        <AppTextInput
          source={`${participationPersonType}.taxCode`}
          label="Codice fiscale"
          required={participationPersonType !== 'depositor'}
          md={3}
        />
        <AppDateInput
          source={`${participationPersonType}.birthday`}
          label="Data di nascita"
          md={3}
        />
      </AppCard>
      <AppCard
        title="Residenza"
        hidden={participationPersonType === 'depositor'}
        disabled={isDisabled}
      >
        <AppInternationalGeographiesInput
          variant="outlined"
          addressSource={`${participationPersonType}.residenceAddress`}
          addressNumberSource={`${participationPersonType}.residenceAddressNumber`}
          zipCodeSource={`${participationPersonType}.residenceZipCode`}
          countrySource={`${participationPersonType}.residenceCountry`}
          regionSource={`${participationPersonType}.residenceRegion`}
          provinceSource={`${participationPersonType}.residenceProvince`}
          citySource={`${participationPersonType}.residenceCity`}
          foreignCitySource={`${participationPersonType}.residenceForeignCity`}
          mode="edit"
        />
      </AppCard>
      <AppCard
        title="Domicilio"
        hidden={participationPersonType === 'depositor'}
        disabled={isDisabled}
      >
        <AppBooleanInput
          source={`${participationPersonType}.domicileIsResidence`}
          label="Coincide con la residenza"
          initialValue={true}
        />
        {!_get(values, `${participationPersonType}.domicileIsResidence`) && (
          <AppInternationalGeographiesInput
            variant="outlined"
            addressSource={`${participationPersonType}.domicileAddress`}
            addressNumberSource={`${participationPersonType}.domicileAddressNumber`}
            zipCodeSource={`${participationPersonType}.domicileZipCode`}
            countrySource={`${participationPersonType}.domicileCountry`}
            regionSource={`${participationPersonType}.domicileRegion`}
            provinceSource={`${participationPersonType}.domicileProvince`}
            citySource={`${participationPersonType}.domicileCity`}
            foreignCitySource={`${participationPersonType}.domicileForeignCity`}
            mode="edit"
          />
        )}
      </AppCard>
      <AppContactsData
        record={values}
        disabled={isDisabled}
        father={participationPersonType}
        emailRequired={participationPersonType !== 'depositor'}
      />
      <AppCard
        title="Informazioni azienda"
        hidden={
          !values[participationPersonType] || values[participationPersonType].type !== 'company'
        }
        disabled={isDisabled}
      >
        <AppTextInput
          source={`${participationPersonType}.businessName`}
          required
          label="Ragione sociale"
          md={4}
        />
        <AppTextInput
          source={`${participationPersonType}.vat`}
          required
          label="Partita IVA"
          md={4}
        />
        <AppTextInput
          source={`${participationPersonType}.sdiCode`}
          defaultValue="0000000"
          label="Codice SDI"
          md={4}
        />
        <AppTextInput
          source={`${participationPersonType}.registeredOffice`}
          label="Sede legale"
          required
        />
        <AppTextInput
          source={`${participationPersonType}.roleInsideCompany`}
          label="Ruolo adottato nell'azienda"
          required
        />
        <AppContactsData
          record={values}
          sourcePrefix={`${participationPersonType}.company`}
          emailRequired
        />
      </AppCard>
      <AppCircularLoader open={loaderOpen} text="Caricamento informazioni anagrafica" />
    </React.Fragment>
  );
};

export default ParticipationPerson;
