import { ResourceExport } from '../../types/resource-export.type';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SaleExperimentCalendar from './sale-experiment-calendar';

const saleExperimentCalendarProps: ResourceExport = {
  list: SaleExperimentCalendar,
  icon: CalendarTodayIcon,
};

export default saleExperimentCalendarProps;
