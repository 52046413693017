import { Email } from '@material-ui/icons';
import { createElement } from 'react';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourceExport } from '../../types/resource-export.type';
import CommunicationDetailActions from './CommunicationDetailActions';
import CommunicationDetails from './CommunicationDetails';
import CommunicationList from './CommunicationList';

const Communication: ResourceExport = {
  list: CommunicationList,
  show: resourceWrapper(
    'show',
    CommunicationDetails,
    undefined,
    createElement(CommunicationDetailActions),
  ),
  icon: Email,
};

export default Communication;
