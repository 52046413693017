import { AppBar as MuiAppBar, IconButton, Theme, Toolbar, Typography } from '@material-ui/core';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { toggleSidebar, useTranslate } from 'ra-core';
import * as React from 'react';
import { Children } from 'react';
import { ClassesOverride, LoadingIndicator } from 'react-admin';
import { useDispatch } from 'react-redux';
import AppTooltip from '../tooltip/AppTooltip';

const useStyles = makeStyles(
  (theme: Theme) => ({
    toolbar: {
      paddingRight: '1.5rem',
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'AppToolbar' },
);

const AppToolbar = (props: AppBarProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, classes: classesOverride, className, color = 'primary', open, ...rest } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  //const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
    <MuiAppBar className={className} color={color} {...rest} position="relative">
      <Toolbar disableGutters variant="regular" className={classes.toolbar}>
        <AppTooltip
          title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
            _: 'Apri/Chiudi menu',
          })}
          enterDelay={500}
        >
          <IconButton
            color="inherit"
            onClick={() => dispatch(toggleSidebar())}
            className={classNames(classes.menuButton)}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
        </AppTooltip>
        {Children.count(children) === 0 ? (
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        ) : (
          children
        )}
        <LoadingIndicator />
        <div id="jugaad-toolbar-context-actions" />
      </Toolbar>
    </MuiAppBar>
  );
};

AppToolbar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
  open: PropTypes.bool,
};

AppToolbar.defaultProps = {};

export interface AppBarProps extends Omit<MuiAppBarProps, 'title' | 'classes'> {
  classes?: ClassesOverride<typeof useStyles>;
  open?: boolean;
}

export default AppToolbar;
