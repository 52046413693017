import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppBooleanInput, AppDateTimeInput, AppNumberInput } from '../../../Components/ui/input';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import { ItemGenre, SaleMode } from '../../../utils/constants';
import { amountFormatter } from '../../../utils/data-formatters';
import { maxDate } from '../../../utils/validators';

export const ExpressionOfInterest: FC<any> = ({
  formData,
  mode,
  selectedItem,
  lastSaleExperiment,
}) => {
  const { fkSaleMode } = formData;

  return fkSaleMode === SaleMode.ExpressionOfInterest ? (
    <AppCard title="Informazioni sulla manifestazione di interesse" disabled={mode === 'show'}>
      <AppDateTimeInput
        label="Inizio"
        source="expressionOfInterestStartAt"
        md={3}
        required
        validate={[maxDate('expressionOfInterestEndAt', 'Termine', false)]}
      />
      <AppDateTimeInput label="Termine" source="expressionOfInterestEndAt" md={3} required />
      <AppDivider />
      <AppBooleanInput
        source="showLastPrice"
        label="Pubblica il prezzo dell'ultimo tentativo di vendita"
        md={12}
      />
      <AppBooleanInput source="showMinPrice" label="Pubblica offerta minima presentabile" />
      <AppNumberInput
        label="Offerta minima presentabile"
        source="minPrice"
        md={6}
        required={selectedItem.fkGenre === ItemGenre.RealEstate && !lastSaleExperiment}
        isAmount
        defaultValue={lastSaleExperiment?.minPrice}
      />
      <AppBooleanInput
        source="showEvaluationPrice"
        label="Pubblica il prezzo di perizia del lotto"
        disabled={!selectedItem.evaluation}
      />
      <Grid item md={6}>
        <Typography align="center">
          Prezzo di perizia{' '}
          <Box display="block">
            <Chip
              color={formData.showEvaluationPrice ? 'primary' : 'secondary'}
              label={amountFormatter(selectedItem.evaluation)}
            />
          </Box>
        </Typography>
      </Grid>
    </AppCard>
  ) : (
    <></>
  );
};
