import { makeStyles } from '@material-ui/core/styles';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import React, { FC } from 'react';
import AppAlert from '../../Components/ui/alert/AppAlert';
import { AppText } from '../../Components/ui/text';
import { COMMUNICATION_COLORS } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'CommunicationsListLegend' },
);

const CommunicationsListLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: COMMUNICATION_COLORS.unsent[0] }} />
        Comunicazione ancora non inviata
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: COMMUNICATION_COLORS.processing[0] }} />
        Comunicazione in fase di invio
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: COMMUNICATION_COLORS.sent[0] }} />
        Comunicazione consegnata al servizio di invio
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: COMMUNICATION_COLORS.error[0] }} />
        Errore in fase di invio della comunicazione
      </AppText>
    </AppAlert>
  );
};

export default CommunicationsListLegend;
