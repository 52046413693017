import { FC, Fragment } from 'react';
import { BooleanInput } from 'react-admin';
import { AppTextInput } from '../ui/input';
import { AppImageInput } from '../ui/input/AppImageInput';

const AppPageSeoForm: FC<any> = () => {
  return (
    <Fragment>
      <AppTextInput source="metaTitle" label="Titolo" md={6} />
      <AppTextInput source="metaDescription" label="Descrizione" md={6} />
      <AppImageInput
        source="imageFile"
        accept="image/png,image/jpeg"
        label="Immagine"
        previewSource="seoImage.url"
        previewLabel="Immagine corrente"
        isRequired
      />
      <AppTextInput source="altImage" label="Testo alternativo immagine" md={4} />
      <AppTextInput source="keywords" label="Parole chiave" md={4} />
      <BooleanInput source="preventIndexing" label="Previeni indexing" md={2} />
    </Fragment>
  );
};

export default AppPageSeoForm;
