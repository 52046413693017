import { FC, ReactElement, useMemo } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppDateTimeInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import { AUCTION_ROOM_BOOKING_STATUSES } from '../../utils/constants';

const AuctionRoomBookingDetails: FC<any> = (props): ReactElement => {
  const isAuctioneer = useMemo(() => props.permissions?.roleId === 17, [props.permissions]);

  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard expanded title="Prenotazione sala d'asta">
        <AppSelectInput
          source="status"
          choices={AUCTION_ROOM_BOOKING_STATUSES}
          label="Stato"
          required
          md={4}
        />
        <AppDateTimeInput source="from" label="Dalle" variant="outlined" md={4} />
        <AppDateTimeInput source="to" label="Alle" variant="outlined" md={4} />
        <AppAutocompleteInput
          source="fkAuctionRoom"
          reference={'auction-rooms'}
          label="Sala d'asta"
          optionText={(selectedAuctionRoom) => {
            if (!selectedAuctionRoom) return '';
            return `${selectedAuctionRoom.title}`;
          }}
          filter={
            {
              _fulltextFields: ['title', 'description'],
            } as any
          }
          disabled={props.mode === 'show'}
          required //TODO: doesn't show the "*" in the label if "reference" prop is set (?)
          md={4}
        />
        {!isAuctioneer && (
          <AppAutocompleteInput
            source="fkPerson"
            reference={'people'}
            label="Persona"
            optionText={(selectedPerson) => {
              if (!selectedPerson) return '';
              return `${selectedPerson.firstName} ${selectedPerson.lastName}`;
            }}
            filter={
              {
                _fulltextFields: ['firstName', 'lastName'],
                role: 17,
              } as any
            }
            disabled={props.mode === 'show'}
            required //TODO: doesn't show the "*" in the label if "reference" prop is set (?)
            md={4}
          />
        )}
        <AppTextInput source="notes" label="Note" variant="outlined" md={4} />
      </AppCard>
    </AppBaseForm>
  );
};

export default AuctionRoomBookingDetails;
