import ConfigurationIcon from '@material-ui/icons/SettingsApplications';
import { resourceWrapper } from '../../../Components/ui/utils';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../../types/resource-export.type';
import ConfigurationDetails from './ConfigurationDetails';
import ConfigurationList from './ConfigurationList';

export const CONFIGURATION_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const Configuration: ResourceExport = {
  list: ConfigurationList,
  create: resourceWrapper(
    'create',
    ConfigurationDetails,
    undefined,
    undefined,
    CONFIGURATION_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    ConfigurationDetails,
    undefined,
    undefined,
    CONFIGURATION_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    ConfigurationDetails,
    undefined,
    undefined,
    CONFIGURATION_PERMISSIONS,
  ),
  icon: ConfigurationIcon,
};
export default Configuration;
