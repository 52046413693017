import React, { FC } from 'react';
import AppCard from '../../../Components/ui/card/AppCard';
import {
  AppDateInput,
  AppNumberInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { ENV_CLASS_TYPES, FUEL_TYPES } from '../../../utils/constants';
import { ItemRelatedDataProps } from '../ItemRelatedData';
import RuoteDaSognoDetails from './RuoteDaSognoDetails';
import MeridaDetails from './MeridaDetails';
import { User } from '../../../types/user.type';
import { usePermissions } from 'react-admin';
import SchiattiClassDetails from './SchiattiClassDetails';

const BASE_SOURCE = 'carsAndCycles';

// TODO: MOVE CUSTOM FIELDS HANDLING TO GENERIC COMPONENT

const RUOTE_DA_SOGNO_FK_FOLDER = 54; // RUOTE DA SOGNO fkFolder su CDA TEST
const MERIDA_FK_FOLDER = 33; // MERIDA fkFolder su CDA TEST
const SCHIATTI_CLASS_FK_FOLDER = 55; // MERIDA fkFolder su CDA TEST

const CarsAndCyclesDetails: FC<ItemRelatedDataProps> = (props) => {
  const { permissions: user } = usePermissions<User>();
  console.log('user', user);

  const { mode, formData } = props;

  /*
  const { change } = useForm();

  // TODO: autocomplete with the feature to add new choices TEST, side task needed to improve it (API side too)
  
  const [autocompleteText, setAutocompleteText] = useState<string>();
  const [brandChoices, setBrandChoices] = useState<Record<string, any>[]>([]);
  const [mutateBrandChoices] = useMutation();
  //const [showCreateChoiceButton, setShowCreateChoiceButton] = useState(false);

  useEffect(() => {
    formData.fkCategory === 5 && mutateBrandChoices({
      type: 'getList',
      resource: 'items/carsAndCycles/choices/brand',
      payload: {}
    }, {
      onSuccess: res => setBrandChoices(res.data),
      onFailure: err => {
        setBrandChoices([]);
        console.error(err);
      }
    })
  }, []);

  const handleChoicesUpdate = (newChoice) => {
    if (!newChoice || !newChoice.length) return;
    setBrandChoices(prevState => [
      ...prevState,
      { id: newChoice, name: newChoice }
    ]);
    change(`${BASE_SOURCE}.brand`, newChoice);
  }

  <AppAutocompleteInput
      source={`${BASE_SOURCE}.brand`}
      choices={brandChoices}
      label="Marca"
      md={3}
      onInputValueChange={debounce((input) => input && input.length > 2 && setAutocompleteText(input), 500)}
      options={{
        InputProps: {
          endAdornment: <InputAdornment position="end"><Button children="AGGIUNGI" onClick={() => handleChoicesUpdate(autocompleteText)} /></InputAdornment>,
        }
      }}
    />

   */

  if (formData.fkCategory !== 5) return <React.Fragment />;

  return (
    <>
      <AppCard title="Dettagli del veicolo" disabled={mode === 'show'}>
        <AppTextInput source={`${BASE_SOURCE}.brand`} label="Marca" md={2} />
        <AppTextInput source={`${BASE_SOURCE}.model`} label="Modello" md={2} />
        <AppDateInput
          source={`${BASE_SOURCE}.registrationDate`}
          label="Data di immatricolazione"
          md={3}
        />
        <AppNumberInput source={`${BASE_SOURCE}.mileage`} label="Chilometraggio" md={2} isAmount />
        <AppSelectInput
          source={`${BASE_SOURCE}.fuelType`}
          label="Tipo di alimentazione"
          choices={FUEL_TYPES}
          md={3}
        />
        <AppNumberInput source={`${BASE_SOURCE}.kw`} label="kW" md={2} isAmount />
        <AppSelectInput
          source={`${BASE_SOURCE}.environmentalClass`}
          label="Classe ambientale"
          choices={ENV_CLASS_TYPES}
          md={3}
        />
      </AppCard>
      {formData.fkFolder === RUOTE_DA_SOGNO_FK_FOLDER && <RuoteDaSognoDetails {...props} />}
      {formData.fkFolder === MERIDA_FK_FOLDER && <MeridaDetails {...props} />}
      {formData.fkFolder === SCHIATTI_CLASS_FK_FOLDER && <SchiattiClassDetails {...props} />}
    </>
  );
};

export default CarsAndCyclesDetails;
