import React, { FC, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import { AppAutocompleteInput } from '../../../Components/ui/input/AppAutocompleteInput';

type Props = {
  mode: string;
  source: string;
  label: string;
};

// TODO: can be avoided using optionValue attribute in autocomplete -> Cause strange text error when selecting option (keeping this component for now -.-)
const PageAgencySelection: FC<Props> = React.memo(({ mode, source, label }) => {
  const [pagesChoices, setPagesChoices] = useState([]);
  const [mutateItems] = useMutation();
  const form = useForm();
  const notify = useNotify();

  const getPages = () =>
    mutateItems(
      {
        type: 'getList',
        resource: 'pages-agencies',
        payload: {},
      },
      {
        onSuccess: (res) => {
          const choices = res.data.map((page) => {
            return { id: page.slug, name: page.header };
          });
          setPagesChoices(choices);
        },
        onFailure: (err) => {
          setPagesChoices([]);
          form.mutators.clearInputs(source);
          notify(err.message, 'error');
        },
      },
    );

  useEffect(() => {
    getPages();
  }, []);

  return (
    <React.Fragment>
      <AppAutocompleteInput
        source={source}
        label={label}
        md={6}
        choices={pagesChoices}
        disabled={mode === 'show'}
        variant="outlined"
        resettable
      />
    </React.Fragment>
  );
});

export default PageAgencySelection;
