import MagazineCoverIcon from '@material-ui/icons/Book';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import MagazineCoverDetails from './MagazineCoverDetails';
import MagazineCoverList from './MagazineCoverList';

export const MAGAZINE_COVER_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const MagazineCover: ResourceExport = {
  list: MagazineCoverList,
  create: resourceWrapper(
    'create',
    MagazineCoverDetails,
    undefined,
    undefined,
    MAGAZINE_COVER_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    MagazineCoverDetails,
    undefined,
    undefined,
    MAGAZINE_COVER_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    MagazineCoverDetails,
    undefined,
    undefined,
    MAGAZINE_COVER_PERMISSIONS,
  ),
  icon: MagazineCoverIcon,
};

export default MagazineCover;
