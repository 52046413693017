import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import AppTooltip from '../../../../Components/ui/tooltip/AppTooltip';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: 'AuctionManagementButton' },
);

const AuctionManagementButton: FC<AuctionManagementButtonProps> = (props) => {
  const { caption, clickBtnHandler, className, tooltipText, ...btnProps } = props;
  const classes = useStyles();

  return tooltipText ? (
    <AppTooltip title={tooltipText}>
      <Button
        {...btnProps}
        className={className ?? classes.button}
        onClick={clickBtnHandler}
        children={caption}
      />
    </AppTooltip>
  ) : (
    <Button
      {...btnProps}
      className={className ?? classes.button}
      onClick={clickBtnHandler}
      children={caption}
    />
  );
};

export default AuctionManagementButton;

interface AuctionManagementButtonProps extends Omit<ButtonProps, 'action'> {
  caption: string;
  clickBtnHandler: () => void;
  tooltipText?: string;
}
