import React, { Children, ReactElement } from 'react';
import { Grid } from '@material-ui/core';

export function innerGridTabsWrapper(
  children: ReactElement[],
  props: Record<string, any>,
): ReactElement[] {
  const { basePath, record, resource, variant, margin, disabled } = props;

  return Children.map(
    children,
    (element: ReactElement) =>
      element && (
        <Grid container item>
          {React.cloneElement(element, {
            basePath,
            record,
            resource,
            variant,
            margin,
            style: { width: '100%' },
            disabled,
          })}
        </Grid>
      ),
  );
}
