import _round from 'lodash/round';

/**
 * Percentage Format: XX,XX
 * @param value
 * @param percentage
 */
export const calcPercentageOfValue = (value: number | null, percentage: number | null): number =>
  value && percentage ? (value * percentage) / 100 : 0;

export const getPercentage = (originalFieldValue: number, refFieldValue: number): number => {
  const percentage = _round((originalFieldValue / refFieldValue) * 100, 2);

  return isNaN(percentage) ? 0 : percentage;
};
