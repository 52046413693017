import { List, makeStyles, Popover } from '@material-ui/core';
import React, { FC } from 'react';
import { useMutation } from 'react-admin';
import { AppText } from '../../../text';
import AppDivider from '../../AppDivider';
import { NotificationsListItem } from './notifications-list-item';

const useStyles = makeStyles(
  (theme) => ({
    listTitleContainer: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: theme.palette.primary.main,
    },
    listTitle: {
      paddingTop: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.secondary.light,
    },
    setAllAsReadContainer: {
      padding: theme.spacing(1),
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: theme.palette.info.main,
      },
    },
    listContainer: {
      maxHeight: theme.spacing(50),
      minWidth: `calc(100vw - ${theme.spacing(30)}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    listEmptyText: {
      textAlign: 'center',
      fontStyle: 'italic',
      padding: theme.spacing(3),
    },
    notificationsHelp: {},
  }),
  { name: 'NotificationsList' },
);

interface NotificationsListProps {
  notificationsListAnchor: any;
  closeNotificationsList: () => void;
  notificationsList: Record<string, any>[];
  setNotificationsList: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
}

export const NotificationsList: FC<NotificationsListProps> = React.memo(
  ({
    notificationsListAnchor,
    closeNotificationsList,
    notificationsList,
    setNotificationsList,
  }) => {
    const classes = useStyles();

    const [mutateNotification] = useMutation();
    const setNotificationAsRead = (notificationId: number) =>
      mutateNotification(
        {
          type: 'update',
          resource: `notifications/${notificationId}/set-as-read`,
          payload: {},
        },
        {
          onSuccess: () =>
            setNotificationsList((prevNotifications) =>
              prevNotifications.filter((notification) => notification.id !== notificationId),
            ),
          onFailure: (err) => {
            console.error('[setNotificationAsRead] Error: ', err);
          },
        },
      );

    const setAllNotificationsAsRead = () =>
      mutateNotification(
        {
          type: 'getOne',
          resource: 'notifications/set-all-as-read',
          payload: {},
        },
        {
          onSuccess: () => setNotificationsList([]),
          onFailure: (err) => {
            console.error('[setAllNotificationsAsRead] Error: ', err);
          },
        },
      );

    return (
      <Popover
        anchorEl={notificationsListAnchor}
        open={!!notificationsListAnchor}
        onClose={closeNotificationsList}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.listTitleContainer}>
          <AppText variant="h6" children="RIEPILOGO NOTIFICHE" className={classes.listTitle} />
          {/*
            <AppTooltip title="Le notifiche possono essere rimosse cliccandoci sopra. è possibile rimuovere tutte le notifiche cliccando su 'segna tutto come letto'">
              <HelpIcon className={classes.notificationsHelp} />
            </AppTooltip>
          */}
        </div>
        <List dense className={classes.listContainer} disablePadding>
          {!!notificationsList.length && (
            <div className={classes.setAllAsReadContainer} onClick={setAllNotificationsAsRead}>
              <AppText children="Segna tutto come letto" variant="button" />
            </div>
          )}
          {notificationsList.map((notification) => (
            <>
              <NotificationsListItem
                notification={notification}
                setAsRead={setNotificationAsRead}
              />
              <AppDivider marginBottom={0} />
            </>
          ))}
          {!notificationsList.length && (
            <AppText children="Nessuna nuova notifica." className={classes.listEmptyText} />
          )}
        </List>
      </Popover>
    );
  },
);
