import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BooleanField, ListProps, SelectField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';
import { MEDIA_ROLES_PERMISSIONS } from './index';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(30),
    },
    type: {
      minWidth: theme.spacing(25),
    },
    canBeMultiple: {
      width: '100%',
    },
  }),
  { name: 'MediaRolesList' },
);

const TYPE_CHOICES = [
  { id: 'image', name: 'Immagine' },
  { id: 'video', name: 'Video' },
  { id: 'virtual-tour', name: 'Tour Virtuale' },
  { id: 'document', name: 'Documento' },
];

const MediaRolesFilterList = (
  <AppListFilter helperText="Nome">
    <AppSelectInput md={3} source="type" choices={TYPE_CHOICES} label="Tipo" />
  </AppListFilter>
);

const MediaRolesList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Ruoli Media" />}
      bulkActionButtons={false}
      filters={MediaRolesFilterList}
      resourcePermissions={MEDIA_ROLES_PERMISSIONS}
    >
      <AppDatagrid>
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <SelectField
          source="type"
          choices={TYPE_CHOICES}
          label="Tipo"
          headerClassName={classes.type}
        />
        <BooleanField
          source="canBeMultiple"
          label="Accetta file multipli?"
          headerClassName={classes.canBeMultiple}
        />
        <AppShowButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={MEDIA_ROLES_PERMISSIONS}
        />
        <AppEditButton
          icon={<span />}
          permissions={props.permissions}
          resourcePermissions={MEDIA_ROLES_PERMISSIONS}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default MediaRolesList;
