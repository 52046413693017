import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(80),
    },
    code: {
      minWidth: theme.spacing(80),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'CourtList' },
);

const CourtList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Tribunali" />}
      filters={<AppListFilter helperText="Nome, Codice" />}
      actions={<AppToolbarActions children={null} buttons={[]} />}
      hideCreateButton
      sort={{
        field: 'order',
        order: 'ASC',
      }}
    >
      <AppDatagrid>
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <AppTextField source="code" label="Codice" headerClassName={classes.code} />
        <AppTextField source="order" label="Ordine" headerClassName={classes.lastCol} />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default CourtList;
