import { FC } from 'react';
import { amountFormatter, dateFormatter } from '../../../../utils/data-formatters';
import { PdfDataLine, PdfSection } from '../utils';
import { PdfSingleSectionProps } from './sections';

export const InfoSale: FC<PdfSingleSectionProps> = (props) => {
  const saleExp = props.additionalData.saleExperiment;
  return (
    <PdfSection subtitle="INFORMAZIONI VENDITA">
      <PdfDataLine label="ID vendita" value={`${saleExp.id}`} />
      <PdfDataLine label="ID PVP" value={`${saleExp.pvpId ?? '-'}`} />
      <PdfDataLine label="Tipo" value={`${saleExp.saleMode.description}`} />
      <PdfDataLine label="Lotto" value={`${saleExp.item.title}`} />
      <PdfDataLine
        label="Inizio vendita"
        value={`${dateFormatter(saleExp.auctionStartAt, false)}`}
      />
      <PdfDataLine
        label="Termine iscrizioni"
        value={`${dateFormatter(saleExp.bidsEndAt, false)}`}
      />
      <PdfDataLine
        label="Cauzione richiesta"
        value={
          saleExp.depositPriceRefTo === 'depositPercentageOnFirstOffer'
            ? `${saleExp.depositPercentageOnFirstOffer}% del prezzo offerto`
            : `€ ${amountFormatter(saleExp.depositPrice)}`
        }
      />
      <PdfDataLine
        label="Prezzo base"
        value={saleExp.basePrice ? `€ ${amountFormatter(saleExp.basePrice, true)}` : '-'}
      />
      <PdfDataLine
        label="Offerta minima"
        value={saleExp.minPrice ? `€ ${amountFormatter(saleExp.minPrice)}` : '-'}
      />
    </PdfSection>
  );
};
