import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ListProps } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';

const useStyles = makeStyles(
  (theme) => ({
    code: {
      minWidth: theme.spacing(20),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ScreenList' },
);

const ScreenList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Schermi d'asta" />}
      bulkActionButtons={false}
      filters={<AppListFilter helperText='Codice, Descrizione' />}
      actions={<AppToolbarActions children={null} buttons={[]} />}
    >
      <AppDatagrid>
        <AppTextField source='code' label='Codice' headerClassName={classes.code} />
        <AppTextField source='description' label='Descrizione' headerClassName={classes.lastCol} />
      </AppDatagrid>
    </AppList>
  );
};

export default ScreenList;
