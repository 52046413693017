import { Card, CardContent, CardProps, Collapse, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isUndefined, omit } from 'lodash';
import { Record } from 'ra-core';
import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppEmptySaveRequired } from '../../empty/app-empty';
import { AppGridProps } from '../types';
import { GRID_PROPS, gridProps } from '../utils';
import AppCardHeader from './AppCardHeader';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  collapsedContent: {
    transition: theme.transitions.create('padding', {
      duration: theme.transitions.duration.standard,
    }),
    padding: 0,
    '&:last-child': { padding: 0 },
  },
}));

const AppCard: FC<AppCardProps> = (props: AppCardProps) => {
  const {
    header,
    title,
    member,
    index,
    record = {} as Record,
    variant = 'outlined', //default for us!
    resource = '',
    margin,
    children,
    hidden = false,
    disabled = false,
    collapsable = true,
    hideContent = false,
    hiddenContentPlaceholder = <AppEmptySaveRequired />,
    expanded: defaultExpandState = false,
    ...rest
  } = props;
  const classes = useStyles();
  const [validInput, setValidInput] = useState<boolean | null>(null);
  const [changedInput, setChangedInput] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      setChangedInput(changedInput + 1);
      const invalidFields = ref.current?.getElementsByClassName('Mui-error');
      setValidInput(invalidFields.length === 0);
    }
  }, [children]);

  const [expanded, setExpanded] = useState(collapsable ? defaultExpandState : true);

  useEffect(() => {
    if (validInput === false) {
      setExpanded(true);
    }
  }, [changedInput]);

  const handleToggle = () => {
    collapsable && setExpanded(!expanded);
  };

  if (header && title) {
    throw new Error('AppCard - Both header and title are not accepted');
  }

  return hidden ? (
    <React.Fragment />
  ) : (
    <Card {...cardProps(rest)} className={classes.card} ref={ref}>
      {header
        ? cloneElement(header, {
            ...header.props,
            title,
            record,
            index,
            contentCollapsable: collapsable,
            contentExpanded: expanded,
            onToggle: handleToggle,
          })
        : header !== false && (
            <AppCardHeader
              title={title}
              record={record}
              contentCollapsable={collapsable}
              contentExpanded={expanded}
              onToggle={handleToggle}
            />
          )}
      <CardContent className={expanded ? '' : classes.collapsedContent}>
        <Collapse in={expanded} timeout="auto">
          {hideContent ? (
            hiddenContentPlaceholder
          ) : (
            <Grid container {...gridProps(rest)} spacing={props.spacing ?? 2} alignItems="center">
              {Children.map(children, (child) => {
                return isValidElement(child)
                  ? cloneElement(child, {
                      ...rest,
                      source: getChildSource(child),
                      index,
                      label: getChildLabel(child),
                      record,
                      resource,
                      variant,
                      margin,
                      disabled,
                      ...child.props, //must be the latest to permit specific input overwrite
                    })
                  : null;
              })}
            </Grid>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );

  function getChildSource(child: ReactElement): string {
    return !isUndefined(member) ? `${member}.${child.props.source}` : child.props.source;
  }

  function getChildLabel(child: ReactElement): string {
    return isUndefined(child.props.label)
      ? child.props.source
        ? `resources.${resource}.fields.${child.props.source}`
        : undefined
      : child.props.label;
  }
};

function cardProps(props: AppCardProps): CardProps {
  return omit(props, GRID_PROPS, 'basePath') as CardProps;
}

export type AppCardProps = CardProps &
  AppGridProps & {
    title?: string;
    header?: ReactElement | false;
    member?: string;
    index?: number;
    basePath?: string;
    record?: Record;
    resource?: string;
    margin?: 'none' | 'normal' | 'dense';
    variant?: 'standard' | 'outlined' | 'filled';
    hidden?: boolean;
    disabled?: boolean;
    collapsable?: boolean;
    expanded?: boolean;
    hideContent?: boolean;
    hiddenContentPlaceholder?: FC;
  };

export default AppCard;
