import { makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { Description, PhotoCamera } from '@material-ui/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  BooleanField,
  DeleteWithConfirmButton,
  FormDataConsumer,
  FunctionField,
  SelectField,
  useMutation,
} from 'react-admin';
import AppCapabilityManager from '../../Components/capability/app-capability-manager';
import AppCodeManager from '../../Components/code/app-code-manager';
import MandatoryOrSuggestedRoles from '../../Components/folder/MandatoryOrSuggestedRoles';
import ImagePreview from '../../Components/media/image-preview';
import UploadMediaButton from '../../Components/media/upload/upload-media-button';
import UploadMediaManager from '../../Components/media/upload/upload-media-manager';
import AppRelatedResource, {
  AppRelatedResourceEditButton,
} from '../../Components/related-resource/app-related-resource';
import AppButtonGroup from '../../Components/ui/button/AppButtonGroup';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppDownloadField from '../../Components/ui/field/AppDownloadField';
import AppMediaAdditionalFields from '../../Components/ui/field/AppMediaAdditionalFields';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppInputFiller,
  AppSelectArrayInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import {
  CAPABILITY_SAVED_AS,
  CODES_TYPES,
  FOLDER_ASSIGNMENTS,
  FOLDER_STATUSES,
  FOLDER_TYPES,
} from '../../utils/constants';
import { getReference } from '../../utils/reference-selector';
import { ITEM_PERMISSIONS } from '../Item';
import ItemDatagrid from '../Item/ItemDatagrid';
import { JudiciaryDetails, PrivateDetails } from './DetailComponents';

const useStyles = makeStyles((theme) => {
  const NAME_WIDTH = '18rem';
  const TYPE_WIDTH = '12rem';
  return {
    lastDatagridColumn: {
      width: '100%',
    },
    roleNameCol: {
      minWidth: theme.spacing(50),
    },
    nameCol: {
      minWidth: theme.spacing(30),
    },
    dateCol: {
      minWidth: theme.spacing(20),
    },
    mediaGridNameCol: {
      minWidth: NAME_WIDTH,
      maxWidth: NAME_WIDTH,
    },
    mediaGridNameCell: {
      minWidth: NAME_WIDTH,
      maxWidth: NAME_WIDTH,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    mediaGridDescCol: {
      width: '100%',
    },
    mediaGridTypeCol: {
      minWidth: TYPE_WIDTH,
      maxWidth: TYPE_WIDTH,
    },
  };
});

const FolderDetails = (props: Record<any, any>): ReactElement => {
  const classes = useStyles();

  /// region FOLDER GENERAL INPUTS OPTIONS

  const [folderTypeInputOptions, setFolderTypeInputOptions] = useState<Record<string, any>>({
    isDisabled: props.mode !== 'create',
    isVisible: true,
    choices: FOLDER_TYPES,
  });
  const [folderStatusInputOptions, setFolderStatusInputOptions] = useState<Record<string, any>>({
    label: 'Stato',
    choices: FOLDER_STATUSES,
  });
  const [folderAssignmentsInputOptions, setFolderAssignmentsInputOptions] = useState<
    Record<string, any>
  >({
    choices: FOLDER_ASSIGNMENTS,
  });

  useEffect(() => {
    // use resource to assign proper options to folder's general inputs (type, status, assignments)
    switch (props.resource) {
      case 'folders':
        break;
      case 'folders-judiciary':
        // type options
        setFolderTypeInputOptions({ isVisible: false });
        // status options
        setFolderStatusInputOptions({
          label: 'Stato del fascicolo',
        });
        break;
      case 'folders-private':
        // type options
        setFolderTypeInputOptions({ isVisible: false });
        // status options
        setFolderStatusInputOptions({
          label: 'Stato della pratica',
        });
        // assignment options
        setFolderAssignmentsInputOptions({
          choices: FOLDER_ASSIGNMENTS.filter(
            (assignment) => assignment.id === 'advertisement' || assignment.id === 'sale',
          ),
        });
        break;
      case 'folders-utp-npl':
        // type options
        setFolderTypeInputOptions((prevState) => ({
          ...prevState,
          choices: FOLDER_TYPES.filter((type) => type.id === 'utp' || type.id === 'npl'),
        }));
        break;
    }
  }, []);

  /// endregion

  // temporary solution in order to pass people data to the media additional fields component
  const [mutatePeopleData, { loading: peopleLoading, data: peopleData }] = useMutation();
  useEffect(() => {
    if (props.mode === 'create') return;

    mutatePeopleData({
      type: 'getList',
      resource: 'people',
      payload: {},
    });
  }, []);

  return (
    <AppTabbedForm {...props} maxWidth="lg">
      <AppFormTab label="Panoramica" disabledCards={props.mode === 'show'}>
        <AppCard title="Informazioni generali" expanded>
          {folderTypeInputOptions.isVisible ? (
            <AppSelectInput
              source="type"
              choices={folderTypeInputOptions.choices}
              required
              label="Tipo di fascicolo"
              disabled={folderTypeInputOptions.isDisabled}
              md={3}
              defaultValue={folderTypeInputOptions.defaultValue}
            />
          ) : (
            <React.Fragment />
          )}
          <AppSelectInput
            source="status"
            choices={FOLDER_STATUSES}
            required
            label={folderStatusInputOptions.label}
            md={3}
          />
          <AppSelectArrayInput
            source="assignment"
            label="Tipo di incarico"
            required
            choices={folderAssignmentsInputOptions.choices}
            md={5}
          />
        </AppCard>
        <FormDataConsumer>
          {(formProps) => [
            <JudiciaryDetails {...formProps} resource={props.resource} mode={props.mode} />,
            <PrivateDetails {...formProps} resource={props.resource} mode={props.mode} />,
          ]}
        </FormDataConsumer>
        <AppCard hideContent={props.mode === 'create'} title="Codici interni">
          <AppTextInput
            source="internalCode"
            label={
              props.resource === 'folders-private'
                ? 'Numero pratica interno'
                : 'Progressivo interno del gestore'
            }
            disabled
            md={3}
          />
          <AppInputFiller md={9} />
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === 'judiciary' && (
                <AppTextInput
                  source="procedureCode"
                  label="Numero tribunale"
                  disabled
                  md={3}
                  variant="outlined"
                />
              )
            }
          </FormDataConsumer>
          <AppRelatedResource
            relatedResource="/codes"
            manager={
              <AppCodeManager
                title={(mode) => (mode === 'insert' ? 'Inserisci nuovo codice' : 'Modifica codice')}
              />
            }
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <FunctionField
                render={(record) => {
                  if (record.name === 3) {
                    return <AppTextField source="customName" emptyText="Altro" record={record} />;
                  }
                  return <SelectField choices={CODES_TYPES} source="name" record={record} />;
                }}
                label="Nome"
                headerClassName={classes.nameCol}
              />
              <AppTextField source="value" label="Codice" headerClassName={classes.nameCol} />
              <BooleanField
                source="publishOnline"
                label="Pubblicato online"
                headerClassName={classes.lastDatagridColumn}
              />
              <AppRelatedResourceEditButton disabled={props.mode === 'show'} />
              <DeleteWithConfirmButton
                redirect={false}
                confirmTitle="Eliminazione codice"
                confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                disabled={props.mode === 'show'}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab
        label="Persone & Ruoli"
        path="capabilities"
        disabledCards={props.mode === 'show'}
        maxWidth={false}
      >
        <MandatoryOrSuggestedRoles resourceType="folder" />
        <AppCard title="Lista completa dei ruoli" hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/capabilities"
            manager={
              <AppCapabilityManager
                title={(mode, record) =>
                  mode === 'insert'
                    ? 'Collega nuovo ruolo'
                    : `Modifica il ruolo ${record.role?.description ?? ''} collegato a ${
                        record.person?.businessName ?? 'questa anagrafica'
                      }`
                }
                resource="folder"
              />
            }
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid
              rowStyle={(record) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const validityTo = new Date(record.validityTo);
                validityTo.setHours(0, 0, 0, 0);
                return validityTo.getTime() < today.getTime() ? { backgroundColor: amber[50] } : {};
              }}
            >
              <AppTextField
                source="role.description"
                label="Ruolo"
                headerClassName={classes.roleNameCol}
              />
              <FunctionField
                label="Persona o azienda"
                render={(record) =>
                  record.person.type === 'person'
                    ? `${record.person.firstName} ${record.person.lastName}`
                    : `${record.person.businessName}`
                }
                headerClassName={classes.nameCol}
              />
              <AppDateField
                source="validityFrom"
                label="Inizio validità"
                headerClassName={classes.dateCol}
              />
              <AppDateField
                source="validityTo"
                label="Termine validità"
                headerClassName={classes.dateCol}
              />
              <SelectField
                source="savedAs"
                label="Salvato come"
                choices={CAPABILITY_SAVED_AS}
                headerClassName={classes.lastDatagridColumn}
              />
              <FunctionField
                render={(record) => (
                  <AppRelatedResourceEditButton
                    disabled={props.mode === 'show' || record.savedAs}
                    record={record}
                  />
                )}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    redirect={false}
                    confirmTitle="Eliminazione assegnatario"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                    disabled={props.mode === 'show' || record.savedAs}
                    record={record}
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Lotti" path="items" maxWidth={false}>
        <AppCard expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/items"
            actions={[
              <AppGoToResourceButton
                mode="create"
                currentResourceId={props.id}
                destinationResourceName={getReference(props.resource, 'items')}
                currentResourceLabel="Fascicolo"
                buttonLabel="Crea"
                detailParameters={{
                  folderType: props.record.type,
                }}
              />,
            ]}
            actionsHidden={props.mode === 'show'}
            sort={
              props.sort ?? {
                field: 'id',
                order: 'DESC',
              }
            }
          >
            <ItemDatagrid
              rowClick={(id) =>
                `/${getReference(
                  props.resource,
                  'items',
                )}/${id}/show?goBack=true&resourceLabel=Fascicolo&previousResourcePath=${
                  props.location.pathname
                }`
              }
              resource={props.resource}
              editButton={
                <AppGoToResourceButton
                  mode="edit"
                  destinationResourceName={getReference(props.resource, 'items')}
                  currentResourceLabel="Fascicolo"
                  permissions={props.permissions}
                  resourcePermissions={ITEM_PERMISSIONS}
                />
              }
              hideEditButton={props.mode === 'show'}
              showButton={
                <AppGoToResourceButton
                  mode="show"
                  destinationResourceName={getReference(props.resource, 'items')}
                  currentResourceLabel="Fascicolo"
                  permissions={props.permissions}
                  resourcePermissions={ITEM_PERMISSIONS}
                />
              }
              permissions={props.permissions}
            />
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Media" path="media" maxWidth={false}>
        <AppCard expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/media"
            sort={{} /* Managed by backend */}
            manager={<UploadMediaManager mainResource="folder" />}
            actions={[
              <AppButtonGroup title="Documenti" icon={<Description />}>
                <UploadMediaButton mediaType="document" label="Caricamento" />
              </AppButtonGroup>,
              <AppButtonGroup title="Immagini" icon={<PhotoCamera />}>
                <UploadMediaButton mediaType="image" label="Caricamento singolo" />
                <UploadMediaButton
                  mediaType="image"
                  label="Caricamento multiplo"
                  multiple
                  role="Foto"
                />
              </AppButtonGroup>,
            ]}
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <AppDownloadField
                source="name"
                label="Nome file"
                sortable={false}
                headerClassName={classes.mediaGridNameCol}
                cellClassName={classes.mediaGridNameCell}
              />
              <AppTextField
                source="description"
                label="Titolo"
                sortable={false}
                headerClassName={classes.mediaGridDescCol}
              />
              <AppTextField
                source="role.name"
                label="Tipologia"
                sortable={false}
                headerClassName={classes.mediaGridTypeCol}
              />
              <FunctionField render={(record) => <ImagePreview record={record} />} />
              {!peopleLoading && <AppMediaAdditionalFields sortable={false} people={peopleData} />}
              <UploadMediaButton
                label="Modifica"
                mode="edit"
                mainResource="folders"
                disabled={props.mode === 'show'}
              />
              <DeleteWithConfirmButton
                redirect={false}
                confirmTitle="Eliminazione allegato"
                confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                disabled={props.mode === 'show'}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default FolderDetails;
