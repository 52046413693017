import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppShippingMaganer from '../../Components/sale-experiment/shipping/app-shipping-manager';

const useStyles = makeStyles((theme) => ({
  colDate: {
    minWidth: theme.spacing(30),
  },
  colTrackingCode: {
    minWidth: theme.spacing(25),
  },
  colFullWidth: {
    width: '100%',
  },
}));

const SaleExperimentDetailsShipping: FC<any> = (props) => {
  const classes = useStyles();

  return (
    <AppCard
      {...props}
      expanded={true}
      collapsable={false}
      hideContent={props.mode === 'create'}
      header={false}
    >
      <AppRelatedResource
        relatedResource="/shippings"
        manager={<AppShippingMaganer title={() => 'Nuova spedizione'} resource="sale-experiment" />}
        sort={{ field: 'shippingDate', sort: 'ASC' }}
        // filter={filters}
        //actionsDisabled={props.mode !== 'edit'}
        //classes={classes}
      >
        <AppDatagrid>
          <AppDateField
            source="shippingDate"
            label="Data"
            showTime={true}
            showSeconds={true}
            showMilliseconds={false /* SET TO TRUE TO SHOW MILLISECONDS */}
            headerClassName={classes.colDate}
            sortable={false}
          />
          <AppTextField
            type="text"
            source="trackingCode"
            label="Tracking code"
            headerClassName={classes.colTrackingCode}
            sortable={false}
          />
        </AppDatagrid>
      </AppRelatedResource>
    </AppCard>
  );
};

export default SaleExperimentDetailsShipping;
