import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SelectField } from 'react-admin';
import { Form } from 'react-final-form';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { ACTOR_TYPES, LOG_TYPES } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  colDate: {
    minWidth: theme.spacing(30),
  },
  colEventType: {
    minWidth: theme.spacing(30),
  },
  colActor: {
    minWidth: theme.spacing(30),
  },
  colAmount: {
    minWidth: theme.spacing(25),
  },
  colFullWidth: {
    width: '100%',
  },
}));

const SaleExperimentDetailsLogFilters = (props) => {
  const { filters, onChange: onChangeHandler } = props;

  const onSubmitHandler = useCallback((filters) => {
    delete filters.actor;
    if (filters.actorId) {
      filters.actorId = parseInt(filters.actorId);
      filters.actor = 'participant';
    }
    if (filters.eventType === null) delete filters.eventType;
    onChangeHandler(filters);
  }, []);

  return (
    <Form
      initialValues={filters}
      onSubmit={onSubmitHandler}
      render={(formProps) => {
        const { handleSubmit } = formProps;
        return (
          <Grid container spacing={2}>
            <AppSelectInput
              source="eventType"
              onChange={handleSubmit}
              label="Tipo di evento"
              variant="outlined"
              choices={LOG_TYPES}
            />
            <AppTextInput
              source="actorId"
              type="number"
              label="Codice partecipante"
              variant="outlined"
              onChange={handleSubmit}
              onKeyDown={(e) => e.code === 'Enter' && e.preventDefault()}
            />
          </Grid>
        );
      }}
    />
  );
};

const SaleExperimentDetailsLog: FC<any> = (props) => {
  const [filters, setFilters] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setFilters({}); // force filters to
  }, [props.record]);

  return (
    <AppCard
      {...props}
      expanded={true}
      collapsable={false}
      hideContent={props.mode === 'create'}
      //header={<SaleExperimentDetailsLogFilters />}
      header={false}
    >
      <AppRelatedResource
        relatedResource="/logs"
        sort={{ field: 'date', sort: 'ASC' }}
        filter={filters}
        actions={[<SaleExperimentDetailsLogFilters filters={filters} onChange={setFilters} />]}
        //actionsDisabled={props.mode !== 'edit'}
        //classes={classes}
      >
        <AppDatagrid>
          <AppDateField
            source="date"
            label="Data"
            showTime={true}
            showSeconds={true}
            showMilliseconds={false /* SET TO TRUE TO SHOW MILLISECONDS */}
            headerClassName={classes.colDate}
            sortable={false}
          />
          <SelectField
            choices={LOG_TYPES}
            source="eventType"
            label="Tipo di evento"
            headerClassName={classes.colEventType}
            sortable={false}
          />
          <SelectField
            choices={ACTOR_TYPES}
            source="actor"
            label="Svolto da"
            headerClassName={classes.colActor}
            sortable={false}
          />
          <AppTextField
            type="amount"
            source="amount"
            label="Importo"
            headerClassName={classes.colAmount}
            sortable={false}
          />
          <AppTextField
            source="description"
            label="Descrizione evento"
            sortable={false}
            headerClassName={classes.colFullWidth}
            cellClassName={classes.colFullWidth}
          />
        </AppDatagrid>
      </AppRelatedResource>
    </AppCard>
  );
};

export default SaleExperimentDetailsLog;
