import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Labeled } from 'react-admin';
import ReactJson from 'react-json-view';
import get from 'lodash/get';

type DetailsProps = {
  record?: any;
};

const OpLogExpand: FC<DetailsProps> = ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h6">Informazioni aggiuntive</Typography>
      </Grid>
      <Grid item md={12}>
        <Labeled label="Richiesta">
          <ReactJson src={get(record, 'http')} />
        </Labeled>
      </Grid>
      <Grid item md={12}>
        <Labeled label="Risposta">
          <ReactJson src={get(record, 'data')} />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default OpLogExpand;
