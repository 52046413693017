import { Grid } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { FC, PropsWithChildren } from 'react';
import { AppText } from '../text';

interface AppSectionTitleProps {
  subtitle?: string;
  className?: string;
  titleVariant?: Variant;
}

const AppSectionTitle: FC<PropsWithChildren<AppSectionTitleProps>> = ({
  children,
  subtitle,
  className,
  titleVariant = 'button',
}) => {
  return (
    <Grid item md={12} className={className}>
      <AppText children={children} variant={titleVariant} />
      <AppText children={subtitle} variant="subtitle1" />
    </Grid>
  );
};

export default AppSectionTitle;
