import { FunctionComponent } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

type Props = {
  name?: string;
  source: string;
} & Omit<FieldRenderProps<any>, 'input' | 'meta'>;

const AppInputHidden: FunctionComponent<Props> = ({ name, source, ...rest }) => (
  <Field {...rest} component="input" type="hidden" name={name || source} />
);

export default AppInputHidden;
