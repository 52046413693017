import { Box, Chip, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';

interface AppUpdatedByFieldProps {
  record: Record<string, any>;
}

export const AppUpdatedByField: FC<AppUpdatedByFieldProps> = (props) => {
  const { record } = props;

  const [operator, setOperator] = useState<Record<string, any> | null>(null);
  const [mutateOperator] = useMutation();
  useEffect(() => {
    record.updatedBy > 0 &&
      mutateOperator(
        {
          type: 'getOne',
          resource: 'people',
          payload: {
            id: record.updatedBy,
          },
        },
        {
          onSuccess: (res) => setOperator(res.data),
          onFailure: (err) => {
            console.error(err);
            setOperator(null);
          },
        },
      );
  }, [record]);

  return (
    <Typography align="center">
      Operatore ultimo salvataggio
      <Box display="block">
        <Chip
          label={
            operator
              ? `${operator.lastName} ${operator.firstName} (${operator.taxCode ?? '-'})`
              : 'Non disponibile'
          }
          color={operator ? 'primary' : 'secondary'}
        />
      </Box>
    </Typography>
  );
};

export default AppUpdatedByField;
