import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import ItemList from './ItemList';
import ItemDetails from './ItemDetails';
import { resourceWrapper } from '../../Components/ui/utils';
import { Home as ItemIcon } from '@material-ui/icons';

const { Auctioneer } = PersonRoles;

export const ITEM_PERMISSIONS = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const Item: ResourceExport = {
  list: ItemList,
  create: resourceWrapper('create', ItemDetails, undefined, undefined, ITEM_PERMISSIONS),
  edit: resourceWrapper('edit', ItemDetails, undefined, undefined, ITEM_PERMISSIONS),
  show: resourceWrapper('show', ItemDetails, undefined, undefined, ITEM_PERMISSIONS),
  icon: ItemIcon,
};

export default Item;
