import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FC, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';

import { useBasePath } from '../../hooks/use-base-path';
import { randomString } from '../../utils/strings';
import AppCircularLoader from '../ui/loaders/AppCircularLoader';
import AppTooltip from '../ui/tooltip/AppTooltip';
import {
  InfoBidder,
  InfoOffer,
  InfoPresenter,
  InfoProcedure,
  InfoSale,
} from './offer-download/sections';
import styles from './offer-download/styles';
import { PdfDataAttachment, PdfPageNumber, PdfTitle } from './offer-download/utils';

type AdditionalDataProps = {
  saleExperiment: any;
  item: any;
  court: any;
  pvpRegister: any;
  pvpRite: any;
  presenter: any;
  bidders: any[];
  amount: any;
  depositAmount: any;
  offeredAt: string;
  pvpOfferData: any;
  payment: any;
  documents: any;
};

type ParticipationPdfProps = {
  additionalData: AdditionalDataProps;
};

const ParticipationRowDownloadPdf: FC<any> = (props) => {
  const { record: participationRecord, saleExpRecord } = props;

  const notify = useNotify();

  const basePath = useBasePath();

  // get mutation function for choices changes
  const [mutateAdditionalData] = useMutation();

  const [creatingPdf, setCreatingPdf] = useState(false);

  // Font.register({
  //   family: 'Roboto',
  //   src: Roboto,
  // });

  const ParticipationPdf: FC<ParticipationPdfProps> = (props) => {
    const additionalData = props.additionalData;
    return (
      <Document>
        <Page wrap size="A4" style={styles.page}>
          <PdfPageNumber />
          <PdfTitle />
          <InfoProcedure additionalData={additionalData} />
          <InfoSale additionalData={additionalData} />
          <InfoPresenter additionalData={additionalData} />
          <InfoBidder additionalData={additionalData} />
          <InfoOffer additionalData={additionalData} />
          {/*<PdfSectionInfoBollo additionalData={additionalData} />
          <PdfSectionInfoAuthData additionalData={additionalData} />
          <PdfSectionInfoConnectionInvite additionalData={additionalData} />
          <PdfSectionInfoAccettazionePec additionalData={additionalData} />
          <PdfSectionInfoConsegnaPec additionalData={additionalData} /> */}
          {additionalData.documents &&
            additionalData.documents.map((doc) => {
              return <PdfDataAttachment doc={doc} />;
            })}
        </Page>
      </Document>
    );
  };

  const generatePdfDocument = async () => {
    setCreatingPdf(true);

    mutateAdditionalData(
      {
        type: 'getOne',
        resource: `${basePath}/${saleExpRecord.id}/participations-additional-data`,
        payload: {
          id: participationRecord.id,
        },
      },
      {
        onSuccess: (res) => {
          pdf(<ParticipationPdf additionalData={res.data} />)
            .toBlob()
            .then((result) => {
              saveAs(
                result,
                `saleexp-${saleExpRecord.id}-participation-${
                  participationRecord.id
                }-${randomString()}.pdf`,
              );
            })
            .catch((e) => console.warn(e))
            .finally(() => setCreatingPdf(false));
        },
        onFailure: (err) => {
          console.error(err);
          notify('Impossibile scaricare PDF offerta.', 'error');
          setCreatingPdf(false);
        },
      },
    );
  };

  return (
    <>
      <AppTooltip title="Scarica PDF offerta" arrow>
        <Button
          children={<CloudDownloadIcon />}
          variant="contained"
          color="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            generatePdfDocument();
          }}
          disabled={creatingPdf}
          style={{ lineHeight: 'initial' }}
        />
      </AppTooltip>
      <AppCircularLoader open={creatingPdf} text="Generazione PDF in corso..." />
    </>
  );
};

export default ParticipationRowDownloadPdf;

// TODO (?)
/*const PdfSectionInfoBollo: FC<PdfSingleSectionProps> = () => (
    <PdfSection subtitle="INFORMAZIONI BOLLO TELEMATICO">
      <DataLine label="???" value={`${'???'}`} />
    </PdfSection>
  );

  const PdfSectionInfoAuthData: FC<PdfSingleSectionProps> = () => (
    <PdfSection subtitle="INVIO DELLE CREDENZIALI DI ACCESSO TRAMITE PEC">
      <DataLine label="INVIO 1" value={`${'???'}`} />
    </PdfSection>
  );

  const PdfSectionInfoConnectionInvite: FC<PdfSingleSectionProps> = () => (
    <PdfSection subtitle="INVITO DI CONNESSIONE TRAMITE SMS">
      <DataLine label="???" value={`${'???'}`} />
    </PdfSection>
  );

  const PdfSectionInfoAccettazionePec: FC<PdfSingleSectionProps> = () => (
    <PdfSection subtitle="DETTAGLIO ACCETTAZIONE INVIO 1">
      <DataLine label="Mittente" value={`${'???'}`} />
      <DataLine label="Oggetto" value={`${'???'}`} />
      <DataLine label="Data ricezione" value={`${'???'}`} />
      <DataLine label="Data importazione" value={`${'???'}`} />
      <DataLine label="Esito ricevuta" value={`${'???'}`} />
      <DataLine label="Errore ricevuta" value={`${'???'}`} />
    </PdfSection>
  );

  const PdfSectionInfoConsegnaPec: FC<PdfSingleSectionProps> = () => (
    <PdfSection subtitle="DETTAGLIO CONSEGNA INVIO 1">
      <DataLine label="Mittente" value={`${'???'}`} />
      <DataLine label="Oggetto" value={`${'???'}`} />
      <DataLine label="Data ricezione" value={`${'???'}`} />
      <DataLine label="Data importazione" value={`${'???'}`} />
      <DataLine label="Esito ricevuta" value={`${'???'}`} />
      <DataLine label="Errore ricevuta" value={`${'???'}`} />
      <DataLine label="Destinatario" value={`${'???'}`} />
      <DataLine label="Certificato" value={`${'???'}`} />
    </PdfSection>
  );*/
