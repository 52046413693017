import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import { PersonRoles } from '../../utils/constants';
import SaleExperimentDetails from './SaleExperimentDetails';
import SaleExperimentList from './SaleExperimentList';

const { Auctioneer } = PersonRoles;

export const SALE_EXPERIMENT_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [Auctioneer],
  canCreate: [],
  canList: [Auctioneer],
};

const SaleExperiment: ResourceExport = {
  list: SaleExperimentList,
  create: resourceWrapper(
    'create',
    SaleExperimentDetails,
    undefined,
    undefined,
    SALE_EXPERIMENT_PERMISSIONS,
  ),
  edit: resourceWrapper(
    'edit',
    SaleExperimentDetails,
    undefined,
    undefined,
    SALE_EXPERIMENT_PERMISSIONS,
  ),
  show: resourceWrapper(
    'show',
    SaleExperimentDetails,
    undefined,
    undefined,
    SALE_EXPERIMENT_PERMISSIONS,
  ),
};

export default SaleExperiment;
