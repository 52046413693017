import { green, red, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import { FC } from 'react';
import AppAlert from '../../Components/ui/alert/AppAlert';
import { AppText } from '../../Components/ui/text';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'FrontEndUsersProfileModificationListLegend' },
);

export const FrontEndUsersProfileModificationListLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: grey[100] }} />
        In attesa di accettata o rifiutata
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: green[400] }} />
        Accettata
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: red[400] }} />
        Rifiutata
      </AppText>
    </AppAlert>
  );
};
