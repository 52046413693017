export enum AuctionStatus {
  inactive = 'inactive', // unable to add participants & modify offers
  authorization = 'authorization', // offers processing
  ready = 'ready', // all offers processed
  active = 'active', // auction is started
  completed = 'completed', // all frozen
  settled = 'settled', // auction canceled for some reason
} // TODO: use everywhere

// NOTE: Could become a generic outcome enum (not only for adv)
export enum AdvertisementOutcomeType {
  Sold = 'sold',
  PartiallySold = 'partially-sold',
  Deserted = 'deserted',
  Postponed = 'postponed',
  Settled = 'settled',
}
