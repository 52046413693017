import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionField, ListContextProvider } from 'react-admin';
import { getPublicationUrl } from '../../../utils/publications';
import AppAlert from '../../ui/alert/AppAlert';
import AppDatagrid from '../../ui/datagrid/AppDatagrid';
import AppTextField from '../../ui/field/AppTextField';
import AppUrlField from '../../ui/field/AppUrlField';
import { AppBold, AppText } from '../../ui/text';

const useStyles = makeStyles(
  (theme) => ({
    nameCol: {
      minWidth: theme.spacing(40),
    },
    urlCol: {
      minWidth: theme.spacing(30),
    },
    checkedCol: {
      width: '100%',
    },
    cellSuccess: {
      color: theme.palette.success.main,
    },
    cellError: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'RequestPublicationsList' },
);

interface RequestPublicationsListProps {
  listCtx: Record<string, any>;
  setListData?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const RequestPublicationsList: FC<RequestPublicationsListProps> = ({ listCtx, setListData }) => {
  const classes = useStyles();
  return listCtx.loading ? (
    <AppText>Caricamento pubblicazioni...</AppText>
  ) : listCtx.total > 0 ? (
    <ListContextProvider value={listCtx}>
      <AppDatagrid>
        <AppTextField source="property.name" label="Sito" headerClassName={classes.nameCol} />
        <FunctionField
          label="Pubblicazione"
          headerClassName={classes.urlCol}
          render={(publication) =>
            publication?.isPublished ? (
              <AppUrlField
                url={getPublicationUrl(publication as any)}
                text="Vai al dettaglio"
                onClick={() => {
                  if (!setListData) return;

                  setListData((prevListData) => ({
                    ...prevListData,
                    [publication.id]: { ...prevListData[publication.id], checked: true },
                  }));
                }}
              />
            ) : (
              <AppText children="Non attiva" className={classes.cellError} />
            )
          }
        />
        {setListData && (
          <FunctionField
            render={(record) => (
              <AppText className={classes[record?.checked ? 'cellSuccess' : 'cellError']}>
                <AppBold>
                  {(record?.checked
                    ? 'Pubblicazione controllata'
                    : 'Pubblicazione non ancora controllata'
                  ).toUpperCase()}
                </AppBold>
              </AppText>
            )}
            headerClassName={classes.checkedCol}
            label="Stato"
          />
        )}
      </AppDatagrid>
    </ListContextProvider>
  ) : (
    <AppAlert children="Lotto non pubblicato." severity="warning" />
  );
};

export default RequestPublicationsList;
