import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ChipField, FunctionField, ReferenceField } from 'react-admin';
import { useHistory } from 'react-router';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppTextField from '../../Components/ui/field/AppTextField';
import { getReference, getResourceType } from '../../utils/reference-selector';
import { FOLDER_PERMISSIONS } from './index';

const useFolderListStyles = makeStyles(
  (theme) => {
    return {
      internalCode: {
        minWidth: theme.spacing(30),
      },
      title: {
        minWidth: theme.spacing(30),
      },
      rite: {
        minWidth: theme.spacing(30),
      },

      // judiciary
      procedureCode: {
        minWidth: theme.spacing(20),
      },
      register: {
        minWidth: theme.spacing(30),
      },
      court: {
        minWidth: theme.spacing(30),
      },
      role: {
        minWidth: theme.spacing(20),
      },

      // private
      competence: {
        minWidth: theme.spacing(25),
      },

      lastCol: {
        width: '100%',
        minWidth: theme.spacing(20),
      },
    };
  },
  { name: 'FolderDatagrid' },
);

const FolderDatagrid: FC<any> = (props) => {
  const { rowClick, permissions, currentResourceLabel, ...rest } = props;
  const classes = useFolderListStyles();
  const history = useHistory();

  /// region LIST OPTIONS

  const [labels, setLabels] = useState<Record<string, any>>({
    internalCode: 'Progressivo',
    title: 'Titolo',
    fkPvpRite: 'Rito',
  });
  useEffect(() => {
    switch (getResourceType(props.resource)) {
      case 'judiciary':
        setLabels({
          internalCode: 'Numero procedura interno',
          title: 'Titolo della procedura',
          fkPvpRite: 'Tipo di rito',
        });
        break;
      case 'private':
        setLabels({
          internalCode: 'Numero pratica interno',
          title: 'Titolo della pratica',
          fkPvpRite: 'Tipo di vendita',
        });
        break;
      default:
        break;
    }
  }, []);

  /// endregion

  return (
    <AppDatagrid {...rest} rowClick={rowClick}>
      <AppTextField
        source="internalCode"
        label={labels.internalCode}
        headerClassName={classes.internalCode}
      />
      <AppTextField source="title" label={labels.title} headerClassName={classes.title} />
      <ReferenceField
        basePath="folders"
        reference="pvp/rites"
        source="fkPvpRite"
        label={labels.fkPvpRite}
        emptyText="-"
        link={false}
        headerClassName={classes.rite}
      >
        <AppTextField source="description" />
      </ReferenceField>

      {getResourceType(props.resource) === 'private' ? (
        [
          // PRIVATE FIELDS
          <AppTextField
            source="owner"
            label="Proprietario"
            headerClassName={classes.role}
            sortable={false}
          />,
          <ReferenceField
            basePath="folders"
            reference="competences"
            source="fkCompetence"
            label="Sede di competenza di Casa delle Aste"
            emptyText="-"
            link={false}
            headerClassName={classes.competence}
          >
            <AppTextField source="name" />
          </ReferenceField>,
          <AppTextField
            source="officerInCharge"
            label="Agente incaricato"
            sortable={false}
            headerClassName={classes.role}
          />,
        ]
      ) : (
        <React.Fragment />
      )}

      {getResourceType(props.resource) === 'judiciary' ? (
        [
          // JUDICIARY FIELDS
          <AppTextField
            source="procedureCode"
            label="Numero procedura"
            headerClassName={classes.procedureCode}
          />,
          <ReferenceField
            basePath="folders"
            reference="pvp/registers"
            source="fkPvpRegister"
            label="Tipo di procedura"
            emptyText="-"
            link={false}
            headerClassName={classes.register}
          >
            <AppTextField source="description" />
          </ReferenceField>,
          <ReferenceField
            basePath="folders"
            reference="courts"
            source="fkCourt"
            label="Tribunale"
            headerClassName={classes.court}
            emptyText="-"
            link={false}
          >
            <AppTextField source="name" />
          </ReferenceField>,
          <AppTextField
            source="sentenced"
            label="Esecutato/debitore"
            headerClassName={classes.role}
            sortable={false}
          />,
          <AppTextField
            source="proceedingCreditor"
            label="Procedente"
            sortable={false}
            headerClassName={classes.role}
          />,
        ]
      ) : (
        <React.Fragment />
      )}

      <FunctionField
        label="Lotti associati"
        render={(r) => {
          return (
            <ChipField
              color="primary"
              source="itemsCount"
              record={r}
              label="Lotti associati"
              onClick={(e) => {
                e.stopPropagation(); // because of rowClick="show" attribute of the list
                if (currentResourceLabel) {
                  // for any other component (e.g.: PersonDetails.tsx)
                  return history.push(
                    `/folders/${r.id}/items?goBack=true&resourceLabel=${currentResourceLabel}&previousResourcePath=${history.location.pathname}`,
                  );
                }
                // for FoldersList.tsx
                history.push(
                  `${getReference(props.resource ?? 'folders', 'folders')}/${r.id}/items`,
                );
              }}
            />
          );
        }}
        headerClassName={classes.lastCol}
      />
      {props.showButton ?? (
        <AppShowButton
          icon={<span />}
          permissions={permissions}
          resourcePermissions={FOLDER_PERMISSIONS}
        />
      )}
      {!props.hideEditButton ? (
        props.editButton ?? (
          <AppEditButton
            icon={<span />}
            permissions={permissions}
            resourcePermissions={FOLDER_PERMISSIONS}
          />
        )
      ) : (
        <span />
      )}
    </AppDatagrid>
  );
};

export default FolderDatagrid;
