import { makeStyles } from '@material-ui/core/styles';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import React, { FC } from 'react';
import AppAlert from '../../Components/ui/alert/AppAlert';
import { AppText } from '../../Components/ui/text';
import { ECWID_EVENT_COLORS } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'EcwidEventsListLegend' },
);

const EcwidEventsListLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS.pending[0] }} />
        Evento ecwid ancora non processato
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS['pre-processing'][0] }} />
        Evento ecwid in fase di pre-esecuzione
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS['pre-processed'][0] }} />
        Evento ecwid pre-processato
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS.processing[0] }} />
        Evento ecwid in fase di esecuzione
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS.processed[0] }} />
        Evento ecwid processato
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: ECWID_EVENT_COLORS.error[0] }} />
        Errore in fase di gestione dell'evento ecwid
      </AppText>
    </AppAlert>
  );
};

export default EcwidEventsListLegend;
