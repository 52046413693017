import { makeStyles } from '@material-ui/core/styles';
import LegendIcon from '@material-ui/icons/FiberManualRecord';
import React, { FC } from 'react';
import AppAlert from '../../Components/ui/alert/AppAlert';
import { AppText } from '../../Components/ui/text';
import { PUBLICATION_COLORS } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    legendElement: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'ExternalPropertiesUpdateListLegend' },
);

const ExternalPropertiesUpdateListLegend: FC = () => {
  const classes = useStyles();
  return (
    <AppAlert title="Legenda" severity="info" style={{ backgroundColor: 'white' }}>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: PUBLICATION_COLORS.pending[0] }} />
        Non inviata
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: PUBLICATION_COLORS.processing[0] }} />
        In fase di invio
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: PUBLICATION_COLORS.success[0] }} />
        Successo
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: PUBLICATION_COLORS.error[0] }} />
        Errore
      </AppText>
      <AppText className={classes.legendElement}>
        <LegendIcon style={{ color: PUBLICATION_COLORS.skip[0] }} />
        Skippata
      </AppText>
    </AppAlert>
  );
};

export default ExternalPropertiesUpdateListLegend;
