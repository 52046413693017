import { Record as RaRecord } from 'ra-core';
import { FC, ReactElement } from 'react';
import { ButtonProps, ShowButton } from 'react-admin';
import { useUserIsAllowed } from '../../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../../Providers/AppPermissionsProvider';

interface AppShowButtonProps extends ButtonProps {
  basePath?: string;
  record?: RaRecord;
  icon?: ReactElement;
}

const AppShowButton: FC<AppShowButtonProps> = (props) => {
  const { variant = 'outlined', icon = <span />, disabled, ...rest } = props;

  const allowShow = useUserIsAllowed(ResourceAction.Get, props.resource);

  return <ShowButton {...rest} variant={variant} icon={icon} disabled={disabled || !allowShow} />;
};

export default AppShowButton;
