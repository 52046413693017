import { assign as _assign } from 'lodash';
import { createElement, FC, useContext, useEffect, useState } from 'react';
import { useMutation, useNotify, useQuery } from 'react-admin';
import { AppRelatedResourceContext } from '../../related-resource/app-related-resource';
import { AppAutocompleteInput, AppDateInput, AppNumberInput, AppTextInput } from '../../ui/input';
import UploadMediaForm from './upload-media-form';
import AuthManager from '../../../Providers/AuthManager';
import { User } from '../../../types/user.type';

const UploadMediaManager: FC<any> = (props) => {
  const notify = useNotify();
  const ctx = useContext(AppRelatedResourceContext);
  const { showAdditionalFields = true, /*showMagazineRole = true,*/ mainResource = '' } = props;
  const { resource, getRelatedResources, item, manager /*, mainResourceRecord*/ } = ctx;

  const [additionalFields, setAdditionalFields] = useState<any>(undefined);
  const [mutateAdditionalFields] = useMutation();

  const mediaType = manager.additionalParams.mediaType;
  const isMultipleUpload = manager.additionalParams.multiple;

  const userJson = AuthManager.getUserInfo();
  const user: User = userJson ? JSON.parse(userJson) : {};
  const isAgency = user.agency ? true : false;

  // let isJudiciary = false;
  // if (mainResource === 'item') {
  //   isJudiciary = mainResourceRecord.relatedFolder?.type === 'judiciary';
  // } else if (mainResource === 'folder') {
  //   isJudiciary = mainResourceRecord.type === 'judiciary';
  // } else {
  //   //
  // }

  const [mediaRolesChoices, setMediaRolesChoices] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (manager.mode === 'edit' && item.fkMediaRole) {
      getAdditionalFields(item.fkMediaRole);
    }
  }, [item]);

  // retrieve media roles using media type
  useQuery(
    {
      type: 'getList',
      resource: 'media-roles',
      payload: {
        filter: {
          type: mediaType,
        },
      },
    },
    {
      onSuccess: (res) => {
        if (mediaType === 'image' && (mainResource === 'item' || mainResource === 'folder')) {
          // remove unnecessary roles TODO: maybe a field on the entity that can be used to filter based on resource
          if (isAgency) {
            return setMediaRolesChoices(res.data.filter((mediaRole) => mediaRole.name === 'Foto'));
          }
          return setMediaRolesChoices(
            res.data.filter(
              (mediaRole) =>
                mediaRole.name !== 'Scansione bonifico - Immagine' &&
                mediaRole.name !== 'Documento partecipante - Immagine',
            ),
          );
        } else if (mainResource === 'participation-forms-set') {
          return setMediaRolesChoices(
            res.data.filter((mediaRole) => mediaRole.name === 'Modulo per offerta'),
          );
        } else if (mainResource === 'decree') {
          return setMediaRolesChoices(
            res.data.filter((mediaRole) => mediaRole.name === 'Decreto o provvedimento'),
          );
        }
        if (isAgency) {
          return setMediaRolesChoices(
            res.data.filter((mediaRole) => mediaRole.name === 'Documento generico'),
          );
        }
        setMediaRolesChoices(res.data);
      },
      onFailure: (err) => {
        console.error(err);
        notify('Non è stato possibile caricare i ruoli.', 'error');
        setMediaRolesChoices([]);
      },
    },
  );

  // retrieve media role additional fields using its id
  const getAdditionalFields = (mediaRoleId) =>
    mutateAdditionalFields(
      {
        type: 'getList',
        resource: `media-roles/${mediaRoleId}/mediaRoleAdditionalFields`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setAdditionalFields(res.data);
        },
        onFailure: (err) => {
          process.env.NODE_ENV !== 'production' && console.error(err);
          setAdditionalFields(null);
        },
      },
    );

  // set form settings using media type
  const [accept, setAccept] = useState('');
  const [singleRole, setSingleRole] = useState<Record<string, any> | undefined>(); // Used for bulk uploads
  const [defaultRole, setDefaultRole] = useState<Record<string, any> | undefined>(); // Used to precompile role autocomplete
  useEffect(() => {
    setSingleRole(undefined);
    setDefaultRole(undefined);
    setAdditionalFields([]);
    let defaultRole;
    switch (mediaType) {
      case 'document':
        setAccept('application/pdf');
        if (mainResource === 'participation-forms-set') {
          const defaultRole = mediaRolesChoices.find((role) => role.name === 'Modulo per offerta');
          if (defaultRole) {
            setDefaultRole(defaultRole);
            getAdditionalFields(defaultRole.id);
          }
        } else if (mainResource === 'decree') {
          const defaultRole = mediaRolesChoices.find(
            (role) => role.name === 'Decreto o provvedimento',
          );
          if (defaultRole) {
            setDefaultRole(defaultRole);
            getAdditionalFields(defaultRole.id);
          }
        }
        break;
      case 'image':
        setAccept('image/jpeg,image/png');
        if (isMultipleUpload) {
          return setSingleRole(
            mediaRolesChoices.find((role) => role.name === manager.additionalParams.role),
          );
        }

        if (mainResource === 'item' || mainResource === 'folder') {
          const defaultRole = mediaRolesChoices.find((role) => role.name === 'Foto');
          if (defaultRole) {
            setDefaultRole(defaultRole);
            getAdditionalFields(defaultRole.id);
          }
        }
        break;
      case 'virtual-tour':
        setAccept('image/jpeg,image/png');
        if (isMultipleUpload) {
          return setSingleRole(mediaRolesChoices.find((role) => role.name === 'Virtual Tour'));
        }

        defaultRole = mediaRolesChoices.find((role) => role.name === 'Virtual Tour');
        if (defaultRole) {
          setDefaultRole(defaultRole);
          getAdditionalFields(defaultRole.id);
        }
        break;
      case 'video':
        setAccept('video/mp4');
        defaultRole = mediaRolesChoices.find((role) => role.name === 'Video');
        if (defaultRole) {
          setDefaultRole(defaultRole);
          getAdditionalFields(defaultRole.id);
        }
        break;
      default:
        break;
    }
  }, [isMultipleUpload, mediaRolesChoices]);

  return (
    <UploadMediaForm
      accept={accept}
      resource={resource}
      onUpload={getRelatedResources}
      record={item}
      isOpen={manager.isOpen}
      close={manager.close}
      mode={manager.mode}
      updateMedia={manager.submit}
      multiple={isMultipleUpload}
      singleRole={singleRole ? singleRole.id : undefined}
    >
      <AppTextInput
        source={manager.mode === 'insert' ? '$raw.name' : 'name'}
        label="Nome"
        md={12}
        disabled
      />
      <AppTextInput
        source="description"
        label="Titolo"
        md={12}
        hidden={isMultipleUpload}
        required={false}
      />
      <AppAutocompleteInput
        label="Ruolo"
        optionText="name"
        choices={mediaRolesChoices}
        source={manager.mode === 'insert' ? '$role' : 'fkMediaRole'}
        required
        defaultValue={defaultRole ? defaultRole.id : null}
        md={mediaType === 'image' ? 6 : 12}
        onChange={showAdditionalFields && getAdditionalFields}
        hidden={isMultipleUpload}
      />
      {/*mediaType === 'image' && showMagazineRole && (
        <AppSelectInput
          hidden={isMultipleUpload}
          label="Ruolo bollettino"
          source="magazineRole"
          choices={[
            { id: null, name: '\xa0' }, // unicode of &nbsp; ;)
            { id: 1, name: 'Foto 1' },
            { id: 2, name: 'Foto 2' },
          ]}
        />
      )*/}
      {/* Media additional fields (related to the media role)*/}
      {showAdditionalFields &&
        !isMultipleUpload &&
        mediaRolesChoices.length &&
        additionalFields &&
        additionalFields.map((additionalField) => {
          let InputComponent;
          const additionalProps: Record<string, any> = {};
          switch (additionalField.type) {
            case 'text':
              InputComponent = AppTextInput;
              break;
            case 'number':
              InputComponent = AppNumberInput;
              break;
            case 'amount':
              InputComponent = AppNumberInput;
              _assign(additionalProps, {
                isAmount: true,
              });
              break;
            case 'date':
              InputComponent = AppDateInput;
              break;
            case 'registry':
              InputComponent = AppAutocompleteInput;
              _assign(additionalProps, {
                reference: 'people',
                optionText: (person) => {
                  if (!person) return '';
                  `${person.lastName} ${person.firstName}`;
                },
                suggestionLimit: 5,
                label: `${additionalField.label} ${additionalField.mandatory ? '*' : ''}`, //TODO: mandatory autocomplete with reference doesn't show the "*"
              });
              break;
            default:
              InputComponent = AppTextInput;
          }
          return createElement(InputComponent as any, {
            source:
              manager.mode === 'insert'
                ? `additionalFields.${additionalField.fieldName}`
                : `metadata.additionalFields.${additionalField.fieldName}`,
            label: additionalField.label,
            key: additionalField.id,
            md: 11,
            required: additionalField.mandatory,
            ...additionalProps,
          });
        })}
    </UploadMediaForm>
  );
};

export default UploadMediaManager;
