import { createContext } from 'react';
import { User } from '../types/user.type';

export type DashboardContextData = {
  isIvg: boolean;
  adminName: string;
  user: User | undefined;
};

// Used to avoid props drilling -> permissions are used only by a child of a child
const DashboardContext = createContext<DashboardContextData>({
  isIvg: false,
  adminName: 'Casa delle Aste',
  user: undefined,
});

export default DashboardContext;
