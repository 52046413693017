import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PropTypes,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import React, {
  Children,
  cloneElement,
  FC,
  Fragment,
  isValidElement,
  ReactElement,
  useRef,
  useState,
} from 'react';

const useStyles = makeStyles(
  (theme) => ({
    buttonGroup: {
      marginRight: theme.spacing(2),
    },
    popper: {
      zIndex: theme.zIndex.drawer + 99999,
      marginTop: theme.spacing(1),
    },
    paper: {
      backgroundColor: theme.palette.secondary.light,
    },
    buttonItem: {
      margin: '0 auto',
    },
  }),
  { name: 'AppButtonGroup' },
);

interface AppButtonGroupProps {
  children?: React.ReactNode;
  title?: string;
  icon?: ReactElement;
  color?: PropTypes.Color;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  className?: string;
  classes?: Record<string, any>;
  size?: 'small' | 'medium' | 'large';
}

const AppButtonGroup: FC<AppButtonGroupProps> = (props) => {
  const {
    children,
    className,
    disabled = false,
    variant = 'contained',
    color = 'primary',
    title = 'Azioni',
    icon = <Settings />,
    classes = {},
    size = 'medium',
  } = props;
  const appButtonGroupStyles = useStyles();

  /// region STATES/REFS

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  /// endregion

  /// region HANDLERS

  const handleMenuItemClick = () => setOpen(false);
  const handleToggle = () => setOpen((prevState) => !prevState);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  /// endregion

  return (
    <Fragment>
      <ButtonGroup
        className={className ?? appButtonGroupStyles.buttonGroup}
        classes={classes}
        variant={variant}
        color={color}
        ref={anchorRef}
        disabled={disabled}
        size={size}
      >
        {/* Open-menu button */}
        <Button
          color={color}
          onClick={handleToggle}
          children={title}
          startIcon={icon}
          variant={variant}
        />
      </ButtonGroup>

      {/* Buttons menu */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        className={appButtonGroupStyles.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={appButtonGroupStyles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {/* Iterate through buttons passed as children */}
                  {Children.map(
                    children,
                    (child, index) =>
                      isValidElement(child) && (
                        <MenuItem key={index} onClick={handleMenuItemClick}>
                          {cloneElement(child, {
                            className: appButtonGroupStyles.buttonItem,
                            color: 'secondary',
                          })}
                        </MenuItem>
                      ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default AppButtonGroup;
