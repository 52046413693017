import { FunctionComponent } from 'react';
import { ListProps } from 'react-admin';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput, AppTextInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import ShippingDatagrid from './ShippingDatagrid';

const ShippingListFilters = (
  <AppListFilter disableFulltextSearch>
    <AppTextInput source="shipping_trackingCode" label="Codice tracking" md={2} />
    <AppAutocompleteInput
      label="Richiedente"
      source="shipping_id"
      resettable
      reference="shippings/get-participations"
      optionText={(record) => {
        if (!record || !record.presenter) return '';
        const { presenter } = record;
        return `${presenter.businessName || `${presenter.lastName} ${presenter.firstName}`} (${
          presenter.taxCode || presenter.vat || '-'
        })`;
      }}
      md={3}
    />
  </AppListFilter>
);

const ShippingList: FunctionComponent<ListProps> = (props) => {
  return (
    <AppList {...props} title={<AppTitle title="Spedizioni" />} filters={ShippingListFilters}>
      <ShippingDatagrid rowClick="show" />
    </AppList>
  );
};

export default ShippingList;
