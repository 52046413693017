import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Record } from 'ra-core';
import { Children, cloneElement, createElement, FC, Fragment, ReactElement } from 'react';
import { useCheckMinimumRequiredProps } from 'react-admin';
import { AppToolbarActionsProps } from './AppToolbarActions';

const useStyles = makeStyles(
  () => ({
    actionDisabled: {
      color: 'rgba(0, 0, 0, 0.26)',
      cursor: 'default',
      pointerEvents: 'none',
    },
  }),
  { name: 'AppToolbarAction' },
);

const AppToolbarAction: FC<AppToolbarActionProps> = (props) => {
  useCheckMinimumRequiredProps('AppToolbarAction', ['onClick', 'text'], props);

  const classes = useStyles();

  const { icon, text, key, selected, onClick, handleClose, children, disabled, ...rest } = props;

  function onClickHandler(event) {
    if (disabled) return;

    if (onClick) onClick(event);
    if (handleClose) handleClose(event);
  }

  return (
    <MenuItem
      key={key}
      selected={selected}
      onClick={onClickHandler}
      className={disabled ? classes.actionDisabled : ''}
    >
      {children ? (
        Children.map(children as any, (child) => cloneElement(child, rest))
      ) : (
        <Fragment>
          {icon && <ListItemIcon>{createElement(icon, { fontSize: 'small' })}</ListItemIcon>}
          <ListItemText primary={text} />
        </Fragment>
      )}
    </MenuItem>
  );
};

export default AppToolbarAction;

export interface AppToolbarActionProps extends Omit<AppToolbarActionsProps, 'children' | 'data'> {
  onClick: (event: any) => void;
  text: string;
  record?: Record;
  redirect?: string;
  children?: ReactElement;
  handleClose?: (event: any) => void; // passed from AppToolbarActions to manage menu close!
  icon?: SvgIconComponent;
  selected?: boolean;
  key?: number;
  disabled?: boolean;
}
