import { FC, useContext } from 'react';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import AppPageSeoForm from './app-page-seo-form';

const AppPageSeoManager: FC<any> = (props) => {
  const { title, resource, ...others } = props;

  /// region CONTEXT

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;

  /// endregion

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => ctx.manager.submit(data, close)}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={130}
      height={110}
      {...others}
    >
      <AppPageSeoForm open={ctx.manager.isOpen} mode={ctx.manager.mode} resource={resource} />
    </AppFormDialog>
  );
};

export default AppPageSeoManager;
