import { InputAdornment } from '@material-ui/core';
import React, { FC } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import { useFormState } from 'react-final-form';
import { CONDITION_TYPE_CHOICES } from '../../Resources/FeeProfile/constants';
import { AppNumberInput, AppSelectInput } from '../ui/input';
import AppSectionTitle from '../ui/layout/AppSectionTitle';

type FeeConditionsInputProps = {
  source: string;
  applyFeeSource: string;
  label: string;
  disabled?: boolean;
};

export const FeeConditionsInput: FC<FeeConditionsInputProps> = React.memo<FeeConditionsInputProps>(
  ({ applyFeeSource, label, ...rest }) => {
    const { values } = useFormState();

    if (values[applyFeeSource] !== undefined && !values[applyFeeSource]) return <></>;

    return (
      <>
        <AppSectionTitle>{label}</AppSectionTitle>
        <ArrayInput {...rest} label={false}>
          <SimpleFormIterator reOrderButtons={<></>}>
            <AppSelectInput
              source="type"
              label="Tipo"
              choices={CONDITION_TYPE_CHOICES}
              required
              md={4}
            />
            <AppNumberInput source="offerAmount" label="Offerta" isAmount required md={4} />
            <AppNumberInput
              source="feePercentage"
              label="Percentuale da applicare"
              required
              md={4}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  },
);
