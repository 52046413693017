import { Description } from '@material-ui/icons';
import React, { FC, ReactElement } from 'react';
import { DeleteWithConfirmButton, FunctionField, SelectField } from 'react-admin';
import UploadMediaButton from '../../Components/media/upload/upload-media-button';
import UploadMediaManager from '../../Components/media/upload/upload-media-manager';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppButtonGroup from '../../Components/ui/button/AppButtonGroup';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDownloadField from '../../Components/ui/field/AppDownloadField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import { AppAutocompleteInput, AppTextInput } from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { hasViewPermissions } from '../../Providers/AppPermissionsProvider';
import { MEDIA_GENRES } from '../../utils/constants';
import { PARTICIPATION_FORMS_SET_PERMISSIONS } from './index';

const ParticipationFormsSetDetails: FC<any> = (props): ReactElement => {
  return (
    <AppTabbedForm
      {...props}
      disabledCards={
        !hasViewPermissions(
          props.permissions.loginRole,
          'create',
          PARTICIPATION_FORMS_SET_PERMISSIONS,
        )
      }
    >
      <AppFormTab label="Parametri" disabledCards={props.mode === 'show'}>
        <AppCard collapsable={false}>
          <AppTextInput
            source="title"
            reference="title"
            label="Titolo"
            variant="outlined"
            md={12}
            required
          />
          <AppTextInput
            source="description"
            reference="description"
            label="Descrizione"
            md={12}
            required
            multiline
            expandable
          />
          <AppDivider />
          <AppAutocompleteInput
            source="genres"
            reference="genres"
            label="Generi"
            variant="outlined"
            optionText="description"
            md={6}
            isArray
          />
          <AppAutocompleteInput
            source="pvpRites"
            reference="pvp/rites"
            label="Riti"
            variant="outlined"
            optionText="description"
            md={6}
            isArray
          />
          <AppAutocompleteInput
            source="saleTypes"
            reference="sale-types"
            label="Tipi di vendita"
            variant="outlined"
            optionText="description"
            md={6}
            isArray
          />
          <AppAutocompleteInput
            source="saleModes"
            reference="sale-modes"
            label="Modalità di vendita"
            variant="outlined"
            optionText="description"
            md={6}
            isArray
          />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Media" path="media" disabledCards={props.mode === 'show'} maxWidth={false}>
        <AppCard expanded hideContent={props.mode === 'create'} title="Gestione media">
          <AppRelatedResource
            relatedResource="/media"
            sort={{} /* Managed by Backend */}
            manager={<UploadMediaManager mainResource="participation-forms-set" />}
            actions={[
              <AppButtonGroup title="Documenti" icon={<Description />}>
                <UploadMediaButton mediaType="document" label="Caricamento" />
              </AppButtonGroup>,
            ]}
            actionsHidden={props.mode === 'show'}
          >
            <AppDatagrid>
              <AppDownloadField source="name" label="Nome file" sort sortable={false} />
              <AppTextField source="description" label="Titolo" sortable={false} />
              <SelectField
                choices={MEDIA_GENRES}
                label="Genere"
                source="role.type"
                sortable={false}
              />
              <AppTextField source="role.name" label="Tipologia" sortable={false} />
              <UploadMediaButton
                label="modifica"
                mode="edit"
                mainResource="items"
                disabled={props.mode === 'show'}
              />
              <FunctionField
                render={(record) => (
                  <DeleteWithConfirmButton
                    record={record}
                    disabled={props.mode === 'show'}
                    redirect={false}
                    confirmTitle="Eliminazione allegato"
                    confirmContent="Sei sicuro di voler procedere con la cancellazione?"
                  />
                )}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default ParticipationFormsSetDetails;
