import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  assign as _assign,
  concat as _concat,
  keyBy as _keyBy,
  map as _map,
  update as _update,
} from 'lodash';
import moment from 'moment';
import 'moment/locale/it';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Title, useMutation, useNotify } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';
import AppDivider from '../../Components/ui/layout/AppDivider';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { useUserIsAgency } from '../../hooks';
import { SaleMode } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';
import { getReference, getResourceType } from '../../utils/reference-selector';
import {
  ASYNC_SALE_EXP_END_COLOR,
  ASYNC_SALE_EXP_START_COLOR,
  EXP_OF_INT_SALE_EXP_END_COLOR,
  EXP_OF_INT_SALE_EXP_START_COLOR,
  OFF_COLL_SALE_EXP_END_COLOR,
  OFF_COLL_SALE_EXP_START_COLOR,
  SYNC_SALE_EXP_COLOR,
} from './constants';
import SaleExperimentCalendarTable from './sale-experiment-calendar-table';
import SaleExperimentCalendarToolbar from './sale-experiment-calendar-toolbar';
import { CalendarEvent } from './types';

/// region STYLES

const useCalendarStyles = makeStyles(
  (theme) => ({
    container: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
      height: '100vh',
      padding: theme.spacing(2),
      '& .rbc-header': {
        lineHeight: '2rem',
      },
      '& .rbc-date-cell': {
        padding: 0,
      },
      '& .rbc-event': {
        borderRadius: '10px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        textAlign: 'center',
        margin: '0px 10px 2px',
        width: 'auto',
      },
      '& .rbc-today': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.3,
      },
      '& .rbc-show-more': {
        textAlign: 'center',
      },
    },
    linksToOtherCalendarsContainer: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    linkButton: {
      maxWidth: theme.spacing(60),
    },
    dialog: {
      width: theme.spacing(500),
      height: theme.spacing(50),
      marginLeft: '16rem',
      maxWidth: 'none',
    },
    dialogTitle: {
      fontWeight: 'bold',
      textAlign: 'center',
    },
    dateHeader: {
      textAlign: 'center',
      lineHeight: '1.5rem',
    },
  }),
  { name: 'SaleExperimentCalendar' },
);

/// endregion

// dialog's transition animation
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/// region CALENDAR SETTINGS / UTILS

// calendar locale
moment.locale('it');

/// endregion

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const SaleExperimentCalendar: FC<any> = (props) => {
  const classes = useCalendarStyles();
  const notify = useNotify();

  const isAgency = useUserIsAgency(props.permissions);

  // list base context (used in selected event's dialog)
  const baseCtx = {
    resource: 'sale-experiments',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
  };

  /// region STATES

  const [open, setOpen] = useState(false); // selected event dialog open/close boolean
  const [dialogTitle, setDialogTitle] = useState('');

  const [events, setEvents] = useState<CalendarEvent[]>([]); // calendar events setter
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>(); // set the clicked event info

  const [calendarTitle, setCalendarTitle] = useState('Calendario vendite');

  const [listCtx, setListCtx] = useState(baseCtx); // updated every time an event is selected

  /// endregion

  /// region FIRST MOUNT

  useEffect(() => {
    switch (getResourceType(props.resource)) {
      case 'judiciary':
        return setCalendarTitle('Calendario vendite giudiziarie');
      case 'private':
        return setCalendarTitle('Calendario vendite private');
      case 'utp-npl':
        return setCalendarTitle('Calendario vendite UTP e NPL');
    }
  }, []);

  /// endregion

  /// region FILTERS

  const [calendarFilters, setCalendarFilters] = useState({
    month: null,
    sales: null,
    court: null,
    saleMode: null,
    city: null,
  });

  useEffect(() => {
    updateSaleExperiments();
  }, [calendarFilters]);

  /// endregion

  /// region MUTATE SALE EXPERIMENTS

  const [mutateSaleExperiments] = useMutation();
  const updateSaleExperiments = () =>
    mutateSaleExperiments(
      {
        type: 'getList',
        resource: `${getReference(props.resource, 'sale-experiments')}/calendar`,
        payload: {
          filter: {
            date: calendarFilters.month,
            sales: calendarFilters.sales,
            courtId: calendarFilters.court,
            saleModeId: calendarFilters.saleMode,
            cityId: calendarFilters.city,
          },
        },
      },
      {
        onSuccess: (res) => {
          getCalendarEventsArray(res.data);
        },
        onFailure: (err) => {
          console.error(err.message);
          notify('Non è stato possibile recuperare le informazioni del calendario', 'error');
        },
      },
    );

  /// endregion

  /// region EVENT DIALOG INFO

  const openDialog = (event) => {
    const { details: dailySaleExperiments } = event;
    // set datagrid title
    setDialogTitle(`
        ${
          event.saleMode === SaleMode.ExpressionOfInterest
            ? 'Manifestazioni di interesse'
            : event.saleMode === SaleMode.OffersCollection
            ? 'Raccolte offerte'
            : event.saleMode === SaleMode.AsyncOnline
            ? 'Vendite asincrone'
            : 'Vendite sincrone'
        } che ${event.isEndDate ? 'terminano' : 'iniziano'} il ${dateFormatter(
      event.start,
      true,
    )}`);

    setListCtx(
      _assign({}, baseCtx, {
        data: _keyBy(dailySaleExperiments, 'id'),
        ids: _map(dailySaleExperiments, 'id'),
        total: dailySaleExperiments.length,
        loaded: true,
        loading: false,
      }) as any,
    );
    setOpen(true);
    setSelectedEvent(dailySaleExperiments);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  /// endregion

  /// region CALENDAR EVENTS

  // called by the mutator when sale experiments data is gathered
  const getCalendarEventsArray = (data) => {
    const saleExperimentsEvents: CalendarEvent[] = [];
    // iterates over the sale experiments and creates three groups based on saleMode
    // 1: Async sale experiments start, 2: Async sale experiments end, 3: Sync sale experiments, 4: Exp. of int. sale experiments start, 5: Exp. of int. sale experiments end, 6: Off. coll. sale experiments start, 7: Off. coll. sale experiments end
    data.forEach((saleExp) => {
      if (saleExp.fkSaleMode === SaleMode.ExpressionOfInterest) {
        // insert/update EXP. OF INT. sale exp
        const existingExpOfIntStart = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.ExpressionOfInterest &&
            !event.isEndDate &&
            event.start.substring(0, 10) === saleExp.expressionOfInterestStartAt.substring(0, 10),
        );
        if (existingExpOfIntStart === -1) {
          //push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.expressionOfInterestStartAt,
            end: saleExp.expressionOfInterestStartAt,
            details: [{ ...saleExp }],
            backgroundColor: EXP_OF_INT_SALE_EXP_START_COLOR,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event
          _update(saleExperimentsEvents[existingExpOfIntStart], 'title', (counter) => counter + 1);
          _update(saleExperimentsEvents[existingExpOfIntStart], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }

        if (!saleExp.expressionOfInterestEndAt) return;

        const existingExpOfIntEnd = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.ExpressionOfInterest &&
            event.isEndDate &&
            event.end.substring(0, 10) === saleExp.expressionOfInterestEndAt.substring(0, 10),
        );

        if (existingExpOfIntEnd === -1) {
          // push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.expressionOfInterestEndAt,
            end: saleExp.expressionOfInterestEndAt,
            details: [{ ...saleExp }],
            backgroundColor: EXP_OF_INT_SALE_EXP_END_COLOR,
            isEndDate: true,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event
          _update(saleExperimentsEvents[existingExpOfIntEnd], 'title', (counter) => counter + 1);
          _update(saleExperimentsEvents[existingExpOfIntEnd], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }
      } else if (saleExp.fkSaleMode === SaleMode.OffersCollection) {
        // insert/update OFF.COLL sale exp
        const existingOffersCollectionStart = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.OffersCollection &&
            !event.isEndDate &&
            event.start.substring(0, 10) === saleExp.offersCollectionStartAt.substring(0, 10),
        );
        if (existingOffersCollectionStart === -1) {
          //push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.offersCollectionStartAt,
            end: saleExp.offersCollectionStartAt,
            details: [{ ...saleExp }],
            backgroundColor: OFF_COLL_SALE_EXP_START_COLOR,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event
          _update(
            saleExperimentsEvents[existingOffersCollectionStart],
            'title',
            (counter) => counter + 1,
          );
          _update(saleExperimentsEvents[existingOffersCollectionStart], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }

        if (!saleExp.offersCollectionEndAt) return;

        const existingOffersCollectionEnd = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.OffersCollection &&
            event.isEndDate &&
            event.end.substring(0, 10) === saleExp.offersCollectionEndAt.substring(0, 10),
        );

        if (existingOffersCollectionEnd === -1) {
          // push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.offersCollectionEndAt,
            end: saleExp.offersCollectionEndAt,
            details: [{ ...saleExp }],
            backgroundColor: OFF_COLL_SALE_EXP_END_COLOR,
            isEndDate: true,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event
          _update(
            saleExperimentsEvents[existingOffersCollectionEnd],
            'title',
            (counter) => counter + 1,
          );
          _update(saleExperimentsEvents[existingOffersCollectionEnd], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }
      } else if (saleExp.fkSaleMode === SaleMode.AsyncOnline) {
        // insert/update ASYNC sale exp

        /// region SALE START
        const existingAsyncSaleExpStart = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.AsyncOnline &&
            !event.isEndDate &&
            event.start.substring(0, 10) === saleExp.auctionStartAt.substring(0, 10),
        );

        if (existingAsyncSaleExpStart === -1) {
          // push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.auctionStartAt,
            end: saleExp.auctionStartAt,
            details: [{ ...saleExp }],
            backgroundColor: ASYNC_SALE_EXP_START_COLOR,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event (auction start)
          _update(
            saleExperimentsEvents[existingAsyncSaleExpStart],
            'title',
            (counter) => counter + 1,
          );
          _update(saleExperimentsEvents[existingAsyncSaleExpStart], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }

        /// endregion

        /// region SALE END

        if (!saleExp.auctionEndAt) return;

        const existingAsyncSaleExpEnd = saleExperimentsEvents.findIndex(
          (event) =>
            event.saleMode === SaleMode.AsyncOnline &&
            event.isEndDate &&
            event.end.substring(0, 10) === saleExp.auctionEndAt.substring(0, 10),
        );

        if (existingAsyncSaleExpEnd === -1) {
          // push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.auctionEndAt,
            end: saleExp.auctionEndAt,
            details: [{ ...saleExp }],
            backgroundColor: ASYNC_SALE_EXP_END_COLOR,
            isEndDate: true,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event (auction end)
          _update(
            saleExperimentsEvents[existingAsyncSaleExpEnd],
            'title',
            (counter) => counter + 1,
          );
          _update(saleExperimentsEvents[existingAsyncSaleExpEnd], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }

        /// endregion
      } else {
        // insert/update SYNC sale exp
        const existingSyncSaleExp = saleExperimentsEvents.findIndex(
          (event) =>
            [SaleMode.SyncMixed, SaleMode.SyncOnline].includes(event.saleMode) &&
            event.start.substring(0, 10) === saleExp.auctionStartAt.substring(0, 10),
        );
        if (existingSyncSaleExp === -1) {
          //push new event
          saleExperimentsEvents.push({
            title: 1,
            start: saleExp.auctionStartAt,
            end: saleExp.auctionStartAt,
            details: [{ ...saleExp }],
            backgroundColor: SYNC_SALE_EXP_COLOR,
            saleMode: saleExp.fkSaleMode,
          });
        } else {
          // update event
          _update(saleExperimentsEvents[existingSyncSaleExp], 'title', (counter) => counter + 1);
          _update(saleExperimentsEvents[existingSyncSaleExp], 'details', (details) =>
            _concat(details, { ...saleExp }),
          );
        }
      }
    });

    setEvents(saleExperimentsEvents);
  };

  /// endregion

  return (
    <Fragment>
      {!IS_IVG && !isAgency && (
        <Card className={classes.linksToOtherCalendarsContainer}>
          <Grid container spacing={2} justify="center">
            {props.resource === 'sale-experiments-calendar' ? (
              [
                <Grid item md={4} className={classes.linkButton}>
                  <Button
                    children="Vai al calendario delle vendite giudiziarie"
                    component={Link}
                    to="/sale-experiments-judiciary-calendar"
                    variant="outlined"
                  />
                </Grid>,
                <Grid item md={4} className={classes.linkButton}>
                  <Button
                    children="Vai al calendario delle vendite private"
                    component={Link}
                    to="/sale-experiments-private-calendar"
                    variant="outlined"
                  />
                </Grid>,
                <Grid item md={4} className={classes.linkButton}>
                  <Button
                    children="Vai al calendario delle vendite UTP e NPL"
                    component={Link}
                    to="/sale-experiments-utp-npl-calendar"
                    variant="outlined"
                  />
                </Grid>,
              ]
            ) : (
              <Grid item md={12} className={classes.linkButton}>
                <Button
                  children="Vai al calendario generale"
                  component={Link}
                  to="/sale-experiments-calendar"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        </Card>
      )}
      <Card className={classes.container}>
        <Title title={<AppTitle title={calendarTitle} />} />
        <Calendar
          localizer={momentLocalizer(moment)}
          events={events}
          startAccessor="start"
          formats={{
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
          }}
          endAccessor="end"
          onSelectEvent={openDialog}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
            },
          })}
          views={['month']}
          popup
          messages={{
            showMore: () => (
              <div
                style={{ cursor: 'pointer' }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {'Espandi'}
              </div>
            ),
          }}
          components={{
            toolbar: (toolbarProps) =>
              SaleExperimentCalendarToolbar({
                ...toolbarProps,
                calendarFilters,
                setCalendarFilters,
                currentResource: props.resource,
                isAgency,
              }),
            month: {
              dateHeader: ({ label }) => <div className={classes.dateHeader}>{label}</div>,
            },
          }}
          onNavigate={(date) => {
            setCalendarFilters((prevFilters) => ({
              ...prevFilters,
              month: date,
            }));
          }}
        />
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={closeDialog}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle className={classes.dialogTitle} children={dialogTitle} />
          <AppDivider flex={false} />
          <DialogContent>
            <SaleExperimentCalendarTable
              resource={props.resource}
              listCtx={listCtx}
              selectedEvent={selectedEvent}
            />
          </DialogContent>
          <AppDivider flex={false} />
          <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="contained">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Fragment>
  );
};

export default SaleExperimentCalendar;
