import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import OpLogExpand from './OpLogExpand';

const useStyles = makeStyles(
  (theme) => ({
    id: {
      minWidth: theme.spacing(20),
    },
    user: {
      minWidth: theme.spacing(30),
    },
    date: {
      minWidth: theme.spacing(30),
    },
    entity: {
      minWidth: theme.spacing(30),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'OpLogList' },
);

const OpLogDatagrid: FC<any> = (props) => {
  const classes = useStyles();

  return (
    <AppDatagrid expand={<OpLogExpand />} resource="op-log">
      <AppTextField source="id" label="Id" headerClassName={classes.id} />
      {props.showUser && (
        <AppTextField source="user" label="Id utente" headerClassName={classes.user} />
      )}
      <AppDateField source="ts" label="Data" showTime headerClassName={classes.date} />
      <AppTextField source="entity" label="Entità" headerClassName={classes.entity} />
      <AppTextField source="method" label="Metodo" headerClassName={classes.lastCol} />
    </AppDatagrid>
  );
};

export default OpLogDatagrid;
