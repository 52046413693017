import { Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { FunctionField, ImageField, Labeled, TextField, useMutation } from 'react-admin';
import { dateFormatter } from '../../utils/data-formatters';

type DetailsProps = {
  record?: any;
};
const ItemExpand: FC<DetailsProps> = ({ record }) => {
  // get media
  const [mediaMutation, { loading: mediaLoading, data: favouriteMedia }] = useMutation();
  useEffect(() => {
    mediaMutation({
      type: 'getOne',
      resource: `items/${record.id}/media/favourite-image`,
      payload: {},
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h6">Informazioni aggiuntive</Typography>
      </Grid>
      <Grid item md={12}>
        <Labeled label="Titolo">
          <TextField source="title" record={record} />
        </Labeled>
      </Grid>
      {record.address && record.locationCity && record.locationProvince ? (
        <Grid item md={12}>
          <Labeled label="Indirizzo">
            <FunctionField
              record={record}
              render={() =>
                `${record.address}, ${record.locationCity} (${record.locationProvince})`
              }
            />
          </Labeled>
        </Grid>
      ) : (
        <React.Fragment />
      )}
      {!mediaLoading && favouriteMedia ? (
        <Grid item md={12}>
          <Labeled label="Immagine principale">
            <ImageField record={favouriteMedia} source="metadata.remoteUrl" />
          </Labeled>
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item md={12}>
        <Labeled label="Data e ora di inizio del prossimo esperimento di vendita">
          <FunctionField
            record={record}
            render={(record) => {
              const currentSaleExp = record.relatedSaleExperiments.find(
                (saleExp) => saleExp.isCurrent,
              );
              return currentSaleExp && currentSaleExp.auctionStartAt
                ? `${dateFormatter(currentSaleExp.auctionStartAt)}`
                : 'Non disponibile';
            }}
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default ItemExpand;
