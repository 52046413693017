import { List, ListItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  complete,
  getNotification,
  hideNotification,
  undo,
  undoableEventEmitter,
  useTranslate,
} from 'ra-core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  type?: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    error: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      maxHeight: '20rem',
      overflow: 'auto',
      marginLeft: '14rem',
      marginBottom: '3rem',
    },
    undo: {
      color: theme.palette.primary.light,
    },
    errorsListItem: {
      padding: 0,
    },
  }),
  { name: 'AppNotification' },
);

const AppNotification: FC<Props & Omit<SnackbarProps, 'open'>> = (props) => {
  const {
    //classes: classesOverride,
    type,
    className,
    autoHideDuration = 4000,
    ...rest
  } = props;
  delete rest.classes;

  const [open, setOpen] = useState(false);
  const notification = useSelector(getNotification); //access the redux state and get the notification
  const dispatch = useDispatch();

  const translate = useTranslate();
  const styles = useStyles(props);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    if (notification && notification.undoable) {
      dispatch(complete());
      undoableEventEmitter.emit('end', { isUndo: false });
    }
    dispatch(hideNotification());
  }, [dispatch, notification]);

  const handleUndo = useCallback(() => {
    dispatch(undo());
    undoableEventEmitter.emit('end', { isUndo: true });
  }, [dispatch]);

  //Format error notification
  const formatErrorNotification = () => {
    if (notification.message === 'ra.message.invalid_form')
      return translate(notification.message, notification.messageArgs); //form error msg

    if (!Array.isArray(notification.message)) return notification.message; //another error msg

    // array of error messages
    return (
      <div>
        <List>
          <ListItem className={styles.errorsListItem}>
            {notification.messageArgs ? notification.messageArgs.title : 'ERRORI:'}
          </ListItem>
          {notification.message.map((err) => (
            <ListItem className={styles.errorsListItem}>{err},</ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <Snackbar
      open={open}
      message={
        notification &&
        notification.message &&
        (notification.type === 'warning' || notification.type === 'error'
          ? formatErrorNotification()
          : translate(notification.message, notification.messageArgs))
      }
      autoHideDuration={(notification && notification.autoHideDuration) ?? autoHideDuration}
      disableWindowBlurListener={notification && notification.undoable}
      onExited={handleExited}
      onClose={handleRequestClose}
      ContentProps={{
        className: classnames(styles[(notification && notification.type) || type], className),
      }}
      action={
        notification && notification.undoable ? (
          <Button color="primary" className={styles.undo} size="small" onClick={handleUndo}>
            {translate('ra.action.undo')}
          </Button>
        ) : null
      }
      {...rest}
    />
  );
};

AppNotification.propTypes = {
  type: PropTypes.string,
};

AppNotification.defaultProps = {
  type: 'info',
  autoHideDuration: undefined,
};

export default AppNotification;
