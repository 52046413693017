import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { DetailPanelField } from '../../../Components/detail-panel';
import AppCard from '../../../Components/ui/card/AppCard';
import { dateFormatter } from '../../../utils/data-formatters';

export const OwnerDetails: FC<any> = ({ record }) => {
  return (
    <AppCard title="Anagrafica" expanded>
      <Grid container>
        <DetailPanelField
          label="Nominativo"
          value={`${record.participation.presenter.lastName} ${record.participation.presenter.firstName}`}
          md={4}
        />
        <DetailPanelField
          label="Codice fiscale"
          value={record.participation.presenter.taxCode}
          md={4}
        />
        <DetailPanelField
          label="Data di nascita"
          value={dateFormatter(record.participation.presenter.birthday, true)}
          md={4}
        />
      </Grid>
    </AppCard>
  );
};
