import React, { FC } from 'react';
import { TopToolbar, useListContext } from 'react-admin';

const AppListTopToolbar: FC<any> = (props) => {
  const { filters } = props; //separate filters from props

  const { resource, displayedFilters, filterValues, showFilter } = useListContext(); //get filters information

  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

export default AppListTopToolbar;
