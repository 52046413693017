import EuroIcon from '@material-ui/icons/Euro';
import { resourceWrapper } from '../../Components/ui/utils';
import { ResourcePermissions } from '../../Providers/AppPermissionsProvider';
import { ResourceExport } from '../../types/resource-export.type';
import FeeProfileDetails from './FeeProfileDetails';
import FeeProfileList from './FeeProfileList';

export const FEE_PROFILE_PERMISSIONS: ResourcePermissions = {
  canEdit: [],
  canShow: [],
  canCreate: [],
  canList: [],
};

const FeeProfile: ResourceExport = {
  list: FeeProfileList,
  create: resourceWrapper(
    'create',
    FeeProfileDetails,
    undefined,
    undefined,
    FEE_PROFILE_PERMISSIONS,
  ),
  edit: resourceWrapper('edit', FeeProfileDetails, undefined, undefined, FEE_PROFILE_PERMISSIONS),
  show: resourceWrapper('show', FeeProfileDetails, undefined, undefined, FEE_PROFILE_PERMISSIONS),
  icon: EuroIcon,
};

export default FeeProfile;
