import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import 'moment/locale/it';
import React, { FC, Fragment } from 'react';
import AppGoToResourceButton from '../../Components/ui/button/AppGoToResourceButton';
import { AUCTION_ROOM_BOOKING_COLORS, AUCTION_ROOM_BOOKING_STATUSES } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';

const AuctionRoomBookingCalendarShowEventModal: FC<any> = (props) => {
  return (
    <Fragment>
      <Dialog
        open={props.isOpen}
        onClose={props.dialogCloseHandler}
        onClick={props.dialogClickHandler}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Richiesta di prenotazione sala d'asta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Riepilogo dei dati della richiesta di prenotazione:
            <p>
              Sala d'asta: <b>{props.event?.auctionRoom?.title}</b>
              <br />
              Da: <b>{dateFormatter(props.event?.start)}</b>
              <br />
              A: <b>{dateFormatter(props.event?.end)}</b>
              <br />
              Stato prenotazione:{' '}
              <b style={{ color: AUCTION_ROOM_BOOKING_COLORS[props.event?.details?.status][0] }}>
                {
                  AUCTION_ROOM_BOOKING_STATUSES.find(
                    (status) => status.id === props.event?.details?.status,
                  )?.name
                }
              </b>
            </p>
          </DialogContentText>
          {!props.isAuctioneer && (
            <Grid item md={12}>
              <AppGoToResourceButton
                destinationResourceName="auction-rooms-bookings"
                mode="show"
                destinationResourceId={props.event?.details?.id}
                buttonLabel="Visualizza richiesta"
                currentResourceLabel="Calendario"
                variant="outlined"
              />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AuctionRoomBookingCalendarShowEventModal;
