export const checkAndGetDate = (date: number | string | Date): Date =>
  typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;

export const getYearsList = (yearStart?: number, yearEnd?: number): number[] => {
  const yearsList: number[] = [];
  if (!yearStart && !yearEnd) return yearsList;

  for (let start = yearStart ?? 1970; start <= (yearEnd ?? 2050); start++) {
    yearsList.push(start);
  }

  return yearsList;
};

export const getMonday = (date: Date | undefined | null): Date => {
  const _date = date ? new Date(Number(date)) : new Date();
  const day = _date.getDay();
  const diff = _date.getDate() - day + (day == 0 ? -6 : 1);
  _date.setDate(diff);
  return _date;
};

export const addDays = (date: Date, days: number): Date => {
  const _date = new Date(date);
  _date.setDate(_date.getDate() + days);
  return _date;
};
