import { Grid, makeStyles } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy, map as _map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { ImageField, Labeled, useMutation } from 'react-admin';
import AppTextField from '../../ui/field/AppTextField';
import RequestPublicationsList from '../utilities/request-publications-list';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(2),
    },
    nameCol: {
      minWidth: theme.spacing(40),
    },
    urlCol: {
      width: '100%',
    },
  }),
  { name: 'RequestLinkListExpand' },
);

const RequestLinkListExpand: FC<any> = ({ record }) => {
  const { relatedItem } = record;
  const classes = useStyles();

  const publicationsListBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    loading: true,
    total: 0,
  };
  const [publicationsListCtx, setPublicationsListCtx] =
    useState<Record<string, any>>(publicationsListBaseCtx);

  // get media
  const [mediaMutation, { loading: mediaLoading, data: favouriteMedia }] = useMutation();
  useEffect(() => {
    mediaMutation({
      type: 'getOne',
      resource: `items/${relatedItem.id}/media/favourite-image`,
      payload: {},
    });
  }, []);

  // get publications
  const [pubMutations] = useMutation();
  useEffect(() => {
    pubMutations(
      {
        type: 'getList',
        resource: `items/${relatedItem.id}/publications`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setPublicationsListCtx(
            _assign({}, publicationsListCtx, {
              data: _keyBy(res.data, 'id'),
              ids: _map(res.data, 'id'),
              total: res.total,
              loaded: true,
              loading: false,
            }),
          );
        },
      },
    );
  }, []);

  return (
    <Grid container spacing={2} className={classes.container}>
      {/* FAV IMG */}
      <Grid container item md={2} spacing={2}>
        {!mediaLoading && favouriteMedia && (
          <Grid item>
            <Labeled label="Anteprima">
              <ImageField record={favouriteMedia} source="metadata.remoteUrl" />
            </Labeled>
          </Grid>
        )}
      </Grid>

      {/* OTHERS */}
      <Grid container item md={4} spacing={2}>
        <Grid item>
          <Labeled label="Indirizzo">
            <AppTextField record={relatedItem} source="address" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label="Città">
            <AppTextField
              customText={
                relatedItem.locationCity
                  ? `${relatedItem.locationCity} (${relatedItem.zipCode ?? '-'}), ${
                      relatedItem.locationProvince
                    }, ${relatedItem.locationRegion}, ${relatedItem.locationCountry}`
                  : 'Non disponibile'
              }
            />
          </Labeled>
        </Grid>
      </Grid>

      {/* PUBLICATIONS */}
      <Grid container item md={6}>
        <Grid item md={12}>
          <RequestPublicationsList listCtx={publicationsListCtx} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestLinkListExpand;
