import { makeStyles } from '@material-ui/core/styles';
import { EditProps } from 'ra-ui-materialui/src/types';
import React, { createElement, ReactElement } from 'react';
import {
  EditContextProvider,
  EditView,
  useCheckMinimumRequiredProps,
  useEditController,
} from 'react-admin';
import { ResourcePermissions } from '../../../Providers/AppPermissionsProvider';
import AppToolbarDelete from '../form/AppToolbarDelete';
import AppToolbarActions from '../layout/AppToolbarActions';

const useStyles = makeStyles(
  {
    card: {
      overflow: 'hidden',
    },
  },
  {
    name: 'AppEdit',
  },
);

export const AppEdit = (
  props: EditProps & { children: ReactElement } & { resourcePermissions?: ResourcePermissions },
): ReactElement => {
  useCheckMinimumRequiredProps('AppEdit', ['children'], props);
  const controllerProps = useEditController(props);
  const appEditClasses = useStyles();
  const { actions, permissions, resource, ...rest } = props;

  /*
  const notify = useNotify();
  const redirect = useRedirect();
  // check if user can access edit view
  useEffect(() => {
    if(resourcePermissions && !hasViewPermissions(permissions.loginRole, 'edit', resourcePermissions)) {
      redirect('list', props.basePath);
      notify('Accesso alla modifica non consentito.', 'error', {}, false, 5000);
    }
  }, []);
  
   */

  return (
    <EditContextProvider value={controllerProps}>
      <EditView
        {...rest}
        {...controllerProps}
        permissions={permissions}
        resource={resource}
        component='div'
        classes={appEditClasses}
        actions={
          actions ?? createElement(AppToolbarActions, rest, React.createElement(AppToolbarDelete))
        }
      />
    </EditContextProvider>
  );
};
