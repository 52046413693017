import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useState } from 'react';
import { useListContext } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    buttons: {
      minWidth: theme.spacing(20),
    },
    container: {
      marginTop: theme.spacing(1.5),
      '& button': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
  { name: 'AppListFilterButtons' },
);

type AppListFilterButtonProps = {
  filtersList: Array<string>;
  initialFilters?: Record<string, any>;
};

const AppListFilterButton: FunctionComponent<AppListFilterButtonProps> = (props) => {
  const { showFilter, hideFilter, displayedFilters, setFilters, filterValues } = useListContext();
  const { filtersList, initialFilters } = props;
  const classes = useStyles();

  const resetFilters = () => {
    setFilters(initialFilters ? { ...initialFilters } : {}, displayedFilters);
  };

  const [openedFilters, setOpenedFilters] = useState(Object.keys(displayedFilters).length > 1);

  const handleFiltersShow = () => {
    if (!openedFilters) {
      filtersList.forEach((filter) => showFilter(filter, undefined)); //expand all filters passed to the button
      setOpenedFilters(true);
    } else {
      filtersList.forEach((filter) => hideFilter(filter));
      setFilters(filterValues.q ? { q: filterValues.q } : {}, { q: true });
      setOpenedFilters(false);
    }
  };

  return (
    <div className={classes.container}>
      <Button
        variant='contained'
        color='primary'
        onClick={resetFilters}
        children='Reset filtri'
        className={classes.buttons}
      />
      {filtersList.length > 0 && (
        <Button
          onClick={handleFiltersShow}
          children={openedFilters ? 'Rimuovi filtri' : 'Aggiungi filtri'}
          color='primary'
          variant='contained'
          className={classes.buttons}
        />
      )}
    </div>
  );
};

export default AppListFilterButton;
