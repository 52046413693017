import { ThemeOptions } from '@material-ui/core';
import { createMuiTheme, createStyles, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import compose from 'lodash/flowRight';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { ComponentPropType, CoreLayoutProps } from 'ra-core';
import React, {
  Component,
  ComponentType,
  createElement,
  ErrorInfo,
  HtmlHTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { defaultTheme, Error as DefaultError, MenuProps } from 'react-admin';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultMenu from '../menu/menu';
import DefaultNotification from './AppNotification';
import DefaultSidebar from './AppSidebar';
import DefaultAppBar from './AppToolbar';

const styles = (theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.getContrastText(theme.palette.background.default),
      display: 'flex',
      flexDirection: 'row',
      zIndex: 1,
      position: 'absolute',
      bottom: 0,
      top: 0,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      overflow: 'hidden',
    },
    main: {
      flex: '1 1 100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      maxHeight: '100vh',
      overflow: 'hidden',
    },
    container: {
      display: 'flex',
      maxHeight: 'calc(100vh - 5rem)',
      flex: '1 1 100%',
      flexDirection: 'column',
      //padding: theme.spacing(3),
      //paddingTop: theme.spacing(1),
    },
  });

class AppLayoutWithoutTheme extends Component<AppLayoutWithoutThemeProps, AppLayoutState> {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      ...props
    } = this.props as Partial<AppLayoutWithoutThemeProps>;
    const { hasError, errorMessage, errorInfo } = this.state;

    return (
      <>
        <div
          className={classnames('layout', classes.root, className)}
          {...omit(props, ['match', 'location', 'history', 'staticContext'])}
        >
          {createElement(sidebar as any, {
            logout,
            children: createElement(menu as any, {
              hasDashboard: !!dashboard,
            }),
          })}
          <main className={classes.main}>
            {createElement(appBar as any, { title, open, logout })}
            <div className={classes.container}>
              {hasError
                ? createElement(error as any, {
                    error: errorMessage,
                    errorInfo,
                    title,
                  })
                : children}
            </div>
          </main>
        </div>
        {createElement(notification as any)}
      </>
    );
  }

  static propTypes = {
    appBar: ComponentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    dashboard: ComponentPropType,
    error: ComponentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    menu: ComponentPropType,
    notification: ComponentPropType,
    open: PropTypes.bool,
    sidebar: ComponentPropType,
    title: PropTypes.node.isRequired,
  };

  static defaultProps = {
    appBar: DefaultAppBar,
    error: DefaultError,
    menu: DefaultMenu,
    notification: DefaultNotification,
    sidebar: DefaultSidebar,
  };
}

export interface AppLayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  appBar?: ComponentType<{
    title?: string | ReactElement<any>;
    open?: boolean;
    logout?: ReactNode;
  }>;
  classes?: any;
  className?: string;
  error?: ComponentType<{
    error?: string;
    errorInfo?: React.ErrorInfo;
    title?: string | ReactElement<any>;
  }>;
  menu?: ComponentType<MenuProps>;
  notification?: ComponentType;
  sidebar?: ComponentType<{ children: JSX.Element }>;
  theme?: ThemeOptions;
}

export interface AppLayoutState {
  hasError: boolean;
  errorMessage: string | null;
  errorInfo: ErrorInfo | null;
}

interface AppLayoutWithoutThemeProps extends RouteComponentProps, Omit<AppLayoutProps, 'theme'> {
  open?: boolean;
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'AppLayout' }),
)(AppLayoutWithoutTheme);

const AppLayout = ({ theme: themeOverride, ...props }: AppLayoutProps): JSX.Element => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

AppLayout.propTypes = {
  theme: PropTypes.object,
};

AppLayout.defaultProps = {
  theme: defaultTheme,
};

export default AppLayout;
