import {
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import { ConfigurationSection } from '../../../Components/cms/configuration/configuration-section';
import AppCard from '../../../Components/ui/card/AppCard';
import AppBaseForm from '../../../Components/ui/form/AppBaseForm';
import {
  AppAutocompleteInput,
  AppColorInput,
  AppSelectInput,
  AppTextInput,
} from '../../../Components/ui/input';
import { AppFileInput } from '../../../Components/ui/input/AppFileInput';
import { AppImageInput } from '../../../Components/ui/input/AppImageInput';
import AppDivider from '../../../Components/ui/layout/AppDivider';
import AppSectionTitle from '../../../Components/ui/layout/AppSectionTitle';
import { ITEM_GENRES } from '../../../utils/constants';
import { blobToBase64 } from '../../../utils/files';
import { LINK_TYPES_CHOICES } from '../../Cms/Configuration/constants';
import PageSelection from '../Pages/PageSelection';
// import { useForm } from 'react-final-form';

const customFieldTypes = {
  text: 'Testo',
  number: 'Numero',
  date: 'Data',
  boolean: 'Booleano',
};

const ConfigurationDetails: FC<any> = (props) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [personSellers, setPersonSellers] = useState([]);
  const [categoryChoices, setCategoryChoices] = useState<any[]>([]);
  const [typologyChoices, setTypologyChoices] = useState<any[]>([]);

  // const { mutators: formMutators } = useForm();

  const [mutatePersonSellers] = useMutation();
  const [mutateCategories] = useMutation();
  const [mutateTypologies] = useMutation();

  useEffect(() => {
    // console.log('props.record', props.record);
    mutatePersonSellers(
      {
        type: 'getList',
        resource: 'person-seller',
        payload: {
          filter: {},
          sort: { field: 'fkPerson', order: 'ASC' },
        },
      },
      {
        onSuccess: (res) => {
          setPersonSellers(res.data);
        },
        onFailure: () => setPersonSellers([]),
      },
    );
    if (props.record.genre) getCategories(props.record.genre);
    if (props.record.category) getTypologies(props.record.category);
  }, []);

  const getCategories = (genreId) =>
    mutateCategories(
      {
        type: 'getList',
        resource: 'categories',
        payload: {
          filter: {
            fkGenre: genreId === 'real-estate' ? 1 : 2,
          },
        },
      },
      {
        onSuccess: (res) => {
          setCategoryChoices(
            // res.data,
            res.data.map((category) => ({
              id: category.type,
              name: category.description,
            })),
          );

          setTypologyChoices([]);
        },
        onFailure: (err) => {
          console.error(err);
          setCategoryChoices([]);
          setTypologyChoices([]);
        },
      },
    );

  const getTypologies = (categoryName) => {
    fetch(`${apiUrl}/categories/by-name/${categoryName}`)
      .then((response) => {
        response
          .json()
          .then((data) => {
            mutateTypologies(
              {
                type: 'getList',
                resource: 'typologies',
                payload: {
                  filter: {
                    fkCategory: data.id,
                  },
                },
              },
              {
                onSuccess: (res) => {
                  setTypologyChoices(res.data);
                },
                onFailure: (err) => {
                  console.error(err);
                  setTypologyChoices([]);
                },
              },
            );
          })
          .catch((error) => {
            console.error('Error fetching ', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching ', error);
      });
  };

  const handleFilesAndSave = async (record: any, redirect: any, options: any) => {
    if (record.CustomPDFFile) {
      await blobToBase64(record.CustomPDFFile.CustomPDFFile).then((result) => {
        record.CustomPDFFile = {
          base64: result,
          filename: record.CustomPDFFile.filename,
          type: record.CustomPDFFile.rawFile.type,
        };
      });
    }

    // Send to API server
    // console.log('record', record);
    props.save(record, redirect, options);
  };

  // useEffect(() => {
  //   console.log('categoryChoices', categoryChoices);
  // }, [categoryChoices]);

  // useEffect(() => {
  //   console.log('typologyChoices', typologyChoices);
  // }, [typologyChoices]);

  const [linkType, setLinkType] = useState<string>(props.record?.searchPayoff?.linkType ?? '');

  return (
    <AppBaseForm
      {...props}
      save={(record, redirect, options) => handleFilesAndSave(record, redirect, options)}
      disabledCards={props.mode === 'show'}
    >
      <ConfigurationSection title="Dati agenzia" collapsable={false}>
        <AppAutocompleteInput
          source="fkPersonSeller"
          choices={personSellers}
          label={'Proprietario'}
          optionText={(choice) => `${choice?.person.firstName} ${choice?.person.lastName}`}
          disabled={props.mode !== 'create'}
          required
          md={4}
        />
        <AppTextInput source="agencyName" label="Nome agenzia" md={4} required />
        <AppTextInput
          source="thirdLevel"
          label="Terzo livello"
          md={4}
          required
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="genre"
          label="Genere"
          choices={ITEM_GENRES}
          md={4}
          onChange={getCategories}
          disabled={props.mode !== 'create'}
        />
        <AppSelectInput
          source="category"
          label="Categoria"
          md={4}
          onChange={(e) => getTypologies(e.target.value)}
          choices={categoryChoices}
          disabled={props.mode === 'show'}
        />
        <AppAutocompleteInput
          source="typologies"
          label="Tipologie"
          md={4}
          optionText="description"
          optionValue="type"
          choices={typologyChoices}
          required
          isArray
          disabled={props.mode === 'show'}
        />
        <AppDivider />
        {props.record.customFields?.length > 0 && (
          <>
            <AppCard title="Campi personalizzati" expanded hideContent={props.mode === 'create'}>
              <TableContainer component={Container}>
                <Table size="small" aria-label="Elenco campi personalizzati">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Etichetta</TableCell>
                      <TableCell>Tipologia</TableCell>
                      <TableCell>Tipo di campo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.record.customFields?.map((customField: any) => (
                      <TableRow>
                        <TableCell>{customField.name}</TableCell>
                        <TableCell>{customField.label}</TableCell>
                        <TableCell>
                          {
                            typologyChoices.find((t) => t.type === customField.typology)
                              ?.description
                          }
                        </TableCell>
                        <TableCell>{customFieldTypes[customField.type]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AppCard>
            <AppDivider />
          </>
        )}
        <AppTextInput source="agencyEmail" label="Email agenzia" md={3} required />
        <AppTextInput source="agencyPhone" label="Telefono agenzia" md={3} required />
        <AppTextInput source="agencyFax" label="Fax agenzia" md={3} required />
        <AppTextInput source="agencyAddress" label="Indirizzo agenzia" md={3} required />
        {/* COLORS */}
        <AppColorInput
          source="Colors.Primary"
          label="Primary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.Secondary"
          label="Secondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.Accent"
          label="Accent"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.AccentSecondary"
          label="AccentSecondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.TextOverSearchPayoff"
          label="TextOverSearchPayoff"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.TextOverBgSecondary"
          label="TextOverBgSecondary"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchRealEstate"
          label="SearchRealEstate"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchMovable"
          label="SearchMovable"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppColorInput
          source="Colors.SearchOther"
          label="SearchOther"
          hexSource="hex"
          rgbSource="rgb"
          md={2}
        />
        <AppDivider />
        <AppSectionTitle>Link del payoff del pannello di ricerca</AppSectionTitle>
        <AppSelectInput
          source="searchPayoff.linkType"
          label="Tipo"
          choices={LINK_TYPES_CHOICES}
          onChange={(e) => setLinkType(e.target.value)}
        />
        {linkType === LINK_TYPES_CHOICES[0].id && (
          <PageSelection source="searchPayoff.link" label="Pagina" mode={props.mode} />
        )}
        {linkType === LINK_TYPES_CHOICES[1].id && (
          <AppTextInput source="searchPayoff.link" label="Url" placeholder="https://google.com" />
        )}
        <Divider />
        {/* FILES */}
        <AppImageInput
          source="LogoDesktopFile"
          accept="image/png"
          label="Logo sito desktop"
          isRequired
          previewSource="LogoDesktop"
          previewLabel="Logo sito desktop corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoMobileFile"
          accept="image/png"
          label="Logo sito mobile"
          isRequired
          previewSource="LogoMobile"
          previewLabel="Logo sito mobile corrente"
        />
        <AppDivider />
        <AppImageInput
          source="LogoVirtualTourFile"
          accept="image/png"
          label="Logo virtual tour"
          isRequired
          previewSource="LogoVirtualTour"
          previewLabel="Logo virtual tour corrente"
        />
        <AppDivider />
        <AppFileInput
          source="CustomPDFFile"
          accept="application/pdf"
          label="PDF personalizzato"
          md={12}
          previewLabel="PDF personalizzato corrente"
          previewSource="CustomPDF"
        />
      </ConfigurationSection>
    </AppBaseForm>
  );
};

export default ConfigurationDetails;
