import PublishIcon from '@material-ui/icons/Publish';
import { ResourceExport } from '../../types/resource-export.type';
import MassivePublicationBuyNowList from './MassivePublicationBuyNowList';

const MassivePublicationBuyNow: ResourceExport = {
  list: MassivePublicationBuyNowList,
  icon: PublishIcon,
};

export default MassivePublicationBuyNow;
