import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageBackward from '@material-ui/icons/ChevronLeft';
import PageForward from '@material-ui/icons/ChevronRight';
import React, { FC, ReactNode, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Visit https://www.npmjs.com/package/react-pdf for the DOC
 */

const useStyles = makeStyles(
  (theme) => ({
    currentPage: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 15,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    container: {
      display: 'table',
      margin: 'auto',
    },
  }),
  { name: 'AppPdfViewer' },
);

interface AppPdfViewerProps {
  file: string; // Could also be a local file, an object... for now the URL is enough
  error?: string | ReactNode;
  loading?: string | ReactNode;
  noData?: string | ReactNode;
  height?: number;
}

const AppPdfViewer: FC<AppPdfViewerProps> = (props) => {
  const {
    file,
    error = 'PDF non caricato correttamente',
    loading = 'Caricamento del PDF in corso...',
    noData = 'File PDF non specificato',
    height,
  } = props;
  const classes = useStyles();

  const [pagesTotal, setPagesTotal] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onDocumentLoadSuccess = (pdfDocument) => {
    setPagesTotal(pdfDocument._pdfInfo.numPages);
  };

  const onPageChange = (direction: string) => {
    if (direction === 'left' && currentPage !== 1)
      return setCurrentPage((prevState) => prevState - 1);
    if (direction === 'right' && currentPage !== pagesTotal)
      return setCurrentPage((prevState) => prevState + 1);
  };

  return (
    <>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        error={error}
        loading={loading}
        noData={noData}
        className={classes.container}
      >
        <Page pageNumber={currentPage} height={height} />
      </Document>
      <div className={classes.container}>
        <Button children={<PageBackward />} onClick={() => onPageChange('left')} />
        <Button children={<PageForward />} onClick={() => onPageChange('right')} />
      </div>
      <Typography className={classes.currentPage}>
        Pagina {currentPage} di {pagesTotal}
      </Typography>
    </>
  );
};

export default AppPdfViewer;
