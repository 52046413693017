import { Button } from '@material-ui/core';
import PublicationCertificateIcon from '@material-ui/icons/Description';
import { FC, useCallback } from 'react';
import { useMutation, useNotify } from 'react-admin';
import AppTooltip from '../../../Components/ui/tooltip/AppTooltip';
import { useBasePath } from '../../../hooks/use-base-path';

const AuctionReportButton: FC<any> = ({ saleExpId }) => {
  const notify = useNotify();

  const basePath = useBasePath();

  const [generateReport] = useMutation();
  const handleCertificateGeneration = useCallback(() => {
    generateReport(
      {
        type: 'getOne',
        resource: `${basePath}/${saleExpId}/generate-auction-awarded-or-deserted-report`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          const a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.pdfBase64;
          a.download = res.data.fileName;
          a.click();
        },
        onFailure: (err) => {
          notify(`Generazione del verbale di asta non possibile: ${err.message}`, 'error');
          console.error(err);
        },
      },
    );
  }, []);

  return (
    <AppTooltip title="Genera il verbale di asta">
      <Button
        children="Genera verbale di asta"
        endIcon={<PublicationCertificateIcon />}
        variant="outlined"
        onClick={handleCertificateGeneration}
      />
    </AppTooltip>
  );
};

export default AuctionReportButton;
