import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { Record as RaRecord, useMutation } from 'react-admin';
import { ReservePriceActionsEnum } from '../../../../../../utils/constants';
import AuctionManagementButton from '../../auction-management-button';

type Actor = 'owner' | 'bestBidder';
type ActionType = 'accept' | 'reject';

type ActionButtonsInfo = {
  buttonCaption: string;
  buttonAction: ReservePriceActionsEnum;
  buttonTooltip: string;
  buttonClasses: string;
};

interface ReservePriceActionsProps {
  record: RaRecord;
  actor: Actor;
  actionType: ActionType;
}

const useStyles = makeStyles(
  (theme) => ({
    // TODO: improve colors :/
    button: {
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
    acceptButton: {
      color: theme.palette.success.main,
    },
    rejectButton: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'ReservePriceActions' },
);

const ReservePriceActions: FC<ReservePriceActionsProps> = (props) => {
  const { actor = 'owner', actionType } = props;
  const classes = useStyles();

  const _getButtonsInfo = (actor: Actor): ActionButtonsInfo => {
    if (actor === 'owner') {
      // OWNER BUTTON(S) SETTINGS
      if (actionType === 'accept') {
        return {
          buttonCaption: 'Accetta migliore offerta',
          buttonAction: ReservePriceActionsEnum.acceptBestOffer,
          buttonTooltip:
            'Il proprietario accetta la migliore offerta anche se al di sotto del prezzo di riserva stabilito.',
          buttonClasses: `${classes.button} ${classes.acceptButton}`,
        };
      }

      return {
        buttonCaption: 'Rifiuta migliore offerta',
        buttonAction: ReservePriceActionsEnum.rejectBestOffer,
        buttonTooltip:
          'Il proprietario rifiuta la migliore offerta. Il miglior offerente potrà decidere se pareggiare o meno il prezzo di riserva.',
        buttonClasses: `${classes.button} ${classes.rejectButton}`,
      };
    }

    // BEST BIDDER BUTTON(S) SETTINGS
    if (actionType === 'accept') {
      return {
        buttonCaption: 'Pareggia prezzo di riserva',
        buttonAction: ReservePriceActionsEnum.equalsReservePrice,
        buttonTooltip:
          "Il miglior offerente pareggia il prezzo di riserva, ottenendo l'aggiudicazione del bene.",
        buttonClasses: `${classes.button} ${classes.acceptButton}`,
      };
    }

    return {
      buttonCaption: 'Rinuncia',
      buttonAction: ReservePriceActionsEnum.renounce,
      buttonTooltip: 'Il miglior offerente rinuncia al bene.',
      buttonClasses: `${classes.button} ${classes.rejectButton}`,
    };
  };

  const [buttonsInfo, setButtonsInfo] = useState<ActionButtonsInfo>(() => _getButtonsInfo(actor));

  useEffect(() => {
    setButtonsInfo(_getButtonsInfo(actor));
  }, [actor]);

  const [sendAction] = useMutation();
  const handleAction = () =>
    sendAction({
      type: 'create',
      resource: `sale-experiments-private/${props.record.id}/reserve-price-action`,
      payload: {
        data: {
          action: buttonsInfo.buttonAction,
        },
      },
    });

  return (
    <AuctionManagementButton
      caption={buttonsInfo.buttonCaption}
      clickBtnHandler={handleAction}
      variant="contained"
      tooltipText={buttonsInfo.buttonTooltip}
      className={buttonsInfo.buttonClasses}
    />
  );
};

export default ReservePriceActions;
