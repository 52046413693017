import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  CheckCircle as SuccessNotificationIcon,
  Error as ErrorNotificationIcon,
  Info as InfoNotificationIcon,
  Warning as WarningNotificationIcon,
} from '@material-ui/icons';
import GoToButtonIcon from '@material-ui/icons/CallMade';
import classnames from 'classnames';
import React, { cloneElement, FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { dateFormatter } from '../../../../../utils/data-formatters';
import { AppText } from '../../../text';
import AppTooltip from '../../../tooltip/AppTooltip';

enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

type StylesProps = {
  type: NotificationType;
};

const useStyles = makeStyles<Theme, StylesProps>(
  (theme) => ({
    notificationItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    notificationText: {
      maxWidth: '90%',
    },
    notificationDate: {
      bottom: '100%',
      fontStyle: 'italic',
    },
    notificationGoToButton: {
      bottom: -theme.spacing(5),
    },
    notificationColor: (props) => ({
      color: theme.palette[props.type].main,
    }),
  }),
  { name: 'NotificationsListItem' },
);

interface NotificationsListItemProps {
  notification: Record<string, any>;
  setAsRead: (notificationId: number) => void;
}

export const NotificationsListItem: FC<NotificationsListItemProps> = React.memo(
  ({ notification, setAsRead }) => {
    const { id, type, title, description, url, createdAt } = notification;

    const classes = useStyles({ type });

    const history = useHistory();

    const selectNotificationIcon = useCallback(() => {
      switch (type) {
        case NotificationType.Info:
          return <InfoNotificationIcon />;
        case NotificationType.Error:
          return <ErrorNotificationIcon />;
        case NotificationType.Warning:
          return <WarningNotificationIcon />;
        case NotificationType.Success:
          return <SuccessNotificationIcon />;
      }
    }, []);

    const onGoToButtonClick = () => {
      history.push(url);
      setAsRead(id);
    };

    return (
      <ListItem className={classes.notificationItem} onClick={() => setAsRead(id)}>
        <ListItemIcon>
          {cloneElement(selectNotificationIcon() as any, { className: classes.notificationColor })}
        </ListItemIcon>
        <ListItemText
          className={classnames(classes.notificationColor, classes.notificationText)}
          primary={<AppText variant="button" children={title} />}
          secondary={
            description.length > 200 ? (
              <AppTooltip title={description}>
                <AppText children={`${description.substr(0, 200)}...`} />
              </AppTooltip>
            ) : (
              <AppText children={description} />
            )
          }
        />
        <ListItemSecondaryAction className={classes.notificationDate}>
          <AppText variant="caption">{dateFormatter(createdAt)}</AppText>
        </ListItemSecondaryAction>
        {!!url && (
          <ListItemSecondaryAction className={classes.notificationGoToButton}>
            <IconButton edge="end" aria-label="delete" onClick={onGoToButtonClick}>
              <GoToButtonIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  },
);
