import { FC } from 'react';
import AppToolbarDelete from '../../Components/ui/form/AppToolbarDelete';
import AppDivider from '../../Components/ui/layout/AppDivider';
import AppToolbarActions from '../../Components/ui/layout/AppToolbarActions';
import AppToolbarDisableAccess from './AppToolbarDisableAccess';
import AppToolbarEnableAccess from './AppToolbarEnableAccess';
import AppToolbarRemoveAccess from './AppToolbarRemoveAccess';
import { PlatformAccessState } from './enums';

const PersonDetailActions: FC<any> = (props) => {
  return (
    <AppToolbarActions {...props}>
      <AppToolbarDelete />
      <AppDivider />
      {props?.data?.accessState === PlatformAccessState.Active ? (
        [<AppToolbarRemoveAccess />, <AppToolbarDisableAccess />]
      ) : (
        <AppToolbarEnableAccess />
      )}
    </AppToolbarActions>
  );
};

export default PersonDetailActions;
