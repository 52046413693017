import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TvOffIcon from '@material-ui/icons/TvOff';
import React, { FC, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import AppTooltip from '../../../../../Components/ui/tooltip/AppTooltip';
import { useBasePath } from '../../../../../hooks/use-base-path';

const useStyles = makeStyles(
  (theme) => ({
    selectInput: {
      bottom: theme.spacing(1.4),
      right: theme.spacing(2),
      minWidth: theme.spacing(27),
      maxWidth: theme.spacing(27),
    },
    btn: {
      marginRight: theme.spacing(2),
      color: theme.palette.error.main,
    },
  }),
  { name: 'AuctionToScreen' },
);

const AuctionToScreen: FC<any> = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  const basePath = useBasePath();

  const [screenId, setScreenId] = useState<number | null>(props.record.fkScreen ?? null);
  const { mutators: formMutators } = useForm();

  const [mutateScreen] = useMutation();
  const screenChangeHandler = ({ target }) => {
    const screenId = target.value;
    screenId &&
      mutateScreen(
        {
          type: 'getOne',
          resource: `${basePath}/${props.record.id}/screen/${screenId}/activate`,
          payload: {},
        },
        {
          onSuccess: () => {
            notify("L'asta è in proiezione.", 'info');
            setScreenId(screenId);
          },
          onFailure: (err) => {
            console.error(err);
            formMutators.clearInputs('fkScreen');
            notify(err.message, 'error');
          },
        },
      );
  };

  const presentationOffHandler = () => {
    mutateScreen(
      {
        type: 'getOne',
        resource: `${basePath}/${props.record.id}/screen/deactivate`,
        payload: {},
      },
      {
        onSuccess: () => {
          notify("L'asta non è più in proiezione.", 'info');
          setScreenId(null);
          formMutators.clearInputs('fkScreen');
        },
        onFailure: (err) => {
          console.error(err);
          notify(err.message, 'error');
        },
      },
    );
  };

  const [screens, setScreens] = useState([]);
  const [mutateScreens] = useMutation();
  useEffect(() => {
    mutateScreens(
      {
        type: 'getList',
        resource: 'screens',
        payload: {},
      },
      {
        onSuccess: ({ data }) => {
          setScreens(data);
        },
        onFailure: (err) => {
          console.error(err);
          setScreens([]);
        },
      },
    );
  }, []);

  return (
    <React.Fragment>
      <AppTooltip title="Termina presentazione" arrow>
        <Button
          children={<TvOffIcon />}
          disabled={!screenId}
          onClick={presentationOffHandler}
          className={classes.btn}
        />
      </AppTooltip>
      <FormControl className={classes.selectInput} variant="outlined">
        <InputLabel id="screen-select-label" shrink={!!screenId}>
          Mostra su schermo
        </InputLabel>
        <Select
          labelId="screen-select-label"
          id="screen-select"
          value={screenId}
          onChange={screenChangeHandler}
          label="Mostra su schermo"
          defaultValue={null}
        >
          {screens.map((screen: Record<string, any>) => (
            <MenuItem value={screen.id}>{`${screen.code} - ${
              screen.description.length > 10
                ? `${screen.description.substr(0, 10)}...`
                : screen.description
            }`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default AuctionToScreen;
