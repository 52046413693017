import { Grid } from '@material-ui/core';
import { FC, Fragment } from 'react';
import AppCard from '../../ui/card/AppCard';
import AppDivider from '../../ui/layout/AppDivider';
import { AppText } from '../../ui/text';
import {
  PvpOfferContacts,
  PvpOfferField,
  PvpOfferLocation,
  PvpOfferLocationType,
  PvpOfferPerson,
} from './utility';

interface PvpOfferBiddersProps {
  offerenti: Record<string, any>[];
}

const PvpOfferBidders: FC<PvpOfferBiddersProps> = ({ offerenti }) => {
  return (
    <AppCard title="OFFERENTI" expanded spacing={4}>
      {offerenti.map((offerente, index) => {
        const { datiAnagrafici, residenza, domicilio, contatti } = offerente;
        return (
          <Fragment>
            <Grid item md={12}>
              <AppText variant="h6">OFFERENTE #{index + 1}</AppText>
            </Grid>
            <PvpOfferPerson datiAnagrafici={datiAnagrafici} />
            <PvpOfferLocation location={residenza} type={PvpOfferLocationType.Residence} />
            {domicilio && Object.values(domicilio).length ? (
              <PvpOfferLocation location={domicilio} type={PvpOfferLocationType.Domicile} />
            ) : (
              <PvpOfferField label="Domicilio" customValue="Residenza come domicilio" md={12} />
            )}
            <PvpOfferContacts contatti={contatti} />
            <PvpOfferField
              label="Quota di partecipazione"
              record={offerente}
              field="quotaPartecipazione"
            />
            <PvpOfferField label="Diritti" record={offerente} field="dirittoProprieta" />
            <PvpOfferField label="Titolo" record={offerente} field="titolo" />
            {index !== offerenti.length - 1 && <AppDivider />}
          </Fragment>
        );
      })}
    </AppCard>
  );
};

export default PvpOfferBidders;
