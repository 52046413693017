import { Chip } from '@material-ui/core';
import { FC, ReactElement } from 'react';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import {
  AppBooleanInput,
  AppDateInput,
  AppDateTimeInput,
  AppInputFiller,
  AppTextInput,
} from '../../Components/ui/input';
import AppSectionTitle from '../../Components/ui/layout/AppSectionTitle';

// TODO: finish this component (link to sale-exp / receiver)
const ChipLink: FC<any> = ({ record }) => {
  // if (!record) return null;

  return <Chip color="primary" label="TEST" />;
};

const ShippingMedia: FC<any> = ({ record }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      marginTop: '10px',
      marginBottom: '10px',
    }}
  >
    {record.media?.map((picture) => (
      <a href={picture.url} target="_blank" rel="noreferrer">
        <a href={picture.url} key={picture.id} target="_blank">
          <img
            src={picture.url}
            style={{
              width: '128px',
              height: '128px',
              objectFit: 'cover',
            }}
          />
        </a>
      </a>
    ))}
  </div>
);

const ShippingDetails: FC<any> = (props): ReactElement => {
  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard expanded title="Dettagli spedizione" collapsable={false}>
        <AppTextInput source="id" label="Identificativo univoco" disabled md={2} />
        <AppDateTimeInput source="shippingDate" label="Data/ora spedizione" disabled md={3} />
        <AppInputFiller md={7} />
        <AppBooleanInput source="received" label="Consegnato" disabled md={3} />
        <AppDateInput source="receivedDate" label="Data/ora consegna" showTime disabled md={6} />
        <AppInputFiller md={3} />
        <AppBooleanInput source="notReceived" label="Non consegnato" md={3} />
        <AppDateInput
          source="receivedDate"
          label="Data/ora mancata consegna"
          showTime
          disabled
          md={6}
        />
        <AppSectionTitle>Immagini</AppSectionTitle>
        <ShippingMedia />
        {/* <ChipLink /> */}
        {/* <Chip
            color="primary"
            onClick={() =>
              winner &&
              winner.presenter &&
              history.push(
                `/participants/${winner.presenter.id}/show?goBack=true&resourceLabel=Esperimento di vendita&previousResourcePath=${history.location.pathname}`,
              )
            }
            label={
              winner && winner.presenter
                ? `${winner.presenter.lastName} ${winner.presenter.firstName} (${
                    winner.presenter.taxCode ?? '-'
                  })`
                : 'Non disponibile'
            }
          /> */}
      </AppCard>
    </AppBaseForm>
  );
};

export default ShippingDetails;
