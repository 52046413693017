import { FC, memo, useEffect } from 'react';
import { useMutation } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppDateInput, AppTextInput } from '../../../Components/ui/input';
import { PaymentMethod } from '../constants';

export const WireTransferDetails: FC<any> = memo((props) => {
  const { values } = useFormState();
  const { change } = useForm();

  const [mutateFeUser] = useMutation();
  const getFeUser = () => {
    mutateFeUser(
      {
        type: 'getOne',
        resource: `front-end-users/from-participation/${values['fkParticipation']}?propertyId=7`, //* Hard-coded to CDA property for now
        payload: {},
      },
      {
        onSuccess: ({ data }) => {
          change('beneficiaryIban', data.ibanCode);
        },
        onFailure: (error) => {
          change('beneficiaryIban', '');
          console.error('[getFeUser] Error:', error);
        },
      },
    );
  };

  useEffect(() => {
    if (props.mode === 'create' && values['fkParticipation']) {
      getFeUser();
    }
  }, [values['fkParticipation']]);

  return (
    <AppCard
      {...props}
      title="Informazioni bonifico bancario"
      hidden={values.paymentMethod !== PaymentMethod.WireTransfer}
    >
      <AppTextInput
        source="beneficiaryIban"
        label="IBAN beneficiario"
        md={12}
        helperText={
          "Compilato in automatico con l'IBAN impostato nel profilo dell'utente - inserire IBAN manualmente se non compilato"
        }
      />
      <AppTextInput source="cro" label="CRO" md={6} />
      <AppDateInput source="croDate" label="Data CRO" md={6} />
    </AppCard>
  );
});
