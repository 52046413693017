import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { AppText } from '../ui/text';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  deactivatedTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  deactivatedSubTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface DeactivatedFeatureProps {
  title: string;
  subtitle: string;
}

export const DeactivatedFeature: FC<DeactivatedFeatureProps> = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Grid item md={12}>
      <Card className={classes.card}>
        <CardContent>
          <AppText variant="h4" align="center" className={classes.deactivatedTitle}>
            {title}
          </AppText>
          <AppText variant="h5" align="center" className={classes.deactivatedSubTitle}>
            {subtitle}
          </AppText>
        </CardContent>
      </Card>
    </Grid>
  );
};
