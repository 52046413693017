import { Record as RaRecord } from 'ra-core';
import React, { FC, Fragment } from 'react';
import { AdvertisementOutcomeType } from '../../../../Resources/SaleExperiment/enums';
import { CompletedAdvertisementInfo } from '../../../../Resources/SaleExperiment/types';
import { AdvertisementAuctionCompletedNotSold } from './advertisement-auction-completed-not-sold';
import { AdvertisementOutcomeCompletedSold } from './advertisement-outcome-completed-sold';

interface AdvertisementOutcomeCompletedProps {
  completedAuctionInfo: CompletedAdvertisementInfo;
  setCompletedAuctionInfo: React.Dispatch<
    React.SetStateAction<CompletedAdvertisementInfo | undefined>
  >;
  saleExperiment: RaRecord;
}

export const AdvertisementOutcomeCompleted: FC<AdvertisementOutcomeCompletedProps> = ({
  completedAuctionInfo,
  setCompletedAuctionInfo,
  saleExperiment,
}) => {
  const selectComponentFromOutcomeType = (outcomeType: AdvertisementOutcomeType) => {
    switch (outcomeType) {
      case AdvertisementOutcomeType.Sold:
        return (
          <AdvertisementOutcomeCompletedSold
            completedAuctionInfo={completedAuctionInfo}
            saleExperiment={saleExperiment}
          />
        );
      case AdvertisementOutcomeType.PartiallySold:
      case AdvertisementOutcomeType.Postponed:
      case AdvertisementOutcomeType.Deserted:
      case AdvertisementOutcomeType.Settled:
        return (
          <AdvertisementAuctionCompletedNotSold
            completedAuctionInfo={completedAuctionInfo}
            setCompletedAuctionInfo={setCompletedAuctionInfo}
          />
        );
      default:
        return <Fragment />;
    }
  };

  return selectComponentFromOutcomeType(completedAuctionInfo.outcomeType);
};
