import React, { FC, useContext } from 'react';
import { ItemGenre, SaleMode } from '../../utils/constants';
import {
  AppRelatedResourceContext,
  AppRelatedResourceEditButton,
} from '../related-resource/app-related-resource';
import AppTooltip from '../ui/tooltip/AppTooltip';

const ParticipationRowCheck: FC<any> = React.memo((props) => {
  const { record: participationRecord, saleExpRecord, disabled, a107 } = props;
  const { auctionStatus, fkSaleMode: saleMode, bidsStartAt, relatedItem } = saleExpRecord;
  const { origin, observer } = participationRecord;
  const ctx = useContext(AppRelatedResourceContext);

  let buttonTitle = observer ? 'VERIFICA RICHIESTA' : 'VERIFICA ISCRIZIONE';
  if (!a107) {
    switch (saleMode) {
      case SaleMode.SyncOnline:
      case SaleMode.SyncMixed:
        if (origin === 'paper') {
          buttonTitle =
            auctionStatus === 'authorization'
              ? observer
                ? 'VERIFICA RICHIESTA'
                : 'VERIFICA ISCRIZIONE'
              : auctionStatus === 'active'
              ? observer
                ? 'CONSULTA RICHIESTA'
                : 'CONSULTA OFFERTA'
              : 'MODIFICA';
        } else {
          buttonTitle =
            auctionStatus === 'authorization'
              ? observer
                ? 'VERIFICA RICHIESTA'
                : 'VERIFICA ISCRIZIONE'
              : observer
              ? 'CONSULTA RICHIESTA'
              : 'CONSULTA OFFERTA';
        }
        break;
      case SaleMode.AsyncOnline:
        buttonTitle = observer ? 'VERIFICA RICHIESTA' : 'VERIFICA ISCRIZIONE';
        if (
          (relatedItem &&
            relatedItem.fkGenre === ItemGenre.RealEstate &&
            !observer &&
            auctionStatus !== 'authorization') ||
          (!(relatedItem && relatedItem.fkGenre === ItemGenre.RealEstate) &&
            !observer &&
            (Date.now() < new Date(bidsStartAt).getTime() ||
              ['completed', 'settled'].includes(auctionStatus)))
        ) {
          buttonTitle = observer ? 'CONSULTA RICHIESTA' : 'CONSULTA OFFERTA';
        }
        break;
      case SaleMode.ExpressionOfInterest:
      case SaleMode.OffersCollection:
        buttonTitle = 'Visualizza dati';
        break;
      default:
        break;
    }
  }

  return (
    <>
      <AppTooltip title={buttonTitle} arrow>
        <AppRelatedResourceEditButton
          record={participationRecord}
          disabled={disabled || participationRecord.owner}
          text={buttonTitle}
          onClick={() => {
            ctx.manager.setAdditionalParams({});
            ctx.getRelatedResource(participationRecord.id);
          }}
        />
      </AppTooltip>
    </>
  );
});

export default ParticipationRowCheck;
