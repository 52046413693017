import { assign as _assign } from 'lodash';
import { checkAndGetDate } from './dates';

/// region DATE

export const dateFormatter = (date: number | string | Date, dateOnly?: boolean): string => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  if (!dateOnly) {
    _assign(options, {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  date = checkAndGetDate(date);
  return date ? date.toLocaleDateString('it-IT', options) : '-';
};

/// endregion

/// region AMOUNT

export const amountFormatter = (
  amount: number,
  mustBePositive = false,
  minimumFractionDigits = 2,
): string => {
  if (!amount || isNaN(amount) || (mustBePositive && amount < 1)) return '-';
  return new Intl.NumberFormat('it-IT', { minimumFractionDigits, maximumFractionDigits: 2 }).format(
    amount,
  );
};

/// endregion

/// region TEXT

export const truncateText = (text: string): string => {
  if (!text) return '-';
  return text.length > 20 ? `${text.substr(0, 20)}...` : text;
};

export const padString = (string: string, length = 2, padString = '0'): string => {
  while (string.length < length) {
    string = padString + string;
  }
  return string;
};

/// endregion
