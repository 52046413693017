import { Button, Grid, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { FC, useMemo, useState } from 'react';
import { useMutation, useNotify, usePermissions } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import AppConfirmDialog from '../../../Components/ui/dialog/confirm-dialog/app-confirm-dialog';
import {
  AppAutocompleteInput,
  AppDateTimeInput,
  AppInputFiller,
  AppTextInput,
} from '../../../Components/ui/input';
import { User } from '../../../types/user.type';
import { ItemGenre, SaleMode } from '../../../utils/constants';
import { folderIsJudiciary } from '../../../utils/folder';
import ItemSelection from '../ItemSelection';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: -theme.spacing(1.5),
      marginRight: theme.spacing(1),
      '&:disabled': {
        backgroundColor: green[500],
        color: 'black',
        fontWeight: 'bold',
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'AuctionGeneral' },
);

export const AuctionGeneral: FC<any> = ({ record, resource, mode, selectedItem }) => {
  const classes = useStyles();
  const notify = useNotify();

  const [isCurrent, setIsCurrent] = useState<boolean>(!!record && record.isCurrent);

  const [confirmChangeCurrentDialogOpen, setConfirmChangeCurrentDialogOpen] =
    useState<boolean>(false);
  const toggleConfirmDialog = () => setConfirmChangeCurrentDialogOpen((prevState) => !prevState);

  const [mutateIsCurrent] = useMutation();
  const onChangeCurrent = () =>
    mutateIsCurrent(
      {
        type: 'getOne',
        resource: `${resource}/${record.id}/update-current`,
        payload: {},
      },
      {
        onSuccess: () => {
          setIsCurrent(true);
          toggleConfirmDialog();
          notify('Questo esperimento è stato indicato come corrente con successo.');
        },
        onFailure: (err) => {
          toggleConfirmDialog();
          console.error('[onChangeCurrent] Error:', err);
          notify(`Operazione fallita: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`, 'error');
        },
      },
    );

  const isJudiciary = useMemo(
    () => folderIsJudiciary(selectedItem.relatedFolder),
    [selectedItem.relatedFolder],
  );

  const [saleModesChoices, setSaleModesChoices] = useState<any[]>([]);
  const [mutateSaleModes] = useMutation();
  useMemo(() => {
    mutateSaleModes(
      {
        type: 'getList',
        resource: 'sale-modes',
        payload: {
          sort: {
            field: 'order',
            order: 'ASC',
          },
        },
      },
      {
        onSuccess: (res) => setSaleModesChoices(res.data),
        onFailure: (err) => console.error(err),
      },
    );
  }, []);

  const { permissions: user } = usePermissions<User>();

  return (
    <AppCard title="Informazioni principali" expanded>
      <ItemSelection mode={mode} resource={resource} record={record} />
      <AppAutocompleteInput
        label="Modalità di vendita"
        source="fkSaleMode"
        choices={
          user?.agency
            ? saleModesChoices.filter((s) =>
                [
                  SaleMode.AsyncOnline,
                  SaleMode.SyncMixed,
                  SaleMode.SyncOnline,
                  SaleMode.ExpressionOfInterest,
                  SaleMode.OffersCollection,
                  user?.agency?.genre === 'movable' ? SaleMode.BuyItNow : undefined,
                ].includes(s.id),
              )
            : saleModesChoices
        }
        optionText={(saleMode) => {
          if (!saleMode) return '';
          return saleMode.description === 'Asincrona' && selectedItem
            ? `${saleMode.description} ${
                selectedItem.fkGenre === ItemGenre.RealEstate ? 'Immobiliare' : 'Mobiliare'
              }`
            : saleMode.description;
        }}
        md={3}
        disabled={mode !== 'create'}
        required
      />
      {mode === 'edit' && [
        // TODO: permissions
        <Grid item>
          <Button
            children={isCurrent ? 'Esperimento corrente' : 'Imposta come esperimento corrente'}
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isCurrent}
            onClick={toggleConfirmDialog}
          />
        </Grid>,
        <AppConfirmDialog
          onConfirm={onChangeCurrent}
          onClose={toggleConfirmDialog}
          open={confirmChangeCurrentDialogOpen}
          title="Confermi di voler rendere corrente questo esperimento di vendita?"
          details="L'azione comporterà la sostituzione immediata delle informazioni presentate a sito"
        />,
      ]}
      {mode !== 'create' && [
        <AppDateTimeInput
          source="isCurrentDate"
          label="Data di pubblicazione"
          md={isCurrent ? 3 : 2}
          disabled={mode === 'show'}
        />,
        mode !== 'edit' ? <AppInputFiller md={2} /> : undefined,
        ...(isJudiciary
          ? [
              <AppTextInput source="pvpId" label="Id inserzione PVP" disabled md={2} />,
              <AppTextInput source="pvpContentId" label="Content Id PVP" disabled md={2} />,
            ]
          : []),
      ]}
    </AppCard>
  );
};
