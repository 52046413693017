import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-admin';
import AppCard from '../../../Components/ui/card/AppCard';
import { AppAutocompleteInput, AppNumberInput, AppTextInput } from '../../../Components/ui/input';
import { courtIsPublicAuthority } from '../../../utils/folder';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';
const ALTRO_VENDITA_NON_GIUDIZIARIA_RITE_ID = 24;

export const JudiciaryDetails: FC<any> = (props) => {
  const { formData, resource, disabled } = props;

  const [publicAuthoritySelected, setPublicAuthoritySelected] = useState(false); // Selected court is "Ente pubblico"

  const [ritesChoices, setRitesChoices] = useState<Record<string, any>[]>([]);
  const [mutateRites] = useMutation();

  useEffect(() => {
    mutateRites(
      {
        type: 'getList',
        resource: 'pvp/rites',
        payload: {
          filter: {
            judiciary: true,
          },
          sort: {
            field: 'description',
            order: 'ASC',
          },
        },
      },
      {
        onSuccess: (res) =>
          setRitesChoices(
            (IS_IVG
              ? res.data.filter((rite) => rite.id !== ALTRO_VENDITA_NON_GIUDIZIARIA_RITE_ID)
              : res.data
            ).map(({ id, description, fkPvpRegister }) => ({
              id,
              description,
              fkPvpRegister,
            })),
          ),
        onFailure: (err) => {
          console.error(err);
        },
      },
    );
  }, []);

  useEffect(() => {
    if (
      (props.mode === 'edit' || props.mode === 'show') &&
      courtIsPublicAuthority(props.record?.courtName)
    ) {
      setPublicAuthoritySelected(true);
    }
  }, [props.mode, props.record]);

  if (!(formData.type === 'judiciary' || resource === 'folders-judiciary')) return <Fragment />;

  return (
    <AppCard title="Giudiziario" disabled={disabled}>
      <AppTextInput md={12} source="title" label="Nome procedura" />
      <AppAutocompleteInput
        reference="pvp/registers"
        optionText="description"
        source="fkPvpRegister"
        label="Tipo di procedura"
        disabled
      />
      <AppAutocompleteInput
        choices={ritesChoices}
        perPage={27}
        optionText="description"
        source="fkPvpRite"
        label="Rito"
        required
        resettable
        onSelect={(rite) => (formData.fkPvpRegister = rite.fkPvpRegister)}
      />
      <AppTextInput
        source="courtProcedureNumber"
        label="Numero procedura"
        required
        md={publicAuthoritySelected ? 3 : 4}
      />
      <AppNumberInput
        source="courtProcedureYear"
        label="Anno procedura"
        required
        md={publicAuthoritySelected ? 3 : 4}
      />
      <AppAutocompleteInput
        source="fkCourt"
        reference="courts"
        label="Tribunale *"
        suggestionLimit={5}
        required
        resettable
        md={publicAuthoritySelected ? 3 : 4}
        onSelect={(selectedCourt) => {
          if (courtIsPublicAuthority(selectedCourt?.name)) {
            setPublicAuthoritySelected(true);
          } else {
            setPublicAuthoritySelected(false);
          }
        }}
      />
      {publicAuthoritySelected && <AppTextInput source="publicAuthority" label="Ente" md={3} />}
    </AppCard>
  );
};
