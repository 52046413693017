import {
  AppBar,
  Card,
  CardContent,
  CardMedia,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { FC, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import AppPdfViewer from '../../../Components/pdf-viewer/pdf-viewer';
import PecViewer from '../../../Components/pec-viewer/pec-viewer';
import AppAlert from '../../../Components/ui/alert/AppAlert';
import AppDownloadMediaButton from '../../../Components/ui/button/AppDownloadMediaButton';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      maxWidth: `calc(100vw - ${theme.spacing(120)}px)`,
      width: `calc(100vw - ${theme.spacing(120)}px)`,
      maxHeight: '100vh',
      height: '100vh',
      overflow: 'hidden',
      boxShadow:
        'inset 0px -1px 10px 0px rgba(0,0,0,0.12), inset 0px -4px 5px 0px rgba(0,0,0,0.14), inset 0px -2px 4px -1px rgba(0,0,0,0.12)',
    },
    toolbar: {
      '& button:not(.close)': {
        marginLeft: theme.spacing(2),
      },
    },
    content: {
      position: 'relative',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingTop: theme.spacing(3),
      paddingBottom: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    card: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    media: {
      width: '70%',
      height: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
    },
    presenter: {
      margin: 'auto',
    },
  }),
  { name: 'AttachmentsWindow' },
);

type Props = {
  attachmentIds: number[];
  isOpen: boolean;
  onClose: () => void;
};

const AttachmentsWindow: FC<Props> = ({ attachmentIds, isOpen, onClose }) => {
  const classes = useStyles();
  const notify = useNotify();

  /// region ATTACHMENTS

  const [attachments, setAttachments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [mutateAttachments] = useMutation();
  const getAttachments = () =>
    mutateAttachments(
      {
        type: 'getList',
        resource: `front-end-users/${attachmentIds}/media`,
        payload: {},
      },
      {
        onSuccess: (res) => {
          setAttachments(res.data);
          setLoading(false);
        },
        onFailure: (err) => {
          setAttachments([]);
          setLoading(false);
          notify('Non è stato possibile recuperare gli allegati.', 'error');
          console.error(err);
        },
      },
    );

  /// endregion

  // update attachments and auth button availability every time the drawer is opened
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getAttachments();
    }
  }, [isOpen]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <AppBar position="relative">
          <Toolbar className={classes.toolbar}>
            <IconButton onClick={onClose} className="close">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!loading && (
          <div className={classes.content}>
            <Typography variant="h4" className={classes.title}>
              Documenti allegati
            </Typography>
            {attachments && attachments.length ? (
              attachments.map((attachment, index) => {
                if (!attachment.metadata || !Object.values(attachment.metadata).length) return;

                const attachmentSrc = `data:${attachment.metadata.mimeType};base64,${attachment.metadata.base64}`;
                return (
                  <Card className={classes.card} key={`attachment-${index}`}>
                    <CardContent>
                      <Typography variant="h6" className={classes.title}>
                        {attachment.name}
                      </Typography>
                      <AppDownloadMediaButton fileName={attachment.name} base64={attachmentSrc} />
                    </CardContent>
                    {attachment.metadata.mimeType === 'application/pdf' ? (
                      <AppPdfViewer file={attachmentSrc} />
                    ) : attachment.metadata.mimeType === 'message/rfc822' ? (
                      <PecViewer pecBase64={attachmentSrc.split(';base64,')[1]} />
                    ) : (
                      <CardMedia
                        className={classes.media}
                        component="img"
                        alt="Anteprima documento non disponibile"
                        src={attachmentSrc}
                      />
                    )}
                  </Card>
                );
              })
            ) : (
              <AppAlert severity="warning">Non sono presenti documenti</AppAlert>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AttachmentsWindow;
