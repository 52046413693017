import { Button, makeStyles } from '@material-ui/core';
import { FileCopy, GetApp, Print, Visibility } from '@material-ui/icons';
import classnames from 'classnames';
import { get as _get } from 'lodash';
import { FC } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { downloadFileFromBufferOrString } from '../../../utils/files';
import { AppText } from '../text';

const useStyles = makeStyles(
  (theme) => ({
    copyAddressButton: {
      marginLeft: theme.spacing(1),
    },
    a5Button: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'AppDownloadFromGCPBucketField' },
);

export const AppDownloadFromGCPBucketField: FC<any> = ({
  record,
  source,
  protectedFile = false,
  headerClassName = '',
  cellClassName = '',
  className = '',
  alwaysShow = false,
  ...rest
}) => {
  const classes = useStyles();
  const publicUrl = _get(record, source);
  const notify = useNotify();

  const copyText = (e: MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(publicUrl).then(
      () => notify('Indirizzo copiato negli appunti'),
      (err) => console.error('Could not copy text: ', err),
    );
  };

  const [mutateFile] = useMutation();
  const openFile = (e: MouseEvent) => {
    e.stopPropagation();
    if (protectedFile) {
      // Download from API
      return mutateFile(
        {
          type: 'getOne',
          resource: `invoices/${record.id}/download-invoice`,
          payload: {},
        },
        {
          onSuccess: (res) =>
            downloadFileFromBufferOrString(res.data.csv, record.fileName, 'text/csv'),
          onFailure: (err) => {
            console.error('[openFile]:', err);
            notify('Impossibile scaricare il file.', 'error');
          },
        },
      );
    }

    window.open(publicUrl, '_blank');
  };

  const openA5File = (e: MouseEvent) => {
    e.stopPropagation();
    const a5Url = `${publicUrl.replace('.pdf', '')}-A5.pdf`;
    window.open(a5Url, '_blank');
  };

  return alwaysShow || record.generationStatus === 'done' ? (
    <>
      <Button
        variant="outlined"
        onClick={openFile}
        disabled={false}
        children={protectedFile ? 'SCARICA' : 'VISUALIZZA'}
        startIcon={protectedFile ? <GetApp /> : <Visibility />}
        className={classnames(className, headerClassName, cellClassName)}
        {...rest}
      />
      {!protectedFile && (
        <Button
          className={classnames(
            classes.copyAddressButton,
            className,
            headerClassName,
            cellClassName,
          )}
          variant="outlined"
          onClick={copyText}
          disabled={false}
          children="INDIRIZZO"
          startIcon={<FileCopy />}
          {...rest}
        />
      )}
      {record.kind === 'print' && (
        <Button
          variant="outlined"
          onClick={openA5File}
          disabled={false}
          children="VERSIONE A5"
          startIcon={<Print />}
          className={classnames(classes.a5Button, className, headerClassName, cellClassName)}
          {...rest}
        />
      )}
    </>
  ) : (
    <AppText>-</AppText>
  );
};

export default AppDownloadFromGCPBucketField;
