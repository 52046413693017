import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ListProps, FunctionField, useListContext, SelectField } from 'react-admin';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import { AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import { MONTHS } from '../../utils/constants';
import { getYearsList } from '../../utils/dates';
import { PVP_ASSIGNMENT_PERMISSIONS } from './index';
import PvpAssignmentExpanded from './PvpAssignmentExpanded';

const useStyles = makeStyles(
  () => ({
    genericCol: {
      whiteSpace: 'nowrap',
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'PvpAssignmentList' },
);

const PvpAssignmentFilters = () => {
  const now = new Date();
  const yearsChoices: Record<string, any>[] = getYearsList(2022, now.getFullYear()).map((year) => ({
    id: year,
    name: year,
  }));
  yearsChoices.unshift({ id: null, name: '\xa0' });
  const { filterValues } = useListContext();
  return (
    <AppListFilter disableFulltextSearch>
      <AppSelectInput
        source="monthFrom"
        label="Incarichi da"
        choices={MONTHS.filter(
          (_, index) =>
            !filterValues.yearFrom ||
            filterValues.yearFrom < now.getFullYear() ||
            (filterValues.yearFrom &&
              filterValues.yearFrom === now.getFullYear() &&
              index < now.getMonth()),
        ).map((month, index) => ({ id: index, name: month }))}
        md={2}
      />
      <AppSelectInput source="yearFrom" label="Del" choices={yearsChoices} md={2} />
      <AppSelectInput
        source="monthTo"
        label="Incarichi a (incluso)"
        choices={MONTHS.filter(
          (_, index) =>
            !filterValues.yearTo ||
            filterValues.yearTo < now.getFullYear() ||
            (filterValues.yearTo &&
              filterValues.yearTo === now.getFullYear() &&
              index < now.getMonth()),
        ).map((month, index) => ({ id: index, name: month }))}
        md={2}
      />
      <AppSelectInput source="yearTo" label="Del" choices={yearsChoices} md={2} />
    </AppListFilter>
  );
};

const PvpAssignmentList: FC<ListProps> = (props: ListProps) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Registro incarichi PVP" />}
      filters={<PvpAssignmentFilters />}
      resourcePermissions={PVP_ASSIGNMENT_PERMISSIONS}
    >
      <AppDatagrid rowClick="expand" expand={<PvpAssignmentExpanded />}>
        <SelectField
          choices={MONTHS.map((month, index) => ({
            id: index,
            name: month,
          }))}
          source="monthFrom"
          label="Da"
          headerClassName={classes.genericCol}
        />
        <AppTextField source="yearFrom" label="Del" headerClassName={classes.genericCol} />
        <SelectField
          choices={MONTHS.map((month, index) => ({
            id: index,
            name: month,
          }))}
          source="monthTo"
          label="A (incluso)"
          headerClassName={classes.genericCol}
        />
        <AppTextField source="yearTo" label="Del" headerClassName={classes.genericCol} />
        <FunctionField
          label="Inserzioni (ID)"
          headerClassName={classes.lastCol}
          render={(record) =>
            !!record.pvpPublicationRequestsList &&
            !!record.pvpPublicationRequestsList.length && (
              <AppTextField
                customText={record.pvpPublicationRequestsList
                  .map((request) => request.saleExperimentInsertionId)
                  .join(', ')}
              />
            )
          }
        />
      </AppDatagrid>
    </AppList>
  );
};

export default PvpAssignmentList;
