import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { BooleanField, FunctionField } from 'react-admin';
import { CreateInfoRequestPaymentButton } from '../../Components/info-request/create-info-request-payment-button';
import { UpdateInfoRequestStatusButton } from '../../Components/info-request/update-info-request-status-button';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import {
  AppDateField,
  AppLinkToEntityField,
  AppTextField,
  AppUrlField,
} from '../../Components/ui/field';
import { AppText } from '../../Components/ui/text';
import {
  INFO_REQUESTER_BASE_SOURCE,
  INFO_REQUEST_TYPE_CHOICES,
  ITEM_BASE_SOURCE,
  PERSON_BASE_SOURCE,
} from './constants';

const useStyles = makeStyles(
  (theme) => ({
    createdAt: {
      whiteSpace: 'nowrap',
    },
    name: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    requestType: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    email: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    phone: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    withPec: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    withDigitalSignature: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    itemTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    publications: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(50),
    },
    lastCol: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    link: {
      color: theme.palette.common.black,
    },
  }),
  { name: 'InfoRequestDatagrid' },
);

const InfoRequestDatagrid: FC<any> = (props) => {
  const classes = useStyles();

  return (
    <AppDatagrid {...props} expand={props.expand}>
      <FunctionField
        label="Tipo di richiesta"
        render={(record) => (
          <AppTextField
            customText={
              record
                ? INFO_REQUEST_TYPE_CHOICES.find((type) => type.id === record.requestType)?.name
                : '-'
            }
          />
        )}
        headerClassName={classes.name}
      />
      <AppDateField source="createdAt" label="Data richiesta" headerClassName={classes.createdAt} />
      <FunctionField
        label="Nominativo"
        render={(record) => (
          <AppTextField
            customText={
              record
                ? `${record[INFO_REQUESTER_BASE_SOURCE].lastName} ${record[INFO_REQUESTER_BASE_SOURCE].firstName}`
                : '-'
            }
          />
        )}
        headerClassName={classes.name}
      />
      <AppTextField
        source={`${INFO_REQUESTER_BASE_SOURCE}.email`}
        label="Email"
        headerClassName={classes.email}
      />
      <AppTextField
        source={`${INFO_REQUESTER_BASE_SOURCE}.phone`}
        label="Telefono"
        headerClassName={classes.phone}
      />
      <BooleanField
        source={`${INFO_REQUESTER_BASE_SOURCE}.withPec`}
        label="Con PEC"
        headerClassName={classes.withPec}
      />
      <BooleanField
        source={`${INFO_REQUESTER_BASE_SOURCE}.withDigitalSignature`}
        label="Con firma digitale"
        headerClassName={classes.withDigitalSignature}
      />
      <FunctionField
        label="Anagrafica richiedente"
        headerClassName={classes.name}
        render={(r) => (
          <AppLinkToEntityField
            targetResource="people"
            originName="richieste informazioni"
            value={() => `${r?.[PERSON_BASE_SOURCE].lastName} ${r?.[PERSON_BASE_SOURCE].firstName}`}
            targetResourceId={() => r?.[PERSON_BASE_SOURCE].id}
            className={classes.link}
          />
        )}
      />
      <FunctionField
        label="Lotto"
        headerClassName={classes.itemTitle}
        render={(infoRequest) =>
          infoRequest?.item ? (
            <AppLinkToEntityField
              label="Lotto"
              targetResource="items"
              originName="richieste informazioni"
              record={infoRequest}
              value={(r) => r[ITEM_BASE_SOURCE].title}
              targetResourceId={(r) => r[ITEM_BASE_SOURCE].id}
              truncate
              className={classes.link}
            />
          ) : (
            <AppText children="-" />
          )
        }
      />
      <FunctionField
        label="Pubblicazioni del lotto"
        headerClassName={classes.publications}
        render={(infoRequest) =>
          infoRequest?.item ? (
            infoRequest?.item?.publications &&
            infoRequest.item?.publications.some((p) => p.isPublished) ? (
              infoRequest.item?.publications
                .filter((p) => p.isPublished)
                .map((p) => {
                  let url = '';
                  if (infoRequest?.item?.thirdLevel) {
                    const propertyHostArr = p.property.host.split('://');
                    url = `${propertyHostArr[0]}://${infoRequest.item.thirdLevel}.${propertyHostArr[1]}/annunci/${p.slug}`;
                  } else {
                    url = `${p.property.host}/annunci/${p.slug}`;
                  }
                  return <AppUrlField url={url} text={p.property.name} component="p" />;
                })
            ) : (
              <AppText children="Nessuna pubblicazione attiva su questo lotto" />
            )
          ) : (
            <AppText children="-" />
          )
        }
      />
      <CreateInfoRequestPaymentButton />
      <UpdateInfoRequestStatusButton />
    </AppDatagrid>
  );
};

export default InfoRequestDatagrid;
