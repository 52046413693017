import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
  BooleanField,
  ChipField,
  ListProps,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    name: {
      minWidth: theme.spacing(50),
    },
    enabled: {
      minWidth: theme.spacing(20),
    },
    genres: {
      width: '100%',
    },
  }),
  { name: 'PropertyList' },
);

const PropertyList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <AppList
      {...props}
      title={<AppTitle title="Siti" />}
      bulkActionButtons={false}
      filters={<AppListFilter helperText="Nome" />}
    >
      <AppDatagrid>
        <AppTextField source="name" label="Nome" headerClassName={classes.name} />
        <BooleanField source="enabled" label="Abilitato" headerClassName={classes.enabled} />
        <ReferenceArrayField
          reference="genres"
          source="genres"
          label="Generi pubblicabili"
          headerClassName={classes.genres}
        >
          <SingleFieldList>
            <ChipField source="description" />
          </SingleFieldList>
        </ReferenceArrayField>
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default PropertyList;
