import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { EmailField, FunctionField, ListProps, ReferenceField, TextField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  (theme) => ({
    email: {
      minWidth: theme.spacing(20),
    },
    phone: {
      minWidth: theme.spacing(15),
    },
    flags: {
      whiteSpace: 'nowrap',
      //textAlign: 'center',
      //'& .MuiTypography-root': {
      //  display: 'inline-block',
      //},
    },
    taxCode: {
      minWidth: theme.spacing(15),
    },
    birthday: {
      minWidth: theme.spacing(10),
    },
    address: {
      minWidth: theme.spacing(25),
    },
    city: {
      minWidth: theme.spacing(30),
    },
    participant: {
      minWidth: theme.spacing(25),
    },
    lastCol: {
      width: '100%',
    },
  }),
  { name: 'ParticipantList' },
);

const ParticipantListFilters = (
  <AppListFilter helperText="cognome, nome, codice fiscale, email">
    {/* <AppSelectInput
      source="_type"
      label="Tipo"
      choices={[
        { id: null, name: '\xa0' },
        { id: 'pvp', name: 'PVP' },
        { id: 'online', name: 'Telematico' },
        { id: 'offline', name: 'Cartaceo' },
      ]}
    />
    <AppBooleanInput source="blacklist" label="Blacklist" md={1} /> */}
  </AppListFilter>
);

const ParticipantList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Partecipanti" />}
      bulkActionButtons={false}
      filters={ParticipantListFilters}
      //resourcePermissions={PARTICIPANT_PERMISSIONS}
    >
      <AppDatagrid rowClick="show">
        <AppTextField source="lastName" label="Cognome" headerClassName={classes.participant} />
        <AppTextField source="firstName" label="Nome" headerClassName={classes.participant} />
        <TextField
          source="taxCode"
          label="Codice Fiscale"
          emptyText="-"
          headerClassName={classes.taxCode}
        />
        <AppDateField
          source="birthday"
          label="Data di nascita"
          headerClassName={classes.birthday}
        />
        <FunctionField
          label="Città di residenza"
          headerClassName={classes.city}
          render={(record) =>
            record?.residenceForeignCity && record?.residenceForeignCity !== '' ? (
              `${record.residenceForeignCity} (${record.residenceCountry})`
            ) : record?.residenceCity && record?.residenceCity > 0 ? (
              <ReferenceField
                reference="cities"
                source="residenceCity"
                emptyText="-"
                label="Città di residenza"
                link={false}
                headerClassName={classes.city}
              >
                <AppTextField source="name" />
              </ReferenceField>
            ) : (
              <AppTextField source="name">-</AppTextField>
            )
          }
        />
        <AppTextField
          source="residenceAddress"
          label="Indirizzo di residenza"
          headerClassName={classes.address}
        />
        <EmailField source="email" label="Email" headerClassName={classes.email} />
        <FunctionField
          label="Telefono"
          render={(r) =>
            (r && r.mobiles && r.mobiles[0]?.number) ||
            (r && r.phones && r.phones[0]?.number) ||
            '-'
          }
          headerClassName={classes.phone}
        />
        {/* <BooleanField
          source="blacklist"
          label="Blacklist"
          headerClassName={`${classes.flags} ${classes.lastCol}`}
          cellClassName={classes.flags}
        /> */}
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default ParticipantList;
