import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import { AppRelatedResourceContext } from '../related-resource/app-related-resource';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import AppCapabilityForm from './app-capability-form';

const AppCapabilityManager: FC<any> = (props) => {
  const { title, resource, ...others } = props;
  const notify = useNotify();

  /// region ADD PERSON-ROLE DIALOG

  const [addPersonButtonIsDisabled, setAddPersonButtonIsDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<'person' | 'company' | null>(null);
  const [selectedRoleInfo, setSelectedRoleInfo] = useState<Record<string, any>>({});
  const handleClickOpen = (type: 'person' | 'company' | null) => {
    setOpen(true);
    setType(type);
  };

  /// endregion

  /// region CONTEXT

  const ctx = useContext(AppRelatedResourceContext);
  ctx.item = ctx.manager.mode === 'insert' ? {} : ctx.item;
  const { mainResourceRecord } = ctx;
  let isJudiciaryFolder = false;
  let courtId;
  if (resource === 'folder') {
    isJudiciaryFolder = mainResourceRecord.type !== 'private';
    courtId = mainResourceRecord.fkCourt;
  } else {
    if (mainResourceRecord.relatedFolder) {
      isJudiciaryFolder = mainResourceRecord.relatedFolder.type !== 'private';
      courtId = mainResourceRecord.relatedFolder.fkCourt;
    }
  }

  useEffect(() => {
    if (ctx.manager.mode === 'edit') {
      getPeople(ctx.item.fkRole);
      setSelectedRoleInfo(ctx.item.role);
      setAddPersonButtonIsDisabled(true);
    }
  }, [ctx.item]);

  /// endregion

  /// region PEOPLE CHOICES

  const [peopleChoices, setPeopleChoices] = useState<Record<string, any>[]>([]);
  const [mutatePeople, { loading: peopleLoading }] = useMutation();
  const getPeople = (roleId) =>
    roleId &&
    mutatePeople(
      {
        type: 'getList',
        resource: `people/fromRole/${roleId}`,
        payload: {
          filter: {
            fkCourt: isJudiciaryFolder ? courtId : undefined,
          },
        },
      },
      {
        onSuccess: (res) => {
          setPeopleChoices(res.data);
          if (!res.data.length) {
            setAddPersonButtonIsDisabled(false);
            notify('Non ci sono anagrafiche collegate al ruolo selezionato', 'warning');
          }
        },
        onFailure: (err) => {
          console.error(err);
          setPeopleChoices([]);
        },
      },
    );

  /// endregion

  /// region FORM UTILITIES

  const showCreatePersonButton = (inputValue): void => {
    if (!addPersonButtonIsDisabled) return;

    if (inputValue && inputValue.length) {
      // try to find person in peopleChoices
      const person = peopleChoices.find((person) => {
        let searchText;
        if (person.type === 'company') {
          searchText = `${person.businessName} ${person.vat}`;
        } else {
          searchText = `${person.firstName} ${person.lastName} ${person.taxCode}`;
        }
        return searchText.toLowerCase().includes(inputValue);
      });

      if (!person) {
        notify(
          `L'anagrafica non esiste o non è stata ancora collegata al ruolo ${selectedRoleInfo.description}.`,
          'warning',
        );
        setAddPersonButtonIsDisabled(false);
      }
    }
  };

  const getOptionText = (option): string => {
    if (!option) return '';
    return type === 'person'
      ? `${option.lastName} ${option.firstName}`
      : `${option.businessName} (${option.vat})`;
  };

  /// endregion

  return (
    <AppFormDialog
      open={ctx.manager.isOpen}
      onClose={ctx.manager.close}
      title={(title && title(ctx.manager.mode, ctx.item)) || (ctx.manager.title ?? '???')}
      onSubmit={(data, close) => {
        ctx.manager.submit(data, close);
      }}
      mode={ctx.manager.mode}
      record={ctx.item}
      width={120}
      height={60}
      {...others}
    >
      <AppCapabilityForm
        isJudiciaryFolder={isJudiciaryFolder}
        resource={resource}
        getPeople={getPeople}
        setAddPersonButtonIsDisabled={setAddPersonButtonIsDisabled}
        addPersonButtonIsDisabled={addPersonButtonIsDisabled}
        setSelectedRoleInfo={setSelectedRoleInfo}
        selectedRoleInfo={selectedRoleInfo}
        peopleLoading={peopleLoading}
        peopleChoices={peopleChoices}
        getOptionText={getOptionText}
        showCreatePersonButton={showCreatePersonButton}
        handleClickOpen={handleClickOpen}
        setOpen={setOpen}
        ctx={ctx}
        type={type}
        courtId={courtId}
        open={open}
      />
    </AppFormDialog>
  );
};

export default AppCapabilityManager;
