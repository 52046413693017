import { makeStyles } from '@material-ui/core/styles';
import { Check as AuthorizedIcon, Clear as UnauthorizedIcon } from '@material-ui/icons';
import { ReactElement, useState } from 'react';
import { FunctionField, SelectField } from 'react-admin';
import AppAddressData from '../../Components/person-company/address-data.component';
import AppContactsData from '../../Components/person-company/contacts-data.component';
import AppRelatedResource from '../../Components/related-resource/app-related-resource';
import AppCard from '../../Components/ui/card/AppCard';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppDateField from '../../Components/ui/field/AppDateField';
import AppLinkToEntityField from '../../Components/ui/field/AppLinkToEntityField';
import AppTextField from '../../Components/ui/field/AppTextField';
import AppFormTab from '../../Components/ui/form/AppFormTab';
import AppTabbedForm from '../../Components/ui/form/AppTabbedForm';
import {
  AppDateInput,
  AppRichTextInput,
  AppSelectInput,
  AppTextInput,
} from '../../Components/ui/input';
import AppDivider from '../../Components/ui/layout/AppDivider';
import { useDidMount } from '../../hooks/use-did-mount';
import { GENDER_CHOICES, ORIGIN_TYPES, PARTICIPANT_TYPES } from '../../utils/constants';
import { dateFormatter } from '../../utils/data-formatters';

const useStyles = makeStyles((theme) => ({
  date: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(25),
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(30),
  },
  price: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
  },
  ivgCode: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
  },
  procedureCode: {
    whiteSpace: 'nowrap',
    minWidth: theme.spacing(20),
  },
  lastColumn: {
    width: '100%',
  },
  cellAuthorized: {
    color: theme.palette.success.main,
  },
  cellUnauthorized: {
    color: theme.palette.error.main,
  },
}));

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

const ParticipantDetails = (props: Record<any, any>): ReactElement => {
  const classes = useStyles();
  const [representsCompany, setRepresentsCompany] = useState<boolean>(false);
  useDidMount(() => {
    if (props.mode !== 'create') {
      setRepresentsCompany(props.record.type === 'company');
    }
  });
  return (
    <AppTabbedForm variant="outlined" {...props}>
      <AppFormTab label="Generale" disabledCards={props.mode === 'show'}>
        <AppCard title="Anagrafica" expanded>
          <AppSelectInput
            source="type"
            label="Tipologia anagrafica"
            choices={PARTICIPANT_TYPES}
            required
            md={4}
            onChange={({ target }) => {
              setRepresentsCompany(target.value === 'company');
            }}
          />
          <AppDivider />
          <AppTextInput source="lastName" label="Cognome" required md={4} />
          <AppTextInput source="firstName" label="Nome" required md={4} />
          <AppTextInput source="taxCode" label="Codice fiscale" md={4} />
          {/* <AppBooleanInput source="blacklist" label="Blacklist" md={2} /> */}
          <AppSelectInput source="gender" label="Sesso" choices={GENDER_CHOICES} md={2} />
          <AppDateInput source="birthday" label="Data di nascita" md={2} />
          {/* <AppDivider /> */}
          {/* <AppBooleanInput source="pvp" label="Da PVP" md={4} />
          <AppBooleanInput source="online" label="Telematico" md={4} />
          <AppBooleanInput source="offline" label="Cartaceo" md={4} /> */}
        </AppCard>
        <AppAddressData
          primaryAddress="Residenza"
          secondaryAddress="Domicilio"
          geocoderRef={props.options.geocoderRef}
          {...props}
        />
        <AppContactsData {...props} />
        <AppCard title="Informazioni azienda" hidden={!representsCompany}>
          <AppTextInput source="businessName" required label="Ragione sociale" md={4} />
          <AppTextInput source="vat" required label="Partita IVA" md={4} />
          <AppTextInput source="sdiCode" label="Codice SDI" defaultValue="0000000" md={4} />
          <AppTextInput source="registeredOffice" label="Sede legale" required />
          <AppTextInput source="roleInsideCompany" label="Ruolo adottato nell'azienda" required />
          <AppContactsData {...props} sourcePrefix="company" emailRequired />
        </AppCard>
        <AppCard title="Annotazioni">
          <AppRichTextInput source="notes" label="" />
        </AppCard>
      </AppFormTab>
      <AppFormTab label="Esperimenti di vendita" path="sale-experiments" maxWidth={false}>
        <AppCard title="Aste non aggiudicate" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/participations"
            filter={{ awarded: false }}
            actionsHidden
          >
            <AppDatagrid>
              <AppLinkToEntityField
                label="Numero di procedura"
                targetResource="folders"
                originName="partecipante"
                value={(r) =>
                  r.relatedSaleExperiment.relatedItem.relatedFolder.procedureCode ??
                  `Fascicolo non giudiziario (${r.relatedSaleExperiment.relatedItem.relatedFolder.internalCode})`
                }
                targetResourceId={(r) => r.relatedSaleExperiment.relatedItem.relatedFolder.id}
                headerClassName={classes.procedureCode}
              />
              <AppLinkToEntityField
                label="Titolo lotto"
                targetResource="items"
                targetResourceId={(r) => r.relatedSaleExperiment.relatedItem.id}
                originName="partecipante"
                value={(r) => r.relatedSaleExperiment.relatedItem.title}
                headerClassName={classes.itemTitle}
                truncate
              />
              <AppLinkToEntityField
                label="Data vendita"
                targetResource="sale-experiments"
                targetResourceId={(r) => r.relatedSaleExperiment.id}
                originName="partecipante"
                value={(r) => dateFormatter(r.relatedSaleExperiment.auctionStartAt, true)}
                headerClassName={classes.date}
              />
              {IS_IVG && (
                <AppTextField
                  source="relatedSaleExperiment.relatedItem.ivgCode"
                  label="Numero IVG"
                  headerClassName={classes.ivgCode}
                />
              )}
              <AppTextField
                source="highestOffer"
                type="amount"
                label="Offerta più alta"
                headerClassName={classes.price}
              />
              <AppDateField
                source="highestOfferDate"
                label="Data/ora offerta più alta"
                showTime
                headerClassName={classes.date}
              />
              <SelectField choices={ORIGIN_TYPES} source="origin" label="Origine" />
              <FunctionField
                label="Autorizzato"
                render={(r) =>
                  r?.authorized ? (
                    <AuthorizedIcon className={classes.cellAuthorized} />
                  ) : (
                    <UnauthorizedIcon className={classes.cellUnauthorized} />
                  )
                }
                headerClassName={classes.lastColumn}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
        <AppCard title="Aste aggiudicate" expanded hideContent={props.mode === 'create'}>
          <AppRelatedResource
            relatedResource="/participations"
            filter={{ awarded: true }}
            actionsHidden
            showDivider={false}
          >
            <AppDatagrid>
              <AppLinkToEntityField
                label="Numero di procedura"
                targetResource="folders"
                originName="partecipante"
                value={(r) =>
                  r.relatedSaleExperiment.relatedItem.relatedFolder.procedureCode ??
                  `Fascicolo non giudiziario (${r.relatedSaleExperiment.relatedItem.relatedFolder.internalCode})`
                }
                targetResourceId={(r) => r.relatedSaleExperiment.relatedItem.relatedFolder.id}
                headerClassName={classes.procedureCode}
              />
              <AppLinkToEntityField
                label="Titolo lotto"
                targetResource="items"
                targetResourceId={(r) => r.relatedSaleExperiment.relatedItem.id}
                originName="partecipante"
                value={(r) => r.relatedSaleExperiment.relatedItem.title}
                headerClassName={classes.itemTitle}
                truncate
              />
              <AppLinkToEntityField
                label="Data vendita"
                targetResource="sale-experiments"
                targetResourceId={(r) => r.relatedSaleExperiment.id}
                originName="partecipante"
                value={(r) => dateFormatter(r.relatedSaleExperiment.auctionStartAt, true)}
                headerClassName={classes.date}
              />
              {IS_IVG && (
                <AppTextField
                  source="relatedSaleExperiment.relatedItem.ivgCode"
                  label="Numero IVG"
                  headerClassName={classes.ivgCode}
                />
              )}
              <AppTextField
                type="amount"
                label="Prezzo di aggiudicazione"
                source="highestOffer"
                headerClassName={classes.price}
                sortable={false}
              />
              <AppDateField
                source="highestOfferDate"
                label="Data/ora offerta di aggiudicazione"
                showTime
                headerClassName={classes.date}
              />
              <SelectField
                choices={ORIGIN_TYPES}
                source="origin"
                label="Origine"
                headerClassName={classes.lastColumn}
              />
            </AppDatagrid>
          </AppRelatedResource>
        </AppCard>
      </AppFormTab>
    </AppTabbedForm>
  );
};

export default ParticipantDetails;
