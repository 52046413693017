import { Text } from '@react-pdf/renderer';
import { FC } from 'react';
import styles from '../styles';

export const PdfPageNumber: FC<any> = () => (
  <Text
    style={styles.pageNumber}
    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
    fixed
  />
);
