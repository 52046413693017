import { Button, makeStyles } from '@material-ui/core';
import { assign as _assign, keyBy as _keyBy } from 'lodash';
import { useNotify } from 'ra-core';
import React, { FC, useEffect, useState, Fragment } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import { AppDialog } from '../../ui/dialog';
import { AppBold, AppText } from '../../ui/text';
import RequestPublicationsList from '../utilities/request-publications-list';

const useStyles = makeStyles(
  (theme) => ({
    dialogContainer: {
      maxWidth: 'none',
      width: theme.spacing(130),
      height: theme.spacing(60),
    },
    dialogDescription: {
      marginBottom: theme.spacing(4),
    },
    checkbox: {
      marginLeft: 0,
      flexGrow: 1,
    },
  }),
  { name: 'PublicationNotificationDialog' },
);

interface PublicationNotificationDialogProps {
  open: boolean;
  closeDialog: () => void;
  pvpRequestId: number;
  publications: Record<string, any>[];
}

const PublicationNotificationDialog: FC<PublicationNotificationDialogProps> = ({
  open,
  closeDialog,
  pvpRequestId,
  publications,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const [publicationsListData, setPublicationsListData] = useState<Record<string, any>>({});
  // manage publications in order to be printed in the React Admin datagrid
  useEffect(
    () =>
      setPublicationsListData(
        _keyBy(
          publications.map((item, index) => ({
            id: index + 1,
            checked: false,
            ...item,
          })),
          'id',
        ),
      ),
    [publications],
  );

  const listBaseCtx = {
    resource: 'pvp-publication-requests',
    page: 1,
    perPage: 1000,
    currentSort: { field: 'id', sort: 'ASC' },
    selectedIds: [],
    total: 0,
  };
  const [listCtx, setListCtx] = useState<Record<string, any>>(listBaseCtx);

  // Update list context
  useEffect(() => {
    const ids = Object.keys(publicationsListData);
    const total = ids.length;

    if (!total) return;

    setListCtx(
      _assign({}, listBaseCtx, {
        data: publicationsListData,
        ids,
        total,
        loaded: true,
        loading: false,
      }),
    );
  }, [publicationsListData]);

  const [notifyPublicationToPvp] = useMutation();
  const handlePublicationToPvpNotification = () =>
    notifyPublicationToPvp(
      {
        type: 'getOne',
        resource: `pvp-publication-requests/${pvpRequestId}/notify-publication-to-pvp`,
        payload: {},
      },
      {
        onSuccess: () => {
          notify('Pubblicazione notificata al PVP con successo.');
          refresh();
          closeDialog();
        },
        onFailure: (err) => {
          console.error(err);
          notify(err.message, 'error');
          closeDialog();
        },
      },
    );

  return (
    <AppDialog
      classes={{ paper: classes.dialogContainer }}
      open={open}
      onClose={closeDialog}
      title="Confermi di voler notificare la pubblicazione al PVP?"
      content={
        <Fragment>
          <div className={classes.dialogDescription}>
            <AppText>
              Confermando l'operazione permetterai al PVP di{' '}
              <AppBold>effettuare il monitoraggio</AppBold> sul lotto pubblicato.
            </AppText>
            <AppText>
              Assicurati che il lotto sia <AppBold>visibile correttamente</AppBold> su tutti i siti
              cliccando sulla voce "vai al dettaglio" di <AppBold>ogni pubblicazione</AppBold>.
            </AppText>
          </div>
          <RequestPublicationsList listCtx={listCtx} setListData={setPublicationsListData} />
        </Fragment>
      }
      actions={
        <Fragment>
          <Button onClick={closeDialog}>Chiudi</Button>
          <Button
            color="primary"
            disabled={
              listCtx.total < 1 || Object.values(publicationsListData).some((p: any) => !p.checked)
            }
            onClick={handlePublicationToPvpNotification}
          >
            Invia notifica
          </Button>
        </Fragment>
      }
    />
  );
};

export default PublicationNotificationDialog;
