import { InputAdornment } from '@material-ui/core';
import { FC } from 'react';
import { ItemGenre, SaleMode } from '../../../utils/constants';
import {
  AppAmountCalculator,
  AppBooleanInput,
  AppInputFiller,
  AppNumberInput,
} from '../../ui/input';
import AppSectionTitle from '../../ui/layout/AppSectionTitle';

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';

interface LegalChargesProps {
  mode: string;
  record: Record<string, any>;
  selectedItem: Record<string, any>;
  saleMode: number;
}

export const LegalCharges: FC<LegalChargesProps> = ({
  selectedItem,
  mode,
  record,
  saleMode,
  ...rest
}) => {
  return (
    <>
      <AppSectionTitle>Oneri</AppSectionTitle>
      <AppNumberInput
        {...rest}
        label="Diritti d'asta"
        source="auctionRights"
        md={3}
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      />
      <AppNumberInput
        {...rest}
        label="IVA su diritti d'asta"
        source="auctionRightsVat"
        md={3}
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      />
      <AppNumberInput
        {...rest}
        label="IVA sull'aggiudicazione"
        source="adjudicationVat"
        md={3}
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      />
      <AppBooleanInput
        {...rest}
        source="publishVatInfo"
        label="Pubblica online"
        md={3}
        initialValue={IS_IVG && selectedItem.fkGenre !== ItemGenre.RealEstate}
      />
      <AppAmountCalculator
        source="expenseAccount"
        label="Conto spese"
        refToSource="expenseAccountRefTo"
        refToChoices={[
          { id: 'priceApiece', name: 'Prezzo di vendita al pezzo' },
          { id: 'priceTotal', name: 'Prezzo di vendita totale' },
        ]}
        mode={mode}
        record={record}
      />
      <AppAmountCalculator
        source="registrationFee"
        label="Tassa di registro"
        refToSource="registrationFeeRefTo"
        refToExternalChoices={[
          {
            id: 'registrationFeePercentageOnAwardPrice',
            name: 'Prezzo di aggiudicazione',
          },
        ]}
        mode={mode}
        record={record}
      />
      <AppSectionTitle>Spese aggiuntive</AppSectionTitle>
      <AppNumberInput
        {...rest}
        label="Bolli"
        source="stamps"
        inputMode="decimal"
        xs={6}
        md={4}
        isAmount
      />
      <AppNumberInput
        {...rest}
        label="Spese accessorie"
        source="ancillaryCosts"
        inputMode="decimal"
        xs={6}
        md={4}
        isAmount
      />
      {selectedItem.fkGenre === ItemGenre.Movable ? (
        <AppNumberInput
          {...rest}
          label="Spese di spedizione"
          source="shippingCharges"
          inputMode="decimal"
          xs={6}
          md={4}
          isAmount
        />
      ) : (
        <AppInputFiller md={4} />
      )}
      {selectedItem.fkGenre === ItemGenre.Movable &&
        (saleMode === SaleMode.AsyncOnline || saleMode === SaleMode.BuyItNow) && (
          <AppAmountCalculator
            source="customDuties"
            label="Dazi doganali"
            refToSource="customDutiesRefTo"
            refToExternalChoices={[
              {
                id: 'customDutiesPercentageOnAwardPrice',
                name: 'Prezzo di aggiudicazione',
              },
            ]}
            mode={mode}
            record={record}
          />
        )}
    </>
  );
};
