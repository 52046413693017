import { Drawer, DrawerProps, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { ReduxState, setSidebarVisibility, useLocale } from 'ra-core';
import React, { Children, cloneElement, FC, ReactElement, ReactNode } from 'react';
import { ClassesOverride } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useCmsConfiguration } from '../../../hooks/use-cms-configuration';
import AppDivider from './AppDivider';
import AppProfile from './profile/AppProfile';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 90;

const useLogoStyles = makeStyles(
  (theme) => ({
    logoStandard: {
      width: '208px',
      margin: '12px auto',
      display: 'block',
    },
    logoSmall: {
      margin: '15px auto',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  }),
  { name: 'AppLogo' },
);

const AppLogo: FC<AppLogoProps> = React.memo<AppLogoProps>(
  ({ standardClassName, smallClassName, open }) => {
    const cmsConfig = useCmsConfiguration();

    return (
      <div>
        {!!open && (
          <img
            className={standardClassName}
            src={cmsConfig?.websiteCustomization?.Generic?.Logo ?? '#'}
            alt="Logo"
          />
        )}
        {!open && (
          <img
            className={smallClassName}
            src={cmsConfig?.websiteCustomization?.Generic?.LogoSmall ?? '#'}
            alt="Logo piccolo"
          />
        )}
      </div>
    );
  },
);

const AppSidebar: FC<SidebarProps> = React.memo<SidebarProps>((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, closedSize, size, classes: classesOverride, ...rest } = props;
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const open = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen);
  useLocale(); // force redraw on locale change
  const handleClose = () => dispatch(setSidebarVisibility(false));
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const { drawerPaper, root, ...classes } = useStyles({ ...props, open });
  const logoClasses = useLogoStyles();

  return (
    <div className={root}>
      {isXSmall ? (
        <Drawer
          variant="temporary"
          open={open}
          PaperProps={{
            className: drawerPaper,
          }}
          onClose={toggleSidebar}
          classes={classes}
          {...rest}
        >
          <AppLogo
            standardClassName={logoClasses.logoStandard}
            smallClassName={logoClasses.logoSmall}
            open={open}
          />
          <AppDivider flex={false} />
          {cloneElement(Children.only(children), {
            onMenuClick: handleClose,
          })}
          <AppDivider flex={false} />
          <AppProfile button />
        </Drawer>
      ) : isSmall ? (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: drawerPaper,
          }}
          onClose={toggleSidebar}
          classes={classes}
          {...rest}
        >
          <AppLogo
            standardClassName={logoClasses.logoStandard}
            smallClassName={logoClasses.logoSmall}
            open={open}
          />
          <AppDivider flex={false} />
          {cloneElement(Children.only(children), {
            onMenuClick: handleClose,
          })}
          <AppDivider flex={false} />
          <AppProfile button />
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: drawerPaper,
          }}
          onClose={toggleSidebar}
          classes={classes}
          {...rest}
        >
          <AppLogo
            standardClassName={logoClasses.logoStandard}
            smallClassName={logoClasses.logoSmall}
            open={open}
          />
          <AppDivider flex={false} />
          {cloneElement(Children.only(children), {
            onMenuClick: defaultOnMenuClick,
          })}
          <AppDivider flex={false} />
          <AppProfile button />
        </Drawer>
      )}
    </div>
  );
});

AppSidebar.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  children: PropTypes.node.isRequired,
  logout: PropTypes.element,
};

const defaultOnMenuClick = () => null;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      zIndex: 2000,
      boxShadow: '6px 0px 6px 0px rgba(0,0,0,0.25)',
    },
    docked: {
      height: '100vh',
    },
    paper: {},
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    drawerPaper: {
      position: 'relative',
      height: '100%',
      overflowX: 'hidden',
      width: (props: { open?: boolean }) =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'transparent',
      borderRight: 'none',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
        height: '100vh',
        position: 'inherit',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
      },
      zIndex: 'inherit',
    },
  }),
  { name: 'AppSidebar' },
);

interface SidebarProps extends DrawerProps {
  children: ReactElement;
  closedSize?: number;
  classes: ClassesOverride<typeof useStyles>;
  size?: number;
  logout?: ReactNode;
}

type AppLogoProps = {
  standardClassName: string;
  smallClassName: string;
  open: boolean;
};

export default AppSidebar;
