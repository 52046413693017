import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import DashboardContext from '../../context';
import { User } from '../../types/user.type';
import AppDashboardAuctionRoomsBookings from './auction-rooms-bookings/app-dashboard-auction-rooms-bookings';
import AppDashboardBookings from './bookings/app-dashboard-bookings';
import AppDashboardComplaints from './complaints/app-dashboard-complaints';
import AppDashboardEcwidEvents from './ecwid-events/app-dashboard-buy-it-now-orders';
import AppDashboardExternalPropertiesPendingPublications from './external-properties-pending-publications/app-dashboard-external-properties-pending-publications';
import AppDashboardFrontEndSalesPendingPublications from './front-end-sales-pending-publications/app-dashboard-front-end-sales-pending-publications';
import AppDashboardInfoRequestPayments from './info-request-payments/app-dashboard-info-request-payments';
import AppDashboardInfoRequests from './info-requests/app-dashboard-info-requests';
import AppDashboardPaymentsToBeVerified from './payments-to-be-verified/app-dashboard-payments-to-be-verified';
import AppDashboardPendingParticipations from './pending-participations/app-dashboard-pending-participations';
import AppDashboardPvp from './pvp/app-dashboard-pvp';
import AppDashboardSaleExperimentToBePaid from './sale-experiments-to-be-paid/app-dashboard-sale-experiments-to-be-paid';
import AppDashboardShippings from './shippings/app-dashboard-shippings';
import AppDashboardTitle from './title/app-dashboard-title';
import AppDashboardTodaySales from './today-sales/app-dashboard-today-sales';

const useStyles = makeStyles(
  () => ({
    container: {
      'overflow-y': 'auto',
    },
  }),
  { name: 'AppDashboard' },
);

const AppDashboard = (): JSX.Element => {
  // TODO: hide general "refresh" button (top-right)

  const classes = useStyles();

  const { permissions: user } = usePermissions<User>();

  return (
    <DashboardContext.Provider
      value={{
        isIvg: process.env.REACT_APP_IS_IVG === 'true',
        adminName: process.env.REACT_APP_IVG_NAME ?? 'Casa delle Aste',
        user,
      }}
    >
      <Grid container className={classes.container}>
        <AppDashboardTitle />
        <AppDashboardTodaySales />
        <AppDashboardPendingParticipations />
        <AppDashboardPendingParticipations a107 />
        <AppDashboardPendingParticipations expressionOfInterests />
        <AppDashboardPendingParticipations offersCollections />
        <AppDashboardPaymentsToBeVerified />
        <AppDashboardPvp />
        <AppDashboardAuctionRoomsBookings />
        <AppDashboardExternalPropertiesPendingPublications />
        <AppDashboardSaleExperimentToBePaid />
        <AppDashboardFrontEndSalesPendingPublications />
        <AppDashboardEcwidEvents />
        <AppDashboardBookings />
        <AppDashboardInfoRequests />
        <AppDashboardInfoRequestPayments />
        <AppDashboardComplaints />
        <AppDashboardShippings />
      </Grid>
    </DashboardContext.Provider>
  );
};

export default AppDashboard;
