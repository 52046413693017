import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { FC } from 'react';

type AppCalculatorInputProps = {
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  variant: 'standard' | 'filled' | 'outlined';
  disabled: boolean;
  label?: string;
};

export const AppCalculatorPercentageInput: FC<AppCalculatorInputProps> = ({
  label,
  value,
  onChange,
  variant,
  disabled,
}) => {
  return (
    <Grid item md={4} xs={12}>
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        variant={variant}
        disabled={disabled}
        size="small"
        helperText="Inserire un valore compreso tra 0 e 100"
        margin="dense"
        fullWidth
      />
    </Grid>
  );
};

export const AppCalculatorDaysInput: FC<AppCalculatorInputProps> = ({
  value,
  onChange,
  variant,
  disabled,
}) => {
  return (
    <Grid item md={4} xs={12}>
      <TextField
        label="Numero di giorni"
        value={value}
        onChange={onChange}
        variant={variant}
        disabled={disabled}
        size="small"
        helperText="Inserire il numero di giorni desiderato"
        margin="dense"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">giorni</InputAdornment>,
        }}
      />
    </Grid>
  );
};
