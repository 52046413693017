import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { EmailField, FunctionField, ListProps, TextField } from 'react-admin';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppShowButton from '../../Components/ui/button/AppShowButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppAutocompleteInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';

import AppTitle from '../../Components/ui/layout/AppTitle';

const useStyles = makeStyles(
  {
    phone: {
      minWidth: '9rem',
    },
    name: {
      width: '50%',
    },
    keepLine: {
      whiteSpace: 'pre',
    },
  },
  {
    name: 'PersonList',
  },
);

const PersonListFilters = (
  <AppListFilter helperText="nome, cognome">
    <AppAutocompleteInput
      xs={3}
      md={6}
      label="Ruolo"
      reference="roles"
      sort={{ field: 'description', sort: 'ASC' }}
      filter={{ naturalPerson: true } as any}
      optionText="description"
      source="roleId"
      resettable
    />
    <AppAutocompleteInput
      xs={5}
      md={6}
      label="Tribunale"
      reference="courts"
      sort={{ field: 'name', sort: 'ASC' }}
      optionText="name"
      source="courtId"
      resettable
    />
    {/* <AppAutocompleteInput
      xs={2}
      md={6}
      label="Codice interno del fascicolo"
      reference="folders"
      optionText="internalCode"
      source="folderId"
      filterToQuery={(search) => (search ? { q: search, _fulltextFields: ['internalCode'] } : {})}
      sort={{ field: 'internalCode', sort: 'ASC' }}
      resettable
    />
    <AppAutocompleteInput
      xs={2}
      md={6}
      label="Numero di procedura"
      reference="folders"
      optionText="procedureCode"
      source="folderId"
      filterToQuery={(search) => (search ? { q: search, _fulltextFields: ['procedureCode'] } : {})}
      sort={{ field: 'procedureCode', sort: 'ASC' }}
      resettable
    /> */}
  </AppListFilter>
);

const getRoles = (roles: Record<string, any>[]) => {
  if (roles.length < 1) {
    return '-';
  }

  return roles
    .map((r) => r.role?.description ?? '-')
    .join('\n')
    .trim();
};

const getCourts = (roles: Record<string, any>[]) => {
  if (roles.length < 1) {
    return '-';
  }

  return roles
    .map((r) => r.court?.name ?? '-')
    .join('\n')
    .trim();
};

const PersonList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Persone" />}
      bulkActionButtons={false}
      filters={
        !props.permissions?.agency ? (
          PersonListFilters
        ) : (
          <AppListFilter helperText="nome, cognome"></AppListFilter>
        )
      }
      filter={{ _source: 'list' }}
    >
      <AppDatagrid rowClick="show">
        <TextField source="lastName" label="Cognome" headerClassName={classes.name} />
        <TextField source="firstName" label="Nome" headerClassName={classes.name} />
        <FunctionField
          label="Ruolo"
          render={(r) => (r?.roles ? getRoles(r.roles) : '-')}
          cellClassName={classes.keepLine}
        />
        {!props.permissions?.agency && (
          <FunctionField
            label="Tribunale"
            render={(r) => (r?.roles ? getCourts(r.roles) : '-')}
            cellClassName={classes.keepLine}
          />
        )}
        <FunctionField
          label="Telefono"
          render={(r) =>
            (!!r && ((r.mobiles && r.mobiles[0]?.number) || (r.phones && r.phones[0]?.number))) ||
            '-'
          }
          headerClassName={classes.phone}
        />
        <EmailField source="email" label="Email" emptyText="-" />
        <EmailField source="pec" label="PEC" emptyText="-" />
        <AppShowButton />
        <AppEditButton />
      </AppDatagrid>
    </AppList>
  );
};

export default PersonList;
